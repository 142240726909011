import React, { useRef } from 'react';
import classnames from 'classnames';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import { childrenPropType } from '../../../types/general';

import { ReactComponent as CloseModalIcon } from 'assets/svg/ic-close-modal.svg';

import './RoundedModalWrapper.scss';


const RoundedModalWrapper = ({
  isOpen,
  title,
  titleFontSize,
  titleChildren,
  padding,
  onClose,
  confirm,
  withoutScroll,
  overflowHidden,
  zIndex,
  children,
}) => {
  const veilRef = useRef(null);
  const handleVeilClick = (event) => {
    if (event.target === veilRef.current) {
      onClose();
    }
  };

  return ReactDom.createPortal(
    <div
      ref={veilRef}
      className={classnames('rounded-modal__veil', {
        'open': isOpen,
        'confirm': confirm,
      })}
      onClick={handleVeilClick}
      style={{
        zIndex: zIndex,
      }}
    >
      <div
        className={classnames('rounded-modal__container', {
          'withoutScroll': withoutScroll,
          'overflowHidden': overflowHidden,
        })}
        style={{
          padding,
        }}
      >
        {title && titleChildren &&
          <div className='rounded-modal__title-wrapper'>
            <p
              className='rounded-modal__title'
              style={{fontSize: `${titleFontSize}px`}}
            >
              {title}
            </p>
            {titleChildren}
          </div>
        }

        {title && !titleChildren &&
          <p
            className='rounded-modal__title'
            style={{fontSize: `${titleFontSize}px`}}
          >
            {title}
          </p>
        }

        {children}

        {!titleChildren &&
          <CloseModalIcon
            className='rounded-modal__close-icon'
            onClick={onClose}
          />
        }
      </div>
    </div>,
    document.getElementById('portal')
  );
};

RoundedModalWrapper.defaultProps = {
  titleFontSize: 16,
  padding: '40px 40px 32px',
};

RoundedModalWrapper.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  titleFontSize: PropTypes.number,
  titleChildren: childrenPropType,
  padding: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  confirm: PropTypes.bool,
  withoutScroll: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  zIndex: PropTypes.number,
  children: childrenPropType,
};

export default RoundedModalWrapper;
