import SizeOfSocialNetworkModel from './SizeOfSocialNetworkModel';

/**
 * @param {number} id
 * @param {string} title
 * @param {boolean} blocked
 * @param {boolean} extended
 * @param {number} sizes
 */
class SocialNetworkModel {
  constructor(data, extendedSocialNetworkIds) {
    this.id = data.id;
    this.name = data.title;
    this.blocked = data.blocked;
    this.extended = extendedSocialNetworkIds.some((id) => id === data.id);
    this.sizes = data.sizes
      .sort((sizeA, sizeB) => sizeA.blocked - sizeB.blocked)
      .map((size) => new SizeOfSocialNetworkModel(size));
  }
}

export default SocialNetworkModel;
