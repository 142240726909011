import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { childrenPropType } from '../../../types/general';

import styles from './styles.module.scss';

const FieldValue = ({ children, className, ...props }) => (
  <div {...props} className={classNames([styles.fieldValue, className])}>
    {children}
  </div>
);

FieldValue.propTypes = {
  children: childrenPropType,
  className: PropTypes.string,
};

export default FieldValue;
