import { Record } from 'immutable';
import FormData from 'form-data';

import {
  GET_GAMES_START,
  GET_GAMES_FAIL,
  GET_GAMES_SUCCESS,

  GAME_CLEARING_PREVIEW,

  GET_ALL_GENRES_REQUEST,
  GET_ALL_GENRES_SUCCESS,
  GET_ALL_GENRES_FAIL,

  GET_ALL_STUDIOS_REQUEST,
  GET_ALL_STUDIOS_SUCCESS,
  GET_ALL_STUDIOS_FAIL,

  GET_ALL_LEVELS_REQUEST,
  GET_ALL_LEVELS_SUCCESS,
  GET_ALL_LEVELS_FAIL,

  SET_SELECTED_GAME_ID,

  GET_SELECTED_GAME_FAIL,
  GET_SELECTED_GAME_REQUEST,
  GET_SELECTED_GAME_SUCCESS,

  UPLOAD_SELECTED_GAME_PREVIEW,
  UPDATE_SIMILAR_GAME_STATUS,

  REMOVE_SELECTED_GAME_FAIL,
  REMOVE_SELECTED_GAME_REQUEST,
  REMOVE_SELECTED_GAME_SUCCESS,

  GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL,
  GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST,
  GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS,

  GET_GAMES_BY_GENRES_REQUEST,
  GET_GAMES_BY_GENRES_SUCCESS,
  GET_GAMES_BY_GENRES_FAIL,

  SET_SELECTED_GAME_GENRES,
  ADD_NEW_GAME_GENRE,

  CREATE_NEW_GAME_FAIL,
  CREATE_NEW_GAME_REQUEST,
  CREATE_NEW_GAME_SUCCESS,

  UPDATE_SELECTED_GAME_FAIL,
  UPDATE_SELECTED_GAME_REQUEST,
  UPDATE_SELECTED_GAME_SUCCESS,

  GET_UNASSOCIATED_JIRA_PROJECTS_START,
  GET_UNASSOCIATED_JIRA_PROJECTS_FAIL,
  GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS,

  UPDATE_OPTION_REQUEST,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
} from './actionTypes';
import { USER_ROLES } from '../../../constants/General';

const Genre = Record({
  id: null,
  genre: '',
});

export const Game = Record({
  id: null,
  name: '',
  genres: [],
  imageUrl: '',
  ownerName: '',
  ownerRole: '',
  description: '',
  similarGames: [],
  isChecked: false,
  publishDate: null,
  genresForUpdate: [],
  gameAssetsStorageId: 0,
  jiraProjectId: '',
  socialMedia: {},
  studio: {},
  level: {},
  podCapacity: {},
});

const InitialState = Record({
  games: null,
  genres: [],
  studios: [],
  levels: [],
  socialMedia: {},
  optionUpdate: {
    loading: false,
    success: true,
    error: null,
  },
  count: 0,
  error: null,
  isFetching: false,
  isFetchingJiraProjects: false,
  isFetchingGamesForComparing: false,
  selectedGameId: null,
  allGamesForComparing: [],
  selectedGameData: new Game(),
  selectedGamePreview: new FormData(),
  unassociatedJiraProjects: [],
  offset: 0,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case CREATE_NEW_GAME_FAIL:
    case UPDATE_SELECTED_GAME_FAIL:
    case REMOVE_SELECTED_GAME_FAIL:
    case GET_GAMES_FAIL:
    case GET_ALL_GENRES_FAIL:
    case GET_ALL_STUDIOS_FAIL:
    case GET_ALL_LEVELS_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', action.error);
      });

    case CREATE_NEW_GAME_REQUEST:
    case UPDATE_SELECTED_GAME_REQUEST:
    case GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST:
    case REMOVE_SELECTED_GAME_REQUEST:
    case GET_SELECTED_GAME_REQUEST:
    case GET_GAMES_START:
    case GET_ALL_GENRES_REQUEST:
    case GET_ALL_STUDIOS_REQUEST:
    case GET_ALL_LEVELS_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('error', null);
      });

    case CREATE_NEW_GAME_SUCCESS:
    case UPDATE_SELECTED_GAME_SUCCESS:
      return state.withMutations((ctx) => {
        ctx.set('isFetching', false);
      });

    case SET_SELECTED_GAME_GENRES: {
      return state.withMutations((ctx) => {
        ctx.setIn(['selectedGameData', 'genresForUpdate'], action.genres);
      });
    }

    case ADD_NEW_GAME_GENRE: {
      return state.withMutations((ctx) => {
        const { genre } = action;
        const newGenre = new Genre({
          id: null,
          genre,
        });
        const genres = [...ctx.getIn(['selectedGameData', 'genresForUpdate'])];
        genres.push(newGenre);
        ctx.setIn(['selectedGameData', 'genresForUpdate'], genres);
      });
    }

    case UPLOAD_SELECTED_GAME_PREVIEW: {
      return state.withMutations((ctx) => {
        const {
          file,
        } = action;
        const selectedGamePreview = new FormData();
        selectedGamePreview.append('files', file);
        ctx.set('selectedGamePreview', selectedGamePreview);
      });
    }

    case UPDATE_SIMILAR_GAME_STATUS: {
      return state.withMutations((ctx) => {
        const {
          index, // this one is selected game id
          status,
        } = action;
        const newSimilarGames = [...ctx.get('allGamesForComparing')];

        // Functional for finding appropriate game in list of games.
        let indexOfGame = 0;
        const oldGame = newSimilarGames.find((game, i) => {
          if (game.get('id') === index) {
            indexOfGame = i;
            return true;
          }

          return false;
        });

        const {
          id,
          name,
          imageUrl,
          description,
          publishDate,
        } = oldGame;

        const newGame = new Game({
          id,
          name,
          imageUrl,
          description,
          publishDate,
          checked: status,
        });

        newSimilarGames.splice(indexOfGame, 1, newGame);
        ctx.set('allGamesForComparing', newSimilarGames);
      });
    }

    case GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('allGamesForComparing', [])
          .set('error', error);
      });
    }

    case GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS: {
      return state.withMutations((ctx) => {

        const {
          games,
        } = action;
        const similarGames = ctx.getIn(['selectedGameData', 'similarGames']) || [];

        const gameItems = games.map((game) => {
          const {
            id,
            name,
            imageUrl,
            description,
            publishDate,
          } = game;

          const hasSimilarIndex = similarGames.findIndex((similarGame) => {
            return (similarGame.id === id);
          });
          const isChecked = (hasSimilarIndex !== -1);

          return new Game({
            id,
            name,
            imageUrl,
            isChecked,
            description,
            publishDate,
          });
        });

        ctx.set('isFetching', false)
          .set('allGamesForComparing', gameItems);
      });
    }

    case GET_GAMES_BY_GENRES_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingGamesForComparing', true)
          .set('error', null);
      });

    case GET_GAMES_BY_GENRES_FAIL: {
      return state.withMutations((ctx) => {
        ctx.set('isFetchingGamesForComparing', false)
          .set('allGamesForComparing', [])
          .set('error', action.error);
      });
    }

    case GET_GAMES_BY_GENRES_SUCCESS: {
      return state.withMutations((ctx) => {
        const similarGames = ctx.getIn(['selectedGameData', 'similarGames']) || [];

        const gameItems = action.games
          .filter((game) => game.id !== ctx.selectedGameId)
          .map(({ id, name, imageUrl, description, publishDate }) => {
            const isSimilar = similarGames.some((similarGame) => similarGame.id === id);

            return new Game({
              id,
              name,
              imageUrl,
              isChecked: isSimilar,
              description,
              publishDate,
            });
          });

        ctx.set('isFetchingGamesForComparing', false)
          .set('allGamesForComparing', gameItems);
      });
    }

    case REMOVE_SELECTED_GAME_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('selectedGameId', null)
          .set('selectedGamePreview', new FormData())
          .set('allGamesForComparing', [])
          .set('selectedGameData', new Game());
      });
    }

    case GET_ALL_GENRES_SUCCESS: {
      return state.withMutations((ctx) => {
        const genreItems = action.genres.map((genreItem) => {
          const { genre, id } = genreItem;

          return new Genre({ genre, id });
        });

        ctx
          .set('isFetching', false)
          .set('genres', genreItems);
      });
    }

    case GET_ALL_STUDIOS_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('studios', action.studios);
      });
    }

    case GET_ALL_LEVELS_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('levels', action.levels);
      });
    }

    case SET_SELECTED_GAME_ID:
      return state.set('selectedGameId', action.id);

    case GAME_CLEARING_PREVIEW: {
      return state.withMutations((ctx) => {
        ctx.set('selectedGameId', null)
          .set('selectedGamePreview', new FormData())
          .set('allGamesForComparing', [])
          .set('selectedGameData', new Game());
      });
    }

    case GET_SELECTED_GAME_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          game,
        } = action;
        const {
          id,
          name,
          owner,
          genres,
          imageUrl,
          description,
          publishDate,
          similarGames,
          jiraProjectId,
          gameStudio,
          gameLevel,
          podCapacity,
          socialMedia,
        } = game;

        const genreItems = genres.map((item) => {
          const {
            id,
            genre,
          } = item;
          return new Genre({
            id,
            genre,
          });
        });

        const similarGamesItems = similarGames.map((similar) => {
          const {
            id,
            name,
            imageUrl,
            description,
            publishDate,
          } = similar;

          return new Game({
            id,
            name,
            imageUrl,
            description,
            publishDate,
          });
        });

        let ownerName = '';
        let ownerRole = '';

        if (owner) {
          ownerName = owner.name;
          const currentOwnerRoleId = owner['role'].id;
          const existRoleIndex = USER_ROLES.findIndex((role) => {
            return (role.id === currentOwnerRoleId);
          });
          ownerRole = USER_ROLES[existRoleIndex].title;
        }

        const selectedGameData = new Game({
          id,
          name,
          imageUrl,
          ownerName,
          ownerRole,
          description,
          publishDate,
          socialMedia,
          genres: genreItems,
          similarGames: similarGamesItems,
          genresForUpdate: [...genreItems],
          jiraProjectId,
          studio: gameStudio,
          level: gameLevel,
          podCapacity,
        });

        ctx
          .set('isFetching', false)
          .set('selectedGameId', id)
          .set('selectedGameData', selectedGameData);
      });
    }

    case GET_SELECTED_GAME_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('error', error)
          .set('selectedGameId', null)
          .set('selectedGameData', new Game());
      });
    }

    case GET_GAMES_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          games,
          count,
          offset,
        } = action;

        ctx.set('isFetching', false)
          .set('games', games)
          .set('count', count)
          .set('offset', offset);
      });
    }

    case GET_UNASSOCIATED_JIRA_PROJECTS_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetchingJiraProjects', true)
          .set('error', null);
      });
    }

    case GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          data,
        } = action;
        ctx.set('isFetchingJiraProjects', false)
          .set('unassociatedJiraProjects', data)
          .set('error', null);
      });
    }

    case GET_UNASSOCIATED_JIRA_PROJECTS_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetchingJiraProjects', false)
          .set('error', error);
      });
    }

    case UPDATE_OPTION_REQUEST: {
      return state.withMutations((ctx) => {
        ctx
          .set('optionUpdate', {
            loading: true,
            success: false,
            error: null,
          });
      });
    }

    case UPDATE_OPTION_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('optionUpdate', {
            loading: false,
            success: true,
            error: null,
          });
      });
    }

    case UPDATE_OPTION_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('optionUpdate', {
            loading: false,
            success: false,
            error: action.error,
          });
      });
    }

    default: {
      return state;
    }
  }
}
