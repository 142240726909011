import cloneDeep from 'lodash/cloneDeep';
import { calculateDefaultCropPositionForSize } from './adCreateHelpers';

// TODO: replace with actual video dimensions
const VIDEO_DIMENSIONS = {
  width: 1920,
  height: 1080,
};

export const transformProjectSizesOnSave = (sizes) => {
  const cloneSizes = cloneDeep(sizes);

  cloneSizes.forEach((size) => {
    const {crop, dragAndDrop, power} = cloneDeep(size.zoom);
    size.zoom.dragAndDrop = crop;
    size.zoom.crop = {
      x: VIDEO_DIMENSIONS.width * power * dragAndDrop.x / 100,
      y: VIDEO_DIMENSIONS.height * power * dragAndDrop.y / 100,
    };
  });

  return cloneSizes;
};

export const transformProjectSizesOnEnter = (sizes) => {
  const cloneSizes = cloneDeep(sizes);

  cloneSizes.forEach((size) => {
    const {crop, dragAndDrop, power} = cloneDeep(size.zoom);

    size.zoom.crop = dragAndDrop ? dragAndDrop : {x: 0, y: 0};

    if (crop) {
      size.zoom.dragAndDrop = {
        x: crop.x * 100 / VIDEO_DIMENSIONS.width / power,
        y: crop.y * 100 / VIDEO_DIMENSIONS.height / power,
      };
    } else {
      size.zoom.dragAndDrop = calculateDefaultCropPositionForSize(size.width / size.height);
    }
  });

  return cloneSizes;
};