import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  VIEW_GRID,
  VIEW_LIST,
} from 'constants/General';

import { ReactComponent as GridViewIcon } from 'assets/svg/ic-grid-view.svg';
import { ReactComponent as ListViewIcon } from 'assets/svg/ic-list-view.svg';
import { ReactComponent as GridViewIconNew } from 'assets/svg/grid-view.svg';
import FilterListIcon from '@material-ui/icons/FilterList';

import './GridListViewPanel.scss';


const GridListViewPanel = ({
  activeViewType,
  handleViewTypeChange,
  newDesign,
}) => {
  return (
    <div
      className={classnames('grid-list-view-panel', {
        'newDesign': newDesign,
      })}
    >
      <div className='grid-list-view-panel__name'>
        View as
      </div>

      <div className='grid-list-view-panel__options'>
        { newDesign ?
          <>
            <GridViewIconNew
              className={classnames('grid-list-view-panel__icon', {
                'active': activeViewType === VIEW_GRID,
              })}
              onClick={() => handleViewTypeChange(VIEW_GRID)}
            />

            <FilterListIcon
              className={classnames('grid-list-view-panel__icon', {
                'active': activeViewType === VIEW_LIST,
              })}
              onClick={() => handleViewTypeChange(VIEW_LIST)}
            />
          </> :
          <>
            <GridViewIcon
              className={classnames('grid-list-view-panel__icon', {
                'active': activeViewType === VIEW_GRID,
              })}
              onClick={() => handleViewTypeChange(VIEW_GRID)}
            />

            <ListViewIcon
              className={classnames('grid-list-view-panel__icon', {
                'active': activeViewType === VIEW_LIST,
              })}
              onClick={() => handleViewTypeChange(VIEW_LIST)}
            />
          </>
        }
      </div>
    </div>
  );
};

GridListViewPanel.propTypes = {
  activeViewType: PropTypes.string.isRequired,
  handleViewTypeChange: PropTypes.func.isRequired,
  newDesign: PropTypes.bool,
};

export default GridListViewPanel;
