import moment from 'moment';
import { FORMAT_DATE_WITHOUT_TIME } from 'constants/General';

/**
 * @param {object} data
 */
class CollectionFolderModel {
  constructor(data) {
    this.id = data.id;
    this.entityId = data.entityId;
    this.collectionId = data.collectionId;
    this.name = data.name;
    this.isFolder = data.isFolder;
    this.parentId = data.parentId;
    this.createdAt = data.createdAt ? moment(data.createdAt).format(FORMAT_DATE_WITHOUT_TIME) : 'no data';
  }
}

export default CollectionFolderModel;