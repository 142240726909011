import FeathersClient, { addLocalTimeHeader } from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';

const UPLOAD_HEADERS = {
  'Content-Type': undefined,
  'Accept': '*/*',
};


class GameService {
  constructor() {
    this.game = FeathersClient.service(`${API_PREFIX}games`);
    this.history = FeathersClient.service(`${API_PREFIX}asset-history`);
    this.genre = FeathersClient.service(`${API_PREFIX}genres`);
    this.studio = FeathersClient.service(`${API_PREFIX}game-studio`);
    this.level = FeathersClient.service(`${API_PREFIX}game-level`);
    this.gameImage = FeathersClient.service(`${API_PREFIX}games/images`);
    this.gameGenre = FeathersClient.service(`${API_PREFIX}games/genres`);
    this.similarGame = FeathersClient.service(`${API_PREFIX}games/{gameId}/similar`);
    this.gameRestrict = FeathersClient.service(`${API_PREFIX}games/restrict`);
    this.gameAllow = FeathersClient.service(`${API_PREFIX}games/allow`);
    this.unassociatedJiraProjects = FeathersClient.service(`${API_PREFIX}games/unassociated_jira_projects`);
  }

  findUnassociatedJiraProjects() {
    return this.unassociatedJiraProjects.find();
  }

  getUnassociatedJiraProjects(id) {
    return this.unassociatedJiraProjects.get(id);
  }

  getGameGenres(query) {
    return this.gameGenre.find({ query });
  }

  getAllGenres(query) {
    return this.genre.find({ query });
  }

  getGameById(id) {
    return this.game.get(id);
  }

  getGames(query) {
    return this.game.find({ query });
  }

  createGame(data) {
    return this.game.create(data);
  }

  removeGame(id) {
    return this.game.remove(id);
  }

  createGameImage(form) {
    return this.gameImage.create(form, {
      headers: addLocalTimeHeader(UPLOAD_HEADERS),
    });
  }

  updateGame(id, data) {
    return this.game.patch(id, data);
  }

  getGameHistory({page, limit, sort, gameId}) {
    const query = {};

    if (gameId) {
      query['gameId'] = gameId;
    }

    if (limit) {
      query['$limit'] = limit;
    }

    if (page) {
      query['$skip'] = (page - 1) * limit;
    }

    if (sort) {
      query['$sort[createdAt]'] = sort;
    }

    return this.history.find({ query });
  }

  getStudios() {
    const query = {
      $sort: {
        id: 1,
      },
      $limit: 999,
    };

    return this.studio.find({ query });
  }

  createStudio(data) {
    return this.studio.create(data);
  }

  updateStudio(id, data) {
    return this.studio.patch(id, data);
  }

  removeStudio(id) {
    return this.studio.remove(id);
  }

  getLevels() {
    const query = {
      $sort: {
        id: 1,
      },
      $limit: 999,
    };

    return this.level.find({ query });
  }

  createLevel(data) {
    return this.level.create(data);
  }

  updateLevel(id, data) {
    return this.level.patch(id, data);
  }

  removeLevel(id) {
    return this.level.remove(id);
  }
}

export default new GameService();
