import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        if (!isEqual(value, debouncedValue)) {
          setDebouncedValue(value);
        }
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },

    [value]
  );

  return debouncedValue;
};

export default useDebounce;
