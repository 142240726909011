import { SET_ASSETS_TO_DOWNLOAD } from './actionsTypes';


export const setAssetsToDownloadAction = (assetsToDownload, collectionId = null, collectionHash = null) => {
  return {
    type: SET_ASSETS_TO_DOWNLOAD,
    payload: {
      assetsToDownload,
      collectionId,
      collectionHash,
    },
  };
};