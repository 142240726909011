import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as FailRequestIcon } from '../../../assets/svg/notifications/fail-request.svg';

import './FailRequestScreen.scss';


const FailRequestScreen = ({ message, additionalClass }) => {
  return (
    <div className={classnames('fail-request-screen-container', additionalClass)}>
      <FailRequestIcon className='fail-request-screen-icon' />
      <h3 className='fail-request-screen-text'>
        {message}
      </h3>
    </div>
  );
};

FailRequestScreen.defaultProps = {
  message: 'Oops! Something went wrong, please try again later.',
};

FailRequestScreen.propTypes = {
  message: PropTypes.string,
  additionalClass: PropTypes.string,
};

export default FailRequestScreen;
