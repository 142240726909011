import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageModal from '../../../UIComponents/MessageModal';
import { removeGeneralMessageAction } from '../GeneralMessages.redux/actions';

import './GeneralMessagesContainer.scss';


const GeneralMessagesContainer = () => {

  const dispatch = useDispatch();
  const { generalMessages } = useSelector((state) => state.generalMessages);

  const handleRemoveMessage = (messageId) => {
    dispatch(removeGeneralMessageAction(messageId));
  };

  return (
    <div className='general-messages-container'>
      {generalMessages.map((message) => {
        return (
          <MessageModal
            key={message.id}
            error={message.error}
            id={message.id}
            message={message.text}
            messageInfo={message.description}
            closeMessage={handleRemoveMessage}
            lifeTime={message.lifeTime}
          />
        );
      })}
    </div>
  );
};

export default GeneralMessagesContainer;