/**
 * @param {number} docId
 * @param {string} docUrl
 * @param {string} name
 */
class FontModel {
  constructor(data) {
    this.docId = parseInt(data._meta.id);
    this.docUrl = data.path.raw;
    this.name = data.asset_name.raw.slice(0, -4);
    this.storageUuid = data.storage_uuid?.raw;
  }
}

export default FontModel;
