import { Record } from 'immutable';

import {
  GET_REWARD_OPTIONS_START,
  GET_REWARD_OPTIONS_FAIL,
  GET_REWARD_OPTIONS_SUCCESS,

  SEND_ITEM_START,
  SEND_ITEM_FAIL,
  SEND_ITEM_SUCCESS,
} from './actionTypes';

const InitialState = Record({
  error: null,
  rewardOptions: [],
  isFetchingRewardOptions: false,
  isSendingItems: false,
  sendItemRes: null,
});

const initialState = new InitialState();

export default function(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case GET_REWARD_OPTIONS_START:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingRewardOptions', true)
          .set('error', null);
      });

    case GET_REWARD_OPTIONS_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingRewardOptions', false)
          .set('rewardOptions', action.rewardOptions)
          .set('error', null);
      });

    case GET_REWARD_OPTIONS_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingRewardOptions', false)
          .set('error', action.error);
      });

    case SEND_ITEM_START:
      return state.withMutations((ctx) => {
        ctx
          .set('isSendingItems', true)
          .set('error', null);
      });

    case SEND_ITEM_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isSendingItems', false)
          .set('sendItemRes', action.res)
          .set('error', null);
      });

    case SEND_ITEM_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isSendingItems', false)
          .set('sendItemRes', 'Fail')
          .set('error', action.error);
      });

    default:
      return state;
  }
}
