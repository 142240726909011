import AnalyticsDeviceService from '../../../services/AnalyticsDevice';
import {
  GET_ANALYTICS_DEVICE_START,
  GET_ANALYTICS_DEVICE_FAIL,
  GET_ANALYTICS_DEVICE_SUCCESS,

  GET_ANALYTICS_DEVICE_BY_TPDID_START,
  GET_ANALYTICS_DEVICE_BY_TPDID_FAIL,
  GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS,

  SET_ANALYTICS_DEVICE_QUERY_ID,

  SET_ANALYTICS_DEVICE_TEST_STATUS_START,
  SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS,
  SET_ANALYTICS_DEVICE_TEST_STATUS_FAIL,
} from './actionTypes';

export function findAnalyticsDevice(query) {
  const {
    idType,
    queryId,
    platform,
  } = query;

  return (dispatch) => {
    dispatch({
      type: SET_ANALYTICS_DEVICE_QUERY_ID,
      queryId,
    });
    dispatch({
      type: GET_ANALYTICS_DEVICE_START,
      idType,
      queryId,
    });
    return AnalyticsDeviceService.findAnalyticsDevice(query)
      .then((res) => {
        dispatch({
          type: GET_ANALYTICS_DEVICE_SUCCESS,
          res: res,
          platform,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ANALYTICS_DEVICE_FAIL,
        }, error.message);
      });
  };
}

export function getAnalyticsDeviceByTpdid(tpdid) {
  return (dispatch) => {
    dispatch({
      type: SET_ANALYTICS_DEVICE_QUERY_ID,
      queryId: tpdid,
    });
    dispatch({
      type: GET_ANALYTICS_DEVICE_BY_TPDID_START,
      queryId: tpdid,
    });
    return AnalyticsDeviceService.getAnalyticsDeviceByTpdid(tpdid)
      .then((res) => {
        dispatch({
          type: GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS,
          res: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ANALYTICS_DEVICE_BY_TPDID_FAIL,
        }, error.message);
      });
  };
}

export function setAnalyticsDeviceTestStatus(tpdid, platform, testStatus) {
  return (dispatch) => {
    dispatch({
      type: SET_ANALYTICS_DEVICE_TEST_STATUS_START,
      testStatus,
    });
    return AnalyticsDeviceService.setAnalyticsDeviceTestStatus(tpdid, platform, testStatus)
      .then((res) => {
        dispatch({
          type: SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS,
          platform,
          res,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_ANALYTICS_DEVICE_TEST_STATUS_FAIL,
        }, error.message);
      });
  };
}
