import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';

import styles from './styles.module.scss';

const GameCheckboxItem = ({ imageUrl, checked, onChange }) => (
  <div
    className={styles.gameCheckboxContainer}
    style={{ backgroundImage: `url('${imageUrl}')` }}
  >
    <Checkbox
      checked={checked}
      onChange={onChange}
    />
  </div>
);

GameCheckboxItem.propTypes = {
  checked: PropTypes.bool,
  imageUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default GameCheckboxItem;
