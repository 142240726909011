import { AD_CREATE_BANNER_ITEM_TYPES } from 'constants/CreativeTool';

/**
 * @param {number|string} id
 * @param {string} type
 * @param {string} text
 * @param {number} fontId
 * @param {Object} styles
 */
class TextBannerItemModel {
  constructor(textBannerItem, id) {
    this.id = id;
    this.type = AD_CREATE_BANNER_ITEM_TYPES.TEXT;
    this.text = textBannerItem.text;
    this.fontId = textBannerItem.fontId;
    this.styles = textBannerItem.styles;
  }
}

export default TextBannerItemModel;
