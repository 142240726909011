import {
  OPEN_UPLOAD_PROGRESS,
  CLOSE_UPLOAD_PROGRESS,
} from './actionsTypes';
import {
  UPLOAD_DOC_GAME_FAIL,
  UPLOAD_DOC_GAME_SUCCESS,
} from '../../Assets/Assets.redux/actionTypes';
import {
  UPLOAD_DOC_CREATIVE_FAIL,
  UPLOAD_DOC_CREATIVE_SUCCESS,
} from '../../Creative/Creative.redux/actionTypes';


const initState = {
  open: false,
  count: 0,
  files: [],
  areCreativeAssets: false,
  success: 0,
  fail: 0,
};

const uploadProgressReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_UPLOAD_PROGRESS: {
      const files = payload.files.map((file) => ({
        name: file.name,
        size: file.size / 1000,
        type: file.type,
      }));

      return {
        ...state,
        open: true,
        count: files.length,
        files: files,
        areCreativeAssets: payload.areCreativeAssets,
        success: 0,
        fail: 0,
      };
    }

    case CLOSE_UPLOAD_PROGRESS:
      return {
        ...state,
        open: false,
        count: 0,
        files: [],
        areCreativeAssets: false,
        success: 0,
        fail: 0,
      };

    case UPLOAD_DOC_GAME_SUCCESS:
    case UPLOAD_DOC_CREATIVE_SUCCESS: {
      const newFiles = state.files.map((file) => {
        if (file.name === payload) {
          return {...file, success: true, completed: true};
        } else {
          return file;
        }
      });

      return {
        ...state,
        files: [...newFiles],
        success: state.success + 1,
      };
    }

    case UPLOAD_DOC_GAME_FAIL:
    case UPLOAD_DOC_CREATIVE_FAIL: {
      const newFiles = state.files.map((file) => {
        if (file.name === payload) {
          return {...file, success: false, completed: true};
        } else {
          return file;
        }
      });

      return {
        ...state,
        files: [...newFiles],
        fail: state.fail + 1,
      };
    }

    default:
      return state;
  }
};

export default uploadProgressReducer;