import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import CreativeStructure from './CreativeStructure';


const CreativeContainer = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect
        exact
        from={path}
        to='/search'
      />
      <Route
        path={`${path}/:gameId/:id`}
        component={CreativeStructure}
      />
      <Route
        path={`${path}/:issueKey`}
        component={CreativeStructure}
      />
    </Switch>
  );
};

export default CreativeContainer;
