
export const VIDEO_PROJECT_DISCARD = 'VIDEO_PROJECT_DISCARD';
export const VIDEO_PROJECT_ACTIVE_EXTENSION_CHANGE = 'VIDEO_PROJECT_ACTIVE_EXTENSION_CHANGE';
export const VIDEO_PROJECT_SOUND_FADE_TOGGLE = 'VIDEO_PROJECT_SOUND_FADE_TOGGLE';
export const VIDEO_PROJECT_SOUND_FADE_TIME_UPDATE = 'VIDEO_PROJECT_SOUND_FADE_TIME_UPDATE';
export const VIDEO_PROJECT_AUDIO_UPDATE = 'VIDEO_PROJECT_AUDIO_UPDATE';
export const VIDEO_PROJECT_UPLOAD_PATH_UPDATE = 'VIDEO_PROJECT_UPLOAD_PATH_UPDATE';
export const VIDEO_PROJECT_RENDER_FRAME_UPDATE = 'VIDEO_PROJECT_RENDER_FRAME_UPDATE';
export const VIDEO_PROJECT_VALIDATION_UPDATE = 'VIDEO_PROJECT_VALIDATION_UPDATE';
export const VIDEO_PROJECT_SPECIFIC_VALIDATION_UPDATE = 'VIDEO_PROJECT_SPECIFIC_VALIDATION_UPDATE';
export const VIDEO_PROJECT_MAIN_VIDEOS_VALIDATION_SWAP = 'VIDEO_PROJECT_MAIN_VIDEOS_VALIDATION_SWAP';

export const VIDEO_PROJECT_SET_CANVAS_TO_NORMAL_SIZE = 'VIDEO_PROJECT_SET_CANVAS_TO_NORMAL_SIZE';
export const VIDEO_PROJECT_SET_CANVAS_TO_FULL_SIZE = 'VIDEO_PROJECT_SET_CANVAS_TO_FULL_SIZE';
