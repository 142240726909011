import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import AssetActions from 'components/Assets/AssetActions';
import ButtonWithCheckbox from 'components/UIComponents/ButtonWithCheckbox';
import SortingButton from 'components/UIComponents/SortingButton';
import GridListViewPanel from 'components/UIComponents/GridListViewPanel';
import RoundedModalWrapper from 'components/UIComponents/RoundedModalWrapper';
import { checkIfExternalUser } from 'components/General/Permissions/Permission';
import { updateAssetAction } from 'components/Assets/Assets.redux/Actions/assetActions';
import { ENTITIES_SORT_OPTIONS } from 'components/MainSearch/mainSearchConstants';
import {
  CREATIVE_GENERAL_TABS_NAMES,
  CREATIVE_STATUS,
} from 'constants/Creatives';

import {
  Check as CheckIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import './CreativeStorageAssetActions.scss';


const CreativeStorageAssetActions = ({
  gameId,
  currentTabName,
  jiraIssueStatus,
  viewType,
  setViewType,
  activeSortOptionId,
  setActiveSortOption,
  storageAssets,
  selectedAssets,
  selectedAssetIdsSet,
  setSelectedAssetIdsSet,
  setFolderToRename,
  getStorage,
}) => {

  const dispatch = useDispatch();
  const [isCantApproveModal, setIsCantApproveModal] = useState(false);

  const canApproveAssets = useMemo(
    () =>
      jiraIssueStatus !== CREATIVE_STATUS.REJECTED &&
      !!selectedAssets.length &&
      selectedAssets.every((asset) => !asset.isFolder) &&
      !checkIfExternalUser()
    ,
    [selectedAssets, checkIfExternalUser]);

  const checkIfAllAssetsSelected = () => {
    return selectedAssetIdsSet.size && storageAssets.every((asset) => selectedAssetIdsSet.has(asset.id));
  };

  const toggleSelectAllAssets = () => {
    if (checkIfAllAssetsSelected()) {
      storageAssets.forEach((asset) => selectedAssetIdsSet.delete(asset.id));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      storageAssets.forEach((asset) => selectedAssetIdsSet.add(asset.id));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  const toggleApprovedStatusForSelectedAssets = async (newApprovedStatus) => {
    if (
      currentTabName === CREATIVE_GENERAL_TABS_NAMES.PRODUCTION ||
      currentTabName === CREATIVE_GENERAL_TABS_NAMES.FINAL ||
      !newApprovedStatus
    ) {
      for await (const selectedAssetId of [...selectedAssetIdsSet]) {
        await dispatch(updateAssetAction(selectedAssetId, {approved: newApprovedStatus}));
      }

      getStorage();
    } else {
      setIsCantApproveModal(true);
    }
  };

  return (
    <div className='creative-storage-asset-actions'>
      <AssetActions
        gameId={gameId}
        selectedAssets={selectedAssets}
        selectedAssetIdsSet={selectedAssetIdsSet}
        setSelectedAssetIdsSet={setSelectedAssetIdsSet}
        setFolderToRename={setFolderToRename}
        actionAfterDelete={getStorage}
      />

      {canApproveAssets &&
        <div className='creative-storage-asset-actions__approve'>
          <span className='creative-storage-asset-actions__approve-text'>Approve</span>
          <CheckIcon
            className='creative-storage-asset-actions__approve-icon'
            onClick={() => toggleApprovedStatusForSelectedAssets(true)}
          />
          <CloseIcon
            className='creative-storage-asset-actions__approve-icon'
            onClick={() => toggleApprovedStatusForSelectedAssets(false)}
          />
        </div>
      }
      
      <ButtonWithCheckbox
        text='select all'
        checkedCondition={!!checkIfAllAssetsSelected()}
        toggleCheck={toggleSelectAllAssets}
        disabled={!storageAssets.length}
      />

      <SortingButton
        sortOptions={ENTITIES_SORT_OPTIONS}
        activeSortOptionId={activeSortOptionId}
        handleChangeActiveSortOption={setActiveSortOption}
      />

      <GridListViewPanel
        activeViewType={viewType}
        handleViewTypeChange={setViewType}
        newDesign
      />

      <RoundedModalWrapper
        isOpen={isCantApproveModal}
        title=''
        onClose={() => setIsCantApproveModal(false)}
        confirm
      >
        <p className='creative-storage-asset-actions__cant-approve-text'>
          This asset is <b>not</b> approved because asset isn’t in the <b>Final</b> or <b>Production</b> folder. To approve this asset, move asset to <b>Final</b> or <b>Production</b> folder
        </p>
      </RoundedModalWrapper>
    </div>
  );
};

CreativeStorageAssetActions.propTypes = {
  gameId: PropTypes.number,
  currentTabName: PropTypes.string.isRequired,
  jiraIssueStatus: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  activeSortOptionId: PropTypes.number.isRequired,
  setActiveSortOption: PropTypes.func.isRequired,
  storageAssets: PropTypes.arrayOf(PropTypes.object),
  selectedAssets: PropTypes.arrayOf(PropTypes.object),
  selectedAssetIdsSet: PropTypes.object.isRequired,
  setSelectedAssetIdsSet: PropTypes.func.isRequired,
  setFolderToRename: PropTypes.func.isRequired,
  getStorage: PropTypes.func.isRequired,
};

export default CreativeStorageAssetActions;
