import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './EntityPreview.scss';


const EntityPreview = ({ previewUrl, hasPreview, alt}) => {
  return (
    <img
      src={previewUrl}
      className={classnames('entity-preview', {
        'icon': !hasPreview,
      })}
      loading='lazy'
      alt={alt}
    />
  );
};

EntityPreview.defaultProps = {
  alt: 'Preview',
};

EntityPreview.propTypes = {
  previewUrl: PropTypes.string,
  hasPreview: PropTypes.bool,
  alt: PropTypes.string,
};

export default EntityPreview;
