import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';
import { PROJECT_FORMAT } from '../constants/CreativeTool';


class VideoProjectService {
  constructor() {
    this.videoProject = FeathersClient.service(`${API_PREFIX}gameplay-trailer`);
    this.videoProjectById = FeathersClient.service(`${API_PREFIX}gameplay-trailer/{:projectId}`);
    this.videoProjectSettings = FeathersClient.service(`${API_PREFIX}creative-tools/settings`);
    this.videoProjectCopy = FeathersClient.service(`${API_PREFIX}gameplay-trailer/copy`);
    this.videoProjectRandom = FeathersClient.service(`${API_PREFIX}gameplay-trailer/random`);
  }

  /**
   * Create video project
   * @param {object} data
   */
  createVideoProject(data) {
    return this.videoProject.create(data);
  }

  /**
   * Rename video project
   * @param {number} projectId
   * @param {string} title
   */
  renameVideoProject(projectId, title) {
    return this.videoProject.patch(projectId, { title });
  }

  /**
   * Save video project
   * @param {string} projectId
   * @param {object} data
   */
  saveVideoProject(projectId, data) {
    return this.videoProject.patch(projectId, data);
  }

  /**
   * Send request to delete video project by Id
   * @param {number} projectId
   * @param {object} data
   */
  sendDeleteRequest(projectId, data) {
    return this.videoProject.remove(projectId, {
      query: data,
    });
  }

  /**
   * Get video projects
   */
  getVideoProjects({ limit, page, filters }) {
    const { games, users, tools, badges, titles, onDelete, from, to, resetDate } = filters;

    const query = {};

    if (tools?.length) {
      query['categories'] = tools;
    } else {
      query['categories'] = [
        PROJECT_FORMAT.VIDEO_CREATE,
        PROJECT_FORMAT.VIDEO_RESIZE,
        PROJECT_FORMAT.AD_CREATE,
      ];
    }

    if (limit) {
      query['$limit'] = limit;
    }

    if (page) {
      query['$skip'] = (page - 1) * limit;
    }

    if (games) {
      query['games'] = games;
    }

    if (users?.length) {
      query['users'] = users.filter((id) => id !== null);
      query['deletedUser'] = users.some((id) => id === null) ? 1 : 0;
    }

    if (titles) {
      query['titles'] = titles;
    }

    if (badges) {
      badges.forEach((badge) => query[badge] = 1);
    }

    if (onDelete) {
      query['onDelete'] = onDelete;
    }

    if (!resetDate) {
      if (from) {
        query['from'] = from;
      }

      if (to) {
        query['to'] = to;
      }
    }

    return this.videoProject.find({ query });
  }

  /**
   * Get video project by id
   * @param {string} projectId
   */
  getVideoProject(projectId) {
    const { videoProjectById } = this;

    videoProjectById.base = `${API_PREFIX}gameplay-trailer/${projectId}`;
    return videoProjectById.find();
  }

  /**
   * Delete video project by Id
   * @param {number} videoId
   */
  deleteVideoProject(videoId) {
    return this.videoProjectSettings.remove(videoId);
  }

  /**
   * Copy video project
   * @param {string} projectId
   * @param {string} title
   */
  copyVideoProject(projectId, title) {
    return this.videoProjectCopy.create({
      trailerId: projectId,
      title,
    });
  }

  /**
   * Get structure for randomizer
   */
  getStructureForRandomizer() {
    return this.videoProjectRandom.find();
  }

  /**
   * Randomize video project
   * @param {object} data
   */
  randomizeVideoProject(data) {
    return this.videoProjectRandom.create(data);
  }
}

export default new VideoProjectService();
