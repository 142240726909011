import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  cloneDeep,
  isEmpty,
  isEqual,
} from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Preloader from '../../UIComponents/Preloader';
import RoundedModalWrapper from '../../UIComponents/RoundedModalWrapper';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import UploaderAssetsPreview from './UploaderAssetsPreview';
import UploaderAssetInfo from './UploaderAssetInfo';
import DoUploadButton from '../Parts/DoUploadButton';
import { getAttributes } from '../../Assets/Assets.redux/Actions/attributeActions';
import {
  createFolderStructureForUpload,
  getFormikErrorsByAssetId,
} from '../assetUploaderHelpers';
import useDebounce from 'utils/customHooks/useDebounce';
import {
  createFolder,
  getAssetDuplicatesAction,
  getStorageData,
  setAssetDuplicatesAction,
  uploadGameAsset,
} from '../../Assets/Assets.redux/Actions/assetActions';
import { uploadCreativeAsset } from '../../Creative/Creative.redux/Actions/assetActions';
import { openUploadProgress } from '../../UploadProgress/UploadProgress.redux/actions';
import { addGeneralMessageAction } from '../../General/GeneralMessages/GeneralMessages.redux/actions';
import { setTriggerForRefreshingMainSearchAction } from '../../MainSearch/MainSearch.redux/actions';
import AssetUploaderContext from '../assetUploaderContext';
import {
  assetsToUploadValidationSchema,
  NULL_VALUE_INDICATOR,
  WITHOUT_FOLDER_PATH,
} from '../assetUploaderConstants';
import { GENERAL_GAME_FOLDERS_NAMES } from 'constants/Game';
import { FAIL_NOTIFICATION_LIVE_TIME } from 'constants/Notifications';
import { CREATIVE_GENERAL_TABS_NAMES } from 'constants/Creatives';
import {
  assetsClassificationPropTypes,
  assetToUploadPropTypes,
  uploadInfoPropTypes,
} from '../Types';

import { ReactComponent as UploadIcon } from 'assets/svg/upload.svg';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import './AssetUploaderForm.scss';


const AssetUploaderForm = ({
  assetsToUpload,
  setAssetsToUpload,
  uploadInfo,
  assetsClassification,
  closeAssetUploader,
  isUserExternal,
}) => {

  const dispatch = useDispatch();
  const selectAssetsToUpload = useContext(AssetUploaderContext);

  const selectedCreativeId = useSelector((state) => state.creatives?.generalCreativeInfo?.id);
  const selectedGameId = useSelector((state) => state.gamesView?.activeStorage?.gameId);

  const assetDuplicates = useSelector((state) => state.assets?.assetDuplicates);

  const emptySelectedAsset = useMemo(() => ({
    typeCat: null,
    description: '',
    ...assetsClassification,
  }), [assetsClassification]);

  const [submitCount, setSubmitCount] = useState(0);
  const [folderStructure, setFolderStructure] = useState([]);
  const [selectedAssetIdsSet, setSelectedAssetIdsSet] = useState(new Set());
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetsThatDoNotPassNamingConvention, setAssetsThatDoNotPassNamingConvention] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const debounceSelectedAsset = useDebounce(selectedAsset, 300);

  useEffect(() => {
    dispatch(getAttributes());
  }, []);

  useEffect(() => {
    setFolderStructure(createFolderStructureForUpload(assetsToUpload));
  }, [assetsToUpload.length]);

  useEffect(() => {
    initSelectedAsset();
  }, [selectedAssetIdsSet.size]);

  useEffect(() => {
    if (debounceSelectedAsset) {
      updateFieldsOfAssetsToUpload();
    }
  }, [debounceSelectedAsset]);

  useEffect(() => {
   if (assetDuplicates.success && !assetDuplicates.success.length) {
     handleSubmit();
   }
  }, [assetDuplicates.success]);

  const initSelectedAsset = () => {
    if (!selectedAssetIdsSet.size) {
      setSelectedAsset(null);
    } else if (selectedAssetIdsSet.size === 1) {
      const neededAsset = assetsToUpload.find((asset) => asset.id === [...selectedAssetIdsSet][0]);

      if (neededAsset) {
        const {
          id,
          category,
          subCategory,
          productionFormat,
          developerFormat,
          typeCat,
          description,
        } = neededAsset;

        setSelectedAsset({
          id,
          category,
          subCategory,
          productionFormat,
          developerFormat,
          typeCat: cloneDeep(typeCat),
          description,
        });
      }
    } else if (selectedAssetIdsSet.size > 1) {
      setSelectedAsset(emptySelectedAsset);
    }
  };

  const getFieldsThatWereChanged = (newObject, oldObject) => {
    return Object.fromEntries(Object
      .entries(newObject)
      .filter(([key, value]) => !isEqual(value, oldObject[key]))
      .map(([key, value]) => {
        if (value === NULL_VALUE_INDICATOR) {
          return [key, null];
        } else if (value && value[0] === NULL_VALUE_INDICATOR) {
          return [key, []];
        } else {
          return [key, value];
        }
      })
    );
  };

  const updateFieldsOfAssetsToUpload = () => {
    let newAssetsToUpload = [];

    if (selectedAssetIdsSet.size === 1) {
      newAssetsToUpload = assetsToUpload.map((assetToUpload) => {
        if (selectedAssetIdsSet.has(assetToUpload.id)) {
          const fieldsThatNeedToChange = getFieldsThatWereChanged(debounceSelectedAsset, assetToUpload);
          return {...assetToUpload, ...cloneDeep(fieldsThatNeedToChange), id: assetToUpload.id};
        }

        return assetToUpload;
      });
    } else if (selectedAssetIdsSet.size > 1) {
      const fieldsThatNeedToChange = getFieldsThatWereChanged(debounceSelectedAsset, emptySelectedAsset);

      newAssetsToUpload = assetsToUpload.map((assetToUpload) => {
        if (selectedAssetIdsSet.has(assetToUpload.id)) {
          return {...assetToUpload, ...cloneDeep(fieldsThatNeedToChange)};
        }

        return assetToUpload;
      });
    }

    setAssetsToUpload(newAssetsToUpload);
  };

  const removeAssetFromUploader = (assetId) => {

    if (selectedAssetIdsSet.size === 1 && assetId === selectedAsset?.id) {
      setSelectedAssetIdsSet(new Set());
    }

    if (selectedAssetIdsSet.size === 2) {
      selectedAssetIdsSet.delete(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
      const neededAsset = assetsToUpload.find((asset) => asset.id === [...selectedAssetIdsSet][0]);
      setSelectedAsset(neededAsset);
    }

    if (selectedAssetIdsSet.size > 2) {
      selectedAssetIdsSet.delete(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }

    setAssetsToUpload(assetsToUpload.filter((assetToUpload) => assetToUpload.id !== assetId));
  };

  const checkFilesForNamingConvention = () => {
    const { creativeTabName } = uploadInfo;

    if (
      creativeTabName.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.PROOF ||
      creativeTabName.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.FINAL
    ) {
      const assetsThatNotPassNamingConvention = assetsToUpload.filter((asset) => {
        const nameWithoutExtension = asset.name.substring(0, asset.name.lastIndexOf('.')) || asset.name;
        const splitName = nameWithoutExtension.split('_');
        const isSomeSubNameIsEmpty = splitName.some((subName) => !subName);

        return splitName.length !== 10 || splitName.length === 10 && isSomeSubNameIsEmpty;
      });

      assetsThatNotPassNamingConvention.length ?
        setAssetsThatDoNotPassNamingConvention(assetsThatNotPassNamingConvention) :
        checkFilesForDuplicates();
    } else {
      checkFilesForDuplicates();
    }
  };

  const checkFilesForDuplicates = () => {
    const { creativeTabName, gameRootFolder } = uploadInfo;

    if (
      (creativeTabName.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.PROOF ||
      creativeTabName.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.FINAL ||
      gameRootFolder === GENERAL_GAME_FOLDERS_NAMES.DEVELOPER_ASSETS) &&
      !isUserExternal
    ) {
      dispatch(getAssetDuplicatesAction(assetsToUpload.map((asset) => asset.name)));
    } else {
      handleSubmit();
    }
  };

  const createFolders = async (storageId, dirPath, neededFoldersNames) => {
    for (const path of neededFoldersNames) {
      if (path.length) {
        await dispatch(createFolder({dirPath: dirPath ? `${dirPath}/${path}` : path, storageId: parseInt(storageId)}));
      }
    }
  };

  const handleSubmit = async () => {
    const {
      isCreative,
      gameId,
      creativityId,
      storageId,
      dirPath,
    } = uploadInfo;

    let allUploadAssetRequests;
    let updateStorage;

    setIsUploading(true);
    dispatch(openUploadProgress(assetsToUpload.map((asset) => asset.file), isCreative));

    const neededFoldersNames = folderStructure.reduce((result, currentFolder) =>
      currentFolder.path !== WITHOUT_FOLDER_PATH ?
        [...result, currentFolder.path] :
        result,
      []);

    const transformedAssetsToUpload = assetsToUpload.map((asset) => {
      let filePath = dirPath ?? '';

      if (asset.path) {
        filePath = dirPath ? `${dirPath}/${asset.path}` : asset.path;
      }

      const attributesJSON = JSON.stringify({
        ...(asset.typeCat ? {type: asset.typeCat} : {}),
      });

      return {
        ...asset,
        filePath,
        attributesJSON,
      };
    });

    await createFolders(storageId, dirPath, neededFoldersNames);

    if (isCreative) {
      allUploadAssetRequests = transformedAssetsToUpload.map((asset) => {
        return dispatch(uploadCreativeAsset(
          asset,
          gameId,
          creativityId,
          storageId
        ));
      });

      if (selectedCreativeId === creativityId) {
        updateStorage = () => dispatch(getStorageData(storageId, dirPath));
      }
    } else {
      allUploadAssetRequests = transformedAssetsToUpload.map((asset) => {
        return dispatch(uploadGameAsset(
          asset,
          gameId,
          storageId
        ));
      });

      if (selectedGameId === gameId) {
        updateStorage = () => {
          dispatch(getStorageData(storageId, dirPath));
        };
      }
    }

    await Promise.all(allUploadAssetRequests);
    setIsUploading(false);
    dispatch(setAssetDuplicatesAction(null));
    dispatch(setTriggerForRefreshingMainSearchAction());
    closeAssetUploader();

    if (updateStorage) {
      await updateStorage();
    }
  };

  const displayErrorMessage = (errors) => {
    if (!isEmpty(errors)) {
      dispatch(addGeneralMessageAction({
        error: true,
        text: 'Upload - failed',
        description: 'Some files can’t be uploaded. Please, check file name or required fields.',
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    }
  };

  return (
    <RoundedModalWrapper
      isOpen
      title='Upload files'
      titleFontSize={24}
      padding='40px 50px 32px'
      withoutScroll
      onClose={closeAssetUploader}
    >
      <Formik
        initialValues={assetsToUpload}
        validationSchema={assetsToUploadValidationSchema}
        validateOnMount
        validateOnBlur
        enableReinitialize
        onSubmit={checkFilesForNamingConvention}
      >
        {({
            values,
            errors,
          }) => {
          const loadingState = isUploading || assetDuplicates.loading;

          return (
            <Form
              className={classnames('asset-uploader-form', {
                'loading': loadingState,
              })}
            >
              {loadingState ?
                <Preloader /> :
                <>
                  <div className='asset-uploader-form__main'>
                    <UploaderAssetsPreview
                      submitCount={submitCount}
                      errors={getFormikErrorsByAssetId(selectedAssetIdsSet, values, errors)}
                      folderStructure={folderStructure}
                      selectedAssetIdsSet={selectedAssetIdsSet}
                      setSelectedAssetIdsSet={setSelectedAssetIdsSet}
                      removeAssetFromUploader={removeAssetFromUploader}
                    />

                    <UploaderAssetInfo
                      errors={getFormikErrorsByAssetId(selectedAssetIdsSet, values, errors, true)}
                      selectedAsset={selectedAsset}
                      setSelectedAsset={setSelectedAsset}
                    />
                  </div>

                  <div className='asset-uploader-form__buttons-container'>
                    <DoUploadButton onClick={selectAssetsToUpload} count={assetsToUpload.length} />

                    <div className='asset-uploader-form__buttons-container'>
                      <button
                        className='asset-uploader-form__button'
                        onClick={closeAssetUploader}
                      >
                        <CloseIcon className='icon-inside-button' />
                        <span>Cancel</span>
                      </button>

                      <button
                        type='submit'
                        onClick={() => {
                          setSubmitCount(submitCount + 1);
                          displayErrorMessage(errors);
                          setSelectedAssetIdsSet(new Set());
                        }}
                        className='asset-uploader-form__button'
                      >
                        <UploadIcon className='icon-inside-button' />
                        <span>Upload</span>
                      </button>
                    </div>
                  </div>
                </>
              }
            </Form>
          );
        }}
      </Formik>

      <ConfirmRoundModal
        isOpen={!!assetsThatDoNotPassNamingConvention.length}
        title='Naming convention warning'
        text='You are trying to upload files that do not meet naming convention. Are you sure you still want to upload them?'
        agreeText='yes, upload'
        agreeIcon={<UploadIcon className='icon-inside-button' />}
        onAgree={() => {
          checkFilesForDuplicates(assetsThatDoNotPassNamingConvention);
          setAssetsThatDoNotPassNamingConvention([]);
        }}
        onClose={() => setAssetsThatDoNotPassNamingConvention([])}
      >
        <div className='asset-uploader-form__naming-convention-list'>
          {assetsThatDoNotPassNamingConvention.map((asset, assetIndex, assets) => (
            <span
              key={asset.id}
              className='asset-uploader-form__naming-convention-list-item'
            >
              {asset.name}
              {assetIndex === assets.length - 1 ? '' : ','}
            </span>
          ))}
        </div>
      </ConfirmRoundModal>

      <ConfirmRoundModal
        isOpen={!!assetDuplicates.success?.length}
        title='File already exists in Pixel'
        text={<>File <span className='asset-uploader-form__duplicate-names'>{assetDuplicates.success?.join(', ')}</span> already exists in &quot;Pixel&quot;. Add file anyway?</>}
        agreeText='yes, add'
        agreeIcon={<AddIcon className='icon-inside-button' />}
        onAgree={() => {
          dispatch(setAssetDuplicatesAction([]));
        }}
        onClose={() => dispatch(setAssetDuplicatesAction(null))}
      >
      </ConfirmRoundModal>
    </RoundedModalWrapper>
  );
};

AssetUploaderForm.propTypes = {
  assetsToUpload: PropTypes.arrayOf(assetToUploadPropTypes),
  setAssetsToUpload: PropTypes.func.isRequired,
  uploadInfo: uploadInfoPropTypes,
  assetsClassification: assetsClassificationPropTypes,
  closeAssetUploader: PropTypes.func.isRequired,
  isUserExternal: PropTypes.bool,
};

export default AssetUploaderForm;
