import {
  RANDOMIZER_STRUCTURES_SUCCESS,
  RANDOMIZE_PROJECTS_START,
  RANDOMIZE_PROJECTS_FAIL,
  RANDOMIZE_PROJECTS_SUCCESS,
  RANDOMIZE_PROJECTS_RESET,
  SET_RENDER_STATUS_RANDOMIZER,
  SET_RANDOMIZER_GROUP_ID,
  CLEAN_UP_RANDOMIZER,
} from './actionsTypes';
import { CREATE_VIDEO_PROJECT_RANDOMIZER } from '../../MainPage/MainPage.redux/actionTypes';


const initState = {
  randomizerStructures: [],
  randomizeLoading: false,
  randomizeSuccess: false,
  randomizeFail: false,
  randomizerRenderStatus: {
    total: 0,
    totalFinished: 0,
  },
  randomizerGroupId: '',
  randomizerProjectInfo: {
    gameId: null,
    gameName: '',
    title: '',
  },
};


const randomizerReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RANDOMIZER_STRUCTURES_SUCCESS:
      return {
        ...state,
        randomizerStructures: payload,
      };

    case RANDOMIZE_PROJECTS_START:
      return {
        ...state,
        randomizeLoading: true,
        randomizeSuccess: false,
        randomizeFail: false,
      };

    case RANDOMIZE_PROJECTS_SUCCESS:
      return {
        ...state,
        randomizeLoading: false,
        randomizeSuccess: true,
        randomizeFail: false,
      };

    case RANDOMIZE_PROJECTS_FAIL:
      return {
        ...state,
        randomizeLoading: false,
        randomizeSuccess: false,
        randomizeFail: true,
      };

    case RANDOMIZE_PROJECTS_RESET:
      return {
        ...state,
        randomizeSuccess: false,
        randomizeFail: false,
      };

    case SET_RENDER_STATUS_RANDOMIZER:
      return {
        ...state,
        randomizerRenderStatus: payload,
      };

    case SET_RANDOMIZER_GROUP_ID:
      return {
        ...state,
        randomizerGroupId: payload,
      };

    case CLEAN_UP_RANDOMIZER:
      return initState;

    case CREATE_VIDEO_PROJECT_RANDOMIZER:
      return {
        ...state,
        randomizerProjectInfo: payload,
      };

    default:
      return {
        ...state,
      };
  }

};

export default randomizerReducer;
