import { CREATIVE_GENERAL_TABS_NAMES } from '../../constants/Creatives';
import { sortBy } from 'lodash';

export const sortCreativeTabs = (tabs) => {
  const generalTabs =  sortBy(tabs.filter((tab) => Object.values(CREATIVE_GENERAL_TABS_NAMES).includes(tab.name.toUpperCase())), 'name');
  const notGeneralTabs = sortBy(tabs.filter((tab) => !Object.values(CREATIVE_GENERAL_TABS_NAMES).includes(tab.name.toUpperCase())), 'name');

  return [
    ...generalTabs,
    ...notGeneralTabs,
  ];
};
