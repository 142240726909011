import history from 'utils/history';
import {
  GET_PROFILE_FAIL,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,

  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,

  CREATE_CREATIVE_API_KEY_START,
  CREATE_CREATIVE_API_KEY_SUCCESS,
  CREATE_CREATIVE_API_KEY_FAIL,

  REVOKE_CREATIVE_API_KEY_START,
  REVOKE_CREATIVE_API_KEY_SUCCESS,
  REVOKE_CREATIVE_API_KEY_FAIL,

  HIDE_CREATIVE_API_KEY,
} from './actionTypes';
import UserService from 'services/User';
import { USER_ROLES } from 'constants/General';


function getCurrentUserProfileStart() {
  return {
    type: GET_PROFILE_START,
  };
}

function getCurrentUserProfileSuccess(profile) {
  return {
    type: GET_PROFILE_SUCCESS,
    profile,
  };
}

function getCurrentUserProfileFail(error) {
  return {
    type: GET_PROFILE_FAIL,
    error: error.message,
  };
}

export const getCurrentUserProfile = () => (dispatch) => {
  dispatch(getCurrentUserProfileStart());

  return UserService.getCurrentUserProfile()
    .then((data) => {
      const {
        role,
      } = data;
      const currentRoleId = role.id;
      const currentRole = USER_ROLES.find((item) => {
        return (currentRoleId === item.id);
      });
      const profile = Object.assign({}, data, {
        role: currentRole.title,
        roleId: role.id,
      });
      dispatch(getCurrentUserProfileSuccess(profile));
    })
    .catch((error) => {
      dispatch(getCurrentUserProfileFail(error));
    });
};

function getUsersListRequest() {
  return {
    type: GET_USERS_LIST_REQUEST,
  };
}

function getUsersListSuccess(users) {
  return {
    type: GET_USERS_LIST_SUCCESS,
    users,
  };
}

function getUsersListFail(error) {
  return {
    type: GET_USERS_LIST_FAIL,
    error: error.message,
  };
}

export const getUsersList = (params) => (dispatch) => {
  dispatch(getUsersListRequest());

  return UserService.getUsersList(params)
    .then((res) => {
      dispatch(getUsersListSuccess(res));
    })
    .catch((error) => {
      dispatch(getUsersListFail(error));
    });
};

export const createCreativeApiKey = () => (dispatch) => {
  dispatch({
    type: CREATE_CREATIVE_API_KEY_START,
  });
  UserService.createCreativeApiKey()
    .then((res) => {
      dispatch({
        type: CREATE_CREATIVE_API_KEY_SUCCESS,
        token: res.token,
      });
    })
    .catch((error) => {
      dispatch({
        type: CREATE_CREATIVE_API_KEY_FAIL,
        error: error.message,
      });
    });
};

export const revokeCreativeApiKey = () => (dispatch) => {
  dispatch({
    type: REVOKE_CREATIVE_API_KEY_START,
  });
  UserService.removeCreativeApiToken()
    .then(() => {
      dispatch({
        type: REVOKE_CREATIVE_API_KEY_SUCCESS,
      });
    })
    .catch((error) => {
      dispatch({
        type: REVOKE_CREATIVE_API_KEY_FAIL,
        error: error.message,
      });
    });
};

export const hideCreativeApiKey = () => (dispatch) => {
  dispatch({
    type: HIDE_CREATIVE_API_KEY,
  });
};
