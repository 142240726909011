import gradients from '../gradients.module.scss';

const trackHeight = 6;

export const MuiSlider = {
  root: {
    height: `${trackHeight}px`,
    padding: 0,
  },
  thumb: {
    display: 'none',
  },
  rail: {
    height: `${trackHeight}px`,
    backgroundColor: 'white',
    opacity: 1,
  },
  track: {
    height: `${trackHeight}px`,
    backgroundImage: gradients.blueToPink,
  },
};
