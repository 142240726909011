import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSharedAssetsAction } from '../Assets/Assets.redux/Actions/assetActions';
import Preloader from '../UIComponents/Preloader';


const Sharing = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSharedAssets();
  }, []);

  const getSharedAssets = async () => {
    await dispatch(getSharedAssetsAction({
      '#': location.search.substring(1),
    }));
    setIsLoading(false);
  };

  if (isLoading) {
    return <Preloader />;
  }

  return null;
};

export default Sharing;
