import { AD_CREATE_BANNER_ITEM_TYPES } from 'constants/CreativeTool';
const imageOverlayMaxSize = 200;

/**
 * @param {number} id
 * @param {string} type
 * @param {string} label
 * @param {object} image
 * @param {object} styles
 */
class ImageOverlayModel {
  constructor(data, label) {
    this.id = parseInt(data._meta.id);
    this.type = AD_CREATE_BANNER_ITEM_TYPES.IMAGE;
    this.label = label;
    this.image = {
      id: parseInt(data._meta.id),
      url: `/file-store/${data.storage_uuid.raw}/${data.path.raw}`,
      thumbnail: data.medium_preview_url.raw,
      name: data.asset_name.raw,
    };
    this.styles = {
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: Number(data.width.raw) > Number(data.height.raw) ? `${imageOverlayMaxSize}px` : Number(data.width.raw) / Number(data.height.raw) * imageOverlayMaxSize + 'px',
      height: Number(data.width.raw) < Number(data.height.raw) ? `${imageOverlayMaxSize}px` : Number(data.height.raw) / Number(data.width.raw) * imageOverlayMaxSize + 'px',
      transform: 'rotate(0deg) scale(1)',
      'z-index': '1',
    };
  }
}

export default ImageOverlayModel;
