import { cloneDeep, omit } from 'lodash';
import {
  GET_GAMES_FAIL,
  GET_GAMES_START,
  GET_GAMES_SUCCESS,
} from 'components/Game/Game.redux/actionTypes';
import {
  GET_STORAGE_DATA_REQUEST,
  GET_STORAGE_DATA_SUCCESS,
  GET_STORAGE_DATA_FAIL,
} from 'components/Assets/Assets.redux/actionTypes';
import {
  GAMES_VIEW_RESET_STORAGE_PATH,
  GAMES_VIEW_SET_ACTIVE_STORAGE,
} from './actionsTypes';
import { ROOT_DIR_PATH } from '../../mainSearchConstants';

const initState = {
  games: {
    loading: false,
    success: [],
    error: null,
  },
  loadingStorage: false,
  errorGetStorage: null,
  storages: {},
  activeStorage: {
    gameId: null,
    gameName: '',
    storageId: null,
    dirPath: '',
  },
};

const gamesViewReducer = (state = initState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case GET_GAMES_START:
      return {
        ...state,
        games: {
          loading: true,
          success: [],
          error: null,
        },
      };

    case GET_GAMES_SUCCESS:
      return {
        ...state,
        games: {
          loading: false,
          success: action.games,
          error: null,
        },
      };

    case GET_GAMES_FAIL:
      return {
        ...state,
        games: {
          loading: false,
          success: [],
          error: action.error,
        },
      };

    case GET_STORAGE_DATA_REQUEST:
      return {
        ...state,
        loadingStorage: true,
        errorGetStorage: null,
      };

    case GET_STORAGE_DATA_SUCCESS: {
      const {
        storageId,
        dirPath,
        assets,
      } = payload;

      const newStorages = cloneDeep(state.storages);

      newStorages[storageId] = {
        ...newStorages[storageId],
        [dirPath === '' ? ROOT_DIR_PATH : dirPath]: assets,
      };

      return {
        ...state,
        storages: newStorages,
        loadingStorage: false,
        errorGetStorage: null,
      };
    }

    case GET_STORAGE_DATA_FAIL:
      return {
        ...state,
        loadingStorage: false,
        errorGetStorage: error,
      };

    case GAMES_VIEW_RESET_STORAGE_PATH: {
      const {
        storageId,
        path,
      } = payload;

      const newStorages = cloneDeep(state.storages);
      const neededStorage = newStorages[storageId];
      const storagePathsToRemove = Object.keys(neededStorage).filter((key) => key?.includes(path));
      newStorages[storageId] = omit(neededStorage, storagePathsToRemove);

      return {
        ...state,
        storages: newStorages,
      };
    }
    case GAMES_VIEW_SET_ACTIVE_STORAGE: {
      return {
        ...state,
        activeStorage:
          {
            ...state.activeStorage,
            ...payload,
          } ||
          {
            gameId: null,
            gameName: '',
            storageId: null,
            dirPath: '',
          },
      };
    }

    default:
      return state;
  }
};

export default gamesViewReducer;
