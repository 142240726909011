// Collection actions
export const PRESET_COLLECTION_ID = 'PRESET_COLLECTION_ID';

export const GET_COLLECTION_START = 'GET_COLLECTION_START';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAIL = 'GET_COLLECTION_FAIL';

export const MANAGE_COLLECTION_ASSETS_START = 'MANAGE_COLLECTION_ASSETS_START';
export const MANAGE_COLLECTION_ASSETS_SUCCESS = 'MANAGE_COLLECTION_ASSETS_SUCCESS';
export const MANAGE_COLLECTION_ASSETS_FAIL = 'MANAGE_COLLECTION_ASSETS_FAIL';

export const GET_COLLECTION_ASSETS_START = 'GET_COLLECTION_ASSETS_START';
export const GET_COLLECTION_ASSETS_SUCCESS = 'GET_COLLECTION_ASSETS_SUCCESS';
export const GET_COLLECTION_ASSETS_FAIL = 'GET_COLLECTION_ASSETS_FAIL';

// Collections actions
export const SET_PRIVATE_OR_PUBLIC_COLLECTIONS = 'SET_PRIVATE_OR_PUBLIC_COLLECTIONS';

export const GET_ALL_USER_COLLECTIONS_SUCCESS = 'GET_ALL_USER_COLLECTIONS_SUCCESS';

export const GET_PRIVATE_COLLECTIONS_START = 'GET_PRIVATE_COLLECTIONS_START';
export const GET_PRIVATE_COLLECTIONS_SUCCESS = 'GET_PRIVATE_COLLECTIONS_SUCCESS';
export const GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS = 'GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS';
export const GET_PRIVATE_COLLECTIONS_FAIL = 'GET_PRIVATE_COLLECTIONS_FAIL';

export const GET_PUBLIC_COLLECTIONS_START = 'GET_PUBLIC_COLLECTIONS_START';
export const GET_PUBLIC_COLLECTIONS_SUCCESS = 'GET_PUBLIC_COLLECTIONS_SUCCESS';
export const GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS = 'GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS';
export const GET_PUBLIC_COLLECTIONS_FAIL = 'GET_PUBLIC_COLLECTIONS_FAIL';

export const SET_COLLECTIONS_SORT_OPTION = 'SET_COLLECTIONS_SORT_OPTION';
export const SET_COLLECTIONS_PAGING_STATUS = 'SET_COLLECTIONS_PAGING_STATUS';
export const SET_COLLECTIONS_SEARCH_VALUE = 'SET_COLLECTIONS_SEARCH_VALUE';

export const SET_ASSETS_IDS_FOR_COLLECTION = 'SET_ASSETS_IDS_FOR_COLLECTION';
export const SET_COLLECTION_CREATION_STATE = 'SET_COLLECTION_CREATION_STATE';
export const SET_COLLECTION_TO_EDIT = 'SET_COLLECTION_TO_EDIT';

export const MANAGE_COLLECTION_START = 'MANAGE_COLLECTION_START';
export const MANAGE_COLLECTION_SUCCESS = 'MANAGE_COLLECTION_SUCCESS';
export const MANAGE_COLLECTION_FAIL = 'MANAGE_COLLECTION_FAIL';
export const MANAGE_COLLECTION_RESET = 'MANAGE_COLLECTION_RESET';