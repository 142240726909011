/**
 * @param {number} id
 * @param {string} email
 * @param {string} status
 * @param {object} permissions
 */
class ExternalUserModel {
  constructor(data, games) {
    this.id = data.id;
    this.email = data.email;
    this.status = data.googleId ? 'approved' : 'pending';
    this.permissions = {
      ...data.externalPermission.tools,
      games: this.transformUserGames(data.externalPermission.games, games),
    };
  }

  transformUserGames(userGames, allGames) {
    return allGames?.map((game) => {
      const findInUserGames = userGames.find((userGame) => userGame.gameId === game.id);

      if (findInUserGames) {
        return {
          id: game.id,
          name: game.name,
          enabled: true,
          access: findInUserGames.access,
        };
      } else {
        return game;
      }

    });
  }
}

export default ExternalUserModel;
