import {
  SET_CURRENT_TAB_CREATIVE,

  CREATE_NEW_TAB_REQUEST,
  CREATE_NEW_TAB_SUCCESS,
  CREATE_NEW_TAB_FAIL,

  RENAME_TAB_REQUEST,
  RENAME_TAB_SUCCESS,
  RENAME_TAB_FAIL,

  DELETE_TAB_REQUEST,
  DELETE_TAB_SUCCESS,
  DELETE_TAB_FAIL,
} from '../actionTypes';
import Creative from 'services/Creative';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const setCurrentTabCreative = (tab) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TAB_CREATIVE,
    payload: tab,
  });
};

export const createNewTab = (data) => (dispatch) => {
  dispatch({
    type: CREATE_NEW_TAB_REQUEST,
  });

  return Creative.createTab(data)
    .then(() => {
      dispatch({
        type: CREATE_NEW_TAB_SUCCESS,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tab.success.create.text,
        description: notifications.tab.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: CREATE_NEW_TAB_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tab.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const renameTab = (tabId, data) => (dispatch) => {
  dispatch({
    type: RENAME_TAB_REQUEST,
  });

  return Creative.renameTab(tabId, data)
    .then(() => {
      dispatch({
        type: RENAME_TAB_SUCCESS,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tab.success.update.text,
        description: notifications.tab.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: RENAME_TAB_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tab.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteTab = (id) => (dispatch) => {
  dispatch({
    type: DELETE_TAB_REQUEST,
  });

  return Creative.removeTab(id)
    .then(() => {
      dispatch({
        type: DELETE_TAB_SUCCESS,
        payload: id,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tab.success.delete.text,
        description: notifications.tab.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: DELETE_TAB_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tab.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
