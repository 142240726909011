import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Preloader from '../Preloader';
import ModalWrapper from '../ModalWrapper';
import HeaderWithLine from '../HeaderWithLine';
import { makeFieldValidation } from 'utils/utils';

import './CreateOrEditInputFieldModal.scss';


const CreateOrEditInputFieldModal = ({
  isOpen,
  type,
  header,
  subheader,
  currentValue,
  placeholderText,
  validationPattern,
  validationText,
  validationTextForEmptyCase,
  onCancel,
  onAccept,
  loading,
  loadingText,
  textTransform,
}) => {

  const [value, setValue] = useState('');
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (type === 'edit') {
      setValue(currentValue);
    } else {
      setValue('');
    }
  }, [currentValue]);

  const handleClose = () => {
    setValue('');
    setValidation(null);
    onCancel();
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const handleBlur = (value) => {
    let validation = makeFieldValidation(
      value,
      validationPattern,
      validationText
    );

    if (!value.trim().length) {
      validation = validationTextForEmptyCase;
    }

    setValidation(validation);
  };

  const handleEdit = () => {
    if (!validation) {
      onAccept(value);
      setValue('');
      setValidation(null);
    }
  };

  return (
    <ModalWrapper open={isOpen} onClose={handleClose}>
      <div
        className={classnames('create-or-edit-field-modal-content', {
          'loading': loading,
          'withSubheader': subheader,
        })}
      >
        <HeaderWithLine header={header} />

        {loading ?
          <Preloader preloaderText={loadingText} /> :
          <>
            <div className='create-or-edit-field-modal-content__input-holder'>

              {subheader &&
                <p className='create-or-edit-field-modal-content__subheader'>
                  {subheader}
                </p>
              }

              <input
                className={classnames('create-or-edit-field-modal-content__input', {
                  'error': validation,
                })}
                style={{textTransform}}
                placeholder={placeholderText}
                onChange={(event) => handleChange(event.target.value)}
                onBlur={(event) => handleBlur(event.target.value)}
                value={value || ''}
              />

              {validation &&
                <p className='create-or-edit-field-modal-content__input-error'>
                  {validation}
                </p>
              }
            </div>

            <div className='create-or-edit-field-modal-content__buttons-container'>
              <button
                className='create-or-edit-field-modal-content__button'
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className='create-or-edit-field-modal-content__button'
                onClick={handleEdit}
                disabled={value === currentValue || !value}
              >
                {type === 'create' ? 'Add' : 'Save'}
              </button>
            </div>
          </>
        }
      </div>
    </ModalWrapper>
  );
};

CreateOrEditInputFieldModal.defaultProps = {
  placeholderText: 'Enter new value',
  validationText: 'The input value is invalid',
  validationTextForEmptyCase: 'Field can\'t contain only spaces',
  textTransform: 'uppercase',
};

CreateOrEditInputFieldModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  currentValue: PropTypes.string,
  placeholderText: PropTypes.string,
  validationPattern: PropTypes.instanceOf(RegExp),
  validationText: PropTypes.string,
  validationTextForEmptyCase: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  textTransform: PropTypes.string,
};

export default CreateOrEditInputFieldModal;
