import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchInput from '../SearchInput';
import NoSearchResults from '../NoSearchResults';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { VIDEO_CREATE_ASSETS_TYPES } from 'constants/CreativeTool';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  AddCircle as AddCircleIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import './CustomSelect.scss';


const CustomSelect = ({
  label,
  value,
  data,
  handleSelect,
  handleBlur,
  handleTouch,
  name,
  error,
  hideErrorText,
  withSearch,
  searchWordLabel,
  withClear,
  addNewOptionButtonName,
  handleAddNewOption,
  placeholder,
  disabled,
  directionTop,
  width,
  pink,
}) => {

  const [isDropDown, setIsDropDown] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [options, setOptions] = useState([]);

  const valueToDisplay = value?.name || value?.value;

  useEffect(() => {
    if (searchWord) {
      setOptions(data.filter((dataItem) => new RegExp(searchWord, 'i').test(dataItem[searchWordLabel])));
    } else {
      setOptions([...data]);
    }
  }, [searchWord, data]);

  const toggleDropDown = () => {
    setIsDropDown(!isDropDown);
    handleTouch();
  };

  const handleOptionClick = (dataItem) => {
    handleSelect(dataItem);
    setIsDropDown(false);
    setSearchWord('');
  };

  const handleClearValue = (event) => {
    event.stopPropagation();
    handleSelect(null);
    setIsDropDown(false);
  };

  const handleClickAway = () => {
    setIsDropDown(false);
    handleBlur();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          className={classnames('custom-select', {
            'disabled': disabled,
            'active': isDropDown,
            'error': error,
            'pink': pink,
          })}
          style={{
            width,
          }}
        >

          <div
            className={classnames('custom-select__field', {
              'withColor': name === VIDEO_CREATE_ASSETS_TYPES.COLORS,
              'withLabel': label,
              'withClear': withClear,
            })}
            onClick={toggleDropDown}
          >
            {name === VIDEO_CREATE_ASSETS_TYPES.COLORS && <div style={{backgroundColor: value?.value}} className='custom-select__color-example' />}

            {label &&
              <label className='custom-select__label'>
                {label}
              </label>
            }

            <h4
              style={{fontFamily: name === VIDEO_CREATE_ASSETS_TYPES.FONTS && value?.name}}
              className='custom-select__field-title'
            >
              {value ?
                valueToDisplay :
                <span className='custom-select__placeholder'>{!label && placeholder}</span>
              }
            </h4>
            <CloseIcon
              className={classnames('custom-select__clean-value-icon', {
                'active': withClear,
              })}
              onClick={handleClearValue}
            />

            <div
              className={classnames('custom-select__expand-icon', {
                'error': error,
              })}
            >
              {isDropDown ?
                <ExpandLessIcon /> :
                <ExpandMoreIcon />
              }
            </div>
          </div>

          <div
            className={classnames('custom-select__drop-down', {
              'active': isDropDown,
              'top': directionTop,
              'withSearch': withSearch,
            })}
          >
            {(withSearch && !!data.length) &&
              <SearchInput
                searchWord={searchWord}
                handleSearch={(searchValue) => setSearchWord(searchValue)}
                withClear
                pink={pink}
              />
            }

            <div
              className={classnames('custom-select__options', {
                'withSearch': withSearch,
              })}
            >
              {options.map((option) =>
                <div
                  key={option.id}
                  className='custom-select__option'
                  onClick={() => handleOptionClick(option)}
                >
                  {name === VIDEO_CREATE_ASSETS_TYPES.COLORS && <div style={{backgroundColor: option.value}} className='custom-select__color-example' />}
                  <p
                    className={classnames('custom-select__option-text', {
                      'active': option.id === value?.id,
                    })}
                    style={{fontFamily: name === VIDEO_CREATE_ASSETS_TYPES.FONTS && option.name}}
                  >
                    {option.name ? option.name : option.value}
                  </p>
                </div>
              )}

              {!options.length &&
                <NoSearchResults
                  height={50}
                  message={!searchWord ? 'no options' : 'no results found'}
                />
              }

              {addNewOptionButtonName &&
                <div
                  onClick={handleAddNewOption}
                  className='custom-select__option custom-select__option--add-new'
                >
                  <AddCircleIcon className='custom-select__add-circle-icon' />
                  <p className='custom-select__option-text'>
                    {addNewOptionButtonName}
                  </p>
                </div>
              }
            </div>
          </div>
        </div>

        {(error && !hideErrorText) &&
          <p className='custom-select__error-text'>{error}</p>
        }
      </div>
    </ClickAwayListener>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.arrayOf(PropTypes.object),
  handleSelect: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
  handleTouch: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.string,
  hideErrorText: PropTypes.bool,
  withSearch: PropTypes.bool,
  searchWordLabel: PropTypes.string,
  withClear: PropTypes.bool,
  addNewOptionButtonName: PropTypes.string,
  handleAddNewOption: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  directionTop: PropTypes.bool,
  width: PropTypes.string,
  pink: PropTypes.bool,
};

CustomSelect.defaultProps = {
  handleBlur: () => {},
  handleTouch: () => {},
  searchWordLabel: 'name',
  placeholder: 'select',
};

export default CustomSelect;
