import cloneDeep from 'lodash/cloneDeep';
import {
  VIDEO_PROJECT_CARD_DELETE,
  VIDEO_PROJECT_CARD_UPDATE,
} from './actionTypes';
import { VIDEO_PROJECT_DISCARD } from '../VideoProjectElements/actionTypes';
import { CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT } from '../../VideoEditor.redux/actionTypes';
import { cardsEmptyStructure } from '../../videoProjectEmptyStructure';


const initState = cloneDeep(cardsEmptyStructure);

const videoProjectCardsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case VIDEO_PROJECT_CARD_UPDATE:
      return {
        ...state,
        [payload.cardName]: payload.updatedCard,
      };

    case VIDEO_PROJECT_CARD_DELETE:
      return {
        ...state,
        [payload.cardName]: cardsEmptyStructure[payload.cardName],
      };

    case CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT:
    case VIDEO_PROJECT_DISCARD:
      return initState;

    default:
      return state;
  }
};

export default videoProjectCardsReducer;
