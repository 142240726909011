import { VIDEO_EXTENSIONS } from 'constants/CreativeTool';

/**
 * @param {number} id
 * @param {string} name
 * @param {string} date
 * @param {string} author
 * @param {string} url
 * @param {string} thumbnail
 * @param {string} format
 */
class GameVideoAdCreateModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.path?.slice(1);
    this.date = data.createdAt;
    this.author = data.storage.tabs.creativity.user?.name;
    this.url = `/file-store/${data.storage.uuid}${data.path}`;
    this.thumbnail = data.smallPreviewUrl;
    this.format = data.metadata.width === data.metadata.height ? VIDEO_EXTENSIONS.SQUARE : VIDEO_EXTENSIONS.LANDSCAPE;
  }
}

export default GameVideoAdCreateModel;
