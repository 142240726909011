import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class PodCapacity {
  constructor() {
    this.podCapacity = FeathersClient.service(`${API_PREFIX}pod/capacity`);
    this.leadership = FeathersClient.service(`${API_PREFIX}pod/directors`);
  }

  /**
   * Get POD capacity
   * @param {number} limit
   * @param {number} skip
   */
  getPodCapacity({limit, skip}) {
    const query = {
      $sort: {
        id: 1,
      },
    };

    if (limit) {
      query['$limit'] = limit;
    }

    if (skip) {
      query['$skip'] = skip;
    }

    return this.podCapacity.find({ query });
  }

  /**
   * create POD capacity
   * @param {object} newPodCapacity
   */
  createPodCapacity(newPodCapacity) {
    return this.podCapacity.create(newPodCapacity);
  }

  /**
   * Update POD capacity
   * @param {number} id
   * @param {object} updatedPodCapacity
   */
  updatePodCapacity(id, updatedPodCapacity) {
    return this.podCapacity.patch(id, updatedPodCapacity);
  }

  /**
   * Delete POD capacity
   * @param {number} id
   */
  deletePodCapacity(id) {
    return this.podCapacity.remove(id);
  }

  /**
   * Get leadership
   */
  getLeadership() {
    return this.leadership.find();
  }

  /**
   * Add user to leadership
   * @param {number} userId
   */
  addUserToLeadership(userId) {
    return this.leadership.create({ userId });
  }

  /**
   * Remove user from leadership
   * @param {number} userId
   */
  removeUserFromLeadership(userId) {
    return this.leadership.remove(userId);
  }
}

export default new PodCapacity();
