import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialModal from '@material-ui/core/Modal';

import ModalCloseButton from '../ModalCloseButton';
import { childrenPropType } from '../../../types/general';

import styles from './styles.module.scss';

const Modal = ({ className, ...props }) => (
  <MaterialModal disableRestoreFocus={true} {...props} className={classNames([styles.modal, className])}>
    <div className={styles.container}>
      <ModalCloseButton onClick={props.onClose} />

      {props.children}
    </div>
  </MaterialModal>
);

Modal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: childrenPropType.isRequired,
};

export default Modal;
