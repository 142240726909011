import {
  SET_IS_MOBILE_STATUS,
  SET_ACTIVE_PLAYER_ID,
  RESTRICT_ACCESS,
} from './actionTypes';

export const setMobileSizeStatus = (isMobile) => ({
  type: SET_IS_MOBILE_STATUS,
  isMobile,
});

export const setActivePlayerIdType = (activePlayerId) => ({
  type: SET_ACTIVE_PLAYER_ID,
  activePlayerId,
});

export const restrictPageAccess = (payload) => ({
  type: RESTRICT_ACCESS,
  payload,
});
