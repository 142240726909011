import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class UserService {
  constructor() {
    this.syncJira = FeathersClient.service(`${API_PREFIX}sync-jira`);
    this.currentUser = FeathersClient.service(`${API_PREFIX}users/current`);
    this.users = FeathersClient.service(`${API_PREFIX}users`);
    this.creativeApiKey = FeathersClient.service(`${API_PREFIX}creative-api-key`);
  }

  syncJiraPermission() {
    return this.syncJira.create({});
  }

  logOut = () => {
    return FeathersClient.authentication.removeAccessToken();
  };

  authentication = () => {
    return FeathersClient.authenticate()
      .then((authenticationResult) => {
        return authenticationResult;
      });
  };

  getCurrentUserProfile() {
    return this.currentUser.find();
  }

  getUsersList(params) {
    const { limit, jiraUsers } = params || {};

    const query = {};

    if (limit) {
      query['$limit'] = limit;
    }

    if (jiraUsers) {
      query['jiraUsers'] = '';
    }

    return this.users.find({ query });
  }

  createCreativeApiKey() {
    return this.creativeApiKey.create({});
  }

  removeCreativeApiToken() {
    return this.creativeApiKey.remove(0);
  }
}

export default new UserService();
