import FormatInFilterModel from './FormatInFilterModel';

/**
 * @param {number} id
 * @param {string} name
 * @param {boolean} open
 * @param {object[]} formats
 */
class CategoryModel {
  constructor(data, checkedFormats) {
    this.id = data.id;
    this.name = data.name;
    this.open = false;
    this.formats = data.formats.map((format) => new FormatInFilterModel(format, checkedFormats));
  }
}

export default CategoryModel;
