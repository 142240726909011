import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import Permission from '../../General/Permissions/Permission';
import GameEditForm from '../../Game/GameEditForm';
import Modal from '../../UIComponents/Modal';
import ExternalUserPermissionWrapper from '../../General/Permissions/ExternalUserPermissionWrapper';
import {
  logOut,
  syncJira,
} from '../../User/User.redux/authActions';
import {
  createCreativeApiKey,
  revokeCreativeApiKey,
  hideCreativeApiKey,
} from '../../User/User.redux/profileActions';
import { clearingGamePreview } from '../../Game/Game.redux/actions';
import {
  PERMISSIONS as P,
  DEPARTMENT_PERMISSION,
  SIDEBAR_SECTIONS,
} from 'constants/General';

import SyncIcon from '@material-ui/icons/Sync';
import MoreIcon from '@material-ui/icons/More';
import { ReactComponent as TiltingPointLogo } from 'assets/svg/logo/logo-white.svg';
import { ReactComponent as AddIcon } from 'assets/svg/plus.svg';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import { ReactComponent as GoogleIcon } from 'assets/svg/logo/google-logo.svg';
import { ReactComponent as DashboardIcon } from 'assets/svg/navigation/dashboard.svg';
import { ReactComponent as VideoToolIcon } from 'assets/svg/navigation/video-tool.svg';
import { ReactComponent as DataToolIcon } from 'assets/svg/navigation/data-tool.svg';
import { ReactComponent as EngineeringToolIcon } from 'assets/svg/navigation/engineering-tool.svg';
import { ReactComponent as CustomerServiceToolIcon } from 'assets/svg/navigation/customer-service-tool.svg';
import { ReactComponent as QAToolIcon } from 'assets/svg/navigation/qa-tool.svg';
import { ReactComponent as ProfileIcon } from 'assets/svg/navigation/profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/svg/navigation/logout.svg';

import styles from './styles.module.scss';


const LeftSidebar = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { avatarUrl } = useSelector((state) => state.user.profile);
  const {
    isFetchingCreativeApiKey,
    isSyncingJira,
    creativeApiKey,
  } = useSelector((state) => state.user);

  const [isNewGameModalOpen, setIsNewGameModalOpen] = useState(false);
  const [isCreativeAPIKeyModalOpen, setIsCreativeAPIKeyModalOpen] = useState(false);
  const [didCopyCreativeAPIKey, setDidCopyCreativeAPIKey] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subAnchorEl, setSubAnchorEl] = useState(null);
  const [section, setSection] = useState('');
  const [subSection, setSubSection] = useState('');

  useEffect(() => {
    dispatch(hideCreativeApiKey());
  }, [hideCreativeApiKey]);

  useEffect(() => {
    setDidCopyCreativeAPIKey(false);
  }, [creativeApiKey]);

  const onCloseNewGameModal = () => {
    dispatch(clearingGamePreview());
    setIsNewGameModalOpen(false);
  };

  const onOpenMenu = (section) => (event) => {
    setAnchorEl(event.currentTarget);
    setSection(section);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
    setSection('');
  };

  const onOpenSubMenu = (section) => (event) => {
    setSubAnchorEl(event.currentTarget);
    setSubSection(section);
  };

  const onCloseSubMenu = () => {
    setSubAnchorEl(null);
    setSubSection('');
  };

  const onCopyApiKey = async () => {
    await navigator.clipboard.writeText(creativeApiKey);
    setDidCopyCreativeAPIKey(true);
  };

  const getMenuContent = (section) => {
    let config;

    switch (section) {
      case SIDEBAR_SECTIONS.ADD_NEW_ITEM:
        config = [
          {
            label: 'Add Game',
            onClick: () => setIsNewGameModalOpen(true),
          },
        ];
        break;
      case SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL:
        config = [
          {
            label: 'SpongeBob',
            onClick: () => history.push('/spongebob-admin'),
          },
          {
            label: 'Retool Dashboard',
            onClick: () => history.push('/retool'),
          },
        ];
        break;
      case SIDEBAR_SECTIONS.CREATIVE_DASHBOARD:
        config = [
          {
            label: 'Automated Uploads to Networks',
            onClick: () => history.push('/automated-uploads'),
          },
          {
            label: 'Studio numbers',
            onClick: () => history.push({
              pathname: '/dashboard',
              search: '?label=studio',
            }),
          },
          {
            label: 'Staff Allocation',
            onClick: () => history.push('/staff-allocation'),
          },
        ];
        break;
      case SIDEBAR_SECTIONS.QA_TOOL:
        config = [
          {
            label: 'Analytics Device',
            onClick: () => history.push('/analytics-device'),
          },
          {
            label: 'Analytics Event',
            onClick: () => history.push('/analytics-event'),
          },
        ];
        break;
      case SIDEBAR_SECTIONS.OTHER:
       return (
         <>
           <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.CREATIVE_TOOLS}>
             <MenuItem
               onClick={() => {
                 onCloseMenu();
                 history.push('/creative-tools');
               }}
             >
               <VideoToolIcon className={styles.menuItemIcon} />
               Creative Tools
             </MenuItem>
           </ExternalUserPermissionWrapper>

           <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.DATA_TOOLS}>
             <Permission
               allowedRoles={P.ALL}
               restrictedDepartments={[
                 DEPARTMENT_PERMISSION.DEVELOPMENT,
                 DEPARTMENT_PERMISSION.CUSTOMER_SUPPORT,
                 DEPARTMENT_PERMISSION.CREATIVE_SERVICES,
               ]}
             >
               <MenuItem
                 onClick={() => {
                   onCloseMenu();
                   history.push('/data-tools');
                 }}
               >
                 <DataToolIcon className={styles.menuItemIcon} />
                 Data Tools
               </MenuItem>
             </Permission>
           </ExternalUserPermissionWrapper>

           <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.GAME_SETTINGS}>
             <MenuItem
               onClick={() => {
                 onCloseMenu();
                 history.push('/game-settings');
               }}
             >
               <EngineeringToolIcon className={styles.menuItemIcon} />
               Game Settings
             </MenuItem>
           </ExternalUserPermissionWrapper>

           <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL}>
             <MenuItem onClick={onOpenSubMenu(SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL)}>
               <CustomerServiceToolIcon className={styles.menuItemIcon} />
               Customer Support Tool
             </MenuItem>
           </ExternalUserPermissionWrapper>

           <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.QA_TOOL}>
             <MenuItem onClick={onOpenSubMenu(SIDEBAR_SECTIONS.QA_TOOL)}>
               <QAToolIcon className={styles.menuItemIcon} />
               QA Tool
             </MenuItem>
           </ExternalUserPermissionWrapper>
         </>
        );
      default:
        config = [];
    }

    return config.map(({ label, onClick }, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          onClick();
          onCloseMenu();
        }}
      >
        {label}
      </MenuItem>
    ));
  };

  const getSubMenuContent = (section) => {
    let config;

    switch (section) {
      case SIDEBAR_SECTIONS.CUSTOMER_SUPPORT_TOOL:
        config = [
          {
            label: 'SpongeBob',
            onClick: () => history.push('/spongebob-admin'),
          },
          {
            label: 'Retool Dashboard',
            onClick: () => history.push('/retool'),
          },
        ];
        break;
      case SIDEBAR_SECTIONS.QA_TOOL:
        config = [
          {
            label: 'Analytics Device',
            onClick: () => history.push('/analytics-device'),
          },
          {
            label: 'Analytics Event',
            onClick: () => history.push('/analytics-event'),
          },
        ];
        break;
      default:
        config = [];
    }

    return config.map(({ label, onClick }, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          onClick();
          onCloseSubMenu();
          onCloseMenu();
        }}
      >
        {label}
      </MenuItem>
    ));
  };

  return (
    <>
      <div className={styles.leftSidebar}>
        <div className={styles.tiltingPointLogo} onClick={() => history.push('/home')}>
          <TiltingPointLogo />
        </div>

        <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.ADD_NEW_ITEM}>
          <Permission
            allowedRoles={[P.S_ADMIN, P.ADMIN]}
            restrictedDepartments={[
              DEPARTMENT_PERMISSION.DEVELOPMENT,
              DEPARTMENT_PERMISSION.CUSTOMER_SUPPORT,
            ]}
          >
            <Tooltip title='New' placement='right'>
              <IconButton
                disableRipple
                className={styles.iconButton}
                onClick={onOpenMenu(SIDEBAR_SECTIONS.ADD_NEW_ITEM)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Permission>
        </ExternalUserPermissionWrapper>

        <Permission
          allowedRoles={P.ALL}
          restrictedDepartments={[DEPARTMENT_PERMISSION.CUSTOMER_SUPPORT]}
        >
          <Tooltip title='Search' placement='right'>
            <NavLink
              className={styles.iconButton}
              activeClassName={styles.active}
              to='/search'
            >
              <SearchIcon />
            </NavLink>
          </Tooltip>
        </Permission>

        <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.GOOGLE_ACCOUNTS}>
          <Permission
            allowedRoles={[P.S_ADMIN, P.ADMIN]}
          >
            <Tooltip title='Google accounts' placement='right'>
              <NavLink
                className={styles.iconButton}
                activeClassName={styles.active}
                to='/external-users-login'
              >
                <GoogleIcon />
              </NavLink>
            </Tooltip>
          </Permission>
        </ExternalUserPermissionWrapper>

        <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.CREATIVE_DASHBOARD}>
          <Tooltip title='Creative dashboard' placement='right'>
            <IconButton
              disableRipple
              className={styles.iconButton}
              onClick={onOpenMenu(SIDEBAR_SECTIONS.CREATIVE_DASHBOARD)}
            >
              <DashboardIcon />
            </IconButton>
          </Tooltip>
        </ExternalUserPermissionWrapper>

        <ExternalUserPermissionWrapper sectionName={SIDEBAR_SECTIONS.OTHER}>
          <Tooltip title='Other tools' placement='right'>
            <IconButton
              disableRipple
              className={classNames([styles.iconButton, styles.flip])}
              onClick={onOpenMenu(SIDEBAR_SECTIONS.OTHER)}
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </ExternalUserPermissionWrapper>

        <div className={styles.bottomItems}>
          <Tooltip title='Log Out' placement='right'>
            <IconButton
              disableRipple
              className={styles.iconButton}
              onClick={() => dispatch(logOut())}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>

          <Permission
            allowedRoles={P.ALL}
            restrictedDepartments={[DEPARTMENT_PERMISSION.CUSTOMER_SUPPORT]}
          >
            <Tooltip title='Profile Settings' placement='right'>
              <IconButton
                disableRipple
                className={classNames([styles.iconButton, styles.profileIcon])}
                onClick={() => setIsCreativeAPIKeyModalOpen(true)}
              >
                {avatarUrl
                  ? <Avatar src={avatarUrl} alt='Current user' />
                  : <ProfileIcon />
                }
              </IconButton>
            </Tooltip>
          </Permission>

          <Tooltip title='Sync with Jira' placement='right'>
            <IconButton
              disableRipple
              className={styles.iconButton}
              disabled={isSyncingJira}
              onClick={() => dispatch(syncJira())}
            >
              {isSyncingJira
                ? <CircularProgress />
                : <SyncIcon />
              }
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={styles.menu}
        onClose={onCloseMenu}
      >
        {getMenuContent(section)}
      </Menu>

      <Menu
        open={!!subAnchorEl}
        anchorEl={subAnchorEl}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classNames([styles.menu, styles.subMenu])}
        onClose={onCloseSubMenu}
      >
        {getSubMenuContent(subSection)}
      </Menu>

      <Modal
        open={isNewGameModalOpen}
        onClose={onCloseNewGameModal}
      >
        <GameEditForm onClose={onCloseNewGameModal} />
      </Modal>

      <Modal
        open={isCreativeAPIKeyModalOpen}
        onClose={() => setIsCreativeAPIKeyModalOpen(false)}
      >
        <div className={styles.creativeApiKeyContainer}>
          {!creativeApiKey ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => dispatch(createCreativeApiKey())}
            >
              Generate Creative API Key
            </Button>
          ) : (
            <Button
              variant='contained'
              color='secondary'
              onClick={() => dispatch(revokeCreativeApiKey())}
            >
              Revoke Creative API Key
            </Button>
          )}
          {(creativeApiKey && creativeApiKey !== '**********') && (
            <p className={styles.keyInfo}>
              Please copy the following key and keep it in your secret file
              right after it got generated, it only shows the credential
              once when generated
            </p>
          )}
          {(!isFetchingCreativeApiKey && creativeApiKey) && (
            <div className={styles.keyContainer}>
              <span className={styles.key}>
                {creativeApiKey}
              </span>
              {(creativeApiKey && creativeApiKey !== '**********') && (
                <Button
                  color={didCopyCreativeAPIKey ? 'secondary' : 'primary'}
                  variant='contained'
                  onClick={onCopyApiKey}
                >
                  {didCopyCreativeAPIKey ? 'Copied' : 'Copy'}
                </Button>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default LeftSidebar;
