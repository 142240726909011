import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Preloader from '../UIComponents/Preloader';
import Main from '../App/Main';
import LogIn from '../User/LogIn';
import Collection from '../Collections/Collection';
import NotFound from '../App/NotFound';
import PrivateRoute from './PrivateRoute';
import { getCurrentUserProfile } from '../User/User.redux/profileActions';
import { START_PAGE, LOGIN_PAGE } from '../../constants/General';


const Routing = () => {

  const dispatch = useDispatch();

  const {
    loggedIn,
    hasLoggedOut,
    isCheckingLoginStatus,
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCurrentUserProfile());
  }, []);

  return isCheckingLoginStatus ?
    <Preloader /> :
    <Switch>
      <Route
        exact
        path={LOGIN_PAGE}
        component={LogIn}
      />

      {!loggedIn &&
        <Route
          exact
          path={'/collection-share/:collectionId/:collectionHash'}
          component={Collection}
        />
      }

      <PrivateRoute
        isAuthenticated={loggedIn}
        hasLoggedOut={hasLoggedOut}
        path={START_PAGE}
        component={Main}
      />

      <Route
        path='*'
        component={NotFound}
      />
    </Switch>;
};

export default Routing;
