import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import './SearchInput.scss';


const SearchInput = ({
  handleSearch,
  searchWord,
  additionalClass,
  withClear,
  disabled,
  pink,
}) => {
  return (
    <div className={classnames('search', {
        [additionalClass]: additionalClass,
        'disabled': disabled,
      })}
    >
      <div
        className={classnames('search__container', {
          'withClear': withClear,
          'pink': pink,
        })}
      >
        <SearchIcon className='search__icon' />

        <input
          className='search__field'
          placeholder='search'
          onChange={(e) => handleSearch(e.target.value)}
          value={searchWord || ''}
        />

        {(withClear && searchWord) &&
          <ClearIcon
            className='search__icon'
            onClick={() => handleSearch('')}
          />
        }
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchWord: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  withClear: PropTypes.bool,
  disabled: PropTypes.bool,
  pink: PropTypes.bool,
};

export default SearchInput;
