import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { childrenPropType } from 'types/general';

import './AccordionPanel.scss';


const AccordionPanel = ({ title, hidden, defaultExpanded, additionalClassName, children }) => {
  return (
    <div
      className={classnames('accordion-panel', {
        'hidden': hidden,
        [additionalClassName]: additionalClassName,
      })}
    >
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

AccordionPanel.propTypes = {
  title: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  additionalClassName: PropTypes.string,
  children: childrenPropType,
};

export default AccordionPanel;
