export const RESIZE_PROJECT_START = 'RESIZE_PROJECT_START';
export const RESIZE_PROJECT_SUCCESS = 'RESIZE_PROJECT_SUCCESS';
export const RESIZE_PROJECT_FAIL = 'RESIZE_PROJECT_FAIL';
export const RESIZE_PROJECT_RESET = 'RESIZE_PROJECT_RESET';

export const RESIZE_PROJECT_INFO = 'RESIZE_PROJECT_INFO';

export const GET_GAMES_FINAL_VIDEOS_START = 'GET_GAMES_FINAL_VIDEOS_START';
export const GET_GAMES_FINAL_VIDEOS_SUCCESS = 'GET_GAMES_FINAL_VIDEOS_SUCCESS';
export const GET_GAMES_FINAL_VIDEOS_SUCCESS_SCROLL = 'GET_GAMES_FINAL_VIDEOS_SUCCESS_SCROLL';
export const GET_GAMES_FINAL_VIDEOS_FAIL = 'GET_GAMES_FINAL_VIDEOS_FAIL';

export const SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS = 'SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS';
export const SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS = 'SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS';

export const LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS = 'LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS';
export const LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS = 'LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS';

export const LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS = 'LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS';
export const LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS = 'LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS';

export const SQUARE_4x5_FRAMES_ANIMATED_SUCCESS = 'SQUARE_4x5_FRAMES_ANIMATED_SUCCESS';
export const SQUARE_4x5_FRAMES_STATIC_SUCCESS = 'SQUARE_4x5_FRAMES_STATIC_SUCCESS';
