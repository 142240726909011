import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonWithDropDown from 'components/UIComponents/ButtonWithDropDown';

import './SortingButton.scss';


const SortingButton = ({ sortOptions, activeSortOptionId, handleChangeActiveSortOption }) => {
  return (
    <div className='sorting-button'>
      <ButtonWithDropDown
        name='sort by'
        dropDownWidth={250}
        closeWhenClickOnDropDown
      >
        <div className='sorting-button__dropDown'>
          {sortOptions.map((sortOption) =>
            <p
              key={sortOption.id}
              className={classnames('sorting-button__sort-option', {
                'active': activeSortOptionId === sortOption.id,
              })}
              onClick={() => handleChangeActiveSortOption(sortOption)}
            >
              {sortOption.name}
            </p>
          )}
        </div>
      </ButtonWithDropDown>
    </div>
  );
};

SortingButton.propTypes = {
  sortOptions: PropTypes.arrayOf(PropTypes.object),
  activeSortOptionId: PropTypes.number.isRequired,
  handleChangeActiveSortOption: PropTypes.func.isRequired,
};

export default SortingButton;
