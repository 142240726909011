import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './GoBackPanel.scss';


const GoBackPanel = ({handleGoBack}) => {
  return (
    <div className='go-back-panel'>
      <div
        className='go-back-panel__btn'
        onClick={handleGoBack}
      >
        <ArrowBackIcon className='go-back-panel__icon' />
        <span className='go-back-panel__text'>Back</span>
      </div>
    </div>
  );
};

GoBackPanel.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
};

export default GoBackPanel;
