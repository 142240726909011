import { AD_CREATE_BANNER_ITEM_TYPES } from '../../constants/CreativeTool';

export const fontWeightOptions = [
  {id: 1, name: 'light', value: '300'},
  {id: 2, name: 'regular', value: '400'},
  {id: 3, name: 'semibold', value: '600'},
  {id: 4, name: 'bold', value: '700'},
];

export const fontSizeOptions = [6, 8, 10, 12, 14, 16, 18, 20, 32, 42, 56, 64, 72, 96, 120, 172, 200, 240];

export const defaultButtonTemplateAdCreate = {
  type: AD_CREATE_BANNER_ITEM_TYPES.BUTTON,
  text: 'play now',
  fontId: null,
  styles: {
    //static
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '182px',
    height: '56px',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'word-break': 'break-all',
    'white-space': 'pre-wrap',
    'text-align': 'center',
    'border-style': 'solid',
    'box-sizing': 'border-box',
    transform: 'rotate(0deg) scale(1)',
    'z-index': '2',

    //dynamic
    color: '#ffffff',
    'font-size': '16px',
    'font-weight': '700',
    'font-family': 'default',
    'font-style': 'normal',
    'text-decoration': 'none',
    'background': '#f9a526',
    'border-color': 'transparent',
    'border-width': '0px',
    'border-radius': '0px',
  },
};

export const defaultTextTemplateAdCreate = {
  type: AD_CREATE_BANNER_ITEM_TYPES.TEXT,
  text: 'Lorem ipsum dol',
  fontId: null,
  styles: {
    //static
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100px',
    height: '50px',
    'word-break': 'break-all',
    'white-space': 'pre-wrap',
    'text-align': 'center',
    transform: 'rotate(0deg) scale(1)',
    'z-index': '3',

    //dynamic
    color: '#000000',
    'font-size': '16px',
    'font-weight': '700',
    'font-family': 'default',
    'font-style': 'normal',
    'text-decoration': 'none',
  },
};

export const defaultContainerTemplateAdCreate = {
  type: AD_CREATE_BANNER_ITEM_TYPES.CONTAINER,
  text: '',
  fontId: null,
  nestedBannerItems: [],
  styles: {
    position: 'absolute',
    width: '575px',
    height: '471px',
    left: '0px',
    top: '0px',
    transform: 'rotate(0deg) scale(1)',
    'z-index': '3',
  },
};