import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import RoundedModalWrapper from '../../UIComponents/RoundedModalWrapper';
import DoUploadButton from '../Parts/DoUploadButton';
import { childrenPropType } from '../../../types/general';
import AssetUploaderContext from '../assetUploaderContext';
import { assetToUploadPropTypes } from '../Types';

import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import './UploadPathSelectionWrapper.scss';


const UploadPathSelectionWrapper = ({
  assetsToUpload,
  closeAssetUploader,
  isNextStepDisabled,
  handleNextStep,
  children,
}) => {
  const selectAssetsToUpload = useContext(AssetUploaderContext);
  
  return (
    <RoundedModalWrapper
      isOpen
      title='Upload files'
      titleChildren={<DoUploadButton onClick={selectAssetsToUpload} count={assetsToUpload.length} isPink />}
      onClose={closeAssetUploader}
      withoutScroll
    >
      <div className='upload-path-selection'>
        <div className='upload-path-selection__inputs-container'>
          {children}
        </div>

        <div className='upload-path-selection__buttons-container'>
          <button
            className='upload-path-selection__button'
            onClick={closeAssetUploader}
          >
            <CloseIcon className='icon-inside-button' />
            <span>Cancel</span>
          </button>

          <button
            className='upload-path-selection__button'
            onClick={handleNextStep}
            disabled={isNextStepDisabled}
          >
            <ArrowForwardIcon className='icon-inside-button' />
            <span>Next</span>
          </button>
        </div>
      </div>
    </RoundedModalWrapper>
  );
};

UploadPathSelectionWrapper.propTypes = {
  assetsToUpload: PropTypes.arrayOf(assetToUploadPropTypes),
  closeAssetUploader: PropTypes.func.isRequired,
  isNextStepDisabled: PropTypes.bool.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  children: childrenPropType,
};

export default UploadPathSelectionWrapper;
