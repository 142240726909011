import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Breadcrumbs } from '@material-ui/core';
import CanDropWrapper from 'components/UIComponents/CanDropWrapper';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import './CreativeStorageNavigation.scss';


const CreativeStorageNavigation = ({
  dirPath,
  navigateToPath,
  handleMoveAssets,
}) => {

  const directoriesInPath = dirPath ? dirPath.split('/') : [];

  const handleNavigateToFolder = (folderIndexInPath) => {
    navigateToPath(directoriesInPath.slice(0, folderIndexInPath + 1).join('/'));
  };

  return (
    <div className='creative-storage-navigation'>
      <Breadcrumbs
        maxItems={3}
        separator={(
          <div className={classnames('creative-storage-navigation__separator', {
            'hidden': !dirPath,
          })} />
        )}
      >
        <CanDropWrapper
          path=''
          acceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
          onDropStorageItem={handleMoveAssets}
        >
          <button
            className={classnames('creative-storage-navigation__path-button', {
              'pink': dirPath,
            })}
            onClick={() => handleNavigateToFolder(-1)}
            disabled={!dirPath}
          >
            All elements
          </button>
        </CanDropWrapper>

        {dirPath?.split('/').map((folderName, index, dirPathArray) => {
          const isCurrentDirectory = index === dirPathArray.length - 1;
          const folderPath = dirPathArray.slice(0, index + 1).join('/');

          return (
            <CanDropWrapper
              key={index}
              acceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
              path={folderPath}
              onDropStorageItem={handleMoveAssets}
            >
              <button
                className={classnames('creative-storage-navigation__path-button', {
                  'pink': !isCurrentDirectory,
                })}
                onClick={() => handleNavigateToFolder(index)}
                disabled={isCurrentDirectory}
              >
                {folderName}
              </button>
            </CanDropWrapper>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

CreativeStorageNavigation.propTypes = {
  dirPath: PropTypes.string,
  navigateToPath: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
};

export default CreativeStorageNavigation;
