import {v4 as uuidv4} from 'uuid';

/**
 * @param {number} id
 * @param {string} key
 * @param {string} name
 * @param {number} otherTimePercentage
 * @param {number} totalSpentTime
 * @param {object[]} userGames
 */
class UserAllocationModel {
  constructor(data) {
    this.id = data.id;
    this.key = uuidv4();
    this.name = data.name;
    this.otherTimePercentage = data.otherTimePercentage;
    this.totalSpentTime = data.totalSpentTime;
    this.userGames = data.userGames.map(({id, gameId, percentage}) => ({
      id,
      gameId,
      key: uuidv4(),
      percentage,
    }));
  }
}

export default UserAllocationModel;
