export const OVERLAYS_SETTINGS__ACTIVE_OVERLAY_MODE_CHANGED = 'OVERLAYS_SETTINGS__ACTIVE_OVERLAY_MODE_CHANGED';
export const OVERLAYS_SETTINGS__OVERLAY_MODE_CHANGED = 'OVERLAYS_SETTINGS__OVERLAY_MODE_CHANGED';

export const OVERLAYS_SETTINGS__VIDEO_INDEX_CHANGED = 'OVERLAYS_SETTINGS__VIDEO_INDEX_CHANGED';
export const OVERLAYS_SETTINGS__VIDEO_ARRAY_CHANGED = 'OVERLAYS_SETTINGS__VIDEO_ARRAY_CHANGED';
export const OVERLAYS_SETTINGS__MEDIA_ITEMS_READY = 'OVERLAYS_SETTINGS__MEDIA_ITEMS_READY';

export const OVERLAYS_SETTINGS__LAYERS_CHANGED = 'OVERLAYS_SETTINGS__LAYERS_CHANGED';

// heroes
export const OVERLAYS_SETTINGS__HERO_SELECTED = 'OVERLAYS_SETTINGS__HERO_SELECTED';
export const OVERLAYS_SETTINGS__HERO_DELETED = 'OVERLAYS_SETTINGS__HERO_DELETED';
export const OVERLAYS_SETTINGS__HERO_POSITION_CHANGED = 'OVERLAYS_SETTINGS__HERO_POSITION_CHANGED';
export const OVERLAYS_SETTINGS__HERO_SIZE_CHANGED = 'OVERLAYS_SETTINGS__HERO_SIZE_CHANGED';
export const OVERLAYS_SETTINGS__HERO_REFLECTION_CHANGED = 'OVERLAYS_SETTINGS__HERO_REFLECTION_CHANGED';
export const OVERLAYS_SETTINGS__HERO_ANIMATION_CHANGED = 'OVERLAYS_SETTINGS__HERO_ANIMATION_CHANGED';
export const OVERLAYS_SETTINGS__HERO_LOOP_CHANGED = 'OVERLAYS_SETTINGS__HERO_LOOP_CHANGED';

// effects
export const OVERLAYS_SETTINGS__EFFECT_SELECTED = 'OVERLAYS_SETTINGS__EFFECT_SELECTED';
export const OVERLAYS_SETTINGS__EFFECT_DELETED = 'OVERLAYS_SETTINGS__EFFECT_DELETED';
export const OVERLAYS_SETTINGS__EFFECT_POSITION_CHANGED = 'OVERLAYS_SETTINGS__EFFECT_POSITION_CHANGED';
export const OVERLAYS_SETTINGS__EFFECT_SIZE_CHANGED = 'OVERLAYS_SETTINGS__EFFECT_SIZE_CHANGED';

// lower thirds
export const OVERLAYS_SETTINGS__LOWER_THIRD_SELECTED = 'OVERLAYS_SETTINGS__LOWER_THIRD_SELECTED';
export const OVERLAYS_SETTINGS__LOWER_THIRD_DELETED = 'OVERLAYS_SETTINGS__LOWER_THIRD_DELETED';
export const OVERLAYS_SETTINGS__LOWER_THIRD_CREATED = 'OVERLAYS_SETTINGS__LOWER_THIRD_CREATED';
export const OVERLAYS_SETTINGS__LOWER_THIRD_COMMON_SETTINGS_CHANGED = 'OVERLAYS_SETTINGS__LOWER_THIRD_COMMON_SETTINGS_CHANGED';
export const OVERLAYS_SETTINGS__LOWER_THIRD_SETTINGS_CHANGED = 'OVERLAYS_SETTINGS__LOWER_THIRD_SETTINGS_CHANGED';

export const OVERLAYS_SETTINGS__STORE_CLEARED = 'OVERLAYS_SETTINGS__STORE_CLEARED';
