/**
 * @param {number} id
 * @param {string} url
 * @param {string} name
 * @param {string} type
 */
class FrameModel {
  constructor(data, type) {
    this.id = parseInt(data._meta.id);
    this.url = data.medium_preview_url.raw;
    this.name = data.asset_name.raw;
    this.type = type;
  }
}

export default FrameModel;
