import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Zoom } from '@material-ui/core';
import {
  isEqual,
  pick,
  uniqWith,
} from 'lodash';
import StyledTooltip from '../../UIComponents/StyledTooltip';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import { setAssetsToDownloadAction } from 'components/Assets/DownloadAssets/DownloadAssets.redux/actions';
import { setAssetsToDeleteAction } from 'components/Assets/DeleteAssets/DeleteAssets.redux/actions';
import { setAssetsToPreviewAction } from 'components/Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/actions';
import {
  copyToClipboardLinkToSharedAssetsAction,
  copyToClipboardLinkToSharedAssetsPathAction,
  getStorageData,
} from 'components/Assets/Assets.redux/Actions/assetActions';
import { setAssetsIdsForCollectionAction } from 'components/Collections/Collections.redux/Actions/collectionsActions';
import { getCreativeByIdOrIssueKey, setCreativeMainCover } from '../../Creative/Creative.redux/Actions/creativeActions';
import {
  checkIfExternalUser,
  checkPermissions,
} from 'components/General/Permissions/Permission';
import { GAME_ACCESS_TYPE } from 'constants/General';
import { GENERAL_GAME_FOLDERS_NAMES } from 'constants/Game';
import { ASSET_TYPE } from 'constants/Asset';
import { MAX_ASSETS_TO_SHARE } from '../../MainSearch/mainSearchConstants';

import {
  Delete as DeleteIcon,
  Reply as ReplyIcon,
  ReplyAll as ReplyAllIcon,
  Edit as EditIcon,
  GetApp as GetAppIcon,
  LibraryAdd as LibraryAddIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';

import './AssetActions.scss';


const AssetActions = ({
  isDetailsModal,
  isNeedToUpdateElastic,
  gameId,
  selectedAssets,
  selectedAssetIdsSet,
  setSelectedAssetIdsSet,
  videoProgress,
  actionAfterDelete,
  setFolderToRename,
}) => {

  const dispatch = useDispatch();

  const [isConfirmDownloadModal, setIsConfirmDownloadModal] = useState(false);

  const checkAssetsAccess = (accessType) => {
    if (gameId) {
      return checkPermissions(gameId, accessType);
    } else {
      const gameIdsSet = new Set(selectedAssets.map((selectedAsset) => selectedAsset.gameId));

      return [...gameIdsSet].every((gameId) => checkPermissions(gameId, accessType));
    }
  };

  // Permissions
  const isUserExternal = checkIfExternalUser();

  const canManageAssets = useMemo(() => {
    const hasPermissionToManageAssets = checkAssetsAccess(GAME_ACCESS_TYPE.UPLOAD);

    if (isUserExternal) {
      const isSomeAssetFromCreativePost = selectedAssets.some((selectedAsset) => selectedAsset.creativeId);
      const isSomeAssetNotFromDeveloperAssetsFolder = selectedAssets.some((selectedAsset) =>
        selectedAsset.folderPath?.split('/')[0] !== GENERAL_GAME_FOLDERS_NAMES.DEVELOPER_ASSETS
      );

      return !isSomeAssetFromCreativePost && !isSomeAssetNotFromDeveloperAssetsFolder && hasPermissionToManageAssets;
    }

    return hasPermissionToManageAssets;
  }, [gameId, isUserExternal, selectedAssets]);

  const canShare = useMemo(() =>
      selectedAssets.every((selectedAsset) => !selectedAsset.isFolder) &&
      !!selectedAssetIdsSet.size &&
      selectedAssetIdsSet.size < MAX_ASSETS_TO_SHARE,
    [selectedAssets, selectedAssetIdsSet]);

  const canEdit = useMemo(() => {
    if (selectedAssetIdsSet.size === 1 && selectedAssets[0]?.isFolder) {
      return canManageAssets;
    } else {
      return canShare;
    }
  }, [selectedAssets, selectedAssetIdsSet]);

  const canDownload = checkAssetsAccess(GAME_ACCESS_TYPE.DOWNLOAD);

  const canSetAsCreativeMainCover = useMemo(() =>
      selectedAssetIdsSet.size === 1 &&
      selectedAssets[0]?.creativeId &&
      !selectedAssets[0]?.isFolder,
    [selectedAssets, selectedAssetIdsSet]);

  const canAddToCollection = useMemo(() =>
      !!selectedAssetIdsSet.size &&
      selectedAssets.every((selectedAsset) => !selectedAsset.isFolder),
    [selectedAssets, selectedAssetIdsSet]);

  const proceedDownloadSelectedAssets = async () => {
    await dispatch(setAssetsToDownloadAction(selectedAssets));
    setIsConfirmDownloadModal(false);
  };

  const handleDownloadSelectedAssets = async () => {
    await dispatch(setAssetsToDownloadAction([]));
    if (selectedAssets.some((asset) => !asset.approved)) {
      setIsConfirmDownloadModal(true);
    } else {
      proceedDownloadSelectedAssets();
    }
  };

  const handleUpdateAfterAction = () => {
    if (setSelectedAssetIdsSet) {
      setSelectedAssetIdsSet(new Set());
    }

    const arrayOfGameStorages = selectedAssets.map((currentAsset) => pick(currentAsset, ['storageId', 'folderPath']));
    const uniqArrayOfGameStorages = uniqWith(arrayOfGameStorages, isEqual);

    uniqArrayOfGameStorages.forEach((gameStorage) =>
      dispatch(getStorageData(gameStorage.storageId, gameStorage.folderPath))
    );

    if (actionAfterDelete) {
      actionAfterDelete();
    }
  };

  const handleDeleteAssets = () => {
    dispatch(setAssetsToDeleteAction({
      assetsToDeleteIds: [...selectedAssetIdsSet],
      actionAfterDelete: handleUpdateAfterAction,
      updateElastic: isNeedToUpdateElastic,
    }));
  };

  const handleEditAssets = (assetsToEdit) => {
    if (assetsToEdit[0].isFolder) {
      setFolderToRename(assetsToEdit[0]);
    } else {
      dispatch(setAssetsToPreviewAction(assetsToEdit, !isNeedToUpdateElastic));
    }
  };

  const handleShareAssets = () => {
    dispatch(copyToClipboardLinkToSharedAssetsAction({
      ids: [...selectedAssetIdsSet],
    }));
  };

  const handleShareAssetsPath = () => {
    dispatch(copyToClipboardLinkToSharedAssetsPathAction({
      ids: [...selectedAssetIdsSet],
    }));
  };

  const handleSetAsMainCover = async () => {
    const { creativeId, id, type } = selectedAssets[0];
    await dispatch(setCreativeMainCover(
      creativeId,
      id,
      type === ASSET_TYPE.VIDEO ? videoProgress : undefined)
    );
    await dispatch(getCreativeByIdOrIssueKey(creativeId));
  };

  const handleAddAssetsToCollection = () => {
    dispatch(setAssetsIdsForCollectionAction([...selectedAssetIdsSet]));
  };

  return (
    <div
      className={classnames('asset-actions', {
        'blueTheme': isDetailsModal,
      })}
    >
      <StyledTooltip title='Delete' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !selectedAssetIdsSet.size || !canManageAssets,
          })}
          onClick={handleDeleteAssets}
        >
          <DeleteIcon className='asset-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Share' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !canShare,
          })}
          onClick={handleShareAssets}
        >
          <ReplyIcon className='asset-actions__icon flipX' />
        </div>
      </StyledTooltip>
      {window.location.href.includes('dirPath') &&
        <StyledTooltip title='Share with path (internal use only)' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('asset-actions__icon-container', {
              'hidden': !canShare,
            })}
            onClick={handleShareAssetsPath}
          >
            <ReplyAllIcon className='asset-actions__icon flipX' />
          </div>
        </StyledTooltip>
      }
      <StyledTooltip title='Edit' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !canEdit || isDetailsModal,
          })}
          onClick={() => handleEditAssets(selectedAssets)}
        >
          <EditIcon className='asset-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Download' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !selectedAssetIdsSet.size || !canDownload,
          })}
          onClick={handleDownloadSelectedAssets}
        >
          <GetAppIcon className='asset-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Set as main cover' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !canSetAsCreativeMainCover || !canManageAssets,
          })}
          onClick={handleSetAsMainCover}
        >
          <VisibilityIcon className='asset-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Add to collection' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('asset-actions__icon-container', {
            'hidden': !canAddToCollection || isUserExternal,
          })}
          onClick={handleAddAssetsToCollection}
        >
          <LibraryAddIcon className='asset-actions__icon' />
        </div>
      </StyledTooltip>

      <ConfirmRoundModal
        isOpen={isConfirmDownloadModal}
        title='Download Not approved asset ?'
        text='You are downloading an asset that has not been approved and cannot be published. Please make sure to use appropriately. Do you still want to download it?'
        agreeText='yes'
        agreeIcon=''
        cancelText='no'
        cancelIcon=''
        onAgree={proceedDownloadSelectedAssets}
        onClose={() => setIsConfirmDownloadModal(false)}
      />
    </div>
  );
};

AssetActions.propTypes = {
  isDetailsModal: PropTypes.bool,
  isNeedToUpdateElastic: PropTypes.bool,
  gameId: PropTypes.number,
  selectedAssets: PropTypes.arrayOf(PropTypes.object),
  selectedAssetIdsSet: PropTypes.object.isRequired,
  setSelectedAssetIdsSet: PropTypes.func,
  videoProgress: PropTypes.number,
  actionAfterDelete: PropTypes.func,
  setFolderToRename: PropTypes.func,
};

export default AssetActions;
