export const GET_GAMES_START = 'GET_GAMES_START';
export const GET_GAMES_FAIL = 'GET_GAMES_FAIL';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';

export const GAME_CLEARING_PREVIEW = 'GAME_CLEARING_PREVIEW';

export const GET_ALL_GENRES_REQUEST = 'GET_ALL_GENRES_REQUEST';
export const GET_ALL_GENRES_SUCCESS = 'GET_ALL_GENRES_SUCCESS';
export const GET_ALL_GENRES_FAIL = 'GET_ALL_GENRES_FAIL';

export const GET_ALL_STUDIOS_REQUEST = 'GET_ALL_STUDIOS_REQUEST';
export const GET_ALL_STUDIOS_SUCCESS = 'GET_ALL_STUDIOS_SUCCESS';
export const GET_ALL_STUDIOS_FAIL = 'GET_ALL_STUDIOS_FAIL';

export const GET_ALL_LEVELS_REQUEST = 'GET_ALL_LEVELS_REQUEST';
export const GET_ALL_LEVELS_SUCCESS = 'GET_ALL_LEVELS_SUCCESS';
export const GET_ALL_LEVELS_FAIL = 'GET_ALL_LEVELS_FAIL';

export const SET_SELECTED_GAME_ID = 'SET_SELECTED_GAME_ID';

export const GET_SELECTED_GAME_FAIL = 'GET_SELECTED_GAME_FAIL';
export const GET_SELECTED_GAME_REQUEST = 'GET_SELECTED_GAME_REQUEST';
export const GET_SELECTED_GAME_SUCCESS = 'GET_SELECTED_GAME_SUCCESS';

export const GET_GAMES_BY_GENRES_REQUEST = 'GET_GAMES_BY_GENRES_REQUEST';
export const GET_GAMES_BY_GENRES_SUCCESS = 'GET_GAMES_BY_GENRES_SUCCESS';
export const GET_GAMES_BY_GENRES_FAIL = 'GET_GAMES_BY_GENRES_FAIL';

export const REMOVE_SELECTED_GAME_FAIL = 'REMOVE_SELECTED_GAME_FAIL';
export const REMOVE_SELECTED_GAME_REQUEST = 'REMOVE_SELECTED_GAME_REQUEST';
export const REMOVE_SELECTED_GAME_SUCCESS = 'REMOVE_SELECTED_GAME_SUCCESS';

export const GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL = 'GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL';
export const GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST = 'GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST';
export const GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS = 'GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS';

export const UPLOAD_SELECTED_GAME_PREVIEW = 'UPLOAD_SELECTED_GAME_PREVIEW';

export const UPDATE_SIMILAR_GAME_STATUS = 'UPDATE_SIMILAR_GAME_STATUS';

export const SET_SELECTED_GAME_GENRES = 'SET_SELECTED_GAME_GENRES';
export const ADD_NEW_GAME_GENRE = 'ADD_NEW_GAME_GENRE';

export const CREATE_NEW_GAME_FAIL = 'CREATE_NEW_GAME_FAIL';
export const CREATE_NEW_GAME_REQUEST = 'CREATE_NEW_GAME_REQUEST';
export const CREATE_NEW_GAME_SUCCESS = 'CREATE_NEW_GAME_SUCCESS';

export const UPDATE_SELECTED_GAME_FAIL = 'UPDATE_SELECTED_GAME_FAIL';
export const UPDATE_SELECTED_GAME_REQUEST = 'UPDATE_SELECTED_GAME_REQUEST';
export const UPDATE_SELECTED_GAME_SUCCESS = 'UPDATE_SELECTED_GAME_SUCCESS';

export const GET_UNASSOCIATED_JIRA_PROJECTS_START = 'GET_UNASSOCIATED_JIRA_PROJECTS_START';
export const GET_UNASSOCIATED_JIRA_PROJECTS_FAIL = 'GET_UNASSOCIATED_JIRA_PROJECTS_FAIL';
export const GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS = 'GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS';

export const UPDATE_OPTION_REQUEST = 'UPDATE_OPTION_REQUEST';
export const UPDATE_OPTION_SUCCESS = 'UPDATE_OPTION_SUCCESS';
export const UPDATE_OPTION_FAIL = 'UPDATE_OPTION_FAIL';
