import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class GameSettingsService {
  constructor() {
    this.gameSettings = FeathersClient.service(`${API_PREFIX}game-settings`);
  }

  findGameSettings(query) {
    return this.gameSettings.find({ query });
  }

  createGameSetting(data) {
    return this.gameSettings.create(data);
  }

  updateGameSetting(systemId, data) {
    return this.gameSettings.update(systemId, data);
  }
}

export default new GameSettingsService();
