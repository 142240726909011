export const GET_HEADER_TITLES_SUCCESS_DASHBOARD = 'GET_HEADER_TITLES_SUCCESS_DASHBOARD';
export const CHANGE_HEADER_TITLES_SUCCESS_DASHBOARD = 'CHANGE_HEADER_TITLES_SUCCESS_DASHBOARD';
export const SET_HEADER_TITLES_DASHBOARD = 'SET_HEADER_TITLES_DASHBOARD';
export const SET_HEADER_TITLES_EDIT_STATE_DASHBOARD = 'SET_HEADER_TITLES_EDIT_STATE_DASHBOARD';
export const SET_HEADER_TITLES_VALIDATION_DASHBOARD = 'SET_HEADER_TITLES_VALIDATION_DASHBOARD';

export const GET_STUDIOS_START = 'GET_STUDIOS_START';
export const GET_STUDIOS_SUCCESS = 'GET_STUDIOS_SUCCESS';
export const GET_STUDIOS_SUCCESS_SCROLL = 'GET_STUDIOS_SUCCESS_SCROLL';
export const GET_STUDIOS_FAIL = 'GET_STUDIOS_FAIL';

export const GET_GAMES_NUMBERS_START = 'GET_GAMES_NUMBERS_START';
export const GET_GAMES_NUMBERS_SUCCESS = 'GET_GAMES_NUMBERS_SUCCESS';
export const GET_GAMES_NUMBERS_SUCCESS_SCROLL = 'GET_GAMES_NUMBERS_SUCCESS_SCROLL';
export const GET_GAMES_NUMBERS_FAIL = 'GET_GAMES_FAIL';

export const GET_POSTS_NUMBERS_START = 'GET_POSTS_NUMBERS_START';
export const GET_POSTS_NUMBERS_SUCCESS = 'GET_POSTS_NUMBERS_SUCCESS';
export const GET_POSTS_NUMBERS_SUCCESS_SCROLL = 'GET_POSTS_NUMBERS_SUCCESS_SCROLL';
export const GET_POSTS_NUMBERS_FAIL = 'GET_POSTS_FAIL';

export const SET_POST_ACTION_START = 'SET_POST_ACTION_START';
export const SET_POST_ACTION_SUCCESS = 'SET_POST_ACTION_SUCCESS';
export const SET_POST_ACTION_FAIL = 'SET_POST_ACTION_FAIL';

export const SET_DASHBOARD_GENERAL_FILTER = 'SET_DASHBOARD_GENERAL_FILTER';

export const TOGGLE_DEPARTMENT_DASHBOARD = 'TOGGLE_DEPARTMENT_DASHBOARD';
export const CLEAR_CHECKED_DEPARTMENTS_DASHBOARD = 'CLEAR_CHECKED_DEPARTMENTS_DASHBOARD';

export const SET_HOURLY_RATE_SUCCESS = 'SET_HOURLY_RATE_SUCCESS';
