import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import {
  cloneDeep,
  isEqual,
  pick,
} from 'lodash';
import RoundedModalWrapper from '../../UIComponents/RoundedModalWrapper';
import CustomSelect from '../../UIComponents/CustomSelect';
import CustomInput from '../../UIComponents/CustomInput';
import CustomTextarea from '../../UIComponents/CustomTextarea';
import Preloader from '../../UIComponents/Preloader';
import {
  getAllCollectionsAction,
  setAssetsIdsForCollectionAction,
  setCollectionCreationStateAction,
  setCollectionToEditAction,
  createCollectionAction,
  editCollectionAction,
  resetCreateOrEditCollectionAction,
  addFilesToCollectionAction,
} from '../Collections.redux/Actions/collectionsActions';
import { presetCollectionIdAction } from '../Collections.redux/Actions/collectionActions';
import { makeFieldValidation } from 'utils/utils';
import { VALIDATION } from 'constants/Validation';

import {
  Add as AddIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  AddToPhotos as AddToPhotosIcon,
} from '@material-ui/icons';

import './CollectionsModal.scss';

const collectionVisibilityOptions = [
  {id: 1, name: 'Private collection', value: true},
  {id: 2, name: 'Public collection', value: false},
];

const collectionFieldsInitState = {
  name: '',
  isPrivate: true,
  description: '',
};

const collectionFieldsValidationInitState = {
  name: null,
  description: null,
};


const CollectionsModal = () => {

  const dispatch = useDispatch();

  const {
    presetCollectionId,
    allUserCollections,
    assetsIds,
    isCollectionCreationState,
    collectionToEdit,
    manageCollection,
  } = useSelector((state) => state.collections);

  const {
    loading,
    success: successCollectionAction,
  } = manageCollection;

  const [selectedCollection, setSelectedCollection] = useState(null);
  const [collectionFields, setCollectionFields] = useState(collectionFieldsInitState);
  const [collectionFieldsValidation, setCollectionFieldsValidation] = useState(collectionFieldsValidationInitState);

  useEffect(() => {
    if (allUserCollections.length) {
      if (presetCollectionId) {
        const neededUserCollection = allUserCollections.find((collection) => collection.id === presetCollectionId);
        setSelectedCollection(neededUserCollection || null);
      } else if (!selectedCollection) {
        setSelectedCollection(allUserCollections[0]);
      }
    }
  }, [presetCollectionId, allUserCollections, selectedCollection]);

  useEffect(() => {
    if (successCollectionAction) {

      if (isCollectionCreationState) {
        dispatch(presetCollectionIdAction(successCollectionAction.id));
        toggleCollectionCreationModal(false);
      }

      if (collectionToEdit) {
        dispatch(setCollectionToEditAction(null));
      }

      resetCollectionFieldsAndValidation();
      dispatch(resetCreateOrEditCollectionAction());
    }
  }, [successCollectionAction]);

  useEffect(() => {
    if (collectionToEdit) {
      setCollectionFields({
        ...collectionToEdit,
      });
    }
  }, [collectionToEdit]);

  useEffect(() => {
    if (assetsIds?.length) {
      dispatch(getAllCollectionsAction());
    }
  }, [assetsIds]);

  const resetCollectionFieldsAndValidation = () => {
    setCollectionFields(collectionFieldsInitState);
    setCollectionFieldsValidation(collectionFieldsValidationInitState);
  };

  const getModalTitle = () => {
    if (isCollectionCreationState) {
      return 'Create collection';
    }

    if (collectionToEdit) {
      return 'Edit collection';
    }

    return 'Add files to a collection';
  };

  const getModalProceedButtonDisabledState = () => {
    if (isCollectionCreationState) {
      return false;
    }

    if (collectionToEdit) {
      return isEqual(collectionFields, collectionToEdit);
    }

    return !selectedCollection;
  };

  const getModalProceedButtonContent = () => {
    if (isCollectionCreationState) {
      return (
        <>
          <AddIcon className='icon-inside-button' />
          <span>create</span>
        </>
      );
    }

    if (collectionToEdit) {
      return (
        <>
          <CheckIcon className='icon-inside-button' />
          <span>save</span>
        </>
      );
    }

    return (
      <>
        <AddToPhotosIcon className='icon-inside-button' />
        <span>add</span>
      </>
    );
  };

  const handleSetCollectionField = (fieldName, fieldValue) => {
    setCollectionFields({
      ...collectionFields,
      [fieldName]: fieldValue,
    });
  };

  const handleBlurCollectionFieldInput = (fieldName, fieldValue) => {
    let validation = null;

    if (fieldName === 'name') {
      validation = makeFieldValidation(
        fieldValue,
        VALIDATION.ASSET_NAME,
        'Please enter a valid collection title'
      );

      if (!fieldValue.length) {
        validation = 'Title is a mandatory field';
      }

      if (fieldValue.length && !fieldValue.trim().length) {
        validation = 'Title can\'t contain only spaces';
      }
    }

    if (fieldName === 'description' && fieldValue.length > 100) {
      validation = 'Maximum number of symbols is 100';
    }

    setCollectionFieldsValidation({
      ...collectionFieldsValidation,
      [fieldName]: validation,
    });

    return validation;
  };

  const toggleCollectionCreationModal = (state) => {
    dispatch(setCollectionCreationStateAction(state));
  };

  const handleCancel = () => {
    if (isCollectionCreationState) {
      toggleCollectionCreationModal(false);
      resetCollectionFieldsAndValidation();
      return;
    }

    if (collectionToEdit) {
      dispatch(setCollectionToEditAction(null));
      resetCollectionFieldsAndValidation();
      return;
    }

    if (assetsIds.length) {
      dispatch(setAssetsIdsForCollectionAction([]));
    }
  };

  const handleCloseModal = () => {
    toggleCollectionCreationModal(false);
    resetCollectionFieldsAndValidation();
    dispatch(setAssetsIdsForCollectionAction([]));
    dispatch(setCollectionToEditAction(null));
  };

  const proceedCreateOrEditCollection = () => {
    if (
      !handleBlurCollectionFieldInput('name', collectionFields.name) &&
      !handleBlurCollectionFieldInput('description', collectionFields.description)
    ) {

      const cloneCollectionFields = cloneDeep(collectionFields);
      cloneCollectionFields.name = cloneCollectionFields.name.trim();
      cloneCollectionFields.description = cloneCollectionFields.description.trim();

      if (isCollectionCreationState) {
        dispatch(createCollectionAction(cloneCollectionFields));
      }

      if (collectionToEdit) {
        dispatch(editCollectionAction(
          cloneCollectionFields.id,
          pick(cloneCollectionFields, Object.keys(collectionFieldsInitState)),
          collectionToEdit.isPrivate,
          cloneCollectionFields.isCollectionPage
        ));
      }
    }
  };

  const handleProceedAction = () => {
    if (isCollectionCreationState || collectionToEdit) {
      proceedCreateOrEditCollection();
    } else {
      dispatch(addFilesToCollectionAction(
        selectedCollection?.id,
        {
          parentId: null,
          ids: assetsIds,
        }
      ));
    }
  };

  let content;

  if (loading) {
    content = (
      <Preloader />
    );
  } else if (isCollectionCreationState || collectionToEdit) {
    content = (
      <>
        <CustomInput
          label='Enter collection title'
          value={collectionFields.name}
          placeholder='Enter title'
          onChange={(newName) => handleSetCollectionField('name', newName)}
          onBlur={(newName) => handleBlurCollectionFieldInput('name', newName)}
          error={collectionFieldsValidation.name}
        />

        <CustomSelect
          label='visibility'
          data={collectionVisibilityOptions}
          value={collectionFields.isPrivate ? collectionVisibilityOptions[0] : collectionVisibilityOptions[1]}
          handleSelect={(option) => handleSetCollectionField('isPrivate', option.value)}
          pink
        />

        <CustomTextarea
          label='description'
          value={collectionFields.description}
          placeholder='Type here your text'
          onChange={(newDescription) => handleSetCollectionField('description', newDescription)}
          onBlur={(newDescription) => handleBlurCollectionFieldInput('description', newDescription)}
          error={collectionFieldsValidation.description}
          maxLength={100}
        />
      </>
    );
  } else {
    content = (
      <>
        <CustomSelect
          label='choose collection'
          data={allUserCollections}
          value={selectedCollection}
          handleSelect={(collection) => setSelectedCollection(collection)}
          withSearch
          disabled={!allUserCollections.length}
          pink
        />

        <div className='collections-modal__create-button-container'>
          <div className='collections-modal-separator'>
            <span className='collections-modal-separator__line' />
            <span className='collections-modal-separator__text'>or</span>
            <span className='collections-modal-separator__line' />
          </div>

          <button
            className='collections-modal__create-button'
            onClick={() => toggleCollectionCreationModal(true)}
          >
            <AddIcon className='icon-inside-button' />
            <span>create collection</span>
          </button>
        </div>
      </>
    );
  }

  return (
    <RoundedModalWrapper
      isOpen={!!assetsIds.length || isCollectionCreationState || !!collectionToEdit}
      title={getModalTitle()}
      titleFontSize={16}
      onClose={handleCloseModal}
      zIndex={12}
    >
      <div className='collections-modal'>
        <div
          className={classnames('collections-modal__content', {
            'loading': loading,
            'createOrEditState': (isCollectionCreationState || collectionToEdit) && !loading,
          })}
        >
          {content}
        </div>

        <div className='collections-modal__actions-buttons-container'>
          <button
            className='collections-modal__cancel-button'
            disabled={loading}
            onClick={handleCancel}
          >
            <CloseIcon className='icon-inside-button' />
            <span>cancel</span>
          </button>
          <button
            className='collections-modal__proceed-button'
            onClick={handleProceedAction}
            disabled={getModalProceedButtonDisabledState() || loading}
          >
            {getModalProceedButtonContent()}
          </button>
        </div>
      </div>
    </RoundedModalWrapper>
  );
};

export default CollectionsModal;
