import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import {
  VolumeUp as VolumeUpIcon,
  VolumeOff as VolumeOffIcon,
} from '@material-ui/icons';

import { ReactComponent as PlayIcon } from '../../../../assets/svg/player/play.svg';
import { ReactComponent as StopIcon } from '../../../../assets/svg/player/stop.svg';
import { ReactComponent as FullscreenIcon } from '../../../../assets/svg/player/fullscreen.svg';

import styles from '../styles.module.scss';

momentDurationFormatSetup(moment);

const Controls = (props) => {
  const [wasPlaying, setWasPlaying] = useState(false);

  const {
    playing,
    volume,
    muted,
    playedSeconds,
    duration,
    rangeValue,
    onFullscreen,
    onPlay,
    onPause,
    setVolume,
    onMute,
    seekTo,
    seekUp,
  } = props;

  const STEP_VOLUME_SLIDER = 0.01;
  const STEP_RANGE_SLIDER = duration * 1000;
  const playedSecondsFormatted = moment.duration(playedSeconds, 'seconds')
    .format('mm:ss', { trim: false });
  const durationFormatted = moment.duration(duration, 'seconds')
    .format('mm:ss', { trim: false });

  const togglePlay = () => {
    if (playing) {
      onPause();
    } else {
      onPlay();
    }
  };

  const handleTimelineChangeCommitted = (event, value) => {
    if (wasPlaying) {
      onPlay();
    }

    seekUp(value);
  };

  const handleTimelineChange = (event, value) => {
    setWasPlaying(playing);
    onPause();
    seekTo(value);
  };

  const handleVolumeChange = (event, value) => setVolume(value);

  return (
    <div className={styles.controlsContainer}>
      <IconButton
        disableRipple
        onClick={togglePlay}
        className={styles.startStopButton}
      >
        {playing
          ? <StopIcon />
          : <PlayIcon />
        }
      </IconButton>

      <Slider
        max={STEP_RANGE_SLIDER}
        min={0}
        value={rangeValue}
        className={styles.timelineSlider}
        onChange={handleTimelineChange}
        onChangeCommitted={handleTimelineChangeCommitted}
      />
      <span className={styles.timeDisplay}>
        {playedSecondsFormatted} / {durationFormatted}
      </span>

      {muted || volume === 0 ?
        <VolumeOffIcon
          className={styles.soundIcon}
          onClick={() => onMute()}
        /> :
        <VolumeUpIcon
          className={styles.soundIcon}
          onClick={() => onMute()}
        />
      }

      <Slider
        max={1}
        min={0}
        step={STEP_VOLUME_SLIDER}
        value={volume}
        className={styles.volumeSlider}
        onChange={handleVolumeChange}
      />

      <IconButton onClick={onFullscreen}>
        <FullscreenIcon />
      </IconButton>
    </div>
  );
};


Controls.propTypes = {
  playing: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired,
  muted: PropTypes.bool.isRequired,
  playedSeconds: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  rangeValue: PropTypes.number.isRequired,
  onFullscreen: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  setVolume: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  seekTo: PropTypes.func.isRequired,
  seekUp: PropTypes.func.isRequired,
};


export default Controls;
