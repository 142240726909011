export const RANDOMIZER_STRUCTURES_SUCCESS = 'RANDOMIZER_STRUCTURES_SUCCESS';

export const RANDOMIZE_PROJECTS_START = 'RANDOMIZE_PROJECTS_START';
export const RANDOMIZE_PROJECTS_SUCCESS = 'RANDOMIZE_PROJECTS_SUCCESS';
export const RANDOMIZE_PROJECTS_FAIL = 'RANDOMIZE_PROJECTS_FAIL';
export const RANDOMIZE_PROJECTS_RESET = 'RANDOMIZE_PROJECTS_RESET';

export const SET_RENDER_STATUS_RANDOMIZER = 'SET_RENDER_STATUS_RANDOMIZER';
export const SET_RANDOMIZER_GROUP_ID = 'SET_RANDOMIZER_GROUP_ID';

export const CLEAN_UP_RANDOMIZER = 'CLEAN_UP_RANDOMIZER';
