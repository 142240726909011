import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomCheckbox from 'components/UIComponents/CustomCheckbox';
import EntityOverlay from '../../Parts/EntityOverlay';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import { ReactComponent as FolderIcon } from 'assets/svg/folder.svg';

import './FolderGridItem.scss';


const FolderGridItem = ({
  id,
  name,
  path,
  isError,
  isSelected,
  toggleSelection,
  navigateToFolder,
  userCanManage,
  dragAndDropAcceptType,
  onDropAssets,
}) => {

  const [, drag] = useDrag(() => ({
    type: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    item: { id, name, isFolder: true },
  }));

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    canDrop: (item) => item.id !== id,
    drop: (item) => onDropAssets(item, path ? path.substring(1) : id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div
      id={id}
      className={classnames('folder-grid-item', {
        'drop': (isOver && canDrop && userCanManage),
        'error': isError,
      })}
      ref={userCanManage ?
        (element) => {
          drag(element);
          drop(element);
        } :
        null
      }
    >
      <FolderIcon className='folder-grid-item__icon' />

      <p className='folder-grid-item__name'>{name}</p>

      <EntityOverlay
        isActive={isSelected}
        onDoubleClick={navigateToFolder}
      >
        <CustomCheckbox
          checkedCondition={isSelected}
          handleCheck={toggleSelection}
          transparent
        />
      </EntityOverlay>
    </div>
  );
};

FolderGridItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  isError: PropTypes.bool,
  isSelected: PropTypes.bool,
  toggleSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func,
  userCanManage: PropTypes.bool,
  dragAndDropAcceptType: PropTypes.string,
  onDropAssets: PropTypes.func,
};

export default FolderGridItem;
