import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RoundedModalWrapper from '../RoundedModalWrapper';
import Preloader from '../Preloader';
import { childrenPropType } from '../../../types/general';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import './ConfirmRoundModal.scss';


const ConfirmRoundModal = ({
  isOpen,
  title,
  text,
  textAlign,
  agreeText,
  agreeIcon,
  cancelText,
  cancelIcon,
  onAgree,
  onClose,
  loading,
  loadingText,
  children,
}) => {
  return (
    <RoundedModalWrapper
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      confirm
    >
      <div
        className={classnames('confirm-round-modal__content', {
          'loading': loading,
        })}
      >
        {loading ?
          <Preloader preloaderText={loadingText} /> :
          <>
            <p
              className='confirm-round-modal__text'
              style={{
                textAlign,
              }}
            >
              {text}
            </p>
            {children}
            <div className='confirm-round-modal__buttons-container'>
              <button
                className='confirm-round-modal__button'
                onClick={onClose}
              >
                {cancelIcon ?? <CloseIcon className='icon-inside-button' />}
                <span>{cancelText}</span>
              </button>
              <button
                className='confirm-round-modal__button'
                onClick={onAgree}
              >
                {agreeIcon ?? <DeleteIcon className='icon-inside-button' />}
                <span>{agreeText}</span>
              </button>
            </div>
          </>
        }
      </div>
    </RoundedModalWrapper>

  );
};

ConfirmRoundModal.defaultProps = {
  textAlign: 'center',
  agreeText: 'delete',
  cancelText: 'cancel',
};

ConfirmRoundModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  textAlign: PropTypes.string,
  agreeText: PropTypes.string,
  agreeIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  cancelText: PropTypes.string,
  cancelIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  children: childrenPropType,
};

export default ConfirmRoundModal;
