import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { LOGIN_PAGE, ACCESS_LOCATION } from '../../../constants/General';

const PrivateRoute = ({ isAuthenticated, hasLoggedOut, ...props }) => {
  if (isAuthenticated) {
    const accessLocation = localStorage.getItem(ACCESS_LOCATION);

    if (accessLocation) {
      localStorage.removeItem(ACCESS_LOCATION);
      return <Redirect to={accessLocation} />;
    } else {
      return <Route {...props} />;
    }
  }

  if (!hasLoggedOut) {
    const pathname = window.location.pathname;
    localStorage.setItem(ACCESS_LOCATION, pathname);
  }

  return <Redirect to={LOGIN_PAGE} />;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  hasLoggedOut: PropTypes.bool,
};

export default PrivateRoute;
