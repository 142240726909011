/**
 * @param {number} id
 * @param {string} name
 * @param {bool} enable
 * @param {object} access
 */
class GameModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.enabled = false;
    this.access = {
      preview: true,
      download: false,
      upload: false,
    };
  }
}

export default GameModel;