import { Record } from 'immutable';

import {
  SET_SELECTED_CREATIVES,
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_FAIL,
  GET_ALL_EMAILS_REQUEST,
  GET_ALL_EMAILS_SUCCESS,
  GET_ALL_EMAILS_FAIL,
  OPEN_MODAL_DIALOG_REPORTS,
  CLOSE_MODAL_DIALOG_REPORTS,
} from './actionTypes';

const InitialState = Record({
  isFetching: false,
  selected: [],
  emailList: [],
  isOpenedModal: false,
  error: null,
});

export const actionHandlers = {

  [SEND_REPORT_REQUEST]: (state) => state.withMutations((ctx) => {
    ctx.set('isFetching', true);
  }),
  [SEND_REPORT_SUCCESS]: (state) => state.withMutations((ctx) => {
    ctx.set('isFetching', false)
      .set('error', null);
  }),
  [SEND_REPORT_FAIL]: (state, action) => state.withMutations((ctx) => ctx.set('isFetching', false)
    .set('error', action.error)),

  [GET_ALL_EMAILS_REQUEST]: (state) => state.withMutations((ctx) => ctx.set('isFetching', true)),
  [GET_ALL_EMAILS_SUCCESS]: (state, action) => state.withMutations((ctx) => ctx.set('isFetching', false)
    .set('error', null))
    .set('emailList', action.emails),
  [GET_ALL_EMAILS_FAIL]: (state, action) => state.withMutations((ctx) => ctx.set('isFetching', false)
    .set('error', action.error)),

  [SET_SELECTED_CREATIVES]: (state, action) => state.withMutations((ctx) => {
    ctx.set('selected', action.selected);
  }),

  [OPEN_MODAL_DIALOG_REPORTS]: (state) => state.withMutations((ctx) => ctx.set('isOpenedModal', true)),
  [CLOSE_MODAL_DIALOG_REPORTS]: (state) => state.withMutations((ctx) => ctx.set('isOpenedModal', false)),
};

const initialState = new InitialState();
export const reducers = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducers;
