import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import SearchBoxRounded from 'components/UIComponents/SearchBoxRounded';
import NavigateBack from '../../Parts/NavigateBack';
import AssetUploaderContext from 'components/AssetUploader/assetUploaderContext';
import { collectionOwnerPropTypes } from '../../Types';

import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';

import './CollectionPanel.scss';


const CollectionPanel = ({ canUploadAssets, name, owner, searchValue, setSearchValue }) => {

  const selectAssetsToUpload = useContext(AssetUploaderContext);
  const { jiraId, googleId } = useSelector((state) => state.user?.profile);

  const getNavigationRoute = () => {
    if (jiraId) {
      return 'collections';
    }

    if (googleId) {
      return 'search';
    }

    return '';
  };

  return (
    <div className='collection-panel'>
      <NavigateBack
        route={getNavigationRoute()}
      />

      <div className='collection-panel__body'>

        <div className='collection-panel-main-info'>
          <h4 className='collection-panel-main-info__name'>{name}</h4>
          <div className='collection-panel-main-info__owner'>
            <span>Created by</span>
            {owner?.avatar ?
              <Avatar
                className='collection-panel-main-info__owner-avatar'
                src={owner.avatar}
                alt='Author'
              /> :
              <Avatar className='collection-panel-main-info__owner-avatar'>
                {owner?.name[0]}
              </Avatar>
            }
            <span className='collection-panel-main-info__owner-name'>
              {owner?.name}
            </span>
          </div>
        </div>

        <SearchBoxRounded
          searchValue={searchValue}
          width={320}
          handleSearch={(newSearchValue) => setSearchValue(newSearchValue)}
        />

        {canUploadAssets &&
          <button
            className='collection-panel__upload-button'
            onClick={selectAssetsToUpload}
          >
            <CloudUploadIcon className='icon-inside-button' />
            <span>Upload media</span>
          </button>
        }
      </div>
    </div>
  );
};

CollectionPanel.propTypes = {
  canUploadAssets: PropTypes.bool,
  name: PropTypes.string.isRequired,
  owner: collectionOwnerPropTypes,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
};

export default CollectionPanel;
