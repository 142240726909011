import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Image as ImageIcon,
  Movie as MovieIcon,
  Audiotrack as AudiotrackIcon,
  FontDownload as FontDownloadIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';

import './File.scss';

const circularStyles = {
  width: '14px',
  height: '14px',
  transform: 'rotate(-90deg)',
};


const File = ({file, speed, totalSizeToUpload}) => {

  const {completed, success, name, size, type} = file;

  const [progress, setProgress] = React.useState(0);
  const [percentToAdd, setPercentToAdd] = React.useState(0);

  const selectIconDependingOfType = () => {
    if (type.includes('image')) {
      return <ImageIcon className='upload-progress__file-type-icon' />;
    } else if (type.includes('video')) {
      return <MovieIcon className='upload-progress__file-type-icon' />;
    } else if (type.includes('audio')) {
      return <AudiotrackIcon className='upload-progress__file-type-icon' />;
    } else {
      return <FontDownloadIcon className='upload-progress__file-type-icon' />;
    }
  };

  const selectIconDependingOfStatus = () => {
    if (completed && success) {
      return <CheckCircleIcon className='upload-progress__file-success-icon upload-progress__file-status-icon--success' />;
    } else if (completed && !success) {
      return <ErrorIcon className='upload-progress__file-fail-icon upload-progress__file-status-icon--fail' />;
    } else {
      return <CircularProgress style={circularStyles} className='upload-progress__circular' variant='determinate' value={progress} />;
    }
  };

  useEffect(() => {
    findPercentToAdd();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevState) => {
        if (prevState + percentToAdd >= 50) {
          clearInterval(timer);
          return prevState;
        } else {
          return prevState + percentToAdd;
        }
      });
    }, 500);

    if (completed) {
      setProgress(100);
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [completed, percentToAdd]);

  const findPercentToAdd = () => {
    const percentWhenUploadAllTogether = speed / 2 * 100 / size;
    const percentWhenUploadOneAfterAnother = speed / 2 * 100 / totalSizeToUpload;
    const averagePercent = (percentWhenUploadAllTogether + percentWhenUploadOneAfterAnother) / 2;

    setPercentToAdd(averagePercent);
  };

  return (
    <div className='upload-progress__file'>
      {selectIconDependingOfType()}

      <p className='upload-progress__file-name'>{name}</p>

      {selectIconDependingOfStatus()}
    </div>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
  speed: PropTypes.number.isRequired,
  totalSizeToUpload: PropTypes.number.isRequired,
};

export default File;
