/**
 * @param {string} id
 * @param {string} project_name
 * @param {string} reason
 * @param {string} game
 * @param {string} author
 */
class DeleteRequestModel {
  constructor(data) {
    this.id = data.metadata.id;
    this.project_name = data.metadata.title;
    this.reason = data.metadata.onDelete.reason;
    this.game = data.game.name;
    this.author = data.metadata.onDelete.userName;
  }
}

export default DeleteRequestModel;
