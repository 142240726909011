import { secondsToJiraHoursAndMinutes } from '../../creativeDashboardHelpers';
import { numberWithCommas } from 'utils/utils';


/**
 * @param {number} id
 * @param {string} jiraLink
 * @param {string} link
 * @param {object} preview
 * @param {string} department
 * @param {string} format
 * @param {number} amount
 * @param {number} cost
 * @param {string} status
 * @param {string} approved
 * @param {string} notes
 * @param {string} jiraPostNumber
 * @param {string} projectWorkflow
 * @param {number} estimatedHours
 * @param {number} actualHours
 * @param {string} pod
 * @param {string} reporter
 * @param {string} assignee
 * @param {string} projectManager
 * @param {string} dueDate
 */
class PostDashboardModel {
  constructor(data) {
    this.id = data.id;
    this.jiraLink = data.jiraUrl;
    this.link = data.pixelUrl;
    this.preview = {
      url: `/file-store/${data.storageUuid}/${data.mainImagePath}`,
      thumbnail: data.mediumPreviewUrl,
      type: data.mainImageType,
    };
    this.jiraPostNumber = data.issueKey;
    this.department = data.department;
    this.format = data.format;
    this.amount = data.amount;
    this.projectWorkflow = data.projectWorkflow;
    this.actualHours = secondsToJiraHoursAndMinutes(data.timeSpentSeconds);
    this.estimatedHours = secondsToJiraHoursAndMinutes(data.originalEstimateSeconds);
    this.cost = numberWithCommas(data.cost);
    this.status = data.status;
    this.approved = data.stateOfApprove;
    this.notes = data.note;
    this.pod = data.podCapacity?.title;
    this.reporter = data.reporter;
    this.assignee = data.assignee;
    this.projectManager = data.projectManager;
    this.dueDate = data.dueDate;
  }
}

export default PostDashboardModel;
