import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ModalWrapper from '../ModalWrapper';
import HeaderWithLine from '../HeaderWithLine';
import Preloader from '../Preloader';

import './ConfirmModal.scss';


const ConfirmModal = ({
  isOpen,
  header,
  text,
  agreeText,
  onAgree,
  onClose,
  loading,
  loadingText,
}) => {
  return (
    <ModalWrapper open={isOpen} onClose={onClose} >
      <div
        className={classnames('confirm-modal-content', {
          'loading': loading,
        })}
      >
        <HeaderWithLine header={header} />

        {loading ?
          <Preloader preloaderText={loadingText} /> :
          <>
            <p className='confirm-modal-content__text'>
              {text}
            </p>
            <div className='confirm-modal-content__buttons-container'>
              <button
                className='confirm-modal-content__button'
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className='confirm-modal-content__button'
                onClick={onAgree}
              >
                {agreeText}
              </button>
            </div>
          </>
        }
      </div>
    </ModalWrapper>
  );
};

ConfirmModal.defaultProps = {
  agreeText: 'delete',
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  agreeText: PropTypes.string,
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default ConfirmModal;
