import { findOutExtensionByWidthAndHeight } from '../../laboratoryHelpers';

/**
 * @param {number} id
 * @param {string} url
 * @param {string} thumbnail
 * @param {string} videoName
 * @param {string} [path]
 * @param {string} extension
 * @param {number} width
 * @param {number} height
 * @param {number} duration
 */
class VideoModel {
  constructor(data) {
    this.id = parseInt(data._meta.id);
    this.url = data.video_preview_url.raw;
    this.thumbnail = data.medium_preview_url.raw;
    this.videoName = data.asset_name.raw;
    this.path = data.path?.raw || null;
    this.extension = findOutExtensionByWidthAndHeight(data.width?.raw, data.height?.raw);
    this.width = parseInt(data.width?.raw);
    this.height = parseInt(data.height?.raw);
    this.duration = parseFloat(data.duration?.raw);
  }
}

export default VideoModel;
