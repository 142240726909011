import moment from 'moment';
import { FORMAT_DATE_WITHOUT_TIME } from 'constants/General';


/**
 * @param {object} data
 */
class CollectionModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.isPrivate = data.isPrivate;
    this.description = data.description;
    this.owner = {
      id: data.user?.id,
      name: data.user?.name,
      avatar: data.user?.avatarUrl,
    };
    this.createdAt = data.createdAt ? moment(data.createdAt).format(FORMAT_DATE_WITHOUT_TIME) : 'no data';
    this.totalFiles = data.totalFiles ?? 0;
    this.file = data.file;
  }
}

export default CollectionModel;