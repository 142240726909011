import {
  GET_POD_CAPACITY_START,
  GET_POD_CAPACITY_SUCCESS,
  GET_POD_CAPACITY_SUCCESS_SCROLL,
  GET_POD_CAPACITY_FAIL,

  START_CREATE_NEW_POD_CAPACITY,
  SET_EDITED_POD_CAPACITY,
  CANCEL_CREATE_OR_EDIT_POD_CAPACITY,
  UPDATE_EDITED_POD_CAPACITY_FIELD,
  VALIDATE_EDITED_POD_CAPACITY_FIELD,

  GET_LEADERSHIP_START,
  GET_LEADERSHIP_SUCCESS,
  GET_LEADERSHIP_FAIL,
} from './actionsTypes';
import {
  idForNewPodCapacity,
  newPodCapacityStructure,
  podCapacityFieldsNames,
} from '../podCapacityConstants';
import PodCapacityModel from '../Models/PodCapacityModel';


const initState = {
  podCapacity: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  editedPodCapacity: {},
  editedPodCapacityValidation: {},
  leadership: {
    loading: false,
    success: [],
    error: '',
  },
};

const podCapacityReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case GET_POD_CAPACITY_START:
      return {
        ...state,
        podCapacity: {
          ...state.podCapacity,
          loading: true,
        },
      };

    case GET_POD_CAPACITY_SUCCESS:
      return {
        ...state,
        podCapacity: {
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((pod) => new PodCapacityModel(pod)),
          },
          error: '',
        },
      };

    case GET_POD_CAPACITY_SUCCESS_SCROLL:
      return {
        ...state,
        podCapacity: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.podCapacity.success.data,
              ...payload.data.map((pod) => new PodCapacityModel(pod)),
            ],
          },
          error: '',
        },
      };

    case GET_POD_CAPACITY_FAIL:
      return {
        ...state,
        podCapacity: {
          ...state.podCapacity,
          loading: false,
          error: payload,
        },
      };

    case START_CREATE_NEW_POD_CAPACITY:
      return {
        ...state,
        editedPodCapacity: newPodCapacityStructure,
        podCapacity: {
          ...state.podCapacity,
          success: {
            ...state.podCapacity.success,
            data: [
              newPodCapacityStructure,
              ...state.podCapacity.success.data,
            ],
          },
        },
      };

    case SET_EDITED_POD_CAPACITY:
      return {
        ...state,
        editedPodCapacity: payload,
        editedPodCapacityValidation: {
          [podCapacityFieldsNames.TITLE]: null,
        },
        podCapacity: {
          ...state.podCapacity,
          success: {
            ...state.podCapacity.success,
            data: state.podCapacity.success.data.filter((pod) => pod.id !== idForNewPodCapacity),
          },
        },
      };

    case CANCEL_CREATE_OR_EDIT_POD_CAPACITY:
      return {
        ...state,
        editedPodCapacity: {},
        podCapacity: {
          ...state.podCapacity,
          success: {
            ...state.podCapacity.success,
            data: state.podCapacity.success.data.filter((pod) => pod.id !== idForNewPodCapacity),
          },
        },
      };

    case UPDATE_EDITED_POD_CAPACITY_FIELD:
      return {
        ...state,
        editedPodCapacity: {
          ...state.editedPodCapacity,
          [payload.name]: payload.value,
        },
      };

    case VALIDATE_EDITED_POD_CAPACITY_FIELD:
      return {
        ...state,
        editedPodCapacityValidation: {
          ...state.editedPodCapacityValidation,
          [payload.name]: payload.value,
        },
      };

    case GET_LEADERSHIP_START:
      return {
        ...state,
        leadership: {
          loading: true,
          success: [],
          error: '',
        },
      };

    case GET_LEADERSHIP_SUCCESS:
      return {
        ...state,
        leadership: {
          loading: false,
          success: payload,
          error: '',
        },
      };

    case GET_LEADERSHIP_FAIL:
      return {
        ...state,
        leadership: {
          loading: false,
          success: [],
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default podCapacityReducer;
