import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import AnalyticsEventItem from '../AnalyticsEventItem';

const styles = () => ({
  root: {
    marginTop: '10px',
  },
});

const AnalyticsEventListView = (props) => {
  const {
    analyticsEvents,
    isFetching,
    totalCount,
    error,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
  } = props;

  const createPageContainer = (
    <Table>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 15, 25, 50]}
            colSpan={3}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );

  if (error) {
    return <div>{error}</div>;
  }

  return isFetching
    ? <CircularProgress />
    : (
      <div>
        {createPageContainer}
        <div className='tgpoint-analytics-event-scroll'>
          {
            analyticsEvents.map((item) => item ? (
              <AnalyticsEventItem
                key={item.id}
                payload={item.payload}
                eventDate={item.eventDate}
                invalidFields={item.invalidFields}
                verified={item.verified}
              />)
              : <div>null</div>
            )
          }
        </div>
      </div>
    );
};

const invalidFieldPropType = PropTypes.shape({
  field_name: PropTypes.string.isRequired,
});

const analyticsEventPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    event_name: PropTypes.string,
    state_name: PropTypes.string,
    evtname: PropTypes.string,
  }).isRequired,
  verified: PropTypes.bool,
  invalidFields: PropTypes.arrayOf(invalidFieldPropType).isRequired,
  eventDate: PropTypes.number.isRequired,
});

AnalyticsEventListView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  analyticsEvents: PropTypes.arrayOf(analyticsEventPropType).isRequired,
  error: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    isFetching,
    error,
  } = state.analyticsEvent;

  return {
    isFetching,
    error,
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, null)(AnalyticsEventListView)
);
