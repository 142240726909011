import GameSettingsService from '../../../services/GameSettings';
import {
  GET_GAME_SETTING_START,
  GET_GAME_SETTING_FAIL,
  GET_GAME_SETTING_SUCCESS,

  UPDATE_GAME_SETTING_START,
  UPDATE_GAME_SETTING_FAIL,
  UPDATE_GAME_SETTING_SUCCESS,

  CREATE_GAME_SETTING_START,
  CREATE_GAME_SETTING_FAIL,
  CREATE_GAME_SETTING_SUCCESS,
} from './actionTypes';
import { getErrorMessage } from 'utils/utils';

export function getGameSettings(query) {
  return (dispatch) => {
    dispatch({
      type: GET_GAME_SETTING_START,
    });
    return GameSettingsService.findGameSettings(query)
      .then((res) => {
        dispatch({
          type: GET_GAME_SETTING_SUCCESS,
          res: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GAME_SETTING_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
}

export function updateGameSetting(systemId, data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_GAME_SETTING_START,
    });
    return GameSettingsService.updateGameSetting(systemId, data)
      .then((res) => {
        dispatch({
          type: UPDATE_GAME_SETTING_SUCCESS,
          systemId: systemId,
          res: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_GAME_SETTING_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
}

export function createGameSetting(data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_GAME_SETTING_START,
    });
    return GameSettingsService.createGameSetting(data)
      .then((res) => {
        dispatch({
          type: CREATE_GAME_SETTING_SUCCESS,
          game: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_GAME_SETTING_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
}
