import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as queryString from 'query-string';
import classnames from 'classnames';
import GoBackPanel from '../../UIComponents/GoBackPanel';
import CanDropWrapper from '../../UIComponents/CanDropWrapper';
import CustomMenu from '../../UIComponents/CustomMenu';
import ConfirmModal from '../../UIComponents/ConfirmModal';
import CreateOrEditInputFieldModal from '../../UIComponents/CreateOrEditInputFieldModal';
import CreativeHead from '../CreativeHead';
import CreativeSectionsMenu from './CreativeSectionsMenu';
import CreativeDriveLink from './CreativeDriveLink';
import CreativeGeneralInfo from '../CreativeGeneralInfo';
import CreativeHistory from '../CreativeHistory';
import CreativeFeedback from '../CreativeFeedback';
import CreativeStorage from '../CreativeStorage';
import { getCreativeByIdOrIssueKey } from '../Creative.redux/Actions/creativeActions';
import { getStorageData } from '../../Assets/Assets.redux/Actions/assetActions';
import {
  createNewTab,
  deleteTab,
  renameTab,
  setCurrentTabCreative,
} from '../Creative.redux/Actions/tabActions';
import { moveAssetAction } from '../../Assets/Assets.redux/Actions/assetActions';
import { SEARCH_CATEGORY_ENGINES } from '../../MainSearch/mainSearchConfig';
import { ROOT_DIR_PATH } from '../../MainSearch/mainSearchConstants';
import { GAME_ACCESS_TYPE } from 'constants/General';
import { VALIDATION } from 'constants/Validation';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';
import {
  CREATIVE_GENERAL_TABS_NAMES,
  CREATIVE_SECTIONS,
} from 'constants/Creatives';
import { sortCreativeTabs } from '../creativeHelpers';
import {
  checkIfExternalUser,
  checkPermissions,
} from '../../General/Permissions/Permission';

import './CreativeStructure.scss';


const CreativeStructure = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const creativePageRef = useRef(null);
  const { issueKey, id } = useParams();

  const {
    isFetching,
    currentTab,
    creativeStorageData: storageAssets,
    generalCreativeInfo,
  } = useSelector((state) => state.creatives);

  const {
    id: creativeId,
    game,
    tabs,
    createdAt,
    jiraIssue,
    mainImageType,
    mediumPreviewUrl,
    mainImagePath,
    user,
    storage,
    googleDriveLink,
  } = generalCreativeInfo;

  const {
    title: jiraIssueTitle,
    format: creativeFormat,
    status: jiraIssueStatus,
  } = jiraIssue || {};
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const assetId = parseInt(urlParams.get('assetId'));

  const [activeSection, setActiveSection] = useState(null);
  const [isCreateTabModal, setIsCreateTabModal] = useState(false);
  const [isRenameTabModal, setIsRenameTabModal] = useState(null);
  const [isEditCreative, setIsEditCreative] = useState(false);
  const [isDeleteTabModal, setIsDeleteTabModal] = useState(null);
  const [selectedAssetIdsSet, setSelectedAssetIdsSet] = useState(new Set());


  const creativeIdFromParams = id || issueKey;
  const searchParams = new URLSearchParams(location.search);
  const storageId = Number(searchParams.get('storageId'));
  const dirPath = searchParams.get('dirPath');
  const sortedTabs = useMemo(() => sortCreativeTabs(tabs), [tabs]);
  const selectedAssets = useMemo(
    () => storageAssets.filter((asset) => selectedAssetIdsSet.has(asset.id)),
    [storageAssets, selectedAssetIdsSet]
  );

  // Permissions
  const isUserExternal = checkIfExternalUser();
  const canManageCreative = checkPermissions(game?.id, GAME_ACCESS_TYPE.EDIT_CREATIVE_POST, user?.id) && !isUserExternal;
  const canManageAssets = checkPermissions(game?.id, GAME_ACCESS_TYPE.UPLOAD) && !isUserExternal;

  const initCreativeSection = () => {
    const parsedQuerySearch = queryString.parse(history.location.search);

    if ('section' in parsedQuerySearch) {
      handleSectionClick(parsedQuerySearch.section);
    } else if ('storageId' in parsedQuerySearch && tabs.length) {
      const neededTab = tabs?.find((tab) => tab.storageId === Number(parsedQuerySearch.storageId));

      if (!neededTab) {
        handleSectionClick(CREATIVE_SECTIONS.GENERAL_INFO);
        return;
      }

      dispatch(setCurrentTabCreative(neededTab));
      setActiveSection(CREATIVE_SECTIONS.STORAGE);
    } else {
      const finalTab = tabs.find((tab) => tab.name.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.FINAL);
      if (!finalTab) {
        handleSectionClick(CREATIVE_SECTIONS.GENERAL_INFO);
        return;
      }

      handleTabClick(finalTab);
    }
  };

  useEffect(() => {
    if (creativeId) {
      initCreativeSection();
    }
  }, [creativeId]);

  useEffect(() => {
    dispatch(getCreativeByIdOrIssueKey(creativeIdFromParams));
  }, [creativeIdFromParams]);

  useEffect(() => {
    setIsEditCreative(false);
  }, [activeSection]);

  const handleGameClick = () => {
    const searchQueryString = queryString.stringify({
      search_category: SEARCH_CATEGORY_ENGINES.GAME,
      gameId: game?.id,
      storageId: game?.gameAssetsStorageId,
      dirPath: ROOT_DIR_PATH,
    });

    history.replace({
      pathname: '/search',
      search: searchQueryString,
    });
  };

  const handleSectionClick = (name) => {
    setActiveSection(name);
    dispatch(setCurrentTabCreative(null));

    if (name !== CREATIVE_SECTIONS.STORAGE) {
      history.push({
        pathname: location.pathname,
        search: new URLSearchParams({
          section: name,
        }).toString(),
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveSection(CREATIVE_SECTIONS.STORAGE);
    dispatch(setCurrentTabCreative(tab));

    history.push({
      pathname: location.pathname,
      search: new URLSearchParams({
        storageId: tab.storageId,
        dirPath: '',
      }).toString(),
    });
  };

  const checkIfTaNameIsGeneral = (tabNameToCheck) => {
    return Object.values(CREATIVE_GENERAL_TABS_NAMES).includes(tabNameToCheck.toUpperCase());
  };

  const handleCreateNewTab = async (tabName) => {
    await dispatch(createNewTab({
      creativityId: Number(creativeId),
      name: tabName,
    }));
    await dispatch(getCreativeByIdOrIssueKey(creativeIdFromParams));
    setIsCreateTabModal(false);
  };

  const handleDeleteTab = async (tabToDelete) => {
    const filteredTabs = tabs.filter((tab) => tab.id !== tabToDelete.id);
    await dispatch(deleteTab(tabToDelete.id));
    setIsDeleteTabModal(null);

    if (filteredTabs.length) {

      if (activeSection === CREATIVE_SECTIONS.STORAGE) {
        dispatch(setCurrentTabCreative(filteredTabs[0]));
      }

      history.push({
        pathname: location.pathname,
        search: new URLSearchParams({
          storageId: (currentTab && currentTab.id === tabToDelete.id) ? filteredTabs[0].storageId : storageId,
          dirPath: '',
        }).toString(),
      });
    }
  };

  const handleRenameTab = async (newName) => {
    await dispatch(renameTab(isRenameTabModal.id, { name: newName }));
    await dispatch(getCreativeByIdOrIssueKey(creativeIdFromParams));
    setIsRenameTabModal(false);
  };

  const handleMoveAssets = async (asset, targetFolder, tabStorageId) => {
    const assetsToMove = selectedAssetIdsSet.size ? selectedAssets : [asset];

    for await (const assetToMove of assetsToMove) {
      await proceedMoveAsset(assetToMove, targetFolder, tabStorageId);
    }

     dispatch(getStorageData(storageId, dirPath));
  };

  const proceedMoveAsset = async (assetToMove, targetFolder, tabStorageId) => {
    const neededPath = targetFolder ? targetFolder + '/' : targetFolder;

    const data = {
      filePath: assetToMove.isFolder ? `${neededPath}${assetToMove.name}` : `${neededPath}${assetToMove.name}.${assetToMove.extension}`,
      docId: assetToMove.id,
      storageId: tabStorageId ? tabStorageId : storageId,
    };

    await dispatch(moveAssetAction(data));
  };

  let contentToDisplay;

  switch (activeSection) {
    case CREATIVE_SECTIONS.GENERAL_INFO:
      contentToDisplay = (
        <CreativeGeneralInfo
          isEditCreative={isEditCreative}
          setIsEditCreative={setIsEditCreative}
        />
      );
      break;
    case CREATIVE_SECTIONS.HISTORY:
      contentToDisplay = (
        <CreativeHistory creativeId={creativeId} />
      );
      break;
    case CREATIVE_SECTIONS.FEEDBACK:
      contentToDisplay = (
        <CreativeFeedback isUserExternal={isUserExternal} />
      );
      break;
    case CREATIVE_SECTIONS.STORAGE:
      contentToDisplay = (
        <CreativeStorage
          creativePageRef={creativePageRef}
          game={game}
          jiraIssueStatus={jiraIssueStatus}
          canManageAssets={canManageAssets}
          selectedAssetIdsSet={selectedAssetIdsSet}
          setSelectedAssetIdsSet={setSelectedAssetIdsSet}
          assetToOpenId={assetId}
          handleMoveAssets={handleMoveAssets}
          handleGameClick={handleGameClick}
        />
      );
      break;
    default:
      contentToDisplay = null;
  }

  return (
    <div ref={creativePageRef} className='creative-page'>
      <GoBackPanel
        handleGoBack={() => history.push('/search')}
      />

      <CreativeHead
        creativeId={creativeId}
        isFetching={isFetching}
        jiraIssueTitle={jiraIssueTitle}
        gameName={game?.name}
        mainPreviewUrl={`/file-store/${storage?.uuid}${mainImagePath}`}
        mainMediumPreviewUrl={mediumPreviewUrl}
        mainPreviewType={mainImageType}
        mainPreviewPath={mainImagePath}
        createdAt={createdAt}
        creativeFormat={creativeFormat}
        isEditCreative={isEditCreative}
        activeSection={activeSection}
        canManageCreative={canManageCreative}
        onEditCreative={() => setIsEditCreative(true)}
        handleGameClick={handleGameClick}
      />

      <div className='creative'>
        <section className='creative-panel'>
          <div className='creative-navigation'>
            {sortedTabs?.map((tab) => (
              <CanDropWrapper
                key={tab.id}
                path=''
                acceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
                tabStorageId={tab.storageId}
                onDropStorageItem={handleMoveAssets}
              >
                <div
                  className={classnames('creative-navigation__button', {
                    'active': currentTab?.id === tab.id,
                    'general': checkIfTaNameIsGeneral(tab.name),
                  })}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab.name}
                  {!checkIfTaNameIsGeneral(tab.name) && canManageAssets &&
                    <CustomMenu
                      onEdit={() => setIsRenameTabModal(tab)}
                      editText={'rename'}
                      onDelete={() => setIsDeleteTabModal(tab)}
                    />
                  }
                </div>
              </CanDropWrapper>
            ))}

            <CreativeSectionsMenu
              activeSection={activeSection}
              onSelect={handleSectionClick}
            />
          </div>

          {currentTab?.name?.toUpperCase() === CREATIVE_GENERAL_TABS_NAMES.PRODUCTION &&
            <CreativeDriveLink
              creativeId={creativeId}
              userId={user?.id}
              driveLink={googleDriveLink || ''}
            />
          }
        </section>

        <div className='creative-line' />

        <section className='creative-content'>
          {contentToDisplay}
        </section>
      </div>

      <CreateOrEditInputFieldModal
        isOpen={isCreateTabModal}
        type='create'
        header='Add new tab'
        subheader='enter name'
        placeholderText='Tab name'
        validationPattern={VALIDATION.COMMON_TEXT}
        validationText={'Please enter a valid name for tab'}
        validationTextForEmptyCase={'Tab name can\'t contain only spaces.'}
        onCancel={() => setIsCreateTabModal(false)}
        onAccept={handleCreateNewTab}
        loading={isFetching}
        loadingText='Creating tab'
      />

      <CreateOrEditInputFieldModal
        isOpen={!!isRenameTabModal}
        type='edit'
        header='Rename tab'
        subheader='enter name'
        currentValue={isRenameTabModal?.name}
        placeholderText='Tab name'
        validationPattern={VALIDATION.COMMON_TEXT}
        validationText={'Please enter a valid name for tab'}
        validationTextForEmptyCase={'Tab name can\'t contain only spaces.'}
        onCancel={() => setIsRenameTabModal(null)}
        onAccept={handleRenameTab}
        loading={isFetching}
        loadingText='Renaming tab'
      />

      <ConfirmModal
        isOpen={!!isDeleteTabModal}
        header='Delete tab'
        text={'Are you sure you want to delete this tab?'}
        onAgree={() => handleDeleteTab(isDeleteTabModal)}
        agreeText='delete'
        onClose={() => setIsDeleteTabModal(null)}
        loading={isFetching}
        loadingText='Deleting tab'
      />
    </div>
  );
};

export default CreativeStructure;
