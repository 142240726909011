
export const maxTextareaLength = 100;

export const AD_CREATE_IMAGE_OVERLAY_LABELS = {
  LOGO: 'logo',
  GENERAL: 'general',
};

export const AD_CREATE_BANNER_FORMATS = {
  PNG: 'png',
  JPG: 'jpeg',
};

export const AD_CREATE_PLAYER_DIMENSIONS = {
  width: 814,
  height: 458,
};

export const INDENT_FOR_DEFAULT_BUTTON = 10;
export const INDENT_FOR_GAME_LOGO = 10;

export const AD_CREATE_RATIOS = {
  LANDSCAPE_START: (1 + 16 / 9) / 2,
  LANDSCAPE: 16 / 9,
  LANDSCAPE_BREAK: 3,
};

export const AD_CREATE_RAW_TEXT_VALUES = {
  header: {
    size: '50px',
    width: '403px',
    height: '58px',
  },
  subheader: {
    size: '30px',
    width: '242px',
    height: '35px',
  },
  bodyText: {
    size: '16px',
    width: '129px',
    height: '18px',
  },
};