import UserService from 'services/User';
import {
  LOG_OUT_FAIL,
  LOG_OUT_START,
  LOG_OUT_SUCCESS,

  CHECK_AUTHORIZATION,

  SET_USER_JIRA_PROJECT_IDS,

  SYNC_JIRA_START,
  SYNC_JIRA_SUCCESS,
  SYNC_JIRA_FAIL,
} from './actionTypes';

export function syncJira() {
  return (dispatch) => {
    dispatch({
      type: SYNC_JIRA_START,
    });

    return UserService.syncJiraPermission()
      .then(() => {
        dispatch({
          type: SYNC_JIRA_SUCCESS,
        });
        window.location.reload();
      })
      .catch(() => {
        dispatch({
          type: SYNC_JIRA_FAIL,
        });
      });
  };
}

export function checkAuth() {
  return (dispatch) => {
    return UserService.authentication()
      .then((authenticationResult) => {
        const  {
          user,
          accessToken,
        } = authenticationResult;

        dispatch({
          type: CHECK_AUTHORIZATION,
          loggedIn: !!accessToken,
        });

        dispatch({
          type: SET_USER_JIRA_PROJECT_IDS,
          payload: user.jiraProjectIds,
        });
      })
      .catch(() => {
        dispatch({
          type: CHECK_AUTHORIZATION,
          loggedIn: false,
        });
      });
  };
}

export function logOut() {
  return (dispatch) => {
    dispatch({ type: LOG_OUT_START });

    return UserService.logOut()
      .then(() => dispatch({ type: LOG_OUT_SUCCESS }))
      .catch(() => dispatch({ type: LOG_OUT_FAIL }));
  };
}
