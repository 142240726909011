import React from 'react';
import PropTypes from 'prop-types';
import MaterialModal from '@material-ui/core/Modal';

import Player from '../Player';
import ModalCloseButton from '../ModalCloseButton';

import styles from './styles.module.scss';

const PlayerModal = ({ url, open, onClose }) => (
  <MaterialModal open={open} onClose={onClose} className={styles.modal}>
    <div className={styles.container}>
      <ModalCloseButton onClick={onClose} />

      <Player
        play
        url={url}
      />
    </div>
  </MaterialModal>
);

PlayerModal.propTypes = {
  url: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PlayerModal;
