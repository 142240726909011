import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import GoogleLogin from '../GoogleLogin';
import {
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import { START_PAGE } from 'constants/General';

import { ReactComponent as TiltingPointLogo } from 'assets/svg/logo/logo-with-text.svg';
import { ReactComponent as JiraLogo } from 'assets/svg/logo/jira-text-logo.svg';

import styles from './styles.module.scss';


const LogIn = () => {

  const {
    loggedIn,
    error,
  } = useSelector((state) => state.user);

  return loggedIn && !error
    ? <Redirect to={START_PAGE} />
    : (
      <div className={styles.container}>
        <Paper className={styles.loginContainer}>
          <TiltingPointLogo className={styles.tiltingPointLogo} />

          <Typography variant='h1'>
            Welcome To Pixel
          </Typography>

          <div className={styles.controls}>
            <JiraLogo className={styles.jiraLogo} />
            <Button
              color='primary'
              variant='contained'
              component='a'
              href='/auth/atlassian'
            >
              Login
            </Button>
          </div>

          <GoogleLogin />
        </Paper>
      </div>
    );
};

export default LogIn;
