import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  Container,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import DeviceInfoBlock from './DeviceInfoBlock';
import HeaderWithControls from '../UIComponents/HeaderWithControls';
import {
  findAnalyticsDevice,
  getAnalyticsDeviceByTpdid,
  setAnalyticsDeviceTestStatus,
} from './AnalyticsDevice.redux/actions';

import styles from './styles.module.scss';

const AnalyticsDevice = (props) => {
  const [idType, setIdType] = useState('tpdid');
  const [platform, setPlatform] = useState(null);

  const {
    error,
    isFetching,
    queryId,
    testDeviceiOS,
    testDeviceAndroid,
    testDeviceWinrt,
    testDeviceStandalone,
    testDeviceOther,
    deviceInfoIOS,
    deviceInfoAndroid,
    deviceInfoWinRT,
    deviceInfoStandalone,
    deviceInfoOther,
    findAnalyticsDevice,
    getAnalyticsDeviceByTpdid,
    setAnalyticsDeviceTestStatus,
  } = props;

  const handleIOSChange = () => {
    setAnalyticsDeviceTestStatus(deviceInfoIOS.tpdid, 'ios', !testDeviceiOS);
  };

  const handleAndroidChange = () => {
    setAnalyticsDeviceTestStatus(deviceInfoAndroid.tpdid, 'android', !testDeviceAndroid);
  };

  const handleWinRTChange = () => {
    setAnalyticsDeviceTestStatus(deviceInfoWinRT.tpdid, 'winrt', !testDeviceWinrt);
  };

  const handleStandaloneChange = () => {
    setAnalyticsDeviceTestStatus(deviceInfoStandalone.tpdid, 'standalone', !testDeviceStandalone);
  };

  const handleOtherChange = () => {
    setAnalyticsDeviceTestStatus(deviceInfoOther.tpdid, 'other', !testDeviceOther);
  };

  const handleQueryIdChange = (event) => {
    const query = {
      queryId: event.target.value,
      platform,
      idType,
    };

    if (idType === 'tpdid') {
      getAnalyticsDeviceByTpdid(event.target.value);
    } else {
      findAnalyticsDevice(query);
    }
  };

  const handleIdTypeChange = (event) => {
    const type = event.target.value;
    let platform;

    switch (type) {
      case 'idfv':
      case 'idfa':
        platform = 'ios';
        break;
      case 'gaid':
      case 'androidid':
        platform = 'android';
        break;
      case 'ashwid':
      case 'uwpadvertisingid':
        platform = 'winrt';
        break;
      case 'otherid':
        platform = 'other';
        break;
      default:
        platform = 'ios';
    }

    setIdType(type);
    setPlatform(platform);
  };

  const handleFindDevice = (event) => {
    event.preventDefault();

    if (idType === 'tpdid') {
      getAnalyticsDeviceByTpdid(queryId);
    } else {
      const query = {
        idType,
        queryId,
        platform,
      };
      findAnalyticsDevice(query);
    }
  };

  const deviceView = (
    <div>
      {isFetching
        ? <CircularProgress />
        : (
          <>
            {deviceInfoIOS && (
              <DeviceInfoBlock
                deviceInfo={deviceInfoIOS}
                platform='iOS'
                testDevice={testDeviceiOS}
                handleChange={handleIOSChange}
              />
            )}
            {deviceInfoAndroid && (
              <DeviceInfoBlock
                deviceInfo={deviceInfoAndroid}
                platform='Android'
                testDevice={testDeviceAndroid}
                handleChange={handleAndroidChange}
              />
            )}
            {deviceInfoWinRT && (
              <DeviceInfoBlock
                deviceInfo={deviceInfoWinRT}
                platform='WinRT'
                testDevice={testDeviceWinrt}
                handleChange={handleWinRTChange}
              />
            )}
            {deviceInfoStandalone && (
              <DeviceInfoBlock
                deviceInfo={deviceInfoStandalone}
                platform='Standalone'
                testDevice={testDeviceStandalone}
                handleChange={handleStandaloneChange}
              />
            )}
            {deviceInfoOther && (
              <DeviceInfoBlock
                deviceInfo={deviceInfoOther}
                platform='Other'
                testDevice={testDeviceOther}
                handleChange={handleOtherChange}
              />
            )}
          </>
        )}
    </div>
  );

  return (
    <Container maxWidth={false}>
      <HeaderWithControls
        titleChildren={<Typography variant='h1'>Analytics Device Viewer</Typography>}
      />
      <form className={styles.form} onSubmit={handleFindDevice}>
        <div className={styles.fieldsContainer}>
          <div className={styles.field}>
            <FormLabel>ID Type</FormLabel>
            <Select
              value={idType}
              variant='outlined'
              onChange={handleIdTypeChange}
              name='idType'
            >
              <MenuItem value='tpdid'>Tpdid</MenuItem>
              <MenuItem value='idfv'>IDFV</MenuItem>
              <MenuItem value='idfa'>IDFA</MenuItem>
              <MenuItem value='gaid'>GAID</MenuItem>
              <MenuItem value='androidid'>Android ID</MenuItem>
              <MenuItem value='ashwid'>ASHWID</MenuItem>
              <MenuItem value='uwpadvertisingid'>UWP Advertising ID</MenuItem>
              <MenuItem value='otherid'>Other ID</MenuItem>
            </Select>
          </div>
          <div className={styles.field}>
            <FormLabel>{idType}</FormLabel>
            <TextField
              value={queryId}
              variant='outlined'
              placeholder={idType}
              onChange={handleQueryIdChange}
              className={styles.queryId}
            />
          </div>
        </div>
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >
          Find Device
        </Button>
      </form>

      {error
        ? error
        : deviceView
      }
    </Container>
  );
};

const deviceInfoPropType = {
  device_name: PropTypes.string,
  device_model: PropTypes.string,
  primary_id: PropTypes.string,
  secondary_id: PropTypes.string,
};

AnalyticsDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  isFetching: PropTypes.bool,
  queryId: PropTypes.string.isRequired,
  testDeviceiOS: PropTypes.bool,
  testDeviceAndroid: PropTypes.bool,
  testDeviceWinrt: PropTypes.bool,
  testDeviceStandalone: PropTypes.bool,
  testDeviceOther: PropTypes.bool,
  deviceInfoIOS: PropTypes.shape(deviceInfoPropType),
  deviceInfoAndroid: PropTypes.shape(deviceInfoPropType),
  deviceInfoWinRT: PropTypes.shape(deviceInfoPropType),
  deviceInfoStandalone: PropTypes.shape(deviceInfoPropType),
  deviceInfoOther: PropTypes.shape(deviceInfoPropType),
  findAnalyticsDevice: PropTypes.func.isRequired,
  getAnalyticsDeviceByTpdid: PropTypes.func.isRequired,
  setAnalyticsDeviceTestStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    error,
    isFetching,
    queryId,
    testDeviceiOS,
    testDeviceAndroid,
    testDeviceWinrt,
    testDeviceStandalone,
    testDeviceOther,
    deviceInfoIOS,
    deviceInfoAndroid,
    deviceInfoWinRT,
    deviceInfoStandalone,
    deviceInfoOther,
  } = state.analyticsDevice;

  return {
    error,
    isFetching,
    queryId,
    testDeviceiOS,
    testDeviceAndroid,
    testDeviceWinrt,
    testDeviceStandalone,
    testDeviceOther,
    deviceInfoIOS,
    deviceInfoAndroid,
    deviceInfoWinRT,
    deviceInfoStandalone,
    deviceInfoOther,
  };
};

const mapDispatchToProps = {
  findAnalyticsDevice,
  getAnalyticsDeviceByTpdid,
  setAnalyticsDeviceTestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDevice);
