import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { childrenPropType } from 'types/general';

import './EntityOverlay.scss';


const EntityOverlay = ({ isActive, onDoubleClick, children }) => {
  return (
    <div
      className={classnames('entity-overlay', {
        'active': isActive,
      })}
      onDoubleClick={onDoubleClick || (() => {})}
    >
      {children}
    </div>
  );
};

EntityOverlay.propTypes = {
  isActive: PropTypes.bool,
  onDoubleClick: PropTypes.func,
  children: childrenPropType,
};

export default EntityOverlay;
