import colors from '../colors.module.scss';

import { MuiMenuItem } from './menu';

export const MuiAutocomplete = {
  popper: {
    width: '100%',
  },
  paper: {
    margin: 0,
    borderRadius: 0,
  },
  listbox: {
    margin: 0,
    padding: 0,
  },
  input: {
    padding: 0,
  },
  inputRoot: {
    '&[class*="MuiInput-root"]': {
      paddingBottom: '1.25em',
      '& .MuiAutocomplete-input:first-child': {
        padding: 0,
      },
    },
  },
  option: MuiMenuItem.root,
  noOptions: {
    ...MuiMenuItem.root,
    '&:hover:not($selected)': {},
  },
  popupIndicator: {
    '& svg': {
      width: '0.75em',
      height: '0.75em',
      '& path': {
        fill: colors.blue,
      },
    },
  },
  endAdornment: {
    top: 'calc(50% - 0.7em)',
    right: '0.5em',
  },
  tag: {
    padding: '0 0 0 0.5rem',
    borderRadius: '16px',
    backgroundColor: colors.blue,
    color: 'white',
    '& .MuiChip-deleteIcon': {
      marginLeft: '3px',
      marginRight: '5px',
    },
  },
};
