
export const GET_HEADER_TITLES_SUCCESS_UNIT_COST = 'GET_HEADER_TITLES_SUCCESS_UNIT_COST';
export const CHANGE_HEADER_TITLES_SUCCESS_UNIT_COST = 'CHANGE_HEADER_TITLES_SUCCESS_UNIT_COST';
export const SET_HEADER_TITLES_UNIT_COST = 'SET_HEADER_TITLES_UNIT_COST';
export const SET_HEADER_TITLES_EDIT_STATE_UNIT_COST = 'SET_HEADER_TITLES_EDIT_STATE_UNIT_COST';
export const SET_HEADER_TITLES_VALIDATION_UNIT_COST = 'SET_HEADER_TITLES_VALIDATION_UNIT_COST';

export const GET_CATEGORIES_SUCCESS_UNIT_COST = 'GET_CATEGORIES_SUCCESS_UNIT_COST';
export const TOGGLE_CATEGORY_UNIT_COST = 'TOGGLE_CATEGORY_UNIT_COST';
export const CHECK_CATEGORY_UNIT_COST = 'CHECK_CATEGORY_UNIT_COST';
export const CHECK_FORMAT_UNIT_COST = 'CHECK_FORMAT_UNIT_COST';
export const ADD_FORMAT_TO_FILTER_UNIT_COST = 'ADD_FORMAT_TO_FILTER_UNIT_COST';
export const CLEAR_CHECKED_FORMATS_UNIT_COST = 'CLEAR_CHECKED_FORMATS_UNIT_COST';

export const GET_FORMATS_START = 'GET_FORMATS_START';
export const GET_FORMATS_SUCCESS = 'GET_FORMATS_SUCCESS';
export const GET_FORMATS_SUCCESS_SCROLL = 'GET_FORMATS_SUCCESS_SCROLL';
export const GET_FORMATS_FAIL = 'GET_FORMATS_FAIL';

export const SET_FORMAT_FIELDS_VALIDATION = 'SET_FORMAT_FIELDS_VALIDATION';

export const SET_EMPTY_FORMAT = 'SET_EMPTY_FORMAT';
export const DELETE_EMPTY_FORMAT = 'DELETE_EMPTY_FORMAT';

export const TOGGLE_WAITING_FORMAT_STATE = 'TOGGLE_WAITING_FORMAT_STATE';
export const CLEAN_EDITABLE_FORMAT_REF = 'CLEAN_EDITABLE_FORMAT_REF';

export const SET_EDITABLE_FORMAT_INIT = 'SET_EDITABLE_FORMAT_INIT';
