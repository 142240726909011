import colors from '../colors.module.scss';

const outlineColor = colors.lightGrey;

export const MuiInput = {
  root: {
    padding: '1.25em',
    backgroundColor: 'white',
  },
  input: {
    padding: 0,
    fontWeight: 400,
  },
};

export const MuiInputBase = {
  input: {
    height: 'unset',
  },
};

export const MuiOutlinedInput = {
  root: {
    borderRadius: 0,
    '&:not($error):hover .MuiOutlinedInput-notchedOutline': {
      borderColor: colors.blue,
    },
  },
  notchedOutline: {
    borderColor: outlineColor,
    borderWidth: '2px',
  },
};
