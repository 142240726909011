import React from 'react';
import PropTypes from 'prop-types';
import { getErrorMessage } from 'utils/utils';
import history from 'utils/history';

import { ReactComponent as FailRequestIcon } from 'assets/svg/notifications/fail-request.svg';

import './ErrorFallback.scss';


const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className='error-fallback'>
      <FailRequestIcon className='error-fallback__icon' />
      <h1 className='error-fallback__title'>Oops something went wrong...</h1>
      <p className='error-fallback__message'>{getErrorMessage(error)}</p>
      <div className='error-fallback__buttons'>
        <button
          className='error-fallback__button'
          onClick={resetErrorBoundary}
        >
          Reset
        </button>
        <button
          className='error-fallback__button'
          onClick={() => {
            resetErrorBoundary();
            history.push('/home');
          }}
        >
          back to Home
        </button>
      </div>
    </div>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  resetErrorBoundary: PropTypes.func.isRequired,
};

export default ErrorFallback;

