export const LIMIT = 9999;

export const FRAMES_PER_SECOND = 24;

export const COLOR_TYPE = {
  SOLID: 'solid',
  GRADIENT: 'gradient',
  NONE: 'none',
};

export const VIEW_LIST = 'VIEW_LIST';
export const VIEW_GRID = 'VIEW_GRID';

export const UPLOAD_TYPES = {
  ALL: '*',
  IMAGE: 'image/*',
  VIDEO: 'video/*',
  NEW_DOCUMENT: null,
};

export const GENERAL_FILE_TYPES = {
  other: 0,
  ppt: 1,
  psd: 2,
  image: 3,
  video: 4,
};

export const MOBILE_WIDTH = 864;
export const DROPZONE_MAX_FILE_SIZE = 52428800; // 50MB
export const ASSET_UPLOADER_MAX_FILE_SIZE = 20000000000; // 20GB

export const API_PREFIX = '/api/v1/';
export const LOGIN_PAGE = '/login';
export const START_PAGE = '/';

export const USER_ROLES = [{
  id: 1,
  title: 'Super Admin',
}, {
  id: 2,
  title: 'Admin',
}, {
  id: 3,
  title: 'Editor',
}, {
  id: 4,
  title: 'User',
}];

export const PERMISSIONS = {
  ALL: ['Super Admin', 'Admin', 'Editor', 'User'],
  S_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  EDITOR: 'Editor',
  USER: 'User',
  DEFAULT: 'Guest',
};

export const DEPARTMENT_PERMISSION = {
  USER_ACQUISITION: 'User Acquisition',
  MARKETING: 'Marketing',
  DESIGN_ANALYSIS: 'Design & Analysis',
  EXECUTIVE: 'Executive',
  CREATIVE_SERVICES: 'Creative Services',
  BUSINESS_DEVELOPMENT: 'Business Development',
  TALENT: 'Talent',
  LIVE_OPERATIONS: 'Live Operations',
  PRODUCT_SUPPORT: 'Product Support',
  DEVELOPMENT: 'Development',
  CUSTOMER_SUPPORT: 'Customer Support',
  EXTERNAL_USER: 'External User',
};

export const HEADER_CONSTANTS = {
  LOCAL_TIME: 'local-time',
};

export const ACCESS_LOCATION = 'accessLocation';

export const FORMAT_DATE_AT_TIME = 'YYYY-MM-DD [at] HH:mm A';
export const FORMAT_DATE_WITHOUT_TIME = 'YYYY-MM-DD';

export const SIDEBAR_SECTIONS = {
  ADD_NEW_ITEM: 'addNewItem',
  GOOGLE_ACCOUNTS: 'googleAccounts',
  CREATIVE_DASHBOARD: 'creativeDashboard',
  OTHER: 'other',
  CREATIVE_TOOLS: 'creativeTools',
  GAME_SETTINGS: 'gameSettings',
  DATA_TOOLS: 'dataTools',
  CUSTOMER_SUPPORT_TOOL: 'customerSupportTool',
  QA_TOOL: 'qaTool',
  SETTINGS: 'settings',
};

export const GAME_ACCESS_TYPE = {
  DOWNLOAD: 'download',
  UPLOAD: 'upload',
  EDIT_CREATIVE_POST: 'edit_creative_post',
};
