import { Record } from 'immutable';

import {
  LOG_OUT_FAIL,
  LOG_OUT_START,
  LOG_OUT_SUCCESS,

  GET_PROFILE_FAIL,
  GET_PROFILE_START,
  GET_PROFILE_SUCCESS,

  GET_USERS_LIST_SUCCESS,

  CHECK_AUTHORIZATION,

  SET_USER_JIRA_PROJECT_IDS,

  SYNC_JIRA_START,
  SYNC_JIRA_SUCCESS,
  SYNC_JIRA_FAIL,

  CREATE_CREATIVE_API_KEY_START,
  CREATE_CREATIVE_API_KEY_SUCCESS,
  CREATE_CREATIVE_API_KEY_FAIL,

  REVOKE_CREATIVE_API_KEY_START,
  REVOKE_CREATIVE_API_KEY_SUCCESS,
  REVOKE_CREATIVE_API_KEY_FAIL,

  HIDE_CREATIVE_API_KEY,
} from './actionTypes';

const InitialState = Record({
  error: false,
  loggedIn: false,
  isCheckingLoginStatus: true,
  usersList: [],
  userJiraProjectIds: [],
  profile: {
    id: null,
    email: '',
    name: '',
    phone: null,
    job: '',
    biography: '',
    contactInfo: null,
    avatarUrl: '',
    departmentId: null,
    isVerified: false,
    isEnabled: false,
    createdAt: '',
    updatedAt: '',
    jiraId: null,
    role: '',
    roleId: null,
    department: {
      id: null,
      name: '',
      createdAt: '',
      updatedAt: '',
      created_at: '',
      updated_at: '',
    },
  },
  isFetching: true,
  hasLoggedOut: false,
  isSyncingJira: false,
  jiraSyncError: null,
  isFetchingCreativeApiKey: false,
  creativeApiKey: null,
  creativeApiKeyError: null,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {

    case CHECK_AUTHORIZATION: {
      return state.withMutations((ctx) => {
        ctx
          .set('loggedIn', action.loggedIn)
          .set('isCheckingLoginStatus', false);
      });
    }

    case SET_USER_JIRA_PROJECT_IDS: {
      return state.withMutations((ctx) => {
        ctx
          .set('userJiraProjectIds', action.payload);
      });
    }

    case LOG_OUT_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('error', false);
      });
    }

    case GET_PROFILE_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', true);
      });
    }

    case GET_PROFILE_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('error', false);
      });
    }

    case GET_PROFILE_SUCCESS: {
      return state.withMutations((ctx) => {
        const { profile } = action;

        ctx.set('isFetching', false)
          .set('profile', profile)
          .set('error', false);

        if (profile.creativeApiKey) {
          ctx.set('creativeApiKey', profile.creativeApiKey);
        }
      });
    }

    case GET_USERS_LIST_SUCCESS: {
      return state.withMutations((ctx) => {
        const { users } = action.users;
        const usersListSortedByName = users.sort((aUser, bUser) => aUser.name?.localeCompare(bUser?.name));

        ctx
          .set('usersList', usersListSortedByName);
      });
    }

    case LOG_OUT_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('loggedIn', false)
          .set('profile', {})
          .set('isFetching', false)
          .set('hasLoggedOut', true)
          .set('error', false);
      });
    }

    case LOG_OUT_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', true);
      });
    }

    case SYNC_JIRA_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('isSyncingJira', true)
          .set('jiraSyncError', null);
      });
    }

    case SYNC_JIRA_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isSyncingJira', false)
          .set('jiraSyncError', null);
      });
    }

    case SYNC_JIRA_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isSyncingJira', false)
          .set('jiraSyncError', null);
      });
    }

    case CREATE_CREATIVE_API_KEY_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingCreativeApiKey', true)
          .set('creativeApiKey', null)
          .set('creativeApiKeyError', null);
      });
    }

    case CREATE_CREATIVE_API_KEY_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingCreativeApiKey', false)
          .set('creativeApiKey', action.token);
      });
    }

    case CREATE_CREATIVE_API_KEY_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingCreativeApiKey', false)
          .set('creativeApiKeyError', action.error);
      });
    }

    case REVOKE_CREATIVE_API_KEY_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingCreativeApiKey', true)
          .set('creativeApiKeyError', null);
      });
    }

    case REVOKE_CREATIVE_API_KEY_SUCCESS: {
      return state.set('creativeApiKey', null);
    }

    case REVOKE_CREATIVE_API_KEY_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingCreativeApiKey', false)
          .set('creativeApiKeyError', action.error);
      });
    }

    case HIDE_CREATIVE_API_KEY: {
      if (state.creativeApiKey) {
        return state.set('creativeApiKey', '**********');
      }

      return state.set('creativeApiKey', null);
    }

    default: {
      return state;
    }
  }
}
