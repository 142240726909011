import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import UploadPathSelectionWrapper from '../UploadPathSelectionWrapper';
import CustomSelect from '../../UIComponents/CustomSelect';
import { getGames } from '../../Game/Game.redux/actions';
import { getCreatives } from '../../Creative/Creative.redux/Actions/creativeActions';
import { getStorageData } from '../../Assets/Assets.redux/Actions/assetActions';
import { UPLOAD_PLACE } from '../assetUploaderConstants';
import {
  getAssetCategoryByJiraIssueType,
  getGameAssetsClassificationByPath,
} from '../assetUploaderHelpers';
import {
  ASSET_CATEGORIES_FOR_GAME_ASSETS,
  ASSET_CATEGORIES_KEYS,
  ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY,
} from 'constants/Asset';
import { ROOT_DIR_PATH } from '../../MainSearch/mainSearchConstants';
import { assetToUploadPropTypes } from '../Types';


const UploadPathSelectionJiraUser = ({
  assetsToUpload,
  closeAssetUploader,
  setAssetsClassification,
  setUploadInfo,
  setUploadPlace,
}) => {

  const dispatch = useDispatch();

  const { userJiraProjectIds } = useSelector((state) => state.user);

  const {
    games,
    isFetching: isFetchingGames,
    error: gamesFetchingError,
  } = useSelector((state) => state.game);

  const { storages } = useSelector((state) => state.gamesView);

  const { isFetching: isFetchingGameStorageData } = useSelector((state) => state.assets);

  const {
    creatives,
    isFetching: isFetchingCreatives,
    error: creativesFetchingError,
  } = useSelector((state) => state.creatives);

  const filteredByPermissionGames = useMemo(
    () => games?.filter((game) => userJiraProjectIds.includes(game.jiraProjectId)) || [],
    [games, userJiraProjectIds]);

  const [isCreative, setIsCreative] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedCreative, setSelectedCreative] = useState(null);
  const [selectedCreativeTab, setSelectedCreativeTab] = useState(null);
  const [selectedGamePath, setSelectedGamePath] = useState({
    firstLevel: null,
    secondLevel: null,
    thirdLevel: null,
  });
  const [gameStorage, setGameStorage] = useState(null);

  useEffect(() => {
    dispatch(getGames());
  }, []);

  useEffect(() => {
    if (selectedGame) {
      if (!isCreative) {
        dispatch(getStorageData(selectedGame.gameAssetsStorageId, ''));
      } else {
        setSelectedCreative(null);
        setSelectedCreativeTab(null);
        dispatch(getCreatives({query: {gameId: selectedGame.id}}));
      }
    }
  }, [selectedGame, isCreative]);

  useEffect(() => {
    setSelectedCreativeTab(null);
  }, [selectedCreative]);

  useEffect(() => {
    setSelectedGamePath({
      firstLevel: null,
      secondLevel: null,
      thirdLevel: null,
    });
  }, [selectedGame]);

  useEffect(() => {
    if (selectedGame && storages[selectedGame.gameAssetsStorageId]) {
      setGameStorage(storages[selectedGame.gameAssetsStorageId]);
    }
  }, [storages, selectedGame]);

  const checkIfCanGoToNextStep = () => {
    if (isCreative) {
      return !!selectedCreativeTab;
    } else {
      const { firstLevel,  secondLevel, thirdLevel } = selectedGamePath;

      if (
        firstLevel &&
        (firstLevel.name !== ASSET_CATEGORIES_FOR_GAME_ASSETS.PRODUCTION_ASSETS &&
        firstLevel.name !== ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS)
      ) {
        return true;
      }

      if (
        firstLevel &&
        firstLevel.name === ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS &&
        secondLevel &&
        !ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY[secondLevel.name]?.length
      ) {
        return true;
      }

      return !!thirdLevel;
    }
  };

  const handleNextStep = () => {
    if (isCreative) {
      setUploadInfo({
        isCreative,
        gameId: selectedGame.id,
        creativityId: selectedCreative.id,
        storageId: selectedCreativeTab.storageId,
        creativeTabName: selectedCreativeTab.name,
        dirPath: '',
        gameRootFolder: '',
      });

      setAssetsClassification({
        [ASSET_CATEGORIES_KEYS.CATEGORY]: getAssetCategoryByJiraIssueType(selectedCreative.jiraIssue?.issueType),
        [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: null,
        [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: null,
        [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: null,
      });

      setUploadPlace(UPLOAD_PLACE.CREATIVE);
    } else {
      const folderToUpload =
        selectedGamePath.thirdLevel ||
        selectedGamePath.secondLevel ||
        selectedGamePath.firstLevel;

      setUploadInfo({
        isCreative,
        gameId: selectedGame.id,
        creativityId: null,
        storageId: folderToUpload.storageId,
        creativeTabName: '',
        dirPath: folderToUpload.path.substring(1),
        gameRootFolder: selectedGamePath.firstLevel.name,
      });

      setAssetsClassification(getGameAssetsClassificationByPath(folderToUpload.path.substring(1)));
      setUploadPlace(UPLOAD_PLACE.GAME);
    }
  };

  const getStorageFoldersByPath = (path) => {
    if (!gameStorage || !gameStorage[path]) {
      return [];
    }

    return gameStorage[path].filter((asset) => asset.isFolder);
  };

  return (
    <UploadPathSelectionWrapper
      assetsToUpload={assetsToUpload}
      closeAssetUploader={closeAssetUploader}
      isNextStepDisabled={!checkIfCanGoToNextStep()}
      handleNextStep={handleNextStep}
    >
      <CustomSelect
        label='is the asset related to Jira ticket?'
        data={[{id: 1, name: 'no', value: false}, {id: 2, name: 'yes', value: true}]}
        value={isCreative ? {id: 2, name: 'yes', value: true} : {id: 1, name: 'no', value: false}}
        handleSelect={(option) => setIsCreative(option.value)}
        pink
      />

      <CustomSelect
        label='Select Game folder'
        data={filteredByPermissionGames}
        value={selectedGame}
        handleSelect={(game) => setSelectedGame(game)}
        disabled={isFetchingGames || !!gamesFetchingError}
        withSearch
        pink
      />

      {!isCreative ?
        <>
          <CustomSelect
            label='Select folder level 1'
            data={getStorageFoldersByPath(ROOT_DIR_PATH)}
            value={selectedGamePath.firstLevel}
            handleSelect={(gameFolder) => {
              if (gameFolder.id !== selectedGamePath.firstLevel?.id) {
                setSelectedGamePath({
                  firstLevel: gameFolder,
                  secondLevel: null,
                  thirdLevel: null,
                });
              }

              if (!gameStorage[gameFolder?.name]) {
                dispatch(getStorageData(gameFolder.storageId, gameFolder.name));
              }
            }}
            disabled={!selectedGame || (isFetchingGameStorageData && !selectedGamePath.firstLevel) }
            withSearch
            pink
          />
          <CustomSelect
            label='Select folder level 2'
            data={getStorageFoldersByPath(selectedGamePath.firstLevel?.name)}
            value={selectedGamePath.secondLevel}
            handleSelect={(gameFolder) => {
              if (gameFolder.id !== selectedGamePath.secondLevel?.id) {
                setSelectedGamePath({
                  ...selectedGamePath,
                  secondLevel: gameFolder,
                  thirdLevel: null,
                });
              }

              if (!gameStorage[selectedGamePath.firstLevel.name + '/' + gameFolder.name]) {
                dispatch(getStorageData(gameFolder.storageId, gameFolder.path.substring(1)));
              }
            }}
            disabled={!selectedGamePath.firstLevel || (isFetchingGameStorageData && !selectedGamePath.secondLevel)}
            withSearch
            pink
          />
          <CustomSelect
            label='Select folder level 3'
            data={getStorageFoldersByPath(selectedGamePath.firstLevel?.name + '/' + selectedGamePath.secondLevel?.name)}
            value={selectedGamePath.thirdLevel}
            handleSelect={(gameFolder) => {
              setSelectedGamePath({
                ...selectedGamePath,
                thirdLevel: gameFolder,
              });
            }}
            disabled={!selectedGamePath.secondLevel || (isFetchingGameStorageData && !selectedGamePath.thirdLevel)}
            withSearch
            pink
          />
        </> :
        <>
          <CustomSelect
            label='Select Jira ticket'
            data={creatives || []}
            value={selectedCreative}
            handleSelect={(creative) => setSelectedCreative(creative)}
            disabled={!selectedGame || isFetchingCreatives || creativesFetchingError}
            withSearch
            pink
          />
          <CustomSelect
            label='Select folder'
            data={selectedCreative ? selectedCreative.tabs : []}
            value={selectedCreativeTab}
            handleSelect={(creativeTab) => setSelectedCreativeTab(creativeTab)}
            disabled={!selectedCreative}
            withSearch
            pink
          />
        </>
      }
    </UploadPathSelectionWrapper>
  );
};

UploadPathSelectionJiraUser.propTypes = {
  assetsToUpload: PropTypes.arrayOf(assetToUploadPropTypes),
  closeAssetUploader: PropTypes.func.isRequired,
  setAssetsClassification: PropTypes.func.isRequired,
  setUploadInfo: PropTypes.func.isRequired,
  setUploadPlace: PropTypes.func.isRequired,
};

export default UploadPathSelectionJiraUser;
