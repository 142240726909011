import React, { useRef, useState } from 'react';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import EntityDoNotUseOverlay from '../../Entities/Parts/EntityDoNotUseOverlay';
import Slider from '@material-ui/core/Slider';
import {
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
  VolumeDown as VolumeDownIcon,
  VolumeOff as VolumeOffIcon,
  VolumeUp as VolumeUpIcon,
} from '@material-ui/icons';

import './AudioPlayer.scss';


const AudioPlayer = ({ url, notApproved, onLoad, onError }) => {
  const audioRef = useRef();

  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = React.useState(0.2);
  const [muteVolume, setMuteVolume] = useState(false);

  const playedSecondsFormatted = moment.duration(timeProgress, 'seconds')
    .format('mm:ss', { trim: false });
  const durationFormatted = moment.duration(duration, 'seconds')
    .format('mm:ss', { trim: false });

  const handleAudioReady = (event) => {
    event.currentTarget.volume = volume;
    onLoad();
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const skipForward = () => {
    audioRef.current.currentTime += 10;
  };

  const skipBackward = () => {
    audioRef.current.currentTime -= 10;
  };

  const handleTimeProgressChange = (event, value) => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.currentTime = value;
    setTimeProgress(value);
  };

  const handleVolumeChange = (event, volumeValue) => {
    if (!audioRef.current) {
      return;
    }
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);

    if (volumeValue === 0) {
      setMuteVolume(true);
    } else {
      setMuteVolume(false);
    }
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) {
      return;
    }
    setMuteVolume(!muteVolume);
    audioRef.current.muted = !muteVolume;
  };

  return (
    <div className='audio-player'>
      <audio
        preload='metadata'
        ref={audioRef}
        src={url}
        autoPlay
        onCanPlay={handleAudioReady}
        onPlaying={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={(e) => setTimeProgress(e.currentTarget.currentTime)}
        onDurationChange={(e) => setDuration(e.currentTarget.duration)}
        onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        onError={onError}
      >
        <source type='audio/mpeg' src={url} />
      </audio>
      <div className='audio-player__controls-wrapper'>
        <div className='audio-player__controls'>
          <button onClick={skipBackward}>
            <FastRewindIcon />
          </button>
          <button onClick={togglePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </button>
          <button onClick={skipForward}>
            <FastForwardIcon />
          </button>
        </div>

        <div className='audio-player__progress'>
          <Slider
            max={duration}
            min={0}
            value={timeProgress}
            className='audio-player__progress-slider'
            onChange={handleTimeProgressChange}
          />
          <span className='audio-player__progress-time'>
            {playedSecondsFormatted} / {durationFormatted}
          </span>
        </div>
        
        <div className='audio-player__volume'>
          <button onClick={handleMuteUnmute}>
            {muteVolume ? (
              <VolumeOffIcon />
            ) : volume < 0.4 ? (
              <VolumeDownIcon />
            ) : (
              <VolumeUpIcon />
            )}
          </button>
          <Slider
            max={1}
            min={0}
            step={0.01}
            value={volume}
            className='audio-player__volume-slider'
            onChange={handleVolumeChange}
          />
        </div>
      </div>
    </div>
  );
};

AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  notApproved: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AudioPlayer;
