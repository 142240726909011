import AdminPanelDataTransformation from '../adminPanelHelpers';
import DeleteRequestModel from '../../Models/AdminPanelData/DeleteRequestModel';
import ProjectModel from '../../Models/AdminPanelData/ProjectModel';
import SocialNetworkModel from '../../Models/AdCreate/SocialNetworkModel';
import ButtonBannerItemModel from '../../Models/AdCreate/ButtonBannerItemModel';
import {
  // Preserve font face
  ADD_FONT_FACE,

  // Creative tools - notifications (delete requests)
  TOGGLE_PAGE_NOTIFICATION,
  GET_DELETE_REQUESTS_START,
  GET_DELETE_REQUESTS_SUCCESS,
  GET_DELETE_REQUESTS_FAIL,

  // Creative tools - projects
  GET_ADMIN_PROJECTS_START,
  GET_ADMIN_PROJECTS_SUCCESS,
  GET_ADMIN_PROJECTS_FAIL,

  // Creative tools - game settings
  GET_ALL_FONTS_SUCCESS,
  GET_ALL_GAMES_SUCCESS,
  GET_SETTINGS_SUCCESS,
  SET_SELECTED_GAME,

  // Ad Create - networks and sizes
  GET_SOCIAL_NETWORKS_AD_CREATE_START,
  GET_SOCIAL_NETWORKS_AD_CREATE_SUCCESS,
  GET_SOCIAL_NETWORKS_AD_CREATE_FAIL,
  SOCIAL_NETWORK_ACTION_AD_CREATE_START,
  SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS,
  SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL,
  SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_START,
  SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS,
  SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL,
  OPEN_SOCIAL_NETWORK_AD_CREATE,
  CLOSE_SOCIAL_NETWORK_AD_CREATE,

  // Ad Create - button templates
  GET_BUTTON_TEMPLATES_AD_CREATE_START,
  GET_BUTTON_TEMPLATES_AD_CREATE_SUCCESS,
  GET_BUTTON_TEMPLATES_AD_CREATE_FAIL,
  SET_CURRENT_BUTTON_OBJECT_AD_CREATE,
  BUTTON_TEMPLATE_ACTION_AD_CREATE_START,
  BUTTON_TEMPLATE_ACTION_AD_CREATE_SUCCESS,
  BUTTON_TEMPLATE_ACTION_AD_CREATE_FAIL,

  // Ad Create - text templates
  GET_TEXT_TEMPLATES_AD_CREATE_START,
  GET_TEXT_TEMPLATES_AD_CREATE_SUCCESS,
  GET_TEXT_TEMPLATES_AD_CREATE_FAIL,
  SET_CURRENT_TEXT_OBJECT_AD_CREATE,
  TEXT_TEMPLATE_ACTION_AD_CREATE_START,
  TEXT_TEMPLATE_ACTION_AD_CREATE_SUCCESS,
  TEXT_TEMPLATE_ACTION_AD_CREATE_FAIL,
} from './actionsTypes';

const initState = {
  // Font face set
  fontFaceSet: new Set(),

  // Creative tools - notifications (delete requests)
  currentPageNotification: 1,
  deleteRequestsData: [],
  adminDeleteRequestsGetting: {
    inProgress: false,
    errorMessage: '',
  },
  deleteRequestsTotalPages: 0,

  // Creative tools - projects
  adminProjects: [],
  adminProjectsGetting: {
    inProgress: false,
    errorMessage: '',
  },
  adminProjectsTotalPages: 0,
  adminProjectsTotalAssets: 0,

  // Creative tools - game settings
  allFonts: [],
  allColors: [],
  allGames: [],
  availableGames: [],
  gameFonts: [],
  gameColors: [],
  maxRandomVideosCount: 1,
  audioFadeOutDuration: 1000,
  gameForAssets: {},

  // Ad Create - networks and sizes
  socialNetworks: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  socialNetworkAction: {
    loading: false,
    success: false,
    error: '',
  },
  sizeOfSocialNetworkAction: {
    loading: false,
    success: false,
    error: '',
  },
  extendedSocialNetworkIds: [],

  // Ad Create - button templates
  buttonTemplates: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  buttonTemplateActionsState: {
    loading: false,
    success: false,
    error: '',
  },
  currentButtonObject: null,

  // Ad Create - text templates
  textTemplates: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  textTemplateActionsState: {
    loading: false,
    success: false,
    error: '',
  },
  currentTextObject: null,
};


const adminPanelReducer = (state = initState, action) => {
  const { type, payload } = action;
  let transformedAllGames;

  switch (type) {

    // Font face set
    case ADD_FONT_FACE:
      return {
        ...state,
        fontFaceSet: new Set([...state.fontFaceSet, payload]),
      };

    // Creative tools - notifications (delete requests)
    case TOGGLE_PAGE_NOTIFICATION:
      return {
        ...state,
        currentPageNotification: payload,
      };

    case GET_DELETE_REQUESTS_START:
      return {
        ...state,
        adminDeleteRequestsGetting: {
          inProgress: true,
          errorMessage: '',
        },
      };

    case GET_DELETE_REQUESTS_SUCCESS:
      return {
        ...state,
        deleteRequestsData: payload.data.map((request) => new DeleteRequestModel(request)),
        deleteRequestsTotalPages: new AdminPanelDataTransformation(payload).totalPages(),
        adminDeleteRequestsGetting: {
          inProgress: false,
          errorMessage: '',
        },
      };

    case GET_DELETE_REQUESTS_FAIL:
      return {
        ...state,
        adminDeleteRequestsGetting: {
          inProgress: false,
          errorMessage: payload,
        },
      };

    // Creative tools - projects
    case GET_ADMIN_PROJECTS_START:
      return {
        ...state,
        adminProjectsGetting: {
          inProgress: true,
          errorMessage: '',
        },
      };

    case GET_ADMIN_PROJECTS_SUCCESS:
      return {
        ...state,
        adminProjects: payload.data.map((project) => new ProjectModel(project)),
        adminProjectsTotalPages: new AdminPanelDataTransformation(payload).totalPages(),
        adminProjectsTotalAssets: payload.totalAssets,
        adminProjectsGetting: {
          inProgress: false,
          errorMessage: '',
        },
      };

    case GET_ADMIN_PROJECTS_FAIL:
      return {
        ...state,
        adminProjectsGetting: {
          inProgress: false,
          errorMessage: payload,
        },
      };

    // Creative tools - game settings
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        maxRandomVideosCount: payload.maxRandomVideosCount,
        audioFadeOutDuration: payload.audioFadeOutDuration,
      };

    case SET_SELECTED_GAME:
      return {
        ...state,
        gameForAssets: payload,
        gameColors: payload.colors,
        gameFonts: payload.fonts,
      };

    case GET_ALL_FONTS_SUCCESS:
      return {
        ...state,
        allFonts: new AdminPanelDataTransformation(payload).uniqueFonts(),
      };

    case GET_ALL_GAMES_SUCCESS:
      transformedAllGames = new AdminPanelDataTransformation(payload).games();

      return {
        ...state,
        allGames: transformedAllGames,
        availableGames: transformedAllGames.filter((game) => game.enabledForTrailer),
        allColors: new AdminPanelDataTransformation(transformedAllGames).uniqueColors(),
      };

    // Ad Create - networks and sizes
    case GET_SOCIAL_NETWORKS_AD_CREATE_START:
      return {
        ...state,
        socialNetworks: {
          ...state.socialNetworks,
          loading: true,
          error: '',
        },
      };

    case GET_SOCIAL_NETWORKS_AD_CREATE_SUCCESS:
      return {
        ...state,
        socialNetworks: {
          ...state.socialNetworks,
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((socialNetwork) => new SocialNetworkModel(socialNetwork, state.extendedSocialNetworkIds)),
          },
          error: '',
        },
      };

    case GET_SOCIAL_NETWORKS_AD_CREATE_FAIL:
      return {
        ...state,
        socialNetworks: {
          ...state.socialNetworks,
          loading: false,
          error: payload,
        },
      };

    case SOCIAL_NETWORK_ACTION_AD_CREATE_START:
      return {
        ...state,
        socialNetworkAction: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS:
      return {
        ...state,
        socialNetworkAction: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL:
      return {
        ...state,
        socialNetworkAction: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_START:
      return {
        ...state,
        sizeOfSocialNetworkAction: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS:
      return {
        ...state,
        sizeOfSocialNetworkAction: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL:
      return {
        ...state,
        sizeOfSocialNetworkAction: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case OPEN_SOCIAL_NETWORK_AD_CREATE:
      return {
        ...state,
        extendedSocialNetworkIds: [...state.extendedSocialNetworkIds, payload],
      };

    case CLOSE_SOCIAL_NETWORK_AD_CREATE:
      return {
        ...state,
        extendedSocialNetworkIds: state.extendedSocialNetworkIds.filter((id) => id !== payload),
      };

    // Ad Create - button templates
    case GET_BUTTON_TEMPLATES_AD_CREATE_START:
      return {
        ...state,
        buttonTemplates: {
          ...state.buttonTemplates,
          loading: true,
          error: '',
        },
      };

    case GET_BUTTON_TEMPLATES_AD_CREATE_SUCCESS:
      return {
        ...state,
        buttonTemplates: {
          ...state.buttonTemplates,
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((buttonTemplate) => new ButtonBannerItemModel(buttonTemplate)),
          },
          error: '',
        },
      };

    case GET_BUTTON_TEMPLATES_AD_CREATE_FAIL:
      return {
        ...state,
        buttonTemplates: {
          ...state.buttonTemplates,
          loading: false,
          error: payload,
        },
      };

    case BUTTON_TEMPLATE_ACTION_AD_CREATE_START:
      return {
        ...state,
        buttonTemplateActionsState: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case BUTTON_TEMPLATE_ACTION_AD_CREATE_SUCCESS:
      return {
        ...state,
        buttonTemplateActionsState: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case BUTTON_TEMPLATE_ACTION_AD_CREATE_FAIL:
      return {
        ...state,
        buttonTemplateActionsState: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case SET_CURRENT_BUTTON_OBJECT_AD_CREATE:
      return {
        ...state,
        currentButtonObject: payload,
      };

    // Ad Create - text templates
    case GET_TEXT_TEMPLATES_AD_CREATE_START:
      return {
        ...state,
        textTemplates: {
          ...state.textTemplates,
          loading: true,
          error: '',
        },
      };

    case GET_TEXT_TEMPLATES_AD_CREATE_SUCCESS:
      return {
        ...state,
        textTemplates: {
          ...state.textTemplates,
          loading: false,
          success: {
            ...payload,
            data: new AdminPanelDataTransformation(payload.data).textTemplates(),
          },
          error: '',
        },
      };

    case GET_TEXT_TEMPLATES_AD_CREATE_FAIL:
      return {
        ...state,
        textTemplates: {
          ...state.textTemplates,
          loading: false,
          error: payload,
        },
      };

    case TEXT_TEMPLATE_ACTION_AD_CREATE_START:
      return {
        ...state,
        textTemplateActionsState: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case TEXT_TEMPLATE_ACTION_AD_CREATE_SUCCESS:
      return {
        ...state,
        textTemplateActionsState: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case TEXT_TEMPLATE_ACTION_AD_CREATE_FAIL:
      return {
        ...state,
        textTemplateActionsState: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case SET_CURRENT_TEXT_OBJECT_AD_CREATE:
      return {
        ...state,
        currentTextObject: payload,
      };

    default:
      return state;
  }
};

export default adminPanelReducer;
