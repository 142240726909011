import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class AttributeService {
  constructor() {
    this.attribute = FeathersClient.service(`${API_PREFIX}attribute`);
    this.attributeOption = FeathersClient.service(`${API_PREFIX}attribute-option`);
  }

  getAllAttributes() {
    return this.attribute.find();
  }

  /**
   * @param {number} attributeId
   * @param {string} value
   */
  createNewAttributeOption(attributeId, value) {
    return this.attributeOption.create({ attributeId, value });
  }

  /**
   * @param {number} attributeOptionId
   * @param {string} value
   */
  updateAttributeOption(attributeOptionId, value) {
    return this.attributeOption.patch(attributeOptionId, { value });
  }

  /**
   * @param {number} attributeOptionId
   */
  deleteAttributeOption(attributeOptionId) {
    return this.attributeOption.remove(attributeOptionId);
  }
}

export default new AttributeService();
