import React from 'react';
import PropTypes from 'prop-types';

import './HeaderWithLine.scss';


const HeaderWithLine = ({header, notes}) => {
  return (
    <div className='header-with-line'>
      <h2 className='header-with-line__text'>{header}</h2>
      {notes && <p className='header-with-line__notes'>{notes}</p>}
    </div>
  );
};

HeaderWithLine.propTypes = {
  header: PropTypes.string,
  notes: PropTypes.string,
};

export default HeaderWithLine;
