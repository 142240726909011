import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import UploaderAssetCard from '../UploaderAssetCard';
import ButtonWithCheckbox from 'components/UIComponents/ButtonWithCheckbox';
import {
  assetToUploadErrorsPropTypes,
  assetToUploadPropTypes,
} from 'components/AssetUploader/Types';
import { WITHOUT_FOLDER_PATH } from 'components/AssetUploader/assetUploaderConstants';

import './UploaderFolderCard.scss';


const UploaderFolderCard = ({
  path,
  differentPathsCount,
  assets,
  submitCount,
  errors,
  selectedAssetIdsSet,
  setSelectedAssetIdsSet,
  removeAssetFromUploader,
}) => {

  const folderAssetsIds = useMemo(() => assets.map((assets) => assets.id), [assets]);
  const isOnlyFiles = useMemo(() => differentPathsCount === 1 && path === WITHOUT_FOLDER_PATH, [differentPathsCount, path]);

  const checkIfAssetSelected = (assetId) => {
    return selectedAssetIdsSet.has(assetId);
  };

  const isError = (assetId) => {
    return !!errors[assetId]?.description || (!!submitCount && !!errors[assetId]);
  };

  const toggleAssetSelection = (assetId) => {
    if (checkIfAssetSelected(assetId)) {
      selectedAssetIdsSet.delete(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      selectedAssetIdsSet.add(assetId);
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  const checkIfAllFolderAssetsSelected = () => {
    return folderAssetsIds.every((assetId) => selectedAssetIdsSet.has(assetId));
  };

  const toggleSelectAll = () => {
    if (checkIfAllFolderAssetsSelected()) {
      folderAssetsIds.forEach((assetId) => selectedAssetIdsSet.delete(assetId));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    } else {
      folderAssetsIds.forEach((assetId) => selectedAssetIdsSet.add(assetId));
      setSelectedAssetIdsSet(new Set(selectedAssetIdsSet));
    }
  };

  return (
    <div className='uploader-folder-card'>
      <div className='uploader-folder-card__header'>
        <div className='uploader-folder-card__info'>
          <p className='uploader-folder-card__path'>
            {isOnlyFiles ? 'FILES' : path}
          </p>
          <div className='uploader-folder-card__files-count'>{assets.length}</div>
        </div>
        <ButtonWithCheckbox
          text='select all'
          checkedCondition={checkIfAllFolderAssetsSelected()}
          toggleCheck={toggleSelectAll}
        />
      </div>

      <div className='uploader-folder-card__assets-list'>
        {assets.map((asset) => (
          <UploaderAssetCard
            key={asset.id}
            asset={asset}
            isSelected={checkIfAssetSelected(asset.id)}
            error={isError(asset.id)}
            toggleAssetSelection={() => toggleAssetSelection(asset.id)}
            onRemove={removeAssetFromUploader}
          />
        ))}
      </div>
    </div>
  );
};

UploaderFolderCard.propTypes = {
  path: PropTypes.string,
  differentPathsCount: PropTypes.number.isRequired,
  assets: PropTypes.arrayOf(assetToUploadPropTypes),
  submitCount: PropTypes.number.isRequired,
  errors: assetToUploadErrorsPropTypes,
  selectedAssetIdsSet: PropTypes.object.isRequired,
  setSelectedAssetIdsSet: PropTypes.func.isRequired,
  removeAssetFromUploader: PropTypes.func.isRequired,
};

export default UploaderFolderCard;
