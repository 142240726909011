import {
  GET_CREATIVES_START,
  GET_CREATIVES_FAIL,
  GET_CREATIVES_SUCCESS,

  GET_CREATIVE_BY_ID_START,
  GET_CREATIVE_BY_ID_SUCCESS,
  GET_CREATIVE_BY_ID_FAIL,

  UPDATE_CREATIVE_START,
  UPDATE_CREATIVE_SUCCESS,
  UPDATE_CREATIVE_FAIL,

  SET_MAIN_IMAGE_FROM_DOC_REQUEST,
  SET_MAIN_IMAGE_FROM_DOC_SUCCESS,
  SET_MAIN_IMAGE_FROM_DOC_FAIL,

  DELETE_CREATIVE_START,
  DELETE_CREATIVE_FAIL,
  DELETE_CREATIVE_SUCCESS,
} from '../actionTypes';
import Creative from 'services/Creative';
import history from 'utils/history';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export function getCreatives(params) {
  return (dispatch) => {
    dispatch({
      type: GET_CREATIVES_START,
    });

    return Creative.getCreatives(params)
      .then((result) => {
        dispatch({
          type: GET_CREATIVES_SUCCESS,
          creatives: result.data,
        });

      })
      .catch((error) => {
        dispatch({
          type: GET_CREATIVES_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
}

export const getCreativeByIdOrIssueKey = (id) => (dispatch) => {
  dispatch({
    type: GET_CREATIVE_BY_ID_START,
  });

  const isJiraKey = isNaN(parseInt(id, 10));

  const params = isJiraKey ?
    {
      query: {jiraIssue: {key: id}},
    } :
    id;

  const getCreative = isJiraKey
    ? Creative.getCreatives
    : Creative.getCreativeById;

  return getCreative.bind(Creative)(params)
    .then((res) => {
      dispatch({
        type: GET_CREATIVE_BY_ID_SUCCESS,
        payload: isJiraKey ? res.data[0] : res,
      });
      return res;
    })
    .catch((error) => {
      history.push('/');
      dispatch({
        type: GET_CREATIVE_BY_ID_FAIL,
        error: getErrorMessage(error),
      });
    });
};

export const updateCreative = (id, data) => (dispatch) => {
  dispatch({
    type: UPDATE_CREATIVE_START,
  });
  return Creative.updateCreative(id, data)
    .then((updateCreative) => {
      dispatch({
        type: UPDATE_CREATIVE_SUCCESS,
        payload: updateCreative,
      });

      if ('googleDriveLink' in data) {
        dispatch(addGeneralMessageAction({
          error: false,
          text: notifications.creative.success.drive_link.text,
          description: notifications.creative.success.drive_link.description,
          lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
        }));
      } else {
        dispatch(addGeneralMessageAction({
          error: false,
          text: notifications.creative.success.update.text,
          description: notifications.creative.success.update.description,
          lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
        }));
      }
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_CREATIVE_FAIL,
        error: getErrorMessage(error),
      });

      if ('googleDriveLink' in data) {
        dispatch(addGeneralMessageAction({
          error: true,
          text: notifications.creative.fail.drive_link.text,
          description: getErrorMessage(error),
          lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
        }));
      } else {
        dispatch(addGeneralMessageAction({
          error: true,
          text: notifications.creative.fail.update.text,
          description: getErrorMessage(error),
          lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
        }));
      }
    });
};

export const setCreativeMainCover = (creativityId, docId, videoTimestamp) => (dispatch) => {
  dispatch({
    type: SET_MAIN_IMAGE_FROM_DOC_REQUEST,
  });

  return Creative.setMainImage({
    creativityId,
    docId,
    videoTimestamp, // required only for video docs
  })
    .then(() => {
      dispatch({
        type: SET_MAIN_IMAGE_FROM_DOC_SUCCESS,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.creative.success.main_image.text,
        description: notifications.creative.success.main_image.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: SET_MAIN_IMAGE_FROM_DOC_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.creative.fail.main_image.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteCreative = (id) => (dispatch) => {
  dispatch({ type: DELETE_CREATIVE_START });

  return Creative.removeCreative(id)
    .then(() => {
      dispatch({ type: DELETE_CREATIVE_SUCCESS });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.creative.success.delete.text,
        description: notifications.creative.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));

      history.push('/');
    })
    .catch((error) => {
      dispatch({ type: DELETE_CREATIVE_FAIL, error: getErrorMessage(error) });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.creative.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
