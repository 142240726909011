export const requiredError = 'Required field';
export const textFormatError = 'Must contain only letters from latin alphabet, numbers and common symbols';
export const maxError = ({ max }) => `Value should be shorter than ${max} characters`;

export const VALIDATION = {
  /*eslint max-len: 0, no-useless-escape: 0*/
  ASSET_NAME: /^[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEFA-Za-z0-9 \-\._]{1,100}$/,
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/,
  TOKEN: /^\w{8}\-\w{4}\-\w{4}\-\w{4}\-\w{12}$/,
  FILE_STORAGE_ITEM_NAME: /([\/:*?"<>|'%$#@!()+=;`])|(^[\s]+$)/,
  TICK_INPUT_DATE: /^(|[0-9]|1[0-2]|0[1-9])(|\/|\/((|[0-9]|[01][1-9]|2[0-9]|3[01]))(|\/|\/(2|20|20[0-9]{0,2})))$/,
  TICK_INPUT_FULL_DATE: /^(1[0-2]|[1-9]|0[1-9])\/((0[1-9]|[1-2][0-9]|3[01]|[1-9])\/)20[0-9]{2}$/,
  TICK_INPUT_TIME: /^(|([0-9]|[10][0-9]|2[0-3])(|:(|[0-9]|[0-5][0-9]|6[0]{0,2}))|2[0-4](|:[0]{0,2}))$/,
  TICK_INPUT_IS_ERROR_TIME: /^(|:|[0-9]+:|:[0-9]+|0{1,4}|0{1,2}:0{1,2})$/,
  CREATIVE_POST_TITLE: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,255}$/,
  COMMON_TEXT: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,255}$/,
  LOWER_THIRD: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@\n]){1,100}$/,
  AD_CREATE_PROJECT_NAME: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,255}$/,
  AD_CREATE_NETWORK_NAME: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){1,100}$/,
  AD_CREATE_SIZE_DIMENSIONS: /^[0-9]{1,4}$/,
  DELETE_REQUEST: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,}$/,
  VIDEO_PROJECT_NAME: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,}$/,
  GAME_STUDIO_LEVEL: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@]){0,30}$/,
  DASHBOARD_TEXT_WITH_COLON: /^([a-zA-Z0-9 :!#$%&'*+-/=?^_`{|}~.,@\n]){0,}$/,
  DASHBOARD_TEXT: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@\n]){0,}$/,
  POD_CAPACITY_TITLE: /^([a-zA-Z0-9 !#$%&'*+-/=?^_`{|}~.,@"\n]){1,255}$/,
};
