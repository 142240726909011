import { Record } from 'immutable';
import {
  SET_FILTER,
  CLEAR_FILTERS,
} from './actionTypes';
import { LIMIT } from '../../../constants/General';

const Filters = Record({
  categoryId: [],
  platformId: [],
  formatId: [],
  statusId: [],
  sizeId: [],
  departmentId: [],
  issueTypeId: [],
  ownerIds: [],
  createdAtFrom: [],
  createdAtTill: [],
  localCreatedAtFrom: [],
  localCreatedAtTill: [],
  updatedAtFrom: [],
  updatedAtTill: [],
  localUpdatedAtFrom: [],
  localUpdatedAtTill: [],
  gameId: [],
  limit: LIMIT,
});

const InitialState = Record({
  filters: new Filters(),
  list: [],
  formatList: [],
  sizeList: [],
  platformList: [],
  statusList: [],
  issueTypeId: [],
  error: null,
  isFetching: false,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_FILTER: {
      return state.withMutations((ctx) => {
        const {
          filterType,
          newFilters,
        } = action;
        ctx.setIn(['filters', filterType], newFilters);
      });
    }

    case CLEAR_FILTERS: {
      return state.withMutations((ctx) => {
        ctx.set('filters', new Filters());
      });
    }

    default: {
      return state;
    }
  }
}
