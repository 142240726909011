import React from 'react';

import { childrenPropType } from '../../../types/general';

import styles from './styles.module.scss';

const HeaderWithControls = ({ titleChildren, controlsChildren }) => (
  <div className={styles.header}>
    {titleChildren}
    {controlsChildren}
  </div>
);

HeaderWithControls.propTypes = {
  titleChildren: childrenPropType,
  controlsChildren: childrenPropType,
};

export default HeaderWithControls;
