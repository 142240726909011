import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Avatar,
  TextField,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@material-ui/icons';
import Preloader from '../../UIComponents/Preloader';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import FailRequestScreen from '../../UIComponents/FailRequestScreen';
import { FORMAT_DATE_AT_TIME } from 'constants/General';
import {
  maxError,
  requiredError,
} from 'constants/Validation';
import {
  getJiraIssueComment,
  addJiraIssueComment,
  deleteJiraIssueComment,
} from '../Creative.redux/Actions/commentActions';

import './CreativeFeedback.scss';

const validationSchema = Yup.object({
  feedback: Yup.string().max(4096, maxError).required(requiredError),
});

const COMMENT_CONTENT_TYPE = {
  TEXT: 'text',
  MEDIA: 'media',
  BREAK: 'hardBreak',
  INLINE_CARD: 'inlineCard',
  MENTION: 'mention',
};

const CreativeFeedback = ({ isUserExternal }) => {

  const dispatch = useDispatch();

  const {
    jiraId: currentUserJiraAccountId,
    name: currentUserName,
    avatarUrl: currentUserAvatarUrl,
  } = useSelector((state) => state.user.profile);

  const {
    generalCreativeInfo,
    isFetchingJiraIssueComments,
    jiraIssueComments,
    fetchingJiraIssueCommentsError,
  } = useSelector((state) => state.creatives);

  const { jiraIssue } = generalCreativeInfo;
  const { id: jiraIssueId } = jiraIssue || {};
  const initialValues = { feedback: '' };

  const transformedFeedbacks = jiraIssueComments.map(({ id, author, created, content }) =>
    ({
      id,
      authorId: author.accountId,
      authorName: author.displayName,
      authorAvatar: author.avatarUrls['48x48'],
      createdAt: created,
      content,
    }));

  const [isDeleteCommentModal, setIsDeleteCommentModal] = useState(null);

  useEffect(() => {
    if (jiraIssueId) {
      dispatch(getJiraIssueComment(jiraIssueId));
    }
  }, [jiraIssueId]);

  const handleAddJiraIssueComment = (comment) => {
    dispatch(addJiraIssueComment(jiraIssueId, comment));
  };

  const handleSubmitFeedback = async ({ feedback }, { resetForm }) => {
    await handleAddJiraIssueComment(feedback);
    resetForm(initialValues);
  };

  const handleDeleteJiraIssueComment = async (commentId) => {
    await dispatch(deleteJiraIssueComment(jiraIssueId, commentId));
    setIsDeleteCommentModal(null);
  };

  const renderCommentPiece = (commentPiece, commentPieceIndex) => {
    switch (commentPiece.type) {
      case COMMENT_CONTENT_TYPE.TEXT:
        if (commentPiece.text.trim()) {
          return (
            <p
              key={commentPieceIndex}
              className='creative-feedback__comment-text'
            >
              {commentPiece.text}
            </p>
          );
        }
        break;
      case COMMENT_CONTENT_TYPE.MEDIA:
        return (
          <a
            key={commentPieceIndex}
            className='creative-feedback__comment-media-link'
            href={commentPiece.text}
            target='_blank'
            rel='noopener noreferrer'
          >
            <InsertDriveFileIcon className='creative-feedback__comment-media-icon' />
            <span>view media file</span>
          </a>
        );
      case COMMENT_CONTENT_TYPE.MENTION:
        return (
          <a
            key={commentPieceIndex}
            className='creative-feedback__comment-user-link'
            href={`https://tiltingpoint.atlassian.net/jira/people/${commentPiece.attrs.id}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {commentPiece.attrs.text}
          </a>
        );
      case COMMENT_CONTENT_TYPE.INLINE_CARD:
        return (
          <a
            key={commentPieceIndex}
            className='creative-feedback__comment-link'
            href={commentPiece.attrs.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            {commentPiece.attrs.url}
          </a>
        );
      case COMMENT_CONTENT_TYPE.BREAK:
        return <br key={commentPieceIndex} />;
      default:
        return null;
    }
  };

  let comments = null;

  if (isFetchingJiraIssueComments) {
    comments = <Preloader preloaderText={'Fetching comments'} />;
  } else if (fetchingJiraIssueCommentsError) {
    comments = <FailRequestScreen message={fetchingJiraIssueCommentsError} />;
  } else if (!transformedFeedbacks.length) {
    comments = (
      <div className='creative-feedback__no-comments'>
        No feedbacks have been created yet
      </div>
    );
  } else if (transformedFeedbacks.length) {
    comments = (
      <>
        <h4 className='creative-feedback__comments-list-title'>
          all feedbacks
        </h4>

        {transformedFeedbacks.map((comment) => {
          const {
            id,
            authorId,
            authorName,
            authorAvatar,
            createdAt,
            content,
          } = comment;

          return (
            <div key={id} className='creative-feedback__comment'>

              <div className='creative-feedback__comment-left'>
                {authorAvatar ?
                  <Avatar
                    className='creative-feedback__user-avatar'
                    src={authorAvatar}
                    alt='Comment author avatar'
                  /> :
                  <Avatar className='creative-feedback__user-avatar'>
                    {authorName ? authorName[0] : 'A'}
                  </Avatar>
                }
              </div>

              <div className='creative-feedback__comment-right'>

                <div className='creative-feedback__comment-header'>
                  <div className='creative-feedback__comment-main-info'>
                    <p className='creative-feedback__comment-author-name'>
                      {authorName}
                    </p>

                    <p className='creative-feedback__comment-date'>
                      {moment(createdAt).format(FORMAT_DATE_AT_TIME)}
                    </p>
                  </div>

                  {authorId === currentUserJiraAccountId &&
                    <div
                      className='creative-feedback__delete-icon-container'
                      onClick={() => setIsDeleteCommentModal(id)}
                    >
                      <DeleteIcon className='creative-feedback__delete-icon' />
                    </div>
                  }
                </div>

                <div className='creative-feedback__comment-body'>
                  {content?.map((commentPiece, commentPieceIndex) => renderCommentPiece(commentPiece, commentPieceIndex))}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div className='creative-feedback'>
      <h4 className='creative-section-name'>feedback</h4>

      {!isUserExternal &&
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnMount
          onSubmit={handleSubmitFeedback}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className='creative-feedback__form' onSubmit={handleSubmit}>
              <div className='creative-feedback__form-top'>
                {currentUserAvatarUrl ?
                  <Avatar
                    className='creative-feedback__user-avatar'
                    src={currentUserAvatarUrl}
                    alt='Author'
                  /> :
                  <Avatar className='creative-feedback__user-avatar'>
                    {currentUserName? currentUserName[0] : 'A'}
                  </Avatar>
                }

                <TextField
                  name='feedback'
                  className='creative-feedback__textarea'
                  placeholder='Leave feedback'
                  fullWidth
                  multiline
                  minRows={4}
                  maxRows={4}
                  disabled={isFetchingJiraIssueComments}
                  value={values.feedback}
                  error={!!(touched.feedback && errors.feedback)}
                  helperText={touched.feedback && errors.feedback}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <button
                type='submit'
                className='creative-feedback__button'
                disabled={!isValid || isFetchingJiraIssueComments}
              >
                Post
              </button>
            </form>
          )}
        </Formik>
      }

      <div className='creative-line' />

      <div className='creative-feedback__comments-list'>
        {comments}
      </div>

      <ConfirmRoundModal
        isOpen={!!isDeleteCommentModal}
        title='Delete feedback'
        text='Are you sure want to delete this feedback?'
        loading={isFetchingJiraIssueComments}
        loadingText='Deleting feedback'
        agreeText='delete'
        onAgree={() => handleDeleteJiraIssueComment(isDeleteCommentModal)}
        onClose={() => setIsDeleteCommentModal(null)}
      />
    </div>
  );
};

CreativeFeedback.propTypes = {
  gameName: PropTypes.string,
  isUserExternal: PropTypes.bool,
};

export default CreativeFeedback;
