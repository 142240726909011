import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import {
  setAssetsToDeleteAction,
  deleteAssetsAction,
} from './DeleteAssets.redux/actions';


const DeleteAssets = () => {

  const dispatch = useDispatch();
  const {
    assetsToDeleteIds,
    actionAfterDelete,
    updateElastic,
  } = useSelector((state) => state.assetsToDelete);

  const [isLoading, setIsLoading] = useState(false);

  const handleProceedDeleteAssets = async () => {
    setIsLoading(true);

    await dispatch(deleteAssetsAction(
      {ids: assetsToDeleteIds},
      updateElastic,
      actionAfterDelete
    ));

    setIsLoading(false);
  };

  const handleCancelDelete = () => {
    dispatch(setAssetsToDeleteAction({
      assetsToDeleteIds: [],
      actionAfterDelete: undefined,
      updateElastic: false,
    }));
  };

  return (
    <ConfirmRoundModal
      isOpen={!!assetsToDeleteIds.length}
      title={`Delete asset${assetsToDeleteIds.length > 1 ? 's' : ''}`}
      text={`Are you sure you want to delete ${assetsToDeleteIds.length} selected asset${assetsToDeleteIds.length > 1 ? 's' : ''}?`}
      loading={isLoading}
      loadingText={`Deleting asset${assetsToDeleteIds.length > 1 ? 's' : ''}`}
      onAgree={handleProceedDeleteAssets}
      onClose={handleCancelDelete}
    />
  );
};

export default DeleteAssets;
