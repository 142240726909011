import {
  TOGGLE_GRID,
  TOGGLE_LIMIT,
  TOGGLE_PAGE,
  TOGGLE_LIST,
  TOGGLE_SHOW_MORE,

  UPDATE_PROJECTS_LIST,

  TOGGLE_GAME_FILTER,
  TOGGLE_USERS_FILTER,
  TOGGLE_TOOLS_FILTER,
  TOGGLE_BADGES_FILTER,

  OPEN_MODAL,
  CLOSE_MODAL,

  CREATE_VIDEO_PROJECT,
  CREATE_VIDEO_PROJECT_SUCCESS,
  CREATE_VIDEO_PROJECT_FAIL,

  COPY_VIDEO_PROJECT,
  COPY_VIDEO_PROJECT_SUCCESS,
  COPY_VIDEO_PROJECT_FAIL,

  RENAME_VIDEO_PROJECT,
  RENAME_VIDEO_PROJECT_SUCCESS,
  RENAME_VIDEO_PROJECT_FAIL,

  GET_VIDEO_PROJECT,
  GET_VIDEO_PROJECT_SUCCESS,
  GET_VIDEO_PROJECT_FAIL,

  CLEAR_MAIN_PAGE_SERVER_ERRORS,
  GET_VIDEO_PROJECT_GAMES_SUCCESS,
  GET_VIDEO_PROJECT_FILTERS_SUCCESS,

  CLEAN_UP_MAIN_SCREEN_FILTERS,
} from './actionTypes';


export const initialState = {
  homeModal: {
    type: '',
    project: {},
  },
  projectView: 'grid',
  numbersPerPage: {
    active: 8,
    available: [
      8,
      16,
      24,
    ],
  },
  projectFilters: {
    games: [],
    users: [],
    tools: [],
    tags: {},
  },
  currentPage: 1,
  showMoreMode: false,
  videoProjects: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
  projectsGetting: {
    inProgress: false,
    inProgressShowMoreMode: false,
    errorMessage: '',
  },
  projectCreating: {
    inProgress: false,
    errorMessage: '',
  },
  projectCopy: {
    inProgress: false,
    errorMessage: '',
  },
  projectRename: {
    inProgress: false,
    errorMessage: '',
  },
  availableGames: [],
  videoProjectsFilters: {},
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_GRID:
      return {
        ...state,
        projectView: 'grid',
      };

    case TOGGLE_LIST:
      return {
        ...state,
        projectView: 'list',
      };

    case UPDATE_PROJECTS_LIST:
      return {
        ...state,
        showMoreMode: false,
        projectFilters: {
          ...state.projectFilters,
        },
      };

    case TOGGLE_LIMIT:
      return {
        ...state,
        currentPage: 1,
        showMoreMode: false,
        numbersPerPage: {
          ...state.numbersPerPage,
          active: action.payload,
        },
      };

    case TOGGLE_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        showMoreMode: false,
      };

    case TOGGLE_SHOW_MORE: {
      return {
        ...state,
        currentPage: state.currentPage + 1,
        showMoreMode: true,
      };
    }

    case TOGGLE_GAME_FILTER:
      return {
        ...state,
        currentPage: 1,
        showMoreMode: false,
        projectFilters: {
          ...state.projectFilters,
          games: action.payload,
        },
      };

    case TOGGLE_USERS_FILTER:
      return {
        ...state,
        currentPage: 1,
        showMoreMode: false,
        projectFilters: {
          ...state.projectFilters,
          users: action.payload,
        },
      };

    case TOGGLE_TOOLS_FILTER:
      return {
        ...state,
        currentPage: 1,
        showMoreMode: false,
        projectFilters: {
          ...state.projectFilters,
          tools: action.payload,
        },
      };

    case TOGGLE_BADGES_FILTER:
      return {
        ...state,
        currentPage: 1,
        showMoreMode: false,
        projectFilters: {
          ...state.projectFilters,
          badges: action.payload,
        },
      };

    case OPEN_MODAL:
      return {
        ...state,
        homeModal: {
          type: action.payload.type,
          project: action.payload.project,
        },
      };

    case CLOSE_MODAL:
      return {
        ...state,
        homeModal: {
          type: '',
          project: {},
        },
      };

    case CREATE_VIDEO_PROJECT:
      return {
        ...state,
        projectCreating: {
          ...state.projectCreating,
          inProgress: true,
        },
      };

    case CREATE_VIDEO_PROJECT_SUCCESS:
      return {
        ...state,
        projectCreating: {
          ...state.projectCreating,
          inProgress: false,
        },
      };

    case CREATE_VIDEO_PROJECT_FAIL:
      return {
        ...state,
        projectCreating: {
          ...state.projectCreating,
          inProgress: false,
          errorMessage: action.payload.message,
        },
      };

    case COPY_VIDEO_PROJECT:
      return {
        ...state,
        projectCopy: {
          ...state.projectCopy,
          inProgress: true,
        },
      };

    case COPY_VIDEO_PROJECT_SUCCESS:
      return {
        ...state,
        projectCopy: {
          ...state.projectCopy,
          inProgress: false,
        },
      };

    case COPY_VIDEO_PROJECT_FAIL:
      return {
        ...state,
        projectCopy: {
          ...state.projectCopy,
          inProgress: false,
          errorMessage: action.payload.message,
        },
      };

    case RENAME_VIDEO_PROJECT:
      return {
        ...state,
        projectRename: {
          inProgress: true,
          errorMessage: '',
        },
      };

    case RENAME_VIDEO_PROJECT_SUCCESS:
      return {
        ...state,
        projectRename: {
          inProgress: false,
          errorMessage: '',
        },
      };

    case RENAME_VIDEO_PROJECT_FAIL:
      return {
        ...state,
        projectRename: {
          inProgress: false,
          errorMessage: action.payload.message,
        },
      };

    case CLEAR_MAIN_PAGE_SERVER_ERRORS:
      return {
        ...state,
        projectsGetting: {
          ...state.projectsGetting,
          errorMessage: '',
        },
        projectCreating: {
          ...state.projectCreating,
          errorMessage: '',
        },
        projectCopy: {
          ...state.projectCopy,
          errorMessage: '',
        },
        projectRename: {
          ...state.projectRename,
          errorMessage: '',
        },
      };

    case GET_VIDEO_PROJECT_FILTERS_SUCCESS:
      return {
        ...state,
        videoProjectsFilters: action.payload,
      };

    case GET_VIDEO_PROJECT:
      if (state.showMoreMode) {
        return {
          ...state,
          projectsGetting: {
            inProgress: false,
            inProgressShowMoreMode: true,
          },
        };
      } else {
        return {
          ...state,
          projectsGetting: {
            inProgress: true,
            inProgressShowMoreMode: false,
          },
        };
      }

    case GET_VIDEO_PROJECT_FAIL:
      return {
        ...state,
        projectsGetting: {
          inProgress: false,
          inProgressShowMoreMode: false,
          errorMessage: action.payload.message,
        },
      };

    case GET_VIDEO_PROJECT_SUCCESS: {
      const { data, total, limit, skip } = action.payload;
      const totalPages = Math.ceil(total / limit);

      let projectsList = [];

      if (state.showMoreMode) {
        projectsList = [
          ...state.videoProjects.data,
          ...data,
        ];
      } else {
        projectsList = data;
      }

      return {
        ...state,
        videoProjects: {
          data: projectsList,
          total,
          limit,
          skip,
          totalPages,
        },
        projectsGetting: {
          inProgress: false,
          inProgressShowMoreMode: false,
          errorMessage: '',
        },
      };
    }

    case GET_VIDEO_PROJECT_GAMES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        availableGames: data,
      };
    }

    case CLEAN_UP_MAIN_SCREEN_FILTERS: {
      return {
        ...state,
        homeModal: {
          type: '',
          project: {},
        },
        projectFilters: {
          games: [],
          users: [],
          tools: [],
          tags: {},
        },
        videoProjects: {
          total: 0,
          limit: 0,
          skip: 0,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};

export default homeReducer;
