import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, CircularProgress, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import GameSettingsSidebar from './GameSettingsSidebar';
import GameSettingsDetails from './GameSettingsDetails';
import HeaderWithControls from '../UIComponents/HeaderWithControls';
import {
  getGameSettings,
  updateGameSetting,
  createGameSetting,
} from './GameSettings.redux/actions';

const classes = () => ({
  root: {
    flexGrow: 1,
  },
});

const GameSettings = (props) => {
  const {
    classes,
    gameSettings,
    isFetching,
    error,
    getGameSettings,
    updateGameSetting,
    createGameSetting,
  } = props;

  const [selectedGameId, setSelectedGameId] = useState('');
  const [configKeyObj, setConfigKeyObj] = useState({});
  const [configKeyJson, setConfigKeyJson] = useState('');

  useEffect(() => {
    getGameSettings();
  }, [getGameSettings]);

  const handleGameSelect = (gameId) => {
    setSelectedGameId(gameId);
  };

  const handleCurrentGameEdit = () => {
    const game = gameSettings.find((item) => item.gameId === selectedGameId);
    const configStr = game && game.configKey ? atob(game.configKey) : '';

    let configKeyObj;
    try {
      configKeyObj = JSON.parse(configStr);
    } catch (e) {
      configKeyObj = {};
    }

    setConfigKeyObj(configKeyObj);
    if (configKeyObj) {
      setConfigKeyJson(JSON.stringify(configKeyObj, null, 2));
    }
  };

  const handleCreateGame = (data) => {
    createGameSetting(data);
  };

  const handleUpdateConfig = (configJson) => {
    const game = gameSettings.find((item) => item.gameId === selectedGameId);

    const base64Str = btoa(configJson);
    const data = {
      ...game,
      configKey: base64Str,
    };

    updateGameSetting(game.systemId, data);
  };

  const handleConfigJsonChange = (event) => {
    setConfigKeyJson(event.target.value);
  };

  const handleConfigFieldChange = (keys, value) => {
    const path = keys.split('/');

    let obj = configKeyObj;
    for (let i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
    }

    obj[path[path.length - 1]] = value;
    setConfigKeyObj(configKeyObj);
  };

  const game = gameSettings.find((item) => item.gameId === selectedGameId);

  const fetchingContainer = <CircularProgress />;

  const errorContainer = <div>error</div>;

  const gameSettingsContainer = (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={3}>
        <GameSettingsSidebar
          gameSettings={gameSettings}
          handleGameSelect={handleGameSelect}
          handleCreateGame={handleCreateGame}
        />
      </Grid>
      <Grid item xs={9}>
        <GameSettingsDetails
          game={game}
          configKeyObj={configKeyObj}
          configKeyJson={configKeyJson}
          handleUpdateConfig={handleUpdateConfig}
          handleConfigFieldChange={handleConfigFieldChange}
          handleCurrentGameEdit={handleCurrentGameEdit}
          handleConfigJsonChange={handleConfigJsonChange}
        />
      </Grid>
    </Grid>
  );

  const getContainerContent = () => {
    if (isFetching) {
      return fetchingContainer;
    }

    if (error) {
      return errorContainer;
    }

    return gameSettingsContainer;
  };

  return (
    <Container maxWidth={false}>
      <HeaderWithControls
        titleChildren={<Typography variant='h1'>Game Settings</Typography>}
      />
      {getContainerContent()}
    </Container>
  );
};

const gameSettingsPropType = PropTypes.shape({
  gameId: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  systemId: PropTypes.string.isRequired,
});

GameSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  gameSettings: PropTypes.arrayOf(gameSettingsPropType).isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.string,
  getGameSettings: PropTypes.func.isRequired,
  updateGameSetting: PropTypes.func.isRequired,
  createGameSetting: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    error,
    isFetching,
    gameSettings,
  } = state.gameSettings;

  return {
    error,
    isFetching,
    gameSettings,
  };
};

const mapDispatchToProps = {
  getGameSettings,
  updateGameSetting,
  createGameSetting,
};

export default withStyles(classes)(
  connect(mapStateToProps, mapDispatchToProps)(GameSettings)
);
