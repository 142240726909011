import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import RoundedModalWrapper from '../../UIComponents/RoundedModalWrapper';
import CustomSelect from '../../UIComponents/CustomSelect';
import { downloadAssets } from './downloadAssetsHelpers';
import { setAssetsToDownloadAction } from './DownloadAssets.redux/actions';
import { str } from 'utils/utils';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import './DownloadAssets.scss';

const downloadedZipName = `${moment().format('MM/DD/YYYY')}.zip`;

const DOWNLOAD_FORMATS = {
  ZIP: '.zip',
  FILES: 'separate files',
};

const DownloadAssets = () => {

  const dispatch = useDispatch();
  const {
    assetsToDownload,
    collectionId,
    collectionHash,
  } = useSelector((state) => state.assetsToDownload);

  const [isDownloadFormatModal, setIsDownloadFormatModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState({id: 1, value: DOWNLOAD_FORMATS.ZIP});

  useEffect(() => {
    if (assetsToDownload.length) {
      selectDownloadScenario();
    }
  }, [assetsToDownload.length]);

  const selectDownloadScenario = () => {
    if (assetsToDownload.length === 1 && !assetsToDownload[0]?.isFolder) {
      downloadAsSeparateFile();
    } else if (assetsToDownload.some((asset) => asset.isFolder)) {
      downloadAsZipFile();
    } else {
      setIsDownloadFormatModal(true);
    }
  };

  const downloadAsSeparateFile = async () => {
    await assetsToDownload.forEach((asset) => {
      downloadAssets([collectionId ? asset.entityId : asset.id], str.getItemName(asset), collectionId, collectionHash);
    });

    dispatch(setAssetsToDownloadAction([]));
  };

  const downloadAsZipFile = async () => {
    const assetsIds = assetsToDownload.map((asset) => collectionId ? asset.entityId : asset.id);

    await downloadAssets(assetsIds, downloadedZipName, collectionId, collectionHash);

    dispatch(setAssetsToDownloadAction([]));
  };

  const proceedDownloadInSelectedFormat = () => {
    if (downloadFormat.value === DOWNLOAD_FORMATS.ZIP) {
      downloadAsZipFile();
    } else {
      downloadAsSeparateFile();
    }

    setIsDownloadFormatModal(false);
  };

  return (
    <RoundedModalWrapper
      isOpen={isDownloadFormatModal}
      title='Download files'
      onClose={() => setIsDownloadFormatModal(false)}
    >
      <div className='download-assets'>

        <div className='download-assets__select-holder'>

          <CustomSelect
            label='select format to download files'
            data={[
              {id: 1, value: DOWNLOAD_FORMATS.ZIP},
              {id: 2, value: DOWNLOAD_FORMATS.FILES},
            ]}
            value={downloadFormat}
            handleSelect={(format) => setDownloadFormat(format)}
            pink
          />

        </div>

        <div className='download-assets__buttons-container'>
          <button
            className='download-assets__button'
            onClick={() => setIsDownloadFormatModal(false)}
          >
            <CloseIcon className='icon-inside-button' />
            <span>Cancel</span>
          </button>

          <button
            className='download-assets__button'
            onClick={proceedDownloadInSelectedFormat}
          >
            <GetAppIcon className='icon-inside-button' />
            <span>download</span>
          </button>
        </div>
      </div>
    </RoundedModalWrapper>
  );
};

export default DownloadAssets;
