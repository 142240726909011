import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  card: {
    minWidth: 275,
    margin: 10,
    width: 600,
  },
});

const DeviceInfoBlock = (props) => {
  const {
    classes,
    platform,
    deviceInfo,
    testDevice,
    handleChange,
  } = props;

  const getPrimaryIdLabel = () => {
    switch (platform) {
      case 'iOS':
        return 'IDFV: ';
      case 'Android':
        return 'GAID: ';
      case 'WinRT':
        return 'ASHWID: ';
      case 'Other':
        return 'Primary ID: ';
      default:
        return null;
    }
  };

  const getSecondaryIdLabel = () => {
    switch (platform) {
      case 'iOS':
        return 'IDFA: ';
      case 'Android':
        return 'Android ID: ';
      case 'WinRT':
        return 'Advertising ID: ';
      case 'Other':
        return 'Secodary ID: ';
      default:
        return null;
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant='h5' component='h2'>
          {platform}
        </Typography>
        <Typography component='p'>
          <span>Tpdid: </span>
          <b>{deviceInfo && deviceInfo.tpdid}</b>
        </Typography>
        <Typography component='p'>
          <span>Device Name: </span>
          <b>{deviceInfo && deviceInfo.device_name}</b>
        </Typography>
        <Typography component='p'>
          <span>Device Model: </span>
          <b>{deviceInfo && deviceInfo.device_model}</b>
        </Typography>
        <Typography component='p'>
          <span>
            {getPrimaryIdLabel()}
          </span>
          <b>{deviceInfo && deviceInfo.primary_id}</b>
        </Typography>
        <Typography component='p'>
          <span>
            {getSecondaryIdLabel()}
          </span>
          <b>{deviceInfo && deviceInfo.secondary_id}</b>
        </Typography>
        <Typography component='p'>
          <span>Is Test Device: </span>
          <input
            type='checkbox'
            checked={testDevice}
            disabled={testDevice === null}
            onChange={handleChange}
          />
        </Typography>
      </CardContent>
      <CardActions />
    </Card>
  );
};

DeviceInfoBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  deviceInfo: PropTypes.shape({
    tpdid: PropTypes.string,
    device_name: PropTypes.string,
    device_model: PropTypes.string,
    primary_id: PropTypes.string,
    secondary_id: PropTypes.string,
  }).isRequired,
  testDevice: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeviceInfoBlock);
