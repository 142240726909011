import React from 'react';
import PropTypes from 'prop-types';
import UploaderFolderCard from './UploaderFolderCard';
import {
  assetToUploadErrorsPropTypes,
  folderStructurePropTypes,
} from '../../Types';

import './UploaderAssetsPreview.scss';


const UploaderAssetsPreview = ({
  submitCount,
  errors,
  folderStructure,
  selectedAssetIdsSet,
  setSelectedAssetIdsSet,
  removeAssetFromUploader,
}) => {
  return (
    <div className='uploader-assets-preview'>
      {folderStructure.map(({path, assets}) => {
        return (
          <UploaderFolderCard
            key={path}
            path={path}
            differentPathsCount={folderStructure.length}
            assets={assets}
            submitCount={submitCount}
            errors={errors}
            selectedAssetIdsSet={selectedAssetIdsSet}
            setSelectedAssetIdsSet={setSelectedAssetIdsSet}
            removeAssetFromUploader={removeAssetFromUploader}
          />
        );
      })}
    </div>
  );
};

UploaderAssetsPreview.propTypes = {
  submitCount: PropTypes.number.isRequired,
  errors: assetToUploadErrorsPropTypes,
  folderStructure: PropTypes.arrayOf(folderStructurePropTypes),
  selectedAssetIdsSet: PropTypes.object.isRequired,
  setSelectedAssetIdsSet: PropTypes.func.isRequired,
  removeAssetFromUploader: PropTypes.func.isRequired,
};

export default UploaderAssetsPreview;
