import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Breadcrumbs } from '@material-ui/core';
import CanDropWrapper from 'components/UIComponents/CanDropWrapper';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import './CollectionStorageNavigation.scss';


const CollectionStorageNavigation = ({
  name,
  folderNameFromQuery,
  isLegitFolder,
  navigateToFolder,
  handleMoveAssets,
}) => {
  return (
    <div className='collection-storage-navigation'>
      <Breadcrumbs
        maxItems={3}
        separator={(
          <div className='collection-storage-navigation__separator' />
        )}
      >
        <Link
          to={'/collections'}
          className='collection-storage-navigation__path pink'
        >
          collections
        </Link>

        <CanDropWrapper
          acceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.COLLECTION}
          onDropStorageItem={handleMoveAssets}
        >
          <p
            className={classnames('collection-storage-navigation__path', {
              'pink': isLegitFolder,
            })}
            onClick={isLegitFolder ? () => navigateToFolder() : undefined}
          >
            {name}
          </p>
        </CanDropWrapper>

        {isLegitFolder &&
          <p className='collection-storage-navigation__path'>
            {folderNameFromQuery}
          </p>
        }
      </Breadcrumbs>
    </div>
  );
};

CollectionStorageNavigation.propTypes = {
  name: PropTypes.string.isRequired,
  folderNameFromQuery: PropTypes.string,
  isLegitFolder: PropTypes.bool,
  navigateToFolder: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
};

export default CollectionStorageNavigation;
