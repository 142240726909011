import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Publish as PublishIcon,
  MoreVert as MoreVertIcon,
  GetApp as GetAppIcon,
  Visibility as VisibilityIcon,
  Reply as ReplyIcon,
  Edit as EditIcon,
  Translate as TranslateIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { ReactComponent as ResizeIcon } from 'assets/svg/resize.svg';

import './CustomMenu.scss';


const CustomMenu = ({
  onUpload,
  uploadText,
  onDownload,
  downloadText,
  onSetAsPreview,
  setAsPreviewText,
  onShare,
  shareText,
  onEdit,
  editText,
  onTranslate,
  translateText,
  onResize,
  resizeText,
  onDelete,
  deleteText,
  disabled,
  pink,
}) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (event, actionFunction) => {
    handleClose();
    actionFunction(event);
  };

  return (
    <div
      className={classnames('custom-menu', {
        'pink': pink,
        'disabled': disabled,
      })}
    >
      <div
        className={classnames('custom-menu__button', {
          'active': Boolean(anchorEl),
        })}
        onClick={handleOpen}
      >
        <MoreVertIcon className='custom-menu__icon' />
      </div>

      <Menu
        className={classnames('custom-menu__drop-down', {
          'pink': pink,
        })}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: pink ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onUpload &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onUpload)}
          >
            <PublishIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{uploadText}</p>
          </MenuItem>
        }

        {onDownload &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onDownload)}
          >
            <GetAppIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{downloadText}</p>
          </MenuItem>
        }

        {onSetAsPreview &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onSetAsPreview)}
          >
            <VisibilityIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{setAsPreviewText}</p>
          </MenuItem>
        }

        {onShare &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onShare)}
          >
            <ReplyIcon
              style={{transform: 'scaleX(-1)'}}
              className='custom-menu__drop-down-icon'
            />
            <p className='custom-menu__drop-down-text'>{shareText}</p>
          </MenuItem>
        }

        {onEdit &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onEdit)}
          >
            <EditIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{editText}</p>
          </MenuItem>
        }

        {onTranslate &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onTranslate)}
          >
            <TranslateIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{translateText}</p>
          </MenuItem>
        }

        {onResize &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onResize)}
          >
            <ResizeIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{resizeText}</p>
          </MenuItem>
        }

        {onDelete &&
          <MenuItem
            className='custom-menu__drop-down-item'
            onClick={(event) => handleAction(event, onDelete)}
          >
            <DeleteIcon className='custom-menu__drop-down-icon' />
            <p className='custom-menu__drop-down-text'>{deleteText}</p>
          </MenuItem>
        }
      </Menu>
    </div>
  );
};

CustomMenu.defaultProps = {
  uploadText: 'upload',
  downloadText: 'download',
  setAsPreviewText: 'set as a cover',
  shareText: 'share',
  editText: 'edit',
  translateText: 'translate',
  resizeText: 'resize',
  deleteText: 'delete',
};

CustomMenu.propTypes = {
  onUpload: PropTypes.func,
  uploadText: PropTypes.string,
  onDownload: PropTypes.func,
  downloadText: PropTypes.string,
  onSetAsPreview: PropTypes.func,
  setAsPreviewText: PropTypes.string,
  onShare: PropTypes.func,
  shareText: PropTypes.string,
  onEdit: PropTypes.func,
  editText: PropTypes.string,
  onTranslate: PropTypes.func,
  translateText: PropTypes.string,
  onResize: PropTypes.func,
  resizeText: PropTypes.string,
  onDelete: PropTypes.func,
  deleteText: PropTypes.string,
  disabled: PropTypes.bool,
  pink: PropTypes.bool,
};

export default CustomMenu;