import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class AssetsAttribute {
  constructor() {
    this.attribute = FeathersClient.service(`${API_PREFIX}storage-object-metadata/attribute`);
  }

  putAttributeToAsset(data) {
    return this.attribute.update(null, data);
  }

}

export default new AssetsAttribute();
