
export const initDataLimit = 10;

export const tableHeaderTitles = [
  'pod',
  'project manager',
  'lead 1',
  'lead 2',
  'cl headcount',
  'cl time',
  'artist headcount',
  'artist time',
  'total internal hours',
  'freelancer hours',
  'vendor hours',
  'total internal + outsource',
];

export const podCapacityFieldsNames = {
  ID: 'id',
  TITLE: 'title',
  PROJECT_MANAGER: 'projectManager',
  FIRST_LEAD: 'lead1',
  SECOND_LEAD: 'lead2',
  CL_HEADCOUNT: 'clHeadcount',
  ARTIST_HEADCOUNT: 'artistHeadcount',
  FREELANCER_HOURS: 'freelancerHours',
  VENDOR_HOURS: 'vendorHours',
};

export const idForNewPodCapacity = 'undefined-pod-capacity-id';

export const newPodCapacityStructure = {
  id: idForNewPodCapacity,
  [podCapacityFieldsNames.TITLE]: '',
  [podCapacityFieldsNames.PROJECT_MANAGER]: {id: null, name: ''},
  [podCapacityFieldsNames.FIRST_LEAD]: {id: null, name: ''},
  [podCapacityFieldsNames.SECOND_LEAD]: {id: null, name: ''},
  [podCapacityFieldsNames.CL_HEADCOUNT]: 0,
  [podCapacityFieldsNames.ARTIST_HEADCOUNT]: 0,
  [podCapacityFieldsNames.FREELANCER_HOURS]: 0,
  [podCapacityFieldsNames.VENDOR_HOURS]: 0,
};

export const clCost = 51;
export const artistCost = 103;