import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as DoUploadIcon } from 'assets/svg/doupload.svg';

import './DoUploadButton.scss';


const DoUploadButton = ({ count, onClick, isPink }) => {
  return (
    <div
      className={classnames('doupload-button', {
        'pink': isPink,
      })}
      onClick={onClick}
    >
      <span className='doupload-button__name'>Add more</span>
      <DoUploadIcon className='doupload-button__icon' />
      <span className='doupload-button__counter'>
        {count}
      </span>
    </div>
  );
};

DoUploadButton.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  isPink: PropTypes.bool,
};

export default DoUploadButton;
