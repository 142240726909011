import { Record } from 'immutable';
import {
  // Creative actions types
  GET_CREATIVES_START,
  GET_CREATIVES_FAIL,
  GET_CREATIVES_SUCCESS,

  GET_CREATIVE_BY_ID_START,
  GET_CREATIVE_BY_ID_SUCCESS,
  GET_CREATIVE_BY_ID_FAIL,

  UPDATE_CREATIVE_START,
  UPDATE_CREATIVE_SUCCESS,
  UPDATE_CREATIVE_FAIL,

  SET_MAIN_IMAGE_FROM_DOC_REQUEST,
  SET_MAIN_IMAGE_FROM_DOC_SUCCESS,
  SET_MAIN_IMAGE_FROM_DOC_FAIL,

  DELETE_CREATIVE_START,
  DELETE_CREATIVE_SUCCESS,
  DELETE_CREATIVE_FAIL,

  // Jira issue actions types
  GET_JIRA_ISSUE_METADATA_SUCCESS,

  // Transition actions types
  GET_JIRA_ISSUE_TRANSITION_SUCCESS,

  // Tab actions types
  SET_CURRENT_TAB_CREATIVE,

  CREATE_NEW_TAB_REQUEST,
  CREATE_NEW_TAB_SUCCESS,
  CREATE_NEW_TAB_FAIL,

  RENAME_TAB_REQUEST,
  RENAME_TAB_SUCCESS,
  RENAME_TAB_FAIL,

  DELETE_TAB_REQUEST,
  DELETE_TAB_SUCCESS,
  DELETE_TAB_FAIL,

  // Asset (file, folder) actions types
  CREATE_NEW_FOLDER_CREATIVE_REQUEST,
  CREATE_NEW_FOLDER_CREATIVE_SUCCESS,
  CREATE_NEW_FOLDER_CREATIVE_FAIL,

  UPLOAD_DOC_CREATIVE_REQUEST,
  UPLOAD_DOC_CREATIVE_SUCCESS,
  UPLOAD_DOC_CREATIVE_FAIL,

  // Comment actions types
  GET_JIRA_ISSUE_COMMENT_START,
  GET_JIRA_ISSUE_COMMENT_SUCCESS,
  GET_JIRA_ISSUE_COMMENT_FAIL,

  ADD_JIRA_ISSUE_COMMENT_START,
  ADD_JIRA_ISSUE_COMMENT_SUCCESS,
  ADD_JIRA_ISSUE_COMMENT_FAIL,

  DELETE_JIRA_ISSUE_COMMENT_START,
  DELETE_JIRA_ISSUE_COMMENT_FAIL,
  DELETE_JIRA_ISSUE_COMMENT_SUCCESS,
} from './actionTypes';
import {
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAIL,
} from '../../Assets/DeleteAssets/DeleteAssets.redux/actionsTypes';
import {
  MOVE_ASSET_FAIL,
  MOVE_ASSET_REQUEST,
  MOVE_ASSET_SUCCESS,
} from '../../Assets/Assets.redux/actionTypes';
import {
  GET_STORAGE_DATA_REQUEST,
  GET_STORAGE_DATA_SUCCESS,
  GET_STORAGE_DATA_FAIL,

  RENAME_ASSET_FAIL,
  RENAME_ASSET_REQUEST,
  RENAME_ASSET_SUCCESS,
} from '../../Assets/Assets.redux/actionTypes';

const Creative = Record({
  id: null,
  name: '',
  createdAt: '',
  updatedAt: '',
  jiraIssue: null,
  jiraTicketUrl: '',
  mainImagePath: '',
  mainImageType: null,
  mediumPreviewUrl: '',
  smallPreviewUrl: '',
  storage: null,
  tabs: [],
  game: null,
  user: null,
  googleDriveLink: '',
});

const InitialState = Record({
  creatives: null,
  generalCreativeInfo: new Creative(),
  updateCreativeStart: false,
  updateCreativeSuccess: false,
  updateCreativeError: '',

  isFetching: false,
  isUploading: false,
  error: null,

  jiraIssueMetadata: {},
  issueTransitions: [],

  isFetchingJiraIssueComments: false,
  jiraIssueComments: [],
  fetchingJiraIssueCommentsError: null,

  currentTab: null,
  creativeStorageData: [],
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case GET_CREATIVES_START:
    case GET_CREATIVE_BY_ID_START:
    case GET_STORAGE_DATA_REQUEST:
    case DELETE_CREATIVE_START:
    case CREATE_NEW_TAB_REQUEST:
    case RENAME_TAB_REQUEST:
    case CREATE_NEW_FOLDER_CREATIVE_REQUEST:
    case MOVE_ASSET_REQUEST:
    case RENAME_ASSET_REQUEST:
    case DELETE_ASSETS_REQUEST:
    case DELETE_TAB_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('error', null);
      });

    case GET_CREATIVE_BY_ID_FAIL:
    case GET_STORAGE_DATA_FAIL:
    case DELETE_CREATIVE_FAIL:
    case CREATE_NEW_TAB_FAIL:
    case RENAME_TAB_FAIL:
    case CREATE_NEW_FOLDER_CREATIVE_FAIL:
    case MOVE_ASSET_FAIL:
    case RENAME_ASSET_FAIL:
    case DELETE_ASSETS_FAIL:
    case DELETE_TAB_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', action.error);
      });

    case UPDATE_CREATIVE_START: {
      return state.withMutations((ctx) => {
        ctx
          .set('updateCreativeStart', true)
          .set('updateCreativeSuccess', false)
          .set('updateCreativeError', '');
      });
    }

    case UPDATE_CREATIVE_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('updateCreativeStart', false)
          .set('updateCreativeSuccess', true)
          .set('updateCreativeError', '')
          .set('generalCreativeInfo', new Creative({...action.payload}));
      });
    }

    case UPDATE_CREATIVE_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('updateCreativeStart', false)
          .set('updateCreativeSuccess', false)
          .set('updateCreativeError', action.error);
      });
    }

    case GET_CREATIVES_SUCCESS: {
      return state.withMutations((ctx) => {
        let newCreatives = [...action.creatives];
        if (ctx.creatives) {
          const oldCreatives = [...ctx.creatives];
          newCreatives = newCreatives.filter((newCreative) => {
            let notDuplicated = true;
            oldCreatives.forEach((creative) => {
              if (newCreative.id === creative.id) {
                notDuplicated = false;
              }
            });
            return notDuplicated;
          });
          newCreatives = [...ctx.creatives].concat(newCreatives);
        }

        ctx
          .set('isFetching', false)
          .set('creatives', newCreatives);
      });
    }

    case GET_CREATIVES_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', action.error);
      });
    }

    case GET_CREATIVE_BY_ID_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('generalCreativeInfo', new Creative({...action.payload}));
      });
    }

    case DELETE_CREATIVE_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('generalCreativeInfo', new Creative());
      });
    }

    case GET_STORAGE_DATA_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('creativeStorageData', action.payload.assets);
      });
    }

    case UPLOAD_DOC_CREATIVE_REQUEST: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', true)
          .set('error', null);
      });
    }

    case UPLOAD_DOC_CREATIVE_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', false)
          .set('error', null);
      });
    }

    case UPLOAD_DOC_CREATIVE_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', false)
          .set('error', action.error);
      });
    }

    case DELETE_ASSETS_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', null);
      });
    }

    case MOVE_ASSET_SUCCESS:
    case RENAME_ASSET_SUCCESS:

      return state.withMutations((ctx) => {
        const creativeStorageData = ctx.get('creativeStorageData');

        const updatedData = creativeStorageData.map((item) => item.id === action.id
          ? { ...item, path: action.path, storageId: action.storageId }
          : item
        );

        ctx
          .set('creativeStorageData', updatedData)
          .set('isFetching', false);
      });

    case SET_CURRENT_TAB_CREATIVE: {
      return state.withMutations((ctx) => {
        ctx
          .set('currentTab', action.payload);
      });
    }

    case CREATE_NEW_FOLDER_CREATIVE_SUCCESS:
    case RENAME_TAB_SUCCESS:
    case CREATE_NEW_TAB_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false);
      });
    }

    case DELETE_TAB_SUCCESS: {
      return state.withMutations((ctx) => {
        const creativeTabs = state.getIn(['generalCreativeInfo', 'tabs']);
        const filteredTabs = creativeTabs.filter((tab) => tab.id !== action.payload);

        ctx
          .setIn(['generalCreativeInfo', 'tabs'], filteredTabs)
          .set('isFetching', false);
      });
    }

    case SET_MAIN_IMAGE_FROM_DOC_REQUEST: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', true)
          .set('error', null);
      });
    }

    case SET_MAIN_IMAGE_FROM_DOC_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', false)
          .set('error', null);
      });
    }

    case SET_MAIN_IMAGE_FROM_DOC_FAIL: {
      return state.withMutations((ctx) => {
        ctx
          .set('isUploading', false)
          .set('error', action.error);
      });
    }

    case GET_JIRA_ISSUE_TRANSITION_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('issueTransitions', action.payload);
      });
    }

    case GET_JIRA_ISSUE_METADATA_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx
          .set('jiraIssueMetadata', action.payload);
      });
    }

    case GET_JIRA_ISSUE_COMMENT_START:
    case ADD_JIRA_ISSUE_COMMENT_START:
    case DELETE_JIRA_ISSUE_COMMENT_START:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingJiraIssueComments', true)
          .set('fetchingJiraIssueCommentsError', null);
      });

    case GET_JIRA_ISSUE_COMMENT_FAIL:
    case ADD_JIRA_ISSUE_COMMENT_FAIL:
    case DELETE_JIRA_ISSUE_COMMENT_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingJiraIssueComments', false)
          .set('fetchingJiraIssueCommentsError', action.error);
      });

    case GET_JIRA_ISSUE_COMMENT_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingJiraIssueComments', false)
          .set('jiraIssueComments', action.payload)
          .set('fetchingJiraIssueCommentsError', null);
      });

    case ADD_JIRA_ISSUE_COMMENT_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetchingJiraIssueComments', false);
      });

    case DELETE_JIRA_ISSUE_COMMENT_SUCCESS:
      return state.withMutations((ctx) => {
        const jiraIssueComments = state.get('jiraIssueComments');
        const filteredComments = jiraIssueComments.filter((comment) => comment.id !== action.payload);

        ctx
          .set('isFetchingJiraIssueComments', false)
          .set('jiraIssueComments', filteredComments);
      });

    default: {
      return state;
    }
  }
}
