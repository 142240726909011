import VideoModel from './Models/GameAssets/VideoModel';
import moment from 'moment';
import AudioModel from './Models/GameAssets/AudioModel';
import FrameModel from './Models/GameAssets/FrameModel';
import OverlayModel from './Models/GameAssets/OverlayModel';
import ColorModel from './Models/GameAssets/ColorModel';
import FontModel from './Models/GameAssets/FontModel';
import { generalGameName } from './VideoEditor/VideoEditor.redux/Actions/initActions';
import { VIDEO_EXTENSIONS } from '../../constants/CreativeTool';


export const millisecondsToTimestamp = (milliseconds) => {
  return moment(milliseconds).format('mm:ss:SS');
};

export const timestampToMilliseconds = (timestamp) => {
  const timestampArray = timestamp.split(':');
  return moment(0).set(
    {
      minutes: timestampArray[0],
      seconds: timestampArray[1],
      milliseconds: timestampArray[2] * 10,
    }
  ).valueOf();
};

export const findOutExtensionByWidthAndHeight = (width, height) => {
  const widthInteger = parseInt(width);
  const heightInteger = parseInt(height);
  const ratio = (widthInteger / heightInteger).toFixed(2);
  
  switch (ratio) {
    case (1).toFixed(2):
      return VIDEO_EXTENSIONS.SQUARE;
    case (9 / 16).toFixed(2):
      return VIDEO_EXTENSIONS.PORTRAIT;
    case (4 / 5).toFixed(2):
      return VIDEO_EXTENSIONS.FOUR_ON_FIVE;
    case (16 / 9).toFixed(2):
      return VIDEO_EXTENSIONS.LANDSCAPE;
    default:
      // check if close to landscape
      if (ratio >= 1.5 && ratio <= 2) {
        return VIDEO_EXTENSIONS.LANDSCAPE;
      }

      return VIDEO_EXTENSIONS.OTHER;
  }
};

export const convertStylesToJSON = (styles) => {
  const result = {};
  const attributes = styles.split(';');

  for (let i = 0; i < attributes.length; i++) {
    const entry = attributes[i].split(':');
    result[entry.splice(0, 1)[0]] = entry.join(':');
  }

  delete result[''];

  return result;
};

export const addFontFace = (fontName, fontUrl) => {
  const newFontUrl = fontUrl?.split('').slice(1).join('').replace(/ /g, '%20');
  const fontFace = new FontFace(fontName, `url(/${newFontUrl})`);
  document.fonts.add(fontFace);
  fontFace.load();

  return fontFace.loaded;
};

export const convertRbgToHex = (color) => {
  if (color.includes('rgb')) {
    const isAlpha = color.includes('a');
    const rgbPoints = color.substring(isAlpha ? 5 : 4, color.length - 1).replace(/ /g, '').split(',');

    const hexArray = rgbPoints.map((point) => {
      const hexPoint = Number(point).toString(16);

      if (hexPoint.length === 1) {
        return '0' + hexPoint;
      } else {
        return hexPoint;
      }
    });

    return isAlpha ? `#${hexArray.slice(0, -1).join('')}` : `${hexArray.join('')}`;
  } else {
    return color;
  }
};

export const splitGradientString = (gradientString) => {
  const regex = new RegExp(/,(?![^(]*\))(?![^"']*["'](?:[^"']*["'][^"']*["'])*[^"']*$)/,'gi');
  const gradientType = gradientString.substring(0, gradientString.indexOf('(')).split('-')[0];
  const secondPartOfGradient = gradientString.substring(gradientString.indexOf('(') + 1, gradientString.lastIndexOf(')')).split(regex);
  let gradientAnglePoint = secondPartOfGradient[0];
  const isDefaultAngle = !gradientAnglePoint.includes('rgb');

  if (!isDefaultAngle) {
    gradientAnglePoint = '180deg';
  }

  const gradientPalettes = secondPartOfGradient.slice(Number(!!isDefaultAngle)).map((palette) => {
    const color = palette.substring(0, palette.indexOf(')') + 1).trim();
    const position = palette.substring(palette.indexOf(')') + 1, palette.length - 1).trim();

    return ({
      color,
      position,
    });
  });

  return [gradientType, gradientAnglePoint, gradientPalettes];
};

/**
 * Helper class for game assets transformation
 */
class GameAssetsTransformation  {
  constructor(data, general_storage_uuid, storage_uuid, type) {
    this.data = data;
    this.general_storage_uuid = general_storage_uuid;
    this.storage_uuid = storage_uuid;
    this.type = type;
  }

  commonVideos() {
    return this.data?.filter((dataItem) => dataItem.video_preview_url)
      .map((dataItem) => {
        return new VideoModel(dataItem);
      });
  }

  mainVideosWithoutFolder() {
    return this.data?.filter((dataItem) => dataItem.video_preview_url)
      .filter((dataItem) => dataItem.path.raw === `/${dataItem.asset_name.raw}`)
      .map((dataItem) => {
        return new VideoModel(dataItem);
      });
  }

  mainVideosFolderStructure() {
    const filesWithFolder = this.data?.filter((dataItem) => dataItem.path.raw !== `/${dataItem.asset_name.raw}`);
    const pathsArray = filesWithFolder?.map((video) => video.path.raw.split('/').slice(1, 3));
    const uniquePathsArray = Array.from(new Set(pathsArray.map(JSON.stringify)), JSON.parse);

    const foldersNameArray = [...new Set(uniquePathsArray.map((item) => item[0]))];
    const foldersStructure = foldersNameArray.map((folderItem) => {
      return (
        {
          name: folderItem,
          subFolders: [],
          open: false,
        }
      );
    });

    uniquePathsArray.forEach((item) => {
      const findFolderIndex = foldersStructure.findIndex((folder) => folder.name === item[0]);
      foldersStructure[findFolderIndex].subFolders.push({name: item[1], files: []});
    });

    filesWithFolder
      .filter((video) => video.video_preview_url)
      .forEach((video) => {
        const videoFolder = video.path.raw.split('/').slice(1, 2).join('');
        const videoSubFolder = video.path.raw.split('/').slice(2, 3).join('');
        const neededFolderIndex = foldersStructure.findIndex((folder) => folder.name === videoFolder);
        const neededSubFolderIndex = foldersStructure[neededFolderIndex].subFolders.findIndex((subFolder) => subFolder.name === videoSubFolder);
        foldersStructure[neededFolderIndex].subFolders[neededSubFolderIndex].files.push(new VideoModel(video));
      });

    return foldersStructure;
  }

  audios() {
    return this.data?.map((dataItem) => {
      return new AudioModel(
        dataItem,
        dataItem.asset_game_name.raw === generalGameName ?
          this.general_storage_uuid :
          this.storage_uuid
      );
    });
  }

  frames() {
    return this.data?.map((dataItem) => {
      return new FrameModel(dataItem, this.type);
    });
  }

  overlays() {
    return this.data?.map((dataItem) => {
      let url = dataItem.medium_preview_url.raw;

      if ('video_preview_url' in dataItem) {
        url = dataItem.video_preview_url.raw;
      }

      return new OverlayModel(dataItem, url);
    });
  }

  colors() {
    return this.data?.map((dataItem) => {
      return new ColorModel(dataItem);
    });
  }

  fonts() {
    return this.data?.map((dataItem) => {
      return new FontModel(dataItem);
    });
  }
}

export default GameAssetsTransformation;
