import React from 'react';
import PropTypes from 'prop-types';
import AssetInfoEmptyState from 'components/UIComponents/AssetInfoEmptyState';
import UploaderConfigState from './UploaderConfigState';
import {
  assetToUploadErrorsPropTypes,
  assetToUploadPropTypes,
} from '../../Types';

import './UploaderAssetInfo.scss';


const UploaderAssetInfo = ({ errors, selectedAsset, setSelectedAsset }) => {
  return (
    <div className='uploader-asset-info'>
      <div className='uploader-asset-info__header'>
        <p className='uploader-asset-info__title'>
          asset Info
        </p>
        <p className='uploader-asset-info__subtitle'>
          Data is applied for selected assets
        </p>
      </div>
      <div className='uploader-asset-info__body'>
        {selectedAsset ?
          <UploaderConfigState
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            errors={errors}
          /> :
          <AssetInfoEmptyState />
        }
      </div>
    </div>
  );
};

UploaderAssetInfo.propTypes = {
  errors: assetToUploadErrorsPropTypes,
  selectedAsset: assetToUploadPropTypes,
  setSelectedAsset: PropTypes.func.isRequired,
};

export default UploaderAssetInfo;
