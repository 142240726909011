import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import { childrenPropType } from '../../../types/general';
import { toggleMainSearchResultsVeilAction } from '../../MainSearch/MainSearch.redux/actions';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './ButtonWithDropDown.scss';


const ButtonWithDropDown = ({
  name,
  dropDownWidth,
  maxDropDownHeight,
  minDropDownHeight,
  dropDownPosition,
  closeWhenClickOnDropDown,
  children,
}) => {

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(toggleMainSearchResultsVeilAction(name, isOpen));
  }, [isOpen]);

  const setDropDownPosition = useCallback(() => {
    switch (dropDownPosition) {
      case 'left':
        return {
          left: 0,
        };
      case 'right':
        return {
          right: 0,
        };
      default:
        return {
          left: `-${dropDownPosition}px`,
        };
    }
  }, [dropDownPosition]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div
        className={classnames('button-with-dropDown', {
          'active': isOpen,
        })}
      >
        <div
          className='button-with-dropDown__trigger-container'
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <div className='button-with-dropDown__trigger'>
            <p className='button-with-dropDown__name'>{name}</p>
            {isOpen ?
              <ExpandLessIcon className='button-with-dropDown__icon' /> :
              <ExpandMoreIcon className='button-with-dropDown__icon' />
            }
          </div>
        </div>

        <div
          className='button-with-dropDown__content'
          style={{
            width: dropDownWidth ? `${dropDownWidth}px` : 'max-content',
            minHeight: `${minDropDownHeight}px`,
            maxHeight: `${maxDropDownHeight}px`,
            ...setDropDownPosition(),
          }}
          onClick={closeWhenClickOnDropDown ? () => setIsOpen(false) : () => {}}
        >
          {children}
        </div>
      </div>
    </ClickAwayListener>
  );
};

ButtonWithDropDown.defaultProps = {
  maxDropDownHeight: 370,
  minDropDownHeight: 0,
  dropDownPosition: 'left',
};

ButtonWithDropDown.propTypes = {
  name: PropTypes.string.isRequired,
  dropDownWidth: PropTypes.number,
  maxDropDownHeight: PropTypes.number,
  minDropDownHeight: PropTypes.number,
  dropDownPosition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  closeWhenClickOnDropDown: PropTypes.bool,
  children: childrenPropType,
};

export default ButtonWithDropDown;
