import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Avatar } from '@material-ui/core';

import './CreativeGeneralInfoStatic.scss';


const CreativeGeneralInfoStatic = ({
  jiraIssueTitle,
  jiraIssueUrl,
  authorAvatar,
  authorName,
  format,
  sizes,
  department,
  quantity,
  status,
  platform,
  language,
}) => {
  const authorDisplayName = useMemo(() => authorName || 'Anonymous Author', [authorName]);

  const creativeFormFields = [
    {name: 'author', value: authorDisplayName},
    {name: 'jira ticket', value: jiraIssueTitle},
    {name: 'format', value: format},
    {name: 'sizes', value: sizes?.join(', ')},
    {name: 'department', value: department},
    {name: 'quantity', value: quantity},
    {name: 'language', value: language?.join(', ')},
    {name: 'status', value: status},
    {name: 'platform', value: platform?.join(', ')},
  ];

  const displayAuthorAvatar = () => {
    return authorAvatar ?
      <Avatar
        className='creative-general-info__author-avatar'
        src={authorAvatar}
        alt='Author'
      /> :
      <Avatar className='creative-general-info__author-avatar'>
        {authorDisplayName[0]}
      </Avatar>;
  };

  return (
    <div className='creative-general-info-static'>
      {creativeFormFields.map((field, index) => (
        <div
          key={field.name}
          className={classnames('creative-general-info__field', {
            'link': index === 1,
          })}
          onClick={index === 1 ? () => window.open(jiraIssueUrl) : undefined}
        >
          <div className='creative-general-info__field-label'>{field.name}</div>
          {index === 0 ?
            <div className='creative-general-info__author'>
              <div>{displayAuthorAvatar()}</div>
              <div className='creative-general-info__field-value'>{field.value}</div>
            </div> :
            <div className='creative-general-info__field-value'>{field.value}</div>
          }
        </div>
      ))}
    </div>
  );
};

CreativeGeneralInfoStatic.propTypes = {
  jiraIssueTitle: PropTypes.string,
  jiraIssueUrl: PropTypes.string,
  authorAvatar: PropTypes.string,
  authorName: PropTypes.string,
  format: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.string),
  department: PropTypes.string,
  quantity: PropTypes.number,
  status: PropTypes.string,
  platform: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.arrayOf(PropTypes.string),
};

export default CreativeGeneralInfoStatic;
