import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import styles from './styles.module.scss';

const VideoPlayOverlay = ({ onClick, className }) => (
  <div className={classNames([styles.videoPlayOverlay, className])}>
    <IconButton onClick={onClick}>
      <PlayArrowIcon />
    </IconButton>
  </div>
);

VideoPlayOverlay.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default VideoPlayOverlay;
