import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import classNames from 'classnames';
import {
  Button,
  CircularProgress,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropZone from '../../General/DropZoneContainer';
import TagEditor from '../../UIComponents/TagEditor';
import GameCheckboxItem from '../../UIComponents/GameCheckboxItem';
import OwnerNameLabel from '../../UIComponents/OwnerNameLabel';
import FieldValue from '../../UIComponents/FieldValue';
import ApprovePopover from '../../UIComponents/ApprovePopover';
import {
  getSelectedGame,
  updateGame,
  createNewGame,
  removeSelectedGame,
  clearingGamePreview,
  findUnassociatedJiraProjects,
  getUnassociatedJiraProjects,
  getAllGenres,
  getAllStudios,
  getAllLevels,
  getGamesByGenres,

  addNewStudio,
  updateStudio,
  removeStudio,
  addNewLevel,
  updateLevel,
  removeLevel,
} from '../Game.redux/actions';
import { getPodCapacityAction } from '../../CreativeDashboard/PodCapacity/PodCapacity.redux/Actions/getPodCapacityActions';
import {
  UPLOAD_TYPES,
  FORMAT_DATE_AT_TIME,
} from 'constants/General';
import {
  requiredError,
  VALIDATION,
} from 'constants/Validation';
import { makeFieldValidation } from 'utils/utils';

import { ReactComponent as CalendarIcon } from 'assets/svg/calendar.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/svg/chevron-down.svg';
import { ReactComponent as EditIcon } from 'assets/svg/ic-edit-dark.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/ic-close-modal.svg';
import { ReactComponent as SaveIcon } from 'assets/svg/ic-save.svg';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from './styles.module.scss';

const validationSchema = yup.object({
  name: yup.string().required(requiredError),
  description: yup.string().required(requiredError),
  jiraProject: yup.object({
    id: yup.string().required(requiredError),
  }),
  studio: yup.object({
    id: yup.number(),
    title: yup.string().trim().matches(VALIDATION.GAME_STUDIO_LEVEL, 'Is not in correct format'),
  }).nullable(),
  level: yup.object({
    id: yup.number(),
    title: yup.string().trim().matches(VALIDATION.GAME_STUDIO_LEVEL, 'Is not in correct format'),
  }).nullable(),
  genres: yup.array().min(1, 'Please, add at least one category'),
});

const GameEditForm = ({
  selectedGameId,
  onClose,
}) => {

  const dispatch = useDispatch();
  const formikRef = useRef();

  const {
    isFetching: isFetchingGame,
    isFetchingJiraProjects,
    genres,
    studios,
    levels,
    selectedGameData,
    unassociatedJiraProjects: jiraProjects,
    allGamesForComparing,
    optionUpdate,
  } = useSelector((state) => state.game);

  const podCapacityList = useSelector((state) => state.podCapacity.podCapacity.success.data);

  const isFetching = isFetchingGame || isFetchingJiraProjects;

  const {
    id,
    name,
    imageUrl,
    ownerName,
    description,
    publishDate,
    similarGames,
    genres: selectedGameGenres,
    jiraProjectId,
    studio,
    level,
    podCapacity,
  } = selectedGameData;

  const formattedDate = moment(publishDate).format(FORMAT_DATE_AT_TIME);
  const genreNames = genres.map(({ genre }) => genre);

  const [confirmCreation, setConfirmCreation] = useState({
    open: false,
    type: null,
    value: null,
    action: null,
    anchor: null,
  });

  const [confirmDeletion, setConfirmDeletion] = useState({
    open: false,
    type: null,
    value: null,
    action: null,
    anchor: null,
  });

  const [editFieldOption, setEditFieldOption] = useState({
    open: false,
    id: null,
    type: null,
    value: null,
    validation: null,
    action: null,
  });

  useEffect(() => {
    dispatch(getAllGenres());
    dispatch(getAllStudios());
    dispatch(getAllLevels());
    dispatch(getPodCapacityAction({
      limit: 999,
    }));

    return () => {
      onClose();
    };
  }, []);

  useEffect(() => {
    if (selectedGameId) {
      dispatch(getUnassociatedJiraProjects(selectedGameId));
    } else {
      dispatch(findUnassociatedJiraProjects());
    }
  }, [selectedGameId]);

  useEffect(() => {
    if (selectedGameId !== undefined && selectedGameId !== null) {
      dispatch(getSelectedGame(selectedGameId));
    } else {
      dispatch(clearingGamePreview());
    }
  }, [selectedGameId]);

  useEffect(() => {
    if (selectedGameId && selectedGameGenres) {
      const genreNames = selectedGameGenres.map(({ genre }) => genre);
      dispatch(getGamesByGenres({ genres: genreNames }));
    }
  }, [selectedGameId, selectedGameGenres]);

  useEffect(() => {
    if (optionUpdate.success) {
      cancelEditFieldOption();

      if (formikRef.current) {
        formikRef.current.resetForm();
      }
    }
  }, [optionUpdate.success, level, studio]);

  const handleSubmit = async (data) => {
    const {
      jiraProject: { id: jiraProjectId },
      publishDate,
      similarGameIds,
      studio,
      level,
      podCapacity,
      ...values
    } = data;

    const requestData = {
      jiraProjectId,
      similarGames: similarGameIds,
      publishDate: publishDate && publishDate.valueOf(),
      gameLevelId: level ? level.id : null,
      gameStudioId: studio ? studio.id : null,
      podCapacityId: podCapacity ? podCapacity.id : null,
      ...values,
    };

    if (selectedGameId) {
      await dispatch(updateGame({ ...requestData, selectedGameId }));
    } else {
      await dispatch(createNewGame(requestData));
    }

    onClose();
  };

  const handleRemoveGame = async () => {
    await dispatch(removeSelectedGame(id));

    onClose();
  };

  const handleChangeGenres = (setFieldValue, genres, similarGameIds) => {
    setFieldValue('genres', genres);

    const updatedSimilarGameIds = similarGameIds.filter((id) => {
      const similarGame = similarGames.find((game) => game.id === id);

      return similarGame && genres.includes(similarGame.genre);
    });

    setFieldValue('similarGameIds', updatedSimilarGameIds);

    dispatch(getGamesByGenres({ genres }));
  };

  const getJiraProjectOptionLabel = ({ id }) => {
    const jiraProject = jiraProjects.find((project) => project.id === id);

    return jiraProject ? jiraProject.name : '';
  };

  const handleInputKeyDown = (options, currentValue, action, type) => (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const optionValues = options?.map((option) => option.title.trim().toLowerCase());
      const trimmedNewValue = currentValue.trim();
      const validation = validateFieldOption(currentValue, false);

      if (!validation && !optionValues.includes(trimmedNewValue)) {
        setConfirmCreation({
          open: true,
          type,
          value: trimmedNewValue,
          action,
          anchor: event.target,
        });
      }
    }
  };

  const closeConfirmCreation = () => {
    setConfirmCreation({
      ...confirmCreation,
      open: false,
    });
  };

  const handleRemoveFieldOption = (fieldOptionId, type, action) => (event) => {
    setConfirmDeletion({
      open: true,
      type,
      value: fieldOptionId,
      action,
      anchor: event.target,
    });
  };

  const closeConfirmDeletion = () => {
    setConfirmDeletion({
      ...confirmDeletion,
      open: false,
    });
  };

  const startEditFieldOption = (type, id, value, action) => {
    setEditFieldOption({
      ...editFieldOption,
      open: true,
      id,
      type,
      value,
      action,
    });
  };

  const cancelEditFieldOption = () => {
    setEditFieldOption({
      open: false,
      id: null,
      type: null,
      value: null,
      validation: null,
      action: null,
    });
  };

  const handleChangeFieldOption = (event) => {
    const value = event.target.value;

    setEditFieldOption({
      ...editFieldOption,
      value,
      validation: validateFieldOption(value, false),
    });
  };

  const handleRenameFieldOption = () => {
    dispatch(editFieldOption.action(editFieldOption.id, editFieldOption.value, id));
  };

  const validateFieldOption = (value, canBeEmpty) => {
    let valueValidation = makeFieldValidation(
      value,
      VALIDATION.GAME_STUDIO_LEVEL,
      'Must be from 1 to 30 symbols and contain only letters from latin alphabet, numbers and common symbols'
    );

    if (!value.trim().length && !canBeEmpty) {
      valueValidation = 'Can\'t contain only spaces.';
    }

    return valueValidation;
  };

  return (
    <div className={styles.modalContainer}>
      {isFetching
        ? <div className={styles.spinner}><CircularProgress /></div>
        : (
          <>
            {!!(name && publishDate) && (
              <div className={styles.header}>
                <Typography variant='h1'>{name}</Typography>
                <p className={styles.publishDate}>
                  <span className={styles.dateLabel}>Date:</span> {formattedDate}
                </p>
              </div>
            )}

            <Formik
              innerRef={formikRef}
              initialValues={{
                name,
                description,
                jiraProject: { id: jiraProjectId },
                studio,
                level,
                podCapacity,
                publishDate: publishDate && moment(publishDate),
                similarGameIds: similarGames.map(({ id }) => id),
                file: null,
                genres: selectedGameGenres.map(({ genre }) => genre),
              }}
              initialTouched={{
                name: false,
                description: false,
                jiraProject: false,
                studio: false,
                level: false,
                genres: false,
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => {
                const genresForAutoComplete = [...new Set([...genreNames, ...values.genres])];

                return (
                  <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formContainer}>
                      <DropZone
                        uploadType={UPLOAD_TYPES.IMAGE}
                        filePreview={imageUrl}
                        onChange={(file) => setFieldValue('file', file)}
                      />
                      <div className={styles.formFieldsContainer}>

                        <div className={styles.title}>
                          <FormLabel>Game title</FormLabel>
                          <TextField
                            name='name'
                            placeholder='Game title'
                            value={values.name}
                            error={!!(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className={styles.description}>
                          <FormLabel>Description</FormLabel>
                          <TextField
                            name='description'
                            placeholder='Description'
                            value={values.description}
                            error={!!(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>

                        <div className={styles.jiraProject}>
                          <FormLabel>Jira project</FormLabel>
                          <Autocomplete
                            value={values.jiraProject}
                            options={[...jiraProjects]}
                            onChange={(event, value) => setFieldValue('jiraProject', value)}
                            getOptionLabel={getJiraProjectOptionLabel}
                            getOptionSelected={(option, value) => option.id === value.id}
                            disableClearable
                            popupIcon={<ChevronDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Project name'
                                error={!!(touched.jiraProject && errors.jiraProject)}
                                helperText={touched.jiraProject && errors.jiraProject && errors.jiraProject.id}
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </div>

                        <div className={classNames([styles.publishDate, !ownerName && styles.spanned])}>
                          <FormLabel>Publication date</FormLabel>
                          <KeyboardDatePicker
                            value={values.publishDate}
                            placeholder='Publication date'
                            onChange={(date) => setFieldValue('publishDate', date)}
                            keyboardIcon={<CalendarIcon className={styles.calendarIcon} />}
                            format='MM/DD/YYYY'
                          />
                        </div>

                        {ownerName && (
                          <div className={styles.ownerName}>
                            <FormLabel>Added by</FormLabel>
                            <FieldValue>
                              <OwnerNameLabel ownerName={ownerName} />
                            </FieldValue>
                          </div>
                        )}

                        <div className={styles.studio}>
                          <FormLabel>Studio</FormLabel>
                          {(editFieldOption.open && editFieldOption.type === 'studio') ?
                            <>
                              <TextField
                                className={styles.editOption}
                                value={editFieldOption.value}
                                onChange={handleChangeFieldOption}
                                placeholder='Enter new studio name'
                                error={editFieldOption.validation}
                                helperText={editFieldOption.validation}
                              />
                              <div className={styles.fieldActions}>
                                <CloseIcon className={optionUpdate.loading ? styles.disabled : undefined} onClick={cancelEditFieldOption} />
                                <SaveIcon className={optionUpdate.loading ? styles.disabled : undefined} onClick={handleRenameFieldOption} />
                              </div>
                            </> :
                            <Autocomplete
                              value={values.studio}
                              options={[...studios]}
                              onChange={(event, value) => setFieldValue('studio', value)}
                              getOptionLabel={(option) => option.title ?? ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              noOptionsText={'Press Enter to add'}
                              popupIcon={<ChevronDownIcon />}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    name='studio'
                                    placeholder='Studio name'
                                    error={touched.studio && validateFieldOption(params.inputProps.value, true)}
                                    helperText={touched.studio && validateFieldOption(params.inputProps.value, true)}
                                    onKeyDown={handleInputKeyDown([...studios], params.inputProps.value, addNewStudio, 'studio')}
                                    onBlur={handleBlur}
                                  />
                                  {values.studio &&
                                    <div className={classNames([styles.fieldActions, styles.rightIndent])}>
                                      <EditIcon onClick={() => startEditFieldOption('studio', values.studio.id, values.studio.title, updateStudio)} />
                                      <DeleteIcon onClick={handleRemoveFieldOption(values.studio.id, `'${values.studio.title}' studio`, removeStudio)} />
                                    </div>
                                  }
                                </>
                              )}
                            />
                          }
                        </div>

                        <div className={styles.level}>
                          <FormLabel>Game category</FormLabel>
                          {(editFieldOption.open && editFieldOption.type === 'level') ?
                            <>
                              <TextField
                                className={styles.editOption}
                                value={editFieldOption.value}
                                onChange={handleChangeFieldOption}
                                placeholder='Enter new Game category name'
                                error={editFieldOption.validation}
                                helperText={editFieldOption.validation}
                              />
                              <div className={styles.fieldActions}>
                                <CloseIcon className={optionUpdate.loading ? styles.disabled : undefined} onClick={cancelEditFieldOption} />
                                <SaveIcon className={optionUpdate.loading ? styles.disabled: undefined} onClick={handleRenameFieldOption} />
                              </div>
                            </> :
                            <Autocomplete
                              value={values.level}
                              options={[...levels]}
                              onChange={(event, value) => setFieldValue('level', value)}
                              getOptionLabel={(option) => option.title ?? ''}
                              getOptionSelected={(option, value) => option.id === value.id}
                              noOptionsText={'Press Enter to add'}
                              popupIcon={<ChevronDownIcon />}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    name='level'
                                    placeholder='Game category name'
                                    error={touched.studio && validateFieldOption(params.inputProps.value, true)}
                                    helperText={touched.studio && validateFieldOption(params.inputProps.value, true)}
                                    onKeyDown={handleInputKeyDown([...levels], params.inputProps.value, addNewLevel, 'Game category')}
                                    onBlur={handleBlur}
                                  />
                                  {values.level &&
                                    <div className={classNames([styles.fieldActions, styles.rightIndent])}>
                                      <EditIcon onClick={() => startEditFieldOption('level', values.level.id, values.level.title, updateLevel)} />
                                      <DeleteIcon onClick={handleRemoveFieldOption(values.level.id, `'${values.level.title}' game category`, removeLevel)} />
                                    </div>
                                  }
                                </>
                              )}
                            />
                          }
                        </div>

                        <div className={styles.pod}>
                          <FormLabel>POD</FormLabel>
                          <Autocomplete
                            value={values.podCapacity}
                            options={[...podCapacityList]}
                            onChange={(event, value) => setFieldValue('podCapacity', value)}
                            getOptionLabel={(option) => option.title ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            popupIcon={<ChevronDownIcon />}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='POD name'
                              />
                            )}
                          />
                        </div>

                        <div className={styles.categories}>
                          <FormLabel>Categories</FormLabel>
                          <TagEditor
                            selectedTags={values.genres}
                            tagsForAutoComplete={genresForAutoComplete}
                            onAddCustomTag={(genre) =>
                              handleChangeGenres(
                                setFieldValue,
                                [...values.genres, genre],
                                values.similarGameIds
                              )
                            }
                            onSetTags={(genres) =>
                              handleChangeGenres(
                                setFieldValue,
                                genres,
                                values.similarGameIds
                              )
                            }
                          />
                          {!!(touched.genres && errors.genres) && (
                            <FormHelperText error>
                              {errors.genres}
                            </FormHelperText>
                          )}
                        </div>

                        {!!allGamesForComparing.length && (
                          <div className={styles.similarGames}>
                            <FormLabel>Similar games</FormLabel>
                            <div className={styles.similarGamesList}>
                              {allGamesForComparing.map((game) => (
                                <GameCheckboxItem
                                  key={game.id}
                                  checked={values.similarGameIds.includes(game.id)}
                                  imageUrl={game.imageUrl}
                                  onChange={(event, checked) => {
                                    const { similarGameIds } = values;
                                    const updatedIds = checked
                                      ? [...similarGameIds, game.id]
                                      : similarGameIds.filter((gameId) => gameId !== game.id);

                                    setFieldValue('similarGameIds', updatedIds);
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.footer}>
                      {selectedGameId && (
                        <Button
                          variant='outlined'
                          onClick={handleRemoveGame}
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        type='submit'
                      >
                        Pin it
                      </Button>
                    </div>
                  </form>
                );
              }}
            </Formik>

            <ApprovePopover
              open={confirmCreation.open}
              anchorEl={confirmCreation.anchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              text={`Create new '${confirmCreation.value}' ${confirmCreation.type}?`}
              onApprove={() => dispatch(confirmCreation.action(confirmCreation.value))}
              onClose={() => closeConfirmCreation()}
            />

            <ApprovePopover
              open={confirmDeletion.open}
              anchorEl={confirmDeletion.anchor}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              text={`Remove ${confirmDeletion.type}?`}
              onApprove={() => dispatch(confirmDeletion.action(confirmDeletion.value))}
              onClose={() => closeConfirmDeletion()}
            />
          </>
        )}
    </div>
  );
};

GameEditForm.propTypes = {
  selectedGameId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

export default GameEditForm;
