import cloneDeep from 'lodash/cloneDeep';
import {
  RESET_AD_CREATE_PROJECT_EDITOR,

  GET_GAME_VIDEOS_AD_CREATE_START,
  GET_GAME_VIDEOS_AD_CREATE_SUCCESS,
  GET_GAME_VIDEOS_AD_CREATE_SUCCESS_SCROLL,
  GET_GAME_VIDEOS_AD_CREATE_FAIL,

  GET_IMAGE_OVERLAYS_AD_CREATE_START,
  GET_IMAGE_OVERLAYS_AD_CREATE_SUCCESS,
  GET_IMAGE_OVERLAYS_AD_CREATE_FAIL,

  GET_GAME_LOGOS_AD_CREATE_START,
  GET_GAME_LOGOS_AD_CREATE_SUCCESS,
  GET_GAME_LOGOS_AD_CREATE_FAIL,

  GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_START,
  GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_SUCCESS,
  GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_FAIL,

  CREATE_AD_PROJECT_VIA_EDITOR_START,
  CREATE_AD_PROJECT_VIA_EDITOR_SUCCESS,
  CREATE_AD_PROJECT_VIA_EDITOR_RESET,
  CREATE_AD_PROJECT_VIA_EDITOR_FAIL,

  CREATE_AD_PROJECT_VIA_RANDOMIZER_START,
  CREATE_AD_PROJECT_VIA_RANDOMIZER_SUCCESS,
  CREATE_AD_PROJECT_VIA_RANDOMIZER_RESET,
  CREATE_AD_PROJECT_VIA_RANDOMIZER_FAIL,

  ADD_SIZE_TO_AD_CREATE_PROJECT,
  REMOVE_SIZE_FROM_AD_CREATE_PROJECT,
  SET_ACTIVE_SIZE_OF_AD_CREATE_PROJECT,
  UPDATE_FIELD_OF_AD_CREATE_PROJECT_SIZE,
  UPDATE_FIELD_OF_AD_CREATE_PROJECT_ACTIVE_SIZE,
  UPDATE_BANNER_ITEMS_OF_ALL_PROJECT_SIZES,

  SET_VIDEO_FRAME_TIME_AD_CREATE_PROJECT,

  SET_CROP_AREA_DIMENSION_AND_POSITION_AD_CREATE_PROJECT,

  TOGGLE_IMAGE_FORMAT_AD_CREATE_PROJECT,

  SAVE_AD_CREATE_PROJECT_START,
  SAVE_AD_CREATE_PROJECT_SUCCESS,
  SAVE_AD_CREATE_PROJECT_FAIL,
  SAVE_AD_CREATE_PROJECT_RESET,

  RENDER_AD_CREATE_PROJECT_START,
  RENDER_AD_CREATE_PROJECT_SUCCESS,
  RENDER_AD_CREATE_PROJECT_FAIL,
  RENDER_AD_CREATE_PROJECT_RESET,

  ADD_OVERLAY_TO_AD_CREATE_PROJECT_SIZE,
} from './actionTypes';
import ImageOverlayModel from 'components/Laboratory/Models/AdCreate/ImageOverlayModel';
import SizeOfSocialNetworkModel from 'components/Laboratory/Models/AdCreate/SizeOfSocialNetworkModel';
import GameVideoAdCreateModel from '../../Models/AdCreate/GameVideoAdCreateModel';
import AdCreateProjectClassHelper from '../adCreateHelpers';
import { transformProjectSizesOnEnter } from '../adCreateProjectSizesTransformation';
import { AD_CREATE_IMAGE_OVERLAY_LABELS } from '../adCreateConstants';


const emptyState = {
  createProjectStatus: {
    loading: false,
    success: false,
    error: '',
  },
  projectName: '',
  gameId: null,
  gameName: '',
  socialNetworkId: null,
  socialNetworkName: '',
  selectedVideo: null,
  frameCutTime: 0,
  cropAreaDimensionAndPosition: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  imageFormats: [],
  gameVideos: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  imageOverlays: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  gameLogos: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  sizesOfSocialNetwork: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  randomizerProject: {
    loading: false,
    success: false,
    error: '',
  },
  randomizerProjectId: '',
  randomizerRenderedFilesIds: [],
  sizesOfAdCreateProject: [],
  activeSizeOfAdCreateProject: null,
  saveProjectStatus: {
    loading: false,
    success: false,
    error: '',
  },
  renderProjectStatus: {
    loading: false,
    success: false,
    error: '',
  },
  renderedFilesIds: [],
};

const initState = {
  ...emptyState,
};

const adCreateReducer = (state = initState, action) => {
  const { type, payload } = action;
  let cloneOfNeededState;
  let neededItem;

  switch (type) {

    case GET_GAME_VIDEOS_AD_CREATE_START:
      return {
        ...state,
        gameVideos: {
          ...state.gameVideos,
          loading: true,
          error: '',
        },
      };

    case GET_GAME_VIDEOS_AD_CREATE_SUCCESS:
      return {
        ...state,
        gameVideos: {
          ...state.gameVideos,
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((video) => new GameVideoAdCreateModel(video)),
          },
          error: '',
        },
      };

    case GET_GAME_VIDEOS_AD_CREATE_SUCCESS_SCROLL:
      return {
        ...state,
        gameVideos: {
          ...state.gameVideos,
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.gameVideos.success.data,
              ...payload.data.map((video) => new GameVideoAdCreateModel(video)),
            ],
          },
          error: '',
        },
      };

    case GET_GAME_VIDEOS_AD_CREATE_FAIL:
      return {
        ...state,
        gameVideos: {
          ...state.gameVideos,
          loading: false,
          error: payload,
        },
      };

    case GET_IMAGE_OVERLAYS_AD_CREATE_START:
      return {
        ...state,
        imageOverlays: {
          ...state.imageOverlays,
          loading: true,
          error: '',
        },
      };

    case GET_IMAGE_OVERLAYS_AD_CREATE_SUCCESS:
      return {
        ...state,
        imageOverlays: {
          ...state.imageOverlays,
          loading: false,
          success: {
            data: payload.results.map((imageOverlay) => new ImageOverlayModel(imageOverlay, AD_CREATE_IMAGE_OVERLAY_LABELS.GENERAL)),
          },
          error: '',
        },
      };

    case GET_IMAGE_OVERLAYS_AD_CREATE_FAIL:
      return {
        ...state,
        imageOverlays: {
          ...state.imageOverlays,
          loading: false,
          error: payload,
        },
      };

    case GET_GAME_LOGOS_AD_CREATE_START:
      return {
        ...state,
        gameLogos: {
          ...state.gameLogos,
          loading: true,
          error: '',
        },
      };

    case GET_GAME_LOGOS_AD_CREATE_SUCCESS:
      return {
        ...state,
        gameLogos: {
          ...state.gameLogos,
          loading: false,
          success: {
            data: payload.results.map((gameLogo) => new ImageOverlayModel(gameLogo, AD_CREATE_IMAGE_OVERLAY_LABELS.LOGO)),
          },
          error: '',
        },
      };

    case GET_GAME_LOGOS_AD_CREATE_FAIL:
      return {
        ...state,
        gameLogos: {
          ...state.gameLogos,
          loading: false,
          error: payload,
        },
      };

    case GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_START:
      return {
        ...state,
        sizesOfSocialNetwork: {
          ...state.sizesOfSocialNetwork,
          loading: true,
          error: '',
        },
      };

    case GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_SUCCESS:
      return {
        ...state,
        sizesOfSocialNetwork: {
          ...state.sizesOfSocialNetwork,
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((size) => new SizeOfSocialNetworkModel(size)),
          },
          error: '',
        },
      };

    case GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_FAIL:
      return {
        ...state,
        sizesOfSocialNetwork: {
          ...state.sizesOfSocialNetwork,
          loading: false,
          error: payload,
        },
      };

    case CREATE_AD_PROJECT_VIA_EDITOR_START:
      return {
        ...state,
        createProjectStatus: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case CREATE_AD_PROJECT_VIA_EDITOR_SUCCESS:
      return {
        ...state,
        createProjectStatus: {
          loading: false,
          success: true,
          error: '',
        },
        projectName: payload.metadata.title,
        gameId: payload.game.id,
        gameName: payload.game.name,
        socialNetworkId: payload.metadata.socialNetwork.id,
        socialNetworkName: payload.metadata.socialNetwork.title,
        selectedVideo: {
          id: payload.metadata.file.id,
          url: '/file-store/' + payload.metadata.file.directPath,
          width: payload.metadata.fileWidth,
          height: payload.metadata.fileHeight,
        },
        frameCutTime: payload.metadata.frameCutTime,
        sizesOfAdCreateProject: payload.metadata.sizes ? transformProjectSizesOnEnter(payload.metadata.sizes, payload.metadata.fileWidth, payload.metadata.fileHeight) : [],
        activeSizeOfAdCreateProject: null,
      };

    case CREATE_AD_PROJECT_VIA_EDITOR_RESET:
      return {
        ...state,
        createProjectStatus: {
          loading: false,
          success: false,
          error: '',
        },
      };

    case CREATE_AD_PROJECT_VIA_EDITOR_FAIL:
      return {
        ...state,
        createProjectStatus: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case CREATE_AD_PROJECT_VIA_RANDOMIZER_START:
      return {
        ...state,
        randomizerProjectId: '',
        randomizerRenderedFilesIds: [],
        randomizerProject: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case CREATE_AD_PROJECT_VIA_RANDOMIZER_SUCCESS:
      return {
        ...state,
        randomizerProjectId: payload.id,
        randomizerRenderedFilesIds: payload.data,
        randomizerProject: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case CREATE_AD_PROJECT_VIA_RANDOMIZER_RESET:
      return {
        ...state,
        randomizerProjectId: '',
        randomizerRenderedFilesIds: [],
        randomizerProject: {
          loading: false,
          success: false,
          error: '',
        },
      };

    case CREATE_AD_PROJECT_VIA_RANDOMIZER_FAIL:
      return {
        ...state,
        randomizerProjectId: '',
        randomizerRenderedFilesIds: [],
        randomizerProject: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case ADD_SIZE_TO_AD_CREATE_PROJECT:
      return {
        ...state,
        sizesOfAdCreateProject: new AdCreateProjectClassHelper(state.sizesOfAdCreateProject, state.activeSizeOfAdCreateProject, payload.size).addNewSize(payload.font, state.gameLogos.success.data[0]),
      };

    case REMOVE_SIZE_FROM_AD_CREATE_PROJECT:
      if (state.activeSizeOfAdCreateProject && payload === state.activeSizeOfAdCreateProject.id) {
        return {
          ...state,
          activeSizeOfAdCreateProject: null,
          sizesOfAdCreateProject: cloneDeep(state.sizesOfAdCreateProject).filter((size) => size.id !== payload),
        };
      } else {
        return {
          ...state,
          sizesOfAdCreateProject: cloneDeep(state.sizesOfAdCreateProject).filter((size) => size.id !== payload),
        };
      }

    case SET_ACTIVE_SIZE_OF_AD_CREATE_PROJECT:
      return {
        ...state,
        activeSizeOfAdCreateProject: payload,
      };

    case UPDATE_FIELD_OF_AD_CREATE_PROJECT_SIZE:
      cloneOfNeededState = cloneDeep(state.sizesOfAdCreateProject);
      neededItem = cloneOfNeededState.find((size) => size.id === payload.id);
      neededItem[payload.name] = payload.data;

      return {
        ...state,
        sizesOfAdCreateProject: cloneOfNeededState,
      };

    case UPDATE_FIELD_OF_AD_CREATE_PROJECT_ACTIVE_SIZE:
      cloneOfNeededState = cloneDeep(state.activeSizeOfAdCreateProject);
      cloneOfNeededState[payload.name] = payload.data;

      return {
        ...state,
        activeSizeOfAdCreateProject: cloneOfNeededState,
      };

    case UPDATE_BANNER_ITEMS_OF_ALL_PROJECT_SIZES:
      return {
        ...state,
        sizesOfAdCreateProject: new AdCreateProjectClassHelper(state.sizesOfAdCreateProject, {}, payload).changeButtons(),
      };

    case SET_VIDEO_FRAME_TIME_AD_CREATE_PROJECT:
      return {
        ...state,
        frameCutTime: Math.round(payload * 1000),
      };

    case SET_CROP_AREA_DIMENSION_AND_POSITION_AD_CREATE_PROJECT:
      return {
        ...state,
        cropAreaDimensionAndPosition: payload,
      };

    case TOGGLE_IMAGE_FORMAT_AD_CREATE_PROJECT:
      if (state.imageFormats.some((imageFormat) => imageFormat === payload)) {
        return {
          ...state,
          imageFormats: state.imageFormats.filter((imageFormat) => imageFormat !== payload),
        };
      } else {
        return {
          ...state,
          imageFormats: [...state.imageFormats, payload],
        };
      }

    case SAVE_AD_CREATE_PROJECT_START:
      return {
        ...state,
        saveProjectStatus: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case SAVE_AD_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        saveProjectStatus: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case SAVE_AD_CREATE_PROJECT_RESET:
      return {
        ...state,
        saveProjectStatus: {
          loading: false,
          success: false,
          error: '',
        },
      };

    case SAVE_AD_CREATE_PROJECT_FAIL:
      return {
        ...state,
        saveProjectStatus: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case RENDER_AD_CREATE_PROJECT_START:
      return {
        ...state,
        renderedFilesIds: [],
        renderProjectStatus: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case RENDER_AD_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        renderedFilesIds: payload,
        renderProjectStatus: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case RENDER_AD_CREATE_PROJECT_RESET:
      return {
        ...state,
        renderedFilesIds: [],
        renderProjectStatus: {
          loading: false,
          success: false,
          error: '',
        },
      };

    case RENDER_AD_CREATE_PROJECT_FAIL:
      return {
        ...state,
        renderedFilesIds: [],
        renderProjectStatus: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case ADD_OVERLAY_TO_AD_CREATE_PROJECT_SIZE: {
      const [cloneSizes, cloneActiveSize] = new AdCreateProjectClassHelper(state.sizesOfAdCreateProject, state.activeSizeOfAdCreateProject, payload).addOverlay();

      return {
        ...state,
        activeSizeOfAdCreateProject: cloneActiveSize,
        sizesOfAdCreateProject: cloneSizes,
      };
    }

    case RESET_AD_CREATE_PROJECT_EDITOR:
      return {
        ...emptyState,
      };

    default:
      return state;
  }
};

export default adCreateReducer;
