import React from 'react';
import PropTypes from 'prop-types';
import HistoryPage from '../../HistoryPage';
import Creative from 'services/Creative';


const CreativeHistory = ({ creativeId }) => {
  return (
    <>
      <h4 className='creative-section-name'>history</h4>
      <HistoryPage
        id={creativeId}
        idType={'creativeId'}
        service={(params) => Creative.getCreativeHistory(params)}
      />
    </>
  );
};

CreativeHistory.propTypes = {
  creativeId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default CreativeHistory;
