/**
 * @param {string} id
 * @param {string} name
 * @param {boolean} enabledForTrailer
 * @param {String[]} colors
 * @param {Object[]} fonts
 * @param {number} storageId
 */
class GameModel {
  constructor(metadata, game) {
    this.id = game.id.toString();
    this.name = game.name;
    this.enabledForTrailer = game.enabledForTrailer;
    this.colors = metadata.colors;
    this.fonts = metadata.fonts;
    this.storageId = game.gameAssetsStorageId;
  }
}

export default GameModel;
