import { bindActionCreators } from 'redux';

import { restrictPageAccess } from '../components/Navigation/Navigation.redux/actions';

/**
 * Middleware for checking user restriction for specific page.
 */
const restrictedAccessMiddleware = (store) => (next) => (action) => {
  if (action.error && action.error.code && (action.error.code === 403)) {
    return bindActionCreators(restrictPageAccess, store.dispatch)({
      isRestricted: true,
    });
  }

  return next(action);
};

export default restrictedAccessMiddleware;
