import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TiltingPointColorfulLogo } from 'assets/svg/logo/logo-colorful.svg';

import './AssetInfoEmptyState.scss';


const AssetInfoEmptyState = ({ entityName }) => {
  return (
    <div className='asset-info-empty-state'>
      <TiltingPointColorfulLogo
        className='asset-info-empty-state__icon'
      />
      <p className='asset-info-empty-state__title'>
        No {entityName} selected
      </p>
      <p className='asset-info-empty-state__subtitle'>
        Please, select minimum one {entityName} to<br />
        add or change Asset info
      </p>
    </div>
  );
};

AssetInfoEmptyState.defaultProps = {
  entityName: 'file',
};

AssetInfoEmptyState.propTypes = {
  entityName: PropTypes.string,
};

export default AssetInfoEmptyState;
