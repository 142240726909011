import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import {
  InputAdornment,
  TextField,
} from '@material-ui/core';
import CustomBadge from 'components/UIComponents/CustomBadge';
import CustomSelect from 'components/UIComponents/CustomSelect';
import CustomMultiSelect from 'components/UIComponents/CustomMultiSelect';
import {
  requiredError,
  textFormatError,
  maxError,
  VALIDATION,
} from 'constants/Validation';
import { allowOnlyNumbers } from 'utils/utils';
import {
  getJiraIssueMetadata,
  getJiraIssueTransitions,
} from '../../Creative.redux/Actions/jiraIssueActions';
import { getUsersList } from 'components/User/User.redux/profileActions';

import './CreativeGeneralInfoForm.scss';

const validationSchema = yup.object({
  userId: yup.number().required(requiredError),
  name: yup.string().max(255, maxError).matches(VALIDATION.CREATIVE_POST_TITLE, textFormatError).required(requiredError),
  format: yup.string().required(requiredError),
  department: yup.string().required(requiredError),
  statusId: yup.string().required(requiredError),
  platform: yup.array().min(1, requiredError),
  availableLanguage: yup.array().min(1, requiredError),
});


const CreativeGeneralInfoForm = ({
  jiraIssueId,
  jiraIssueKey,
  jiraIssueSummary,
  authorId,
  usersList,
  format,
  sizes,
  department,
  quantity,
  statusId,
  platform,
  language,
  onCancelEditCreative,
  onSaveCreative,
}) => {

  const dispatch = useDispatch();

  const {
    jiraIssueMetadata,
    issueTransitions,
  } = useSelector((state) => state.creatives);

  const {
    format: formats,
    department: departments,
    platform: platforms,
    availableLanguage: availableLanguages,
  } = jiraIssueMetadata || {};

  useEffect(() => {
    dispatch(getJiraIssueMetadata());
    dispatch(getUsersList({jiraUsers: true}));
  }, []);

  useEffect(() => {
    if (jiraIssueId) {
      dispatch(getJiraIssueTransitions(jiraIssueId));
    }
  }, [jiraIssueId]);

  return (
    <div className='creative-general-info-form'>
      <Formik
        enableReinitialize
        initialValues={{
          userId: authorId,
          name: jiraIssueSummary,
          format,
          department,
          statusId,
          platform,
          availableLanguage: language,
        }}
        initialTouched={{
          userId: false,
          name: false,
          format: false,
          department: false,
          statusId: false,
          platform: false,
          availableLanguage: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSaveCreative}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <div className='creative-general-info-form'>
            <div className='creative-general-info-form__main'>
              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>Author</div>

                <CustomSelect
                  data={usersList ?? []}
                  value={usersList?.find((user) => user.id === values.userId)}
                  handleSelect={(item) => setFieldValue('userId', item.id)}
                  handleBlur={() => setFieldTouched('userId')}
                  error={(touched.userId && errors.userId) ? errors.userId : ''}
                  withSearch
                />
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>jira ticket</div>

                <TextField
                  className={'creative-general-info-form__input'}
                  placeholder='jira issue title'
                  value={values.name}
                  error={!!(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>{jiraIssueKey} -</InputAdornment>,
                  }}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  onBlur={() => setFieldTouched('name')}
                />
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>format</div>
                <CustomSelect
                  data={formats ?? []}
                  value={{id: uuidv4(), name: values.format}}
                  handleSelect={(item) => setFieldValue('format', item.value)}
                  handleBlur={() => setFieldTouched('format')}
                  error={(touched.format && errors.format) ? errors.format : ''}
                  withSearch
                />
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>sizes</div>
                <div className='creative-general-info__field-value form'>
                  {sizes?.join(', ')}
                </div>
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>department</div>
                <CustomSelect
                  data={departments ?? []}
                  value={{id: uuidv4(), name: values.department}}
                  handleSelect={(item) => setFieldValue('department', item.value)}
                  handleBlur={() => setFieldTouched('department')}
                  error={(touched.department && errors.department) ? errors.department : ''}
                  withSearch
                />
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>quantity</div>
                <div className='creative-general-info__field-value form'>
                  {quantity}
                </div>
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>language</div>
                <CustomMultiSelect
                  data={availableLanguages ? availableLanguages.map((language) => language.value) : []}
                  value={values.availableLanguage ?? []}
                  handleSelect={(value) => setFieldValue('availableLanguage', value)}
                  handleBlur={() => setFieldTouched('availableLanguage')}
                  error={(touched.availableLanguage && errors.availableLanguage) ? errors.availableLanguage : ''}
                  withSearch
                />
                <div className='creative-general-info__field-badges'>
                  {values.availableLanguage?.map((language, index) => (
                    <CustomBadge
                      key={index}
                      name={language}
                      handleDelete={(selectedItem) => setFieldValue(
                        'availableLanguage',
                        values.availableLanguage?.filter((language) => language !== selectedItem)
                      )}
                    />
                  ))}
                </div>
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>status</div>
                <CustomSelect
                  data={issueTransitions?.map((status) => ({id: status.statusId, name: status.status})) ?? []}
                  value={{id: values.statusId, name: issueTransitions?.find((status) => status.statusId === values.statusId)?.status}}
                  handleSelect={(item) => setFieldValue('statusId', item.id)}
                  handleBlur={() => setFieldTouched('statusId')}
                  error={(touched.status && errors.status) ? errors.status : ''}
                  withSearch
                />
              </div>

              <div className='creative-general-info__field'>
                <div className='creative-general-info__field-label'>platform</div>
                <CustomMultiSelect
                  data={platforms ? platforms.map((platform) => platform.value) : []}
                  value={values.platform ?? []}
                  handleSelect={(value) => setFieldValue('platform', value)}
                  handleBlur={() => setFieldTouched('platform')}
                  error={(touched.platform && errors.platform) ? errors.platform : ''}
                  withSearch
                />
                <div className='creative-general-info__field-badges'>
                  {values.platform?.map((platform, index) => (
                    <CustomBadge
                      key={index}
                      name={platform}
                      handleDelete={(selectedItem) => setFieldValue(
                        'platform',
                        values.platform?.filter((platform) => platform !== selectedItem)
                      )}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className='creative-general-info-form__buttons-container'>
              <button
                className='creative-general-info-form__button'
                onClick={onCancelEditCreative}
              >
                Cancel
              </button>
              <button
                type={'submit'}
                onClick={handleSubmit}
                className='creative-general-info-form__button'
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

CreativeGeneralInfoForm.propTypes = {
  jiraIssueId: PropTypes.string,
  jiraIssueKey: PropTypes.string,
  jiraIssueSummary: PropTypes.string,
  authorId: PropTypes.number,
  authorName: PropTypes.string,
  authorAvatar: PropTypes.string,
  usersList: PropTypes.arrayOf(PropTypes.object),
  format: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.string),
  department: PropTypes.string,
  quantity: PropTypes.number,
  statusId: PropTypes.string,
  platform: PropTypes.arrayOf(PropTypes.string),
  language: PropTypes.arrayOf(PropTypes.string),
  onCancelEditCreative: PropTypes.func.isRequired,
  onSaveCreative: PropTypes.func.isRequired,
};

export default CreativeGeneralInfoForm;
