import React from 'react';
import PropTypes from 'prop-types';
import CollectionStorageGridView from '../CollectionStorageGridView';
import CollectionStorageListView from '../CollectionStorageListView';
import { VIEW_GRID } from 'constants/General';


const CollectionStorageResults = ({
  viewType,
  folders,
  files,
  canManageCollection,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  handleGameClick,
  handleMoveAssets,
  openAssetInfo,
}) => {
  return (
    <div className='collection-storage__results'>
      {viewType === VIEW_GRID ?
        <CollectionStorageGridView
          folders={folders}
          files={files}
          canManageCollection={canManageCollection}
          selectedAssetIdsSet={selectedAssetIdsSet}
          toggleAssetSelection={toggleAssetSelection}
          navigateToFolder={navigateToFolder}
          handleGameClick={handleGameClick}
          handleMoveAssets={handleMoveAssets}
          openAssetInfo={openAssetInfo}
        /> :
        <CollectionStorageListView
          folders={folders}
          files={files}
          canManageCollection={canManageCollection}
          selectedAssetIdsSet={selectedAssetIdsSet}
          toggleAssetSelection={toggleAssetSelection}
          navigateToFolder={navigateToFolder}
          handleGameClick={handleGameClick}
          handleMoveAssets={handleMoveAssets}
          openAssetInfo={openAssetInfo}
        />
      }
    </div>
  );
};

CollectionStorageResults.propTypes = {
  viewType: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  canManageCollection: PropTypes.bool,
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
};

export default CollectionStorageResults;
