import { v4 as uuidv4 } from 'uuid';
import GameAssetsTransformation from 'components/Laboratory/laboratoryHelpers';
import { VIDEO_RESIZE_EXTENSIONS } from 'constants/CreativeTool';
import {
  UNSUITABLE_STRUCTURE,
  GET_PROJECT_FAIL,

  GET_GAME_ASSETS_FAIL,

  GET_AUDIOS_SUCCESS,
  GET_VOICE_OVER_SUCCESS,
  GET_VOICE_OVER_END_CARDS_SUCCESS,

  GET_END_CARDS_SUCCESS,
  GET_TITLE_CARDS_SUCCESS,
  GET_MAIN_VIDEOS_SUCCESS,
  GET_COLORS_SUCCESS,
  GET_FONTS_SUCCESS,
  GET_APP_SEARCH_FONTS_SUCCESS,

  GET_LOWER_THIRDS_LANDSCAPE_SUCCESS,
  GET_LOWER_THIRDS_SQUARE_SUCCESS,
  GET_LOWER_THIRDS_PORTRAIT_SUCCESS,
  GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS,

  GET_IMAGE_OVERLAYS_SUCCESS,

  SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS,
  SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
  GET_LIVE_ACTION_VIDEOS_SUCCESS,

  GET_EFFECTS_LANDSCAPE_SUCCESS,
  GET_EFFECTS_SQUARE_SUCCESS,
  GET_EFFECTS_PORTRAIT_SUCCESS,
  GET_EFFECTS_FOUR_ON_FIVE_SUCCESS,

  RENDER_PROJECT_START,
  RENDER_PROJECT_SUCCESS,
  RENDER_PROJECT_FAIL,

  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAIL,

  RENDER_PROJECT_RESET,
  SET_PROJECT_COMPONENTS,

  SET_RENDER_STATUS,
  SAVE_PROJECT_RESET,

  GET_GENERAL_GAME_STORAGE_UUID_SUCCESS,

  SET_MAIN_VIDEOS_DATA,
  CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT,
} from './actionTypes';
import {
  SQUARE_4x5_FRAMES_ANIMATED_SUCCESS,
  SQUARE_4x5_FRAMES_STATIC_SUCCESS,
} from '../../VideoResize/VideoResize.redux/actionTypes';


const initState = {
  unsuitableStructure: false,
  projectFail: false,

  projectName: '',
  projectComponents: [],
  errorAssets: [],

  gameDataCount: 0,
  gameDataObject: {
    storageLiveActionVideos: [],
    storageIntroVideos: [],
    titleCards: [],
    endCards: [],
    mainVideos: [[], [], []],

    audios: [],
    voiceOver: [],
    voiceOverEndCards: [],

    lowerThirdsLandscape: [],
    lowerThirdsSquare: [],
    lowerThirdsPortrait: [],
    lowerThirdsFourOnFive: [],

    imageOverlays: [],

    effectsLandscape: [],
    effectsSquare: [],
    effectsPortrait: [],
    effectsFourOnFive: [],

    colors: [],
    fonts: [],

    framesPortraitStatic: [],
    framesPortraitAnimated: [],
    framesFourOnFiveStatic: [],
    framesFourOnFiveAnimated: [],

    flatMainVideos: [],
    flatFonts: [],
  },

  mainVideosFoldersStructure: [],

  loadingSave: false,
  successSave: false,
  errorSave: '',

  loadingRender: false,
  successRender: false,
  errorRender: '',

  renderStatus: '',

  storage_uuid: '',
  generalStorage_uuid: '',
};

/**
 * If initState.gameDataObject keys were changed, change them here too
 */
export const ASSET_NAMES_IN_REDUCER = {
  STORAGE_LIVE_ACTION_VIDEOS: 'storageLiveActionVideos',
  STORAGE_INTRO_VIDEOS: 'storageIntroVideos',
  TITLE_CARDS: 'titleCards',
  END_CARDS: 'endCards',

  AUDIOS: 'audios',
  VOICE_OVER: 'voiceOver',
  VOICE_OVER_END_CARDS: 'voiceOverEndCards',

  LOWER_THIRDS_LANDSCAPE: 'lowerThirdsLandscape',
  LOWER_THIRDS_SQUARE: 'lowerThirdsSquare',
  LOWER_THIRDS_PORTRAIT: 'lowerThirdsPortrait',
  LOWER_THIRDS_FOUR_ON_FIVE: 'lowerThirdsFourOnFive',

  IMAGE_OVERLAYS: 'imageOverlays',

  EFFECTS_LANDSCAPE: 'effectsLandscape',
  EFFECTS_SQUARE: 'effectsSquare',
  EFFECTS_PORTRAIT: 'effectsPortrait',
  EFFECTS_FOUR_ON_FIVE: 'effectsFourOnFive',

  COLORS: 'colors',
  FONTS: 'fonts',

  FRAMES_PORTRAIT_STATIC: 'framesPortraitStatic',
  FRAMES_PORTRAIT_ANIMATED: 'framesPortraitAnimated',
  FRAMES_FOUR_ON_FIVE_STATIC: 'framesFourOnFiveStatic',
  FRAMES_FOUR_ON_FIVE_ANIMATED: 'framesFourOnFiveAnimated',

  FLAT_MAIN_VIDEOS: 'flatMainVideos',
  FLAT_FONTS: 'flatFonts',
};


const videoEditorReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UNSUITABLE_STRUCTURE:
      return {
        ...state,
        unsuitableStructure: true,
      };

    case GET_PROJECT_FAIL:
      return {
        ...state,
        projectFail: payload,
      };

    case SET_PROJECT_COMPONENTS:
      return {
        ...state,
        projectName: payload.metadata.title,
        projectComponents: payload,
        storage_uuid: payload.game.storage.uuid,
      };

    case GET_GAME_ASSETS_FAIL:
      return {
        ...state,
        errorAssets: payload.error ? [...state.errorAssets, payload.error] : [...state.errorAssets],
        gameDataObject: {
          ...state.gameDataObject,
          [payload.assetName]: [],
        },
      };

    case GET_GENERAL_GAME_STORAGE_UUID_SUCCESS:
      return {
        ...state,
        generalStorage_uuid: payload,
      };

    case GET_TITLE_CARDS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          storageIntroVideos: [...new GameAssetsTransformation(payload).commonVideos()],
          titleCards: [
            ...state.gameDataObject.storageLiveActionVideos,
            ...new GameAssetsTransformation(payload).commonVideos(),
          ],
        },
      };

    case GET_END_CARDS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          endCards: [...new GameAssetsTransformation(payload).commonVideos()],
        },
      };

    case GET_MAIN_VIDEOS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          flatMainVideos: [
            ...state.gameDataObject.storageIntroVideos?.map((intro) => parseInt(intro.id)),
            ...state.gameDataObject.storageLiveActionVideos?.map((liveAction) => parseInt(liveAction.id)),
            ...payload?.map((mainVideo) => parseInt(mainVideo._meta.id)),
          ],
        },
        mainVideosFoldersStructure: [
          {name: 'others', open: false, subFolders: [
            {name: 'live action', files: state.gameDataObject.storageLiveActionVideos},
            {name: 'intros', files: state.gameDataObject.storageIntroVideos},
            {name: 'Without folder', files: [...new GameAssetsTransformation(payload).mainVideosWithoutFolder()]},
          ]},
          ...new GameAssetsTransformation(payload).mainVideosFolderStructure(),
        ],
      };

    case SET_MAIN_VIDEOS_DATA:
      return {
        ...state,
        gameDataObject: {
          ...state.gameDataObject,
          mainVideos: payload,
        },
      };

    case GET_LOWER_THIRDS_LANDSCAPE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          lowerThirdsLandscape: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_LOWER_THIRDS_SQUARE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          lowerThirdsSquare: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_LOWER_THIRDS_PORTRAIT_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          lowerThirdsPortrait: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          lowerThirdsFourOnFive: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_EFFECTS_LANDSCAPE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          effectsLandscape: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_EFFECTS_SQUARE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          effectsSquare: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_EFFECTS_PORTRAIT_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          effectsPortrait: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_EFFECTS_FOUR_ON_FIVE_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          effectsFourOnFive: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_IMAGE_OVERLAYS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          imageOverlays: [...new GameAssetsTransformation(payload).overlays()],
        },
      };

    case GET_APP_SEARCH_FONTS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          flatFonts: payload?.map((font) => parseInt(font._meta.id)),
        },
      };

    case GET_COLORS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          colors: [...new GameAssetsTransformation(payload).colors()],
        },
      };

    case GET_FONTS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          fonts: [...new GameAssetsTransformation(payload).fonts()],
        },
      };

    case GET_AUDIOS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          audios: [...new GameAssetsTransformation(payload, null, state.storage_uuid).audios()],
        },
      };

    case GET_VOICE_OVER_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          voiceOver: [...new GameAssetsTransformation(payload, state.generalStorage_uuid, state.storage_uuid).audios()],
        },
      };

    case GET_VOICE_OVER_END_CARDS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          voiceOverEndCards: [...new GameAssetsTransformation(payload, state.generalStorage_uuid, state.storage_uuid).audios()],
        },
      };

    case SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          framesPortraitStatic: [
            {id: uuidv4(), name: 'blur', url: 'none', type: VIDEO_RESIZE_EXTENSIONS.BLUR_PORTRAIT_CONTAINS_SQUARE},
            ...new GameAssetsTransformation(payload, null, null, VIDEO_RESIZE_EXTENSIONS.STATIC_PORTRAIT_CONTAINS_SQUARE).frames(),
          ],
        },
      };

    case SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          framesPortraitAnimated: [...new GameAssetsTransformation(payload, null, null, VIDEO_RESIZE_EXTENSIONS.ANIM_PORTRAIT_CONTAINS_SQUARE).frames()],
        },
      };

    case SQUARE_4x5_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          framesFourOnFiveStatic: [
            {id: uuidv4(), name: 'blur', url: 'none', type: VIDEO_RESIZE_EXTENSIONS.FOUR_ON_FIVE_CONTAINS_SQUARE_BLUR},
            ...new GameAssetsTransformation(payload, null, null, VIDEO_RESIZE_EXTENSIONS.FOUR_ON_FIVE_CONTAINS_SQUARE_STATIC).frames(),
          ],
        },
      };

    case SQUARE_4x5_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          framesFourOnFiveAnimated: [...new GameAssetsTransformation(payload, null, null, VIDEO_RESIZE_EXTENSIONS.FOUR_ON_FIVE_CONTAINS_SQUARE_ANIM).frames()],
        },
      };

    case GET_LIVE_ACTION_VIDEOS_SUCCESS:
      return {
        ...state,
        gameDataCount: state.gameDataCount + 1,
        gameDataObject: {
          ...state.gameDataObject,
          storageLiveActionVideos: [...new GameAssetsTransformation(payload).commonVideos()],
        },
      };

    case SAVE_PROJECT_START:
      return {
        ...state,
        loadingSave: true,
        successSave: false,
        successSaveOnLeave: false,
        errorSave: '',
      };

    case SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingSave: false,
        successSave: true,
        errorSave: '',
      };

    case SAVE_PROJECT_FAIL:
      return {
        ...state,
        loadingSave: false,
        successSave: false,
        errorSave: payload,
      };

    case SAVE_PROJECT_RESET:
      return {
        ...state,
        successSave: false,
        errorSave: '',
      };

    case RENDER_PROJECT_START:
      return {
        ...state,
        loadingRender: true,
        successRender: false,
        errorRender: '',
      };

    case RENDER_PROJECT_SUCCESS:
      return {
        ...state,
        loadingRender: false,
        successRender: true,
        errorRender: '',
      };

    case RENDER_PROJECT_FAIL:
      return {
        ...state,
        loadingRender: false,
        successRender: false,
        errorRender: payload,
      };

    case RENDER_PROJECT_RESET:
      return {
        ...state,
        successRender: false,
        errorRender: '',
      };

    case SET_RENDER_STATUS:
      return {
        ...state,
        renderStatus: payload,
      };

    case CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT:
      return initState;

    default:
      return state;
  }
};

export default videoEditorReducer;
