import { v4 as uuidv4 } from 'uuid';
import { VIDEO_CREATE_ASSETS_TYPES } from 'constants/CreativeTool';
import cloneDeep from 'lodash/cloneDeep';


export const cardExtensionEmptyStructure = {
  id: '',
  url: '',
  duration: 0,
  limit: 5000,
  limitMask: '00:05:00',
  muted: false,
  range: [0, 0],
  transition: 'fade',
  extension: '',
  width: 0,
  height: 0,
  thumbnail: '',
  voiceOver: {isEnabled: false, searchWord: '', id: '', name: '', url: ''},
  scale: {x: 0, y: 0, xComplete: 0, yComplete: 0, zoom: 1},
};

export const mainVideoExtensionEmptyStructure = {
  id: '',
  path: '',
  url: '',
  duration: 0,
  limit: 5000,
  limitMask: '00:05:00',
  muted: false,
  range: [0, 0],
  transition: 'fade',
  extension: '',
  width: 0,
  height: 0,
  thumbnail: '',
  voiceOver: {isEnabled: false, searchWord: '', id: '', name: '', url: ''},
  scale: {x: 0, y: 0, xComplete: 0, yComplete: 0, zoom: 1},
  overlays: [],
};

export const cardsEmptyStructure = {
  titleCard: {
    landscape: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.TITLE_CARD, custom_id: uuidv4()},
    square: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.TITLE_CARD, custom_id: uuidv4()},
    portrait: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.TITLE_CARD, custom_id: uuidv4()},
    fourOnFive: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.TITLE_CARD, custom_id: uuidv4()},
  },
  endCard: {
    landscape: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.END_CARD, custom_id: uuidv4()},
    square: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.END_CARD, custom_id: uuidv4()},
    portrait: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.END_CARD, custom_id: uuidv4()},
    fourOnFive: {...cardExtensionEmptyStructure, name: VIDEO_CREATE_ASSETS_TYPES.END_CARD, custom_id: uuidv4()},
  },
};

export const mainVideosEmptyStructure = [
  {
    landscape: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    square: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    portrait: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    fourOnFive: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
  },
  {
    landscape: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    square: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    portrait: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    fourOnFive: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
  },
  {
    landscape: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    square: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    portrait: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
    fourOnFive: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
  },
];
