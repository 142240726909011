import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchInput from '../SearchInput';
import NoSearchResults from '../NoSearchResults';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ListItemWithCheckbox from '../ListItemWithCheckbox';

import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import './CustomMultiSelect.scss';


const CustomMultiSelect = ({
  label,
  value,
  data,
  handleSelect,
  handleBlur,
  error,
  withSearch,
  withClear,
  placeholder,
  disabled,
  directionTop,
  width,
  getOneOption,
  pink,
}) => {

  const [isDropDown, setIsDropDown] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (searchWord) {
      setOptions(data.filter((dataItem) => new RegExp(searchWord, 'i').test(dataItem)));
    } else {
      setOptions([...data]);
    }
  }, [searchWord, data]);

  const handleOptionClick = (option) => {
    if (value.includes(option)) {
      handleSelect(value.filter((valueItem) => valueItem !== option));
      getOneOption(option);
    } else {
      handleSelect([...value, option]);
      getOneOption(option);
    }
  };

  const handleClearValue = (event) => {
    event.stopPropagation();
    handleSelect([]);
    setIsDropDown(false);
  };

  const handleClickAway = () => {
    setIsDropDown(false);
    handleBlur();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          className={classnames('custom-multi-select', {
            'active': isDropDown,
            'disabled': disabled,
            'error': error,
            'pink': pink,
          })}
          style={{
            width,
          }}
        >
          <div
            className={classnames('custom-multi-select__field', {
              'withClear': withClear,
              'withLabel': label,
            })}
            onClick={() => setIsDropDown(!isDropDown)}
          >
            {label &&
              <label className='custom-multi-select__label'>
                {label}
              </label>
            }

            <h4 className='custom-multi-select__field-title'>
              {value.length ?
                value.join(', ') :
                <span className='custom-multi-select__placeholder'>{!label && placeholder}</span>
              }
            </h4>
            <CloseIcon
              className={classnames('custom-multi-select__clean-value-icon', {
                'active': withClear,
              })}
              onClick={handleClearValue}
            />
            <div
              className={classnames('custom-multi-select__expand-icon', {
                'error': error,
              })}
            >
              {isDropDown ?
                <ExpandLessIcon /> :
                <ExpandMoreIcon />
              }
            </div>
          </div>

          <div
            className={classnames('custom-multi-select__drop-down', {
              'active': isDropDown,
              'top': directionTop,
              'withSearch': withSearch,
            })}
          >
            {(withSearch && !!data.length) &&
              <SearchInput
                searchWord={searchWord}
                handleSearch={(searchValue) => setSearchWord(searchValue)}
                withClear
                pink={pink}
              />
            }

            <div
              className={classnames('custom-multi-select__options', {
                'withSearch': withSearch,
              })}
            >
              {options.map((option) =>
                <ListItemWithCheckbox
                  key={option}
                  item={option}
                  checkedCondition={value.includes(option)}
                  handleCheck={() => handleOptionClick(option)}
                />
              )}

              {!options.length &&
                <NoSearchResults
                  height={50}
                  message={!searchWord ? 'no options' : 'no results found'}
                />
              }
            </div>
          </div>
        </div>

        {error &&
          <p className='custom-multi-select__error-text'>{error}</p>
        }
      </div>
    </ClickAwayListener>
  );
};

CustomMultiSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.string),
  handleSelect: PropTypes.func,
  getOneOption: PropTypes.func,
  handleBlur: PropTypes.func,
  withSearch: PropTypes.bool,
  withClear: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  directionTop: PropTypes.bool,
  width: PropTypes.string,
  pink: PropTypes.bool,
};

CustomMultiSelect.defaultProps = {
  handleBlur: () => {},
  handleSelect: () => {},
  getOneOption: () => {},
  placeholder: 'select',
};

export default CustomMultiSelect;
