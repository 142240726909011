import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import Routing from '../../Routing';
import GeneralMessagesContainer from '../../General/GeneralMessages/GeneralMessagesContainer';
import AssetsDetailsPreview from '../../Assets/AssetsDetailsPreview';
import DownloadAssets from '../../Assets/DownloadAssets';
import UploadProgress from '../../UploadProgress';
import RestrictedPermissions from '../../General/Permissions/RestrictedPermissions';
import { checkAuth } from '../../User/User.redux/authActions';
import { setMobileSizeStatus } from '../../Navigation/Navigation.redux/actions';
import { MOBILE_WIDTH } from 'constants/General';

import { ReactComponent as InitPreloadIcon } from 'assets/svg/loader/init-loader.svg';


const App = () => {

  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.user) || {};
  const { isRestricted } = useSelector((state) => state.navigation);
  const { assetsToPreview } = useSelector((state) => state.assetsToPreview);
  const { assetsToDownload } = useSelector((state) => state.assetsToDownload);
  const uploadProgressState = useSelector((state) => state.uploadProgress);

  useEffect(() => {
    dispatch(checkAuth());

    detectViewState({ target: window });
    window.addEventListener('resize', detectViewState);

    return () => {
      window.removeEventListener('resize', detectViewState);
    };
  }, []);

  useEffect(() => {
    if (profile?.role && profile?.role !== '') {
      ReactGA.set({
        pixelId: profile.id,
        jiraId: profile.jiraId,
      });
    }
  }, [profile?.role]);

  const detectViewState = (e) => {
    const { innerWidth } = e.target;

    dispatch(setMobileSizeStatus(innerWidth < MOBILE_WIDTH));
  };

  return (
    <>
      <Helmet>
        <title>Pixel</title>
      </Helmet>
      {isRestricted
        ? <RestrictedPermissions />
        : (
          <>
            <Routing />
            <GeneralMessagesContainer />

            {!!assetsToPreview.length &&
              <AssetsDetailsPreview />
            }

            {!!assetsToDownload.length &&
              <DownloadAssets />
            }

            {uploadProgressState?.open &&
              <UploadProgress uploadProgressState={uploadProgressState} />
            }

            <InitPreloadIcon
              style={{
                position: 'absolute',
                top: '-100%',
                left: '-100%',
              }}
            />
          </>
        )}
    </>
  );
};

export default App;
