import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './NoSearchResults.scss';


const NoSearchResults = ({ message, transparent, height }) => {
  return (
    <div
      className={classnames('no-search-result', {
        'transparent': transparent,
      })}
      style={{
        height: height ? `${height}px` : '100%',
      }}
    >
      {message}
    </div>
  );
};

NoSearchResults.defaultProps = {
  message: 'no results found',
};

NoSearchResults.propTypes = {
  message: PropTypes.string,
  height: PropTypes.number,
  transparent: PropTypes.bool,
};

export default NoSearchResults;
