import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import {
  MAX_FACET_SIZE,
  RESULTS_PER_PAGE,
  ENTITIES_SORT_ORDER,
} from './mainSearchConstants';


const apiConnector = new AppSearchAPIConnector({
  searchKey: process.env.REACT_APP_ELASTIC_SEARCH_KEY,
  engineName: process.env.REACT_APP_ELASTIC_ENGINE_NAME,
  endpointBase: process.env.REACT_APP_ELASTIC_ENDPOINT_BASE,
  cacheResponses: false,
});

export const FACETS = {
  SEARCH_CATEGORY: 'search_category',
  CREATIVE_GAME_NAME: 'creative_game_name',
  ASSET_GAME_NAME: 'asset_game_name',
  OWNER: 'owner',
  PROJECT_MANAGER: 'project_manager',
  REPORTER: 'reporter',
  DEPARTMENT: 'department',
  FORMAT: 'format',
  PROJECT_WORKFLOW: 'project_workflow',
  LANGUAGE: 'available_language',
  PLATFORM: 'platform',
  POD: 'pod',
  STUDIO: 'studio',
  LEVEL: 'level',
  UA_TYPE: 'jira_ua_type',
  USED_BY_UA: 'used_by_ua',
  MEDIA_SOURCE: 'media_source',
  SUCCESS_RATE: 'success_rate',
  LIVE: 'live',
  CREATED_AT: 'created_at',
  IS_FOLDER: 'is_folder',
  CATEGORY: 'category',
  SUB_CATEGORY: 'sub_category',
  DEVELOPER_FORMAT: 'developer_format',
  PRODUCTION_FORMAT: 'production_format',
  ASSET_TYPE: 'asset_type',
  FILE_EXTENSION: 'file_extension',
  TABS_NAME: 'tabs_name',
  DURATION_RANGE: 'duration_range',
  SOURCE_FILE: 'source_file',
  ORIENTATION: 'orientation',
  SIZE: 'size',
  JIRA_PROJECT_ID: 'jira_project_id',
  APPROVED: 'approved',
  GENRE: 'genre',
  ISSUE_TYPE: 'type',
  STATUS: 'status',
};

export const BOOLEAN_FACETS_ARRAY  = [
  FACETS.LIVE,
  FACETS.SOURCE_FILE,
  FACETS.USED_BY_UA,
];

export const NO_BADGES_FACETS_ARRAY = [
  FACETS.SEARCH_CATEGORY,
  FACETS.IS_FOLDER,
  FACETS.JIRA_PROJECT_ID,
  FACETS.APPROVED,
];

export const SEARCH_CATEGORY_ENGINES = {
  GAME: 'game',
  CREATIVE: 'creative',
  GAME_ASSET: 'game_asset',
  ASSET: 'asset',
};

export const SEARCH_CATEGORY_ENGINES_ITEMS_NAME_FIELDS = {
  ASSET: 'asset_name',
  CREATIVE: 'creative_title',
  GAME: 'game_name',
};

const ADDITIONAL_FIELDS_FOR_SEARCH_AND_SUGGESTIONS_ARRAY = [
  'asset_creative_title',
  'asset_issue_key',
  'description',
  'status',
  'type',
  'genres',
];

const ASSET_TYPE_WITH_ORIENTATION_AND_SIZE = ['image', 'video'];

const facetsCommonConfig = {
  type: 'value',
  sort: { value: ENTITIES_SORT_ORDER.ASC },
  size: MAX_FACET_SIZE,
};

const facetsConfig = Object.fromEntries(
  Object.values(FACETS)
    .map((facet) => ([facet, {name: facet, ...facetsCommonConfig}]))
);

const FACETS_EXCLUDED_FROM_SEARCH_AND_SUGGESTIONS = [
  ...BOOLEAN_FACETS_ARRAY,
  ...NO_BADGES_FACETS_ARRAY,
  FACETS.CREATED_AT,
];

const searchSuggestionsFields = [
  ...ADDITIONAL_FIELDS_FOR_SEARCH_AND_SUGGESTIONS_ARRAY,
  ...Object.values(FACETS).filter((facet) => !FACETS_EXCLUDED_FROM_SEARCH_AND_SUGGESTIONS.includes(facet)),
  ...Object.values(SEARCH_CATEGORY_ENGINES_ITEMS_NAME_FIELDS),
];

const searchFieldsConfig = Object.fromEntries(searchSuggestionsFields.map((facet) => ([facet, {}])));

const checkIfNeedToDisplayFacets = (filterName, allowedFilterValues) => {
  return ({ filters }) => {
    const isAssetTypeFilterActive = filters.some((filter) => filter.field === FACETS.ASSET_TYPE);

    return !isAssetTypeFilterActive || filters.some((filter) =>
      filter.field === filterName &&
      filter.values.every((value) => allowedFilterValues?.includes(value))
    );
  };
};

export const searchProviderConfig = {
  alwaysSearchOnInitialLoad: true,
  trackUrlState: false,
  urlPushDebounceLength: 100,
  apiConnector,
  initialState: {
    resultsPerPage: RESULTS_PER_PAGE,
    sortField: FACETS.CREATED_AT,
    sortDirection: ENTITIES_SORT_ORDER.DESC,
  },
  searchQuery: {
    disjunctiveFacets: Object.values(FACETS),
    disjunctiveFacetsAnalyticsTags: ['ignore'],
    facets: facetsConfig,
    conditionalFacets: {
      [FACETS.ORIENTATION]: checkIfNeedToDisplayFacets(FACETS.ASSET_TYPE, ASSET_TYPE_WITH_ORIENTATION_AND_SIZE),
      [FACETS.SIZE]: checkIfNeedToDisplayFacets(FACETS.ASSET_TYPE, ASSET_TYPE_WITH_ORIENTATION_AND_SIZE),
    },
    search_fields: searchFieldsConfig,
  },
  autocompleteQuery: {
    suggestions: {
      types: {
        documents: {  fields: searchSuggestionsFields },
      },
      size: 5,
    },
  },
};

export const defaultFilters = [
  { field: FACETS.SEARCH_CATEGORY, values: [SEARCH_CATEGORY_ENGINES.GAME_ASSET, SEARCH_CATEGORY_ENGINES.ASSET], type: 'any' },
  { field: FACETS.IS_FOLDER, values: ['false'], type: 'any' },
  { field: FACETS.APPROVED, values: ['true', 'false'], type: 'any' },
];
