import { v4 as uuidv4 } from 'uuid';
import {
  VIDEO_PROJECT_MAIN_VIDEO_DELETE,
  VIDEO_PROJECT_MAIN_VIDEO_UPDATE,
} from './actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import { VIDEO_PROJECT_DISCARD } from '../VideoProjectElements/actionTypes';
import { CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT } from '../../VideoEditor.redux/actionTypes';
import {
  mainVideosEmptyStructure,
  mainVideoExtensionEmptyStructure,
} from '../../videoProjectEmptyStructure';


const initState = cloneDeep(mainVideosEmptyStructure);

const videoProjectMainVideosReducer = (state = initState, action) => {
  const { type, payload } = action;
  let cloneMainVideoArray;

  switch (type) {
    case VIDEO_PROJECT_MAIN_VIDEO_UPDATE:
      return payload;

    case VIDEO_PROJECT_MAIN_VIDEO_DELETE:
      cloneMainVideoArray = cloneDeep(state);
      cloneMainVideoArray[payload] = {
        landscape: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
        square: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
        portrait: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
        fourOnFive: cloneDeep({...mainVideoExtensionEmptyStructure, custom_id: uuidv4()}),
      };

      return cloneMainVideoArray;

    case CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT:
    case VIDEO_PROJECT_DISCARD:
      return initState;

    default:
      return state;
  }
};

export default videoProjectMainVideosReducer;
