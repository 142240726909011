import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CustomTextarea from 'components/UIComponents/CustomTextarea';
import AccordionPanel from 'components/UIComponents/AccordionPanel';
import AssetCatTypeConfig from '../../AssetCatTypeConfig';
import { getAttributes } from '../../Assets.redux/Actions/attributeActions';

import './AssetInfoEditState.scss';

const AssetInfoEditState = ({
  assetInfo,
  setAssetInfo,
  assetInfoValidation,
  handleBlurAssetDescriptionTextarea,
  isUserExternal,
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAttributes());
  }, []);

  const handleChangeAssetInfoField = (filedName, fieldNewValue) => {
    setAssetInfo({
      ...assetInfo,
      [filedName]: assetInfo[filedName] === fieldNewValue ? null : fieldNewValue,
    });
  };

  if (!assetInfo) {
    return;
  }

  return (
    <div className='asset-info-edit-state'>
      <div className='asset-info-edit-state__inner-container'>
        <CustomTextarea
          label='description'
          value={assetInfo?.description}
          placeholder='Type here your text'
          onChange={(newDescription) => handleChangeAssetInfoField('description', newDescription)}
          onBlur={(newDescription) => handleBlurAssetDescriptionTextarea(newDescription)}
          error={assetInfoValidation.description}
          maxLength={100}
        />

        <AccordionPanel
          title='CAT Asset type'
          hidden={isUserExternal}
        >
          <AssetCatTypeConfig
            currentAssetTypeCat={assetInfo?.CATAssetType}
            toggleAssetTypeCat={(newTypeCat) => handleChangeAssetInfoField('CATAssetType', newTypeCat)}
          />
        </AccordionPanel>
      </div>
    </div>
  );
};

AssetInfoEditState.propTypes = {
  assetInfo: PropTypes.object,
  setAssetInfo: PropTypes.func.isRequired,
  assetInfoValidation: PropTypes.object,
  handleBlurAssetDescriptionTextarea: PropTypes.func.isRequired,
  isUserExternal: PropTypes.bool,
};

export default AssetInfoEditState;
