import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { childrenPropType } from '../../../types/general';
import colorsVariables from 'styles/colors.module.scss';


const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colorsVariables.white,
    color: colorsVariables.pink,
    fontSize: 10,
    letterSpacing: 0.3,
  },
  tooltipPlacementBottom: {
    margin: '1px 0',
  },
}))(Tooltip);

const StyledTooltip = (props) => {
  const { children, ...tooltipProps} = props;
  return (
    <CustomTooltip {...tooltipProps}>
      {children}
    </CustomTooltip>
  );
};

StyledTooltip.propTypes = {
  children: childrenPropType,
};

export default StyledTooltip;
