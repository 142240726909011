/**
 * @param {number} id
 * @param {string} title
 * @param {object} projectManager
 * @param {object} lead1
 * @param {object} lead2
 * @param {number} clHeadcount
 * @param {number} artistHeadcount
 * @param {number} freelancerHours
 * @param {number} vendorHours
 */
class PodCapacityModel {
  constructor(data) {
    this.id = data.id;
    this.title = data.title;
    this.projectManager = data.projectManager || {id: null, name: ''};
    this.lead1 = data.leads && data.leads[0]?.user ? data.leads[0].user : {id: null, name: ''};
    this.lead2 = data.leads && data.leads[1]?.user ? data.leads[1].user : {id: null, name: ''};
    this.clHeadcount = data.clHeadcount;
    this.artistHeadcount = data.artistHeadcount;
    this.freelancerHours = data.freelancerHours;
    this.vendorHours = data.vendorHours;
  }
}

export default PodCapacityModel;
