import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import VideoPlayOverlay from 'components/UIComponents/VideoPlayOverlay';
import CustomCheckbox from 'components/UIComponents/CustomCheckbox';
import EntityPreview from '../../Parts/EntityPreview';
import EntityOverlay from '../../Parts/EntityOverlay';
import EntityStatus from '../../Parts/EntityStatus';
import EntityDoNotUseOverlay from '../../Parts/EntityDoNotUseOverlay';
import { stringOrNumberPropType } from 'types/general';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import ZoomInIcon from '@material-ui/icons/ZoomIn';

import './FileListItem.scss';


const FileListItem = ({
  id,
  name,
  extension,
  gameName,
  jiraTicketName,
  jiraTicketLink,
  createdAt,
  weight,
  live,
  approved,
  isSelected,
  previewUrl,
  hasPreview,
  hasVideoPreview,
  toggleSelection,
  handleGameClick,
  userCanManage,
  dragAndDropAcceptType,
  openFileInfo,
}) => {

  const [, drag] = useDrag(() => ({
    type: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    item: { id, name, isFolder: false, extension },
  }));

  return (
    <div
      ref={userCanManage ? (element) => drag(element) : null}
      className={classnames('file-list-item', {
        'gameStorage': !jiraTicketName && !gameName,
      })}
    >
      <CustomCheckbox
        checkedCondition={isSelected}
        handleCheck={toggleSelection}
      />

      <div id={id} className='file-list-item__preview'>

        <EntityPreview
          previewUrl={previewUrl}
          hasPreview={hasPreview}
          alt={'File preview'}
        />

        {hasVideoPreview &&
          <VideoPlayOverlay className={'file-list-item__video-play-icon'} />
        }

        <EntityOverlay isActive={isSelected}>
          <div
            className='file-list-item__info-icon-container'
            onClick={openFileInfo}
          >
            <ZoomInIcon className='file-list-item__info-icon' />
          </div>
        </EntityOverlay>

        {!approved &&
          <EntityDoNotUseOverlay iconFontSize={35} />
        }
      </div>

      <EntityStatus live={live} />

      <p className='file-list-item__name'>{name}</p>

      <p className='file-list-item__extension'>{extension}</p>

      {gameName &&
        <p
          className={classnames('file-list-item__game-name', {
            'clickable': handleGameClick,
          })}
          onClick={handleGameClick}
        >
          {gameName}
        </p>
      }

      {jiraTicketName &&
        <a
          rel='noreferrer'
          href={jiraTicketLink}
          target={'_blank'}
          className='file-list-item__jira-ticket'
        >
          {jiraTicketName}
        </a>
      }

      <p className='file-list-item__creation-date'>{createdAt}</p>

      <p className='file-list-item__size'>{weight}</p>
    </div>
  );
};

FileListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  extension: PropTypes.string,
  gameName: PropTypes.string,
  jiraTicketName: PropTypes.string,
  jiraTicketLink: PropTypes.string,
  createdAt: PropTypes.string,
  weight: stringOrNumberPropType,
  live: PropTypes.bool,
  approved: PropTypes.bool,
  isSelected: PropTypes.bool,
  previewUrl: PropTypes.string,
  hasPreview: PropTypes.bool,
  hasVideoPreview: PropTypes.bool,
  toggleSelection: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func,
  userCanManage: PropTypes.bool,
  dragAndDropAcceptType: PropTypes.string,
  openFileInfo: PropTypes.func.isRequired,
};

export default FileListItem;
