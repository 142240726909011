import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StyledTooltip from 'components/UIComponents/StyledTooltip';
import ConfirmRoundModal from 'components/UIComponents/ConfirmRoundModal';
import { setAssetsToPreviewAction } from 'components/Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/actions';
import { setAssetsToDownloadAction } from 'components/Assets/DownloadAssets/DownloadAssets.redux/actions';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
} from '@material-ui/icons';

import './CollectionAssetsActions.scss';


const CollectionAssetsActions = ({
  collectionId,
  collectionHash,
  canManageCollection,
  selectedAssetIdsSet,
  selectedAssets,
  setFolderToRename,
  setIsDeleteAssetsModal,
}) => {

  const dispatch = useDispatch();

  const [isConfirmDownloadModal, setIsConfirmDownloadModal] = useState(false);

  const canEdit = useMemo(() => {
    if (selectedAssetIdsSet.size === 1 && selectedAssets[0]?.isFolder) {
      return canManageCollection;
    } else {
      return selectedAssetIdsSet.size && selectedAssets.every((selectedAsset) => !selectedAsset.isFolder);
    }
  }, [selectedAssets, selectedAssetIdsSet]);

  const handleOpenAssetsPreview = (assetsToPreview) => {
    if (assetsToPreview[0].isFolder) {
      setFolderToRename(assetsToPreview[0]);
    } else {
      dispatch(setAssetsToPreviewAction(assetsToPreview, false, true));
    }
  };

  const handleDeleteCollectionAssets = () => {
    setIsDeleteAssetsModal(true);
  };

  const proceedDownloadCollectionAssets = async () => {
    await dispatch(setAssetsToDownloadAction(selectedAssets, collectionId, collectionHash));
    setIsConfirmDownloadModal(false);
  };

  const handleDownloadCollectionAssets = async () => {
    await dispatch(setAssetsToDownloadAction([]));
    if (selectedAssets.some((asset) => !asset.approved)) {
      setIsConfirmDownloadModal(true);
    } else {
      proceedDownloadCollectionAssets();
    }
  };

  return (
    <div className='collection-assets-actions'>
      <StyledTooltip title='More info' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('collection-assets-actions__icon-container', {
            'hidden': !canEdit,
          })}
          onClick={() => handleOpenAssetsPreview(selectedAssets)}
        >
          <EditIcon className='collection-assets-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Delete' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('collection-assets-actions__icon-container', {
            'hidden': !canManageCollection || !selectedAssetIdsSet.size,
          })}
          onClick={handleDeleteCollectionAssets}
        >
          <DeleteIcon className='collection-assets-actions__icon' />
        </div>
      </StyledTooltip>

      <StyledTooltip title='Download' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('collection-assets-actions__icon-container', {
            'hidden': !selectedAssetIdsSet.size,
          })}
          onClick={handleDownloadCollectionAssets}
        >
          <GetAppIcon className='collection-assets-actions__icon' />
        </div>
      </StyledTooltip>

      <ConfirmRoundModal
        isOpen={isConfirmDownloadModal}
        title='Download Not approved asset ?'
        text='You are downloading an asset that has not been approved and cannot be published. Please make sure to use appropriately. Do you still want to download it?'
        agreeText='yes'
        agreeIcon=''
        cancelText='no'
        cancelIcon=''
        onAgree={proceedDownloadCollectionAssets}
        onClose={() => setIsConfirmDownloadModal(false)}
      />
    </div>
  );
};

CollectionAssetsActions.propTypes = {
  collectionId: PropTypes.number.isRequired,
  collectionHash: PropTypes.string,
  canManageCollection: PropTypes.bool,
  selectedAssetIdsSet: PropTypes.object,
  selectedAssets: PropTypes.arrayOf(PropTypes.object),
  setFolderToRename: PropTypes.func.isRequired,
  setIsDeleteAssetsModal: PropTypes.func.isRequired,
};

export default CollectionAssetsActions;
