export const configTemplate = {
  'config_version': '5.0',
  'tp_api_key': '',
  'registration_endpoint': 'https://registration.tiltingpoint.io/v3',
  'gcm_sender_id': '',
  'attribution': {
    'adjust_ios': {
      'app_token': '',
      'app_launch': '',
      'tutorial_complete': '',
      'iap_buy': '',
      'ad_event': '',
      'cs_event': '',
      'level_progress_event': '',
      'custom_event': '',
      'universal_link_hash': '',
    },
    'adjust_android': {
      'app_token': '',
      'app_launch': '',
      'tutorial_complete': '',
      'iap_buy': '',
      'ad_event': '',
      'cs_event': '',
      'level_progress_event': '',
      'custom_event': '',
    },
  },
  'customersupport': {
    'api_key': '',
    'domain': 'tiltingpoint.helpshift.com',
    'app_id': {
      'android': '',
      'ios': '',
    },
  },
  'ads': {
    'supersonic': {
      'app_key': {
        'android': '',
        'ios': '',
      },
    },
  },
  'crm': {
    'leanplum': {
      'app_id': '',
      'dev_key': '',
      'prod_key': '',
    },
  },
  'analytics': {
    'amplitude': {
      'api_key': '',
    },
  },
  'google_app_id': '',
  'google_api_key': '',
};

