import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

const OwnerNameLabel = ({ ownerName, className, setFilter }) => {
  const searchRouteMatch = useRouteMatch('/search');
  const history = useHistory();

  const handleClick = () => {
    if (searchRouteMatch) {
      if (setFilter) {
        setFilter('owner', ownerName, 'any');
      }
    } else {
      history.push('/search', { ownerName });
    }
  };


  return (
    <span
      className={classNames([styles.ownerName, className])}
      onClick={handleClick}
    >
      {ownerName}
    </span>
  );
};

OwnerNameLabel.propTypes = {
  ownerName: PropTypes.string,
  className: PropTypes.string,
  setFilter: PropTypes.func,
};

export default OwnerNameLabel;
