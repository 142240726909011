import {
  EXPORT_TO_PDF_FAIL_CREATIVE_DASHBOARD,
  EXPORT_TO_PDF_START_CREATIVE_DASHBOARD,
  EXPORT_TO_PDF_SUCCESS_CREATIVE_DASHBOARD,
} from './actionsTypes';


const initState = {
  exportToPDFStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const creativeDashboardsReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {

    case EXPORT_TO_PDF_START_CREATIVE_DASHBOARD:
      return {
        ...state,
        exportToPDFStatus: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case EXPORT_TO_PDF_SUCCESS_CREATIVE_DASHBOARD:
      return {
        ...state,
        exportToPDFStatus: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case EXPORT_TO_PDF_FAIL_CREATIVE_DASHBOARD:
      return {
        ...state,
        exportToPDFStatus: {
          loading: false,
          success: false,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default creativeDashboardsReducer;