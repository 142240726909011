import {
  SET_ASSETS_TO_DELETE,

  DELETE_ASSETS_FAIL,
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
} from './actionsTypes';
import Assets from 'services/Assets';
import {
  setTriggerForRefreshingMainSearchAction,
  toggleMainSearchLoadingAfterAction,
} from 'components/MainSearch/MainSearch.redux/actions';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const setAssetsToDeleteAction = (data) => {
  return {
    type: SET_ASSETS_TO_DELETE,
    payload: data,
  };
};

export const deleteAssetsAction = (data, isNeedToUpdateElastic, actionAfterSuccessfulDelete) => (dispatch) => {
  dispatch({ type: DELETE_ASSETS_REQUEST });

  if (isNeedToUpdateElastic) {
    dispatch(toggleMainSearchLoadingAfterAction(true));
  }

  return Assets.deleteAssets(data)
    .then(() => {
      dispatch({type: DELETE_ASSETS_SUCCESS});

      actionAfterSuccessfulDelete();

      if (isNeedToUpdateElastic) {
        dispatch(setTriggerForRefreshingMainSearchAction());
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.delete.text,
        description: notifications.asset.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: DELETE_ASSETS_FAIL,
        error: getErrorMessage(error),
      });

      if (isNeedToUpdateElastic) {
        dispatch(toggleMainSearchLoadingAfterAction(false));
      }

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    })
    .finally(() => {
      dispatch(setAssetsToDeleteAction({
        assetsToDeleteIds: [],
        actionAfterDelete: undefined,
        updateElastic: false,
      }));
    });
};
