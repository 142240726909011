import colors from '../colors.module.scss';

export const MuiMenuItem = {
  root: {
    padding: '1em 1.25em !important',
    border: '1px solid',
    borderColor: colors.lightGrey,
    color: colors.darkGrey,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover:not($selected)': {
      color: 'white',
      backgroundColor: colors.blue,
    },
  },
};
