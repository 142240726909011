import { v4 as uuidv4 } from 'uuid';
import {
  ADD_GENERAL_MESSAGE,
  REMOVE_GENERAL_MESSAGE,
} from './actionTypes';


const initState = {
  generalMessages: [],
};

const generalMessagesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case ADD_GENERAL_MESSAGE:
      return {
        ...state,
        generalMessages: [
          ...state.generalMessages,
          {id: uuidv4(), ...payload},
        ],
      };

    case REMOVE_GENERAL_MESSAGE:
      return {
        ...state,
        generalMessages: state.generalMessages.filter((message) => message.id !== payload),
      };

    default:
      return state;
  }
};

export default generalMessagesReducer;