import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomCheckbox from 'components/UIComponents/CustomCheckbox';
import EntityOverlay from '../../Parts/EntityOverlay';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import { ReactComponent as FolderIcon } from 'assets/svg/folder.svg';

import './FolderListItem.scss';


const FolderListItem = ({
  id,
  name,
  path,
  createdAt,
  isSelected,
  toggleSelection,
  navigateToFolder,
  userCanManage,
  dragAndDropAcceptType,
  onDropAssets,
  isCollectionFolder,
}) => {

  const [, drag] = useDrag(() => ({
    type: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    item: { id, name, isFolder: true },
  }));

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: dragAndDropAcceptType || ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME,
    canDrop: (item) => item.id !== id,
    drop: (item) => onDropAssets(item, path ? path.substring(1) : id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return (
    <div
      className={classnames('folder-list-item', {
        'drop': (isOver && canDrop && userCanManage),
        'collection': isCollectionFolder,
      })}
      ref={userCanManage ?
        (element) => {
          drag(element);
          drop(element);
        } :
        null
      }
    >
      <CustomCheckbox
        checkedCondition={isSelected}
        handleCheck={toggleSelection}
      />

      <div id={id} className='folder-list-item__preview'>
        <FolderIcon className='folder-list-item__icon' />
        <EntityOverlay
          isActive={isSelected}
          onDoubleClick={navigateToFolder}
        />
      </div>

      <div />

      <p className='folder-list-item__name'>{name}</p>

      <p className='folder-list-item__extension'>folder</p>

      {isCollectionFolder &&
        <>
          <span />
          <span />
        </>
      }

      <p className='folder-list-item__creation-date'>{createdAt}</p>

      {isCollectionFolder && <span />}
      <div />
    </div>
  );
};

FolderListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  createdAt: PropTypes.string,
  isSelected: PropTypes.bool,
  toggleSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  userCanManage: PropTypes.bool,
  dragAndDropAcceptType: PropTypes.string,
  onDropAssets: PropTypes.func,
  isCollectionFolder: PropTypes.bool,
};

export default FolderListItem;
