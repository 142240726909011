import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
} from '@material-ui/core';

const FilterSelectDialog = (props) => {
  const {
    onClose,
    onSelect,
    filters,
    open,
  } = props;

  const handleListItemClick = (value) => {
    if (onSelect) {
      onSelect(value);
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='simple-dialog-title'>
      <DialogTitle id='simple-dialog-title'>Select filters</DialogTitle>
      <div>
        <List>
          {filters.map((filterKey) => (
            <ListItem
              key={filterKey}
              button
              onClick={() => handleListItemClick(filterKey)}
            >
              <ListItemText primary={filterKey} />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
};

FilterSelectDialog.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

export default FilterSelectDialog;
