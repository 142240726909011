import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as TiltingPointLogo } from '../../../assets/svg/logo/logo-colorful.svg';

import styles from './styles.module.scss';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.errorCode}>
        4<TiltingPointLogo className={styles.icon} />4
      </div>
      <Typography variant='h1'>
        Sorry, page not found
      </Typography>
      <div className={styles.controls}>
        <Link to='/home'>
          <Button
            variant='contained'
            color='primary'
          >
            Go to Home page
          </Button>
        </Link>
        <Button
          variant='contained'
          color='primary'
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
