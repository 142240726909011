import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import {
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

import AnalyticsEventListView from './AnalyticsEventListView';
import DeviceSelectDialog from './DeviceSelectDialog';
import FilterSelectDialog from './FilterSelectDialog';
import HeaderWithControls from '../UIComponents/HeaderWithControls';
import {
  getAnalyticsEvents,
  getMostRecentTestDevice,
  removeAnalyticsEvents,
  getAllAnalyticsGames,
} from './AnalyticsEvent.redux/actions';

import styles from './styles.module.scss';

class AnalyticsEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devicesSelectOpen: false,
      filterSelectOpen: false,
      handleSelectFilter: null,
      tpdid: '',
      gameId: 'warhammer',
      provider: 'tpevents',
      page: 0,
      rowsPerPage: 15,
      filters: {
        $or: [
          { verified: true },
          { verified: false },
          { verified: 'NULL' },
        ],
        $and: [],
      },
      filterStates: {},
    };
  }

  componentDidMount() {
    const { getMostRecentTestDevice, getAllAnalyticsGames } = this.props;

    const { gameId, provider } = this.state;

    getMostRecentTestDevice(gameId, provider);
    getAllAnalyticsGames();
  }

  handleDeviceSelectOpen = () => {
    const { gameId, provider } = this.state;

    this.props.getMostRecentTestDevice(gameId, provider);
    this.setState({ devicesSelectOpen: true });
  }

  handleDeviceSelectClose = () => this.setState({ devicesSelectOpen: false });

  handleFilterSelectClose = () => this.setState({ filterSelectOpen: false });

  handleTpdidChange = (event) => {
    const tpdid = event.target.value;

    this.setState({ tpdid });
  }

  handleGameIdChange = (value) => this.setState({ gameId: value });

  handleProviderChange = (value) => this.setState({ provider: value });

  handleSelectTestDevice = (tpdid) => {
    const {
      page,
      gameId,
      provider,
      rowsPerPage,
      filters,
    } = this.state;

    this.setState({ tpdid });

    const query = {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      gameId,
      provider,
      tpdid,
    };

    this.props.getAnalyticsEvents(query, filters);
  }

  handleChangePage = (event, page) => {
    const {
      gameId,
      provider,
      rowsPerPage,
      tpdid,
      filters,
    } = this.state;

    this.setState({ page });

    const query = {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      gameId,
      provider,
      tpdid,
    };

    this.props.getAnalyticsEvents(query, filters);
  }

  handleChangeRowsPerPage = (event) => {
    const {
      page,
      gameId,
      provider,
      tpdid,
      filters,
    } = this.state;

    const rowsPerPage = +event.target.value;

    this.setState({ rowsPerPage });

    const query = {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      gameId,
      provider,
      tpdid,
    };

    this.props.getAnalyticsEvents(query, filters);
  }

  handleDeleteOrFilter = (index, orIndex) => {
    this.setState((prevState) => {
      const newFilters = cloneDeep(prevState.filters);

      if (newFilters['$and'][index]['$or'].length === 1) {
        newFilters['$and'] = newFilters['$and']
          .filter((filter, newIdx) => newIdx !== index);
      } else {
        newFilters['$and'][index]['$or'] = newFilters['$and'][index]['$or']
          .filter((filter, newIdx) => orIndex !== newIdx);
      }

      return { filters: newFilters };
    });
  }

  handleDeleteAndFilter = (index) => {
    this.setState((prevState) => {
      const newFilters = cloneDeep(prevState.filters);

      newFilters['$and'] = newFilters['$and'].filter((filter, newIdx) => index !== newIdx);

      return { filters: newFilters };
    });
  }

  handleOpenFilterValue = (filterItemKey) => {
    this.setState((prevState) => {
      const newFilterStates = {
        ...prevState.filterStates,
      };
      newFilterStates[filterItemKey] = false;

      return { filterStates: newFilterStates };
    });
  }

  handleCloseFilterValue = (filterItemKey) => {
    this.setState((prevState) => {
      const newFilterStates = {
        ...prevState.filterStates,
      };
      newFilterStates[filterItemKey] = true;

      return { filterStates: newFilterStates };
    });
  }

  handleChangeOrFilterValue = (index, orIndex, filterKey) => (event) => {
    const value = event.target.value;

    this.setState((prevState) => {
      const newFilters = cloneDeep(prevState.filters);

      newFilters['$and'][index]['$or'][orIndex][filterKey]['$like'] = value;

      return { filters: newFilters };
    });
  }

  handleChangeAndFilterValue = (index, filterKey) => (event) => {
    const value = event.target.value;

    this.setState((prevState) => {
      const newFilters = cloneDeep(prevState.filters);

      newFilters['$and'][index][filterKey]['$like'] = value;

      return { filters: newFilters };
    });
  }

  handleAddOrFilter = (index) => {
    this.setState({
      filterSelectOpen: true,
      handleSelectFilter: this.getSelectOrFilterHandler(index),
    });
  }

  handleAddAndFilter = () => {
    this.setState({
      filterSelectOpen: true,
      handleSelectFilter: this.handleSelectAndFilter,
    });
  }

  getSelectOrFilterHandler = (index) => (value) => {
    this.setState(({ filters }) => {
      const filterKey = value;
      const newFilters = cloneDeep(filters);

      if ('$or' in newFilters['$and'][index]) {
        newFilters['$and'][index]['$or'].push({
          [`record.${filterKey}`]: {
            $like: '%',
          },
        });
      } else {
        newFilters['$and'][index] = {
          '$or': [
            filters['$and'][index],
            {
              [`record.${filterKey}`]: {
                $like: '%',
              },
            },
          ],
        };
      }

      return { filters: newFilters };
    });
  }

  handleSelectAndFilter = (value) => {
    this.setState((prevState) => {
      const filterKey = value;
      const newFilters = cloneDeep(prevState.filters);

      newFilters['$and'].push({
        [`record.${filterKey}`]: {
          $like: '%',
        },
      });

      return { filters: newFilters };
    });
  }

  handleFilterCheckboxChange = (filterValue) => (event) => {
    const checked = event.target.checked;

    this.setState((prevState) => {
      const newFilters = cloneDeep(prevState.filters);

      if (checked) {
        newFilters['$or'].push({ verified: filterValue });
      }
      else {
        newFilters['$or'] = newFilters['$or'].filter((filter) => filter['verified'] !== filterValue);
      }

      return { filters: newFilters };
    });
  }

  handleRefresh = () => {
    const {
      rowsPerPage,
      page,
      gameId,
      provider,
      tpdid,
      filters,
    } = this.state;

    const query = {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      gameId,
      provider,
      tpdid,
    };

    this.props.getAnalyticsEvents(query, filters);
  }

  render() {
    const {
      analyticsEvents,
      totalCount,
      removeAnalyticsEvents,
      testDevices,
      analyticsGames,
    } = this.props;

    const {
      page,
      devicesSelectOpen,
      gameId,
      provider,
      tpdid,
      rowsPerPage,
      filters,
      filterSelectOpen,
      handleSelectFilter,
      filterStates,
    } = this.state;

    const andFilters = filters['$and'];
    const orFilters = filters['$or'];

    const hasVerifiedFilter = orFilters.some((filter) => filter['verified'] === true);
    const hasInvalidFilter = orFilters.some((filter) => filter['verified'] === false);
    const hasNullFilter = orFilters.some((filter) => filter['verified'] === 'NULL');

    const testDeviceSelectDialog = (
      <DeviceSelectDialog
        devices={testDevices}
        open={devicesSelectOpen}
        onClose={this.handleDeviceSelectClose}
        onSelect={this.handleSelectTestDevice}
      />
    );

    const filterKeyOptions = [
      'state_name',
      'event_name',
      'event_id',
      'state_id',
      'state_time_server',
      'event_time_server',
      'player_name',
      'ip_country',
      'user_id',
      'session_duration',
      'session_duration_focused',
    ];

    const filterSelectDialog = (
      <FilterSelectDialog
        filters={filterKeyOptions}
        open={filterSelectOpen}
        onClose={this.handleFilterSelectClose}
        onSelect={handleSelectFilter}
      />
    );

    const tpProviderSuggestions = ['tpevents', 'adjust'];

    const gameIds = analyticsGames === 0
      ? ['warhammer', 'languinis', 'zombieland', 'terragenesis', 'sdkharnessunity']
      : analyticsGames;

    const userInputBox = (
      <form>
        <div className={styles.fieldsContainer}>
          <div className={styles.field}>
            <FormLabel>Provider</FormLabel>
            <Autocomplete
              defaultValue={provider}
              options={tpProviderSuggestions}
              onChange={(event, value) => this.handleProviderChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={false}
                  placeholder='tpevents'
                  variant='outlined'
                />
              )}
            />
          </div>
          <div className={styles.field}>
            <FormLabel>Game ID</FormLabel>
            <Autocomplete
              defaultValue={gameId}
              options={gameIds}
              onChange={(event, value) => this.handleGameIdChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth={false}
                  placeholder='Game ID'
                  variant='outlined'
                />
              )}
            />
          </div>
          <div className={styles.field}>
            <FormLabel>TPDID</FormLabel>
            <TextField
              variant='outlined'
              placeholder='TPDID'
              value={tpdid}
              onChange={this.handleTpdidChange}
            />
          </div>
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={this.handleDeviceSelectOpen}
        >
          Show Devices
        </Button>
        <div className={styles.filters}>
          <FormLabel>Filters</FormLabel>
          {andFilters.map((item, index) => {
            let container;

            if ('$or' in item) {
              const {
                $or: orList,
              } = item;

              container = orList.map((orItem, orIndex) => {
                const filterKey = Object.keys(orItem)[0];
                const suffix = `${index}-${orIndex}`;
                const filterItemKey = `${filterKey}${suffix}`;

                return (
                  <span key={filterItemKey}>
                    {orIndex === 0
                      ? <Chip label='and' color='primary' />
                      : <Chip label='or' color='secondary' />
                    }
                    {filterStates[filterItemKey]
                      ? (
                        <Chip
                          label={`${filterKey.match('^record.(.*)')[1]} like '${orItem[filterKey]['$like']}'`}
                          onClick={() => this.handleOpenFilterValue(filterItemKey)}
                          onDelete={() => this.handleDeleteOrFilter(index, orIndex)}
                        />
                      )
                      : (
                        <TextField
                          autoFocus
                          variant='outlined'
                          label={filterKey.match('^record.(.*)')[1]}
                          key={filterItemKey}
                          value={orItem[filterKey]['$like']}
                          onBlur={() => this.handleCloseFilterValue(filterItemKey)}
                          onChange={this.handleChangeOrFilterValue(index, orIndex, filterKey)}
                        />
                      )}
                  </span>
                );
              });
            } else {
              const filterKey = Object.keys(item)[0];
              const suffix =`${index}-0`;
              const filterItemKey = `${filterKey}${suffix}`;

              container = (
                <>
                  <Chip label='and' color='primary' />
                  {filterStates[filterItemKey]
                    ? (
                      <Chip
                        label={`${filterKey.match('^record.(.*)')[1]} like '${item[filterKey]['$like']}'`}
                        onClick={() => this.handleOpenFilterValue(filterItemKey)}
                        onDelete={() => this.handleDeleteAndFilter(index)}
                      />
                    )
                    : (
                      <TextField
                        autoFocus
                        variant='outlined'
                        label={filterKey.match('^record.(.*)')[1]}
                        key={filterItemKey}
                        value={item[filterKey]['$like']}
                        onBlur={() => this.handleCloseFilterValue(filterItemKey)}
                        onChange={this.handleChangeAndFilterValue(index, filterKey)}
                      />
                    )}
                </>
              );
            }

            return (
              <div key={index}>
                {container}
                <span>
                  <IconButton
                    size='small'
                    color='primary'
                    aria-label='Add'
                    onClick={() => this.handleAddOrFilter(index)}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </div>
            );
          })}
          <IconButton
            size='small'
            color='primary'
            aria-label='Add'
            onClick={this.handleAddAndFilter}
          >
            <AddIcon />
          </IconButton>
        </div>
        <div className={styles.checkboxes}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasVerifiedFilter}
                onChange={this.handleFilterCheckboxChange(true)}
                color='primary'
              />
            )}
            label='verified'
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasInvalidFilter}
                onChange={this.handleFilterCheckboxChange(false)}
                color='secondary'
              />
            )}
            label='invalid'
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasNullFilter}
                onChange={this.handleFilterCheckboxChange('NULL')}
                color='secondary'
              />
            )}
            label='notValid'
          />
        </div>

        <div className={styles.buttons}>
          <Button
            aria-label='Refresh'
            onClick={this.handleRefresh}
          >
            Refresh
            <RefreshIcon />
          </Button>
          <Button
            aria-label='Delete'
            onClick={() => removeAnalyticsEvents(gameId, provider, tpdid)}
          >
            Clear
            <DeleteIcon />
          </Button>
        </div>
      </form>
    );

    return (
      <Container maxWidth={false}>
        <HeaderWithControls
          titleChildren={<Typography variant='h1'>Analytics Events Viewer</Typography>}
        />
        {userInputBox}
        {testDeviceSelectDialog}
        {filterSelectDialog}
        <AnalyticsEventListView
          analyticsEvents={analyticsEvents}
          totalCount={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Container>
    );
  }
}

const invalidFieldPropType = PropTypes.shape({
  field_name: PropTypes.string.isRequired,
});

const analyticsEventPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    event_name: PropTypes.string,
    state_name: PropTypes.string,
    evtname: PropTypes.string,
  }).isRequired,
  verified: PropTypes.bool,
  invalidFields: PropTypes.arrayOf(invalidFieldPropType).isRequired,
  eventDate: PropTypes.number.isRequired,
});

AnalyticsEvent.propTypes = {
  analyticsEvents: PropTypes.arrayOf(analyticsEventPropType).isRequired,
  totalCount: PropTypes.number.isRequired,
  testDevices: PropTypes.arrayOf(PropTypes.string),
  analyticsGames: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeAnalyticsEvents: PropTypes.func.isRequired,
  getAllAnalyticsGames: PropTypes.func.isRequired,
  getAnalyticsEvents: PropTypes.func.isRequired,
  getMostRecentTestDevice: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    error,
    analyticsEvents,
    analyticsGames,
    totalCount,
    testDevices,
  } = state.analyticsEvent;

  return {
    error,
    analyticsEvents,
    totalCount,
    testDevices,
    analyticsGames,
  };
};

const mapDispatchToProps = {
  getAnalyticsEvents,
  getMostRecentTestDevice,
  removeAnalyticsEvents,
  getAllAnalyticsGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsEvent);
