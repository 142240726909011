export const TOGGLE_PAGE_NOTIFICATION = 'TOGGLE_PAGE_NOTIFICATION';
export const GET_DELETE_REQUESTS_START = 'GET_DELETE_REQUESTS_START';
export const GET_DELETE_REQUESTS_SUCCESS = 'GET_DELETE_REQUESTS_SUCCESS';
export const GET_DELETE_REQUESTS_FAIL = 'GET_DELETE_REQUESTS_FAIL';

export const GET_ADMIN_PROJECTS_START = 'GET_ADMIN_PROJECTS_START';
export const GET_ADMIN_PROJECTS_SUCCESS = 'GET_ADMIN_PROJECTS_SUCCESS';
export const GET_ADMIN_PROJECTS_FAIL = 'GET_ADMIN_PROJECTS_FAIL';

export const GET_ALL_FONTS_SUCCESS = 'GET_ALL_FONTS_SUCCESS';
export const GET_ALL_GAMES_SUCCESS = 'GET_ALL_GAMES_SUCCESS';
export const ADD_FONT_FACE = 'ADD_FONT_FACE';

export const SET_SELECTED_GAME = 'SET_SELECTED_GAME';

export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

export const ADMIN_PROJECTS_LOADING = 'ADMIN_PROJECTS_LOADING';
export const ADMIN_DELETE_REQUESTS_LOADING = 'ADMIN_DELETE_REQUESTS_LOADING';

export const CREATIVE_TOOLS_ADMIN_PANEL_ADD_MESSAGE = 'CREATIVE_TOOLS_ADMIN_PANEL_ADD_MESSAGE';
export const CREATIVE_TOOLS_ADMIN_PANEL_REMOVE_MESSAGE = 'CREATIVE_TOOLS_ADMIN_PANEL_REMOVE_MESSAGE';

// Ad Create - networks and sizes actions
export const GET_SOCIAL_NETWORKS_AD_CREATE_START = 'GET_SOCIAL_NETWORKS_AD_CREATE_START';
export const GET_SOCIAL_NETWORKS_AD_CREATE_SUCCESS = 'GET_SOCIAL_NETWORKS_AD_CREATE_SUCCESS';
export const GET_SOCIAL_NETWORKS_AD_CREATE_FAIL = 'GET_SOCIAL_NETWORKS_AD_CREATE_FAIL';

export const SOCIAL_NETWORK_ACTION_AD_CREATE_START = 'SOCIAL_NETWORK_ACTION_AD_CREATE_START';
export const SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS = 'SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS';
export const SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL = 'SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL';

export const SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_START = 'SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_START';
export const SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS = 'SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_SUCCESS';
export const SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL = 'SIZE_OF_SOCIAL_NETWORK_ACTION_AD_CREATE_FAIL';

export const OPEN_SOCIAL_NETWORK_AD_CREATE = 'OPEN_SOCIAL_NETWORK_AD_CREATE';
export const CLOSE_SOCIAL_NETWORK_AD_CREATE = 'CLOSE_SOCIAL_NETWORK_AD_CREATE';

// Ad Create - button templates
export const GET_BUTTON_TEMPLATES_AD_CREATE_START = 'GET_BUTTON_TEMPLATES_AD_CREATE_START';
export const GET_BUTTON_TEMPLATES_AD_CREATE_SUCCESS = 'GET_BUTTON_TEMPLATES_AD_CREATE_SUCCESS';
export const GET_BUTTON_TEMPLATES_AD_CREATE_FAIL = 'GET_BUTTON_TEMPLATES_AD_CREATE_FAIL';

export const BUTTON_TEMPLATE_ACTION_AD_CREATE_START = 'BUTTON_TEMPLATE_ACTION_AD_CREATE_START';
export const BUTTON_TEMPLATE_ACTION_AD_CREATE_SUCCESS = 'BUTTON_TEMPLATE_ACTION_AD_CREATE_SUCCESS';
export const BUTTON_TEMPLATE_ACTION_AD_CREATE_FAIL = 'BUTTON_TEMPLATE_ACTION_AD_CREATE_FAIL';

export const SET_CURRENT_BUTTON_OBJECT_AD_CREATE = 'SET_CURRENT_BUTTON_OBJECT_AD_CREATE';

// Ad Create - text templates
export const GET_TEXT_TEMPLATES_AD_CREATE_START = 'GET_TEXT_TEMPLATES_AD_CREATE_START';
export const GET_TEXT_TEMPLATES_AD_CREATE_SUCCESS = 'GET_TEXT_TEMPLATES_AD_CREATE_SUCCESS';
export const GET_TEXT_TEMPLATES_AD_CREATE_FAIL = 'GET_TEXT_TEMPLATES_AD_CREATE_FAIL';

export const TEXT_TEMPLATE_ACTION_AD_CREATE_START = 'TEXT_TEMPLATE_ACTION_AD_CREATE_START';
export const TEXT_TEMPLATE_ACTION_AD_CREATE_SUCCESS = 'TEXT_TEMPLATE_ACTION_AD_CREATE_SUCCESS';
export const TEXT_TEMPLATE_ACTION_AD_CREATE_FAIL = 'TEXT_TEMPLATE_ACTION_AD_CREATE_FAIL';

export const SET_CURRENT_TEXT_OBJECT_AD_CREATE = 'SET_CURRENT_TEXT_OBJECT_AD_CREATE';