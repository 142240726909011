import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SearchInput from '../../UIComponents/SearchInput';
import ListItemWithCheckbox from '../../UIComponents/ListItemWithCheckbox';
import NoSearchResults from '../../UIComponents/NoSearchResults';
import { ASSET_ATTRIBUTE_NAMES } from 'constants/Asset';


const AssetCatTypeConfig = ({ currentAssetTypeCat, optionsDisplayLimit, toggleAssetTypeCat }) => {

  const { attributes } = useSelector((state) => state.assets);

  const [typeCatOptions, setTypeCatOptions] = useState([]);
  const [searchValueTypeCat, setSearchValueTypeCat] = useState('');

  const filteredTypeCatOptions = useMemo(
    () =>
      typeCatOptions.filter((typeCatOption) =>
        typeCatOption.value.toLowerCase().includes(searchValueTypeCat.toLowerCase())
      ),
    [typeCatOptions, searchValueTypeCat]
  );

  useEffect(() => {
    if (attributes) {
      const typeAttributes = attributes.find((attribute) => attribute.name === ASSET_ATTRIBUTE_NAMES.TYPE);

      setTypeCatOptions(typeAttributes?.option ?? []);
    }
  }, [attributes]);

  return (
    <>
      <SearchInput
        searchWord={searchValueTypeCat}
        handleSearch={setSearchValueTypeCat}
        withClear
        pink
      />

      {filteredTypeCatOptions.slice(0, optionsDisplayLimit || filteredTypeCatOptions.length).map((filteredTypeCatOption) => (
        <ListItemWithCheckbox
          key={filteredTypeCatOption.id}
          item={filteredTypeCatOption}
          nameToShow='value'
          checkedCondition={currentAssetTypeCat === filteredTypeCatOption.value}
          handleCheck={() => toggleAssetTypeCat(filteredTypeCatOption.value)}
          radio
          pink
        />
      ))}

      {!filteredTypeCatOptions.length &&
        <NoSearchResults height={68} />
      }
    </>
  );
};

AssetCatTypeConfig.propTypes = {
  currentAssetTypeCat: PropTypes.string,
  optionsDisplayLimit: PropTypes.number,
  toggleAssetTypeCat: PropTypes.func.isRequired,
};

export default AssetCatTypeConfig;
