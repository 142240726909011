import PropTypes from 'prop-types';

export const collectionOwnerPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  avatar: PropTypes.string,
});

const collectionMainFilePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  entityId: PropTypes.number.isRequired,
  collectionId: PropTypes.number.isRequired,
  parentId: PropTypes.number,
  name: PropTypes.string,
  data: PropTypes.object,
  createAt: PropTypes.string,
  isFolder: PropTypes.bool,
  smallPreviewUrl: PropTypes.string,
  mediumPreviewUrl: PropTypes.string,
  path: PropTypes.string,
  storage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string,
  }),
});

export const collectionPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  isPrivate: PropTypes.bool,
  createAt: PropTypes.string,
  file: collectionMainFilePropTypes,
  totalFiles: PropTypes.number,
  owner: collectionOwnerPropTypes,
});