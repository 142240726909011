import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class AnalyticsEventService {
  constructor() {
    this.analyticsEvent = FeathersClient.service(`${API_PREFIX}analytics-events`);
    this.analyticsGame = FeathersClient.service(`${API_PREFIX}analytics-games`);
  }

  getAllAnalyticsGames() {
    return this.analyticsGame.find();
  }

  getAnalyticsEvents(query) {
    return this.analyticsEvent.find({ query });
  }

  removeAnalyticEvents(query) {
    return this.analyticsEvent.remove(null, { query });
  }
}

export default new AnalyticsEventService();
