import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './CollectionInfo.scss';


const CollectionInfo = ({ createdAt, visibility, description}) => {

  const infoFields = useMemo(() => [
    {name: 'date created', value: createdAt},
    {name: 'visibility', value: visibility},
    {name: 'description', value: description},
  ], [createdAt, visibility, description]);

  return (
    <div className='collection-info'>
      <p className='collection-info__title'>
        Collection info
      </p>

      <div className='collection-info__content'>
        {infoFields.map((infoField) => (
          <div
            key={infoField.name}
            className='collection-info-section'
          >
            <p className='collection-info-section__title'>{infoField.name}</p>

            {infoField.name === 'description' && !infoField?.value ?
              <p className='collection-info-section__no-data'>no data</p>:
              <ul role='list' className='collection-info-section__list'>
                <li
                  className={classnames('collection-info-section__list-item', {
                    'regular': infoField.name === 'description',
                  })}
                >
                  {infoField?.value}
                </li>
              </ul>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

CollectionInfo.propTypes = {
  createdAt: PropTypes.string.isRequired,
  visibility: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default CollectionInfo;
