import { useState, useEffect } from 'react';


const useSpeed = (API) => {

  const [averageSpeed, setAverageSpeed] = useState(null);

  useEffect(() => {
    check();
    const timer = setInterval(check, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const check = () => {
    const data = getRandomString(1);
    let startTime = 0;
    let speed = 0;

    API({data})
      .finally(() => {
        speed = Math.round(1024 / ((new Date() - startTime) / 1000));

        !averageSpeed ?
          setAverageSpeed(speed) :
          setAverageSpeed(Math.round((averageSpeed + speed) / 2));
      });

    startTime = new Date();
  };

  const getRandomString = (sizeInMb) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;\':,./<>?';
    const iterations = sizeInMb * 1024 * 1024;
    let result = '';

    for (let index = 0; index < iterations; index++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return result;
  };

  return averageSpeed;
};

export default useSpeed;
