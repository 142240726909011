import colors from '../colors.module.scss';

export const MuiCheckbox = {
  root: {
    border: '1px solid',
    borderColor: colors.lightGrey,
    borderRadius: 0,
    width: '32px',
    height: '32px',
    backgroundColor: 'white',
    '& .MuiIconButton-label': {
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      backgroundColor: 'white',
    },
  },
};
