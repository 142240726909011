import { Record } from 'immutable';

import {
  GET_ANALYTICS_EVENTS_START,
  GET_ANALYTICS_EVENTS_FAIL,
  GET_ANALYTICS_EVENTS_SUCCESS,

  GET_MOST_RECENT_TEST_DEVICE_START,
  GET_MOST_RECENT_TEST_DEVICE_FAIL,
  GET_MOST_RECENT_TEST_DEVICE_SUCCESS,

  REMOVE_ANALYTICS_EVENTS_START,
  REMOVE_ANALYTICS_EVENTS_FAIL,
  REMOVE_ANALYTICS_EVENTS_SUCCESS,

  GET_ALL_ANALYTICS_GAMES_START,
  GET_ALL_ANALYTICS_GAMES_FAIL,
  GET_ALL_ANALYTICS_GAMES_SUCCESS,
} from './actionTypes';

const InitialState = Record({
  error: null,
  analyticsEvents: [],
  analyticsGames: [],
  isFetchingGameIds: false,
  isFetching: false,
  totalCount: 0,
  testDevices: [],
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case GET_ANALYTICS_EVENTS_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case GET_ANALYTICS_EVENTS_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          res,
        } = action;

        const analyticsEvents = res.data.map((item) => {
          let eventDate;

          if (item.provider === 'tpevents') {
            eventDate = item.record.event_time_server || item.record.state_time_server;
          } else {
            eventDate = item.record.time * 1000;
          }

          return {
            payload: {
              ...item.record,
              game_id: item.game_id,
              provider: item.provider,
              tpdid: item.tpdid,
            },
            id: item.id,
            verified: item.verified,
            invalidFields: item.invalidFields,
            eventDate,
          };
        });

        analyticsEvents.sort((a, b) => b.eventDate - a.eventDate);

        ctx.set('isFetching', false)
          .set('analyticsEvents', analyticsEvents)
          .set('totalCount', res.total);
      });
    }

    case GET_ANALYTICS_EVENTS_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    case GET_MOST_RECENT_TEST_DEVICE_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case GET_MOST_RECENT_TEST_DEVICE_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          res,
        } = action;
        const tpdids = res.data.map((item) => item.tpdid);
        const testDevices = [...new Set(tpdids)];
        ctx.set('isFetching', false)
          .set('testDevices', testDevices);
      });
    }

    case GET_MOST_RECENT_TEST_DEVICE_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    case REMOVE_ANALYTICS_EVENTS_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case REMOVE_ANALYTICS_EVENTS_SUCCESS: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', false)
          .set('error', null)
          .set('analyticsEvents', [])
          .set('totalCount', 0);
      });
    }

    case REMOVE_ANALYTICS_EVENTS_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    case GET_ALL_ANALYTICS_GAMES_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetchingGameIds', true)
          .set('error', null);
      });
    }

    case GET_ALL_ANALYTICS_GAMES_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetchingGameIds', false)
          .set('error', error);
      });
    }

    case GET_ALL_ANALYTICS_GAMES_SUCCESS: {
      const {
        gameIds,
      } = action;
      return state.withMutations((ctx) => {
        ctx.set('isFetchingGameIds', false)
          .set('error', null)
          .set('analyticsGames', gameIds);
      });
    }

    default: {
      return state;
    }
  }
}
