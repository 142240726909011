/**
 * @param {number} id
 * @param {string} jiraFormat
 * @param {boolean} checked
 */
class FormatInFilterModel {
  constructor(data, checkedFormats) {
    this.id = data.id;
    this.jiraFormat = data.jiraFormat;
    this.checked = checkedFormats.some((id) => id === data.id);
  }
}

export default FormatInFilterModel;
