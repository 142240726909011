import { v4 as uuidv4 } from 'uuid';
import { secondsToJiraHoursAndMinutes } from '../../creativeDashboardHelpers';

/**
 * @param {string} id
 * @param {string[]} title
 * @param {string[]} games
 * @param {number} jiraTickets
 * @param {string[]} departments
 * @param {string[]} creativeFormats
 * @param {string[]} projectWorkflows
 * @param {number} actualHours
 * @param {number} estimatedHours
 * @param {number} podCapacity
 * @param {number} actualHours
 * @param {number} averageHours
 */
class DepartmentWorkloadModel {
  constructor(data) {
    this.id = uuidv4();
    this.title = data.title;
    this.games = data.games ? [...new Set(data.games)] : [];
    this.jiraTickets = data.tickets;
    this.departments = data.departments ?? [];
    this.creativeFormats = data.formats ?? [];
    this.issueTypes = data.issueTypes ?? [];
    this.projectWorkflows = data.projectWorkflows ?? [];
    this.actualHours = secondsToJiraHoursAndMinutes(data.timeSpentSecondsAmount);
    this.estimatedHours = secondsToJiraHoursAndMinutes(data.originalEstimateSecondsAmount);
    this.podCapacity = data.podCapacity ? data.podCapacity + 'H' : '';
    this.averageHours = secondsToJiraHoursAndMinutes(data.avgTrackedIssueHours);
  }
}

export default DepartmentWorkloadModel;