import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import UploadPathSelectionWrapper from '../UploadPathSelectionWrapper';
import CustomSelect from '../../UIComponents/CustomSelect';
import { getGames } from '../../Game/Game.redux/actions';
import { getStorageData } from '../../Assets/Assets.redux/Actions/assetActions';
import { UPLOAD_PLACE } from '../assetUploaderConstants';
import {
  ASSET_CATEGORIES_FOR_GAME_ASSETS,
  ASSET_CATEGORIES_KEYS,
  ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY,
} from 'constants/Asset';
import { GENERAL_GAME_FOLDERS_NAMES } from 'constants/Game';
import { ROOT_DIR_PATH } from '../../MainSearch/mainSearchConstants';
import { filterGamesForExternalUser } from '../assetUploaderHelpers';
import { assetToUploadPropTypes } from '../Types';


const UploadPathSelectionExternalUser = ({
  assetsToUpload,
  closeAssetUploader,
  setAssetsClassification,
  setUploadInfo,
  setUploadPlace,
}) => {

  const dispatch = useDispatch();

  const {
    games,
    isFetching: isFetchingGames,
    error: gamesFetchingError,
  } = useSelector((state) => state.game);
  const { storages } = useSelector((state) => state.gamesView);
  const { isFetching: isFetchingGameStorageData } = useSelector((state) => state.assets);

  const [gameOptions, setGameOptions] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedGamePath, setSelectedGamePath] = useState({
    firstLevel: null,
    secondLevel: null,
    thirdLevel: null,
  });
  const [gameStorage, setGameStorage] = useState(null);

  useEffect(() => {
    dispatch(getGames());
  }, []);

  useEffect(() => {
    if (games?.length) {
      setGameOptions(filterGamesForExternalUser(games));
    }
  }, [games]);

  useEffect(() => {
    if (selectedGame) {
      dispatch(getStorageData(selectedGame.gameAssetsStorageId, ''));
    }

    setSelectedGamePath({
      firstLevel: null,
      secondLevel: null,
      thirdLevel: null,
    });
  }, [selectedGame]);

  useEffect(() => {
    if (selectedGame && storages[selectedGame.gameAssetsStorageId]) {
      setGameStorage(storages[selectedGame.gameAssetsStorageId]);
    }
  }, [storages, selectedGame]);

  const checkIfCanGoToNextStep = () => {
    const { secondLevel, thirdLevel } = selectedGamePath;

    if (
      secondLevel &&
      !ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY[secondLevel.name]?.length
    ) {
      return true;
    }

    return !!thirdLevel;
  };

  const handleNextStep = () => {
    const folderToUpload =
      selectedGamePath.thirdLevel ||
      selectedGamePath.secondLevel ||
      selectedGamePath.firstLevel;

    setUploadInfo({
      isCreative: false,
      gameId: selectedGame.id,
      creativityId: null,
      storageId: folderToUpload.storageId,
      creativeTabName: '',
      dirPath: folderToUpload.path.substring(1),
      gameRootFolder: GENERAL_GAME_FOLDERS_NAMES.DEVELOPER_ASSETS,
    });

    setAssetsClassification({
      [ASSET_CATEGORIES_KEYS.CATEGORY]: ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS,
      [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: null,
      [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: null,
      [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: null,
    });

    setUploadPlace(UPLOAD_PLACE.GAME);
  };
  const getStorageFoldersByPath = (path, folderName) => {
    if (!gameStorage || !gameStorage[path]) {
      return [];
    }

    return gameStorage[path].filter((asset) =>
      folderName ? asset.isFolder && asset.name === folderName : asset.isFolder
    );
  };

  return (
    <UploadPathSelectionWrapper
      assetsToUpload={assetsToUpload}
      closeAssetUploader={closeAssetUploader}
      isNextStepDisabled={!checkIfCanGoToNextStep()}
      handleNextStep={handleNextStep}
    >
      <CustomSelect
        label='Select Game folder'
        data={gameOptions}
        value={selectedGame}
        handleSelect={(game) => setSelectedGame(game)}
        disabled={isFetchingGames || !!gamesFetchingError}
        withSearch
        pink
      />
      <CustomSelect
        label='Select folder level 1'
        data={getStorageFoldersByPath(ROOT_DIR_PATH, GENERAL_GAME_FOLDERS_NAMES.DEVELOPER_ASSETS)}
        value={selectedGamePath.firstLevel}
        handleSelect={(gameFolder) => {
          if (gameFolder.id !== selectedGamePath.firstLevel?.id) {
            setSelectedGamePath({
              firstLevel: gameFolder,
              secondLevel: null,
              thirdLevel: null,
            });
          }

          if (!gameStorage[gameFolder?.name]) {
            dispatch(getStorageData(gameFolder.storageId, gameFolder.name));
          }
        }}
        disabled={!selectedGame || (isFetchingGameStorageData && !selectedGamePath.firstLevel) }
        withSearch
        pink
      />
      <CustomSelect
        label='Select folder level 2'
        data={getStorageFoldersByPath(selectedGamePath.firstLevel?.name)}
        value={selectedGamePath.secondLevel}
        handleSelect={(gameFolder) => {
          if (gameFolder.id !== selectedGamePath.secondLevel?.id) {
            setSelectedGamePath({
              ...selectedGamePath,
              secondLevel: gameFolder,
              thirdLevel: null,
            });
          }

          if (!gameStorage[selectedGamePath.firstLevel.name + '/' + gameFolder.name]) {
            dispatch(getStorageData(gameFolder.storageId, gameFolder.path.substring(1)));
          }
        }}
        disabled={!selectedGamePath.firstLevel || (isFetchingGameStorageData && !selectedGamePath.secondLevel)}
        withSearch
        pink
      />
      <CustomSelect
        label='Select folder level 3'
        data={getStorageFoldersByPath(selectedGamePath.firstLevel?.name + '/' + selectedGamePath.secondLevel?.name)}
        value={selectedGamePath.thirdLevel}
        handleSelect={(gameFolder) => {
          setSelectedGamePath({
            ...selectedGamePath,
            thirdLevel: gameFolder,
          });
        }}
        disabled={!selectedGamePath.secondLevel || (isFetchingGameStorageData && !selectedGamePath.thirdLevel)}
        withSearch
        pink
      />
    </UploadPathSelectionWrapper>
  );
};

UploadPathSelectionExternalUser.propTypes = {
  assetsToUpload: PropTypes.arrayOf(assetToUploadPropTypes),
  closeAssetUploader: PropTypes.func.isRequired,
  setAssetsClassification: PropTypes.func.isRequired,
  setUploadInfo: PropTypes.func.isRequired,
  setUploadPlace: PropTypes.func.isRequired,
};

export default UploadPathSelectionExternalUser;
