
export const formatsLimit = 10;

export const maxLengthSmallTextarea = 50;
export const maxLengthBigTextarea = 225;

export const splitTimeBy = ':';

export const idForEmptyFormat = 'undefined-format-id';

export const headerTitlesOrder = [
  'EXAMPLE',
  'CATEGORY',
  'FORMAT',
  'TOTAL_TIME',
  'DESCRIPTION',
  'GENERAL_INFO',
];

export const formatFieldsNames = {
  JIRA_FORMAT: 'jiraFormat',
  DESCRIPTION: 'description',
  GENERAL_INFO: 'generalInfo',
  TIME_TOTAL: 'timeTotal',
};

export const allowedFileExtension = '.jpg,.jpeg,.png,.tiff,.tif,.gif,video/mp4,video/x-m4v,video/*';
