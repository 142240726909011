import {
  PRESET_COLLECTION_ID,

  GET_COLLECTION_START,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_FAIL,

  GET_COLLECTION_ASSETS_START,
  GET_COLLECTION_ASSETS_SUCCESS,
  GET_COLLECTION_ASSETS_FAIL,

  MANAGE_COLLECTION_ASSETS_START,
  MANAGE_COLLECTION_ASSETS_SUCCESS,
  MANAGE_COLLECTION_ASSETS_FAIL,
} from '../actionsTypes';
import Collections from 'services/Collections';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const presetCollectionIdAction = (collectionId) => (dispatch) => {
  dispatch({
    type: PRESET_COLLECTION_ID,
    payload: collectionId,
  });
};

export const getCollectionAction = (collectionId, collectionHash) => (dispatch) => {
  dispatch({
    type: GET_COLLECTION_START,
  });

  return Collections.getCollection(collectionId, collectionHash)
    .then((response) => {
      dispatch({
        type: GET_COLLECTION_SUCCESS,
        payload: response,
      });

      dispatch({
        type: GET_COLLECTION_ASSETS_SUCCESS,
        payload: response.files,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });
    });
};

export const createCollectionFolderAction = (collectionId, folderName) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_ASSETS_START,
  });

  return Collections.createCollectionFolder(collectionId, folderName)
    .then(() => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_SUCCESS,
      });

      dispatch(getCollectionAssetsAction({ collectionId }));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.folder.success.create.text,
        description: notifications.folder.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.folder.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const editCollectionFolderAction = (collectionId, folderId, newFolderName) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_ASSETS_START,
  });

  return Collections.updateCollectionFolder(collectionId, folderId, newFolderName)
    .then(() => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_SUCCESS,
      });

      dispatch(getCollectionAssetsAction({ collectionId }));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.folder.success.update.text,
        description: notifications.folder.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.folder.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const getCollectionAssetsAction = (params) => (dispatch) => {
  dispatch({
    type: GET_COLLECTION_ASSETS_START,
  });

  return Collections.getCollectionAssets(params)
    .then((response) => {
      dispatch({
        type: GET_COLLECTION_ASSETS_SUCCESS,
        payload: response,
      });

    })
    .catch((error) => {
      dispatch({
        type: GET_COLLECTION_ASSETS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.get_asset.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteCollectionAssetsAction = (collectionId, assetsIds, folderToUpdate) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_ASSETS_START,
  });

  return Collections.deleteCollectionAssets(collectionId, assetsIds)
    .then(() => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_SUCCESS,
      });

      dispatch(getCollectionAssetsAction({
        collectionId,
        parentId: folderToUpdate?.id,
        folderName: folderToUpdate?.name,
      }));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.delete.text,
        description: notifications.asset.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const moveCollectionAssetsAction = (collectionId, data, folderToUpdate) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_ASSETS_START,
  });

  return Collections.moveCollectionAssets(collectionId, data)
    .then(() => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_SUCCESS,
      });

      dispatch(getCollectionAssetsAction({
        collectionId,
        parentId: folderToUpdate?.id,
        folderName: folderToUpdate?.name,
      }));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.move.text,
        description: notifications.asset.success.move.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_ASSETS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.move.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
