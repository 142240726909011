export const CREATIVE_GENERAL_TABS_NAMES = {
  PROOF: 'PROOF',
  PRODUCTION: 'PRODUCTION',
  FINAL: 'FINAL',
};

export const CREATIVE_SECTIONS = {
  GENERAL_INFO: 'general information',
  HISTORY: 'history',
  FEEDBACK: 'feedback',
  STORAGE: 'storage',
};

export const CREATIVE_STATUS = {
  REJECTED: 'Rejected',
};
