import AnalyticsEventService from '../../../services/AnalyticsEvent';
import {
  GET_ANALYTICS_EVENTS_START,
  GET_ANALYTICS_EVENTS_FAIL,
  GET_ANALYTICS_EVENTS_SUCCESS,

  GET_MOST_RECENT_TEST_DEVICE_START,
  GET_MOST_RECENT_TEST_DEVICE_FAIL,
  GET_MOST_RECENT_TEST_DEVICE_SUCCESS,

  REMOVE_ANALYTICS_EVENTS_START,
  REMOVE_ANALYTICS_EVENTS_FAIL,
  REMOVE_ANALYTICS_EVENTS_SUCCESS,

  GET_ALL_ANALYTICS_GAMES_START,
  GET_ALL_ANALYTICS_GAMES_FAIL,
  GET_ALL_ANALYTICS_GAMES_SUCCESS,
} from './actionTypes';

export function getAllAnalyticsGames() {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_ANALYTICS_GAMES_START,
    });

    return AnalyticsEventService.getAllAnalyticsGames()
      .then((gameIds) => {
        if (!Array.isArray(gameIds)) {
          return Promise.reject(gameIds);
        }

        dispatch({
          type: GET_ALL_ANALYTICS_GAMES_SUCCESS,
          gameIds: gameIds,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_ANALYTICS_GAMES_FAIL,
        }, error.message);
      });
  };
}

export function getMostRecentTestDevice(gameId, provider) {
  return (dispatch) => {
    dispatch({
      type: GET_MOST_RECENT_TEST_DEVICE_START,
    });

    // get device from 1 hour ago
    const recentTimestamp = new Date().getTime() - 60 * 24 * 3600 * 1000;
    const dateStr = new Date(recentTimestamp);
    return AnalyticsEventService.getAnalyticsEvents({
      $distinct: 'tpdid',
      createdAt: {
        $gt: dateStr,
      },
      game_id: gameId,
      provider,
    })
      .then((res) => {
        dispatch({
          type: GET_MOST_RECENT_TEST_DEVICE_SUCCESS,
          res,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_MOST_RECENT_TEST_DEVICE_FAIL,
        }, error.message);
      });
  };
}

export function removeAnalyticsEvents(gameId, provider, tpdid) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ANALYTICS_EVENTS_START,
    });

    return AnalyticsEventService.removeAnalyticEvents({
      game_id: gameId,
      tpdid,
      provider,
    })
      .then((res) => {
        dispatch({
          type: REMOVE_ANALYTICS_EVENTS_SUCCESS,
          res,
        });
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_ANALYTICS_EVENTS_FAIL,
        }, error.message);
      });
  };
}

export function getAnalyticsEvents(query, filters) {
  const {
    limit,
    offset,
    gameId,
    provider,
    tpdid,
  } = query;

  let data  = {
    $limit: limit,
    $skip: offset,
    $sort: {
      ts: -1,
    },
    game_id: gameId,
    provider,
    tpdid,
  };

  if (filters) {
    const newFilters = {};
    const orFilters = filters['$or'];
    const andFilters = filters['$and'];
    if (orFilters.length > 0) {
      newFilters['$or'] = orFilters;
    }

    if (andFilters.length > 0) {
      newFilters['$and'] = andFilters;
    }

    if (Object.keys(newFilters).length > 0) {
      data = {
        ...newFilters,
        ...data,
      };
    }
  }

  return (dispatch) => {
    dispatch({
      type: GET_ANALYTICS_EVENTS_START,
    });
    return AnalyticsEventService.getAnalyticsEvents(data)
      .then((res) => {
        dispatch({
          type: GET_ANALYTICS_EVENTS_SUCCESS,
          res: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ANALYTICS_EVENTS_FAIL,
        }, error.message);
      });
  };
}
