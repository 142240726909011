import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import './NavigateBack.scss';


const NavigateBack = ({ route, text }) => {
  return (
    <div
      className={classnames('collections-navigate-back', {
        'notVisible': !route,
      })}
    >
      <Link to={`/${route}`} className='collections-navigate-back__link' >
        <ArrowBackIcon className='collections-navigate-back__icon' />
        <span className='collections-navigate-back__text'>{text}</span>
      </Link>
    </div>
  );
};

NavigateBack.defaultProps = {
  text: 'back',
};

NavigateBack.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string.isRequired,
};

export default NavigateBack;
