import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import './CustomCheckbox.scss';


const CustomCheckbox = ({checkedCondition, disabled, handleCheck, transparent}) => {
  return (
    <label
      className={classnames('custom-checkbox', {
        'active': checkedCondition,
        'disabled': disabled,
      })}
      onClick={(event) => event.stopPropagation()}
      onDoubleClick={(event) => event.stopPropagation()}
    >
      <input
        className='custom-checkbox__input'
        type='checkbox'
        onChange={(event) => handleCheck(event, !checkedCondition)}
      />
      {transparent ?
        <>
          {checkedCondition ?
            <CheckBoxIcon className='custom-checkbox__mui-icon' /> :
            <CheckBoxOutlineBlankIcon className='custom-checkbox__mui-icon' />
          }
        </> :
        <svg
          className={classnames('custom-checkbox__icon', {
            'active': checkedCondition,
          })}
          aria-hidden='true'
          viewBox='0 0 15 11'
          fill='none'
        >
          <path
            d='M1 4.5L5 9L14 1'
            strokeWidth='2'
          />
        </svg>
      }
    </label>
  );
};

CustomCheckbox.propTypes = {
  checkedCondition: PropTypes.bool,
  disabled: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  transparent: PropTypes.bool,
};

export default CustomCheckbox;