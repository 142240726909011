import colors from '../colors.module.scss';

export const MuiFormLabel = {
  root: {
    display: 'block',
    marginBottom: '0.5rem',
    color: colors.darkNavy,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
