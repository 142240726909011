import AppSearch from 'services/AppSearch';
import { getGameAssetsFail } from 'components/Laboratory/VideoEditor/VideoEditor.redux/Actions/initActions';
import { ASSET_ATTRIBUTES } from 'constants/CreativeTool';
import { ASSET_NAMES_IN_REDUCER } from 'components/Laboratory/VideoEditor/VideoEditor.redux/reducer';
import {
  SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS,
  SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS,
  LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
  LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS,
  LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS,
  LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS,
  SQUARE_4x5_FRAMES_ANIMATED_SUCCESS,
  SQUARE_4x5_FRAMES_STATIC_SUCCESS,
} from '../actionTypes';


/**
 * SQUARE_LANDSCAPE_FRAMES
 */
const getGamesSquareLandscapeFramesAnimatedSuccess = (frames) => {
  return {
    type: SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS,
    payload: frames,
  };
};

export const getGamesSquareLandscapeFramesAnimated = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.LANDSCAPE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquareLandscapeFramesAnimatedSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.LANDSCAPE));
      throw error;
    });
};


const getGamesSquareLandscapeFramesStaticSuccess = (frames) => {
  return {
    type: SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS,
    payload: frames,
  };
};

export const getGamesSquareLandscapeFramesStatic = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.LANDSCAPE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquareLandscapeFramesStaticSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.LANDSCAPE));
      throw error;
    });
};

/**
 * LANDSCAPE_PORTRAIT_FRAMES
 */
const getGamesLandscapePortraitFramesAnimatedSuccess = (frames) => {
  return {
    type: LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
    payload: frames,
  };
};

export const getGamesLandscapePortraitFramesAnimated = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.ANIMATED.LANDSCAPE.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLandscapePortraitFramesAnimatedSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.ANIMATED.LANDSCAPE.PORTRAIT));
      throw error;
    });
};

const getGamesLandscapePortraitFramesStaticSuccess = (frames) => {
  return {
    type: LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS,
    payload: frames,
  };
};

export const getGamesLandscapePortraitFramesStatic = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.STATIC.LANDSCAPE.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLandscapePortraitFramesStaticSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.STATIC.LANDSCAPE.PORTRAIT));
      throw error;
    });
};


/**
 * LANDSCAPE_4x5_FRAMES
 */
const getGamesLandscape4x5FramesAnimatedSuccess = (frames) => {
  return {
    type: LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS,
    payload: frames,
  };
};

export const getGamesLandscape4x5FramesAnimated = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.ANIMATED.LANDSCAPE.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLandscape4x5FramesAnimatedSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.ANIMATED.LANDSCAPE.FOUR_ON_FIVE));
      throw error;
    });
};


const getGamesLandscape4x5FramesStaticSuccess = (frames) => {
  return {
    type: LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS,
    payload: frames,
  };
};

export const getGamesLandscape4x5FramesStatic = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.STATIC.LANDSCAPE.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLandscape4x5FramesStaticSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.STATIC.LANDSCAPE.FOUR_ON_FIVE));
      throw error;
    });
};


/**
 * SQUARE_4x5_FRAMES
 */
const getGamesSquare4x5FramesAnimatedSuccess = (frames) => {
  return {
    type: SQUARE_4x5_FRAMES_ANIMATED_SUCCESS,
    payload: frames,
  };
};

export const getGamesSquare4x5FramesAnimated = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquare4x5FramesAnimatedSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.FOUR_ON_FIVE, ASSET_NAMES_IN_REDUCER.FRAMES_FOUR_ON_FIVE_ANIMATED));
      throw error;
    });
};


const getGamesSquare4x5FramesStaticSuccess = (frames) => {
  return {
    type: SQUARE_4x5_FRAMES_STATIC_SUCCESS,
    payload: frames,
  };
};

export const getGamesSquare4x5FramesStatic = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquare4x5FramesStaticSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.FOUR_ON_FIVE, ASSET_NAMES_IN_REDUCER.FRAMES_FOUR_ON_FIVE_STATIC));
      throw error;
    });
};
