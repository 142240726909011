import React from 'react';
import PropTypes from 'prop-types';
import CreativeStorageGridView from '../CreativeStorageGridView';
import CreativeStorageListView from '../CreativeStorageListView';
import { VIEW_GRID } from 'constants/General';


const CreativeStorageResults = ({
  viewType,
  gameName,
  folders,
  files,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  assetToOpenId,
  canManageAssets,
  handleMoveAssets,
  handleGameClick,
  openAssetInfo,
}) => {
  return (
    <>
      {viewType === VIEW_GRID ?
        <CreativeStorageGridView
          gameName={gameName}
          folders={folders}
          files={files}
          selectedAssetIdsSet={selectedAssetIdsSet}
          toggleAssetSelection={toggleAssetSelection}
          navigateToFolder={navigateToFolder}
          canManageAssets={canManageAssets}
          handleMoveAssets={handleMoveAssets}
          handleGameClick={handleGameClick}
          openAssetInfo={openAssetInfo}
          assetToOpenId={assetToOpenId}
        /> :
        <CreativeStorageListView
          folders={folders}
          files={files}
          selectedAssetIdsSet={selectedAssetIdsSet}
          toggleAssetSelection={toggleAssetSelection}
          navigateToFolder={navigateToFolder}
          canManageAssets={canManageAssets}
          handleMoveAssets={handleMoveAssets}
          openAssetInfo={openAssetInfo}
          assetToOpenId={assetToOpenId}
        />
      }
    </>
  );
};

CreativeStorageResults.propTypes = {
  viewType: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  canManageAssets: PropTypes.bool,
  handleMoveAssets: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
  assetToOpenId: PropTypes.number,
};

export default CreativeStorageResults;
