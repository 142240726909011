export const PROJECT_FORMAT = {
  VIDEO_CREATE: 'video_project',
  VIDEO_RESIZE: 'video_resize',
  AD_CREATE: 'ad_create',
};

export const VIDEO_CREATE_ASSETS_TYPES = {
  TITLE_CARD: 'titleCard',
  END_CARD: 'endCard',
  LOWER_THIRD: 'lowerThird',
  LOWER_THIRDS: 'lowerThirds',
  MAIN_VIDEOS_ARRAY: 'mainVideos',
  IMAGE_OVERLAY: 'imageOverlay',
  COLORS: 'colors',
  FONTS: 'fonts',
};

export const PROJECT_RENDER_STATUS = {
  COMPLETED: 'completed',
  INIT: 'without state',
  ACTIVE: 'active',
  WAITING: 'waiting',
  FAILED: 'failed',
};

export const VIDEO_EXTENSIONS = {
  LANDSCAPE: 'landscape',
  SQUARE: 'square',
  PORTRAIT: 'portrait',
  FOUR_ON_FIVE: 'fourOnFive',
  FOUR_ON_FIVE_FRAME: 'four_on_five',
  OTHER: 'other',
};

export const VIDEO_EXTENSIONS_ARRAY = [
  'landscape',
  'square',
  'portrait',
  'fourOnFive',
];

export const FRAME_TYPES = {
  BLUR_FRAME: 'blur_frame',
  STATIC_FRAME: 'static_frame',
  ANIM_FRAME: 'anim_frame',
  RANDOM_FRAME: 'random_frame',
};

export const VIDEO_RESIZE_EXTENSIONS = {
  SQUARE_CONTAINS_LANDSCAPE_BLUR: 'square_contains_landscape_blur',
  SQUARE_CONTAINS_LANDSCAPE_ANIM: 'square_contains_landscape_anim',
  SQUARE_CONTAINS_LANDSCAPE_STATIC: 'square_contains_landscape_static',
  FOUR_ON_FIVE_CONTAINS_LANDSCAPE_BLUR: 'four_on_five_contains_landscape_blur',
  FOUR_ON_FIVE_CONTAINS_LANDSCAPE_ANIM: 'four_on_five_contains_landscape_anim',
  FOUR_ON_FIVE_CONTAINS_LANDSCAPE_STATIC: 'four_on_five_contains_landscape_static',
  BLUR_PORTRAIT_CONTAINS_LANDSCAPE: 'blur_portrait_contains_landscape',
  ANIM_PORTRAIT_CONTAINS_LANDSCAPE: 'anim_portrait_contains_landscape',
  STATIC_PORTRAIT_CONTAINS_LANDSCAPE: 'static_portrait_contains_landscape',
  LANDSCAPE_CONTAINS_SQUARE_BLUR: 'landscape_contains_square_blur',
  LANDSCAPE_CONTAINS_SQUARE_ANIM: 'landscape_contains_square_anim',
  LANDSCAPE_CONTAINS_SQUARE_STATIC: 'landscape_contains_square_static',
  FOUR_ON_FIVE_CONTAINS_SQUARE_BLUR: 'four_on_five_contains_square_blur',
  FOUR_ON_FIVE_CONTAINS_SQUARE_ANIM: 'four_on_five_contains_square_anim',
  FOUR_ON_FIVE_CONTAINS_SQUARE_STATIC: 'four_on_five_contains_square_static',
  BLUR_PORTRAIT_CONTAINS_SQUARE: 'blur_portrait_contains_square',
  ANIM_PORTRAIT_CONTAINS_SQUARE: 'anim_portrait_contains_square',
  STATIC_PORTRAIT_CONTAINS_SQUARE: 'static_portrait_contains_square',
};

export const VIDEO_CREATE_MODS = {
  EDITOR: 'editor',
  RANDOMIZER: 'randomizer',
};

export const VIDEO_RESIZE_OBJECT_FRAME = {
  SQUARE: 'frameSquare',
  LANDSCAPE: 'frameLandscape',
  PORTRAIT: 'framePortrait',
  FOUR_ON_FIVE: 'frame4x5',
};

export const LOWER_THIRD_MAX_TEXT_SIZE = {
  RENDER_SQUARE: 60,
  RENDER_LANDSCAPE: 80,
};

export const ANIMATION_TYPES = {
  NONE: 'none',
  SLIDE_UP: 'slide_up',
  SLIDE_RIGHT: 'slide_right',
  SLIDE_LEFT: 'slide_left',
  SLIDE_LEFT_ACROSS: 'slide_left_across',
  SLIDE_RIGHT_ACROSS: 'slide_right_across',
  MOVE_LEFT_ACROSS: 'move_left_across',
  MOVE_RIGHT_ACROSS: 'move_right_across',
  FADE: 'fade',
};

export const DEFAULT_OVERLAY_ANIMATION = {
  id: 9,
  name: 'Fade in',
  value: ANIMATION_TYPES.FADE,
};

export const OVERLAY_ANIMATIONS = [
  {id: 1, name: 'none', value: ANIMATION_TYPES.NONE},
  {id: 2, name: 'Slide up', value: ANIMATION_TYPES.SLIDE_UP},
  {id: 3, name: 'Slide in right', value: ANIMATION_TYPES.SLIDE_RIGHT},
  {id: 4, name: 'Slide in left', value: ANIMATION_TYPES.SLIDE_LEFT},
  {id: 5, name: 'Slide right across', value: ANIMATION_TYPES.SLIDE_RIGHT_ACROSS},
  {id: 6, name: 'Slide left across', value: ANIMATION_TYPES.SLIDE_LEFT_ACROSS},
  {id: 7, name: 'Slide Right Stop', value: ANIMATION_TYPES.MOVE_RIGHT_ACROSS},
  {id: 8, name: 'Slide Left Stop', value: ANIMATION_TYPES.MOVE_LEFT_ACROSS},
  DEFAULT_OVERLAY_ANIMATION,
];

export const SCREEN_SIZE = {
  LANDSCAPE_WIDTH: 1920,
  LANDSCAPE_HEIGHT: 1080,
  FOUR_ON_FIVE_HEIGHT: 1350,
};

export const CACHE_MEDIA_FORMATS = {
  WEBM: 'webm',
  PNG: 'png',
  MP4: 'mg4',
};

export const IMAGE_OVERLAY_DEFAULT_SIZE = {
  WIDTH: 200,
  HEIGHT: 200,
};

export const TRANSITION_TYPES = {
  NONE: 'none',
  FADE: 'fade',
  PIXELIZE: 'pixelize',
  SMOOTHLEFT: 'smoothleft',
  SMOOTHUP: 'smoothup',
  CIRCLEOPEN: 'circleopen',
  HORZOPEN: 'horzopen',
  VERTOPEN: 'vertopen',
};

export const ANIMATION_DURATION = 500;

export const OVERLAY_TYPES = {
  HERO_OVERLAY: 'HERO_OVERLAY',
  LOWER_THIRDS_OVERLAY: 'LOWER_THIRDS_OVERLAY',
  EFFECT_OVERLAY: 'EFFECT_OVERLAY',
};

export const ASSET_ATTRIBUTES = {
  INTRO: 'intros',
  END_CARD: 'end_cards',
  MAIN_FOOTAGE: 'gameplay_footages',
  LOWER_THIRD: {
    LANDSCAPE: 'lower_thrids_Landscape',
    SQUARE: 'lower_thrids_square',
    PORTRAIT: 'lower_thrids_portrait',
    FOUR_ON_FIVE: 'lower_thrids_4x5',
  },
  EFFECT: {
    LANDSCAPE: 'Effect_Landscape',
    SQUARE: 'Effect_Square',
    PORTRAIT: 'Effect_Portrait',
    FOUR_ON_FIVE: 'Effect_4x5',
  },
  OVERLAY: 'Overlay',
  GAME_LOGO: 'Game_logo',
  AUDIO: 'audio_accompaniments',
  VOICE_OVER: 'VO',
  VOICE_OVER_END_CARD: 'VO_End_Card',
  FONT: 'fonts',
  LIVE_ACTION: 'Live_Action',
  FRAME: {
    STATIC: {
      SQUARE: {
        PORTRAIT: 'Square_Portrait_Static',
        LANDSCAPE: 'Square_Landscape_Static',
        FOUR_ON_FIVE: 'Square_4x5_Static',
      },
      LANDSCAPE: {
        PORTRAIT: 'Landscape_Portrait_Static',
        FOUR_ON_FIVE: 'Landscape_4x5_Static',
      },
    },
    ANIMATED: {
      SQUARE: {
        PORTRAIT: 'Square_Portrait_Animated',
        LANDSCAPE: 'Square_Landscape_Animated',
        FOUR_ON_FIVE: 'Square_4x5_Animated',
      },
      LANDSCAPE: {
        PORTRAIT: 'Landscape_Portrait_Animated',
        FOUR_ON_FIVE: 'Landscape_4x5_Animated',
      },
    },
  },
};

export const AD_CREATE_BANNER_ITEM_TYPES = {
  CONTAINER: 'container',
  TEXT: 'text',
  BUTTON: 'button',
  IMAGE: 'image',
};
