import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import UploadPathSelectionExternalUser from './UploadPathSelectionExternalUser';
import UploadPathSelectionJiraUser from './UploadPathSelectionJiraUser';
import AssetUploaderForm from './AssetUploaderForm';
import { UPLOAD_PLACE } from './assetUploaderConstants';
import {
  getAssetCategoryByJiraIssueType,
  getGameAssetsClassificationByPath,
} from './assetUploaderHelpers';
import { checkIfExternalUser } from '../General/Permissions/Permission';
import { assetToUploadPropTypes } from './Types';
import { ROOT_DIR_PATH } from '../MainSearch/mainSearchConstants';
import { ASSET_CATEGORIES_KEYS } from '../../constants/Asset';


const AssetUploader = ({ assetsToUpload, setAssetsToUpload}) => {

  const location = useLocation();
  const history = useHistory();
  const URLParams = new URLSearchParams(location.search);
  const parsedQuerySearch = queryString.parse(useLocation().search);
  const isUserExternal = checkIfExternalUser();

  const creativeRouteWithGameId = useRouteMatch('/creatives/:gameId/:creativeId');
  const creativeRouteWithoutGameId = useRouteMatch('/creatives/:creativeId');
  const creativeRouteMatch = creativeRouteWithGameId || creativeRouteWithoutGameId;

  const parsedGameId = parsedQuerySearch.gameId && parseInt(parsedQuerySearch.gameId, 10);
  const parsedCreativeId = creativeRouteMatch && parseInt(creativeRouteMatch.params.creativeId, 10);

  const { generalCreativeInfo } = useSelector((state) => state.creatives);
  const { externalPermission } = useSelector((state) => state.user.profile);

  const creativeTabs = generalCreativeInfo?.tabs;
  const creativeGameId = generalCreativeInfo?.game?.id;
  const issueType = generalCreativeInfo?.jiraIssue?.issueType;

  const { game } = useSelector((state) => state.creatives);

  const [uploadPlace, setUploadPlace] = useState('');
  const [uploadInfo,  setUploadInfo] = useState({
    isCreative: false,
    gameId: null,
    creativityId: null,
    storageId: null,
    creativeTabName: '',
    dirPath: '',
    gameRootFolder: '',
  });

  const [assetsClassification, setAssetsClassification] = useState({
    [ASSET_CATEGORIES_KEYS.CATEGORY]: null,
    [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: null,
    [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: null,
    [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: null,
  });

  useEffect(() => {
    const newAssetsToUpload = assetsToUpload.map((assetToUpload) => ({
      ...assetToUpload,
      ...assetsClassification,
    }));

    setAssetsToUpload(newAssetsToUpload);
  },[assetsClassification, assetsToUpload.length]);

  useEffect(() => {
    return history.listen(() => {
      closeAssetUploader();
    });
  },[history]);

  useEffect(() => {
    if (parsedCreativeId && parsedQuerySearch?.storageId) {
      const storageId = getStorageId();
      const creativeTabName = creativeTabs.find((tab) => tab.storageId === storageId)?.name;
      setUploadPlace(UPLOAD_PLACE.CREATIVE);

      setUploadInfo({
        isCreative: true,
        gameId: creativeGameId,
        creativityId: parsedCreativeId,
        storageId: storageId,
        creativeTabName: creativeTabName ?? '',
        dirPath: URLParams.get('dirPath'),
        gameRootFolder: '',
      });

      setAssetsClassification({
        [ASSET_CATEGORIES_KEYS.CATEGORY]: getAssetCategoryByJiraIssueType(issueType),
        [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: null,
        [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: null,
        [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: null,
      });
    } else if (parsedGameId) {
      const dirPath = URLParams.get('dirPath');
      const rootFolder = dirPath ? dirPath.split('/')[0] : '';
      setUploadPlace(UPLOAD_PLACE.GAME);

      setUploadInfo({
        isCreative: false,
        gameId: parsedGameId,
        creativityId: parsedCreativeId,
        storageId: getStorageId(game?.gameAssetsStorageId),
        creativeTabName: '',
        dirPath: dirPath === ROOT_DIR_PATH ? '' : dirPath,
        gameRootFolder: rootFolder,
      });

      setAssetsClassification(getGameAssetsClassificationByPath(dirPath));
    } else {
      setUploadPlace(UPLOAD_PLACE.GENERAL);
    }
  }, [parsedCreativeId, parsedGameId]);

  const canUserProceedUpload = () => {
    const {
      isCreative,
      gameId,
    } = uploadInfo;

    if (isUserExternal) {
      if (isCreative) {
        return false;
      } else {
        return !!externalPermission.games.find((game) => game.access.upload && game.gameId === gameId);
      }
    }

    return true;
  };

  const getStorageId = (defaultStorageId= null) => {
    const storageId = URLParams.get('storageId');

    return storageId ? parseInt(storageId, 10) : defaultStorageId;
  };

  const closeAssetUploader = () => {
    setAssetsToUpload([]);
  };

  let content;

  switch (uploadPlace) {
    case UPLOAD_PLACE.GENERAL:
      if (isUserExternal) {
        content = (
          <UploadPathSelectionExternalUser
            assetsToUpload={assetsToUpload}
            closeAssetUploader={closeAssetUploader}
            setAssetsClassification={setAssetsClassification}
            setUploadInfo={setUploadInfo}
            setUploadPlace={setUploadPlace}
          />
        );
      } else {
        content = (
          <UploadPathSelectionJiraUser
            assetsToUpload={assetsToUpload}
            closeAssetUploader={closeAssetUploader}
            setAssetsClassification={setAssetsClassification}
            setUploadInfo={setUploadInfo}
            setUploadPlace={setUploadPlace}
          />
        );
      }
      break;
    case UPLOAD_PLACE.GAME:
    case UPLOAD_PLACE.CREATIVE:
      content = canUserProceedUpload() && (
        <AssetUploaderForm
          assetsToUpload={assetsToUpload}
          setAssetsToUpload={setAssetsToUpload}
          uploadInfo={uploadInfo}
          assetsClassification={assetsClassification}
          closeAssetUploader={closeAssetUploader}
          isUserExternal={isUserExternal}
        />
      );
      break;
  }

  return (
    <div className='asset-uploader'>
      {content}
    </div>
  );
};

AssetUploader.propTypes = {
  assetsToUpload: PropTypes.arrayOf(assetToUploadPropTypes),
  setAssetsToUpload: PropTypes.func.isRequired,
};

export default AssetUploader;
