import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import { childrenPropType } from 'types/general';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE } from 'constants/Asset';

import './CanDropWrapper.scss';


const CanDropWrapper = ({
  acceptType,
  path,
  tabStorageId,
  onDropStorageItem,
  children,
}) => {

  const [{ isOver }, drop] = useDrop({
    accept: acceptType,
    drop: (item) => {
      if (acceptType === ASSET_DRAG_AND_DROP_ACCEPT_TYPE.GAME) {
        onDropStorageItem(item, path);
      } else if (acceptType === ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE) {
        onDropStorageItem(
          item,
          path,
          tabStorageId
        );
      } else {
        onDropStorageItem(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={classnames('can-drop-wrapper', {
        'over': isOver,
      })}
    >
      {children}
    </div>
  );
};

CanDropWrapper.propTypes = {
  acceptType: PropTypes.string.isRequired,
  path: PropTypes.string,
  tabStorageId: PropTypes.number,
  onDropStorageItem: PropTypes.func.isRequired,
  children: childrenPropType.isRequired,
};

export default CanDropWrapper;
