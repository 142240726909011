import {
  // Collection actions
  PRESET_COLLECTION_ID,

  GET_COLLECTION_START,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_FAIL,

  GET_COLLECTION_ASSETS_START,
  GET_COLLECTION_ASSETS_SUCCESS,
  GET_COLLECTION_ASSETS_FAIL,

  MANAGE_COLLECTION_ASSETS_START,
  MANAGE_COLLECTION_ASSETS_SUCCESS,
  MANAGE_COLLECTION_ASSETS_FAIL,

  // Collections actions
  SET_PRIVATE_OR_PUBLIC_COLLECTIONS,

  GET_ALL_USER_COLLECTIONS_SUCCESS,

  GET_PRIVATE_COLLECTIONS_START,
  GET_PRIVATE_COLLECTIONS_SUCCESS,
  GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS,
  GET_PRIVATE_COLLECTIONS_FAIL,

  GET_PUBLIC_COLLECTIONS_START,
  GET_PUBLIC_COLLECTIONS_SUCCESS,
  GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS,
  GET_PUBLIC_COLLECTIONS_FAIL,

  SET_COLLECTIONS_SORT_OPTION,
  SET_COLLECTIONS_PAGING_STATUS,
  SET_COLLECTIONS_SEARCH_VALUE,

  SET_ASSETS_IDS_FOR_COLLECTION,
  SET_COLLECTION_CREATION_STATE,
  SET_COLLECTION_TO_EDIT,

  MANAGE_COLLECTION_START,
  MANAGE_COLLECTION_SUCCESS,
  MANAGE_COLLECTION_FAIL,
  MANAGE_COLLECTION_RESET,
} from './actionsTypes';
import CollectionModel from '../Models/collectionModel';
import {
  COLLECTION_SORT_OPTIONS,
  COLLECTIONS_PAGINATION_LOCAL_STORAGE_KEY,
  COLLECTIONS_SORTING_LOCAL_STORAGE_KEY,
} from '../collectionsConstants';
import AssetModel from '../../MainSearch/Models/assetModel';
import CollectionFolderModel from '../Models/collectionFolderModel';


const initState = {
  presetCollectionId: null,
  isPrivateCollections: true,
  allUserCollections: [],
  sortOption: JSON.parse(localStorage.getItem(COLLECTIONS_SORTING_LOCAL_STORAGE_KEY)) || COLLECTION_SORT_OPTIONS[0],
  isPaging: localStorage.getItem(COLLECTIONS_PAGINATION_LOCAL_STORAGE_KEY) === 'true',
  collectionsSearchValue: '',
  privateCollections: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  publicCollections: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  assetsIds: [],
  isCollectionCreationState: false,
  collectionToEdit: null,
  manageCollection: {
    loading: false,
    success: false,
    error: '',
  },
  collection: {
    loading: false,
    data: null,
    error: '',
  },
  collectionAssets: {
    loading: false,
    data: [],
    error: '',
  },
  manageCollectionAssets: {
    loading: false,
    error: '',
  },
};

const collectionsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case PRESET_COLLECTION_ID:
      return {
        ...state,
        presetCollectionId: payload,
      };

    case SET_PRIVATE_OR_PUBLIC_COLLECTIONS:
      return {
        ...state,
        isPrivateCollections: payload,
      };

    case SET_COLLECTIONS_SORT_OPTION:
      return {
        ...state,
        sortOption: payload,
      };

    case SET_COLLECTIONS_PAGING_STATUS:
      return {
        ...state,
        isPaging: payload,
      };

    case SET_COLLECTIONS_SEARCH_VALUE:
      return {
        ...state,
        collectionsSearchValue: payload,
      };

    case GET_ALL_USER_COLLECTIONS_SUCCESS:
      return {
        ...state,
        allUserCollections: payload.map((collectionRaw) => new CollectionModel(collectionRaw)),
      };

    case GET_PRIVATE_COLLECTIONS_START:
      return {
        ...state,
        privateCollections: {
          ...state.privateCollections,
          loading: true,
          error: '',
        },
      };

    case GET_PRIVATE_COLLECTIONS_SUCCESS:
      return {
        ...state,
        privateCollections: {
          loading: false,
          success: {
            ...payload,
            data: payload?.data.map((collectionRaw) => new CollectionModel(collectionRaw)),
          },
          error: '',
        },
      };

    case GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS:
      return {
        ...state,
        privateCollections: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.privateCollections.success.data,
              ...payload?.data.map((collectionRaw) => new CollectionModel(collectionRaw)),
            ],
          },
          error: '',
        },
      };

    case GET_PRIVATE_COLLECTIONS_FAIL:
      return {
        ...state,
        privateCollections: {
          ...state.privateCollections,
          loading: false,
          error: payload,
        },
      };

    case GET_PUBLIC_COLLECTIONS_START:
      return {
        ...state,
        publicCollections: {
          ...state.publicCollections,
          loading: true,
          error: '',
        },
      };

    case GET_PUBLIC_COLLECTIONS_SUCCESS:
      return {
        ...state,
        publicCollections: {
          loading: false,
          success: {
            ...payload,
            data: payload?.data.map((collectionRaw) => new CollectionModel(collectionRaw)),
          },
          error: '',
        },
      };

    case GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS:
      return {
        ...state,
        publicCollections: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.publicCollections.success.data,
              ...payload?.data.map((collectionRaw) => new CollectionModel(collectionRaw)),
            ],
          },
          error: '',
        },
      };

    case GET_PUBLIC_COLLECTIONS_FAIL:
      return {
        ...state,
        publicCollections: {
          ...state.publicCollections,
          loading: false,
          error: payload,
        },
      };

    case SET_ASSETS_IDS_FOR_COLLECTION:
      return {
        ...state,
        assetsIds: payload,
      };

    case SET_COLLECTION_CREATION_STATE:
      return {
        ...state,
        isCollectionCreationState: payload,
      };

    case SET_COLLECTION_TO_EDIT:
      return {
        ...state,
        collectionToEdit: payload,
      };

    case MANAGE_COLLECTION_START:
      return {
        ...state,
        manageCollection: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case MANAGE_COLLECTION_SUCCESS:
      return {
        ...state,
        manageCollection: {
          loading: false,
          success: payload,
          error: '',
        },
      };

    case MANAGE_COLLECTION_FAIL:
      return {
        ...state,
        manageCollection: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    case MANAGE_COLLECTION_RESET:
      return {
        ...state,
        manageCollection: {
          loading: false,
          success: false,
          error: '',
        },
      };

    case GET_COLLECTION_START:
      return {
        ...state,
        collection: {
          loading: true,
          data: null,
          error: '',
        },
      };

    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        collection: {
          loading: false,
          data: new CollectionModel(payload),
          error: '',
        },
      };

    case GET_COLLECTION_FAIL:
      return {
        ...state,
        collection: {
          loading: false,
          data: null,
          error: payload,
        },
      };

    case GET_COLLECTION_ASSETS_START:
      return {
        ...state,
        collectionAssets: {
          loading: true,
          error: '',
        },
      };

    case GET_COLLECTION_ASSETS_SUCCESS:
      return {
        ...state,
        collectionAssets: {
          loading: false,
          data: payload.map((asset) => asset.collectionId ?
            new CollectionFolderModel(asset) :
            new AssetModel(asset)
          ),
          error: '',
        },
      };

    case GET_COLLECTION_ASSETS_FAIL:
      return {
        ...state,
        collectionAssets: {
          loading: false,
          error: payload,
        },
      };

    case MANAGE_COLLECTION_ASSETS_START:
      return {
        ...state,
        manageCollectionAssets: {
          loading: true,
          error: '',
        },
      };

    case MANAGE_COLLECTION_ASSETS_SUCCESS:
      return {
        ...state,
        manageCollectionAssets: {
          loading: false,
          error: '',
        },
      };

    case MANAGE_COLLECTION_ASSETS_FAIL:
      return {
        ...state,
        manageCollectionAssets: {
          loading: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default collectionsReducer;
