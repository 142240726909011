import {
  GET_STORAGE_DATA_REQUEST,
  GET_STORAGE_DATA_SUCCESS,
  GET_STORAGE_DATA_FAIL,
  
  CREATE_NEW_FOLDER_GAME_REQUEST,
  CREATE_NEW_FOLDER_GAME_SUCCESS,
  CREATE_NEW_FOLDER_GAME_FAIL,

  UPLOAD_DOC_GAME_REQUEST,
  UPLOAD_DOC_GAME_SUCCESS,
  UPLOAD_DOC_GAME_FAIL,

  RENAME_ASSET_REQUEST,
  RENAME_ASSET_SUCCESS,
  RENAME_ASSET_FAIL,

  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,

  MOVE_ASSET_REQUEST,
  MOVE_ASSET_SUCCESS,
  MOVE_ASSET_FAIL,

  SEARCH_ASSET_DUPLICATES_REQUEST,
  SEARCH_ASSET_DUPLICATES_SUCCESS,
  SEARCH_ASSET_DUPLICATES_FAIL,
} from '../actionTypes';
import Assets from 'services/Assets';
import Creative from 'services/Creative';
import { getErrorMessage, str } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  setAssetsToPreviewAction,
  updateAssetToPreviewAction,
} from 'components/Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/actions';
import {
  setTriggerForRefreshingMainSearchAction,
  toggleMainSearchLoadingAfterAction,
} from 'components/MainSearch/MainSearch.redux/actions';
import AssetModel from 'components/MainSearch/Models/assetModel';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const getStorageData = (storageId, dirPath) => (dispatch) => {
  dispatch({
    type: GET_STORAGE_DATA_REQUEST,
  });

  return Creative.getStorageData({
    storageId,
    dirPath,
  })
    .then((response) => {
      const transformedAssets = response.map((assetRaw) => new AssetModel(assetRaw));

      dispatch({
        type: GET_STORAGE_DATA_SUCCESS,
        payload: {
          storageId,
          dirPath,
          assets: transformedAssets,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_STORAGE_DATA_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: 'Get storage - request failed',
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const createFolder = (data) => (dispatch) => {
  dispatch({ type: CREATE_NEW_FOLDER_GAME_REQUEST });

  return Assets.createFolder(data)
    .then(() => {
      dispatch({ type: CREATE_NEW_FOLDER_GAME_SUCCESS });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.folder.success.create.text,
        description: notifications.folder.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: CREATE_NEW_FOLDER_GAME_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.folder.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const uploadGameAsset = (asset, gameId, storageId) => (dispatch) => {
  dispatch({ type: UPLOAD_DOC_GAME_REQUEST });

  const formData = new FormData();

  formData.append('gameId', gameId);
  formData.append('storageId', storageId);
  formData.append('dirPath', asset.filePath);
  formData.append('category', asset.category);
  formData.append('subCategory', asset.subCategory);
  formData.append('productionFormat', asset.productionFormat);
  formData.append('developerFormat', asset.developerFormat);
  formData.append('description', asset.description.trim());
  formData.append('attributes', asset.attributesJSON);
  formData.append('files', asset.file);

  return Assets.createDoc(formData, gameId)
    .then(() => {
      dispatch({
        type: UPLOAD_DOC_GAME_SUCCESS,
        payload: asset.name,
      });
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_DOC_GAME_FAIL,
        error: getErrorMessage(error),
        payload: asset.name,
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.upload.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const renameAssetAction = (data) => (dispatch) => {
  dispatch({ type: RENAME_ASSET_REQUEST });

  return Assets.moveOrRenameAsset(data)
    .then(() => {
      dispatch({
        type: RENAME_ASSET_SUCCESS,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.rename.text,
        description: notifications.asset.success.rename.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: RENAME_ASSET_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.rename.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const updateAssetAction = (id, data, isAssetsFromGameStorage, dirPath, storageId, assetInfo) => (dispatch) => {
  dispatch({ type: UPDATE_ASSET_REQUEST });

  if (!isAssetsFromGameStorage) {
    dispatch(toggleMainSearchLoadingAfterAction(true));
  }

  return Assets.updateAsset(id, data)
    .then((response) => {
      dispatch({
        type: UPDATE_ASSET_SUCCESS,
        payload: response,
      });

      if (!isAssetsFromGameStorage) {
        dispatch(setTriggerForRefreshingMainSearchAction());
      }

      if (storageId) {
        dispatch(getStorageData(storageId, dirPath));
      }

      if (assetInfo) {
        dispatch(updateAssetToPreviewAction(id, assetInfo));
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.update.text,
        description: notifications.asset.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_ASSET_FAIL,
        error: getErrorMessage(error),
      });

      if (!isAssetsFromGameStorage) {
        dispatch(toggleMainSearchLoadingAfterAction(false));
      }

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const moveAssetAction = (data) => (dispatch) => {
  dispatch({ type: MOVE_ASSET_REQUEST });

  return Assets.moveOrRenameAsset(data)
    .then(() => {
      dispatch({ type: MOVE_ASSET_SUCCESS });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.move.text,
        description: notifications.asset.success.move.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MOVE_ASSET_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.move.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const setAssetDuplicatesAction = (assetDuplicates) => (dispatch) => dispatch({
  type: SEARCH_ASSET_DUPLICATES_SUCCESS,
  payload: assetDuplicates,
});

export const getAssetDuplicatesAction = (assetNames) => (dispatch) => {
  dispatch({ type: SEARCH_ASSET_DUPLICATES_REQUEST });

  return Assets.getAssetDuplicates(assetNames)
    .then((response) => {
      dispatch({
        type: SEARCH_ASSET_DUPLICATES_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SEARCH_ASSET_DUPLICATES_FAIL,
        error: getErrorMessage(error),
      });
    });
};

export const copyToClipboardLinkToSharedAssetsAction = (data) => (dispatch) => {
  return Assets.getSharedAssetsHash(data)
    .then((response) => str.copyTextToClipboard(`${process.env.REACT_APP_URL}/share-assets/?${response}`))
    .then(() => {
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.asset.success.share.text,
        description: notifications.asset.success.share.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.share.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};


export const copyToClipboardLinkToSharedAssetsPathAction = (data) => (dispatch) => {
  const id = data.ids[0];
  str.copyTextToClipboard(`${window.location.href}&assetId=${id}`);
  dispatch(addGeneralMessageAction({
    error: false,
    text: notifications.asset.success.share.text,
    description: notifications.asset.success.share.description,
    lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
  }));
};

export const getSharedAssetsAction = (query) => (dispatch) => {
  return Assets.getSharedAssets(query)
    .then((response) => {
      dispatch(setAssetsToPreviewAction(response.map((asset) => new AssetModel(asset))));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.share.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
