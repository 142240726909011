export const GET_ANALYTICS_DEVICE_START = 'GET_ANALYTICS_DEVICE_START';
export const GET_ANALYTICS_DEVICE_FAIL = 'GET_ANALYTICS_DEVICE_FAIL';
export const GET_ANALYTICS_DEVICE_SUCCESS = 'GET_ANALYTICS_DEVICE_SUCCESS';

export const SET_ANALYTICS_DEVICE_QUERY_ID = 'SET_ANALYTICS_DEVICE_QUERY_ID';

export const SET_ANALYTICS_DEVICE_TEST_STATUS_START = 'SET_ANALYTICS_DEVICE_TEST_STATUS_START';
export const SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS = 'SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS';
export const SET_ANALYTICS_DEVICE_TEST_STATUS_FAIL = 'SET_ANALYTICS_DEVICE_TEST_STATUS_FAIL';

export const GET_ANALYTICS_DEVICE_BY_TPDID_START = 'GET_ANALYTICS_DEVICE_BY_TPDID_START';
export const GET_ANALYTICS_DEVICE_BY_TPDID_FAIL = 'GET_ANALYTICS_DEVICE_BY_TPDID_FAIL';
export const GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS = 'GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS';
