import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Chip, Input, InputAdornment, Popper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactComponent as SearchIcon } from '../../../assets/svg/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/cross.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';

import styles from './styles.module.scss';

const TagEditor = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [newTag, setNewTag] = useState('');

  const {
    selectedTags,
    tagsForAutoComplete,
    onAddCustomTag,
    onSetTags,
  } = props;

  const handleAddNewClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = (event, reason) => {
    if (reason === 'toggleInput') {
      return;
    }

    setAnchorEl(null);
  };

  const handleChangeTags = (event, value) => {
    onSetTags(value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const tagTexts = [...selectedTags, ...tagsForAutoComplete].map((tag) => tag.trim());
      const trimmedTag = newTag.trim();

      if (trimmedTag.length && !tagTexts.includes(trimmedTag)) {
        onAddCustomTag(trimmedTag);
      }
    }
  };

  const handleInputChange = (event) => setNewTag(event.target.value);

  const renderInput = (params) => (
    <Input
      ref={params.InputProps.ref}
      inputProps={params.inputProps}
      autoFocus
      endAdornment={(
        <InputAdornment position='end'>
          <SearchIcon className={styles.searchIcon} />
        </InputAdornment>
      )}
      onKeyDown={handleInputKeyDown}
      onChange={handleInputChange}
    />
  );

  const renderOption = (option, { selected }) => (
    <>
      <span>{option}</span>
      {selected && (
        <CloseIcon className={styles.closeIcon} />
      )}
    </>
  );

  return (
    <>
      <div className={styles.tagList}>
        <Button
          variant='contained'
          className={styles.addNewButton}
          onClick={handleAddNewClick}
        >
          <PlusIcon className={styles.plusIcon} />
          Add new
        </Button>
        {selectedTags.map((item) => (
          <Chip
            key={item}
            label={item}
            clickable={false}
          />
        ))}
      </div>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement='bottom-start'
        className={styles.popper}
        disablePortal
      >
        <Autocomplete
          open
          multiple
          disableCloseOnSelect
          disablePortal
          value={selectedTags}
          renderTags={() => null}
          options={tagsForAutoComplete}
          noOptionsText='Press Enter to add'
          renderInput={renderInput}
          renderOption={renderOption}
          onChange={handleChangeTags}
          onClose={handleClose}
        />
      </Popper>
    </>
  );
};

TagEditor.propTypes = {
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagsForAutoComplete: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddCustomTag: PropTypes.func.isRequired,
  onSetTags: PropTypes.func.isRequired,
};

export default TagEditor;
