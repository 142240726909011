import { Record } from 'immutable';

import {
  GET_GAME_SETTING_START,
  GET_GAME_SETTING_FAIL,
  GET_GAME_SETTING_SUCCESS,
  UPDATE_GAME_SETTING_START,
  UPDATE_GAME_SETTING_FAIL,
  UPDATE_GAME_SETTING_SUCCESS,
  CREATE_GAME_SETTING_START,
  CREATE_GAME_SETTING_FAIL,
  CREATE_GAME_SETTING_SUCCESS,
} from './actionTypes';

const InitialState = Record({
  error: null,
  gameSettings: [],
  isFetching: false,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const {
    type,
  } = action;

  switch (type) {
    case GET_GAME_SETTING_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case GET_GAME_SETTING_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          res,
        } = action;

        const gameSettings = [...res];
        ctx.set('isFetching', false)
          .set('gameSettings', gameSettings);
      });
    }

    case GET_GAME_SETTING_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    case UPDATE_GAME_SETTING_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case UPDATE_GAME_SETTING_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          systemId,
          res,
        } = action;

        const {
          Attributes,
        } = res;

        const gameSettings = state.gameSettings.map((game) => {
          if (game.systemId !== systemId) {
            return game;
          }

          return {
            systemId,
            ...Attributes,
          };
        });

        ctx.set('isFetching', false)
          .set('gameSettings', gameSettings);
      });
    }

    case UPDATE_GAME_SETTING_FAIL: {
      const {
        error,
      } = action;

      return state.withMutations((ctx) => {
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    case CREATE_GAME_SETTING_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case CREATE_GAME_SETTING_SUCCESS: {
      const {
        game,
      } = action;

      const newGameSettings = [...state.gameSettings, game];
      return state.withMutations((ctx) => {
        ctx.set('isFetching', false)
          .set('gameSettings', newGameSettings);
      });
    }

    case CREATE_GAME_SETTING_FAIL: {
      const {
        error,
      } = action;
      return state.withMutations((ctx) => {
        ctx.set('isFetching', false)
          .set('error', error);
      });
    }

    default: {
      return state;
    }
  }
}
