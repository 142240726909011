import * as Yup from 'yup';
import { obj } from '../../utils/utils';
import { ASSET_UPLOADER_MAX_FILE_SIZE } from '../../constants/General';
import { VALIDATION } from '../../constants/Validation';
import { ASSET_CATEGORIES_KEYS } from '../../constants/Asset';


export const UPLOAD_PLACE = {
  CREATIVE: 'creative',
  GAME: 'game',
  GENERAL: 'general',
};

export const WITHOUT_FOLDER_PATH = 'WITHOUT FOLDER';
export const NULL_VALUE_INDICATOR = 'init';

const fileSchema = Yup
  .mixed()
  .test(
    'size',
    'File size should be less than 20GB',
    obj.validateFileSize(ASSET_UPLOADER_MAX_FILE_SIZE)
  );

const attributeOptionSchema = Yup.object({
  id: Yup.number(),
  value: Yup.string(),
});

export const assetsToUploadValidationSchema = Yup.array().of(
  Yup.object({
    id: Yup.string().required(),
    file: fileSchema,
    name: Yup.string().required().matches(
      VALIDATION.ASSET_NAME,
      'File name contains prohibited characters or exceeds the maximum length'
    ),
    path: Yup.string(),
    iconPreview: Yup.string().required(),
    [ASSET_CATEGORIES_KEYS.CATEGORY]: Yup.string().nullable(),
    [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: Yup.string().nullable(),
    [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: Yup.string().nullable(),
    [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: Yup.string().nullable(),
    typeCat: attributeOptionSchema.nullable(),
    description: Yup.string().max(100, 'Maximum number of symbols is 100'),
  })
);
