import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  FORMAT_DATE_AT_TIME,
  GENERAL_FILE_TYPES,
} from '../../../constants/General';
import PlayerModal from '../../UIComponents/PlayerModal';
import ConfirmModal from '../../UIComponents/ConfirmModal';
import VideoPlayOverlay from '../../UIComponents/VideoPlayOverlay';
import RoundModalWithInput from '../../UIComponents/RoundModalWithInput';
import { deleteCreative } from '../Creative.redux/Actions/creativeActions';
import { getStorageData } from '../../Assets/Assets.redux/Actions/assetActions';
import { createNewFolder } from '../Creative.redux/Actions/assetActions';
import { VALIDATION } from 'constants/Validation';
import { CREATIVE_SECTIONS } from 'constants/Creatives';
import { str } from 'utils/utils';

import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import './CreativeHead.scss';


const CreativeHead = ({
  creativeId,
  isFetching,
  jiraIssueTitle,
  gameName,
  mainPreviewUrl,
  mainMediumPreviewUrl,
  mainPreviewType,
  mainPreviewPath,
  createdAt,
  creativeFormat,
  isEditCreative,
  activeSection,
  canManageCreative,
  onEditCreative,
  handleGameClick,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const storageId = useMemo(() => Number(searchParams.get('storageId')), [searchParams]);
  const dirPath = useMemo(() => searchParams.get('dirPath'), [searchParams]);

  const [isVideoPreviewOpen, setIsVideoPreviewOpen] = useState(false);
  const [isDeleteCreativeModal, setIsDeleteCreativeModal] = useState(false);
  const [isNewFolderModal, setIsNewFolderModal] = useState(false);

  const proceedDeleteCreative = async () => {
    await dispatch(deleteCreative(creativeId));
    await setIsDeleteCreativeModal(false);
  };

  const proceedCreateFolder = async (folderName) => {
    const dirPathForSave = dirPath.length ? `${dirPath}/${folderName}` : folderName;

    if (!isNaN(storageId)) {
      await dispatch(createNewFolder({
        dirPath: dirPathForSave,
        storageId: storageId,
      }, creativeId));

      await dispatch(getStorageData(storageId, dirPath));
      setIsNewFolderModal(false);
    }
  };

  return (
    <>
      <div className='creative-head'>
        <div className='creative-head__media-container'>
          <img
            className={classnames('creative-head__image', {
              'icon': !mainMediumPreviewUrl,
            })}
            src={mainMediumPreviewUrl || str.getExtensionIconSrc(str.getExtensionFromPath(mainPreviewPath))}
            alt='Creative main preview'
          />

          {(mainPreviewUrl && mainPreviewType === GENERAL_FILE_TYPES.video) &&
            <VideoPlayOverlay
              className='creative-head__video-play-overlay'
              onClick={() => setIsVideoPreviewOpen(true)}
            />
          }
        </div>

        <div className='creative-head-info'>
          <h1 className='creative-head-info__title'>
            {jiraIssueTitle}
          </h1>
          <h2
            className='creative-head-info__game-link'
            onClick={handleGameClick}
          >
            {gameName}
          </h2>

          <div className='creative-head-info__other'>
            <span className='creative-head-info__field'>
              <span>Created at: </span>{moment(createdAt).format(FORMAT_DATE_AT_TIME)}
            </span>
            <span className='creative-head-info__field'>
              <span>Format: </span>{creativeFormat}
            </span>
          </div>
        </div>

        <div className='creative-head-actions'>
          {activeSection === CREATIVE_SECTIONS.STORAGE &&
            <div
              className='creative-head-actions__button creative-head-actions__button--with-text'
              onClick={() => setIsNewFolderModal(true)}
            >
              <span>Create folder</span>
              <AddIcon className='creative-head-actions__icon small' />
            </div>
          }

          {activeSection === CREATIVE_SECTIONS.GENERAL_INFO && !isEditCreative &&
            <>
              {canManageCreative &&
                <div
                  className='creative-head-actions__button'
                  onClick={onEditCreative}
                >
                  <EditIcon className='creative-head-actions__icon' />
                </div>
              }
              <div
                className='creative-head-actions__button'
                onClick={() => setIsDeleteCreativeModal(true)}
              >
                <DeleteIcon className='creative-head-actions__icon' />
              </div>
            </>
          }
        </div>
      </div>

      <PlayerModal
        open={isVideoPreviewOpen}
        url={mainPreviewUrl}
        onClose={() => setIsVideoPreviewOpen(false)}
      />

      <ConfirmModal
        isOpen={isDeleteCreativeModal}
        header='Delete creative post'
        text={<>Are you sure want to delete this creative post?<br />Please also check that it has no jira ticket or assets uploaded</>}
        agreeText='delete'
        onAgree={proceedDeleteCreative}
        onClose={() => setIsDeleteCreativeModal(false)}
        loading={isFetching}
      />

      <RoundModalWithInput
        isOpen={isNewFolderModal}
        title='create folder'
        label='Enter name'
        validationPattern={VALIDATION.ASSET_NAME}
        validationText='Please enter a valid name for folder'
        validationTextForEmptyCase={'Name can\'t contain only spaces'}
        agreeText='Create'
        agreeIcon={<AddIcon className='icon-inside-button' />}
        onCancel={() => setIsNewFolderModal(false)}
        onAgree={proceedCreateFolder}
        loading={isFetching}
        loadingText='Creating folder'
      />
    </>
  );
};

CreativeHead.propTypes = {
  creativeId: PropTypes.number,
  isFetching: PropTypes.bool,
  jiraIssueTitle: PropTypes.string,
  gameName: PropTypes.string,
  mainPreviewUrl: PropTypes.string,
  mainMediumPreviewUrl: PropTypes.string,
  mainPreviewType: PropTypes.number,
  mainPreviewPath: PropTypes.string,
  createdAt: PropTypes.string,
  creativeFormat: PropTypes.string,
  isEditCreative: PropTypes.bool,
  activeSection: PropTypes.string,
  canManageCreative: PropTypes.bool,
  onEditCreative: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
};

export default CreativeHead;
