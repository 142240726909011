import React, { useEffect, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Preloader from '../UIComponents/Preloader';
import Pagination from '../UIComponents/Pagination';
import FailRequestScreen from '../UIComponents/FailRequestScreen';
import {
  History as HistoryIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@material-ui/icons';

import './HistoryPage.scss';

const limit = 11;


const HistoryPage = ({id, idType, service}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isDescendOrder, seIsDescendOrder] = useState(true);

  useEffect(() => {
    getHistoryData(currentPage, isDescendOrder);
  }, [currentPage, isDescendOrder]);

  const getHistoryData = (page, order) => {

    const query = {
      limit,
      page,
      sort: order ? -1 : 1,
      [idType]: id,
    };

    setIsLoading(true);
    setIsError(false);

    service(query)
      .then((response) => {
        setHistoryData(response.data);
        setIsLoading(false);
        setTotalPages(Math.ceil(response.total / limit));
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  const handlePageChanged = (page) => {
    setCurrentPage(page);
  };

  const handleSortByTime = () => {
    seIsDescendOrder((prevState) => !prevState);
  };

  let content;

  if (isLoading) {
    content =
      <div className='history-without-table-state'>
        <Preloader />
      </div>;
  } else if (isError) {
    content =
      <div className='history-without-table-state'>
        <FailRequestScreen />
      </div>;
  } else if (historyData.length) {
    content =
      <>
        <table className='history-table'>
          <thead className='history-table__header'>
            <tr>
              <th className='history-table__header-cell'>File</th>
              <th className='history-table__header-cell'>Action</th>
              <th className='history-table__header-cell history-table__header-cell--time'>
                <span>Time</span>
                <div
                  className='history-table__sort-icons-container'
                  onClick={handleSortByTime}
                >
                  <ArrowDropUpIcon
                    className={classnames('history-table__sort-icon', {
                      'active': isDescendOrder,
                    })}
                  />
                  <ArrowDropDownIcon
                    className={classnames('history-table__sort-icon', {
                      'active': !isDescendOrder,
                    })}
                  />
                </div>
              </th>
              <th className='history-table__header-cell'>Author</th>
            </tr>
          </thead>

          <tbody className='history-table__body'>
            {historyData.map((historyDataItem) => {
              return (
                <tr key={historyDataItem.id}>
                  <td className='history-table__body-cell'>{historyDataItem.storageObjectMetadata}</td>
                  <td className='history-table__body-cell'>{historyDataItem.action}</td>
                  <td className='history-table__body-cell'>{moment(historyDataItem.createdAt).format('YYYY-MM-DD [AT] hh:mm A')}</td>
                  <td className='history-table__body-cell'>{historyDataItem.user?.name ?? 'Deleted user'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {totalPages > 1 &&
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChanged={handlePageChanged}
            disabled={isLoading}
          />
        }
      </>;
  } else {
    content =
      <div className='history-without-table-state'>
        <div className='history-empty-state'>
          <HistoryIcon />
          <p className='history-empty-state__text'>There is no history yet</p>
        </div>
      </div>;
  }

  return (
    <div className='history'>
      {content}
    </div>
  );
};

HistoryPage.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  idType: PropTypes.string.isRequired,
  service: PropTypes.func.isRequired,
};

export default HistoryPage;
