import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReactGA from 'react-ga';
import {
  Button,
  CircularProgress,
  Container,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import HeaderWithControls from '../../UIComponents/HeaderWithControls';
import { getRewardOptions, sendItems } from '../CustomerSupport.redux/actions';
import { getAllAnalyticsGames } from '../../AnalyticsEvent/AnalyticsEvent.redux/actions';

import styles from './styles.module.scss';

const CustomerSupportDashboard = (props) => {
  const {
    isSendingItems,
    isFetchingRewardOptions,
    sendItemRes,
    rewardOptions: itemOptions,
    analyticsGames,
    getRewardOptions,
    getAllAnalyticsGames,
    sendItems,
  } = props;

  const [gameName, setGameName] = useState('zombieland');
  const [userId, setUserId] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [csTitle, setCsTitle] = useState('Message from Customer Support');
  const [csMessage, setCsMessage] = useState('Thank you for contacting customer support. We have sent you the following items.');
  const [itemSelections, setItemSelections] = useState([0,1,2,3].map((id) => ({
    key: id,
    value: null,
  })));

  useEffect(() => {
    getRewardOptions(gameName);
    getAllAnalyticsGames();
  }, [getRewardOptions, getAllAnalyticsGames, gameName]);

  const handleChangeGame = (event) => setGameName(event.target.value);

  const handleChangeUserId = (event) => setUserId(event.target.value);

  const handleChangeTicketId = (event) => setTicketId(event.target.value);

  const handleChangeCsTitle = (event) => setCsTitle(event.target.value);

  const handleChangeCsMessage = (event) => setCsMessage(event.target.value);

  const handleChangeItemValue = (itemIndex) => (event) => {
    const selectedLabel = event.target.value;
    const option = itemOptions.find((option) => option.option_label && option.option_label === selectedLabel);

    if (option) {
      setItemSelections((prevSelections) => {
        const updatedSelections = prevSelections.map((selection, index) => itemIndex === index
          ? { ...selection, value: option }
          : selection);

        return updatedSelections;
      });
    }
  };

  const handleChangeItemQuantity = (index) => (event) => {
    const selections = [...itemSelections];
    const newItem = _.cloneDeep(itemSelections[index]);

    newItem.value.reward_value = event.target.value;
    selections[index] = newItem;
    setItemSelections(selections);
  };

  const handleSendItems = () => {
    const uid = userId || 'e83f27e8-784e-4f0d-acd3-5f32959c3226';
    const data = {
      gameId: gameName,
      userId: uid,
      title: csTitle,
      message: csMessage,
      itemSelections,
    };

    sendItems(data);

    ReactGA.event({
      action: 'send_reward_items',
      category: 'customer_support',
      label: gameName,
    });
  };

  return (
    <Container maxWidth={false} >
      <HeaderWithControls
        titleChildren={<Typography variant='h1'>Tilting Point Customer Service Dashboard v0.1</Typography>}
      />

      <FormLabel>Game</FormLabel>
      <Select
        value={gameName}
        onChange={handleChangeGame}
        variant='outlined'
        className={styles.gameSelect}
      >
        {analyticsGames && analyticsGames.map((gameName) => (
          <MenuItem key={gameName} value={gameName}>{gameName}</MenuItem>
        ))}
      </Select>

      {isFetchingRewardOptions && <CircularProgress />}
      {!!(itemOptions.length) && (
        <div className={styles.itemsContainer}>
          <FormLabel>User ID</FormLabel>
          <TextField
            variant='outlined'
            value={userId}
            onChange={handleChangeUserId}
          />

          <FormLabel>Ticket</FormLabel>
          <TextField
            variant='outlined'
            value={ticketId}
            onChange={handleChangeTicketId}
          />

          <FormLabel>Title</FormLabel>
          <TextField
            variant='outlined'
            value={csTitle}
            onChange={handleChangeCsTitle}
          />

          <FormLabel>Message</FormLabel>
          <TextField
            variant='outlined'
            multiline
            value={csMessage}
            onChange={handleChangeCsMessage}
          />

          <div className={styles.itemList}>
            {itemSelections.map((item, index) => (
              <div key={item.key} className={styles.item}>
                <FormLabel className={styles.itemTitle}>{`Item #${index + 1}`}</FormLabel>

                <div className={styles.itemField}>
                  <FormLabel>Name</FormLabel>
                  <Select
                    variant='outlined'
                    value={(item.value && item.value.option_label) || ''}
                    onChange={handleChangeItemValue(index)}
                  >
                    {itemOptions.map((option) => (
                      <MenuItem key={option.reward_id} value={option.option_label}>
                        {option.option_label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div className={styles.itemField}>
                  <FormLabel>Quantity</FormLabel>
                  <TextField
                    type='number'
                    variant='outlined'
                    disabled={!item || !item.value || !item.value.option_label}
                    value={(item && item.value && item.value.reward_value) || 0}
                    onChange={handleChangeItemQuantity(index)}
                  />
                </div>
              </div>
            ))}
          </div>

          <Button
            variant='contained'
            color='primary'
            className={styles.sendItemsButton}
            onClick={handleSendItems}
          >
            Send Items
          </Button>

          {isSendingItems
            ? <div>Sending items ...</div>
            : sendItemRes && <div>{sendItemRes}</div>
          }
        </div>
      )}
    </Container>
  );
};

const optionPropType = PropTypes.shape({
  option_label: PropTypes.string,
  reward_id: PropTypes.string,
});

CustomerSupportDashboard.propTypes = {
  sendItemRes: PropTypes.string,
  isSendingItems: PropTypes.bool,
  isFetchingRewardOptions: PropTypes.bool,
  rewardOptions: PropTypes.arrayOf(optionPropType).isRequired,
  analyticsGames: PropTypes.arrayOf(PropTypes.string).isRequired,
  getRewardOptions: PropTypes.func.isRequired,
  sendItems: PropTypes.func.isRequired,
  getAllAnalyticsGames: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getRewardOptions,
  sendItems,
  getAllAnalyticsGames,
};

const mapStateToProps = ({ customerSupport, analyticsEvent }) => {
  const {
    error,
    rewardOptions,
    isFetchingRewardOptions,
    isSendingItems,
    sendItemRes,
  } = customerSupport;
  const { analyticsGames } = analyticsEvent;

  return {
    error,
    rewardOptions,
    isFetchingRewardOptions,
    isSendingItems,
    sendItemRes,
    analyticsGames,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupportDashboard);
