import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import blue from '@material-ui/core/colors/blue';

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const DeviceSelectDialog = (props) => {
  const {
    classes,
    onClose,
    onSelect,
    devices,
    open,
  } = props;

  const handleListItemClick = (value) => {
    onSelect(value);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='simple-dialog-title'>
      <DialogTitle id='simple-dialog-title'>Select Device Tpdid</DialogTitle>
      <div>
        <List>
          {devices.map((tpdid) => (
            <ListItem button onClick={() => handleListItemClick(tpdid)} key={tpdid}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tpdid} />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
};

DeviceSelectDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  devices: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeviceSelectDialog);
