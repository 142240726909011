import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import './SearchBoxRounded.scss';


const SearchBoxRounded = ({ searchValue, placeholder, width, handleSearch, elasticSearchBox }) => {
  return (
    <div
      className='search-box-rounded'
      style={{
        width: `${width}px`,
      }}
    >
      {elasticSearchBox ?
        elasticSearchBox :
        <input
          className='search-box-rounded__input'
          value={searchValue}
          placeholder={placeholder}
          onChange={(event) => handleSearch(event.target.value)}
        />
      }

      <ClearIcon
        className={classnames('search-box-rounded__clear-icon', {
          'active': !!searchValue,
        })}
        onClick={() => handleSearch('')}
      />

      <button
        type='submit'
        className='search-box-rounded__search-icon-container'
        onClick={() => handleSearch(searchValue)}
      >
        <SearchIcon className='search-box-rounded__search-icon' />
      </button>
    </div>
  );
};

SearchBoxRounded.defaultProps = {
  width: 200,
  placeholder: 'Type to search',
};

SearchBoxRounded.propTypes = {
  searchValue: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  handleSearch: PropTypes.func.isRequired,
  elasticSearchBox: PropTypes.element,
};

export default SearchBoxRounded;
