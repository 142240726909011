import cloneDeep from 'lodash/cloneDeep';
import {
  OVERLAYS_SETTINGS__ACTIVE_OVERLAY_MODE_CHANGED,
  OVERLAYS_SETTINGS__OVERLAY_MODE_CHANGED,

  OVERLAYS_SETTINGS__VIDEO_INDEX_CHANGED,
  OVERLAYS_SETTINGS__VIDEO_ARRAY_CHANGED,
  OVERLAYS_SETTINGS__MEDIA_ITEMS_READY,

  OVERLAYS_SETTINGS__LAYERS_CHANGED,

  OVERLAYS_SETTINGS__STORE_CLEARED,

  // heroes
  OVERLAYS_SETTINGS__HERO_SELECTED,
  OVERLAYS_SETTINGS__HERO_DELETED,
  OVERLAYS_SETTINGS__HERO_POSITION_CHANGED,
  OVERLAYS_SETTINGS__HERO_SIZE_CHANGED,
  OVERLAYS_SETTINGS__HERO_REFLECTION_CHANGED,
  OVERLAYS_SETTINGS__HERO_ANIMATION_CHANGED,
  OVERLAYS_SETTINGS__HERO_LOOP_CHANGED,

  // effects
  OVERLAYS_SETTINGS__EFFECT_SELECTED,
  OVERLAYS_SETTINGS__EFFECT_DELETED,
  OVERLAYS_SETTINGS__EFFECT_POSITION_CHANGED,
  OVERLAYS_SETTINGS__EFFECT_SIZE_CHANGED,

  // lower thirds
  OVERLAYS_SETTINGS__LOWER_THIRD_SELECTED,
  OVERLAYS_SETTINGS__LOWER_THIRD_DELETED,
  OVERLAYS_SETTINGS__LOWER_THIRD_CREATED,
  OVERLAYS_SETTINGS__LOWER_THIRD_COMMON_SETTINGS_CHANGED,
  OVERLAYS_SETTINGS__LOWER_THIRD_SETTINGS_CHANGED,
} from './actionTypes';

import {
  OVERLAY_TYPES,
  VIDEO_EXTENSIONS,
} from 'constants/CreativeTool';

const initState = {
  overlayMode: '',
  activeOverlayMode: OVERLAY_TYPES.HERO_OVERLAY,
  videoArray: [],
  mediaItemsReady: false,
  videoIndex: null,
  lowerThirdsCommonConfig: null,
};


const VideoOverlaysSettingsReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OVERLAYS_SETTINGS__ACTIVE_OVERLAY_MODE_CHANGED: {
      return {
        ...state,
        activeOverlayMode: payload,
      };
    }

    case OVERLAYS_SETTINGS__OVERLAY_MODE_CHANGED: {
      const videoArray = cloneDeep(state.videoArray);

      return {
        ...state,
        overlayMode: payload,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__VIDEO_INDEX_CHANGED: {
      return {
        ...state,
        videoIndex: payload,
      };
    }

    case OVERLAYS_SETTINGS__VIDEO_ARRAY_CHANGED: {
      return {
        ...state,
        videoArray: payload,
      };
    }

    case OVERLAYS_SETTINGS__MEDIA_ITEMS_READY: {
      return {
        ...state,
        mediaItemsReady: payload,
      };
    }

    case OVERLAYS_SETTINGS__LAYERS_CHANGED: {
      const { activeExtension, overlays } = payload;
      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];

      currentVideo[activeExtension].overlays = overlays;

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__STORE_CLEARED: {
      return {
        ...initState,
      };
    }

    // heroes
    case OVERLAYS_SETTINGS__HERO_SELECTED: {
      const { activeExtension, overlay } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlaysForActiveExtension = currentVideo[activeExtension].overlays;

      const overlays = [
        ...currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays,
        ...currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays,
        ...currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays,
        ...currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays,
      ];

      const hero = overlays.find((overlay) => overlay.type === OVERLAY_TYPES.HERO_OVERLAY);

      if (hero) {
        // set isLoop option the same as for other heroes
        overlay.isLoop = hero.isLoop;
      }

      overlaysForActiveExtension.push(overlay);

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_DELETED:
    case OVERLAYS_SETTINGS__EFFECT_DELETED: {
      // remove from list by id
      const { id, activeExtension } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlaysForActiveExtension = currentVideo[activeExtension].overlays;

      const index = overlaysForActiveExtension.findIndex((overlay) => overlay.id === id);

      if (index !== -1) {
        overlaysForActiveExtension.splice(index, 1);
      }

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_POSITION_CHANGED:
    case OVERLAYS_SETTINGS__EFFECT_POSITION_CHANGED: {
      const { id, activeExtension, x, y } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlays = currentVideo[activeExtension].overlays;

      const hero = overlays.find((overlay) => overlay.id === id);

      hero.x = x;
      hero.y = y;

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_SIZE_CHANGED:
    case OVERLAYS_SETTINGS__EFFECT_SIZE_CHANGED: {
      const { id, activeExtension, x, y, resizeWidth, resizeHeight } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlays = currentVideo[activeExtension].overlays;

      const hero = overlays.find((overlay) => overlay.id === id);

      hero.x = x;
      hero.y = y;
      hero.resizeWidth = resizeWidth;
      hero.resizeHeight = resizeHeight;

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_REFLECTION_CHANGED: {
      const { id, activeExtension, hflip } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlays = currentVideo[activeExtension].overlays;

      const hero = overlays.find((overlay) => overlay.id === id);

      hero.hflip = hflip;

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_ANIMATION_CHANGED: {
      const { id, activeExtension, animation } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];
      const overlays = currentVideo[activeExtension].overlays;

      const hero = overlays.find((overlay) => overlay.id === id);

      hero.animation = animation;

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__HERO_LOOP_CHANGED: {
      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];

      // change 'loop' option for all overlays in the video
      const heroesLandscape = currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays.filter((overlay) => overlay.type === OVERLAY_TYPES.HERO_OVERLAY);
      const heroesSquare = currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays.filter((overlay) => overlay.type === OVERLAY_TYPES.HERO_OVERLAY);
      const heroesPortrait = currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays.filter((overlay) => overlay.type === OVERLAY_TYPES.HERO_OVERLAY);
      const heroesFourOnFive = currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays.filter((overlay) => overlay.type === OVERLAY_TYPES.HERO_OVERLAY);

      [...heroesLandscape, ...heroesSquare, ...heroesPortrait, ...heroesFourOnFive].forEach((hero) => {
        hero.isLoop = payload;
      });

      return {
        ...state,
        videoArray,
      };
    }

    // effects
    case OVERLAYS_SETTINGS__EFFECT_SELECTED: {
      const { activeExtension, overlay } = payload;

      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];

      currentVideo[activeExtension].overlays = [
        ...currentVideo[activeExtension].overlays.filter((overlay) => overlay.type !== OVERLAY_TYPES.EFFECT_OVERLAY),
        overlay,
      ];

      return {
        ...state,
        videoArray,
      };
    }

    // lower thirds
    case OVERLAYS_SETTINGS__LOWER_THIRD_SELECTED: {
      const { landscape, square, portrait, fourOnFive } = cloneDeep(payload);
      const videoArray = cloneDeep(state.videoArray);

      videoArray.forEach((video) => {
        const overlaysLandscape = video[VIDEO_EXTENSIONS.LANDSCAPE].overlays;
        const overlaysSquare = video[VIDEO_EXTENSIONS.SQUARE].overlays;
        const overlaysPortrait = video[VIDEO_EXTENSIONS.PORTRAIT].overlays;
        const overlaysFourOnFive = video[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays;

        const lowerThirdLandscape = overlaysLandscape.find((overlay) => overlay.type === OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const lowerThirdSquare = overlaysSquare.find((overlay) => overlay.type === OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const lowerThirdPortrait = overlaysPortrait.find((overlay) => overlay.type === OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const lowerThirdFourOnFive = overlaysFourOnFive.find((overlay) => overlay.type === OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

        const filteredLandscape = overlaysLandscape.filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const filteredSquare = overlaysSquare.filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const filteredPortrait = overlaysPortrait.filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);
        const filteredFourOnFive = overlaysFourOnFive.filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

        if (lowerThirdLandscape && lowerThirdSquare && lowerThirdPortrait && lowerThirdFourOnFive) {
          // if overlay is exist than save some setting as they were
          const newLandscape = {
            ...lowerThirdLandscape,
            ...landscape,
          };

          const newSquare = {
            ...lowerThirdSquare,
            ...square,
          };

          const newPortrait = {
            ...lowerThirdPortrait,
            ...portrait,
          };

          const newFourOnFive = {
            ...lowerThirdFourOnFive,
            ...fourOnFive,
          };

          video[VIDEO_EXTENSIONS.LANDSCAPE].overlays = [...filteredLandscape, newLandscape];
          video[VIDEO_EXTENSIONS.SQUARE].overlays = [...filteredSquare, newSquare];
          video[VIDEO_EXTENSIONS.PORTRAIT].overlays = [...filteredPortrait, newPortrait];
          video[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays = [...filteredFourOnFive, newFourOnFive];
        }
      });

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__LOWER_THIRD_DELETED: {
      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[payload];

      currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays = currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays
        .filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

      currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays = currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays
        .filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

      currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays = currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays
        .filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

      currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays = currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays
        .filter((overlay) => overlay.type !== OVERLAY_TYPES.LOWER_THIRDS_OVERLAY);

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__LOWER_THIRD_CREATED: {
      const {
        landscape: newLandscape,
        square: newSquare,
        portrait: newPortrait,
        fourOnFive: newFourOnFive,
      } = cloneDeep(payload);
      const videoArray = cloneDeep(state.videoArray);
      const currentVideo = videoArray[state.videoIndex];

      currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays = [...currentVideo[VIDEO_EXTENSIONS.LANDSCAPE].overlays, newLandscape];
      currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays = [...currentVideo[VIDEO_EXTENSIONS.SQUARE].overlays, newSquare];
      currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays = [...currentVideo[VIDEO_EXTENSIONS.PORTRAIT].overlays, newPortrait];
      currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays = [...currentVideo[VIDEO_EXTENSIONS.FOUR_ON_FIVE].overlays, newFourOnFive];

      return {
        ...state,
        videoArray,
      };
    }

    case OVERLAYS_SETTINGS__LOWER_THIRD_COMMON_SETTINGS_CHANGED: {
      return {
        ...state,
        lowerThirdsCommonConfig: {
          ...state.lowerThirdsCommonConfig,
          ...payload,
        },
      };
    }

    case OVERLAYS_SETTINGS__LOWER_THIRD_SETTINGS_CHANGED: {
      let videoArray = cloneDeep(state.videoArray);

      videoArray = payload;

      return {
        ...state,
        videoArray,
      };
    }

    default:
      return state;
  }
};

export default VideoOverlaysSettingsReducer;
