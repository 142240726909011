import { Record } from 'immutable';
import {
  SET_IS_MOBILE_STATUS,
  SET_ACTIVE_PLAYER_ID,
  RESTRICT_ACCESS,
} from './actionTypes';

const InitialState = Record({
  isMobile: false,
  activePlayerId: '',
  isRestricted: false,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {

    case SET_IS_MOBILE_STATUS:
      return state.set('isMobile', action.isMobile);

    case SET_ACTIVE_PLAYER_ID:
      return state.set('activePlayerId', action.activePlayerId);

    case RESTRICT_ACCESS:
      return state.set('isRestricted', action.payload.isRestricted);

    default: {
      return state;
    }
  }
}
