import streamSaver from 'streamsaver';
import { addLocalTimeHeader, getToken } from 'utils/FeathersClient';
import { API_PREFIX } from 'constants/General';
import { downloadCollection } from '../../Collections/dowloadCollectionHelpers';


export const downloadAssetsService = (data, onDownloadProgress= null) => {
  return getToken()
    .then((token) => fetch(`${API_PREFIX}game-asset/docs/download`, {
      method: 'POST',
      body: JSON.stringify(data),
      responseType: 'blob',
      headers: addLocalTimeHeader({
        'Authorization': token,
        'Content-Type': 'application/json',
      }),
      onDownloadProgress,
    }));
};

export const downloadAssets = async (fileIds, name, collectionId, collectionHash) => {

  if (collectionId) {
    downloadCollection(collectionId, collectionHash, name, fileIds);
    return;
  }

  const data = {
    docIds: fileIds,
  };

  const response = await downloadAssetsService(data, null);

  const fileStream = streamSaver.createWriteStream(name);
  const readableStream = response.body;

  if (window.WritableStream && readableStream.pipeTo) {
    await readableStream.pipeTo(fileStream);
    return;
  }

  // Write (pipe) manually
  window.writer = fileStream.getWriter();
  const reader = readableStream.getReader();

  const pump = async () => {
    const result = await reader.read();

    if (result.done) {
      window.writer.close();
    }
    else {
      await window.writer.write(result.value);
      await pump();
    }
  };

  await pump();
};