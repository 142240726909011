import AppSearch from 'services/AppSearch';
import VideoProjectService from 'services/VideoProject';
import Game from 'services/Game';
import { ASSET_ATTRIBUTES } from 'constants/CreativeTool';
import { ASSET_NAMES_IN_REDUCER } from '../reducer';
import {
  getGamesSquare4x5FramesAnimated,
  getGamesSquare4x5FramesStatic,
} from 'components/Laboratory/VideoResize/VideoResize.redux/Actions/framesActions';
import {
  UNSUITABLE_STRUCTURE,
  GET_PROJECT_FAIL,
  GET_GAME_ASSETS_FAIL,
  GET_AUDIOS_SUCCESS,
  GET_VOICE_OVER_SUCCESS,
  GET_VOICE_OVER_END_CARDS_SUCCESS,
  GET_COLORS_SUCCESS,
  GET_END_CARDS_SUCCESS,
  GET_TITLE_CARDS_SUCCESS,
  GET_FONTS_SUCCESS,
  GET_APP_SEARCH_FONTS_SUCCESS,
  GET_LOWER_THIRDS_LANDSCAPE_SUCCESS,
  GET_LOWER_THIRDS_SQUARE_SUCCESS,
  GET_LOWER_THIRDS_PORTRAIT_SUCCESS,
  GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS,
  GET_IMAGE_OVERLAYS_SUCCESS,
  GET_MAIN_VIDEOS_SUCCESS,
  SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS,
  SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
  SET_PROJECT_COMPONENTS,
  GET_LIVE_ACTION_VIDEOS_SUCCESS,
  GET_EFFECTS_LANDSCAPE_SUCCESS,
  GET_EFFECTS_SQUARE_SUCCESS,
  GET_EFFECTS_PORTRAIT_SUCCESS,
  GET_EFFECTS_FOUR_ON_FIVE_SUCCESS,
  GET_GENERAL_GAME_STORAGE_UUID_SUCCESS,
  SET_MAIN_VIDEOS_DATA,
  CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT,
} from '../actionTypes';


export const generalGameName = 'Tilting Point Corporate';

const getProjectFail = (state) => {
  return {
    type: GET_PROJECT_FAIL,
    payload: state,
  };
};

export const getProjectById = (projectId) => (dispatch) => {
  return VideoProjectService.getVideoProject(projectId)
    .then((response) => {
      const existingMainVideosLength = response.metadata.videos?.mains?.filter((mainVideo) => mainVideo !== null).length;
      dispatch(getProjectFail(false));

      if (existingMainVideosLength > 3) {
        dispatch(setUnsuitableStructure());
      } else {
        dispatch(setProjectComponents(response));
        dispatch(getGeneralGameId(response.game.name));
        dispatch(getLiveActionVideos(response.game.name));
        dispatch(getGamesEndCards(response.game.name));
        dispatch(getGamesLowerThirdsLandscape(response.game.name));
        dispatch(getGamesLowerThirdsSquare(response.game.name));
        dispatch(getGamesLowerThirdsPortrait(response.game.name));
        dispatch(getGamesLowerThirdsFourOnFive(response.game.name));
        dispatch(getGamesEffectsLandscape(response.game.name));
        dispatch(getGamesEffectsSquare(response.game.name));
        dispatch(getGamesEffectsPortrait(response.game.name));
        dispatch(getGamesEffectsFourOnFive(response.game.name));
        dispatch(getGamesImageOverlays(response.game.name));
        dispatch(getGamesAudios(response.game.name));
        dispatch(getGamesSquarePortraitFramesStatic(response.game.name));
        dispatch(getGamesSquarePortraitFramesAnimated(response.game.name));
        dispatch(getGamesSquare4x5FramesStatic(response.game.name));
        dispatch(getGamesSquare4x5FramesAnimated(response.game.name));
        dispatch(getGamesFontsFromAppSearch());

        if (response.game.metadata !== null) {
          dispatch(getGamesColorsSuccess(response.game.metadata.colors));
          dispatch(getGamesFontsSuccess(response.game.metadata.fonts));
        } else {
          dispatch(getGamesColorsSuccess([]));
          dispatch(getGamesFontsSuccess([]));
        }
      }

    })
    .catch((error) => {
      dispatch(getProjectFail(true));
      throw error;
    });
};

const setUnsuitableStructure = () => {
  return {
    type: UNSUITABLE_STRUCTURE,
  };
};

const setProjectComponents = (projectComponents) => {
  return {
    type: SET_PROJECT_COMPONENTS,
    payload: projectComponents,
  };
};

export const getGameDataForRandomizer = (gameName) => (dispatch) => {
  dispatch(getLiveActionVideos(gameName));
  dispatch(getGamesEndCards(gameName));
  dispatch(getGamesAudios(gameName));
  dispatch(getGamesSquarePortraitFramesStatic(gameName));
  dispatch(getGamesSquarePortraitFramesAnimated(gameName));
  dispatch(getGamesSquare4x5FramesStatic(gameName));
  dispatch(getGamesSquare4x5FramesAnimated(gameName));
};

export const getGameAssetsFail = (error, assetName) => {
  return {
    type: GET_GAME_ASSETS_FAIL,
    payload: {
      error,
      assetName,
    },
  };
};

/**
 * Get storage_uuid of General game
 */
const getGeneralGameId = (gamaName) => (dispatch) => {
  return Game.getGames({text: generalGameName})
    .then((response) => {
      if (response.raw.length) {
        dispatch(getGeneralGameStorageUuid(response.raw[0].id, gamaName));
      } else {
        dispatch(getGamesVoiceOver(gamaName));
        dispatch(getGamesVoiceOverEndCards(gamaName));
      }
    })
    .catch((error) => {
      dispatch(getGamesVoiceOver(gamaName));
      dispatch(getGamesVoiceOverEndCards(gamaName));
      throw error;
    });
};

const getGeneralGameStorageUuidSuccess = (storageUuid) => {
  return {
    type: GET_GENERAL_GAME_STORAGE_UUID_SUCCESS,
    payload: storageUuid,
  };
};

const getGeneralGameStorageUuid = (generalGameId, gamaName) => (dispatch) => {
  return Game.getGameById(generalGameId)
    .then((response) => {
      dispatch(getGeneralGameStorageUuidSuccess(response.storage.uuid));
    })
    .catch((error) => {
      throw error;
    })
    .finally(() => {
      dispatch(getGamesVoiceOver(gamaName));
      dispatch(getGamesVoiceOverEndCards(gamaName));
    });
};

/**
 * Get title cards
 */
const getGamesTitleCardsSuccess = (titleCards) => {
  return {
    type: GET_TITLE_CARDS_SUCCESS,
    payload: titleCards,
  };
};

const getGamesTitleCards = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.INTRO],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesTitleCardsSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.INTRO, ASSET_NAMES_IN_REDUCER.TITLE_CARDS));
      dispatch(getGameAssetsFail(null, ASSET_NAMES_IN_REDUCER.STORAGE_INTRO_VIDEOS));
      throw error;
    })
    .finally(() => {
      dispatch(getGamesMainVideos(gameName));
    });
};

/**
 * Get end cards
 */
const getGamesEndCardsSuccess = (endCards) => {
  return {
    type: GET_END_CARDS_SUCCESS,
    payload: endCards,
  };
};

const getGamesEndCards = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.END_CARD],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesEndCardsSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.END_CARD, ASSET_NAMES_IN_REDUCER.END_CARDS));
      throw error;
    });
};

/**
 * Get fonts
 */
const getGamesFontsSuccess = (fonts) => {
  return {
    type: GET_FONTS_SUCCESS,
    payload: fonts,
  };
};

/**
 * Get colors
 */
const getGamesColorsSuccess = (colors) => {
  return {
    type: GET_COLORS_SUCCESS,
    payload: colors,
  };
};

/**
 * Get Lower thirds Landscape
 */
const getGamesLowerThirdsLandscapeSuccess = (lowerThirdsLandscape) => {
  return {
    type: GET_LOWER_THIRDS_LANDSCAPE_SUCCESS,
    payload: lowerThirdsLandscape,
  };
};

const getGamesLowerThirdsLandscape = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.LOWER_THIRD.LANDSCAPE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLowerThirdsLandscapeSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.LOWER_THIRD.LANDSCAPE, ASSET_NAMES_IN_REDUCER.LOWER_THIRDS_LANDSCAPE));
      throw error;
    });
};

/**
 * Get Lower thirds Square
 */
const getGamesLowerThirdsSquareSuccess = (lowerThirdsSquare) => {
  return {
    type: GET_LOWER_THIRDS_SQUARE_SUCCESS,
    payload: lowerThirdsSquare,
  };
};

const getGamesLowerThirdsSquare = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.LOWER_THIRD.SQUARE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLowerThirdsSquareSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.LOWER_THIRD.SQUARE, ASSET_NAMES_IN_REDUCER.LOWER_THIRDS_SQUARE));
      throw error;
    });
};

/**
 * Get Lower thirds Portrait
 */
const getGamesLowerThirdsPortraitSuccess = (lowerThirdsPortrait) => {
  return {
    type: GET_LOWER_THIRDS_PORTRAIT_SUCCESS,
    payload: lowerThirdsPortrait,
  };
};

const getGamesLowerThirdsPortrait = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.LOWER_THIRD.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLowerThirdsPortraitSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.LOWER_THIRD.PORTRAIT, ASSET_NAMES_IN_REDUCER.LOWER_THIRDS_PORTRAIT));
      throw error;
    });
};

/**
 * Get Lower thirds 4x5
 */
const getGamesLowerThirdsFourOnFiveSuccess = (lowerThirdsFourOnFive) => {
  return {
    type: GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS,
    payload: lowerThirdsFourOnFive,
  };
};

const getGamesLowerThirdsFourOnFive = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.LOWER_THIRD.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesLowerThirdsFourOnFiveSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.LOWER_THIRD.FOUR_ON_FIVE, ASSET_NAMES_IN_REDUCER.LOWER_THIRDS_FOUR_ON_FIVE));
      throw error;
    });
};

/**
 * Get Effects Landscape
 */
const getGamesEffectsLandscapeSuccess = (effectsLandscape) => {
  return {
    type: GET_EFFECTS_LANDSCAPE_SUCCESS,
    payload: effectsLandscape,
  };
};

export const getGamesEffectsLandscape = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.EFFECT.LANDSCAPE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesEffectsLandscapeSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.EFFECT.LANDSCAPE, ASSET_NAMES_IN_REDUCER.EFFECTS_LANDSCAPE));
      throw error;
    });
};

/**
 * Get Effects Square
 */
const getGamesEffectsSquareSuccess = (effectsSquare) => {
  return {
    type: GET_EFFECTS_SQUARE_SUCCESS,
    payload: effectsSquare,
  };
};

export const getGamesEffectsSquare = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.EFFECT.SQUARE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesEffectsSquareSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.EFFECT.SQUARE, ASSET_NAMES_IN_REDUCER.EFFECTS_SQUARE));
      throw error;
    });
};

/**
 * Get Effects Portrait
 */
const getGamesEffectsPortraitSuccess = (effectsPortrait) => {
  return {
    type: GET_EFFECTS_PORTRAIT_SUCCESS,
    payload: effectsPortrait,
  };
};

export const getGamesEffectsPortrait = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.EFFECT.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesEffectsPortraitSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.EFFECT.PORTRAIT, ASSET_NAMES_IN_REDUCER.EFFECTS_PORTRAIT));
      throw error;
    });
};

/**
 * Get Effects 4x5
 */
const getGamesEffectsFourOnFiveSuccess = (effectsFourOnFive) => {
  return {
    type: GET_EFFECTS_FOUR_ON_FIVE_SUCCESS,
    payload: effectsFourOnFive,
  };
};

export const getGamesEffectsFourOnFive = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.EFFECT.FOUR_ON_FIVE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesEffectsFourOnFiveSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.EFFECT.FOUR_ON_FIVE, ASSET_NAMES_IN_REDUCER.EFFECTS_FOUR_ON_FIVE));
      throw error;
    });
};

/**
 * Get image overlays (heroes)
 */
const getGamesImageOverlaysSuccess = (imageOverlays) => {
  return {
    type: GET_IMAGE_OVERLAYS_SUCCESS,
    payload: imageOverlays,
  };
};

const getGamesImageOverlays = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.OVERLAY],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesImageOverlaysSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.OVERLAY, ASSET_NAMES_IN_REDUCER.IMAGE_OVERLAYS));
      throw error;
    });
};

/**
 * Get audio accompaniments
 */
const getGamesAudiosSuccess = (audios) => {
  return {
    type: GET_AUDIOS_SUCCESS,
    payload: audios,
  };
};

const getGamesAudios = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.AUDIO],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesAudiosSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.AUDIO, ASSET_NAMES_IN_REDUCER.AUDIOS));
      throw error;
    });
};

/**
 * Get voiceover
 */
const getGamesVoiceOverSuccess = (voiceOver) => {
  return {
    type: GET_VOICE_OVER_SUCCESS,
    payload: voiceOver,
  };
};

const getGamesVoiceOver = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.VOICE_OVER],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesVoiceOverSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.VOICE_OVER, ASSET_NAMES_IN_REDUCER.VOICE_OVER));
      throw error;
    });
};

/**
 * Get voiceover for End cards
 */
const getGamesVoiceOverEndCardsSuccess = (voiceOver) => {
  return {
    type: GET_VOICE_OVER_END_CARDS_SUCCESS,
    payload: voiceOver,
  };
};

const getGamesVoiceOverEndCards = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [generalGameName, gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.VOICE_OVER_END_CARD],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesVoiceOverEndCardsSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.VOICE_OVER_END_CARD, ASSET_NAMES_IN_REDUCER.VOICE_OVER_END_CARDS));
      throw error;
    });
};

/**
 * Get fonts from App search
 */
const getGamesFontsFromAppSearchSuccess = (fonts) => (dispatch) => {
  dispatch({
    type: GET_APP_SEARCH_FONTS_SUCCESS,
    payload: fonts,
  });
};

const getGamesFontsFromAppSearch = () => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FONT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesFontsFromAppSearchSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FONT, ASSET_NAMES_IN_REDUCER.FONTS));
      dispatch(getGameAssetsFail(null, ASSET_NAMES_IN_REDUCER.FLAT_FONTS));
      throw error;
    });
};

/**
 * Get main videos
 */
const getGamesMainVideosSuccess = (mainVideos) => {
  return {
    type: GET_MAIN_VIDEOS_SUCCESS,
    payload: mainVideos,
  };
};

const getGamesMainVideos = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.MAIN_FOOTAGE],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesMainVideosSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.MAIN_FOOTAGE, ASSET_NAMES_IN_REDUCER.FLAT_MAIN_VIDEOS));
      throw error;
    });
};

/**
 * Get Static frames
 */
const getGamesSquarePortraitFramesStaticSuccess = (framesStatic) => {
  return {
    type: SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS,
    payload: framesStatic,
  };
};

export const getGamesSquarePortraitFramesStatic = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquarePortraitFramesStaticSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.STATIC.SQUARE.PORTRAIT, ASSET_NAMES_IN_REDUCER.FRAMES_PORTRAIT_STATIC));
      throw error;
    });
};

/**
 * Get Animated frames
 */
const getGamesSquarePortraitFramesAnimatedSuccess = (framesStatic) => {
  return {
    type: SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
    payload: framesStatic,
  };
};

export const getGamesSquarePortraitFramesAnimated = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'asset_game_name',
        values: [gameName],
      },
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.PORTRAIT],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getGamesSquarePortraitFramesAnimatedSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.FRAME.ANIMATED.SQUARE.PORTRAIT, ASSET_NAMES_IN_REDUCER.FRAMES_PORTRAIT_ANIMATED));
      throw error;
    });
};

/**
 * Get Live action videos
 */
const getLiveActionVideosSuccess = (liveActionVideos) => {
  return {
    type: GET_LIVE_ACTION_VIDEOS_SUCCESS,
    payload: liveActionVideos,
  };
};

export const getLiveActionVideos = (gameName) => (dispatch) => {
  const options = {
    filters: [
      {
        field: 'attribute_type',
        values: [ASSET_ATTRIBUTES.LIVE_ACTION],
        type: 'any',
      },
    ],
    page: {
      size: 999,
      current: 1,
    },
  };
  return AppSearch.getAssets(options)
    .then((response) => {
      dispatch(getLiveActionVideosSuccess(response.results));
    })
    .catch((error) => {
      dispatch(getGameAssetsFail(ASSET_ATTRIBUTES.LIVE_ACTION, ASSET_NAMES_IN_REDUCER.STORAGE_LIVE_ACTION_VIDEOS));
      throw error;
    })
    .finally(() => {
      dispatch(getGamesTitleCards(gameName));
    });
};

/**
 * Other init actions
 */
export const setMainVideosData = (mainVideosData) => {
  return {
    type: SET_MAIN_VIDEOS_DATA,
    payload: mainVideosData,
  };
};

export const cleanUpVideoProjectOnUnmount = () => {
  return {
    type: CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT,
  };
};
