import { v4 as uuidv4 } from 'uuid';
import FontModel from '../Models/AdminPanelData/FontModel';
import GameModel from '../Models/AdminPanelData/GameModel';
import ContainerBannerItemModel from '../Models/AdCreate/ContainerBannerItemModel';
import TextBannerItemModel from '../Models/AdCreate/TextBannerItemModel';
import { defaultContainerTemplateAdCreate } from '../laboratoryConstants';


/**
 * Helper class for Admin Panel data transformation
 */
class AdminPanelDataTransformation {
  constructor(data) {
    this.data = data;
  }

  totalPages() {
    return Math.ceil(this.data.total / this.data.limit);
  }

  uniqueFonts () {
    const transformedAllFonts = this.data.map((font) => new FontModel(font));

    const uniqueAllFonts = [];

    transformedAllFonts.filter((font) => {
      const i = uniqueAllFonts.findIndex((uniqueFont) => uniqueFont.name === font.name);
      if (i === -1) {
        uniqueAllFonts.push(font);
      }
      return null;
    });

    return uniqueAllFonts.sort((a, b) => b - a);
  }

  uniqueColors () {
    const colorsSet = new Set();
    const colorsArray = this.data.map((game) => game.colors).flat();
    colorsArray.forEach((color) => colorsSet.add(color));
    return Array.from(colorsSet).sort((a, b) => b - a);
  }

  games () {
    return this.data.sort((a, b) => b.id - a.id).map((game) => {
      let metadata;
      if (!game.metadata) {
        metadata = {
          fonts: [],
          colors: [],
        };
      } else {
        metadata = game.metadata;
      }

      return new GameModel(metadata, game);
    });
  }

  textTemplates () {
    return this.data.map((textTemplate) => {
      if (textTemplate.nestedBannerItems) {
        return new ContainerBannerItemModel(textTemplate);
      } else {
        return {
          ...defaultContainerTemplateAdCreate,
          id: textTemplate.id,
          nestedBannerItems: [
            new TextBannerItemModel(textTemplate, uuidv4()),
          ],
        };
      }
    });
  }

}

export default AdminPanelDataTransformation;
