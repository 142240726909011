/**
 * @param {number} id
 * @param {number} width
 * @param {number} height
 * @param {number} maxFileSize
 * @param {boolean} blocked
 */
class SizeOfSocialNetworkModel {
  constructor(data) {
    this.id = data.id;
    this.width = data.width;
    this.height = data.height;
    this.maxFileSize = data.maxFileSize;
    this.blocked = data.blocked;
  }
}

export default SizeOfSocialNetworkModel;
