import axios from 'axios';
import moment from 'moment';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';

import { API_PREFIX, HEADER_CONSTANTS } from '../constants/General';

const feathersClient = feathers();

feathersClient
  .configure(rest().axios(axios))
  .configure(auth({
    storage: window.localStorage,
    storageKey: 'feathers-jwt',
  }));

function authHook(hook) {
  const isRequestToApi = (`/${hook.path}`).includes(API_PREFIX);

  if (isRequestToApi) {
    feathersClient.authentication.getAccessToken()
      .then((accessToken) => {
        hook.params.headers = {
          ...hook.params.headers,
          Authorization: accessToken,
        };
      });
  }
}

const localTimeHook = (hook) => {
  hook.params.headers = {
    ...hook.params.headers,
    [HEADER_CONSTANTS.LOCAL_TIME]: moment().format('YYYY-MM-DD HH:mm'),
  };
};

feathersClient.mixins.push((service) => {
  service.hooks({
    before: [authHook, localTimeHook],
  });
});

export const getToken = () => feathersClient.authentication.getAccessToken();

export const addLocalTimeHeader = (headers) => {
  return {
    ...headers,
    [HEADER_CONSTANTS.LOCAL_TIME]: moment().format('YYYY-MM-DD HH:mm'),
  };
};

export default feathersClient;
