import React, { useEffect, useState } from 'react';
import GameService from 'services/Game';
import { ReactComponent as EditIcon } from 'assets/svg/ic-edit.svg';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Preloader from 'components/UIComponents/Preloader';
import GameEditForm from '../GameEditForm/index';
import Modal from 'components/UIComponents/Modal';

const GameSettings = ({gameId}) => {
  const [game, setGame] = useState({});
  const [loading, setLoading] = useState(false);
  const [jiraProjectLoading, setJiraProjectLoading] = useState(false);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [jiraProject, setJiraProject] = useState();
  const [isEditGameModalOpen, setIsEditGameModalOpen] = useState(false);
  const getAllData = async () => {
    setLoading(true);
    setJiraProjectLoading(true);
    const game = await GameService.getGameById(gameId);
    setGame(game);
    setLoading(false);
    const jiraProjects = await GameService.getUnassociatedJiraProjects(gameId);
    setJiraProjects(jiraProjects);
    setJiraProject(_.find(jiraProjects, ({id}) => id === game.jiraProjectId)?.name);
    setJiraProjectLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, [gameId]);

  const onClose = () => {
    setIsEditGameModalOpen(false);
    getAllData();
  };

  const SOCIAL_MEDIAS = {
    meta: 'Meta',
    tiktok: 'TikTok',
  };

  const getSocialMediaRow = (socialMedia={}) => {
    const arrayOfLabels = [];
    Object.keys(socialMedia).forEach((key) => {
      if (SOCIAL_MEDIAS[key] && socialMedia[key]) {
        arrayOfLabels.push(SOCIAL_MEDIAS[key]);
      }
    });
    return arrayOfLabels.join(', ');
  };

  return (
    <>
      <Modal open={isEditGameModalOpen} onClose={() => setIsEditGameModalOpen(false)} >
        <GameEditForm
          selectedGameId={gameId}
          onClose={() => onClose()} 
          jiraProjects={jiraProjects}
        />
      </Modal>
      {loading ? 
        <Preloader /> :
        <div className='automated-uploads__settings'>
          <div className='game-settings__image-wrapper'>
            <img src={game.imageUrl} />
          </div>
          <div className='game-settings__data'>
            <div className='game-settings__header'>
              <h1 className='header-with-line__text'>{game.name}</h1>
              <button disabled={jiraProjectLoading} className='game-settings__edit-button' onClick={() => setIsEditGameModalOpen(true)}>
                <EditIcon className='edit-icon' />
                <span>EDIT</span>
              </button>
            </div>
            <div className='game-settings__info' >
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Game Title:</span>
                <span className='game-settings__info-row__value'>{game.name}</span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Jira Project:</span>
                <span className='game-settings__info-row__value'>
                  {jiraProjectLoading ? <Preloader additionalClass='small-loader' /> : jiraProject}  
                </span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Studio:</span>
                <span className='game-settings__info-row__value'>{_.get(game, 'gameStudio.title')}</span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Game Category:</span>
                <span className='game-settings__info-row__value'>{_.get(game, 'gameLevel.title')}</span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Shared to Social Media:</span>
                <span className='game-settings__info-row__value'>
                  {getSocialMediaRow(_.get(game, 'socialMedia') || {})}
                </span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>TikTok Advertiser ID:</span>
                <span className='game-settings__info-row__value'>
                  {game.socialMedia?.tiktokAdvertiserId}
                </span>
              </div>
              <div className='game-settings__info-row'>
                <span className='game-settings__info-row__label'>Categories:</span>
                <div className='game-settings__info-row__tags' >
                  {_.get(game, 'genres')?.map?.(({genre, id}) => <span key={id} className='game-settings__info-row__tag' >{genre}</span>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </> 
  );
};

GameSettings.propTypes = {
  gameId: PropTypes.string.isRequired,
};

export default GameSettings;
