import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import styles from './styles.module.scss';

const classes = () => ({
  root: {
    padding: '10px',
    width: '100%',
  },
  inputText: {
    fontFamily: 'Consolas,monaco,monospace',
    fontSize: '14px',
  },
});

const GameSettingsDetails = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingJson, setIsEditingJson] = useState(false);
  const [tabIdx, setTabIdx] = useState(0);
  const [error, setError] = useState('');

  const {
    game,
    configKeyObj,
    configKeyJson,
    handleUpdateConfig,
    handleConfigJsonChange,
    handleConfigFieldChange,
    handleCurrentGameEdit,
    classes,
  } = props;

  const handleConfigTabChange = (event, value) => {
    setTabIdx(value);
  };

  const handleUpdate = () => {
    handleUpdateConfig(JSON.stringify(configKeyObj));

    setIsEditing(false);
  };

  const handleValidateJSONAndUpdate = () => {
    try {
      const json = JSON.stringify(JSON.parse(configKeyJson));
      handleUpdateConfig(json);

      setIsEditing(false);
    } catch (e) {
      setError('Invalid JSON');
    }
  };

  const handleConfigKey = () => {
    handleCurrentGameEdit();

    setIsEditing(true);
  };

  const handleEditJSON = () => {
    handleCurrentGameEdit();

    setIsEditingJson(true);
    setError('');
  };

  const flattenObject = (obj, path) => {
    let flattened = {};

    Object.keys(obj).forEach((key) => {
      const newPath = path === ''
        ? key
        : `${path}/${key}`;

      if (typeof obj[key] === 'object' && obj[key] !== null) {
        flattened = {
          ...flattened,
          ...flattenObject(obj[key], newPath),
        };
      } else {
        flattened[newPath] = obj[key];
      }
    });

    return flattened;
  };

  const flatConfigs = {};

  Object.keys(configKeyObj).forEach((key) => {
    flatConfigs[key] = flattenObject(configKeyObj[key], '');
  });

  return game
    ? (
      <Paper className={classes.root}>
        <div className={styles.gameInfo}>
          <div><strong>{`Game Id: ${game.gameId}`}</strong></div>
          <div><strong>{`Game Name: ${game.gameName}`}</strong></div>
          <div><strong>{`System Id: ${game.systemId}`}</strong></div>
        </div>
        <Dialog
          fullWidth
          maxWidth='lg'
          open={isEditing}
          onClose={() => setIsEditing(false)}
        >
          <DialogTitle id='form-dialog-title'>{game.gameName}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              game id:
              {game.gameId}
            </DialogContentText>
            <DialogContentText>
              system id:
              {game.systemId}
            </DialogContentText>
            {configKeyObj && Object.keys(configKeyObj)
              .filter((key) => typeof configKeyObj[key] === 'string')
              .map((key) => (
                <TextField
                  key={key}
                  margin='dense'
                  label={key}
                  fullWidth
                  value={configKeyObj[key]}
                  onChange={(event) => handleConfigFieldChange(key, event.target.value)}
                />
              ))
            }
            <Tabs
              value={tabIdx}
              onChange={handleConfigTabChange}
              indicatorColor='primary'
              textColor='primary'
            >
              {configKeyObj && Object.keys(configKeyObj)
                .filter((key) => typeof configKeyObj[key] === 'object')
                .map((key) => <Tab key={key} label={key} />)
              }
            </Tabs>
            {configKeyObj && Object.keys(configKeyObj)
              .filter((key, index) => typeof configKeyObj[key] === 'object' && tabIdx === index)
              .map((key) => (
                <Typography
                  key={key}
                  component='div'
                  style={{
                    padding: 8 * 3,
                  }}
                >
                  {Object.keys(flatConfigs[key])
                    .filter((fieldKey) => typeof flatConfigs[key][fieldKey] === 'string')
                    .map((fieldKey) => (
                      <TextField
                        key={fieldKey}
                        margin='dense'
                        label={fieldKey}
                        fullWidth
                        value={flatConfigs[key][fieldKey]}
                        onChange={(event) => handleConfigFieldChange(`${key}/${fieldKey}`, event.target.value)}
                      />
                    ))}
                </Typography>
              )
              )}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isEditingJson}
          fullWidth
          maxWidth='lg'
          onClose={() => setIsEditingJson(false)}
        >
          <DialogTitle id='form-dialog-title'>{game.gameName}</DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                className: classes.inputText,
              }}
              value={configKeyJson}
              fullWidth
              onChange={handleConfigJsonChange}
              multiline
            />
          </DialogContent>
          <DialogActions>
            <span style={{
              color: 'red',
            }}>
              {error}
            </span>
            <Button
              variant='contained'
              color='primary'
              onClick={handleValidateJSONAndUpdate}
            >
              Update
            </Button>
            <Button onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <div className={styles.controls}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleConfigKey}
          >
            ConfigKey
          </Button>
          <Button onClick={handleEditJSON}>
            Edit JSON
            <EditIcon />
          </Button>
        </div>
        <div>
          <p className={styles.configKey}>
            {game.configKey}
          </p>
        </div>
      </Paper>
    )
    : (
      <Paper className={classes.root}>
        Not selected.
      </Paper>
    );
};

GameSettingsDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  game: PropTypes.shape({
    gameId: PropTypes.string.isRequired,
    gameName: PropTypes.string.isRequired,
    systemId: PropTypes.string.isRequired,
    configKey: PropTypes.string.isRequired,
  }),
  configKeyObj: PropTypes.object.isRequired,
  configKeyJson: PropTypes.string.isRequired,
  handleUpdateConfig: PropTypes.func.isRequired,
  handleConfigFieldChange: PropTypes.func.isRequired,
  handleConfigJsonChange: PropTypes.func.isRequired,
  handleCurrentGameEdit: PropTypes.func.isRequired,
};

export default withStyles(classes)(GameSettingsDetails);
