import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { childrenPropType } from 'types/general';
import { SIDEBAR_SECTIONS } from 'constants/General';


const ExternalUserPermissionWrapper = ({sectionName, children}) => {

  const {
    googleId,
    externalPermission,
  } = useSelector((state) => state.user.profile);

  if (!googleId) {
    return children;
  }

  const arrayOfTools = externalPermission ? Object.entries(externalPermission.tools) : [];

  if (sectionName === SIDEBAR_SECTIONS.OTHER) {
    if (arrayOfTools.every((tool) => !tool[1])) {
      return null;
    } else {
      return children;
    }
  }

  if (arrayOfTools.some((tool) => tool[0] === sectionName && tool[1])) {
    return children;
  } else {
    return null;
  }
};

ExternalUserPermissionWrapper.propTypes = {
  sectionName: PropTypes.string.isRequired,
  children: childrenPropType.isRequired,
};

export default ExternalUserPermissionWrapper;
