import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Zoom } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import StyledTooltip from 'components/UIComponents/StyledTooltip';
import { CREATIVE_SECTIONS } from 'constants/Creatives';

import { Info as InfoIcon } from '@material-ui/icons';

import './CreativeSectionsMenu.scss';


const CreativeSectionsMenu = ({ activeSection, onSelect }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className='creative-sections-menu'>
      <StyledTooltip title='Ticket info' placement='bottom' TransitionComponent={Zoom}>
        <div
          className={classnames('creative-sections-menu__button', {
            'active': Boolean(anchorEl) || activeSection !== CREATIVE_SECTIONS.STORAGE,
          })}
          onClick={handleOpen}
        >
          <InfoIcon className='creative-sections-menu__icon' />
        </div>
      </StyledTooltip>

      <Menu
        className='creative-sections-menu__drop-down'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.values(CREATIVE_SECTIONS).slice(0, -1).map((sectionName, sectionNameIndex) => (
          <MenuItem
            key={sectionName}
            className={classnames('creative-sections-menu__drop-down-item', {
              'active': activeSection === sectionName,
            })}
            onClick={() => {
              onSelect(sectionName);
              handleClose();
            }}
          >
            <p className='creative-sections-menu__drop-down-text'>
              {sectionNameIndex === 0 ? sectionName.slice(0, -7) : sectionName}
            </p>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CreativeSectionsMenu.propTypes = {
  activeSection: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default CreativeSectionsMenu;
