import React from 'react';

import './GoogleLogin.scss';


const GoogleLogin = () => {
  return (
    <a
      className='google-login'
      href={'/auth/google'}
    >
      <img
        src={process.env.PUBLIC_URL + './google_login.png'}
        className='google-login__image'
        alt='google-icon'
      />

      <p className='google-login__text'>
        sign in with google
      </p>
    </a>
  );
};

export default GoogleLogin;