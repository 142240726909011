import React, { useState, useMemo, useEffect } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

import './SearchBox.scss';
import classNames from 'classnames';


const SearchBox = ({
  searchValue,
  handleSearch,
  suggestions,
  width,
  rounded,
}) => {

  const [isDropDown, setIsDropDown] = useState(false);

  useEffect(() => {
    setIsDropDown(true);
  }, [searchValue]);

  const handleClearSearch = () => {
    handleSearch('');
  };

  const handleSuggestionClick = async (suggestion) => {
    await handleSearch(suggestion);
    setIsDropDown(false);
  };

  const suitableSuggestions = useMemo(() => {
    if (searchValue) {
     return suggestions.filter((suggestion) => suggestion.includes(searchValue));
    }

    return [];
  },
    [searchValue, suggestions]
  );

  return (
    <ClickAwayListener onClickAway={() => setIsDropDown(false)}>
      <div className='search-box'>
        <div
          className={classNames('search-box__trigger', {rounded})}
          style={{
            width: `${width}px`,
          }}
        >
          <input
            className='search-box__input'
            value={searchValue}
            placeholder='type to search'
            onChange={(event) => handleSearch(event.target.value)}
          />

          {searchValue ?
            <ClearIcon
              className='search-box__icon search-box__icon--clear'
              onClick={handleClearSearch}
            /> :
            <SearchIcon
              className='search-box__icon'
            />
          }
        </div>

        {!!suitableSuggestions.length && isDropDown &&
          <div className='search-box__dropDown'>
            {suitableSuggestions.map((suggestion) => (
              <div
                key={suggestion}
                className='search-box__suggestion'
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <p className='search-box__suggestion-text'>{suggestion}</p>
              </div>
            ))}
          </div>
        }
      </div>
    </ClickAwayListener>
  );
};

SearchBox.defaultProps = {
  suggestions: [],
  width: 200,
};

SearchBox.propTypes = {
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  rounded: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  width: PropTypes.number,
};

export default SearchBox;