
export const maxLengthSmallTextarea = 50;
export const maxLengthBigTextarea = 225;
export const initDataLimit = 20;

export const headerTitlesOrder = {
  studios: [
    'STUDIO_NAME',
    'CREATIVE_AMOUNT',
    'COST',
    'TICKETS',
    'PERCENTAGE',
    'ACTUAL_HOURS',
    'ESTIMATED_HOURS',
  ],
  games: [
    'LEVEL',
    'GAME_NAME',
    'CREATIVE_AMOUNT',
    'COST',
    'TICKETS',
    'PERCENTAGE',
    'ACTUAL_HOURS',
    'ESTIMATED_HOURS',
    'NOTES',
  ],
  posts: [
    'JIRA',
    'JIRA_ISSUE_NUMBER',
    'DEPARTMENT',
    'CREATIVE_FORMAT',
    'AMOUNT',
    'PROJECT_WORKFLOW',
    'ACTUAL_HOURS',
    'ESTIMATED_HOURS',
    'COST',
    'STATUS',
    'APPROVED',
    'NOTES',
    'POD',
    'REPORTER',
    'ASSIGNEE',
    'PROJECT_MANAGER',
    'DUE_DATE',
  ],
};

export const postActionOptions = {
  BILLABLE: 'BILLABLE',
  NON_BILLABLE: 'NON_BILLABLE',
  MOVE: 'MOVE',
  REMOVE: 'REMOVE',
};

export const postActionMessages = {
  BILLABLE: {
    text: 'Post status',
    description: 'Post was changed as billable',
  },
  NON_BILLABLE: {
    text: 'Post status',
    description: 'Post was changed as non billable',
  },
  MOVE: {
    text: 'Post due date',
    description: 'Post due date was successfully changed',
  },
  REMOVE: {
    text: 'Remove section',
    description: 'Section was successfully removed',
  },
};

export const DASHBOARD_SECTIONS = {
  STUDIO: 'studio',
  GAME: 'game',
  POST: 'post',
};
