import { ENTITIES_SORT_FIELD } from './mainSearchConstants';
import {
  FACETS,
  SEARCH_CATEGORY_ENGINES,
} from './mainSearchConfig';


export const getActiveSearchCategoryEngineName = (filters) => {
  return filters.find((filter) => filter.field === FACETS.SEARCH_CATEGORY)?.values.flat() ??
    [SEARCH_CATEGORY_ENGINES.GAME_ASSET, SEARCH_CATEGORY_ENGINES.ASSET];
};

export const handleSortEntities = (entityToSort, sortOption) => {
  if (sortOption.value === ENTITIES_SORT_FIELD.CREATED_AT) {
    if (sortOption.order === 'asc') {
      return [...entityToSort].sort((aEntity, bEntity) => new Date(aEntity.createdAt) - new Date(bEntity.createdAt));
    } else {
      return [...entityToSort].sort((aEntity, bEntity) => new Date(bEntity.createdAt) - new Date(aEntity.createdAt));
    }
  } else {
    if (sortOption.order === 'asc') {
      return [...entityToSort].sort((aEntity, bEntity) => aEntity.name?.localeCompare(bEntity.name));
    } else {
      return [...entityToSort].sort((aEntity, bEntity) => bEntity.name?.localeCompare(aEntity.name));
    }
  }
};