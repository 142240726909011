import ExampleModel from './ExampleModel';
import { convertMinutesToTime } from '../unitCostHelpers';

/**
 * @param {number} id
 * @param {object} category
 * @param {string} description
 * @param {string} generalInfo
 * @param {string} jiraFormat
 * @param {number} timeTotal
 * @param {object[]} examples
 */
class FormatModel {
  constructor(data) {
    this.id = data.id;
    this.category = data.category;
    this.description = data.description;
    this.generalInfo = data.generalInfo;
    this.jiraFormat = data.jiraFormat;
    this.timeTotal = convertMinutesToTime(data.timeTotal);
    this.examples = data.files ? data.files.map((file) => new ExampleModel(file, data.storage.uuid)) : [];
  }
}

export default FormatModel;
