import colors from '../colors.module.scss';
import gradients from '../gradients.module.scss';

export const MuiAccordion = {
  root: {
    border: '1px solid',
    borderColor: colors.lightGrey,
    '&:before': {
      content: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
};

export const MuiAccordionSummary = {
  root: {
    padding: '0.5em 1em 0.5em 1.25em',
    backgroundColor: colors.lightGrey,
    color: colors.darkGrey,
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',

    '&$expanded': {
      minHeight: 'unset',
    },

    '&:hover': {
      backgroundImage: gradients.darkBlueToLightBlue,
      color: 'white',

      '& .MuiAccordionSummary-expandIcon path': {
        fill: 'white',
      },
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    '& svg': {
      width: '0.75em',
      height: '0.75em',
      '& path': {
        fill: colors.blue,
      },
    },
    '&$expanded': {
      transform: 'rotate(0deg)',
    },
  },
};
