import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  MenuItem,
  MenuList,
  TextField,
} from '@material-ui/core';

import { configTemplate } from '../../../constants/GameSettings';

import styles from './styles.module.scss';

const classes = () => ({
  root: {
    maxHeight: '100vh',
    width: '100%',
  },
  newGameButton: {
    height: '50px',
  },
  searchText: {
    height: '50px',
  },
  menu: {
    width: '100%',
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
  },
});

const GameSettingsSidebar = (props) => {
  const [isNewGame, setIsNewGame] = useState(false);
  const [newGameName, setNewGameName] = useState('');
  const [newGameId, setNewGameId] = useState('');
  const [newGameIdAbbr, setNewGameIdAbbr] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const {
    gameSettings,
    classes,
    handleGameSelect,
    handleCreateGame,
  } = props;

  const onCreateGame = () => {
    const base64Str = btoa(JSON.stringify(configTemplate));
    handleCreateGame({
      gameId: newGameId,
      gameName: newGameName,
      gameIdAbbr: newGameIdAbbr,
      configKey: base64Str,
    });

    setIsNewGame(false);
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={isNewGame}
        onClose={() => setIsNewGame(false)}
      >
        <DialogTitle id='form-dialog-title'>New Game</DialogTitle>
        <DialogContent className={styles.newGameSettingsDialog}>
          <FormLabel>Game Id</FormLabel>
          <TextField
            autoFocus
            placeholder='Game Id'
            variant='outlined'
            fullWidth
            value={newGameId}
            onChange={(event) => setNewGameId(event.target.value)}
          />
          <FormLabel>Game Name</FormLabel>
          <TextField
            placeholder='Game Name'
            variant='outlined'
            fullWidth
            value={newGameName}
            onChange={(event) => setNewGameName(event.target.value)}
          />
          <FormLabel>Game Short Id</FormLabel>
          <TextField
            placeholder='Game Short Id'
            variant='outlined'
            fullWidth
            value={newGameIdAbbr}
            onChange={(event) => setNewGameIdAbbr(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={onCreateGame}
          >
            Create
          </Button>
          <Button onClick={() => setIsNewGame(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        className={classes.newGameButton}
        variant='contained'
        color='primary'
        fullWidth
        onClick={() => setIsNewGame(true)}
      >
        + New Game
      </Button>
      <TextField
        className={classes.searchText}
        placeholder='Search for Game'
        variant='outlined'
        value={searchFilter}
        fullWidth
        onChange={(event) => setSearchFilter(event.target.value)}
      />
      <MenuList component='nav' className={classes.menu}>
        {gameSettings
          .filter((item) => item.gameName.toLowerCase().includes(searchFilter))
          .sort((a, b) => a.gameName.toLowerCase().localeCompare(b.gameName.toLowerCase()))
          .map((item) => (
            <MenuItem
              key={item.systemId}
              button
              onClick={() => handleGameSelect(item.gameId)}
            >
              {item.gameName}
            </MenuItem>
          ))}
      </MenuList>
    </div>
  );
};

const gameSettingsPropType = PropTypes.shape({
  gameId: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  systemId: PropTypes.string.isRequired,
});

GameSettingsSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  gameSettings: PropTypes.arrayOf(gameSettingsPropType).isRequired,
  handleGameSelect: PropTypes.func.isRequired,
  handleCreateGame: PropTypes.func.isRequired,
};

export default withStyles(classes)(GameSettingsSidebar);
