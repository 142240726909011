import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class AppSearch {
  constructor() {
    this.assets = FeathersClient.service(`${API_PREFIX}app-search/search`);
  }

  getAssets(options) {
    return this.assets.find({ query: {
      query: {},
      options: options,
    }});
  }

}

export default new AppSearch();
