import { SET_ASSETS_TO_DOWNLOAD } from './actionsTypes';

const initState = {
  assetsToDownload: [],
  collectionId: null,
  collectionHash: null,
};

const assetsToDownloadReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_ASSETS_TO_DOWNLOAD:
      return {
        ...payload,
      };

    default:
      return state;
  }
};

export default assetsToDownloadReducer;