import { secondsToJiraHoursAndMinutes } from '../../creativeDashboardHelpers';
import { numberWithCommas } from 'utils/utils';


/**
 * @param {number} id
 * @param {string} name
 * @param {number} amount
 * @param {number} cost
 * @param {number} numberOfTickets
 * @param {number} percentage
 * @param {number} actualHours
 * @param {number} estimatedHours
 */
class StudioDashboardModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.title;
    this.amount = data.amount;
    this.cost = numberWithCommas(data.cost);
    this.numberOfTickets = numberWithCommas(data.ticketsTotal);
    this.percentage = data.percentage;
    this.actualHours = secondsToJiraHoursAndMinutes(data.timeSpentTotal);
    this.estimatedHours = secondsToJiraHoursAndMinutes(data.timeEstimateTotal);
  }
}

export default StudioDashboardModel;
