import {
  SET_PRIVATE_OR_PUBLIC_COLLECTIONS,

  GET_ALL_USER_COLLECTIONS_SUCCESS,

  GET_PRIVATE_COLLECTIONS_START,
  GET_PRIVATE_COLLECTIONS_SUCCESS,
  GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS,
  GET_PRIVATE_COLLECTIONS_FAIL,

  GET_PUBLIC_COLLECTIONS_START,
  GET_PUBLIC_COLLECTIONS_SUCCESS,
  GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS,
  GET_PUBLIC_COLLECTIONS_FAIL,

  SET_COLLECTIONS_SORT_OPTION,
  SET_COLLECTIONS_PAGING_STATUS,
  SET_COLLECTIONS_SEARCH_VALUE,

  SET_ASSETS_IDS_FOR_COLLECTION,
  SET_COLLECTION_CREATION_STATE,
  SET_COLLECTION_TO_EDIT,

  MANAGE_COLLECTION_START,
  MANAGE_COLLECTION_SUCCESS,
  MANAGE_COLLECTION_FAIL,
  MANAGE_COLLECTION_RESET,
} from '../actionsTypes';
import Collections from 'services/Collections';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  COLLECTION_SORT_OPTIONS,
  COLLECTIONS_LIMIT,
} from '../../collectionsConstants';
import { getCollectionAction } from './collectionActions';
import { getErrorMessage, str } from 'utils/utils';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const setPrivateOrPublicCollectionsAction = (isPrivateCollections) => {
  return {
    type: SET_PRIVATE_OR_PUBLIC_COLLECTIONS,
    payload: isPrivateCollections,
  };
};

export const getAllCollectionsAction = () => (dispatch, getState) => {
  const { id: userId } = getState().user?.profile;

  return Collections.getCollections({ limit: 999, sort: COLLECTION_SORT_OPTIONS[2], userId })
    .then((response) => {
      dispatch({
        type: GET_ALL_USER_COLLECTIONS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.get.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const getPrivateCollectionsAction = (query, isInfiniteScroll) => (dispatch, getState) => {
  const {
    sortOption: sort,
    isPaging,
    collectionsSearchValue: searchValue,
    privateCollections,
  } = getState().collections;

  const { skip: skipValueFromReducer } = privateCollections.success;

  const newQuery = {
    isPrivate: true,
    limit: COLLECTIONS_LIMIT,
    sort,
    searchValue,
    skip: isPaging ? skipValueFromReducer : 0,
    ...query,
  };

  if (!isInfiniteScroll) {
    dispatch({
      type: GET_PRIVATE_COLLECTIONS_START,
    });
  }

  return Collections.getCollections(newQuery)
    .then((response) => {
      if (isInfiniteScroll) {
        dispatch({
          type: GET_PRIVATE_COLLECTIONS_INFINITE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: GET_PRIVATE_COLLECTIONS_SUCCESS,
          payload: response,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PRIVATE_COLLECTIONS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.get.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const getPublicCollectionsAction = (query, isInfiniteScroll) => (dispatch, getState) => {
  const {
    sortOption: sort,
    isPaging,
    collectionsSearchValue: searchValue,
    publicCollections,
  } = getState().collections;

  const { skip: skipValueFromReducer } = publicCollections.success;

  const newQuery = {
    isPrivate: false,
    limit: COLLECTIONS_LIMIT,
    sort,
    searchValue,
    skip: isPaging ? skipValueFromReducer : 0,
    ...query,
  };

  if (!isInfiniteScroll) {
    dispatch({
      type: GET_PUBLIC_COLLECTIONS_START,
    });
  }

  return Collections.getCollections(newQuery)
    .then((response) => {
      if (isInfiniteScroll) {
        dispatch({
          type: GET_PUBLIC_COLLECTIONS_INFINITE_SUCCESS,
          payload: response,
        });
      } else {
        dispatch({
          type: GET_PUBLIC_COLLECTIONS_SUCCESS,
          payload: response,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PUBLIC_COLLECTIONS_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.get.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const setCollectionsSortOptionAction = (sortOption) => (dispatch) => {
  dispatch({
    type: SET_COLLECTIONS_SORT_OPTION,
    payload: sortOption,
  });
};

export const setCollectionsPagingStatusAction = (pagingStatus) => (dispatch) => {
  dispatch({
    type: SET_COLLECTIONS_PAGING_STATUS,
    payload: pagingStatus,
  });
};

export const setCollectionsSearchValueAction = (collectionsSearchValue) => (dispatch) => {
  dispatch({
    type: SET_COLLECTIONS_SEARCH_VALUE,
    payload: collectionsSearchValue,
  });
};

export const setAssetsIdsForCollectionAction = (assetsIds) => (dispatch) => {
  dispatch({
    type: SET_ASSETS_IDS_FOR_COLLECTION,
    payload: assetsIds,
  });
};

export const setCollectionCreationStateAction = (state) => (dispatch) => {
  dispatch({
    type: SET_COLLECTION_CREATION_STATE,
    payload: state,
  });
};

export const setCollectionToEditAction = (collectionToEdit) => (dispatch) => {
  dispatch({
    type: SET_COLLECTION_TO_EDIT,
    payload: collectionToEdit,
  });
};

export const resetCreateOrEditCollectionAction = () => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_RESET,
  });
};

export const createCollectionAction = (data) => (dispatch, getState) => {
  dispatch({
    type: MANAGE_COLLECTION_START,
  });

  const { assetsIds } = getState().collections;

  return Collections.createCollection(data)
    .then((response) => {
      dispatch({
        type: MANAGE_COLLECTION_SUCCESS,
        payload: response,
      });

      if (assetsIds.length) {
        dispatch(getAllCollectionsAction());
      } else {
        if (data.isPrivate) {
          dispatch(getPrivateCollectionsAction());
        } else {
          dispatch(getPublicCollectionsAction());
        }
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.create.text,
        description: notifications.collection.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const editCollectionAction = (collectionId, data, isPrivateCollections, isCollectionPage) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_START,
  });

  return Collections.updateCollection(collectionId, data)
    .then((response) => {
      dispatch({
        type: MANAGE_COLLECTION_SUCCESS,
        payload: response,
      });

      if (isCollectionPage) {
        dispatch(getCollectionAction(collectionId));
      } else {
        if (isPrivateCollections) {
          dispatch(getPrivateCollectionsAction());
        } else {
          dispatch(getPublicCollectionsAction());
        }
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.edit.text,
        description: notifications.collection.success.edit.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.edit.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteCollectionAction = (collectionId, isPrivateCollections, isCollectionPage) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_START,
  });

  return Collections.deleteCollection(collectionId)
    .then((response) => {
      dispatch({
        type: MANAGE_COLLECTION_SUCCESS,
        payload: response,
      });

      if (!isCollectionPage) {
        if (isPrivateCollections) {
          dispatch(getPrivateCollectionsAction());
        } else {
          dispatch(getPublicCollectionsAction());
        }
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.delete.text,
        description: notifications.collection.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const copyCollectionAction = (collectionId, isPrivateCollections, isCollectionPage) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_START,
  });

  return Collections.copyCollection(collectionId)
    .then((response) => {
      dispatch({
        type: MANAGE_COLLECTION_SUCCESS,
        payload: response,
      });

      if (isPrivateCollections && !isCollectionPage) {
        dispatch(getPrivateCollectionsAction());
      }

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.copy.text,
        description: notifications.collection.success.copy.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.copy.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const addFilesToCollectionAction = (collectionId, data) => (dispatch) => {
  dispatch({
    type: MANAGE_COLLECTION_START,
  });

  return Collections.addFilesToCollection(collectionId, data)
    .then((response) => {
      dispatch({
        type: MANAGE_COLLECTION_SUCCESS,
        payload: response,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.add_file.text,
        description: notifications.collection.success.add_file.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: MANAGE_COLLECTION_FAIL,
        payload: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.add_file.text,
        description: `${error?.message}: \n${Array.isArray(error?.errors) ? error?.errors?.join(',\r\n') : ''}`,
        lifeTime: error?.errors ? 15000 : FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const shareCollectionAction = (collectionId) => (dispatch) => {
  return Collections.shareCollection(collectionId)
    .then((response) => {
      str.copyTextToClipboard(`${process.env.REACT_APP_URL}/collection-share/${collectionId}/${response}`);

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.collection.success.share.text,
        description: notifications.collection.success.share.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.collection.fail.share.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
