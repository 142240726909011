import {
  ASSET_CATEGORIES_KEYS,
  ASSET_CATEGORIES_FOR_CREATIVE_ASSETS,
  ASSET_CATEGORIES_FOR_GAME_ASSETS,
  ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS_BY_CATEGORY,
  ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY,
  ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY,
  ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS,
} from '../../constants/Asset';
import { store } from '../../store/store';
import { WITHOUT_FOLDER_PATH } from './assetUploaderConstants';


export const getAssetCategoryByJiraIssueType = (jiraIssueType) => {
  const types = Object.keys(ASSET_CATEGORIES_FOR_CREATIVE_ASSETS);
  const neededType = types.find((type) => type === jiraIssueType);

  return neededType ? ASSET_CATEGORIES_FOR_CREATIVE_ASSETS[neededType] : null;
};

export const filterGamesForExternalUser = (allGames) => {
  const userProfile = store.getState().user.get('profile');
  const gamesWithUploadAccess = userProfile.externalPermission.games.filter((game) => game.access.upload);

  return allGames.filter((game) => gamesWithUploadAccess.some((gamsWithUploadAccess) => gamsWithUploadAccess.gameId === game.id));
};

export const createFolderStructureForUpload = (assetsToUpload) => {
  const uniqDirPathSet = new Set(assetsToUpload.map((asset) => asset.path));
  const sortedUniqDirPathArray = [...uniqDirPathSet].sort((pathA, pathB) => pathA.localeCompare(pathB));
  const folderStructure = [];

  sortedUniqDirPathArray.forEach((dirPath) => {
    if (dirPath.length) {
      folderStructure.push({
        path: dirPath,
        assets: assetsToUpload.filter((asset) => asset.path === dirPath),
      });
    } else {
      folderStructure.push({
        path: WITHOUT_FOLDER_PATH,
        assets: assetsToUpload.filter((asset) => !asset.path),
      });
    }
  });

  return folderStructure;
};

export const getFormikErrorsByAssetId = (selectedAssetIdsSet, formValues, formErrors, isNeedOneErrorObject) => {
  const errorsSplitByAssetId = Object.fromEntries(formValues.map((value, index) => [value.id, formErrors[index] ?? null]));

  if (isNeedOneErrorObject) {
    if (selectedAssetIdsSet.size === 1) {
      return errorsSplitByAssetId[[...selectedAssetIdsSet][0]];
    }

    if (selectedAssetIdsSet.size > 1) {
      return Object.entries(errorsSplitByAssetId).reduce((result, current) => {
        return selectedAssetIdsSet.has(Number(current[0])) && current[1] ? {...result, ...current[1]} : result;
      }, {});
    }

  } else {
    return errorsSplitByAssetId;
  }
};

export const getGameAssetsClassificationByPath = (path) => {
  const foldersStructure = path ? path.split('/') : [''];

  const nullableAssetsClassification = {
    [ASSET_CATEGORIES_KEYS.CATEGORY]: null,
    [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: null,
    [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: null,
    [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: null,
  };
  const category = Object.values(ASSET_CATEGORIES_FOR_GAME_ASSETS)?.includes(foldersStructure[0]) ?
    foldersStructure[0] : null;

  if (category === null) {
    return nullableAssetsClassification;
  }

  if (category === ASSET_CATEGORIES_FOR_GAME_ASSETS.GAMEPLAY_ASSETS) {
    return {
      ...nullableAssetsClassification,
      [ASSET_CATEGORIES_KEYS.CATEGORY]: category,
    };
  }

  const subCategory =
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS_BY_CATEGORY[category]?.includes(foldersStructure[1]) ?
      foldersStructure[1] : null;

  if (subCategory === null) {
    return nullableAssetsClassification;
  }

  let productionFormat = null;
  let developerFormat = null;

  if (category === ASSET_CATEGORIES_FOR_GAME_ASSETS.PRODUCTION_ASSETS) {
    productionFormat =
      ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY[subCategory]?.includes(foldersStructure[2]) ?
        foldersStructure[2] : null;
  }

  if (category === ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS) {
    developerFormat =
      ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY[subCategory]?.includes(foldersStructure[2]) ?
        foldersStructure[2] : null;
  }

  if (productionFormat === null && developerFormat === null) {
    if (
      category === ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS &&
      (subCategory === ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.VIDEO || subCategory === ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.ANIMATION_ASSETS)
    ) {
      return {
        [ASSET_CATEGORIES_KEYS.CATEGORY]: category,
        [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: subCategory,
        [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: productionFormat,
        [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: developerFormat,
      };
    } else {
      return nullableAssetsClassification;
    }
  }

  return {
    [ASSET_CATEGORIES_KEYS.CATEGORY]: category,
    [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: subCategory,
    [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: productionFormat,
    [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: developerFormat,
  };
};
