import { v4 as uuidv4 } from 'uuid';
import {
  SET_ENGINE_QUERY_STRING,
  REPLACE_ENGINE_QUERY_STRINGS,
  TOGGLE_MAIN_SEARCH_RESULTS_VEIL,
  TOGGLE_MAIN_SEARCH_LOADING_AFTER_ACTION,
  SET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
  RESET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
} from './actionsTypes';
import { SEARCH_CATEGORY_ENGINES } from '../mainSearchConfig';

const initState = {
  engineQueryStrings: {
    [[SEARCH_CATEGORY_ENGINES.GAME_ASSET, SEARCH_CATEGORY_ENGINES.ASSET]]: null,
    [SEARCH_CATEGORY_ENGINES.CREATIVE]: null,
    [SEARCH_CATEGORY_ENGINES.GAME]: null,
  },
  dropDownsStateMap: new Map(),
  loadingAfterAction: false,
  triggerForRefreshingMainSearchResults: null,
};

const mainSearchReducer = (state = initState, action) => {
  const { type, payload } = action;
  const { engineName, engineQueryString } = payload || {};

  switch (type) {

    case SET_ENGINE_QUERY_STRING:
      return {
        ...state,
        engineQueryStrings: {
          ...state.engineQueryStrings,
          [engineName]: engineQueryString,
        },
      };

    case REPLACE_ENGINE_QUERY_STRINGS:
      return {
        ...state,
        engineQueryStrings: {
          ...state.engineQueryStrings,
          ...payload,
        },
      };

    case TOGGLE_MAIN_SEARCH_RESULTS_VEIL: {
      const  {
        dropDownName,
        dropDownState,
      } = payload;

      const newDropDownsStateMap = state.dropDownsStateMap;
      newDropDownsStateMap.set(dropDownName, dropDownState);

      return {
        ...state,
        dropDownsStateMap: newDropDownsStateMap,
      };
    }

    case TOGGLE_MAIN_SEARCH_LOADING_AFTER_ACTION:
      return {
        ...state,
        loadingAfterAction: payload,
      };

    case SET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS:
      return {
        ...state,
        triggerForRefreshingMainSearchResults: uuidv4(),
      };

    case RESET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS:
      return {
        ...state,
        triggerForRefreshingMainSearchResults: null,
      };

    default:
      return state;
  }
};

export default mainSearchReducer;
