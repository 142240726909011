import { SET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS } from 'components/MainSearch/MainSearch.redux/actionsTypes';
import {
  SET_ASSETS_TO_PREVIEW,
  UPDATE_ASSET_TO_PREVIEW,
} from './actionsTypes';


export const setAssetsToPreviewAction = (
  assetsToPreview,
  index = 0,
  isAssetsFromGameStorage = false,
  isAssetsFromCollection = false
) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSETS_TO_PREVIEW,
      payload: {
        assetsToPreview,
        assetIndex: index,
        isAssetsFromGameStorage,
        isAssetsFromCollection,
      },
    });
  };
};

export const updateAssetToPreviewAction = (assetId, newAssetFields) => {
  return {
    type: UPDATE_ASSET_TO_PREVIEW,
    payload: {
      assetId,
      newAssetFields,
    },
  };
};