export const UNSUITABLE_STRUCTURE = 'UNSUITABLE_STRUCTURE';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const GET_GAME_ASSETS_FAIL = 'GET_GAME_ASSETS_FAIL';

export const GET_TITLE_CARDS_SUCCESS = 'GET_TITLE_CARDS_SUCCESS';
export const GET_END_CARDS_SUCCESS = 'GET_END_CARDS_SUCCESS';
export const GET_APP_SEARCH_FONTS_SUCCESS = 'GET_APP_SEARCH_FONTS_SUCCESS';
export const GET_FONTS_SUCCESS = 'GET_FONTS_SUCCESS';
export const GET_COLORS_SUCCESS = 'GET_COLORS_SUCCESS';
export const GET_MAIN_VIDEOS_SUCCESS = 'GET_MAIN_VIDEOS_SUCCESS';

export const GET_LOWER_THIRDS_LANDSCAPE_SUCCESS = 'GET_LOWER_THIRDS_LANDSCAPE_SUCCESS';
export const GET_LOWER_THIRDS_SQUARE_SUCCESS = 'GET_LOWER_THIRDS_SQUARE_SUCCESS';
export const GET_LOWER_THIRDS_PORTRAIT_SUCCESS = 'GET_LOWER_THIRDS_PORTRAIT_SUCCESS';
export const GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS = 'GET_LOWER_THIRDS_FOUR_ON_FIVE_SUCCESS';

export const GET_IMAGE_OVERLAYS_SUCCESS = 'GET_IMAGE_OVERLAYS_SUCCESS';

export const GET_AUDIOS_SUCCESS = 'GET_AUDIOS_SUCCESS';
export const GET_VOICE_OVER_SUCCESS = 'GET_VOICE_OVER_SUCCESS';
export const GET_VOICE_OVER_END_CARDS_SUCCESS = 'GET_VOICE_OVER_END_CARDS_SUCCESS';

export const SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS = 'SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS';
export const SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS = 'SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS';

export const GET_LIVE_ACTION_VIDEOS_SUCCESS = 'GET_LIVE_ACTION_VIDEOS_SUCCESS';

export const GET_EFFECTS_LANDSCAPE_SUCCESS = 'GET_EFFECTS_LANDSCAPE_SUCCESS';
export const GET_EFFECTS_SQUARE_SUCCESS = 'GET_EFFECTS_SQUARE_SUCCESS';
export const GET_EFFECTS_PORTRAIT_SUCCESS = 'GET_EFFECTS_PORTRAIT_SUCCESS';
export const GET_EFFECTS_FOUR_ON_FIVE_SUCCESS = 'GET_EFFECTS_FOUR_ON_FIVE_SUCCESS';

export const SET_PROJECT_COMPONENTS = 'SET_PROJECT_COMPONENTS';

export const RENDER_PROJECT_START = 'RENDER_PROJECT_START';
export const RENDER_PROJECT_SUCCESS = 'RENDER_PROJECT_SUCCESS';
export const RENDER_PROJECT_FAIL = 'RENDER_PROJECT_FAIL';

export const SAVE_PROJECT_START = 'SAVE_PROJECT_START';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_FAIL = 'SAVE_PROJECT_FAIL';

export const RENDER_PROJECT_RESET = 'RENDER_PROJECT_RESET';
export const SAVE_PROJECT_RESET = 'SAVE_PROJECT_RESET';
export const SET_RENDER_STATUS = 'SET_RENDER_STATUS';

export const GET_GENERAL_GAME_STORAGE_UUID_SUCCESS = 'GET_GENERAL_GAME_STORAGE_UUID_SUCCESS';

export const SET_MAIN_VIDEOS_DATA = 'SET_MAIN_VIDEOS_DATA';

export const CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT = 'CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT';
