import {
  GET_JIRA_ISSUE_METADATA_SUCCESS,
  GET_JIRA_ISSUE_TRANSITION_SUCCESS,
} from '../actionTypes';
import Creative from 'services/Creative';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import { FAIL_NOTIFICATION_LIVE_TIME } from 'constants/Notifications';


export const getJiraIssueMetadata = () => (dispatch) => {
  return Creative.getJiraIssueMetadata()
    .then((response) => {
      dispatch({
        type: GET_JIRA_ISSUE_METADATA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: 'Jira issue metadata',
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const getJiraIssueTransitions = (issueId) => (dispatch) => {
  return Creative.getJiraIssueTransitions(issueId)
    .then((issueTransitions) => {
      dispatch({
        type: GET_JIRA_ISSUE_TRANSITION_SUCCESS,
        payload: issueTransitions,
      });
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: 'Jira issue transitions',
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
