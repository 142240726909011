import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class CustomerSupport {
  constructor() {
    this.customerSupportSendItem = FeathersClient.service(`${API_PREFIX}customer-support/send-items`);
    this.customerSupportRewardOptions = FeathersClient.service(`${API_PREFIX}customer-support/reward-options`);
  }

  getRewardOptions(gameId) {
    return this.customerSupportRewardOptions.find({
      query: { gameId },
    });
  }

  sendItems(data) {
    return this.customerSupportSendItem.create(data);
  }
}

export default new CustomerSupport();
