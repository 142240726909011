import { Record } from 'immutable';

import {
  // Asset (file, folder) actions types
  GET_STORAGE_DATA_REQUEST,
  GET_STORAGE_DATA_SUCCESS,
  GET_STORAGE_DATA_FAIL,

  CREATE_NEW_FOLDER_GAME_REQUEST,
  CREATE_NEW_FOLDER_GAME_SUCCESS,
  CREATE_NEW_FOLDER_GAME_FAIL,

  UPLOAD_DOC_GAME_REQUEST,
  UPLOAD_DOC_GAME_SUCCESS,
  UPLOAD_DOC_GAME_FAIL,

  RENAME_ASSET_REQUEST,
  RENAME_ASSET_SUCCESS,
  RENAME_ASSET_FAIL,

  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAIL,

  SEARCH_ASSET_DUPLICATES_REQUEST,
  SEARCH_ASSET_DUPLICATES_SUCCESS,
  SEARCH_ASSET_DUPLICATES_FAIL,

  // Attributes actions types
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_FAIL,

  CREATE_ATTRIBUTE_OPTION_REQUEST,
  CREATE_ATTRIBUTE_OPTION_SUCCESS,
  CREATE_ATTRIBUTE_OPTION_FAIL,

  UPDATE_ATTRIBUTE_OPTION_REQUEST,
  UPDATE_ATTRIBUTE_OPTION_SUCCESS,
  UPDATE_ATTRIBUTE_OPTION_FAIL,

  DELETE_ATTRIBUTE_OPTION_REQUEST,
  DELETE_ATTRIBUTE_OPTION_SUCCESS,
  DELETE_ATTRIBUTE_OPTION_FAIL,

  CHANGE_ASSET_ATTRIBUTE_START,
  CHANGE_ASSET_ATTRIBUTE_SUCCESS,
  CHANGE_ASSET_ATTRIBUTE_FAIL,
} from './actionTypes';
import {
  DELETE_ASSETS_REQUEST,
  DELETE_ASSETS_SUCCESS,
  DELETE_ASSETS_FAIL,
} from '../DeleteAssets/DeleteAssets.redux/actionsTypes';
import {
  MOVE_ASSET_FAIL,
  MOVE_ASSET_REQUEST,
  MOVE_ASSET_SUCCESS,
} from './actionTypes';


const InitialState = Record({
  isFetching: false,
  error: null,
  attributes: null,
  attributeOptionActionSuccess: false,
  changeAttributeLoading: false,
  changeAttributeSuccess: false,
  changeAttributeFail: false,
  gameAssetStorageData: [],
  assetUpdate: {
    loading: false,
    success: null,
    fail: null,
  },
  assetDuplicates: {
    loading: false,
    success: null,
    fail: null,
  },
});

const initialState = new InitialState();

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_FOLDER_GAME_REQUEST:
    case RENAME_ASSET_REQUEST:
    case MOVE_ASSET_REQUEST:
    case GET_STORAGE_DATA_REQUEST:
    case DELETE_ASSETS_REQUEST:
    case UPLOAD_DOC_GAME_REQUEST:
    case GET_ATTRIBUTES_REQUEST:
    case CREATE_ATTRIBUTE_OPTION_REQUEST:
    case UPDATE_ATTRIBUTE_OPTION_REQUEST:
    case DELETE_ATTRIBUTE_OPTION_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('attributeOptionActionSuccess', false);
      });

    case CREATE_NEW_FOLDER_GAME_SUCCESS:
    case UPLOAD_DOC_GAME_SUCCESS:
    case RENAME_ASSET_SUCCESS:
    case MOVE_ASSET_SUCCESS:
    case DELETE_ASSETS_SUCCESS:
    case CREATE_ATTRIBUTE_OPTION_SUCCESS:
    case UPDATE_ATTRIBUTE_OPTION_SUCCESS:
    case DELETE_ATTRIBUTE_OPTION_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', null)
          .set('attributeOptionActionSuccess', true);
      });

    case CREATE_NEW_FOLDER_GAME_FAIL:
    case UPLOAD_DOC_GAME_FAIL:
    case RENAME_ASSET_FAIL:
    case MOVE_ASSET_FAIL:
    case DELETE_ASSETS_FAIL:
    case GET_STORAGE_DATA_FAIL:
    case GET_ATTRIBUTES_FAIL:
    case CREATE_ATTRIBUTE_OPTION_FAIL:
    case UPDATE_ATTRIBUTE_OPTION_FAIL:
    case DELETE_ATTRIBUTE_OPTION_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', action.error)
          .set('attributeOptionActionSuccess', false);
      });

    case GET_STORAGE_DATA_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('error', null)
          .set('gameAssetStorageData', action.payload.assets);
      });

    case UPDATE_ASSET_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', true)
          .set('assetUpdate', {
            loading: true,
            success: null,
            fail: null,
          });
      });

    case UPDATE_ASSET_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('assetUpdate', {
            loading: false,
            success: action.payload,
            fail: null,
          });
      });

    case UPDATE_ASSET_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('assetUpdate', {
            loading: false,
            success: null,
            fail: action.payload,
          });
      });

    case SEARCH_ASSET_DUPLICATES_REQUEST:
      return state.withMutations((ctx) => {
        ctx
          .set('assetDuplicates', {
            loading: true,
            success: null,
            fail: null,
          });
      });

    case SEARCH_ASSET_DUPLICATES_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('assetDuplicates', {
            loading: false,
            success: action.payload,
            fail: null,
          });
      });

    case SEARCH_ASSET_DUPLICATES_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('assetDuplicates', {
            loading: false,
            success: null,
            fail: action.payload,
          });
      });

    case GET_ATTRIBUTES_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('isFetching', false)
          .set('attributes', action.attributes)
          .set('attributeOptionActionSuccess', true);
      });

    case CHANGE_ASSET_ATTRIBUTE_START:
      return state.withMutations((ctx) => {
        ctx
          .set('changeAttributeLoading', true)
          .set('changeAttributeSuccess', false)
          .set('changeAttributeFail', false);
      });

    case CHANGE_ASSET_ATTRIBUTE_SUCCESS:
      return state.withMutations((ctx) => {
        ctx
          .set('changeAttributeLoading', false)
          .set('changeAttributeSuccess', true)
          .set('changeAttributeFail', false);
      });

    case CHANGE_ASSET_ATTRIBUTE_FAIL:
      return state.withMutations((ctx) => {
        ctx
          .set('changeAttributeLoading', false)
          .set('changeAttributeSuccess', false)
          .set('changeAttributeFail', true);
      });

    default:
      return state;
  }
};
