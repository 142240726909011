import * as queryString from 'query-string';
import history from 'utils/history';
import {
  GET_GAMES_START,
  GET_GAMES_FAIL,
  GET_GAMES_SUCCESS,

  GAME_CLEARING_PREVIEW,

  GET_ALL_GENRES_REQUEST,
  GET_ALL_GENRES_SUCCESS,
  GET_ALL_GENRES_FAIL,

  GET_ALL_STUDIOS_REQUEST,
  GET_ALL_STUDIOS_SUCCESS,
  GET_ALL_STUDIOS_FAIL,

  GET_ALL_LEVELS_REQUEST,
  GET_ALL_LEVELS_SUCCESS,
  GET_ALL_LEVELS_FAIL,

  GET_GAMES_BY_GENRES_REQUEST,
  GET_GAMES_BY_GENRES_SUCCESS,
  GET_GAMES_BY_GENRES_FAIL,

  SET_SELECTED_GAME_ID,

  GET_SELECTED_GAME_FAIL,
  GET_SELECTED_GAME_REQUEST,
  GET_SELECTED_GAME_SUCCESS,

  REMOVE_SELECTED_GAME_FAIL,
  REMOVE_SELECTED_GAME_REQUEST,
  REMOVE_SELECTED_GAME_SUCCESS,

  GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL,
  GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST,
  GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS,

  UPLOAD_SELECTED_GAME_PREVIEW,
  UPDATE_SIMILAR_GAME_STATUS,

  SET_SELECTED_GAME_GENRES,
  ADD_NEW_GAME_GENRE,

  CREATE_NEW_GAME_FAIL,
  CREATE_NEW_GAME_REQUEST,
  CREATE_NEW_GAME_SUCCESS,

  UPDATE_SELECTED_GAME_FAIL,
  UPDATE_SELECTED_GAME_REQUEST,
  UPDATE_SELECTED_GAME_SUCCESS,

  GET_UNASSOCIATED_JIRA_PROJECTS_START,
  GET_UNASSOCIATED_JIRA_PROJECTS_FAIL,
  GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS,

  UPDATE_OPTION_REQUEST,
  UPDATE_OPTION_SUCCESS,
  UPDATE_OPTION_FAIL,
} from './actionTypes';
import Game from 'services/Game';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import { setTriggerForRefreshingMainSearchAction } from '../../MainSearch/MainSearch.redux/actions';
import { setActiveGameStorageAction } from '../../MainSearch/GamesView/GamesView.redux/actions';
import { FACETS, SEARCH_CATEGORY_ENGINES } from '../../MainSearch/mainSearchConfig';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const findUnassociatedJiraProjects = () => {
  return (dispatch) => {
    dispatch({
      type: GET_UNASSOCIATED_JIRA_PROJECTS_START,
    });

    return Game.findUnassociatedJiraProjects()
      .then((data) => {
        dispatch({
          type: GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNASSOCIATED_JIRA_PROJECTS_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
};

export const getUnassociatedJiraProjects = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_UNASSOCIATED_JIRA_PROJECTS_START,
    });

    return Game.getUnassociatedJiraProjects(id)
      .then((data) => {
        dispatch({
          type: GET_UNASSOCIATED_JIRA_PROJECTS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_UNASSOCIATED_JIRA_PROJECTS_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
};

export function getGames(direction, offset, limit, reload, search) {
  return (dispatch) => {
    dispatch({ type: GET_GAMES_START });

    return Game.getGames({
      direction,
      offset,
      limit,
      text: search,
    })
      .then((result) => {
        const {
          raw, count,
        } = result;

        dispatch({
          type: GET_GAMES_SUCCESS,
          games: raw,
          offset,
          count,
          reload,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GAMES_FAIL,
          error: getErrorMessage(error),
        });
      });
  };
}

export const clearingGamePreview = () => ({
  type: GAME_CLEARING_PREVIEW,
});

const getAllGenresRequest = () => ({
  type: GET_ALL_GENRES_REQUEST,
});

const getAllGenresSuccess = (genres) => ({
  type: GET_ALL_GENRES_SUCCESS,
  genres,
});

const getAllGenresFail = (error) => ({
  type: GET_ALL_GENRES_FAIL,
  error: getErrorMessage(error),
});

export const getAllGenres = (query) => {
  return (dispatch) => {
    dispatch(getAllGenresRequest());

    return Game.getAllGenres(query)
      .then((res) => dispatch(getAllGenresSuccess(res)))
      .catch((error) => dispatch(getAllGenresFail(error)));
  };
};

const getGamesByGenresRequest = () => ({
  type: GET_GAMES_BY_GENRES_REQUEST,
});

const getGamesByGenresSuccess = (games) => ({
  type: GET_GAMES_BY_GENRES_SUCCESS,
  games,
});

const getGamesByGenresFail = (error) => ({
  type: GET_GAMES_BY_GENRES_FAIL,
  error: getErrorMessage(error),
});

export const getGamesByGenres = (query) => (dispatch) => {
  dispatch(getGamesByGenresRequest());

  return Game.getGameGenres(query)
    .then((games) => dispatch(getGamesByGenresSuccess(games)))
    .catch((error) => dispatch(getGamesByGenresFail(error)));
};

export const setSelectedGameId = (id) => ({
  type: SET_SELECTED_GAME_ID,
  id,
});

const getSelectedGameRequest = () => ({
  type: GET_SELECTED_GAME_REQUEST,
});

const getSelectedGameSuccess = (game) => ({
  type: GET_SELECTED_GAME_SUCCESS,
  game,
});

const getSelectedGameFail = (error) => ({
  type: GET_SELECTED_GAME_FAIL,
  error: getErrorMessage(error),
});

export const getSelectedGame = (id) => (dispatch) => {
  dispatch(getSelectedGameRequest());

  return Game.getGameById(id)
    .then((res) => dispatch(getSelectedGameSuccess(res)))
    .catch((error) => dispatch(getSelectedGameFail(error)));
};

const removeSelectedGameRequest = () => ({
  type: REMOVE_SELECTED_GAME_REQUEST,
});

const removeSelectedGameSuccess = () => ({
  type: REMOVE_SELECTED_GAME_SUCCESS,
});

const removeSelectedGameFail = (error) => ({
  type: REMOVE_SELECTED_GAME_FAIL,
  error: getErrorMessage(error),
});

export const removeSelectedGame = (id) => (dispatch) => {
  dispatch(removeSelectedGameRequest());

  return Game.removeGame(id)
    .then(() => {
      dispatch(removeSelectedGameSuccess());
      dispatch(setTriggerForRefreshingMainSearchAction());
      dispatch(setActiveGameStorageAction());
      history.push({
        pathname: '/search',
        search: queryString.stringify({[FACETS.SEARCH_CATEGORY]: SEARCH_CATEGORY_ENGINES.GAME}),
      });
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.game.success.delete.text,
        description: notifications.game.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(removeSelectedGameFail(error));

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.game.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

function getAllExistGamesRequest() {
  return {
    type: GET_ALL_EXIST_GAMES_FOR_SIMILAR_REQUEST,
  };
}

function getAllExistGamesSuccess(games) {
  return {
    type: GET_ALL_EXIST_GAMES_FOR_SIMILAR_SUCCESS,
    games,
  };
}

function getAllExistGamesFail(error) {
  return {
    type: GET_ALL_EXIST_GAMES_FOR_SIMILAR_FAIL,
    error: error.message,
  };
}

export const getAllExistGamesCompleteAPICall = (count) => (dispatch) => {
  dispatch(getAllExistGamesRequest());
  return Game.getGames({
    offset: 0,
    count,
  })
    .then((res) => {
      const {
        raw,
      } = res;
      dispatch(getAllExistGamesSuccess(raw));
    })
    .catch((error) => {
      dispatch(getAllExistGamesFail(error));
    });
};

function uploadGamePreview(file) {
  return {
    type: UPLOAD_SELECTED_GAME_PREVIEW,
    file,
  };
}

export const uploadGamePreviewCall = (file) => (dispatch) => {
  dispatch(uploadGamePreview(file));
};

export const updateSimilarGameStatusCall = (index, status) => (dispatch) => {
  dispatch({
    type: UPDATE_SIMILAR_GAME_STATUS,
    status,
    index,
  });
};

export const setSelectedGameGenres = (genres) => ({
  type: SET_SELECTED_GAME_GENRES,
  genres,
});

export const addNewGameGenre = (genre) => ({
  type: ADD_NEW_GAME_GENRE,
  genre,
});

const createGameRequest = () => ({
  type: CREATE_NEW_GAME_REQUEST,
});

const createGameSuccess = () => ({
  type: CREATE_NEW_GAME_SUCCESS,
});

const createNewGameFail = (error) => ({
  type: CREATE_NEW_GAME_FAIL,
  error: getErrorMessage(error),
});

export const createNewGame = ({ file, ...data }) => {
  return (dispatch) => {
    dispatch(createGameRequest());

    return Game.createGame(data)
      .then((game) => {
        if (file) {
          const formData = new FormData();
          formData.append('gameId', game.id);
          formData.append('files', file);
          return Game.createGameImage(formData);
        }
      })
      .then(() => {
        dispatch(createGameSuccess());
        dispatch(setTriggerForRefreshingMainSearchAction());
        dispatch(addGeneralMessageAction({
          error: false,
          text: notifications.game.success.create.text,
          description: notifications.game.success.create.description,
          lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
        }));
      })
      .catch((error) => {
        dispatch(createNewGameFail(error));

        dispatch(addGeneralMessageAction({
          error: true,
          text: notifications.game.fail.create.text,
          description: getErrorMessage(error),
          lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
        }));
      });
  };
};

const updateGameRequest = () => ({
  type: UPDATE_SELECTED_GAME_REQUEST,
});

const updateGameSuccess = () => ({
  type: UPDATE_SELECTED_GAME_SUCCESS,
});

const updateGameFail = (error) => ({
  type: UPDATE_SELECTED_GAME_FAIL,
  error: getErrorMessage(error),
});

export const updateGame = ({ file, ...data }) => {
  return (dispatch) => {
    dispatch(updateGameRequest());

    return Game.updateGame(data.selectedGameId, data)
      .then((response) => {
        if (file) {
          const formData = new FormData();
          formData.append('files', file);
          formData.append('gameId', data.selectedGameId);
          return Game.createGameImage(formData);
        }
        return response;
      })
      .then((response) => {
        dispatch(updateGameSuccess());
        dispatch(setTriggerForRefreshingMainSearchAction());
        dispatch(setActiveGameStorageAction({
          gameId: response.id,
          gameName: response.name,
        }));

        dispatch(addGeneralMessageAction({
          error: false,
          text: notifications.game.success.update.text,
          description: notifications.game.success.update.description,
          lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
        }));
      })
      .catch((error) => {
        dispatch(updateGameFail(error));

        dispatch(addGeneralMessageAction({
          error: true,
          text: notifications.game.fail.update.text,
          description: getErrorMessage(error),
          lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
        }));
      });
  };
};

export const getAllStudios = () => (dispatch) => {
  dispatch({
    type: GET_ALL_STUDIOS_REQUEST,
  });

  return Game.getStudios()
    .then((res) => {
      dispatch({
        type: GET_ALL_STUDIOS_SUCCESS,
        studios: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_STUDIOS_FAIL,
        error: getErrorMessage(error),
      });
    });
};

export const addNewStudio = (studioName) => (dispatch) => {
  return Game.createStudio({title: studioName})
    .then(() => {
      dispatch(getAllStudios());

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.studio.success.create.text,
        description: notifications.studio.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.studio.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const removeStudio = (studioId) => (dispatch) => {
  return Game.removeStudio(studioId)
    .then(() => {
      dispatch(getAllStudios());
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.studio.success.delete.text,
        description: notifications.studio.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.studio.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const getAllLevels = () => (dispatch) => {
  dispatch({
    type: GET_ALL_LEVELS_REQUEST,
  });

  return Game.getLevels()
    .then((res) => {
      dispatch({
        type: GET_ALL_LEVELS_SUCCESS,
        levels: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_ALL_LEVELS_FAIL,
        error: getErrorMessage(error),
      });
    });
};

export const addNewLevel = (levelName) => (dispatch) => {
  return Game.createLevel({title: levelName})
    .then(() => {
      dispatch(getAllLevels());

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.level.success.create.text,
        description: notifications.level.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.level.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const removeLevel = (levelId) => (dispatch) => {
  return Game.removeLevel(levelId)
    .then(() => {
      dispatch(getAllLevels());
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.level.success.delete.text,
        description: notifications.level.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.level.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

const updateOptionRequest = () => ({
  type: UPDATE_OPTION_REQUEST,
});

const updateOptionSuccess = () => ({
  type: UPDATE_OPTION_SUCCESS,
});

const updateOptionFail = (error) => ({
  type: UPDATE_OPTION_FAIL,
  error: getErrorMessage(error),
});

export const updateStudio = (studioId, newStudioName, gameId) => (dispatch) => {
  dispatch(updateOptionRequest());

  return Game.updateStudio(studioId, {title: newStudioName})
    .then(() => {
      dispatch(updateOptionSuccess());
      dispatch(getAllStudios());
      dispatch(getSelectedGame(gameId));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.studio.success.update.text,
        description: notifications.studio.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(updateOptionFail(error));

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.studio.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const updateLevel = (levelId, newLevelName, gameId) => (dispatch) => {
  dispatch(updateOptionRequest());

  return Game.updateLevel(levelId, {title: newLevelName})
    .then(() => {
      dispatch(updateOptionSuccess());
      dispatch(getAllLevels());
      dispatch(getSelectedGame(gameId));

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.level.success.update.text,
        description: notifications.level.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch(updateOptionFail(error));

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.level.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
