
export const GET_POD_CAPACITY_START = 'GET_POD_CAPACITY_START';
export const GET_POD_CAPACITY_SUCCESS = 'GET_POD_CAPACITY_SUCCESS';
export const GET_POD_CAPACITY_SUCCESS_SCROLL = 'GET_POD_CAPACITY_SUCCESS_SCROLL';
export const GET_POD_CAPACITY_FAIL = 'GET_POD_CAPACITY_FAIL';

export const START_CREATE_NEW_POD_CAPACITY = 'START_CREATE_NEW_POD_CAPACITY';
export const SET_EDITED_POD_CAPACITY = 'SET_EDITED_POD_CAPACITY';
export const CANCEL_CREATE_OR_EDIT_POD_CAPACITY = 'CANCEL_CREATE_OR_EDIT_POD_CAPACITY';
export const UPDATE_EDITED_POD_CAPACITY_FIELD = 'UPDATE_EDITED_POD_CAPACITY_FIELD';
export const VALIDATE_EDITED_POD_CAPACITY_FIELD = 'VALIDATE_EDITED_POD_CAPACITY_FIELD';

export const GET_LEADERSHIP_START = 'GET_LEADERSHIP_START';
export const GET_LEADERSHIP_SUCCESS = 'GET_LEADERSHIP_SUCCESS';
export const GET_LEADERSHIP_FAIL = 'GET_LEADERSHIP_FAIL';
