import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import './CustomTextarea.scss';

const inputId = uuidv4();


const CustomTextarea = ({
  label,
  placeholder,
  name,
  value,
  disabled,
  error,
  maxLength,
  onChange,
  onBlur,
}) => {

  const handleChange = (event) => {
    const { value, name } = event.target;

    onChange(value, name, event);
  };

  const handleBlur = (event) => {
    const { value, name } = event.target;

    if (onBlur) {
      onBlur(value, name, event);
    }
  };

  return (
    <div
      className={classnames('custom-textarea', {
        'disabled': disabled,
        'error': !!error,
      })}
    >
      <div className='custom-textarea__inner'>
        <label htmlFor={inputId} className='custom-textarea__header'>
          <p className='custom-textarea__label'>{label}</p>
          <p className='custom-textarea__size'>{value.length}/{maxLength}</p>
        </label>

        <TextareaAutosize
          id={inputId}
          name={name}
          className='custom-textarea__field'
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>

      {error && !disabled &&
        <p className='custom-textarea__error'>
          {error}
        </p>
      }
    </div>
  );
};

CustomTextarea.defaultProps = {
  placeholder: 'Typer here tour text',
  value: '',
  maxLength: 100,
};

CustomTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default CustomTextarea;
