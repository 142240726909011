export const TOGGLE_GRID = 'TOGGLE_GRID';
export const TOGGLE_LIST = 'TOGGLE_LIST';
export const TOGGLE_LIMIT = 'TOGGLE_LIMIT';
export const TOGGLE_PAGE = 'TOGGLE_PAGE';
export const TOGGLE_SHOW_MORE = 'TOGGLE_SHOW_MORE';

export const UPDATE_PROJECTS_LIST = 'UPDATE_PROJECTS_LIST';

export const TOGGLE_GAME_FILTER = 'TOGGLE_GAME_FILTER';
export const TOGGLE_USERS_FILTER = 'TOGGLE_USERS_FILTER';
export const TOGGLE_TOOLS_FILTER = 'TOGGLE_TOOLS_FILTER';
export const TOGGLE_BADGES_FILTER = 'TOGGLE_BADGES_FILTER';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const GET_VIDEO_PROJECT_GAMES = 'GET_VIDEO_PROJECT_GAMES';
export const GET_VIDEO_PROJECT_GAMES_SUCCESS = 'GET_VIDEO_PROJECT_GAMES_SUCCESS';
export const GET_VIDEO_PROJECT_GAMES_FAIL = 'GET_VIDEO_PROJECT_GAMES_FAIL';

export const CREATE_VIDEO_PROJECT = 'CREATE_VIDEO_PROJECT';
export const CREATE_VIDEO_PROJECT_SUCCESS = 'CREATE_VIDEO_PROJECT_SUCCESS';
export const CREATE_VIDEO_PROJECT_FAIL = 'CREATE_VIDEO_PROJECT_FAIL';
export const CREATE_VIDEO_PROJECT_RANDOMIZER = 'CREATE_VIDEO_PROJECT_RANDOMIZER';

export const CLEAR_MAIN_PAGE_SERVER_ERRORS = 'CLEAR_MAIN_PAGE_SERVER_ERRORS';

export const COPY_VIDEO_PROJECT = 'COPY_VIDEO_PROJECT';
export const COPY_VIDEO_PROJECT_SUCCESS = 'COPY_VIDEO_PROJECT_SUCCESS';
export const COPY_VIDEO_PROJECT_FAIL = 'COPY_VIDEO_PROJECT_FAIL';

export const RENAME_VIDEO_PROJECT = 'RENAME_VIDEO_PROJECT';
export const RENAME_VIDEO_PROJECT_SUCCESS = 'RENAME_VIDEO_PROJECT_SUCCESS';
export const RENAME_VIDEO_PROJECT_FAIL = 'RENAME_VIDEO_PROJECT_FAIL';

export const GET_VIDEO_PROJECT = 'GET_VIDEO_PROJECT';
export const GET_VIDEO_PROJECT_SUCCESS = 'GET_VIDEO_PROJECT_SUCCESS';
export const GET_VIDEO_PROJECT_FAIL = 'GET_VIDEO_PROJECT_FAIL';

export const GET_VIDEO_PROJECT_FILTERS = 'GET_VIDEO_PROJECT_FILTERS';
export const GET_VIDEO_PROJECT_FILTERS_SUCCESS = 'GET_VIDEO_PROJECT_FILTERS_SUCCESS';
export const GET_VIDEO_PROJECT_FILTERS_FAIL = 'GET_VIDEO_PROJECT_FILTERS_FAIL';

export const CLEAN_UP_MAIN_SCREEN_FILTERS = 'CLEAN_UP_MAIN_SCREEN_FILTERS';

