import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as CrossIcon } from '../../../assets/svg/cross.svg';

import styles from './styles.module.scss';

const ModalCloseButton = ({ onClick }) => (
  <IconButton
    className={styles.closeButton}
    onClick={onClick}
  >
    <CrossIcon />
  </IconButton>
);

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ModalCloseButton;
