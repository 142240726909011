import React from 'react';
import PropTypes from 'prop-types';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import './EntityDoNotUseOverlay.scss';


const EntityDoNotUseOverlay = ({ iconFontSize }) => {
  return (
    <div className='entity-do-not-use-overlay'>
      <NotInterestedIcon
        className='entity-do-not-use-overlay__icon'
        style={{
          fontSize: `${iconFontSize}px`,
        }}
      />
    </div>
  );
};

EntityDoNotUseOverlay.defaultProps = {
  iconFontSize: 20,
};

EntityDoNotUseOverlay.propTypes = {
  iconFontSize: PropTypes.number,
};

export default EntityDoNotUseOverlay;
