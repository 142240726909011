import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class CollectionsService {
  constructor() {
    this.collection = FeathersClient.service(`${API_PREFIX}collections`);
    this.collectionCopy = FeathersClient.service(`${API_PREFIX}collections/copy`);
    this.collectionShare = FeathersClient.service(`${API_PREFIX}collections/share`);
    this.collectionMove = FeathersClient.service(`${API_PREFIX}collections/{:collectionId}/move`);
    this.collectionFiles = FeathersClient.service(`${API_PREFIX}collections/{:collectionId}/files`);
    this.collectionFolders = FeathersClient.service(`${API_PREFIX}collections/{:collectionId}/folders`);
  }

  /**
   * @param {object} params
   */
  getCollections(params) {
    const {
      isPrivate,
      limit,
      skip,
      sort,
      searchValue,
      userId,
    } = params;

    const query = {};

    if (Object.hasOwn(params, 'isPrivate')) {
      query['isPrivate'] = isPrivate;
    }

    if (sort) {
      query[`$sort[${sort.value}]`] = sort.order;
    }

    if (limit) {
      query['$limit'] = limit;
    }

    if (skip) {
      query['$skip'] = skip;
    }

    if (userId) {
      query['userId'] = userId;
    }

    if (searchValue) {
      query['name[$iLike]'] = `%${searchValue}%`;
    }

    return this.collection.find({ query });
  }

  /**
   * @param {number} collectionId
   * @param {string} collectionHash
   */
  getCollection(collectionId, collectionHash) {
    if (collectionHash) {
      return this.collectionShare.find({ query: { '#': collectionHash } });
    } else {
      return this.collection.get(collectionId);
    }
  }

  /**
   * @param {object} data
   */
  createCollection(data) {
    return this.collection.create(data);
  }

  /**
   * @param {string} collectionId
   * @param {object} data
   */
  updateCollection(collectionId, data) {
    return this.collection.patch(collectionId, data);
  }

  /**
   * @param {number} collectionId
   */
  deleteCollection(collectionId) {
    return this.collection.remove(collectionId);
  }

  /**
   * @param {number} collectionId
   */
  copyCollection(collectionId) {
    return this.collectionCopy.create({ collectionId });
  }

  /**
   * @param {number} collectionId
   */
  shareCollection(collectionId) {
    return this.collectionShare.create({ collectionId });
  }

  /**
   * @param {number} collectionId
   * @param {object} data
   */
  addFilesToCollection(collectionId, data) {
    const { collectionFiles } = this;
    collectionFiles.base = `${API_PREFIX}collections/${collectionId}/files`;

    return collectionFiles.create(data);
  }

  /**
   * @param {object} params
   */
  getCollectionAssets(params) {
    const {
      collectionId,
      parentId,
      folderName,
      collectionHash,
    } = params;

    const { collectionFiles } = this;
    collectionFiles.base = `${API_PREFIX}collections/${collectionId}/files`;

    const query = {
      parentId: parentId ? parentId : 'null',
    };

    if (collectionHash) {
      query['#'] = collectionHash;
    }

    if (folderName) {
      query.folderName = folderName;
    }

    return collectionFiles.find({ query });
  }

  /**
   * @param {number} collectionId
   * @param {number[]} assetsIds
   */
  deleteCollectionAssets(collectionId, assetsIds) {
    const { collectionFiles } = this;
    collectionFiles.base = `${API_PREFIX}collections/${collectionId}/files`;

    return collectionFiles.remove(null, { query: { id: assetsIds } });
  }

  /**
   * @param {number} collectionId
   * @param {object} data
   */
  moveCollectionAssets(collectionId, data) {
    const { collectionMove } = this;
    collectionMove.base = `${API_PREFIX}collections/${collectionId}/move`;

    return collectionMove.create(data);
  }

  /**
   * @param {number} collectionId
   * @param {string} folderName
   */
  createCollectionFolder(collectionId, folderName) {
    const { collectionFolders } = this;
    collectionFolders.base = `${API_PREFIX}collections/${collectionId}/folders`;

    return collectionFolders.create({ name: folderName });
  }

  /**
   * @param {number} collectionId
   * @param {number} folderId
   * @param {string} newFolderName
   */
  updateCollectionFolder(collectionId, folderId, newFolderName) {
    const { collectionFolders } = this;
    collectionFolders.base = `${API_PREFIX}collections/${collectionId}/folders`;

    return collectionFolders.patch(folderId, {name: newFolderName});
  }

}

export default new CollectionsService();
