export const SUCCESS_NOTIFICATION_LIVE_TIME = 3000;
export const FAIL_NOTIFICATION_LIVE_TIME = 5000;

export const notifications = {
  creative: {
    success: {
      main_image: {text: 'Creative image changed', description: 'Creative image has been successfully changed'},
      drive_link: {text: 'Drive link', description: 'Link is updated successfully'},
      update: {text: 'Creative saved', description: 'Creative has been successfully saved'},
      delete: {text: 'Creative deleted', description: 'Creative has been successfully deleted'},
    },
    fail: {
      main_image: {text: 'Creative image - request failed'},
      drive_link: {text: 'Updating drive link - request failed'},
      update: {text: 'Update creative - request failed'},
      delete: {text: 'Delete creative - request failed'},
    },
  },
  game: {
    success: {
      create: {text: 'Game created', description: 'New game has been successfully created'},
      update: {text: 'Game updated', description: 'Game has been successfully updated'},
      delete: {text: 'Game deleted', description: 'Game has been successfully deleted'},
    },
    fail: {
      create: {text: 'Create game - request failed'},
      update: {text: 'Update game - request failed'},
      delete: {text: 'Delete game - request failed'},
    },
  },
  studio: {
    success: {
      create: {text: 'Studio created', description: 'New studio was successfully created'},
      update: {text: 'Studio updated', description: 'Studio has been successfully updated'},
      delete: {text: 'Studio deleted', description: 'Studio has been successfully deleted'},
    },
    fail: {
      create: {text: 'Create studio - request failed'},
      update: {text: 'Update studio - request failed'},
      delete: {text: 'Delete studio - request failed'},
    },
  },
  level: {
    success: {
      create: {text: 'Game category created', description: 'New Game category was successfully created'},
      update: {text: 'Game category updated', description: 'Game category has been successfully updated'},
      delete: {text: 'Game category deleted', description: 'Game category has been successfully deleted'},
    },
    fail: {
      create: {text: 'Create game category - request failed'},
      update: {text: 'Update game category - request failed'},
      delete: {text: 'Delete game category - request failed'},
    },
  },
  tab: {
    success: {
      create: {text: 'Tab created', description: 'Tab has been successfully created'},
      update: {text: 'Tab renamed', description: 'Tab has been successfully renamed'},
      delete: {text: 'Tab deleted', description: 'Tab has been successfully deleted'},
    },
    fail: {
      create: {text: 'Create new tab - request failed'},
      update: {text: 'Rename tab - request failed'},
      delete: {text: 'Delete tab - request failed'},
    },
  },
  feedback: {
    success: {
      create: {text: 'Feedback added', description: 'Feedback has been successfully added'},
      delete: {text: 'Feedback deleted', description: 'Feedback has been successfully deleted'},
    },
    fail: {
      get: {text: 'Get feedbacks - request failed'},
      create: {text: 'Add new feedback - request failed'},
      delete: {text: 'Delete feedback - request failed'},
    },
  },
  file: {
    success: {
      attribute: {text: 'File attribute changed', description: 'File attribute has been successfully changed'},
      share: {text: 'File sent', description: 'File has been successfully sent'},
      update: {text: 'File renamed', description: 'File has been successfully renamed'},
      delete: {text: 'File deleted', description: 'File has been successfully deleted'},
    },
    fail: {
      attribute: {text: 'Change attribute - request failed'},
      share: {text: 'Send file - request failed'},
      update: {text: 'Rename file - request failed'},
      delete: {text: 'Delete file - request failed'},
    },
  },
  folder: {
    success: {
      create: {text: 'Folder added', description: 'Folder has been successfully added'},
      update: {text: 'Folder renamed', description: 'Folder has been successfully renamed'},
      delete: {text: 'Folder deleted', description: 'Folder has been successfully deleted'},
    },
    fail: {
      create: {text: 'Add folder - request failed'},
      update: {text: 'Rename folder - request failed'},
      delete: {text: 'Delete Folder - request failed'},
    },
  },
  asset: {
    success: {
      upload: {text: 'Asset(s) uploaded', description: 'Asset(s) has(have) been successfully uploaded'},
      move: {text: 'Asset moved', description: 'Asset has been successfully moved'},
      rename: {text: 'Asset renamed', description: 'Asset has been successfully renamed'},
      update: {text: 'Сhanges saved', description: 'Changes have been successfully saved'},
      share: {text: 'Link copied', description: 'The link is copied to the clipboard'},
      delete: {text: 'Asset(s) deleted', description: 'Asset(s) has(have) been successfully deleted'},
    },
    fail: {
      upload: {text: 'Upload asset(s) - request failed'},
      move: {text: 'Move asset - request failed'},
      rename: {text: 'Rename asset - request failed'},
      update: {text: 'Editing failed'},
      share: {text: 'Assets link'},
      delete: {text: 'Delete asset(s) - request failed'},
    },
  },
  tag: {
    success: {
      create: {text: 'Tag created', description: 'Tag has been successfully created'},
      update: {text: 'Tag edited', description: 'Tag has been successfully edited'},
      delete: {text: 'Tag Deleted', description: 'Tag has been successfully deleted'},
    },
    fail: {
      create: {text: 'Create tag - request fail'},
      update: {text: 'Edit tag - request fail'},
      delete: {text: 'Delete tag - request fail'},
    },
  },
  attribute: {
    success: {
      set: {text: 'File attribute changed', description: 'File attribute has been successfully changed'},
    },
    fail: {
      set: {text: 'Change file attribute - request failed'},
    },
  },
  collection: {
    success: {
      create: {text: 'Collection created', description: 'Collection has been successfully created'},
      copy: {text: 'Collection copied', description: 'The collection is copied to the Private'},
      edit: {text: 'Collection edited', description: 'Collection has been successfully edited'},
      share: {text: 'Link copied', description: 'The link is copied to the clipboard'},
      add_file: {text: 'File(s) added', description: 'File(s) have been successfully added to collection'},
      delete: {text: 'Collection deleted', description: 'Collection has been successfully deleted'},
    },
    fail: {
      get: {text: 'Get collections - request failed'},
      create: {text: 'Create collection - request failed'},
      copy: {text: 'Copy collection - request failed'},
      edit: {text: 'Edit collection - request failed'},
      share: {text: 'Share collection - request failed'},
      add_file: {text: 'Add file(s) to collection - request failed'},
      get_asset: {text: 'Get collection assets - request failed'},
      delete: {text: 'Delete collection - request failed'},
    },
  },
  report: {
    success: {
      send: {text: 'Send reports', description: 'Reports has been sent successfully'},
    },
    fail: {
      send: {text: 'Send reports - request failed'},
    },
  },
};
