import FeathersClient, { addLocalTimeHeader } from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';

const UPLOAD_HEADERS = {
  'Content-Type': undefined,
  'Accept': '*/*',
};


class CreativeService {
  constructor() {
    this.creative = FeathersClient.service(`${API_PREFIX}creativities`);
    this.history = FeathersClient.service(`${API_PREFIX}asset-history`);
    this.image = FeathersClient.service(`${API_PREFIX}creativities/images`);
    this.tabs = FeathersClient.service(`${API_PREFIX}creativities/tabs`);
    this.folders = FeathersClient.service(`${API_PREFIX}creativities/folders`);
    this.doc = FeathersClient.service(`${API_PREFIX}creativities/docs`);
    this.download = FeathersClient.service(`${API_PREFIX}creativities/download`);
    this.mainImage = FeathersClient.service(`${API_PREFIX}creativities/docs/main-image`);
    this.storageData = FeathersClient.service(`${API_PREFIX}storage-object-metadata`);
    this.feedback = FeathersClient.service(`${API_PREFIX}creative-feedback`);
    this.jiraIssue = FeathersClient.service(`${API_PREFIX}creative-jira-issue`);
    this.jiraIssueTransitions = FeathersClient.service(`${API_PREFIX}creative-jira-issue/{:issueId}/transitions`);
    this.jiraIssueComments = FeathersClient.service(`${API_PREFIX}creative-jira-issue/{:issueId}/comments`);
    this.jiraIssueMetadata = FeathersClient.service(`${API_PREFIX}creative-jira-issue-metadata`);
  }

  getCreatives(params) {
    const query = {
      $limit: 9999,
      category: 'generic',
      ...params?.query,
    };

    return this.creative.find({ query });
  }

  getCreativeHistory({page, limit, sort, creativeId}) {
    const query = {};

    if (creativeId) {
      query['creativeId'] = creativeId;
    }

    if (limit) {
      query['$limit'] = limit;
    }

    if (page) {
      query['$skip'] = (page - 1) * limit;
    }

    if (sort) {
      query['$sort[createdAt]'] = sort;
    }

    return this.history.find({ query });
  }

  getCreativeById(id) {
    return this.creative.get(id);
  }

  getStorageData(query) {
    return this.storageData.find({ query });
  }

  removeCreative(id) {
    return this.creative.remove(id);
  }

  createCreativeImage(form) {
    return this.image.create(form, {
      headers: addLocalTimeHeader(UPLOAD_HEADERS),
    });
  }

  updateCreative(id, data) {
    return this.creative.patch(id, data, {
      headers: addLocalTimeHeader(UPLOAD_HEADERS),
    });
  }

  createTab(data) {
    return this.tabs.create(data);
  }

  renameTab(id, data) {
    return this.tabs.patch(id, data);
  }

  createNewFolder(data) {
    return this.folders.create(data);
  }

  removeTab(id) {
    return this.tabs.remove(id);
  }

  uploadDoc(form, gameId, onUploadProgress) {
    return this.doc.create(form, {
      headers: addLocalTimeHeader(UPLOAD_HEADERS),
      query: {
        gameId,
      },
      connection: {
        onUploadProgress,
      },
    });
  }

  setMainImage(data) {
    return this.mainImage.create(data);
  }

  getJiraIssueMetadata() {
    return this.jiraIssueMetadata.find();
  }

  getJiraIssueTransitions(issueId) {
    const { jiraIssueTransitions } = this;

    jiraIssueTransitions.base = `${API_PREFIX}creative-jira-issue/${issueId}/transitions`;
    return jiraIssueTransitions.find();
  }

  getJiraIssueComment(issueId) {
    const { jiraIssueComments } = this;

    jiraIssueComments.base = `${API_PREFIX}creative-jira-issue/${issueId}/comment`;
    return jiraIssueComments.find();
  }

  addJiraIssueComment(issueId, comment) {
    const { jiraIssueComments } = this;

    jiraIssueComments.base = `${API_PREFIX}creative-jira-issue/${issueId}/comment`;
    return jiraIssueComments.create({ comment });
  }

  deleteJiraIssueComment(issueId, commentId) {
    const { jiraIssueComments } = this;

    jiraIssueComments.base = `${API_PREFIX}creative-jira-issue/${issueId}/comment`;
    return jiraIssueComments.remove(commentId);
  }
}

export default new CreativeService();
