import colors from '../colors.module.scss';

export const MuiSelect = {
  icon: {
    width: '1rem',
    height: '1rem',
    top: 'calc(50% - 6px)',
    right: '16px',

    '& path': {
      fill: colors.blue,
    },
  },
};
