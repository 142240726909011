import {
  ADD_GENERAL_MESSAGE,
  REMOVE_GENERAL_MESSAGE,
} from './actionTypes';


export const addGeneralMessageAction = (message) => {
  return {
    type: ADD_GENERAL_MESSAGE,
    payload: message,
  };
};

export const removeGeneralMessageAction = (messageId) => {
  return {
    type: REMOVE_GENERAL_MESSAGE,
    payload: messageId,
  };
};
