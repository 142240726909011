import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StyledTooltip from '../../UIComponents/StyledTooltip';
import ConfirmRoundModal from '../../UIComponents/ConfirmRoundModal';
import {
  copyCollectionAction,
  shareCollectionAction,
  deleteCollectionAction,
  setCollectionToEditAction,
} from '../Collections.redux/Actions/collectionsActions';
import { downloadCollection } from '../dowloadCollectionHelpers';
import { collectionPropTypes } from '../Types';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  GetApp as GetAppIcon,
  FileCopy as FileCopyIcon,
  Reply as ReplyIcon,
} from '@material-ui/icons';

import './CollectionsActions.scss';


const CollectionsActions = ({ isCollectionPage, isPrivateCollections, selectedCollection }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { collectionHash } = useParams();

  const {
    id: userId,
    jiraId,
  } = useSelector((state) => state.user?.profile);
  const { loading } = useSelector((state) => state.collections.manageCollection);

  const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false);

  const canEditOrDeleteCollection = useMemo(() =>
     selectedCollection?.owner?.id === userId,
    [userId, selectedCollection]);

  const handleEditCollection = () => {
    dispatch(setCollectionToEditAction({
      ...selectedCollection,
      isCollectionPage,
    }));
  };

  const handleDeleteCollection = () => {
    setIsConfirmDeleteModal(true);
  };

  const handleProceedDeleteCollection = async () => {
    await dispatch(deleteCollectionAction(selectedCollection?.id, isPrivateCollections, isCollectionPage));
    setIsConfirmDeleteModal(false);

    if (isCollectionPage) {
      history.push('/collections');
    }
  };

  const handleDownloadCollection = () => {
    downloadCollection(
      selectedCollection?.id,
      collectionHash,
      `${selectedCollection.name}.zip`
    );
  };

  const handleCopyCollection = () => {
    dispatch(copyCollectionAction(selectedCollection?.id, isPrivateCollections, isCollectionPage));
  };

  const handleShareCollection = () => {
    dispatch(shareCollectionAction(selectedCollection?.id));
  };

  return (
    <>
      <div
        className={classnames('collections-actions', {
          'hidden': !selectedCollection,
          'blueTheme': isCollectionPage,
        })}
      >
        <StyledTooltip title='Edit collection' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('collections-actions__icon-container', {
              'hidden': !canEditOrDeleteCollection,
            })}
            onClick={handleEditCollection}
          >
            <EditIcon className='collections-actions__icon' />
          </div>
        </StyledTooltip>

        <StyledTooltip title='Delete collection' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('collections-actions__icon-container', {
              'hidden': !canEditOrDeleteCollection,
            })}
            onClick={handleDeleteCollection}
          >
            <DeleteIcon className='collections-actions__icon' />
          </div>
        </StyledTooltip>

        <StyledTooltip title='Download collection' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('collections-actions__icon-container', {
              'hidden': selectedCollection?.totalFiles === 0,
            })}
            onClick={handleDownloadCollection}
          >
            <GetAppIcon className='collections-actions__icon' />
          </div>
        </StyledTooltip>

        <StyledTooltip title='Copy collection' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('collections-actions__icon-container', {
              'hidden': !jiraId,
            })}
            onClick={handleCopyCollection}
          >
            <FileCopyIcon className='collections-actions__icon' />
          </div>
        </StyledTooltip>

        <StyledTooltip title='Share collection' placement='bottom' TransitionComponent={Zoom}>
          <div
            className={classnames('collections-actions__icon-container', {
              'hidden': !jiraId,
            })}
            onClick={handleShareCollection}
          >
            <ReplyIcon className='collections-actions__icon flipX' />
          </div>
        </StyledTooltip>
      </div>

      <ConfirmRoundModal
        isOpen={isConfirmDeleteModal}
        title='Delete collection'
        text='Are you sure you want to delete this collection?'
        loading={loading}
        loadingText='Deleting collection'
        onAgree={handleProceedDeleteCollection}
        onClose={() => setIsConfirmDeleteModal(false)}
      />
    </>
  );
};

CollectionsActions.propTypes = {
  isCollectionPage: PropTypes.bool,
  isPrivateCollections: PropTypes.bool,
  selectedCollection: collectionPropTypes,
};

export default CollectionsActions;
