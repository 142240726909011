/**
 * @param {number} id
 * @param {string} name
 * @param {number} totalSpentTime
 */
class GameAllocationModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.totalSpentTime = data.totalSpentTime;
  }
}

export default GameAllocationModel;