/**
 * @param {number} id
 * @param {string} url
 * @param {string} name
 */
class AudioModel {
  constructor(data, storage_uuid) {
    this.id = parseInt(data._meta.id);
    this.url = `/file-store/${storage_uuid}${data.path.raw}`;
    this.name = data.asset_name.raw.slice(0, -4);
  }
}

export default AudioModel;
