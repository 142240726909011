import {
  GET_JIRA_ISSUE_COMMENT_START,
  GET_JIRA_ISSUE_COMMENT_SUCCESS,
  GET_JIRA_ISSUE_COMMENT_FAIL,

  ADD_JIRA_ISSUE_COMMENT_START,
  ADD_JIRA_ISSUE_COMMENT_SUCCESS,
  ADD_JIRA_ISSUE_COMMENT_FAIL,

  DELETE_JIRA_ISSUE_COMMENT_START,
  DELETE_JIRA_ISSUE_COMMENT_SUCCESS,
  DELETE_JIRA_ISSUE_COMMENT_FAIL,
} from '../actionTypes';
import Creative from 'services/Creative';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const getJiraIssueComment = (issueId) => (dispatch) => {
  dispatch({
    type: GET_JIRA_ISSUE_COMMENT_START,
  });

  return Creative.getJiraIssueComment(issueId)
    .then((res) => {
      dispatch({
        type: GET_JIRA_ISSUE_COMMENT_SUCCESS,
        payload: res,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_JIRA_ISSUE_COMMENT_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.feedback.fail.get.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const addJiraIssueComment = (issueId, comment) => (dispatch) => {
  dispatch({
    type: ADD_JIRA_ISSUE_COMMENT_START,
  });

  return Creative.addJiraIssueComment(issueId, comment)
    .then((res) => {
      dispatch({
        type: ADD_JIRA_ISSUE_COMMENT_SUCCESS,
      });

      dispatch({
        type: GET_JIRA_ISSUE_COMMENT_SUCCESS,
        payload: res,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.feedback.success.create.text,
        description: notifications.feedback.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: ADD_JIRA_ISSUE_COMMENT_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.feedback.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteJiraIssueComment = (issueId, commentId) => (dispatch) => {
  dispatch({
    type: DELETE_JIRA_ISSUE_COMMENT_START,
  });

  return Creative.deleteJiraIssueComment(issueId, commentId)
    .then(() => {
      dispatch({
        type: DELETE_JIRA_ISSUE_COMMENT_SUCCESS,
        payload: commentId,
      });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.feedback.success.delete.text,
        description: notifications.feedback.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: DELETE_JIRA_ISSUE_COMMENT_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.feedback.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
