import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CustomTextarea from 'components/UIComponents/CustomTextarea';
import {
  assetToUploadErrorsPropTypes,
  assetToUploadPropTypes,
} from 'components/AssetUploader/Types';

import CloseIcon from '@material-ui/icons/Close';

import './UploaderConfigState.scss';


const UploaderConfigState = ({ selectedAsset, setSelectedAsset, errors }) => {

  const handleChangeDescription = (newDescription) => {
    setSelectedAsset((prevValue) => ({
      ...prevValue,
      description: newDescription,
    }));
  };

  return (
    <div
      className={classnames('uploader-config-state', {
        'nameError': errors?.name,
      })}
    >
      {errors?.name &&
        <div className='uploader-config-state__file-name-error-container'>
          <div className='uploader-config-state__error-icon-container'>
            <CloseIcon className='uploader-config-state__error-icon' />
          </div>
          <p className='uploader-config-state__file-name-error'>
            {errors?.name}
          </p>
        </div>
      }

      <div className='uploader-config-state__section'>
        <CustomTextarea
          label='description'
          value={selectedAsset.description}
          placeholder='Type here your text'
          onChange={(newDescription) => handleChangeDescription(newDescription)}
          error={errors?.description}
          maxLength={100}
        />
      </div>
    </div>
  );
};

UploaderConfigState.propTypes = {
  selectedAsset: assetToUploadPropTypes,
  setSelectedAsset: PropTypes.func.isRequired,
  errors: assetToUploadErrorsPropTypes,
};

export default UploaderConfigState;
