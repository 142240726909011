import FeathersClient from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';


class AnalyticsDeviceService {
  constructor() {
    this.analyticsDevice = FeathersClient.service(`${API_PREFIX}analytics-device`);
  }

  findAnalyticsDevice(query) {
    return this.analyticsDevice.find({ query });
  }

  getAnalyticsDeviceByTpdid(tpdid) {
    return this.analyticsDevice.get(tpdid);
  }

  setAnalyticsDeviceTestStatus(tpdid, platform, testStatus) {
    const data = {
      tpdid,
      platform,
      testStatus,
    };

    return this.analyticsDevice.update(tpdid, data);
  }
}

export default new AnalyticsDeviceService();
