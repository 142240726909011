import {
  GET_DEPARTMENT_WORKLOAD_START,
  GET_DEPARTMENT_WORKLOAD_SUCCESS,
  GET_DEPARTMENT_WORKLOAD_SUCCESS_SCROLL,
  GET_DEPARTMENT_WORKLOAD_FAIL,

  SET_DEPARTMENT_WORKLOAD_FILTER,
  REMOVE_DEPARTMENT_WORKLOAD_FILTER,
  SET_ALL_DEPARTMENT_WORKLOAD_FILTER,

  SET_DEPARTMENT_WORKLOAD_DATE_FILTER,
} from './actionsTypes';
import { departmentWorkloadFilterNames } from '../departmentWorkloadConstants';
import DepartmentWorkloadModel from '../Models/DepartmentWorkloadModel';


const initState = {
  departmentWorkload: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  exportToPDFStatus: {
    loading: false,
    success: false,
    error: false,
  },
  departmentWorkloadFilters: Object.fromEntries(Object.values(departmentWorkloadFilterNames).map((filterName) => [filterName, []])),
  departmentWorkloadSelectedFilters: Object.fromEntries(Object.values(departmentWorkloadFilterNames).map((filterName) => [filterName, []])),
  dateFilter: {
    from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  },
};

const departmentWorkloadReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case GET_DEPARTMENT_WORKLOAD_START:
      return {
        ...state,
        departmentWorkload: {
          ...state.departmentWorkload,
          loading: true,
        },
      };

    case GET_DEPARTMENT_WORKLOAD_SUCCESS:
      return {
        ...state,
        departmentWorkload: {
          loading: false,
          success: {
            total: payload.total,
            limit: payload.limit,
            skip: payload.skip,
            data: payload.data.map((workload) => new DepartmentWorkloadModel(workload)),
          },
          error: '',
        },
        departmentWorkloadFilters: Object.fromEntries(Object.entries(payload.filters).map((filter) => [filter[0], [...new Set([...filter[1]])]])),
      };

    case GET_DEPARTMENT_WORKLOAD_SUCCESS_SCROLL:
      return {
        ...state,
        departmentWorkload: {
          loading: false,
          success: {
            total: payload.total,
            limit: payload.limit,
            skip: payload.skip,
            data: [
              ...state.departmentWorkload.success.data,
              ...payload.data.map((workload) => new DepartmentWorkloadModel(workload)),
            ],
          },
          error: '',
        },
      };

    case GET_DEPARTMENT_WORKLOAD_FAIL:
      return {
        ...state,
        departmentWorkload: {
          ...state.departmentWorkload,
          loading: false,
          error: payload,
        },
      };

    case SET_DEPARTMENT_WORKLOAD_FILTER:
      return {
        ...state,
        departmentWorkloadSelectedFilters: {
          ...state.departmentWorkloadSelectedFilters,
          [payload.name]: [...state.departmentWorkloadSelectedFilters[payload.name], payload.value],
        },
      };

    case REMOVE_DEPARTMENT_WORKLOAD_FILTER:
      return {
        ...state,
        departmentWorkloadSelectedFilters: {
          ...state.departmentWorkloadSelectedFilters,
          [payload.name]: state.departmentWorkloadSelectedFilters[payload.name].filter((filterValue) => filterValue !== payload.value),
        },
      };

    case SET_ALL_DEPARTMENT_WORKLOAD_FILTER:
      return {
        ...state,
        departmentWorkloadSelectedFilters: {
          ...state.departmentWorkloadSelectedFilters,
          [payload]: [],
        },
      };

    case SET_DEPARTMENT_WORKLOAD_DATE_FILTER:
      return {
        ...state,
        dateFilter: payload,
      };

    default:
      return state;
  }
};

export default departmentWorkloadReducer;
