import PropTypes from 'prop-types';

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const stringOrNumberPropTypeRequired = PropTypes.oneOfType([
  PropTypes.string.isRequired,
  PropTypes.number.isRequired,
]);

export const stringOrNumberPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);