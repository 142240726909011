export const GET_GAME_SETTING_START = 'GET_GAME_SETTING_START';
export const GET_GAME_SETTING_FAIL = 'GET_GAME_SETTING_FAIL';
export const GET_GAME_SETTING_SUCCESS = 'GET_GAME_SETTING_SUCCESS';

export const UPDATE_GAME_SETTING_START = 'UPDATE_GAME_SETTING_START';
export const UPDATE_GAME_SETTING_FAIL = 'UPDATE_GAME_SETTING_FAIL';
export const UPDATE_GAME_SETTING_SUCCESS = 'UPDATE_GAME_SETTING_SUCCESS';

export const CREATE_GAME_SETTING_START = 'CREATE_GAME_SETTING_START';
export const CREATE_GAME_SETTING_FAIL = 'CREATE_GAME_SETTING_FAIL';
export const CREATE_GAME_SETTING_SUCCESS = 'CREATE_GAME_SETTING_SUCCESS';
