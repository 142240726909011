import TextBannerItemModel from './TextBannerItemModel';
import { AD_CREATE_BANNER_ITEM_TYPES } from 'constants/CreativeTool';

/**
 * @param {number} id
 * @param {string} type
 * @param {string} text
 * @param {null} fontId
 * @param {Object} styles
 * @param {Object[]} nestedBannerItems
 */
class ContainerBannerItemModel {
  constructor(bannerItem) {
    this.id = bannerItem.id;
    this.type = AD_CREATE_BANNER_ITEM_TYPES.CONTAINER;
    this.text = bannerItem.text;
    this.fontId = bannerItem.fontId;
    this.styles = bannerItem.styles;
    this.nestedBannerItems = bannerItem.nestedBannerItems.map((nestedBannerItem) => new TextBannerItemModel(nestedBannerItem, nestedBannerItem.id));
  }
}

export default ContainerBannerItemModel;
