import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RestrictedPermissions from '../RestrictedPermissions';
import { store } from 'store/store';
import { childrenPropType } from 'types/general';
import {
  PERMISSIONS,
  GAME_ACCESS_TYPE,
} from 'constants/General';


const Permission = (props) => {
  const {
    restrictedDepartments,
    allowedRoles,
    userDepartment,
    userRole,
    routeProps,
    children,

    userPermissions,
    allowedPermissions,
  } = props;

  if (!userPermissions || !userPermissions.length) {
    return null;
  }

  if (allowedPermissions) {
    const hasAllPermissions = allowedPermissions.every((p) => userPermissions.includes(p));

    if (!hasAllPermissions) {
      return null;
    }
  }

  if (!userDepartment || !userRole) {
    return null;
  }

  if (routeProps) {
    if (!restrictedDepartments.includes(userDepartment) && allowedRoles.includes(userRole || '')) {
      return React.cloneElement(children, { ...routeProps });
    }

    return <RestrictedPermissions />;
  }

  return <DepartmentPermission {...props} />;
};

const DepartmentPermission = ({
  restrictedDepartments,
  userDepartment,
  ...props
}) => {
  if (!restrictedDepartments.includes(userDepartment)) {
    return <RolesPermission {...props} />;
  }

  return null;
};

const RolesPermission = ({
  userRole,
  allowedRoles,
  children,
}) => {
  if (allowedRoles.includes(userRole || '')) {
    return children;
  }

  return null;
};

const departmentPropTypes = {
  routeProps: PropTypes.object,
  restrictedDepartments: PropTypes.arrayOf(PropTypes.string),
  userDepartment: PropTypes.string.isRequired,
};

const departmentDefaultProps = {
  restrictedDepartments: [],
  routeProps: null,
};

const rolesPropTypes = {
  userRole: PropTypes.string.isRequired,
  allowedRoles: PropTypes.array,
  routeProps: PropTypes.object,
  children: childrenPropType,
};

const rolesDefaultProps = {
  userRole: '',
  allowedRoles: [PERMISSIONS.DEFAULT],
};

DepartmentPermission.propTypes = departmentPropTypes;
DepartmentPermission.defaultProps = departmentDefaultProps;

RolesPermission.propTypes = rolesPropTypes;
RolesPermission.defaultProps = rolesDefaultProps;

Permission.propTypes = {
  ...departmentPropTypes,
  ...rolesPropTypes,
  userPermissions: PropTypes.arrayOf(PropTypes.string),
  allowedPermissions: PropTypes.arrayOf(PropTypes.string),
};

Permission.defaultProps = {
  ...departmentDefaultProps,
  ...rolesDefaultProps,
};

const mapStateToProps = (state) => ({
  userPermissions: state.user.profile.permissions,
  userRole: state.user.profile.role,
  userDepartment: state.user.profile.department ? state.user.profile.department.name : '',
});

export const checkRolePermissions = (allowedRoles) => {
  const userRole = store.getState().user.get('profile').role;

  return allowedRoles.includes(userRole || '');
};

export const checkPermissions = (gameId, gameAccessType, ownerId, permissionName = 'admin') => {
  const userProfile = store.getState().user.get('profile');
  const userPermissions = userProfile.permissions;

  if (userProfile.googleId) {
    const allowedGames = userProfile.externalPermission.games;

    return !!allowedGames.find((game) => game.gameId === parseInt(gameId) && game.access[gameAccessType]);
  } else {
    if (gameAccessType === GAME_ACCESS_TYPE.EDIT_CREATIVE_POST && userProfile.id !== ownerId) {
      return !!userPermissions && userPermissions.some((permission) => permission.includes(permissionName));
    } else {
      return true;
    }
  }
};

export const checkIfExternalUser = () => {
  return !!store.getState().user.get('profile').googleId;
};

export default connect(mapStateToProps)(Permission);
