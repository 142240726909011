import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import './Pagination.scss';


const Pagination = ({
  totalPages,
  currentPage,
  handlePageChanged,
  disabled,
}) => {

  const switchPage = (value) => {
    handlePageChanged(currentPage + value);
  };

  const skipPage = (value) => {
    let newPage = currentPage + value;

    if (newPage < 1) {
      newPage = 1;
    }

    if (newPage > totalPages) {
      newPage = totalPages;
    }

    handlePageChanged(newPage);
  };

  const selectPage = (page) => {
    handlePageChanged(page);
  };

  const pages = (
    !!totalPages && Array(totalPages).fill().map((item, index, array) => {
      const indexOfCurrentPage = currentPage - 1;

      if (
        (array.length > 7) &&
        (index > 0 && index < array.length - 1) &&
        (index !== indexOfCurrentPage) &&
        (index !== indexOfCurrentPage - 1) &&
        (index !== indexOfCurrentPage + 1)
      ) {
        return;
      } else if (index === indexOfCurrentPage - 1 && index > 1 && array.length > 7) {
        return (
          <React.Fragment key={index}>
            <div
              className='custom-pagination__dots'
              onClick={() => skipPage(-5)}
            >
              ...
            </div>
            <div
              onClick={() => selectPage(index + 1)}
              className={classnames('custom-pagination__page', {
                'active': currentPage === index + 1,
              })}
            >
              {index + 1}
            </div>
          </React.Fragment>
        );
      } else if (index === indexOfCurrentPage + 1 && index < array.length - 2 && array.length > 7) {
        return (
          <React.Fragment key={index}>
            <div
              onClick={() => selectPage(index + 1)}
              className={classnames('custom-pagination__page', {
                'active': currentPage === index + 1,
              })}
            >
              {index + 1}
            </div>
            <div
              className='custom-pagination__dots flip'
              onClick={() => skipPage(5)}
            >
              ...
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div
            key={index}
            onClick={() => selectPage(index + 1)}
            className={classnames('custom-pagination__page', {
              'active': currentPage === index + 1,
            })}
          >
            {index + 1}
          </div>
        );
      }
    })
  );

  return (
    <div
      className={classnames('custom-pagination', {
        'disabled': disabled,
      })}
    >
      <div
        className={classnames('custom-pagination__arrow', {
          'disabled': currentPage <= 1,
        })}
        onClick={() => switchPage(-1)}
      >
        <NavigateBeforeIcon className='custom-pagination__arrow-icon' />
      </div>

      <div className='custom-pagination__pages'>
        {pages}
      </div>

      <div
        className={classnames('custom-pagination__arrow', {
          'disabled': currentPage >= totalPages,
        })}
        onClick={() => switchPage(1)}
      >
        <NavigateNextIcon className='custom-pagination__arrow-icon' />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Pagination;
