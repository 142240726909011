
export const departmentWorkloadFilterNames = {
  POD: 'pods',
  GAME: 'games',
  DEPARTMENT: 'departments',
  CREATIVE_FORMAT: 'formats',
  ISSUE_TYPE: 'issueTypes',
  PROJECT_WORKFLOW: 'projectWorkflows',
};

export const initDataLimit = 10;