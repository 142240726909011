import {
  EDITOR_MEDIA_CACHE_START,
  EDITOR_MEDIA_CACHE_END,
  ADD_TO_MEDIA_CACHE,
} from './actionTypes';

const initState = {
  editorMediaCacheLoaded: false,
  editorMediaCache: {},
};


const videoEditorCacheReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case EDITOR_MEDIA_CACHE_START: {
      return {
        ...state,
        editorMediaCacheLoaded: false,
      };
    }

    case ADD_TO_MEDIA_CACHE: {
      const transformedEditorMediaCache = {
        ...state.editorMediaCache,
      };

      for (const media of payload) {
        transformedEditorMediaCache[media.id] = media;
      }

      return {
        ...state,
        editorMediaCache: transformedEditorMediaCache,
      };
    }

    case EDITOR_MEDIA_CACHE_END: {
      return {
        ...state,
        editorMediaCacheLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default videoEditorCacheReducer;
