import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CloseModalIcon } from 'assets/svg/ic-close-modal.svg';
import MaterialModal from '@material-ui/core/Modal';
import { childrenPropType } from 'types/general';

import styles from './styles.module.scss';


const ModalWrapper = ({ className, ...props }) => (
  <MaterialModal {...props} className={classNames([styles.modal, className])}>
    <div className={styles.container}>
      {!props.closeDisabled &&
        <CloseModalIcon className={styles.closeModalButton} onClick={props.onClose} />
      }

      {props.children}
    </div>
  </MaterialModal>
);

ModalWrapper.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  closeDisabled: PropTypes.bool,
  children: childrenPropType,
};

export default ModalWrapper;
