import { SET_ASSETS_TO_DELETE } from './actionsTypes';

const initState = {
  assetsToDeleteIds: [],
  actionAfterDelete: undefined,
  updateElastic: false,
};

const assetsToDeleteReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case SET_ASSETS_TO_DELETE:
      return payload;

    default:
      return state;
  }
};

export default assetsToDeleteReducer;