import {
  CREATE_NEW_FOLDER_CREATIVE_REQUEST,
  CREATE_NEW_FOLDER_CREATIVE_SUCCESS,
  CREATE_NEW_FOLDER_CREATIVE_FAIL,

  UPLOAD_DOC_CREATIVE_REQUEST,
  UPLOAD_DOC_CREATIVE_SUCCESS,
  UPLOAD_DOC_CREATIVE_FAIL,
} from '../actionTypes';
import Creative from 'services/Creative';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import { getErrorMessage } from 'utils/utils';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const createNewFolder = (data) => (dispatch) => {
  dispatch({ type: CREATE_NEW_FOLDER_CREATIVE_REQUEST });

  return Creative.createNewFolder(data)
    .then(() => {
      dispatch({ type: CREATE_NEW_FOLDER_CREATIVE_SUCCESS });

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.folder.success.create.text,
        description: notifications.folder.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: CREATE_NEW_FOLDER_CREATIVE_FAIL,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.folder.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const uploadCreativeAsset = (asset, gameId, creativeId, storageId) => (dispatch) => {
  dispatch({ type: UPLOAD_DOC_CREATIVE_REQUEST });

  const formData = new FormData();

  formData.append('creativityId', creativeId);
  formData.append('storageId', storageId);
  formData.append('dirPath', asset.filePath);
  formData.append('category', asset.category);
  formData.append('subCategory', asset.subCategory);
  formData.append('productionFormat', asset.productionFormat);
  formData.append('developerFormat', asset.developerFormat);
  formData.append('description', asset.description.trim());
  formData.append('attributes', asset.attributesJSON);
  formData.append('files', asset.file);

  return Creative.uploadDoc(formData, gameId)
    .then(() => {
      dispatch({
        type: UPLOAD_DOC_CREATIVE_SUCCESS,
        payload: asset.name,
      });
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_DOC_CREATIVE_FAIL,
        payload: asset.file.name,
        error: getErrorMessage(error),
      });

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.asset.fail.upload.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
