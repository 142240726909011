import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Permission from '../Permission';
import { restrictPageAccess } from 'components/Navigation/Navigation.redux/actions';
import { DEPARTMENT_PERMISSION, PERMISSIONS as P } from 'constants/General';

import { ReactComponent as NotFoundDesktop } from 'assets/svg/403-desk.svg';
import { ReactComponent as NotFoundMobile } from 'assets/svg/403-mob.svg';

import './RestrictedPermissions.scss';


const RestrictedPermissions = (props) => {
  const { isMobile, clearPageRestriction } = props;

  return isMobile
    ? (
      <div className='not-found-page mob'>
        <div className='not-found-page__description'>
          <p>Unauthorized user. You can&#39;t view this page. Contact admin to change your level of access.</p>
        </div>
        <div className='not-found-page__logo'>
          <NotFoundMobile />
        </div>
        <div className='not-found-page__bottom-navigation'>
          <Permission
            allowedRoles={P.ALL}
            restrictedDepartments={[DEPARTMENT_PERMISSION.DEVELOPMENT]}
          >
            <Link to='/dashboard/1' onClick={() => { clearPageRestriction(); }}>
              <div className='not-found-page__bottom-navigation__button first'>
                <span>TO DASHBOARD</span>
              </div>
            </Link>
          </Permission>
          <Link to='/creatives' onClick={() => { clearPageRestriction(); }}>
            <div className='not-found-page__bottom-navigation__button second'>
              <span>TO CREATIVES</span>
            </div>
          </Link>
        </div>
      </div>
    )
    : (
      <div className='not-found-page mob'>
        <div className='not-found-logo'>
          <NotFoundDesktop />
        </div>
        <div className='not-found-bottom-navigation-desk'>
          <div className='not-found-page__bottom-navigation'>
            <Permission
              allowedRoles={P.ALL}
              restrictedDepartments={[DEPARTMENT_PERMISSION.DEVELOPMENT]}
            >
              <Link to='/dashboard/1' onClick={() => { clearPageRestriction(); }}>
                <div className='not-found-page__bottom-navigation__button first'>
                  <span>TO DASHBOARD</span>
                </div>
              </Link>
            </Permission>
            <Link to='/creatives' onClick={() => { clearPageRestriction(); }}>
              <div className='not-found-page__bottom-navigation__button second'>
                <span>TO CREATIVES</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
};

RestrictedPermissions.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  clearPageRestriction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMobile: state.navigation.isMobile,
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearPageRestriction() {
      dispatch(restrictPageAccess({
        isRestricted: false,
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedPermissions);
