import {
  SET_MONTH_STAFF_ALLOCATION,
  SET_YEAR_STAFF_ALLOCATION,

  GET_STAFF_ALLOCATION_START,
  GET_STAFF_ALLOCATION_SUCCESS,
  GET_STAFF_ALLOCATION_FAIL,

  SET_RECORD_TO_EDIT_STAFF_ALLOCATION,
} from './actionsTypes';
import UserAllocationModel from '../Models/UserAllocationModel';
import GameAllocationModel from '../Models/GameAllocationModel';


const initState = {
  month: {id: new Date().getMonth(), value: new Date().toLocaleDateString('en', {month: 'long'})},
  year: new Date().getFullYear(),
  allocation: {
    loading: false,
    data: {
      games: [],
      users: [],
    },
    error: '',
  },
  recordToEdit: {
    id: undefined,
    userId: null,
    gameId: null,
    value: 0,
  },
};

const staffAllocationReducer = (state = initState, action) => {
  const {type, payload} = action;

  switch (type) {

    case SET_MONTH_STAFF_ALLOCATION:
      return {
        ...state,
        month: payload,
      };

    case SET_YEAR_STAFF_ALLOCATION:
      return {
        ...state,
        year: payload,
      };

    case GET_STAFF_ALLOCATION_START:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          loading: true,
          error: '',
        },
      };

    case GET_STAFF_ALLOCATION_SUCCESS:
      return {
        ...state,
        allocation: {
          loading: false,
          data: {
            games: payload.games.map((game) => new GameAllocationModel(game)),
            users: payload.users.map((user) => new UserAllocationModel(user)),
          },
          error: '',
        },
      };

    case GET_STAFF_ALLOCATION_FAIL:
      return {
        ...state,
        allocation: {
          ...state.allocation,
          loading: false,
          error: payload,
        },
      };

    case SET_RECORD_TO_EDIT_STAFF_ALLOCATION:
      return {
        ...state,
        recordToEdit: payload,
      };

    default:
      return state;
  }
};

export default staffAllocationReducer;