
export const ASSET_DRAG_AND_DROP_ACCEPT_TYPE = {
  CREATIVE: 'CREATIVE',
  GAME: 'GAME',
  COLLECTION: 'COLLECTION',
};

export const ASSET_TYPE = {
  DOC: 'doc',
  AUDIO: 'audio',
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'other',
};

export const IMAGE_TAG_SUPPORTED_EXTENSIONS = [
  'apng',
  'avif',
  'gif',
  'jpg',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'png',
  'svg',
  'webp',
];

export const ASSET_CATEGORIES_KEYS = {
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  PRODUCTION_FORMAT: 'productionFormat',
  DEVELOPER_FORMAT: 'developerFormat',
};

export const ASSET_CATEGORIES_FOR_CREATIVE_ASSETS = {
  Static: 'TP Final 2D',
  Video: 'TP Final Video',
  '3D': 'TP Final 3D',
  Playable: 'TP Final Playable',
};

export const ASSET_CATEGORIES_FOR_GAME_ASSETS = {
  PRODUCTION_ASSETS: 'Production assets',
  DEVELOPER_ASSETS: 'Developer assets',
  GAMEPLAY_ASSETS: 'Gameplay assets',
};

export const ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS = {
  '2D': '2D',
  ANIMATION_ASSETS: 'Animation assets',
  AUDIO: 'Audio',
  VIDEO: 'Video',
};

export const ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS = {
  // 2D
  CHARACTERS: 'Characters',
  BRANDING_ASSETS: 'Branding assets',
  BACKGROUNDS: 'Backgrounds',
  UI: 'UI',
  STOCK_IMAGES: 'Stock images',
  PROPS: 'Props',
  // Audio
  SOUNDTRACK: 'Soundtrack',
  SFX: 'SFX',
  VO: 'VO',
  // Video
  VFX: 'VFX',
  STOCK_FOOTAGE: 'Stock Footage',
  LOWER_THIRDS: 'Lower Thirds',
  END_CARDS: 'Endcards',
  ANIMATION_ASSETS: 'Animation assets',
};

export const ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS = {
  // 2D
  CHARACTERS: 'Characters',
  KEY_ART: 'Key art and Splash screen',
  BRANDING_ASSETS: 'Branding assets',
  BACKGROUNDS: 'Backgrounds',
  UI: 'UI',
  PROPS: 'Props',
  // Audio
  SOUNDTRACK: 'Soundtrack',
  SFX: 'SFX',
  VO: 'VO',
};

export const ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS_BY_CATEGORY = {
  [ASSET_CATEGORIES_FOR_GAME_ASSETS.PRODUCTION_ASSETS]: [
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS['2D'],
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.AUDIO,
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.VIDEO,
  ],
  [ASSET_CATEGORIES_FOR_GAME_ASSETS.DEVELOPER_ASSETS]: [
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS['2D'],
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.AUDIO,
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.ANIMATION_ASSETS,
    ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.VIDEO,
  ],
};

export const ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY = {
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS['2D']]: [
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.CHARACTERS,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.BRANDING_ASSETS,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.BACKGROUNDS,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.UI,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.STOCK_IMAGES,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.PROPS,
  ],
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.AUDIO]: [
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.SOUNDTRACK,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.SFX,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.VO,
  ],
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.VIDEO]: [
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.VFX,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.STOCK_FOOTAGE,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.LOWER_THIRDS,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.END_CARDS,
    ASSET_PRODUCTION_FORMATS_FOR_GAME_ASSETS.ANIMATION_ASSETS,
  ],
};

export const ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS_BY_SUB_CATEGORY = {
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS['2D']]: [
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.CHARACTERS,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.KEY_ART,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.BRANDING_ASSETS,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.BACKGROUNDS,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.UI,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.PROPS,
  ],
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.AUDIO]: [
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.SOUNDTRACK,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.SFX,
    ASSET_DEVELOPER_FORMATS_FOR_GAME_ASSETS.VO,
  ],
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.VIDEO]: [],
  [ASSET_SUB_CATEGORIES_FOR_GAME_ASSETS.ANIMATION_ASSETS]: [],
};

export const ASSET_ATTRIBUTE_NAMES = {
  TYPE: 'type',
  FEATURE: 'feature',
};
