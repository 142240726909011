import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Zoom } from '@material-ui/core';
import classnames from 'classnames';

import './ListItemWithCheckbox.scss';


const ListItemWithCheckbox = ({
  item,
  nameToShow,
  checkedCondition,
  handleCheck,
  disabledCondition,
  additionalClassNames,
  withTooltip,
  endPart,
  colorExample,
  radio,
  pink,
}) => {
  return (
    <label
      className={classnames('options-checkbox-item', {
        [additionalClassNames]: additionalClassNames,
        'disabled': disabledCondition,
        'endPart': endPart,
        'pink': pink,
      })}
    >
      <input
        type='checkbox'
        checked={!!checkedCondition}
        onChange={(event) => handleCheck(event)}
        disabled={disabledCondition}
      />

      {radio ?
        <div className='options-checkbox-item__radio-label' /> :
        <div className='options-checkbox-item__label' />
      }

      {colorExample}

      {withTooltip ?
        <Tooltip title={item.name ?? ''} TransitionComponent={Zoom}>
          <div className='options-checkbox-item__name'>{nameToShow ? item[nameToShow] : item}</div>
        </Tooltip> :
        <div className='options-checkbox-item__name'>{nameToShow ? item[nameToShow] : item}</div>
      }

      {endPart}
    </label>
  );
};

ListItemWithCheckbox.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  nameToShow: PropTypes.string,
  checkedCondition: PropTypes.bool,
  handleCheck: PropTypes.func.isRequired,
  disabledCondition: PropTypes.bool,
  additionalClassNames: PropTypes.string,
  withTooltip: PropTypes.bool,
  endPart: PropTypes.element,
  colorExample: PropTypes.element,
  radio: PropTypes.bool,
  pink: PropTypes.bool,
};

export default ListItemWithCheckbox;
