import cloneDeep from 'lodash/cloneDeep';
import StudioDashboardModel from '../Models/StudioDashboardModel';
import GameDashboardModel from '../Models/GameDashboardModel';
import PostDashboardModel from '../Models/PostDashboardModel';
import { headerTitlesOrder } from '../dashboardConstants';
import {
  GET_STUDIOS_START,
  GET_STUDIOS_SUCCESS,
  GET_STUDIOS_SUCCESS_SCROLL,
  GET_STUDIOS_FAIL,

  GET_GAMES_NUMBERS_START,
  GET_GAMES_NUMBERS_SUCCESS,
  GET_GAMES_NUMBERS_SUCCESS_SCROLL,
  GET_GAMES_NUMBERS_FAIL,

  GET_POSTS_NUMBERS_START,
  GET_POSTS_NUMBERS_SUCCESS,
  GET_POSTS_NUMBERS_SUCCESS_SCROLL,
  GET_POSTS_NUMBERS_FAIL,

  SET_POST_ACTION_START,
  SET_POST_ACTION_SUCCESS,
  SET_POST_ACTION_FAIL,

  GET_HEADER_TITLES_SUCCESS_DASHBOARD,
  CHANGE_HEADER_TITLES_SUCCESS_DASHBOARD,
  SET_HEADER_TITLES_DASHBOARD,
  SET_HEADER_TITLES_EDIT_STATE_DASHBOARD,
  SET_HEADER_TITLES_VALIDATION_DASHBOARD,

  SET_DASHBOARD_GENERAL_FILTER,
  TOGGLE_DEPARTMENT_DASHBOARD,
  CLEAR_CHECKED_DEPARTMENTS_DASHBOARD,

  SET_HOURLY_RATE_SUCCESS,
} from './actionsTypes';


const initState = {
  studios: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
      generalTotal: {
        amount: 0,
        cost: 0,
      },
    },
    error: '',
  },
  games: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
      generalTotal: {
        amount: 0,
        cost: 0,
      },
    },
    error: '',
  },
  posts: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
      generalTotal: {
        amount: 0,
        cost: 0,
        timeEstimate: 0,
        timeSpent: 0,
      },
    },
    error: '',
  },
  headerTitles: {
    studios: {
      id: '',
      init: {},
      current: {},
      isEditState: false,
      validation: {},
    },
    games: {
      id: '',
      init: {},
      current: {},
      isEditState: false,
      validation: {},
    },
    posts: {
      id: '',
      init: {},
      current: {},
      isEditState: false,
      validation: {},
    },
  },
  generalFilter: {
    created_at: {
      month: {id: new Date().getMonth().toString(), value: new Date().toLocaleDateString('en', {month: 'long'})},
      year: new Date().getFullYear().toString(),
    },
  },
  postAction: {
    loading: false,
    success: false,
    error: false,
  },

  existingDepartments: [],
  checkedDepartments: [],

  exportToPDFStatus: {
    loading: false,
    success: false,
    error: false,
  },

  hourlyRate: 0,
};


const dashboardReducer = (state = initState, action) => {
  const { type, payload } = action;
  let headerTitlesEntries;
  let headerTitlesEntriesValidation;
  let cloneCheckedDepartments;

  switch (type) {

    case GET_STUDIOS_START:
      return {
        ...state,
        studios: {
          ...state.studios,
          loading: true,
        },
      };

    case GET_STUDIOS_SUCCESS:
      return {
        ...state,
        studios: {
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((studio) => new StudioDashboardModel(studio)),
          },
          error: '',
        },
        hourlyRate: payload.hourlyRate,
      };

    case GET_STUDIOS_SUCCESS_SCROLL:
      return {
        ...state,
        studios: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.studios.success.data,
              ...payload.data.map((studio) => new StudioDashboardModel(studio)),
            ],
          },
          error: '',
        },
        hourlyRate: payload.hourlyRate,
      };

    case GET_STUDIOS_FAIL:
      return {
        ...state,
        studios: {
          ...state.studios,
          loading: false,
          error: payload,
        },
      };

    case GET_GAMES_NUMBERS_START:
      return {
        ...state,
        games: {
          ...state.games,
          loading: true,
        },
      };

    case GET_GAMES_NUMBERS_SUCCESS:
      return {
        ...state,
        games: {
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((game) => new GameDashboardModel(game)),
          },
          error: '',
        },
      };

    case GET_GAMES_NUMBERS_SUCCESS_SCROLL:
      return {
        ...state,
        games: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.games.success.data,
              ...payload.data.map((game) => new GameDashboardModel(game)),
            ],
          },
          error: '',
        },
      };

    case GET_GAMES_NUMBERS_FAIL:
      return {
        ...state,
        games: {
          ...state.games,
          loading: false,
          error: payload,
        },
      };

    case GET_POSTS_NUMBERS_START:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: true,
        },
      };

    case GET_POSTS_NUMBERS_SUCCESS:
      return {
        ...state,
        existingDepartments: payload.departments.map((item) => item['department']),
        posts: {
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((post) => new PostDashboardModel(post)),
          },
          error: '',
        },
      };

    case GET_POSTS_NUMBERS_SUCCESS_SCROLL:
      return {
        ...state,
        posts: {
          loading: false,
          success: {
            ...payload,
            data: [
              ...state.posts.success.data,
              ...payload.data.map((post) => new PostDashboardModel(post)),
            ],
          },
          error: '',
        },
      };

    case GET_POSTS_NUMBERS_FAIL:
      return {
        ...state,
        posts: {
          ...state.posts,
          loading: false,
          error: payload,
        },
      };

    case GET_HEADER_TITLES_SUCCESS_DASHBOARD:
      headerTitlesEntries = headerTitlesOrder[payload.tableName].map((title) => [title, payload.data.metadata[title]]);
      headerTitlesEntriesValidation = headerTitlesOrder[payload.tableName].map((title) => [title, null]);

      return {
        ...state,
        headerTitles: {
          ...cloneDeep(state.headerTitles),
          [payload.tableName]: {
            id: payload.data.id,
            init: Object.fromEntries(headerTitlesEntries),
            current: Object.fromEntries(headerTitlesEntries),
            isEditState: false,
            validation: Object.fromEntries(headerTitlesEntriesValidation),
          },
        },
      };

    case CHANGE_HEADER_TITLES_SUCCESS_DASHBOARD:
      return {
        ...state,
        headerTitles: {
          ...cloneDeep(state.headerTitles),
          [payload.tableName]: {
            ...state.headerTitles[payload.tableName],
            init: {...payload.data},
            current: {...payload.data},
            isEditState: false,
          },
        },
      };

    case SET_HEADER_TITLES_DASHBOARD:
      return {
        ...state,
        headerTitles: {
          ...cloneDeep(state.headerTitles),
          [payload.tableName]: {
            ...state.headerTitles[payload.tableName],
            current: {...payload.data},
          },
        },
      };

    case SET_HEADER_TITLES_EDIT_STATE_DASHBOARD:
      headerTitlesEntriesValidation = headerTitlesOrder[payload.tableName].map((title) => [title, null]);

      return {
        ...state,
        headerTitles: {
          ...cloneDeep(state.headerTitles),
          [payload.tableName]: {
            ...state.headerTitles[payload.tableName],
            isEditState: payload.data,
            validation: Object.fromEntries(headerTitlesEntriesValidation),
          },
        },
      };

    case SET_HEADER_TITLES_VALIDATION_DASHBOARD:
      return {
        ...state,
        headerTitles: {
          ...cloneDeep(state.headerTitles),
          [payload.tableName]: {
            ...state.headerTitles[payload.tableName],
            validation: payload.data,
          },
        },
      };

    case SET_POST_ACTION_START:
      return {
        ...state,
        postAction: {
          loading: true,
          success: false,
          error: false,
        },
      };

    case SET_POST_ACTION_SUCCESS:
      return {
        ...state,
        postAction: {
          loading: false,
          success: true,
          error: false,
        },
      };

    case SET_POST_ACTION_FAIL:
      return {
        ...state,
        postAction: {
          loading: false,
          success: false,
          error: true,
        },
      };

    case SET_DASHBOARD_GENERAL_FILTER:
      return {
        ...state,
        generalFilter: {
          ...state.generalFilter,
          [payload.filterName]: cloneDeep(payload.filterData),
        },
      };

    case TOGGLE_DEPARTMENT_DASHBOARD:
      cloneCheckedDepartments = [...state.checkedDepartments];

      if (!cloneCheckedDepartments.includes(payload)) {
        cloneCheckedDepartments.push(payload);
      } else {
        cloneCheckedDepartments.splice(cloneCheckedDepartments.indexOf(payload), 1);
      }

      return {
        ...state,
        checkedDepartments: cloneCheckedDepartments,
      };

    case CLEAR_CHECKED_DEPARTMENTS_DASHBOARD:
      return {
        ...state,
        checkedDepartments: [],
      };

    case SET_HOURLY_RATE_SUCCESS:
      return {
        ...state,
        hourlyRate: payload,
      };

    default:
      return state;
  }
};

export default dashboardReducer;
