export const SET_SELECTED_CREATIVES = 'reports/SET_SELECTED_CREATIVES';

export const SEND_REPORT_REQUEST = 'reports/SEND_REPORT_REQUEST';
export const SEND_REPORT_SUCCESS = 'reports/SEND_REPORT_SUCCESS';
export const SEND_REPORT_FAIL = 'reports/SEND_REPORT_FAIL';
export const GET_CREATIVE_REPORTS_REQUEST = 'reports/GET_CREATIVE_REPORTS_REQUEST';
export const GET_CREATIVE_REPORTS_SUCCESS = 'reports/GET_CREATIVE_REPORTS_SUCCESS';
export const GET_CREATIVE_REPORTS_FAIL = 'reports/GET_CREATIVE_REPORTS_FAIL';
export const GET_ALL_EMAILS_REQUEST = 'reports/GET_ALL_EMAILS_REQUEST';
export const GET_ALL_EMAILS_SUCCESS = 'reports/GET_ALL_EMAILS_SUCCESS';
export const GET_ALL_EMAILS_FAIL = 'reports/GET_ALL_EMAILS_FAIL';
export const OPEN_MODAL_DIALOG_REPORTS = 'reports/OPEN_MODAL_DIALOG_REPORTS';
export const CLOSE_MODAL_DIALOG_REPORTS = 'reports/CLOSE_MODAL_DIALOG_REPORTS';
