import {
  GET_ATTRIBUTES_REQUEST,
  GET_ATTRIBUTES_SUCCESS,
  GET_ATTRIBUTES_FAIL,

  CREATE_ATTRIBUTE_OPTION_REQUEST,
  CREATE_ATTRIBUTE_OPTION_SUCCESS,
  CREATE_ATTRIBUTE_OPTION_FAIL,

  UPDATE_ATTRIBUTE_OPTION_REQUEST,
  UPDATE_ATTRIBUTE_OPTION_SUCCESS,
  UPDATE_ATTRIBUTE_OPTION_FAIL,

  DELETE_ATTRIBUTE_OPTION_REQUEST,
  DELETE_ATTRIBUTE_OPTION_SUCCESS,
  DELETE_ATTRIBUTE_OPTION_FAIL,

  CHANGE_ASSET_ATTRIBUTE_FAIL,
  CHANGE_ASSET_ATTRIBUTE_START,
  CHANGE_ASSET_ATTRIBUTE_SUCCESS,
} from '../actionTypes';
import AssetsAttribute from 'services/AssetsAttribute';
import Attribute from 'services/Attribute';
import { getErrorMessage } from 'utils/utils';
import { addGeneralMessageAction } from 'components/General/GeneralMessages/GeneralMessages.redux/actions';
import {
  SUCCESS_NOTIFICATION_LIVE_TIME,
  FAIL_NOTIFICATION_LIVE_TIME,
  notifications,
} from 'constants/Notifications';


export const getAttributes = () => (dispatch) => {
  dispatch({ type: GET_ATTRIBUTES_REQUEST });

  return Attribute.getAllAttributes()
    .then((attributes) => dispatch({ type: GET_ATTRIBUTES_SUCCESS, attributes }))
    .catch((error) => dispatch({ type: GET_ATTRIBUTES_FAIL, error: getErrorMessage(error) }));
};

export const createNewAttributeOption = (attributeId, value) => (dispatch) => {
  dispatch({ type: CREATE_ATTRIBUTE_OPTION_REQUEST });

  return Attribute.createNewAttributeOption(attributeId, value)
    .then(() => {
      dispatch({ type: CREATE_ATTRIBUTE_OPTION_SUCCESS });
      dispatch(getAttributes());
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tag.success.create.text,
        description: notifications.tag.success.create.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: CREATE_ATTRIBUTE_OPTION_FAIL,
        error: getErrorMessage(error),
      });
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tag.fail.create.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const updateAttributeOption = (attributeOptionId, value) => (dispatch) => {
  dispatch({ type: UPDATE_ATTRIBUTE_OPTION_REQUEST });

  return Attribute.updateAttributeOption(attributeOptionId, value)
    .then(() => {
      dispatch({ type: UPDATE_ATTRIBUTE_OPTION_SUCCESS });
      dispatch(getAttributes());
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tag.success.update.text,
        description: notifications.tag.success.update.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_ATTRIBUTE_OPTION_FAIL,
        error: getErrorMessage(error),
      });
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tag.fail.update.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const deleteAttributeOption = (attributeOptionId) => (dispatch) => {
  dispatch({ type: DELETE_ATTRIBUTE_OPTION_REQUEST });

  return Attribute.deleteAttributeOption(attributeOptionId)
    .then(() => {
      dispatch({ type: DELETE_ATTRIBUTE_OPTION_SUCCESS });
      dispatch(getAttributes());
      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.tag.success.delete.text,
        description: notifications.tag.success.delete.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({
        type: DELETE_ATTRIBUTE_OPTION_FAIL,
        error: getErrorMessage(error),
      });
      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.tag.fail.delete.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};

export const addNewAttributeToAsset = (data) => (dispatch) => {
  dispatch({type: CHANGE_ASSET_ATTRIBUTE_START});

  return AssetsAttribute.putAttributeToAsset(data)
    .then(() => {
      dispatch({type: CHANGE_ASSET_ATTRIBUTE_SUCCESS});

      dispatch(addGeneralMessageAction({
        error: false,
        text: notifications.attribute.success.set.text,
        description: notifications.attribute.success.set.description,
        lifeTime: SUCCESS_NOTIFICATION_LIVE_TIME,
      }));
    })
    .catch((error) => {
      dispatch({type: CHANGE_ASSET_ATTRIBUTE_FAIL});

      dispatch(addGeneralMessageAction({
        error: true,
        text: notifications.attribute.fail.set.text,
        description: getErrorMessage(error),
        lifeTime: FAIL_NOTIFICATION_LIVE_TIME,
      }));
    });
};
