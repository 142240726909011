import {
  CLOSE_UPLOAD_PROGRESS,
  OPEN_UPLOAD_PROGRESS,
} from './actionsTypes';


export const openUploadProgress = (files, areCreativeAssets) => (dispatch) => {
  dispatch({
    type: OPEN_UPLOAD_PROGRESS,
    payload: {
      files,
      areCreativeAssets,
    },
  });
};

export const closeUploadProgress = () => (dispatch) => {
  dispatch({
    type: CLOSE_UPLOAD_PROGRESS,
  });
};