import streamSaver from 'streamsaver';
import {
  addLocalTimeHeader,
  getToken,
} from '../../utils/FeathersClient';
import { API_PREFIX } from '../../constants/General';
import * as queryString from 'query-string';


export const downloadCollectionService = (collectionId, data) => {
  return getToken()
    .then((token) => fetch(`${API_PREFIX}collections/${collectionId}/download?${data}`, {
      method: 'GET',
      responseType: 'blob',
      headers: addLocalTimeHeader({
        'Authorization': token,
        'Content-Type': 'application/json',
      }),
    }));
};

export const downloadCollection = async (collectionId, collectionHash, fileName, collectionAssetIds) => {
  const params = {};

  if (collectionHash) {
    params['#'] = collectionHash;
  }

  if (collectionAssetIds) {
    params.ids = collectionAssetIds;
  }

  const paramsStringify = queryString.stringify(params, {arrayFormat: 'bracket'});
  const response = await downloadCollectionService(collectionId, paramsStringify);
  const fileStream = streamSaver.createWriteStream(fileName);
  const readableStream = response.body;

  if (window.WritableStream && readableStream.pipeTo) {
    await readableStream.pipeTo(fileStream);
    return;
  }

  // Write (pipe) manually
  window.writer = fileStream.getWriter();
  const reader = readableStream.getReader();

  const pump = async () => {
    const result = await reader.read();

    if (result.done) {
      window.writer.close();
    }
    else {
      await window.writer.write(result.value);
      await pump();
    }
  };

  await pump();
};