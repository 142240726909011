/**
 * @param {number} id
 * @param {string} url
 * @param {string} name
 */
class FontModel {
  constructor(data) {
    this.id = parseInt(data.docId);
    this.url = `/file-store/${data.storageUuid}/${data.docUrl}`;
    this.name = data.name;
  }
}

export default FontModel;
