import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../reducers';
import restrictedAccessMiddleware from '../middlewares/restrictedAccess';

export default function configureStore(initialState) {
  const middewares = [
    thunkMiddleware,
    restrictedAccessMiddleware,
  ];

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middewares))
  );
}
