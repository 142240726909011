import moment from 'moment';


export const extractCheckedFormatsId = (categories) => {
  return categories
    .flatMap((category) => category.formats)
    .filter((format) => format.checked)
    .map((format) => format.id);
};

export const findNewFilteredFormats = (category) => {
  const isAllFormatsChecked = category.formats.some((format) => !format.checked);

  if (isAllFormatsChecked) {
    return category.formats.map((format) => {
      return {
        ...format,
        checked: true,
      };
    });
  } else {
    return category.formats.map((format) => {
      return {
        ...format,
        checked: false,
      };
    });
  }
};

export const secondsToJiraHoursAndMinutes = (seconds) => {
  const h = Math.floor(seconds / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);

  if (s > 30) {
    m++;
  }

  const hDisplay = h > 0 ? h + 'H ' : '';
  const mDisplay = m > 0 ? m + 'M' : '';

  return hDisplay + mDisplay;
};

export const transformDateForPDFandCSV = (created_at) => {
  const {from, to} = created_at;

  return `${moment(from).format('YYYY.MM.DD')} — ${moment(to).format('YYYY.MM.DD')}`;
};

export const generateArrayOfYears = (min) => {
  const max = new Date().getFullYear();
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push({id: i, value: i});
  }

  return years;
};

export const generateArrayOfMonths = () => {
  return Array.from({length: 12}, (e, i) => {
    return {
      id: i,
      value: new Date(null, i + 1, null).toLocaleDateString('en', {month: 'long'}),
    };
  });
};
