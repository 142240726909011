export const COLLECTIONS_LIMIT = 10;

export const COLLECTIONS_PAGINATION_LOCAL_STORAGE_KEY = 'PixelCollectionsPagination';
export const COLLECTIONS_SORTING_LOCAL_STORAGE_KEY = 'PixelCollectionsSortOption';

export const COLLECTION_SORT_OPTIONS = [
  {id: 1, name: 'Date (NEWEST - OLDEST)', value: 'createdAt', order: -1},
  {id: 2, name: 'Date (OLDEST - NEWEST)', value: 'createdAt', order: 1},
  {id: 3, name: 'Name (A to Z)', value: 'name', order: 1},
  {id: 4, name: 'Name (Z to A)', value: 'name', order: -1},
];