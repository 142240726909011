/**
 * @param {number} id
 * @param {string} exampleLink
 * @param {object} file
 * @param {string} url
 * @param {string} fileExtension
 * @param {boolean} isPreview
 * @param {string} thumbnail
 */
class ExampleModel {
  constructor(data, storage_uuid) {
    this.id = data.id;
    this.exampleLink = data.exampleLink;
    this.file = data.file;
    this.url = `/file-store/${storage_uuid}/${data.file}`;
    this.fileExtension = data.fileExtension;
    this.isPreview = data.isPreview;
    this.thumbnail = data.thumbnail;
  }
}

export default ExampleModel;
