import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as PreloadPurpleIcon } from 'assets/svg/loader/purple-gradient.svg';

import './Preloader.scss';


const Preloader = ({ additionalClass, preloaderText }) => {
  return (
    <div className={classnames('preloader-container', additionalClass)}>
      <PreloadPurpleIcon className='preloader-icon' />
      {preloaderText && <div className='preloader-text'>{preloaderText}</div>}
    </div>
  );
};

Preloader.propTypes = {
  additionalClass: PropTypes.string,
  preloaderText: PropTypes.string,
};

export default Preloader;
