
export const RESET_AD_CREATE_PROJECT_EDITOR = 'RESET_AD_CREATE_PROJECT_EDITOR';

export const GET_GAME_VIDEOS_AD_CREATE_START = 'GET_GAME_VIDEOS_AD_CREATE_START';
export const GET_GAME_VIDEOS_AD_CREATE_SUCCESS = 'GET_GAME_VIDEOS_AD_CREATE_SUCCESS';
export const GET_GAME_VIDEOS_AD_CREATE_SUCCESS_SCROLL = 'GET_GAME_VIDEOS_AD_CREATE_SUCCESS_SCROLL';
export const GET_GAME_VIDEOS_AD_CREATE_FAIL = 'GET_GAME_VIDEOS_AD_CREATE_FAIL';

export const GET_IMAGE_OVERLAYS_AD_CREATE_START = 'GET_IMAGE_OVERLAYS_AD_CREATE_START';
export const GET_IMAGE_OVERLAYS_AD_CREATE_SUCCESS = 'GET_IMAGE_OVERLAYS_AD_CREATE_SUCCESS';
export const GET_IMAGE_OVERLAYS_AD_CREATE_FAIL = 'GET_IMAGE_OVERLAYS_AD_CREATE_FAIL';

export const GET_GAME_LOGOS_AD_CREATE_START = 'GET_GAME_LOGOS_AD_CREATE_START';
export const GET_GAME_LOGOS_AD_CREATE_SUCCESS = 'GET_GAME_LOGOS_AD_CREATE_SUCCESS';
export const GET_GAME_LOGOS_AD_CREATE_FAIL = 'GET_GAME_LOGOS_AD_CREATE_FAIL';

export const GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_START = 'GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_START';
export const GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_SUCCESS = 'GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_SUCCESS';
export const GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_FAIL = 'GET_SIZES_OF_SOCIAL_NETWORK_AD_CREATE_FAIL';

export const CREATE_AD_PROJECT_VIA_EDITOR_START = 'CREATE_AD_PROJECT_VIA_EDITOR_START';
export const CREATE_AD_PROJECT_VIA_EDITOR_SUCCESS = 'CREATE_AD_PROJECT_VIA_EDITOR_SUCCESS';
export const CREATE_AD_PROJECT_VIA_EDITOR_RESET = 'CREATE_AD_PROJECT_VIA_EDITOR_RESET';
export const CREATE_AD_PROJECT_VIA_EDITOR_FAIL = 'CREATE_AD_PROJECT_VIA_EDITOR_FAIL';

export const CREATE_AD_PROJECT_VIA_RANDOMIZER_START = 'CREATE_AD_PROJECT_VIA_RANDOMIZER_START';
export const CREATE_AD_PROJECT_VIA_RANDOMIZER_SUCCESS = 'CREATE_AD_PROJECT_VIA_RANDOMIZER_SUCCESS';
export const CREATE_AD_PROJECT_VIA_RANDOMIZER_RESET = 'CREATE_AD_PROJECT_VIA_RANDOMIZER_RESET';
export const CREATE_AD_PROJECT_VIA_RANDOMIZER_FAIL = 'CREATE_AD_PROJECT_VIA_RANDOMIZER_FAIL';

export const ADD_SIZE_TO_AD_CREATE_PROJECT = 'ADD_SIZE_TO_AD_CREATE_PROJECT';
export const REMOVE_SIZE_FROM_AD_CREATE_PROJECT = 'REMOVE_SIZE_FROM_AD_CREATE_PROJECT';
export const SET_ACTIVE_SIZE_OF_AD_CREATE_PROJECT = 'SET_ACTIVE_SIZE_OF_AD_CREATE_PROJECT';
export const UPDATE_FIELD_OF_AD_CREATE_PROJECT_SIZE = 'UPDATE_FIELD_OF_AD_CREATE_PROJECT_SIZE';
export const UPDATE_FIELD_OF_AD_CREATE_PROJECT_ACTIVE_SIZE = 'UPDATE_FIELD_OF_AD_CREATE_PROJECT_ACTIVE_SIZE';
export const UPDATE_BANNER_ITEMS_OF_ALL_PROJECT_SIZES = 'UPDATE_BANNER_ITEMS_OF_ALL_PROJECT_SIZES';

export const SET_VIDEO_FRAME_TIME_AD_CREATE_PROJECT = 'SET_VIDEO_FRAME_TIME_AD_CREATE_PROJECT';

export const SET_CROP_AREA_DIMENSION_AND_POSITION_AD_CREATE_PROJECT = 'SET_CROP_AREA_DIMENSION_AND_POSITION_AD_CREATE_PROJECT';

export const TOGGLE_IMAGE_FORMAT_AD_CREATE_PROJECT = 'TOGGLE_IMAGE_FORMAT_AD_CREATE_PROJECT';

export const SAVE_AD_CREATE_PROJECT_START = 'SAVE_AD_CREATE_PROJECT_START';
export const SAVE_AD_CREATE_PROJECT_SUCCESS = 'SAVE_AD_CREATE_PROJECT_SUCCESS';
export const SAVE_AD_CREATE_PROJECT_FAIL = 'SAVE_AD_CREATE_PROJECT_FAIL';
export const SAVE_AD_CREATE_PROJECT_RESET = 'SAVE_AD_CREATE_PROJECT_RESET';

export const RENDER_AD_CREATE_PROJECT_START = 'RENDER_AD_CREATE_PROJECT_START';
export const RENDER_AD_CREATE_PROJECT_SUCCESS = 'RENDER_AD_CREATE_PROJECT_SUCCESS';
export const RENDER_AD_CREATE_PROJECT_FAIL = 'RENDER_AD_CREATE_PROJECT_FAIL';
export const RENDER_AD_CREATE_PROJECT_RESET = 'RENDER_AD_CREATE_PROJECT_RESET';

export const ADD_OVERLAY_TO_AD_CREATE_PROJECT_SIZE = 'ADD_OVERLAY_TO_AD_CREATE_PROJECT_SIZE';