import {
  SET_ENGINE_QUERY_STRING,
  REPLACE_ENGINE_QUERY_STRINGS,
  TOGGLE_MAIN_SEARCH_RESULTS_VEIL,
  TOGGLE_MAIN_SEARCH_LOADING_AFTER_ACTION,
  SET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
  RESET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
} from './actionsTypes';


export const setEngineQueryStringAction = (engineName, engineQueryString) => {
  return {
    type: SET_ENGINE_QUERY_STRING,
    payload: {
      engineName,
      engineQueryString,
    },
  };
};

export const replaceEngineQueryStringsAction = (enginesQueryString) => {
  return {
    type: REPLACE_ENGINE_QUERY_STRINGS,
    payload: enginesQueryString,
  };
};

export const toggleMainSearchResultsVeilAction = (dropDownName, dropDownState) => {
  return {
    type: TOGGLE_MAIN_SEARCH_RESULTS_VEIL,
    payload: {
      dropDownName,
      dropDownState,
    },
  };
};

export const toggleMainSearchLoadingAfterAction = (loadingState) => {
  return {
    type: TOGGLE_MAIN_SEARCH_LOADING_AFTER_ACTION,
    payload: loadingState,
  };
};

export const setTriggerForRefreshingMainSearchAction = () => {
  return {
    type: SET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
  };
};

export const resetTriggerForRefreshingMainSearchAction = () => {
  return {
    type: RESET_TRIGGER_FOR_REFRESHING_MAIN_SEARCH_RESULTS,
  };
};
