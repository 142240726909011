import React, { useEffect, useState } from 'react';
import GameService from 'services/Game';
import { ReactComponent as AddIcon } from 'assets/svg/plus.svg';
import SearchBox from 'components/UIComponents/SearchBox';
import _ from 'lodash';
import './AutomatedUploads.scss';
import '../CreativeDashboard.scss';
import classNames from 'classnames';
import GameSettings from './GameSettings';
import Modal from 'components/UIComponents/Modal';
import GameEditForm from './GameEditForm';
import { MenuItem, Select } from '@material-ui/core';
import CustomBadge from 'components/UIComponents/CustomBadge';

const selectProps = {
  multiple: true,
  displayEmpty: true,
  MenuProps: {
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    style: {
      marginTop: 52,
      width: 156,
    },
  },
};

const AutomatedUploads = () => {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState();
  const [activeFilter, setActiveFilter] = useState([]);
  const [isNewGameModalOpen, setIsNewGameModalOpen] = useState(false);
  const [genres, setGenres] = useState([]);
  const [genresFilter, setGenresFilter] = useState([]);
  const [searchValue, setSearch] = useState('');
  const isFilters = Boolean(activeFilter.length || genresFilter.length);
  const getFileters = ({genresFilterValue, activeFilterValue} = {}) => {
    const activeData = activeFilterValue || activeFilter;
    const genresData = genresFilterValue || genresFilter;
    const isActiveFilter = activeData.includes('active');
    const isConcludedFilter = activeData.includes('concluded');
    const filter = {};
    if (isActiveFilter) {
      filter.isActive = true;
    }
    if (isConcludedFilter) {
      filter.isConcluded = true;
    }
    if (genresData.length > 0) {
      filter.genres = genresData.map((genre) => _.toLower(genre));
    }
    return filter;
  };

  useEffect(() => {
    (async () => {
      const games = await GameService.getGames();
      const genres = await GameService.getAllGenres();
      setGenres(genres);
      setGames(games.raw);  
    })();
  }, []);

  const handleSearch = _.debounce(async (value) => {
    setSearch(value);
    const filters = getFileters();
    const response = await GameService.getGames({
      text: value,
      isActive: filters.isActive,
      isConcluded: filters.isConcluded,
      genres: filters.genres, 
    });
    setGames(response.raw);
  }, 450);

  const handleCloseModal = async () => {
    setIsNewGameModalOpen(false);
    const response = await GameService.getGames();
    setGames(response.raw);
  };

  const handleActiveFilter = async (value) => {
    setActiveFilter(value);
    const filters = getFileters({activeFilterValue: value});
    const response = await GameService.getGames({
      text: searchValue,
      isActive: filters.isActive,
      isConcluded: filters.isConcluded,
      genres: filters.genres, 
    });
    setGames(response.raw);
  };

  const handleGenresFilter = async (value) => {
    setGenresFilter(value);
    const filters = getFileters({genresFilterValue: value});
    const response = await GameService.getGames({
      text: searchValue,
      isActive: filters.isActive,
      isConcluded: filters.isConcluded,
      genres: filters.genres, 
    });
    setGames(response.raw);
  };
  const handleDeleteFilter = (value, filter, setter) => {
    const newFilter = filter.filter((item) => item !== value);
    setter(newFilter);
  };
  return (
    <div className='automated-uploads'>
      <Modal open={isNewGameModalOpen} onClose={handleCloseModal} >
        <GameEditForm onClose={handleCloseModal} />
      </Modal>
      <div className='automated-uploads__header'>
        <div className='header-with-line__text automated-uploads__header-text'>Game Dashobard</div>
        <SearchBox width={300} handleSearch={handleSearch} rounded />
        <Select
          className='automated-uploads__select'
          value={activeFilter}
          onChange={(e) => handleActiveFilter(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span
                className='automated-uploads__select__empty'
              >
                Game Category
              </span>;
            }
            return selected.join(', ');}
          }
          {...selectProps}
        >
          <MenuItem value='active' classes='automation-uploads__menu-item' >active</MenuItem>
          <MenuItem value='concluded' classes='automation-uploads__menu-item'>concluded</MenuItem>
        </Select>
        <Select
          {...selectProps}
          className='automated-uploads__select'
          value={genresFilter}
          onChange={(e) => handleGenresFilter(e.target.value)}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <span
                className='automated-uploads__select__empty'
              >
                Genre
              </span>;
            }
            return selected.join(', ');}
          }
          MenuProps={{
            ...selectProps.MenuProps,
            style: {
              ...selectProps.MenuProps.style,
              width: 400,
            },
          }}
        >
          {genres.map((genre) => <MenuItem
            classes='automation-uploads__menu-item'
            key={genre.id}
            value={genre.genre}
          >
            {genre.genre}
          </MenuItem>)}
        </Select>

        <button className='automated-uploads__button' onClick={() => setIsNewGameModalOpen(true)} >
          <AddIcon className='plus-icon' />
          <span>NEW GAME</span>
        </button>
      </div>
      <div className='automated-uploads__divider' />
      {isFilters && <>
        <div className='automated-uploads__filters'>
          {activeFilter.map((filter) => <CustomBadge
            handleDelete={(value) => handleDeleteFilter(value, activeFilter, handleActiveFilter)}
            key={filter}
            name={filter}
          />)}
          {genresFilter.map((filter) => <CustomBadge
            handleDelete={(value) => handleDeleteFilter(value, genresFilter, handleGenresFilter)}
            key={filter}
            name={filter}
          />)}
        </div>
        <div className='automated-uploads__divider' />
      </>}
      <div className='automated-uploads__wrapper'>
        <div className='automated-uploads__games'>
          { games.length > 0 ?
              games.map((game) => <div
                className={classNames('automated-uploads__game', {selected: game.id === selectedGame})}
                key={game.id}
                onClick={() => setSelectedGame(game.id)}
              >
                <img src={game.imageUrl} className='automated-uploads__game-image' />
                <div className='automated-uploads__game-title' >{game.name}</div>
              </div>) :
              <div className='automated-uploads__no-games' >No games found</div>
          }
        </div>
        {selectedGame ? <GameSettings
          gameId={selectedGame}
        /> :
        <div className='automated-uploads__not-selected' >Not Selected</div>}
      </div>
    </div>
  );
};

export default AutomatedUploads;
