/**
 * @param {number} id
 * @param {string} url
 * @param {string} name
 * @param {string} thumbnail
 * @param {string} extension
 */
class OverlayModel {
  constructor(data, url) {
    this.id = parseInt(data._meta.id);
    this.url = url;
    this.name = data.asset_name.raw;
    this.thumbnail = data.medium_preview_url.raw;
    this.extension = data.file_extension.raw;
  }
}

export default OverlayModel;
