import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './components/App/Root';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-toastify/dist/ReactToastify.min.css' ;

import './assets/styles/main.scss';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);