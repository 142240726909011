import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';

import './CustomInput.scss';

const inputId = uuidv4();


const CustomInput = ({
  type,
  label,
  placeholder,
  name,
  value,
  disabled,
  onSaveDisabled,
  error,
  onChange,
  onBlur,
  onKeyDown,
  onSave,
  onCancel,
}) => {

  const editState = onSave && onCancel;

  const handleChange = (event) => {
    const { value, name } = event.target;

    onChange(value, name, event);
  };

  const handleBlur = (event) => {
    const { value, name } = event.target;

    if (onBlur) {
      onBlur(value, name, event);
    }
  };

  return (
    <label
      htmlFor={inputId}
      className={classnames('custom-input', {
        'disabled': disabled,
        'error': !!error,
        'editState': editState,
      })}
    >
      <div className='custom-input__inner'>
        {label &&
          <p className='custom-input__label'>
            {label}
          </p>
        }

        <input
          id={inputId}
          className='custom-input__field'
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
          value={value}
          name={name}
          type={type}
        />

        {editState &&
          <div className='custom-input__buttons-container'>
            <button
              type='button'
              className='custom-input__button-cancel'
              onClick={onCancel}
              disabled={disabled}
            >
              <ClearIcon className='custom-input__icon' />
            </button>

            <button
              type='button'
              className='custom-input__button-save'
              disabled={onSaveDisabled || disabled}
              onClick={onSave}
            >
              <DoneIcon className='custom-input__icon' />
            </button>
          </div>
        }
      </div>

      {error && !disabled &&
        <p className='custom-input__error'>
          {error}
        </p>
      }
    </label>
  );
};

CustomInput.defaultProps = {
  type: 'text',
  placeholder: 'Enter here',
  value: '',
};

CustomInput.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'email',
    'password',
    'tel',
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  onSaveDisabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CustomInput;
