import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import FormatModel from '../Models/FormatModel';
import CategoryModel from '../../Models/CategoryModel';
import FormatInFilterModel from '../../Models/FormatInFilterModel';
import {
  extractCheckedFormatsId,
  findNewFilteredFormats,
} from '../../creativeDashboardHelpers';
import {
  formatFieldsNames,
  headerTitlesOrder,
  idForEmptyFormat,
} from '../unitCostConstants';
import { categoryNameForNotAssignedFormats } from '../../creativeDashboardConstants';
import {
  GET_HEADER_TITLES_SUCCESS_UNIT_COST,
  CHANGE_HEADER_TITLES_SUCCESS_UNIT_COST,
  SET_HEADER_TITLES_UNIT_COST,
  SET_HEADER_TITLES_EDIT_STATE_UNIT_COST,
  SET_HEADER_TITLES_VALIDATION_UNIT_COST,

  GET_CATEGORIES_SUCCESS_UNIT_COST,
  CHECK_CATEGORY_UNIT_COST,
  CHECK_FORMAT_UNIT_COST,
  TOGGLE_CATEGORY_UNIT_COST,
  ADD_FORMAT_TO_FILTER_UNIT_COST,
  CLEAR_CHECKED_FORMATS_UNIT_COST,

  GET_FORMATS_START,
  GET_FORMATS_SUCCESS,
  GET_FORMATS_SUCCESS_SCROLL,
  GET_FORMATS_FAIL,

  SET_FORMAT_FIELDS_VALIDATION,

  TOGGLE_WAITING_FORMAT_STATE,

  SET_EMPTY_FORMAT,
  DELETE_EMPTY_FORMAT,

  SET_EDITABLE_FORMAT_INIT,
  CLEAN_EDITABLE_FORMAT_REF,
} from './actionsTypes';


const initState = {
  headerTitles: {
    id: '',
    init: {},
    current: {},
    isEditState: false,
    validation: {},
  },

  categories: [],
  checkedFormats: [],

  formatsLoading: false,
  formats: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
  formatsError: '',

  editableFormatInit: {},
  editableFormatValidation: Object.keys(formatFieldsNames).reduce((result, key) => {
    result[formatFieldsNames[key]] = null;
    return result;
  }, {}),

  isAddingNewFormatState: false,
  isWaitingFormatState: false,
  isCleanEditableFormatRef: false,
};


const unitCostReducer = (state = initState, action) => {
  const { type, payload } = action;
  let headerTitlesEntries;
  let headerTitlesEntriesValidation;
  let categories;
  let otherCategory;
  let cloneCategoryList;
  let neededCategory;
  let neededFormat;

  switch (type) {
    case GET_HEADER_TITLES_SUCCESS_UNIT_COST:
      headerTitlesEntries = headerTitlesOrder.map((title) => [title, payload.metadata[title]]);
      headerTitlesEntriesValidation = headerTitlesOrder.map((title) => [title, null]);

      return {
        ...state,
        headerTitles: {
          id: payload.id,
          init: Object.fromEntries(headerTitlesEntries),
          current: Object.fromEntries(headerTitlesEntries),
          isEditState: false,
          validation: Object.fromEntries(headerTitlesEntriesValidation),
        },
      };

    case CHANGE_HEADER_TITLES_SUCCESS_UNIT_COST:
      return {
        ...state,
        headerTitles: {
          ...state.headerTitles,
          init: {...payload},
          current: {...payload},
          isEditState: false,
        },
      };

    case SET_HEADER_TITLES_UNIT_COST:
      return {
        ...state,
        headerTitles: {
          ...state.headerTitles,
          current: {...payload},
        },
      };

    case SET_HEADER_TITLES_EDIT_STATE_UNIT_COST:
      headerTitlesEntriesValidation = headerTitlesOrder.map((title) => [title, null]);

      return {
        ...state,
        headerTitles: {
          ...state.headerTitles,
          isEditState: payload,
          validation: Object.fromEntries(headerTitlesEntriesValidation),
        },
      };

    case SET_HEADER_TITLES_VALIDATION_UNIT_COST:
      return {
        ...state,
        headerTitles: {
          ...state.headerTitles,
          validation: payload,
        },
      };

    case GET_CATEGORIES_SUCCESS_UNIT_COST:
      categories = payload.categories.map((category) => new CategoryModel(category, state.checkedFormats));

      otherCategory = {
        id: uuidv4(),
        name: categoryNameForNotAssignedFormats,
        formats: payload.notAssigned.map((format) => new FormatInFilterModel(format, state.checkedFormats)),
      };

      return {
        ...state,
        categories: [
          ...categories,
          otherCategory,
        ],
      };

    case TOGGLE_CATEGORY_UNIT_COST:
      cloneCategoryList = cloneDeep(state.categories);
      neededCategory = cloneCategoryList.find((category) => category.id === payload);
      neededCategory.open = !neededCategory.open;

      return {
        ...state,
        categories: cloneCategoryList,
      };

    case CHECK_CATEGORY_UNIT_COST:
      cloneCategoryList = cloneDeep(state.categories);
      neededCategory = cloneCategoryList.find((folder) => folder.id === payload);

      if (!neededCategory.formats.length) {
        return {
          ...state,
        };
      } else {
        neededCategory.formats = findNewFilteredFormats(neededCategory);

        return {
          ...state,
          categories: cloneCategoryList,
          checkedFormats: extractCheckedFormatsId(cloneCategoryList),
        };
      }

    case CHECK_FORMAT_UNIT_COST:
      cloneCategoryList = cloneDeep(state.categories);
      neededCategory = cloneCategoryList.find((category) => category.id === payload.categoryId);
      neededFormat = neededCategory.formats.find((format) => format.id === payload.formatId);
      neededFormat.checked = !neededFormat.checked;

      return {
        ...state,
        categories: cloneCategoryList,
        checkedFormats: extractCheckedFormatsId(cloneCategoryList),
      };

    case ADD_FORMAT_TO_FILTER_UNIT_COST:
      return {
        ...state,
        checkedFormats: [...state.checkedFormats, payload],
      };

    case CLEAR_CHECKED_FORMATS_UNIT_COST:
      cloneCategoryList = cloneDeep(state.categories)
        .map((category) => {
          return {
            ...category,
            formats: category.formats.map((format) => {
              return {
                ...format,
                checked: false,
              };
            }),
          };
        });

      return {
        ...state,
        categories: cloneCategoryList,
        checkedFormats: [],
      };

    case GET_FORMATS_START:
      return {
        ...state,
        formatsLoading: true,
      };

    case GET_FORMATS_SUCCESS:
      return {
        ...state,
        formats: {
          ...payload,
          data: payload.data.map((format) => new FormatModel(format)),
        },
        formatsLoading: false,
        formatsError: '',
      };

    case GET_FORMATS_SUCCESS_SCROLL:
      return {
        ...state,
        formats: {
          ...payload,
          data: [
            ...state.formats.data,
            ...payload.data.map((format) => new FormatModel(format))],
        },
        formatsLoading: false,
        formatsError: '',
      };

    case GET_FORMATS_FAIL:
      return {
        ...state,
        formatsError: payload,
        formatsLoading: false,
      };

    case SET_FORMAT_FIELDS_VALIDATION:
      return {
        ...state,
        editableFormatValidation: payload,
      };

    case SET_EMPTY_FORMAT:
      return {
        ...state,
        formats: {
          ...state.formats,
          data: [
            payload,
            ...state.formats.data,
          ],
        },
        editableFormatInit: payload,
        isAddingNewFormatState: true,
      };

    case DELETE_EMPTY_FORMAT:
      return {
        ...state,
        formats: {
          ...state.formats,
          data: state.formats.data.filter((format) => format.id !== idForEmptyFormat),
        },
        isAddingNewFormatState: false,
      };

    case SET_EDITABLE_FORMAT_INIT:
      return {
        ...state,
        formats: {
          ...state.formats,
          data: state.formats.data.filter((format) => format.id !== idForEmptyFormat),
        },
        editableFormatInit: payload,
        isAddingNewFormatState: false,
      };

    case TOGGLE_WAITING_FORMAT_STATE:
      return {
        ...state,
        isWaitingFormatState: payload,
      };

    case CLEAN_EDITABLE_FORMAT_REF:
      return {
        ...state,
        isCleanEditableFormatRef: payload,
      };

    default:
      return state;
  }
};

export default unitCostReducer;
