import { VIDEO_CREATE_ASSETS_TYPES } from 'constants/CreativeTool';


/**
 * Set correct value for Wheel time input
 */
export const putWheelValueInRightPosition = (wheelValue, limitMask, limit, position) => {
  const splitMask = limitMask.split(':');
  const maxValueCount = 3;

  splitMask.push(...new Array(maxValueCount - splitMask.length).fill('00'));

  const transformedSplitMask = splitMask.map((item) => `00${item}`.slice(-2));
  transformedSplitMask[position] = `0${wheelValue}`.slice(-2);

  return transformedSplitMask.join(':');
};

/**
 * Create scaling video array for extension
 */
export const createSequenceOfVideosForScaling = (titleCard, endCard, mainVideos, activeExtension) => {
  const mainVideosForSelectedExtension = selectMainVideosForExtension(mainVideos, activeExtension).map((video, videoIndex) => {
    return ({
      id: video.id,
      url: video.url,
      scale: video.scale,
      type: VIDEO_CREATE_ASSETS_TYPES.MAIN_VIDEOS_ARRAY,
      extension: video.extension,
      index: videoIndex + 1,
    });
  });

  const titleCardForSelectedExtension = titleCard[activeExtension];
  const endCardForSelectedExtension = endCard[activeExtension];

  return [
    {
      id: titleCardForSelectedExtension.id,
      url: titleCardForSelectedExtension.url,
      scale: titleCardForSelectedExtension.scale,
      type: VIDEO_CREATE_ASSETS_TYPES.TITLE_CARD,
      extension: titleCardForSelectedExtension.extension,
      index: 0,
    },
    ...mainVideosForSelectedExtension,
    {
      id: endCardForSelectedExtension.id,
      url: endCardForSelectedExtension.url,
      scale: endCardForSelectedExtension.scale,
      type: VIDEO_CREATE_ASSETS_TYPES.END_CARD,
      extension: endCardForSelectedExtension.extension,
      index: 4,
    },
  ];
};

/**
 * Create init object with extensions
 */
export const createInitObjectWithExtensions = (extensions, value) => {
  return extensions.reduce((object, key) => ({
    ...object,
    [key]: value,
  }), {});
};

/**
 * Make array of main videos for selected extension
 */
export const selectMainVideosForExtension = (mainVideosArray, activeExtension) => {
  if (mainVideosArray.length) {
    if (mainVideosArray[0][activeExtension]) {
      const result = [];
      mainVideosArray.forEach((element) => result.push(element[activeExtension]));
      return result;
    } else {
      return mainVideosArray;
    }
  } else {
    return mainVideosArray;
  }
};