export const GET_ANALYTICS_EVENTS_START = 'GET_ANALYTICS_EVENTS_START';
export const GET_ANALYTICS_EVENTS_FAIL = 'GET_ANALYTICS_EVENTS_FAIL';
export const GET_ANALYTICS_EVENTS_SUCCESS = 'GET_ANALYTICS_EVENTS_SUCCESS';

export const GET_MOST_RECENT_TEST_DEVICE_START = 'GET_MOST_RECENT_TEST_DEVICE_START';
export const GET_MOST_RECENT_TEST_DEVICE_FAIL = 'GET_MOST_RECENT_TEST_DEVICE_FAIL';
export const GET_MOST_RECENT_TEST_DEVICE_SUCCESS = 'GET_MOST_RECENT_TEST_DEVICE_SUCCESS';

export const REMOVE_ANALYTICS_EVENTS_START = 'REMOVE_ANALYTICS_EVENTS_START';
export const REMOVE_ANALYTICS_EVENTS_FAIL = 'REMOVE_ANALYTICS_EVENTS_FAIL';
export const REMOVE_ANALYTICS_EVENTS_SUCCESS = 'REMOVE_ANALYTICS_EVENTS_SUCCESS';

export const GET_ALL_ANALYTICS_GAMES_START = 'GET_ALL_ANALYTICS_GAMES_START';
export const GET_ALL_ANALYTICS_GAMES_FAIL = 'GET_ALL_ANALYTICS_GAMES_FAIL';
export const GET_ALL_ANALYTICS_GAMES_SUCCESS = 'GET_ALL_ANALYTICS_GAMES_SUCCESS';
