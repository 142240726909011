import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import EntityStatus from '../EntityStatus';

import './EntityGridInfo.scss';


const EntityGridInfo = ({ live, title, subtitle, subtitleRight, footerLeftInfo, footerRightInfo, jiraTicketLink, handleSubtitleClick }) => {
  return (
    <div className='entity-grid-info'>
      <div className='entity-grid-info__header'>
        {live !== undefined && <EntityStatus live={live} />}
        <p className='entity-grid-info__title entity-grid-info__text'>{title}</p>
      </div>
      <div className='entity-grid-info__footer'>
        <div
          className='entity-grid-info__subtitle'
        >
          {subtitle}
        </div>
        <div className='entity-grid-info__subtitle'>
          {subtitleRight}
        </div>
      </div>
      <div className='entity-grid-info__footer'>
        {jiraTicketLink ?
          <a
            rel='noreferrer'
            href={jiraTicketLink}
            target={'_blank'}
            className='entity-grid-info__footer-left-info entity-grid-info__text'
          >
            {footerLeftInfo}
          </a> :
          <p className='entity-grid-info__footer-left-info entity-grid-info__text'>
            {footerLeftInfo}
          </p>
        }
        <p className='entity-grid-info__footer-right-info entity-grid-info__text'>{footerRightInfo}</p>
      </div>
    </div>
  );
};

EntityGridInfo.propTypes = {
  live: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleRight: PropTypes.string,
  footerLeftInfo: PropTypes.string,
  footerRightInfo: PropTypes.string,
  jiraTicketLink: PropTypes.string,
  handleSubtitleClick: PropTypes.func,
};

export default EntityGridInfo;
