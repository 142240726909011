import { combineReducers } from 'redux';

import mainSearchReducer from '../components/MainSearch/MainSearch.redux/reducer';
import collectionsReducer from '../components/Collections/Collections.redux/reducer';
import analyticsDevice from '../components/AnalyticsDevice/AnalyticsDevice.redux/reducer';
import analyticsEvent from '../components/AnalyticsEvent/AnalyticsEvent.redux/reducer';
import assets from '../components/Assets/Assets.redux/reducer';
import assetsToPreviewReducer from '../components/Assets/AssetsDetailsPreview/AssetsDetailsPreview.redux/reducer';
import assetsToDownloadReducer from '../components/Assets/DownloadAssets/DownloadAssets.redux/reducer';
import assetsToDeleteReducer from '../components/Assets/DeleteAssets/DeleteAssets.redux/reducer';
import uploadProgressReducer from '../components/UploadProgress/UploadProgress.redux/reducer';
import categories from '../components/Category/Category.redux/reducer';
import creatives from '../components/Creative/Creative.redux/reducer';
import customerSupport from '../components/CustomerSupport/CustomerSupport.redux/reducer';
import game from '../components/Game/Game.redux/reducer';
import gameSettings from '../components/GameSettings/GameSettings.redux/reducer';
import gamesViewReducer from '../components/MainSearch/GamesView/GamesView.redux/reducer';
import navigation from '../components/Navigation/Navigation.redux/reducer';
import generalMessagesReducer from '../components/General/GeneralMessages/GeneralMessages.redux/reducer';
import reports from '../components/Reports/Reports.redux/reducer';
import sharing from '../components/Sharing/Sharing.redux/reducer';
import user from '../components/User/User.redux/reducer';
import homeReducer from '../components/Laboratory/MainPage/MainPage.redux/reducer';
import videoEditorReducer from '../components/Laboratory/VideoEditor/VideoEditor.redux/reducer';
import videoProjectCardsReducer from '../components/Laboratory/VideoEditor/VideoProjectStructure.redux/VideoProjectCards/reducer';
import videoProjectMainVideosReducer from '../components/Laboratory/VideoEditor/VideoProjectStructure.redux/VideoProjectMainVideos/reducer';
import videoProjectElementsReducer from '../components/Laboratory/VideoEditor/VideoProjectStructure.redux/VideoProjectElements/reducer';
import videoEditorCacheReducer from '../components/Laboratory/VideoEditor/VideoEditorCache.redux/reducer';
import VideoOverlaysSettingsReducer from '../components/Laboratory/VideoEditor/VideoOverlaysSettings.redux/reducer';
import adminPanelReducer from '../components/Laboratory/AdminPanel/AdminPanel.redux/reducer';
import randomizerReducer from '../components/Laboratory/Randomizer/Randomizer.redux/reducer';
import videoResizeReducer from '../components/Laboratory/VideoResize/VideoResize.redux/reducer';
import creativeDashboardsReducer from '../components/CreativeDashboard/CreativeDashboard.redux/reducer';
import unitCostReducer from '../components/CreativeDashboard/UnitCost/UnitCost.redux/reducer';
import dashboardReducer from '../components/CreativeDashboard/Dashboard/Dashboard.redux/reducer';
import podCapacityReducer from '../components/CreativeDashboard/PodCapacity/PodCapacity.redux/reducer';
import departmentWorkloadReducer from '../components/CreativeDashboard/DepartmentWorkload/DepartmentWorkload.redux/reducer';
import staffAllocationReducer from '../components/CreativeDashboard/StaffAllocation/StaffAllocation.redux/reducer';
import externalUsersLoginReducer from '../components/ExternalUsersLogin/ExternalUsersLogin.redux/reducer';
import adCreateReducer from '../components/Laboratory/AdCreate/AdCreate.redux/reducer';

const rootReducer = combineReducers({
  mainSearch: mainSearchReducer,
  collections: collectionsReducer,
  analyticsDevice,
  analyticsEvent,
  assets,
  assetsToPreview: assetsToPreviewReducer,
  assetsToDownload: assetsToDownloadReducer,
  assetsToDelete: assetsToDeleteReducer,
  uploadProgress: uploadProgressReducer,
  categories,
  creatives,
  customerSupport,
  game,
  gameSettings,
  gamesView: gamesViewReducer,
  navigation,
  generalMessages: generalMessagesReducer,
  reports,
  sharing,
  user,
  home: homeReducer,
  videoEditor: videoEditorReducer,
  videoProjectCards: videoProjectCardsReducer,
  videoProjectMainVideos: videoProjectMainVideosReducer,
  videoProjectElements: videoProjectElementsReducer,
  videoEditorCache: videoEditorCacheReducer,
  videoOverlaysSettings: VideoOverlaysSettingsReducer,
  adminPanel: adminPanelReducer,
  randomizer: randomizerReducer,
  videoResize: videoResizeReducer,
  creativeDashboards: creativeDashboardsReducer,
  unitCost: unitCostReducer,
  dashboard: dashboardReducer,
  podCapacity: podCapacityReducer,
  departmentWorkload: departmentWorkloadReducer,
  staffAllocation: staffAllocationReducer,
  externalUsersLogin: externalUsersLoginReducer,
  adCreate: adCreateReducer,
});

export default rootReducer;
