import React from 'react';
import classnames from 'classnames';
import CustomCheckbox from '../CustomCheckbox';
import PropTypes from 'prop-types';

import './ButtonWithCheckbox.scss';


const ButtonWithCheckbox = ({ text, checkedCondition, toggleCheck, disabled }) => {
  return (
    <div
      className={classnames('button-with-checkbox', {
        'checked': checkedCondition,
        'disabled': disabled,
      })}
      onClick={toggleCheck}
    >
      <span>{text}</span>
      <CustomCheckbox
        checkedCondition={checkedCondition}
        handleCheck={toggleCheck}
        disabled={disabled}
      />
    </div>
  );
};

ButtonWithCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  checkedCondition: PropTypes.bool.isRequired,
  toggleCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ButtonWithCheckbox;
