import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FolderGridItem from 'components/Entities/Folder/FolderGridItem';
import FileGridItem from 'components/Entities/File/FileGridItem';
import {
  ASSET_DRAG_AND_DROP_ACCEPT_TYPE,
  ASSET_TYPE,
} from 'constants/Asset';
import { str } from 'utils/utils';

import './CollectionStorageGridView.scss';


const CollectionStorageGridView = ({
  folders,
  files,
  canManageCollection,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  handleGameClick,
  handleMoveAssets,
  openAssetInfo,
}) => {
  return (
    <div className='collection-storage-grid-view'>

      {!!folders.length &&
        <div className='collection-storage-grid-view__section'>
          <p className='collection-storage-grid-view__title'>Folders</p>
          <div className='collection-storage-grid-view__folders'>
            {folders.map((folder) => (
              <FolderGridItem
                key={folder.entityId}
                id={folder.entityId}
                name={folder.name}
                isSelected={selectedAssetIdsSet.has(folder.entityId)}
                toggleSelection={() => toggleAssetSelection(folder.entityId)}
                navigateToFolder={() => navigateToFolder(folder.entityId, folder.name)}
                userCanManage={canManageCollection}
                dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.COLLECTION}
                onDropAssets={handleMoveAssets}
              />
            ))}
          </div>
        </div>
      }

      <div
        className={classnames('collection-storage-grid-view__separator', {
          'active': !!folders.length && !!files.length,
        })}
      />

      {!!files.length &&
        <div className='collection-storage-grid-view__section'>
          <p className='collection-storage-grid-view__title'>Files</p>
          <div className='collection-storage-grid-view__files'>
            {files.map((file, index) => (
              <FileGridItem
                key={file.entityId}
                id={file.entityId}
                name={file.name}
                extension={file.extension}
                jiraIssueKey={file.jiraIssueKey}
                status={file.status}
                size={file.width ? `${file.width} x ${file.height}` : ''}
                jiraTicketLink={file.jiraTicketLink}
                weight={file.weight}
                live={file.live}
                approved={file.approved && file.status !== 'Rejected'}
                isSelected={selectedAssetIdsSet.has(file.entityId)}
                previewUrl={file.smallPreviewUrl ?? str.getExtensionIconSrc(file.extension)}
                hasPreview={!!file.smallPreviewUrl}
                hasVideoPreview={file.type === ASSET_TYPE.VIDEO}
                toggleSelection={() => toggleAssetSelection(file.entityId)}
                handleGameClick={() => handleGameClick(
                  file.gameId,
                  file.creativeId ? file.gameStorageId : file.storageId,
                  !file.creativeId ? file.folderPath : null
                )}
                userCanManage={canManageCollection}
                dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.COLLECTION}
                openFileInfo={() => openAssetInfo(files, index)}
              />
            ))}
          </div>
        </div>
      }
    </div>
  );
};

CollectionStorageGridView.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  canManageCollection: PropTypes.bool,
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
  handleMoveAssets: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
};

export default CollectionStorageGridView;
