import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { red, green, yellow } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    height: 1,
  },
  eventPanel: {
    fontWeight: theme.typography.fontWeightRegular,
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
  statePanel: {
    fontWeight: theme.typography.fontWeightRegular,
    background: '#e7e7e7',
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
    },
  },
  detail: {
    'line-height': '13px',
  },
  clearIcon: {
    color: red[800],
    height: 15,
  },
  doneIcon: {
    color: green[800],
    height: 15,
  },
  warningIcon: {
    color: yellow[800],
    height: 15,
  },
  listItem: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    overflow: 'auto',
    'padding-top': 0,
    'padding-bottom': 0,
    wordBreak: 'break-all',
  },
  validField: {
    color: '##696969',
    'font-size': theme.typography.pxToRem(14),
  },
  invalidField: {
    color: '#e53039',
    'font-size': theme.typography.pxToRem(14),
  },
  invalidReason: {
    color: '#950912',
    'font-size': theme.typography.pxToRem(13),
    'padding-left': 10,
  },
});

const AnalyticsEventItem = (props) => {
  const {
    payload,
    verified,
    classes,
    invalidFields,
    eventDate,
  } = props;

  const invalidDict = {};
  if (invalidFields) {
    invalidFields.forEach((item) => {
      invalidDict[item.field_name] = {
        item,
        checked: false,
      };
    });
  }

  const eventName = payload.event_name || payload.state_name || payload.evtname;
  const eventTimestamp = eventDate;
  const eventType = payload.event_name || payload.evtname ? 'event' : 'state';

  const currentDate = new Date(eventTimestamp);
  const dateString = eventTimestamp ? moment(currentDate).utc().format('YYYY-MM-DD h:mm:ss a') : 'null';
  const fromNowStr = eventTimestamp ? moment(currentDate).utc().fromNow() : 'null';

  const getVerifiedIcon = (verified) => {
    switch (verified) {
      case null:
        return <WarningIcon className={classes.warningIcon} />;
      case true:
        return <DoneIcon className={classes.doneIcon} />;
      case false:
        return <ClearIcon className={classes.clearIcon} />;
      default:
        return null;
    }
  };

  return (
    <Accordion className={eventType === 'event' ? classes.eventPanel : classes.statePanel}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {`${dateString} (${fromNowStr}) ${eventName}`}
        {getVerifiedIcon(verified)}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {Object.keys(payload).map((key) => {
            let errorItem = null;
            if (key in invalidDict) {
              errorItem = invalidDict[key].item;
              invalidDict[key].checked = true;
            }

            return (
              <ListItem key={key} className={classes.listItem} subheader={<li />}>
                {!verified && errorItem ?
                  (
                    <ListItemText
                      classes={
                        {
                          primary: classes.invalidField,
                          secondary: classes.invalidReason,
                        }
                      }
                      primary={`"${key}": ${JSON.stringify(payload[key])}`}
                      secondary={`- reason: ${errorItem.reason}`}
                    />
                  ) : (
                    <ListItemText
                      classes={
                        {
                          primary: classes.validField,
                        }
                      }
                      primary={`"${key}": ${JSON.stringify(payload[key])}`}
                    />
                  )
                }
              </ListItem>
            );
          })}
          {!verified && Object.keys(invalidDict)
            .filter((key) => !invalidDict[key].checked)
            .map((key) => (
              <ListItem key={key} className={classes.listItem} subheader={<li />}>
                <ListItemText
                  classes={
                    {
                      primary: classes.invalidField,
                      secondary: classes.invalidReason,
                    }
                  }
                  primary={`"${key}":`}
                  secondary={`- reason: ${invalidDict[key].item.reason}`}
                />
              </ListItem>
            ))
          }
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const invalidFieldPropType = PropTypes.shape({
  field_name: PropTypes.string.isRequired,
});

AnalyticsEventItem.propTypes = {
  classes: PropTypes.object.isRequired,
  payload: PropTypes.shape({
    event_name: PropTypes.string,
    state_name: PropTypes.string,
    evtname: PropTypes.string,
  }).isRequired,
  verified: PropTypes.bool,
  invalidFields: PropTypes.arrayOf(invalidFieldPropType).isRequired,
  eventDate: PropTypes.number.isRequired,
};

export default withStyles(styles)(AnalyticsEventItem);
