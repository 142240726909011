/**
 * @param {number} id
 * @param {string} value
 * @param {string} name
 */
class ColorModel {
  /**
   @param {string} data
   */
  constructor(data) {
    this.id = data;
    this.value = data;
    this.name = data;
  }
}

export default ColorModel;
