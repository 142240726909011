import { AD_CREATE_BANNER_ITEM_TYPES } from 'constants/CreativeTool';

/**
 * @param {number|string} id
 * @param {string} type
 * @param {string} text
 * @param {number} fontId
 * @param {Object} styles
 */
class ButtonBannerItemModel {
  constructor(buttonBannerItem) {
    this.id = buttonBannerItem.id;
    this.type = AD_CREATE_BANNER_ITEM_TYPES.BUTTON;
    this.text = buttonBannerItem.text;
    this.fontId = buttonBannerItem.fontId;
    this.styles = buttonBannerItem.styles;
  }
}

export default ButtonBannerItemModel;
