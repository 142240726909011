import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { dashboardBase } from '../../../constants/Retool';

const Retool = ({ dashboardId }) => {
  useEffect(() => {
    window.retool.RetoolDashboard(
      document.getElementById('main-scrollable-component'),
      `${dashboardBase}${dashboardId}`
    );

    return () => {
      const retoolIframe = document.querySelector('#main-scrollable-component iframe');
      document.getElementById('main-scrollable-component').removeChild(retoolIframe);
    };
  }, [dashboardId]);

  const jwt = localStorage.getItem('feathers-jwt');

  const host = process.env.REACT_APP_ENV === 'production'
    ? 'pixel.tiltingpoint.com'
    : 'stage.pixel.tiltingpoint.com';

  return (
    <div>
      <div
        id='pixelhost'
        style={{
          display: 'none',
        }}
      >
        {host}
      </div>
      <div
        id='pixeljwt'
        style={{
          display: 'none',
        }}
      >
        {jwt}
      </div>
      <div
        id='container'
        style={{
          height: '100%',
        }}
      />
    </div>
  );
};

Retool.propTypes = {
  dashboardId: PropTypes.string.isRequired,
};

export default Retool;
