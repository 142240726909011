import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';
import CustomInput from 'components/UIComponents/CustomInput';
import StyledTooltip from 'components/UIComponents/StyledTooltip';
import { updateCreative } from '../../Creative.redux/Actions/creativeActions';
import useDebounce from 'utils/customHooks/useDebounce';
import { isValidUrl } from 'utils/utils';

import { ReactComponent as LinkIcon } from 'assets/svg/link.svg';

import './CreativeDriveLink.scss';


const CreativeDriveLink = ({ creativeId, userId, driveLink }) => {
  const dispatch = useDispatch();
  const [link, setLink] = useState(driveLink);

  const debounceLink = useDebounce(link, 1000);

  useEffect(() => {
    if (debounceLink !== driveLink) {
      dispatch(updateCreative(creativeId, {
        userId,
        googleDriveLink: link,
      }));
    }
  }, [debounceLink]);
  
  return (
    <div className='creative-drive-link'>
      <CustomInput
        label='Google drive link'
        value={link}
        placeholder='Enter Google drive link'
        onChange={(value) => setLink(value)}
      />
      <StyledTooltip title='Open link' placement='bottom' TransitionComponent={Zoom}>
        <a
          rel='external noreferrer'
          className='creative-drive-link__icon'
          href={isValidUrl(link) ? link : '/' + link}
          target={'_blank'}
        >
          <LinkIcon className='asset-info-static-block__icon' />
        </a>
      </StyledTooltip>
    </div>
  );
};

CreativeDriveLink.propTypes = {
  creativeId: PropTypes.number,
  userId: PropTypes.number,
  driveLink: PropTypes.string,
};

export default CreativeDriveLink;
