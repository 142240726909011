/**
 * @param {string} id
 * @param {string} project_name
 * @param {string} date
 * @param {string} game
 * @param {number} gameId
 * @param {string} author
 * @param {number} authorId
 * @param {string} project_type
 * @param {number} amount_of_assets
 */
class ProjectModel {
  constructor(data) {
    this.id = data.metadata.id;
    this.project_name = data.metadata.title;
    this.date = data.createdAt;
    this.game = data.game.name;
    this.gameId = data.gameId;
    this.author = data.user?.name;
    this.authorId = data.user?.id;
    this.project_type = data.category;
    this.amount_of_assets = data.assets;
  }
}

export default ProjectModel;
