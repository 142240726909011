import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CancelIcon from '@material-ui/icons/Cancel';

import './CustomBadge.scss';


const CustomBadge = ({name, handleDelete, disabled}) => {
  return (
    <div
      className={classnames('custom-badge', {
        disabled,
      })}
    >
      <span className='custom-badge__name'>{name}</span>
      <CancelIcon
        className='custom-badge__icon'
        onClick={() => handleDelete(name)}
      />
    </div>
  );
};

CustomBadge.propTypes = {
  name: PropTypes.string,
  handleDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CustomBadge;
