import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

import './MessageModal.scss';


const MessageModal = ({
  error,
  id,
  message,
  messageInfo,
  closeMessage,
  disable,
  positionFixed,
  handleClose,
  lifeTime,
}) => {

  const messageRef = useRef();

  useEffect(() => {
    if (lifeTime) {
      setTimeout(() => {
        handleRemoveMessage(id);
      }, lifeTime);
    }
  }, [lifeTime]);

  const handleRemoveMessage = (id) => {
    if (messageRef.current) {
      messageRef.current.classList.add('hide');
    }

    if (handleClose) {
      handleClose();
    }

    if (closeMessage) {
      setTimeout(() => {
        closeMessage(id);
      }, 700);
    }
  };

  return (
    <div
      ref={messageRef}
      className={classnames('message-modal', {
        'fixed': positionFixed,
        'error': error,
      })}
    >

      {error ?
        <ErrorOutlineIcon className='message-modal__error-icon' /> :
        <CheckCircleOutlineIcon className='message-modal__success-icon' />
      }
      <div className='message-modal__text'>
        <h3 className='message-modal__header'>{message}</h3>
        <p className='message-modal__subheader'>{messageInfo}</p>
      </div>

      <CloseIcon
        onClick={() => handleRemoveMessage(id)}
        className={classnames('message-modal__close-icon', {
          'disable': disable,
        })}
      />
    </div>
  );
};

MessageModal.defaultProps =  {
  positionFixed: false,
};

MessageModal.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string,
  message: PropTypes.string.isRequired,
  messageInfo: PropTypes.string.isRequired,
  closeMessage: PropTypes.func,
  handleClose: PropTypes.func,
  disable: PropTypes.bool,
  positionFixed: PropTypes.bool,
  lifeTime: PropTypes.number,
};

export default MessageModal;
