// Asset (file, folder) actions types
export const GET_STORAGE_DATA_REQUEST = 'GET_STORAGE_DATA_REQUEST';
export const GET_STORAGE_DATA_SUCCESS = 'GET_STORAGE_DATA_SUCCESS';
export const GET_STORAGE_DATA_FAIL = 'GET_STORAGE_DATA_FAIL';

export const CREATE_NEW_FOLDER_GAME_REQUEST = 'CREATE_NEW_FOLDER_GAME_REQUEST';
export const CREATE_NEW_FOLDER_GAME_SUCCESS = 'CREATE_NEW_FOLDER_GAME_SUCCESS';
export const CREATE_NEW_FOLDER_GAME_FAIL = 'CREATE_NEW_FOLDER_GAME_FAIL';

export const UPLOAD_DOC_GAME_REQUEST = 'UPLOAD_DOC_GAME_REQUEST';
export const UPLOAD_DOC_GAME_SUCCESS = 'UPLOAD_DOC_GAME_SUCCESS';
export const UPLOAD_DOC_GAME_FAIL = 'UPLOAD_DOC_GAME_FAIL';

export const RENAME_ASSET_REQUEST = 'RENAME_ASSET_REQUEST';
export const RENAME_ASSET_SUCCESS = 'RENAME_ASSET_SUCCESS';
export const RENAME_ASSET_FAIL = 'RENAME_ASSET_FAIL';

export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAIL = 'UPDATE_ASSET_FAIL';

export const MOVE_ASSET_REQUEST = 'MOVE_ASSET_REQUEST';
export const MOVE_ASSET_SUCCESS = 'MOVE_ASSET_SUCCESS';
export const MOVE_ASSET_FAIL = 'MOVE_ASSET_FAIL';

export const SEARCH_ASSET_DUPLICATES_REQUEST = 'SEARCH_ASSET_DUPLICATES_REQUEST';
export const SEARCH_ASSET_DUPLICATES_SUCCESS = 'SEARCH_ASSET_DUPLICATES_SUCCESS';
export const SEARCH_ASSET_DUPLICATES_FAIL = 'SEARCH_ASSET_DUPLICATES_FAIL';

// Attributes actions types
export const GET_ATTRIBUTES_REQUEST = 'GET_ATTRIBUTES_REQUEST';
export const GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS';
export const GET_ATTRIBUTES_FAIL = 'GET_ATTRIBUTES_FAIL';

export const CREATE_ATTRIBUTE_OPTION_REQUEST = 'CREATE_ATTRIBUTE_OPTION_REQUEST';
export const CREATE_ATTRIBUTE_OPTION_SUCCESS = 'CREATE_ATTRIBUTE_OPTION_SUCCESS';
export const CREATE_ATTRIBUTE_OPTION_FAIL = 'CREATE_ATTRIBUTE_OPTION_FAIL';

export const UPDATE_ATTRIBUTE_OPTION_REQUEST = 'UPDATE_ATTRIBUTE_OPTION_REQUEST';
export const UPDATE_ATTRIBUTE_OPTION_SUCCESS = 'UPDATE_ATTRIBUTE_OPTION_SUCCESS';
export const UPDATE_ATTRIBUTE_OPTION_FAIL = 'UPDATE_ATTRIBUTE_OPTION_FAIL';

export const DELETE_ATTRIBUTE_OPTION_REQUEST = 'DELETE_ATTRIBUTE_OPTION_REQUEST';
export const DELETE_ATTRIBUTE_OPTION_SUCCESS = 'DELETE_ATTRIBUTE_OPTION_SUCCESS';
export const DELETE_ATTRIBUTE_OPTION_FAIL = 'DELETE_ATTRIBUTE_OPTION_FAIL';

export const CHANGE_ASSET_ATTRIBUTE_START = 'CHANGE_ASSET_ATTRIBUTE_START';
export const CHANGE_ASSET_ATTRIBUTE_SUCCESS = 'CHANGE_ASSET_ATTRIBUTE_SUCCESS';
export const CHANGE_ASSET_ATTRIBUTE_FAIL = 'CHANGE_ASSET_ATTRIBUTE_FAIL';
