import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import App from '../App';
import ErrorFallback from '../../UIComponents/ErrorFallback';
import withTracker from '../../General/WithTracker';
import history from '../../../utils/history';
import { store } from '../../../store/store';
import theme from '../../../styles/theme';

const Root = () => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <DndProvider backend={HTML5Backend}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <Route component={withTracker(App)} />
            </Router>
          </MuiPickersUtilsProvider>
        </DndProvider>
      </MuiThemeProvider>
    </Provider>
  </ErrorBoundary>
);

export default Root;
