import {
  GET_REWARD_OPTIONS_START,
  GET_REWARD_OPTIONS_FAIL,
  GET_REWARD_OPTIONS_SUCCESS,

  SEND_ITEM_START,
  SEND_ITEM_FAIL,
  SEND_ITEM_SUCCESS,
} from './actionTypes';

import CustomerSupport from '../../../services/CustomerSupport';

export const getRewardOptions = (gameId) => (dispatch) => {
  dispatch({ type: GET_REWARD_OPTIONS_START });

  return CustomerSupport.getRewardOptions(gameId)
    .then((res) => dispatch({ type: GET_REWARD_OPTIONS_SUCCESS, rewardOptions: res }))
    .catch((error) => dispatch({ type: GET_REWARD_OPTIONS_FAIL, error: error.message }));
};

export const sendItems = (data) => (dispatch) => {
  dispatch({ type: SEND_ITEM_START });

  return CustomerSupport.sendItems(data)
    .then((res) => dispatch({ type: SEND_ITEM_SUCCESS, res }))
    .catch((error) => dispatch({ type: SEND_ITEM_FAIL, error: error.message }));
};
