import { VIDEO_EXTENSIONS } from 'constants/CreativeTool';

/**
 * @param {number} id
 * @param {string} name
 * @param {string} date
 * @param {string} author
 * @param {string} url
 * @param {string} thumbnail
 * @param {string} format
 */
class FinalVideoModel {
  constructor(data) {
    this.id = data.id;
    this.name = data.path.slice(1);
    this.date = data.createdAt;
    this.author = data.user?.name;
    this.url = `/file-store/${data.storageUuid}${data.path}`;
    this.thumbnail = data.previewUrl;
    this.format = data.width === data.height ? VIDEO_EXTENSIONS.SQUARE : VIDEO_EXTENSIONS.LANDSCAPE;
  }
}

export default FinalVideoModel;
