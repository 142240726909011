import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './EntityStatus.scss';

const EntityStatus = ({ live }) => {
  return (
    <div
      className={classnames('entity-status', {
        'active': live,
      })}
    />
  );
};

EntityStatus.propTypes = {
  live: PropTypes.bool,
};

export default EntityStatus;
