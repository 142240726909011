import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import RoundedModalWrapper from '../RoundedModalWrapper';
import Preloader from '../Preloader';
import CustomInput from '../CustomInput';
import { makeFieldValidation } from 'utils/utils';

import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import './RoundModalWithInput.scss';


const RoundModalWithInput = ({
  isOpen,
  title,
  label,
  currentValue,
  validationPattern,
  validationText,
  validationTextForEmptyCase,
  agreeText,
  agreeIcon,
  onCancel,
  onAgree,
  loading,
  loadingText,
}) => {

  const [value, setValue] = useState('');
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (currentValue) {
      setValue(currentValue);
    }
  }, [currentValue]);

  const handleClose = () => {
    setValue('');
    setValidation(null);
    onCancel();
  };

  const handleChange = (value) => {
    setValue(value);
  };

  const handleBlur = (value) => {
    let validation = makeFieldValidation(
      value,
      validationPattern,
      validationText
    );

    if (!value.trim().length) {
      validation = validationTextForEmptyCase;
    }

    setValidation(validation);
  };

  const handleEdit = () => {
    if (!validation) {
      onAgree(value);
      setValue('');
      setValidation(null);
    }
  };

  return (
    <RoundedModalWrapper
      isOpen={isOpen}
      title={title}
      withoutScroll
      onClose={onCancel}
    >
      <div className='round-modal-with-input'>
        <div
          className={classnames('round-modal-with-input__input-holder', {
            'loading': loading,
          })}
        >
          {loading ?
            <Preloader preloaderText={loadingText} /> :
            <CustomInput
              label={label}
              value={value}
              onChange={(value) => handleChange(value)}
              onBlur={(value) => handleBlur(value)}
              error={validation}
              disabled={loading}
            />
          }
        </div>

        <div className='round-modal-with-input__buttons-container'>
          <button
            className='round-modal-with-input__button'
            onClick={handleClose}
            disabled={loading}
          >
            <CloseIcon className='icon-inside-button' />
            <span>Cancel</span>
          </button>

          <button
            className='round-modal-with-input__button'
            onClick={handleEdit}
            disabled={loading || value === currentValue || !value}
          >
            {agreeIcon ?? <DeleteIcon className='icon-inside-button' />}
            <span>{agreeText}</span>
          </button>
        </div>
      </div>
    </RoundedModalWrapper>
  );
};

RoundModalWithInput.defaultProps = {
  label: 'Enter new value',
  validationText: 'The input value is invalid',
  validationTextForEmptyCase: 'Field can\'t contain only spaces',
};

RoundModalWithInput.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentValue: PropTypes.string,
  validationPattern: PropTypes.instanceOf(RegExp),
  validationText: PropTypes.string,
  validationTextForEmptyCase: PropTypes.string,
  agreeText: PropTypes.string,
  agreeIcon: PropTypes.element,
  onCancel: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

export default RoundModalWithInput;
