import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as queryString from 'query-string';
import FolderGridItem from 'components/Entities/Folder/FolderGridItem';
import FileGridItem from 'components/Entities/File/FileGridItem';
import { ASSET_DRAG_AND_DROP_ACCEPT_TYPE, ASSET_TYPE } from 'constants/Asset';
import { SEARCH_CATEGORY_ENGINES } from 'components/MainSearch/mainSearchConfig';
import { ROOT_DIR_PATH } from 'components/MainSearch/mainSearchConstants';
import { str } from 'utils/utils';

import './CreativeStorageGridView.scss';


const CreativeStorageGridView = ({
  gameName,
  folders,
  files,
  selectedAssetIdsSet,
  toggleAssetSelection,
  navigateToFolder,
  canManageAssets,
  handleMoveAssets,
  handleGameClick,
  assetToOpenId,
  openAssetInfo,
}) => {

  const history = useHistory();
  useEffect(() => {
    if (assetToOpenId) {
      const file = files.find((f) => f.id === assetToOpenId);
      if (file) {
        openAssetInfo(files, files.indexOf(file));
      }
    }
  }, []);
  return (
    <div className='creative-storage-grid-view'>

      {!!folders.length &&
        <div className='creative-storage-grid-view__section'>
          <p className='creative-storage-grid-view__title'>Folders</p>
          <div className='creative-storage-grid-view__folders'>
            {folders.map((folder) => (
              <FolderGridItem
                key={folder.id}
                id={folder.id}
                name={folder.name}
                path={folder.path}
                isSelected={selectedAssetIdsSet.has(folder.id)}
                toggleSelection={() => toggleAssetSelection(folder.id)}
                navigateToFolder={() => navigateToFolder(folder.name)}
                userCanManage={canManageAssets}
                dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
                onDropAssets={handleMoveAssets}
              />
            ))}
          </div>
        </div>
      }

      <div
        className={classnames('creative-storage-grid-view__separator', {
          'active': !!folders.length && !!files.length,
        })}
      />

      {!!files.length &&
        <div className='creative-storage-grid-view__section'>
          <p className='creative-storage-grid-view__title'>Files</p>
          <div className='creative-storage-grid-view__files'>
            {files.map((file, index) => (
              <FileGridItem
                key={file.id}
                id={file.id}
                name={file.name}
                extension={file.extension}
                status={file.status}
                size={file.width ? `${file.width} x ${file.height}` : ''}
                jiraIssueKey={file.jiraIssueKey}
                weight={file.weight}
                live={file.live}
                approved={file.approved && file.status !== 'Rejected'}
                isSelected={selectedAssetIdsSet.has(file.id)}
                previewUrl={file.smallPreviewUrl ?? str.getExtensionIconSrc(file.extension)}
                hasPreview={!!file.smallPreviewUrl}
                hasVideoPreview={file.type === ASSET_TYPE.VIDEO}
                toggleSelection={() => toggleAssetSelection(file.id)}
                handleGameClick={handleGameClick}
                userCanManage={canManageAssets}
                dragAndDropAcceptType={ASSET_DRAG_AND_DROP_ACCEPT_TYPE.CREATIVE}
                openFileInfo={() => openAssetInfo(files, index)}
              />
            ))}
          </div>
        </div>
      }
    </div>
  );
};

CreativeStorageGridView.propTypes = {
  gameName: PropTypes.string.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object),
  files: PropTypes.arrayOf(PropTypes.object),
  selectedAssetIdsSet: PropTypes.object,
  toggleAssetSelection: PropTypes.func.isRequired,
  navigateToFolder: PropTypes.func.isRequired,
  canManageAssets: PropTypes.bool,
  handleMoveAssets: PropTypes.func.isRequired,
  handleGameClick: PropTypes.func.isRequired,
  openAssetInfo: PropTypes.func.isRequired,
  assetToOpenId: PropTypes.number,
};

export default CreativeStorageGridView;
