import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';
import Preloader from '../../UIComponents/Preloader';
import FailRequestScreen from '../../UIComponents/FailRequestScreen';
import CollectionPanel from './CollectionPanel';
import CollectionInfo from './CollectionInfo';
import CollectionStorage from './CollectionStorage';
import {
  getCollectionAction,
  getCollectionAssetsAction,
} from '../Collections.redux/Actions/collectionActions';

import './Collection.scss';


const Collection = ({ canUploadAssets }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { collectionId } = useParams();
  const { collectionHash } = useParams();

  const {
    loading,
    data: collection,
    error,
  } = useSelector((state) => state.collections.collection);

  const {
    name,
    owner,
    createdAt,
    isPrivate,
    description,
  } = collection || {};

  const [searchValue, setSearchValue] = useState('');

  const folder = useMemo(() => queryString.parse(location.search),[location.search]);

  useEffect(() => {
    if (collectionId) {
      dispatch(getCollectionAction(collectionId, collectionHash ? collectionHash : null));
    }
  }, [collectionId, collectionHash]);

  useEffect(() => {
    if (folder?.id && folder?.name && name) {
      dispatch(getCollectionAssetsAction({
        collectionId,
        parentId: folder?.id,
        folderName: folder?.name,
        collectionHash,
      }));
    }
  }, [folder?.id, folder?.name, name]);

  if (loading || (!collection && !error)) {
    return <Preloader />;
  }

  if (error) {
    return <FailRequestScreen message={error} />;
  }

  return (
    <div className='collection'>
      <CollectionPanel
        canUploadAssets={canUploadAssets}
        name={name}
        owner={owner}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <div className='collection__body'>
        <CollectionInfo
          createdAt={createdAt}
          visibility={`${isPrivate ? 'private' : 'public'} collection`}
          description={description}
        />

        <CollectionStorage
          isPrivate={isPrivate}
          collection={collection}
          searchValue={searchValue}
        />
      </div>
    </div>
  );
};

Collection.propTypes = {
  canUploadAssets: PropTypes.bool,
};

export default Collection;
