import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Preloader from '../../UIComponents/Preloader';
import CreativeGeneralInfoStatic from './CreativeGeneralInfoStatic';
import CreativeGeneralInfoForm from './CreativeGeneralInfoForm';
import { updateCreative } from '../Creative.redux/Actions/creativeActions';

import './CreativeGeneralInfo.scss';


const CreativeGeneralInfo = ({
  isEditCreative,
  setIsEditCreative,
}) => {

  const dispatch = useDispatch();

  const { usersList } = useSelector((state) => state.user);

  const {
    generalCreativeInfo,
    updateCreativeStart,
    updateCreativeSuccess,
  } = useSelector((state) => state.creatives);

  const {
    id: fetchedCreativeId,
    jiraIssue,
    jiraTicketUrl: jiraIssueUrl,
    user,
  } = generalCreativeInfo;

  const {
    id: authorId,
    name: authorName,
    avatarUrl: authorAvatar,
  } = user || {};

  const {
    id: jiraIssueId,
    key: jiraIssueKey,
    summary: jiraIssueSummary,
    title: jiraIssueTitle,
    format,
    department,
    platform,
    status: statusName,
    statusId,
    availableLanguage,
    qty,
    sizes,
  } = jiraIssue || {};

  useEffect(() => {
    if (updateCreativeSuccess) {
      setIsEditCreative(false);
    }
  }, [updateCreativeSuccess]);

  const handleSaveCreative = (data) => {
    dispatch(updateCreative(fetchedCreativeId, data));
  };

  return (
    <div className='creative-general-info'>
      <h4 className='creative-section-name'>general info</h4>

      {updateCreativeStart &&
        <Preloader preloaderText={'Updating creative'} />
      }

      {isEditCreative ?
        <CreativeGeneralInfoForm
          jiraIssueId={jiraIssueId}
          jiraIssueKey={jiraIssueKey}
          jiraIssueSummary={jiraIssueSummary}
          authorId={authorId}
          authorName={authorName}
          authorAvatar={authorAvatar}
          usersList={usersList}
          format={format}
          sizes={sizes}
          department={department}
          quantity={qty}
          statusId={statusId}
          platform={platform}
          language={availableLanguage}
          onCancelEditCreative={() => setIsEditCreative(false)}
          onSaveCreative={handleSaveCreative}
        /> :
        <CreativeGeneralInfoStatic
          jiraIssueTitle={jiraIssueTitle}
          jiraIssueUrl={jiraIssueUrl}
          authorName={authorName}
          authorAvatar={authorAvatar}
          format={format}
          sizes={sizes}
          department={department}
          quantity={qty}
          status={statusName}
          platform={platform}
          language={availableLanguage}
        />
      }
    </div>
  );
};

CreativeGeneralInfo.propTypes = {
  isEditCreative: PropTypes.bool.isRequired,
  setIsEditCreative: PropTypes.func.isRequired,
};

export default CreativeGeneralInfo;
