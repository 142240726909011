
export const GET_DEPARTMENT_WORKLOAD_START = 'GET_DEPARTMENT_WORKLOAD_START';
export const GET_DEPARTMENT_WORKLOAD_SUCCESS = 'GET_DEPARTMENT_WORKLOAD_SUCCESS';
export const GET_DEPARTMENT_WORKLOAD_SUCCESS_SCROLL = 'GET_DEPARTMENT_WORKLOAD_SUCCESS_SCROLL';
export const GET_DEPARTMENT_WORKLOAD_FAIL = 'GET_DEPARTMENT_WORKLOAD_FAIL';

export const SET_DEPARTMENT_WORKLOAD_FILTER = 'SET_DEPARTMENT_WORKLOAD_FILTER';
export const REMOVE_DEPARTMENT_WORKLOAD_FILTER = 'REMOVE_DEPARTMENT_WORKLOAD_FILTER';
export const SET_ALL_DEPARTMENT_WORKLOAD_FILTER = 'SET_ALL_DEPARTMENT_WORKLOAD_FILTER';

export const SET_DEPARTMENT_WORKLOAD_DATE_FILTER = 'SET_DEPARTMENT_WORKLOAD_DATE_FILTER';