import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import styles from './styles.module.scss';

const ApprovePopover = ({ text, onApprove, ...props }) => {
  const handleApprove = () => {
    onApprove();
    props.onClose();
  };

  return (
    <Popover
      {...props}
      PaperProps={{
        className: styles.approvePopup,
      }}
    >
      <p>{text}</p>
      <div className={styles.approveControls}>
        <Button
          variant='contained'
          color='secondary'
          onClick={props.onClose}
        >
          No
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleApprove}
        >
          Yes
        </Button>
      </div>
    </Popover>
  );
};

ApprovePopover.propTypes = {
  text: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApprovePopover;
