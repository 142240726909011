import { splitTimeBy } from './unitCostConstants';

export const convertMinutesToTime = (value) => {
  let hours = Math.floor(value / 60);
  let minutes = value - hours * 60;

  if (hours === 100) {
    hours -= 1;
    minutes += 60;
  }

  hours = ('0' + hours).slice(-2);
  minutes = ('0' + minutes).slice(-2);

  return hours + splitTimeBy + minutes;
};

export const convertTimeToMinutes = (time) => {
  const timeArray = time.split(splitTimeBy);
  const hours = timeArray[0] ? Number(timeArray[0]) : 0;
  const minutes = timeArray[1] ? Number(timeArray[1]) : 0;

  return parseInt(hours * 60 + minutes);
};
