import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from 'types/general';

import { ReactComponent as IconLogoPurpleGradient } from 'assets/svg/logo/logo-purple-gradient.svg';

import './EmptyState.scss';


const EmptyState = ({ text, note, children }) => {
  return (
    <div className='empty-state'>
      <IconLogoPurpleGradient className='empty-state__logo' />
      <p className='empty-state__text'>{text}</p>
      <p className='empty-state__none'>{note}</p>
      <div className='empty-state__children'>
        {children}
      </div>
    </div>
  );
};

EmptyState.defaultProps = {
  text: 'Oops! Nothing was found',
};

EmptyState.propTypes = {
  text: PropTypes.string.isRequired,
  note: PropTypes.string,
  children: childrenPropType,
};

export default EmptyState;
