import React, { useEffect, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons';
import Creative from '../../services/Creative';
import Assets from '../../services/Assets';
import useSpeed from '../../utils/customHooks/useSpeed';
import useExitPrompt from '../../utils/customHooks/useExitPrompt';
import File from './File';
import { closeUploadProgress } from './UploadProgress.redux/actions';

import './UploadProgress.scss';


const UploadProgress = () => {

  const dispatch = useDispatch();

  const {
    open,
    count,
    files,
    areCreativeAssets,
    success,
    fail,
  } = useSelector((state) => state.uploadProgress);

  const startTime = useRef(new Date());

  const [isExpand, setIsExpand] = useState(true);
  const [isHide, setIsHide] = useState(false);
  const setShowExitPrompt = useExitPrompt(false);

  const [totalSizeToUpload, setTotalSizeToUpload] = useState(0);
  const [uploadCounter, setUploadCounter] = useState(null);

  const speed = useSpeed(areCreativeAssets ?
    (params) => Creative.uploadDoc(params) :
    (params) => Assets.createDoc(params)
  );

  useEffect(() => {
    setShowExitPrompt(true);
    findTotalSizeToUpload();
  }, []);

  useEffect(() => {
    if (speed) {
      initUploadCounter(files, speed);
    }
  }, [speed]);

  useEffect(() => {
    if (count <= success + fail) {
      setShowExitPrompt(false);

      setTimeout(() => {
        dispatch(closeUploadProgress());
      }, 0);
    }
  }, [count, success, fail]);

  const handleHideUploadProgress = () => {
    setIsHide(true);
  };

  const handleExpandUploadProgress = () => {
    setIsExpand(((prevState) => !prevState));
  };

  const findTotalSizeToUpload = () => {
    setTotalSizeToUpload(files.reduce((accumulator, current) => accumulator + current.size, 0));
  };

  const initUploadCounter = (files, speed) => {
    const biggestFileSize = Math.max(...files.map((file) => file.size));
    const secondsToUploadAllTogether = totalSizeToUpload / speed;
    const secondsToUploadTheBiggest = biggestFileSize / speed;
    const averageSecondsToUpload = Math.round((secondsToUploadAllTogether + secondsToUploadTheBiggest) / 2);

    if (uploadCounter) {
      const secondsPass = Math.round((new Date() - startTime.current) / 1000);
      setUploadCounter(averageSecondsToUpload - secondsPass);
    } else {
      setUploadCounter(averageSecondsToUpload);
    }
  };

  const convertSecondsToRemainingTime = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours) {
      return `${hours} hour${hours > 1 ? 's' : ''} left...`;
    } else if (minutes) {
      return `${minutes} min left...`;
    } else {
      return 'Less than a minute left';
    }
  };

  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <div
      className={classnames('upload-progress', {
        'hidden': isHide,
      })}
    >

      <div className='upload-progress__header'>
        <p className='upload-progress__header-text'>
          upload files
          <span className='upload-progress__header-count'>{success + fail}/{count}</span>
        </p>

        <div className='upload-progress__header-button-container'>
          {isExpand ?
            <ExpandMoreIcon
              className='upload-progress__header-button'
              onClick={handleExpandUploadProgress}
            />:
            <ExpandLessIcon
              className='upload-progress__header-button'
              onClick={handleExpandUploadProgress}
            />
          }
          <CloseIcon
            className='upload-progress__header-button'
            onClick={handleHideUploadProgress}
          />
        </div>
      </div>

      <div
        className={classnames('upload-progress__body', {
          'expand': isExpand,
        })}
      >
        <p className='upload-progress__time'>
          {(uploadCounter || uploadCounter === 0) && convertSecondsToRemainingTime(uploadCounter)}
        </p>

        <div className='upload-progress__files-container'>
          {(speed || speed === 0) && files
            .sort((aFile, bFile) => aFile.completed - bFile.completed)
            .map((file, fileIndex) =>
              <File key={fileIndex} file={file} speed={speed} totalSizeToUpload={totalSizeToUpload} />
            )}
        </div>
      </div>
    </div>,
    document.getElementById('portal')
  );
};

export default UploadProgress;
