import { v4 as uuidv4 } from 'uuid';
import FinalVideoModel from '../../Models/GameAssets/FinalVideoModel';
import FrameModel from '../../Models/GameAssets/FrameModel';
import { FRAME_TYPES } from 'constants/CreativeTool';
import {
  SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS,
  SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
} from '../../VideoEditor/VideoEditor.redux/actionTypes';
import {
  RESIZE_PROJECT_START,
  RESIZE_PROJECT_SUCCESS,
  RESIZE_PROJECT_FAIL,
  RESIZE_PROJECT_RESET,
  RESIZE_PROJECT_INFO,
  GET_GAMES_FINAL_VIDEOS_START,
  GET_GAMES_FINAL_VIDEOS_SUCCESS,
  GET_GAMES_FINAL_VIDEOS_SUCCESS_SCROLL,
  GET_GAMES_FINAL_VIDEOS_FAIL,
  SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS,
  SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS,
  LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS,
  LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS,
  LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS,
  LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS,
  SQUARE_4x5_FRAMES_ANIMATED_SUCCESS,
  SQUARE_4x5_FRAMES_STATIC_SUCCESS,
} from './actionTypes';


const initState = {
  gamesSquareLandscapeFramesStatic: null,
  gamesSquareLandscapeFramesAnimated: null,

  gamesSquarePortraitFramesStatic: null,
  gamesSquarePortraitFramesAnimated: null,

  gamesLandscapePortraitFramesStatic: null,
  gamesLandscapePortraitFramesAnimated: null,

  gamesLandscape4x5FramesStatic: null,
  gamesLandscape4x5FramesAnimated: null,

  gamesSquare4x5FramesStatic: null,
  gamesSquare4x5FramesAnimated: null,

  loadingGamesFinalVideos: false,
  gamesFinalVideos: {
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  },
  errorGamesFinalVideos: false,
  loadingResize: false,
  successResize: false,
  errorResize: '',
  projectId: null,
  availableForDownload: [],
};

const videoResizeReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SQUARE_LANDSCAPE_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gamesSquareLandscapeFramesStatic: [
          {id: uuidv4(), name: 'blur', url: 'none', type: FRAME_TYPES.BLUR_FRAME},
          ...payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.STATIC_FRAME)),
        ],
      };

    case SQUARE_LANDSCAPE_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gamesSquareLandscapeFramesAnimated: payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.ANIM_FRAME)),
      };

    case SQUARE_PORTRAIT_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gamesSquarePortraitFramesStatic: [
          {id: uuidv4(), name: 'blur', url: 'none', type: FRAME_TYPES.BLUR_FRAME},
          ...payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.STATIC_FRAME)),
        ],
      };

    case SQUARE_PORTRAIT_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gamesSquarePortraitFramesAnimated: payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.ANIM_FRAME)),
      };

    case LANDSCAPE_PORTRAIT_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gamesLandscapePortraitFramesStatic: [
          {id: uuidv4(), name: 'blur', url: 'none', type: FRAME_TYPES.BLUR_FRAME},
          ...payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.STATIC_FRAME)),
        ],
      };

    case LANDSCAPE_PORTRAIT_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gamesLandscapePortraitFramesAnimated: payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.ANIM_FRAME)),
      };

    case LANDSCAPE_4x5_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gamesLandscape4x5FramesStatic: [
          {id: uuidv4(), name: 'blur', url: 'none', type: FRAME_TYPES.BLUR_FRAME},
          ...payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.STATIC_FRAME)),
        ],
      };

    case LANDSCAPE_4x5_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gamesLandscape4x5FramesAnimated: payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.ANIM_FRAME)),
      };

    case SQUARE_4x5_FRAMES_STATIC_SUCCESS:
      return {
        ...state,
        gamesSquare4x5FramesStatic: [
          {id: uuidv4(), name: 'blur', url: 'none', type: FRAME_TYPES.BLUR_FRAME},
          ...payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.STATIC_FRAME)),
        ],
      };

    case SQUARE_4x5_FRAMES_ANIMATED_SUCCESS:
      return {
        ...state,
        gamesSquare4x5FramesAnimated: payload?.map((frame) => new FrameModel(frame, FRAME_TYPES.ANIM_FRAME)),
      };

    case RESIZE_PROJECT_START:
      return {
        ...state,
        loadingResize: true,
        successResize: false,
        errorResize: '',
      };

    case RESIZE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingResize: false,
        successResize: true,
        errorResize: '',
      };

    case RESIZE_PROJECT_FAIL:
      return {
        ...state,
        loadingResize: false,
        successResize: false,
        errorResize: payload,
      };

    case RESIZE_PROJECT_RESET:
      return {
        ...state,
        loadingResize: false,
        successResize: false,
        errorResize: '',
        projectId: null,
        availableForDownload: [],
      };

    case RESIZE_PROJECT_INFO:
      return {
        ...state,
        projectId: payload.projectId,
        availableForDownload: payload.availableForDownload,
      };

    case GET_GAMES_FINAL_VIDEOS_START:
      return {
        ...state,
        loadingGamesFinalVideos: true,
        gamesFinalVideos: {
          total: 0,
          limit: 0,
          skip: 0,
          data: [],
        },
        errorGamesFinalVideos: false,
      };

    case GET_GAMES_FINAL_VIDEOS_SUCCESS:
      return {
        ...state,
        loadingGamesFinalVideos: false,
        gamesFinalVideos: {
          ...payload,
          data: payload.data.map((video) => new FinalVideoModel(video)),
        },
        errorGamesFinalVideos: false,
      };

    case GET_GAMES_FINAL_VIDEOS_SUCCESS_SCROLL:
      return {
        ...state,
        loadingGamesFinalVideos: false,
        gamesFinalVideos: {
          ...payload,
          data: [
            ...state.gamesFinalVideos.data,
            ...payload.data.map((video) => new FinalVideoModel(video)),
          ],
        },
        errorGamesFinalVideos: false,
      };
    case GET_GAMES_FINAL_VIDEOS_FAIL:
      return {
        ...state,
        loadingGamesFinalVideos: false,
        gamesFinalVideos: {
          total: 0,
          limit: 0,
          skip: 0,
          data: [],
        },
        errorGamesFinalVideos: true,
      };

    default:
      return state;
  }
};

export default videoResizeReducer;
