import { GENERAL_FILE_TYPES } from '../constants/General';

import pngIcon from '../assets/svg/file-previews/png.svg';
import jpgIcon from '../assets/svg/file-previews/jpg.svg';
import videoIcon from '../assets/svg/file-previews/video.svg';
import audioIcon from '../assets/svg/file-previews/audio.svg';
import textIcon from '../assets/svg/file-previews/text.svg';
import vectorIcon from '../assets/svg/file-previews/vector.svg';
import d3Icon from '../assets/svg/file-previews/3d.svg';
import pptIcon from '../assets/svg/file-previews/ppt.svg';
import psdIcon from '../assets/svg/file-previews/psd.svg';
import psbIcon from '../assets/svg/file-previews/psb.svg';
import aepIcon from '../assets/svg/file-previews/aep.svg';
import htmlIcon from '../assets/svg/file-previews/html.svg';
import cssIcon from '../assets/svg/file-previews/css.svg';
import phpIcon from '../assets/svg/file-previews/php.svg';
import prprojIcon from '../assets/svg/file-previews/prproj.svg';
import wrdIcon from '../assets/svg/file-previews/wrd.svg';
import zipIcon from '../assets/svg/file-previews/zip.svg';
import newIcon from '../assets/svg/file-previews/new.svg';

const deepGet = (obj, props, defaultValue) => {
  if (obj === undefined || obj === null) {
    return defaultValue;
  }

  if (props.length === 0) {
    return obj;
  }

  const foundSoFar = obj[props[0]];
  const remainingProps = props.slice(1);
  return deepGet(foundSoFar, remainingProps, defaultValue);
};

const isPromise = (object) => {
  if (Promise && Promise.resolve) {
    return Promise.resolve(object) === object;
  }

  return false;
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  }
  catch (e) {
    return false;
  }
};

const previewSource = (docType, documentUrl) => {
  switch (docType) {
    case 1:
      return pptIcon;
    case 2:
      return psdIcon;
    case 3:
      return documentUrl;
    case 4:
      return documentUrl;
    default:
      return newIcon;
  }
};

export const getExtensionIconSrc = (fileExt) => {
  switch (fileExt) {
    case 'png':
      return pngIcon;
    case 'jpg':
    case 'jpeg':
      return jpgIcon;
    case 'mp4':
    case 'mov':
    case 'webm':
      return videoIcon;
    case 'aif':
    case 'mp3':
    case 'sfk':
    case 'wav':
    case 'ftc':
    case 'meta':
    case 'm4a':
      return audioIcon;
    case 'docx':
    case 'tx':
    case 'txt':
    case 'csv':
    case 'xlsx':
    case 'pdf':
      return textIcon;
    case 'indd':
    case 'ai':
    case 'eps':
    case 'svg':
      return vectorIcon;
    case 'blend':
    case 'c4d':
    case 'exr':
    case 'fbx':
    case 'ma':
    case 'max':
    case 'mb':
    case 'mtl':
    case 'obj':
    case 'spp':
    case 't3d':
    case 'uasset':
    case 'zlt':
      return d3Icon;
    case 'psd':
      return psdIcon;
    case 'psb':
      return psbIcon;
    case 'css':
      return cssIcon;
    case 'html':
      return htmlIcon;
    case 'php':
      return phpIcon;
    case 'aep':
      return aepIcon;
    case 'ppt':
      return pptIcon;
    case 'prproj':
      return prprojIcon;
    case 'wrd':
      return wrdIcon;
    case 'zip':
    case 'rar':
    case '7z':
      return zipIcon;
    default:
      return newIcon;
  }
};

const getExtensionFromPath = (path) => path?.split('.')?.pop().toLowerCase();

const getItemName = (elem) => {
  const allPathArray = elem.path.split('/');
  return allPathArray[allPathArray.length - 1];
};

const getFileUrl = (file) => {
  const { path, mediumPreviewUrl} = file;

  switch (file.data.fileType) {
    case GENERAL_FILE_TYPES.image:
    case GENERAL_FILE_TYPES.video:
      return `/file-store/${file.storage.uuid}${path}`;

    default: {
      if (mediumPreviewUrl) {
        return mediumPreviewUrl;
      } else {
        return getExtensionIconSrc(getExtensionFromPath(path));
      }
    }
  }
};

const getFileDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    }, false);

    reader.addEventListener('error', () => {
      reject(reader.error);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

export const makeFieldValidation = (value, validationPattern, message = 'error') => {
  if (!validationPattern.test(value)) {
    return message;
  }
  return null;
};

export const allowOnlyNumbers = (event) => {
  if (!/^(\d|.{2,})$/.test(event.key)) {
    event.preventDefault();
  }
};

export const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const allowOnlyDecimalNumbers = (event) => {
  if (!/^([\d,.]|.{2,})$/.test(event.key)) {
    event.preventDefault();
  }
};

const getJiraIssueUrl = (issueKey) => `https://tiltingpoint.atlassian.net/browse/${issueKey}`;

const capitalize = (text) => `${text[0].toUpperCase()}${text.slice(1)}`;

const validateFileSize = (maxSize) => (file) => file.size <= maxSize;

const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

export const getErrorMessage = (error) => {
  let message;

  if (error instanceof Error) {
    message = error.message;
  } else if (error && typeof error === 'object' && 'message' in error) {
    message = String(error.message);
  } else if (typeof error === 'string') {
    message = error;
  } else {
    message = 'Unknown error';
  }

  return message;
};

export const obj = {
  deepGet,
  isPromise,
  validateFileSize,
};

export const str = {
  previewSource,
  getExtensionIconSrc,
  getExtensionFromPath,
  getItemName,
  getFileUrl,
  getFileDataURL,
  getJiraIssueUrl,
  capitalize,
  copyTextToClipboard,
};
