import {
  GAMES_VIEW_RESET_STORAGE_PATH,
  GAMES_VIEW_SET_ACTIVE_STORAGE,
} from './actionsTypes';


/**
 * Reset game storage path
 */
export const resetGameStoragePathAction = (storageId, path) => (dispatch) => {
  dispatch({
    type: GAMES_VIEW_RESET_STORAGE_PATH,
    payload: {
      storageId,
      path,
    },
  });
};

/**
 * Set active game storage
 */
export const setActiveGameStorageAction = (data) => (dispatch) => {
  dispatch({
    type: GAMES_VIEW_SET_ACTIVE_STORAGE,
    payload: data,
  });
};
