import {
  GET_POD_CAPACITY_START,
  GET_POD_CAPACITY_SUCCESS,
  GET_POD_CAPACITY_SUCCESS_SCROLL,
  GET_POD_CAPACITY_FAIL,
} from '../actionsTypes';
import PodCapacity from 'services/PodCapacity';
import { getErrorMessage } from 'utils/utils';


const getPodCapacityStart = () => {
  return {
    type: GET_POD_CAPACITY_START,
  };
};

const getPodCapacitySuccess = (podCapacity) => {
  return {
    type: GET_POD_CAPACITY_SUCCESS,
    payload: podCapacity,
  };
};

const getPodCapacitySuccessScroll = (podCapacity) => {
  return {
    type: GET_POD_CAPACITY_SUCCESS_SCROLL,
    payload: podCapacity,
  };
};

const getPodCapacityFail = (error) => {
  return {
    type: GET_POD_CAPACITY_FAIL,
    payload: getErrorMessage(error),
  };
};

export const getPodCapacityAction = (params, isInfiniteScroll) => (dispatch) => {

  if (!isInfiniteScroll) {
    dispatch(getPodCapacityStart());
  }

  return PodCapacity.getPodCapacity(params)
    .then((response) => {
      if (isInfiniteScroll) {
        dispatch(getPodCapacitySuccessScroll(response));
      } else {
        dispatch(getPodCapacitySuccess(response));
      }
    })
    .catch((error) => {
      dispatch(getPodCapacityFail(error));
    });
};
