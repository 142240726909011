import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { DROPZONE_MAX_FILE_SIZE } from '../../../constants/General';
import { str } from '../../../utils/utils';
import { ReactComponent as DropzonePlaceholderIcon } from '../../../assets/svg/image-uploader-placeholder.svg';

import styles from './styles.module.scss';

const DropZoneContainer = (props) => {
  const {
    uploadType,
    multiple,
    filePreview,
    onChange,
  } = props;

  const [previewImageDataURL, setPreviewImageDataURL] = useState(null);
  const [error, setError] = useState(null);

  const handleDropRejected = (fileRejections) => {
    const rejection = fileRejections[0];

    const errorMessage = rejection.errors.map((error) => {
      return error.code === 'file-too-large'
        ? 'File should be less than 50MB'
        : error.message;
    }).join('\n');

    setError(errorMessage);
  };

  const handleOnDropAccepted = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    const url = await str.getFileDataURL(file);
    setPreviewImageDataURL(url);

    setError(null);

    if (multiple) {
      onChange(acceptedFiles);
    } else {
      onChange(file);
    }
  };

  const defaultPreview = previewImageDataURL || filePreview;

  return (
    <Dropzone
      accept={{[uploadType]: []}}
      multiple={multiple}
      maxSize={DROPZONE_MAX_FILE_SIZE}
      noDragEventsBubbling
      onDropAccepted={handleOnDropAccepted}
      onDropRejected={handleDropRejected}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} className={styles.dropzoneContainer}>
          <input {...getInputProps()} />
          {defaultPreview
            ? (
              <img
                src={defaultPreview}
                alt='Game preview'
                className={styles.preview}
              />
            )
            : <DropzonePlaceholderIcon className={styles.placeholderIcon} />
          }

          {error && <span className={styles.error}>{error}</span>}
        </div>
      )}
    </Dropzone>
  );
};

DropZoneContainer.propTypes = {
  multiple: PropTypes.bool,
  uploadType: PropTypes.string.isRequired,
  filePreview: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DropZoneContainer;
