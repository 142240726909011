import cloneDeep from 'lodash/cloneDeep';
import {
  VIDEO_PROJECT_DISCARD,
  VIDEO_PROJECT_ACTIVE_EXTENSION_CHANGE,
  VIDEO_PROJECT_SOUND_FADE_TOGGLE,
  VIDEO_PROJECT_SOUND_FADE_TIME_UPDATE,
  VIDEO_PROJECT_AUDIO_UPDATE,
  VIDEO_PROJECT_UPLOAD_PATH_UPDATE,
  VIDEO_PROJECT_RENDER_FRAME_UPDATE,
  VIDEO_PROJECT_VALIDATION_UPDATE,
  VIDEO_PROJECT_SPECIFIC_VALIDATION_UPDATE,
  VIDEO_PROJECT_MAIN_VIDEOS_VALIDATION_SWAP,
  VIDEO_PROJECT_SET_CANVAS_TO_NORMAL_SIZE,
  VIDEO_PROJECT_SET_CANVAS_TO_FULL_SIZE,
} from './actionTypes';
import { CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT } from '../../VideoEditor.redux/actionTypes';
import {
  VIDEO_EXTENSIONS,
  VIDEO_EXTENSIONS_ARRAY,
  VIDEO_CREATE_ASSETS_TYPES,
} from 'constants/CreativeTool';
import { createInitObjectWithExtensions } from '../../videoEditorHelpers';
import { SCREEN_SIZE } from 'constants/CreativeTool';
import variables from 'assets/styles/variables.module.scss';

const CSS = {
  canvasWidthDefault: variables.canvasWidthDefault,
};

const initState = {
  activeExtension: VIDEO_EXTENSIONS.LANDSCAPE,
  audio: {
    id: '',
    name: '',
    url: '',
    searchWord: '',
    isEnabled: false,
  },
  renderFrame: {
    portrait: {id: '', name: 'none', url: '', type: '', label: ''},
    four_on_five: {id: '', name: 'none', url: '', type: '', label: ''},
  },
  isSoundFade: false,
  soundFadeTime: 2000,
  uploadPath: null,
  canvasHeight: Math.round(CSS.canvasWidthDefault / (SCREEN_SIZE.LANDSCAPE_WIDTH / SCREEN_SIZE.LANDSCAPE_HEIGHT)),
  canvasWidth: CSS.canvasWidthDefault,
  validation: {
    titleCard: createInitObjectWithExtensions(VIDEO_EXTENSIONS_ARRAY, null),
    endCard: createInitObjectWithExtensions(VIDEO_EXTENSIONS_ARRAY, null),
    mainVideos: [
      createInitObjectWithExtensions(VIDEO_EXTENSIONS_ARRAY, null),
      createInitObjectWithExtensions(VIDEO_EXTENSIONS_ARRAY, null),
      createInitObjectWithExtensions(VIDEO_EXTENSIONS_ARRAY, null),
    ],
  },
};

const videoProjectElementsReducer = (state = initState, action) => {
  const { type, payload } = action;
  let cloneValidation;
  let firstValidation;
  let secondValidation;

  switch (type) {
    case VIDEO_PROJECT_ACTIVE_EXTENSION_CHANGE:
      return {
        ...state,
        activeExtension: payload,
      };

    case VIDEO_PROJECT_SOUND_FADE_TOGGLE:
      return {
        ...state,
        isSoundFade: !state.isSoundFade,
      };

    case VIDEO_PROJECT_SOUND_FADE_TIME_UPDATE:
      return {
        ...state,
        soundFadeTime: payload,
      };

    case VIDEO_PROJECT_AUDIO_UPDATE:
      return {
        ...state,
        audio: payload,
      };

    case VIDEO_PROJECT_UPLOAD_PATH_UPDATE:
      return {
        ...state,
        uploadPath: payload,
      };

    case VIDEO_PROJECT_RENDER_FRAME_UPDATE:
      return {
        ...state,
        renderFrame: payload,
      };

    case VIDEO_PROJECT_VALIDATION_UPDATE:
      return {
        ...state,
        validation: payload,
      };

    case VIDEO_PROJECT_SPECIFIC_VALIDATION_UPDATE:
      cloneValidation = cloneDeep(state.validation);

      if (payload.index !== undefined) {
        cloneValidation[payload.type][payload.index][payload.extension] = payload.validation;
      } else {
        cloneValidation[payload.type][payload.extension] = payload.validation;
      }

      return {
        ...state,
        validation: cloneValidation,
      };

    case VIDEO_PROJECT_MAIN_VIDEOS_VALIDATION_SWAP:
      cloneValidation = cloneDeep(state.validation);

      firstValidation = cloneValidation[VIDEO_CREATE_ASSETS_TYPES.MAIN_VIDEOS_ARRAY][payload.firstIndex];
      secondValidation = cloneValidation[VIDEO_CREATE_ASSETS_TYPES.MAIN_VIDEOS_ARRAY][payload.secondIndex];

      cloneValidation[VIDEO_CREATE_ASSETS_TYPES.MAIN_VIDEOS_ARRAY][payload.firstIndex] = secondValidation;
      cloneValidation[VIDEO_CREATE_ASSETS_TYPES.MAIN_VIDEOS_ARRAY][payload.secondIndex] = firstValidation;

      return {
        ...state,
        validation: cloneValidation,
      };

    case VIDEO_PROJECT_SET_CANVAS_TO_NORMAL_SIZE:
      return {
        ...state,
        canvasHeight: Math.round(CSS.canvasWidthDefault / (SCREEN_SIZE.LANDSCAPE_WIDTH / SCREEN_SIZE.LANDSCAPE_HEIGHT)),
        canvasWidth: CSS.canvasWidthDefault,
      };

    case VIDEO_PROJECT_SET_CANVAS_TO_FULL_SIZE:
      return {
        ...state,
        canvasHeight: Math.round(CSS.canvasWidthDefault / (SCREEN_SIZE.LANDSCAPE_WIDTH / SCREEN_SIZE.LANDSCAPE_HEIGHT)),
        canvasWidth: CSS.canvasWidthDefault,
      };

    case CLEAN_UP_VIDEO_PROJECT_ON_UNMOUNT:
    case VIDEO_PROJECT_DISCARD:
      return initState;

    default:
      return state;
  }
};

export default videoProjectElementsReducer;
