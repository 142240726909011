import { darken } from '@material-ui/core/styles';

import colors from '../colors.module.scss';

const borderWidthPx = 2;

const buttonVerticalPadding = '0.5em';
const buttonHorizontalPadding = '1.25em';

export const MuiButton = {
  root: {
    color: colors.darkGrey,
    padding: `${buttonVerticalPadding} ${buttonHorizontalPadding}`,
    borderRadius: 0,
    fontSize: '1rem',
  },
  contained: {
    color: colors.darkGrey,
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  outlined: {
    border: `${borderWidthPx}px solid`,
    borderColor: colors.lightGrey,
    padding: `calc(${buttonVerticalPadding} - ${borderWidthPx}px) calc(${buttonHorizontalPadding} - ${borderWidthPx}px)`,
  },
  containedPrimary: {
    color: 'white',
  },
  containedSecondary: {
    color: colors.darkGrey,
    '&:hover': {
      backgroundColor: darken(colors.lightGrey, 0.15),
    },
  },
  iconSizeMedium: {
    '& > *:first-child': {
      fontSize: 'inherit',
    },
  },
  endIcon: {
    '& svg': {
      width: '1em',
      height: '1em',
    },
  },
};

export const MuiChip = {
  root: {
    height: 'unset',
    padding: `${buttonVerticalPadding} ${buttonHorizontalPadding}`,
    borderRadius: 0,
    color: colors.darkGrey,
    backgroundColor: 'white',
    fontSize: '1rem',
  },
  label: {
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    lineHeight: 1.75,
  },
  colorPrimary: {
    color: 'white',
  },
};
