import { Record } from 'immutable';

import {
  GET_ANALYTICS_DEVICE_START,
  GET_ANALYTICS_DEVICE_FAIL,
  GET_ANALYTICS_DEVICE_SUCCESS,

  GET_ANALYTICS_DEVICE_BY_TPDID_START,
  GET_ANALYTICS_DEVICE_BY_TPDID_FAIL,
  GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS,

  SET_ANALYTICS_DEVICE_QUERY_ID,
  SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS,
} from './actionTypes';

const InitialState = Record({
  error: null,
  isFetching: false,
  queryId: '',
  deviceName: '',
  deviceModel: '',
  testDeviceiOS: false,
  testDeviceAndroid: false,
  testDeviceWinrt: false,
  testDeviceStandalone: false,
  testDeviceOther: false,
  primaryId: '',
  secondaryId: '',
  deviceInfoIOS: null,
  deviceInfoAndroid: null,
  deviceInfoWinRT: null,
  deviceInfoStandalone: null,
  deviceInfoOther: null,
});

const initialState = new InitialState();

export default function (state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_ANALYTICS_DEVICE_QUERY_ID: {
      return state.withMutations((ctx) => {
        const {
          queryId,
        } = action;
        ctx.set('queryId', queryId);
      });
    }

    case GET_ANALYTICS_DEVICE_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case GET_ANALYTICS_DEVICE_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          res,
          platform,
        } = action;

        const data = {
          ...res,
        };

        ctx.set('isFetching', false)
          .set('testDeviceiOS', null)
          .set('testDeviceAndroid', null)
          .set('testDeviceWinrt', null)
          .set('testDeviceStandalone', null)
          .set('testDeviceOther', null)
          .set('deviceInfoIOS', null)
          .set('deviceInfoAndroid', null)
          .set('deviceInfoWinRT', null)
          .set('deviceInfoStandalone', null)
          .set('deviceInfoOther', null);

        switch (platform) {
          case 'ios':
            ctx.set('testDeviceiOS', data.test_device)
              .set('deviceInfoIOS', data);
            break;
          case 'android':
            ctx.set('testDeviceAndroid', data.test_device)
              .set('deviceInfoAndroid', data);
            break;
          case 'winrt':
            ctx.set('testDeviceWinrt', data.test_device)
              .set('deviceInfoWinRT', data);
            break;
          case 'standalone':
            ctx.set('testDeviceStandalone', data.test_device)
              .set('deviceInfoStandalone', data);
            break;
          case 'other':
            ctx.set('testDeviceOther', data.test_device)
              .set('deviceInfoOther', data);
            break;
          default:
            return;
        }

      });
    }

    case GET_ANALYTICS_DEVICE_BY_TPDID_FAIL:
    case GET_ANALYTICS_DEVICE_FAIL: {
      return state.withMutations((ctx) => {
        const {
          error,
        } = action;

        ctx.set('isFetching', false)
          .set('error', error)
          .set('isFetching', false)
          .set('testDeviceiOS', null)
          .set('testDeviceAndroid', null)
          .set('testDeviceWinrt', null)
          .set('testDeviceStandalone', null)
          .set('testDeviceOther', null)
          .set('deviceInfoIOS', null)
          .set('deviceInfoAndroid', null)
          .set('deviceInfoWinRT', null)
          .set('deviceInfoStandalone', null)
          .set('deviceInfoOther', null);
      });
    }

    case GET_ANALYTICS_DEVICE_BY_TPDID_START: {
      return state.withMutations((ctx) => {
        ctx.set('isFetching', true)
          .set('error', null);
      });
    }

    case GET_ANALYTICS_DEVICE_BY_TPDID_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          res,
        } = action;

        const data = {
          ...res,
        };

        ctx.set('isFetching', false)
          .set('testDeviceiOS', data.ios)
          .set('testDeviceAndroid', data.android)
          .set('testDeviceWinrt', data.winrt)
          .set('testDeviceStandalone', data.standalone)
          .set('testDeviceOther', data.other)
          .set('deviceInfoIOS', data.deviceInfoIOS)
          .set('deviceInfoAndroid', data.deviceInfoAndroid)
          .set('deviceInfoWinRT', data.deviceInfoWinRT)
          .set('deviceInfoStandalone', data.deviceInfoStandalone)
          .set('deviceInfoOther', data.deviceInfoOther);
      });
    }

    case SET_ANALYTICS_DEVICE_TEST_STATUS_SUCCESS: {
      return state.withMutations((ctx) => {
        const {
          platform,
          res,
        } = action;

        const {
          test_device,
        } = res;

        switch (platform) {
          case 'ios':
            ctx.set('testDeviceiOS', test_device);
            break;
          case 'android':
            ctx.set('testDeviceAndroid', test_device);
            break;
          case 'winrt':
            ctx.set('testDeviceWinrt', test_device);
            break;
          case 'standalone':
            ctx.set('testDeviceStandalone', test_device);
            break;
          case 'other':
            ctx.set('testDeviceOther', test_device);
            break;
          default:
            return;
        }
      });
    }

    default: {
      return state;
    }
  }
}
