import FeathersClient, { addLocalTimeHeader } from '../utils/FeathersClient';
import { API_PREFIX } from '../constants/General';

const UPLOAD_HEADERS = {
  'Content-Type': undefined,
  'Accept': '*/*',
};


class AssetsService {
  constructor() {
    this.folders = FeathersClient.service(`${API_PREFIX}game-asset/folders`);
    this.docs = FeathersClient.service(`${API_PREFIX}game-asset/docs`);
    this.asset = FeathersClient.service(`${API_PREFIX}storage-object-metadata`);
    this.moveOrRename = FeathersClient.service(`${API_PREFIX}storage-object-metadata/move`);
    this.usedByUa = FeathersClient.service(`${API_PREFIX}storage-object-metadata/used-by-ua`);
    this.share = FeathersClient.service(`${API_PREFIX}share-assets`);
    this.searchAssetDuplicates = FeathersClient.service(`${API_PREFIX}storage-object-metadata/search`);
  }

  createFolder(data) {
    return this.folders.create(data);
  }

  createDoc(form, gameId) {
    return this.docs.create(form, {
      headers: addLocalTimeHeader(UPLOAD_HEADERS),
      query: {
        gameId,
      },
    });
  }

  deleteAssets(data) {
    return this.asset.remove('null', {
      query: {
        ...data,
      },
    });
  }

  moveOrRenameAsset(data) {
    return this.moveOrRename.create(data);
  }

  updateAsset(id, data) {
    return this.asset.patch(id, data);
  }

  getAssetDuplicates(assetNames) {
    return this.searchAssetDuplicates.find({ query: {
        fileName: assetNames.map((assetName) => `%/${assetName}`),
      }});
  }

  getSharedAssetsHash(data) {
    return this.share.create(data);
  }

  getSharedAssets(query) {
    return this.share.find({ query });
  }

  setUsedByUa(id, usedByUaNa) {
    return this.usedByUa.patch(id, {usedByUaNa});
  }
}

export default new AssetsService();
