import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';
import { AD_CREATE_BANNER_ITEM_TYPES } from '../../../constants/CreativeTool';
import { defaultButtonTemplateAdCreate } from '../laboratoryConstants';
import {
  AD_CREATE_PLAYER_DIMENSIONS,
  AD_CREATE_RATIOS,
  AD_CREATE_IMAGE_OVERLAY_LABELS,
  INDENT_FOR_DEFAULT_BUTTON,
  INDENT_FOR_GAME_LOGO,
} from './adCreateConstants';


export const createOverlayImplementationOptions = (overlayName) => {
  return [
    {id: 1, name: 'none', value: 'none'},
    {id: 2, name: 'add randomly and automatically', value: 'add randomly and automatically'},
    {id: 3, name: `add from the list of ${overlayName}`, value: `add from the list of ${overlayName}`},
  ];
};

export const calculateDefaultCropPositionForSize = (sizeRatio) => {
  if (sizeRatio < AD_CREATE_RATIOS.LANDSCAPE) {
    const widthOnPlayer = AD_CREATE_PLAYER_DIMENSIONS.height * sizeRatio;
    const shiftLeft = (AD_CREATE_PLAYER_DIMENSIONS.width - widthOnPlayer) / 2 / AD_CREATE_PLAYER_DIMENSIONS.width * 100;

    return {
      x: shiftLeft,
      y: 0,
    };
  } else if (sizeRatio > AD_CREATE_RATIOS.LANDSCAPE) {
    const heightOnPlayer = AD_CREATE_PLAYER_DIMENSIONS.width / sizeRatio;
    const shiftTop = (AD_CREATE_PLAYER_DIMENSIONS.height - heightOnPlayer) / 2 / AD_CREATE_PLAYER_DIMENSIONS.height * 100;

    return {
      x: 0,
      y: shiftTop,
    };
  } else {
    return {
      x: 0,
      y: 0,
    };
  }
};

export const createDefaultButtonOverlayForSize = (sizeWidth, sizeHeight, font) => {
  const cloneDefaultButtonTemplateAdCreate = cloneDeep(defaultButtonTemplateAdCreate);

  if (sizeWidth / sizeHeight < AD_CREATE_RATIOS.LANDSCAPE_START) {
    cloneDefaultButtonTemplateAdCreate.styles.top = AD_CREATE_PLAYER_DIMENSIONS.height - INDENT_FOR_DEFAULT_BUTTON - parseInt(cloneDefaultButtonTemplateAdCreate.styles.height) + 'px';
    cloneDefaultButtonTemplateAdCreate.styles.left = AD_CREATE_PLAYER_DIMENSIONS.width / 2 - parseInt(cloneDefaultButtonTemplateAdCreate.styles.width) / 2 + 'px';
  } else if (sizeWidth / sizeHeight >= AD_CREATE_RATIOS.LANDSCAPE_START && sizeWidth / sizeHeight < AD_CREATE_RATIOS.LANDSCAPE) {
    const ratioWidthOfSize = sizeWidth / sizeHeight * AD_CREATE_PLAYER_DIMENSIONS.height;
    const widthOfOneBlackCrop = (AD_CREATE_PLAYER_DIMENSIONS.width - ratioWidthOfSize) / 2;

    cloneDefaultButtonTemplateAdCreate.styles.top = AD_CREATE_PLAYER_DIMENSIONS.height - INDENT_FOR_DEFAULT_BUTTON - parseInt(cloneDefaultButtonTemplateAdCreate.styles.height) + 'px';
    cloneDefaultButtonTemplateAdCreate.styles.left = widthOfOneBlackCrop + INDENT_FOR_DEFAULT_BUTTON + 'px';
  } else if (sizeWidth / sizeHeight >= AD_CREATE_RATIOS.LANDSCAPE && sizeWidth / sizeHeight < AD_CREATE_RATIOS.LANDSCAPE_BREAK) {
    const ratioHeightOfSize = sizeHeight / sizeWidth * AD_CREATE_PLAYER_DIMENSIONS.width;
    const heightOfOneBlackCrop = (AD_CREATE_PLAYER_DIMENSIONS.height - ratioHeightOfSize) / 2;

    cloneDefaultButtonTemplateAdCreate.styles.top = ratioHeightOfSize + heightOfOneBlackCrop - INDENT_FOR_DEFAULT_BUTTON - parseInt(cloneDefaultButtonTemplateAdCreate.styles.height) + 'px';
    cloneDefaultButtonTemplateAdCreate.styles.left = INDENT_FOR_DEFAULT_BUTTON + 'px';
  } else if (Math.round(sizeWidth / sizeHeight) >= AD_CREATE_RATIOS.LANDSCAPE_BREAK) {
    cloneDefaultButtonTemplateAdCreate.styles.top = AD_CREATE_PLAYER_DIMENSIONS.height / 2 - parseInt(cloneDefaultButtonTemplateAdCreate.styles.height) / 2 + 'px';
    cloneDefaultButtonTemplateAdCreate.styles.left = AD_CREATE_PLAYER_DIMENSIONS.width - INDENT_FOR_DEFAULT_BUTTON - parseInt(cloneDefaultButtonTemplateAdCreate.styles.width) + 'px';
  }

  return [
    {
      ...cloneDefaultButtonTemplateAdCreate,
      id: uuidv4(),
      groupId: 1,
      fontId: font?.docId,
      styles: {
        ...cloneDefaultButtonTemplateAdCreate.styles,
        'font-family': font?.name,
        'z-index': '1',
      },
    },
  ];
};

export const addGameLogoToSize = (size, gameLogo) => {
  // remove existing game logo from size
  const cloneStructure = cloneDeep(size.structure.filter((bannerItem) => bannerItem.label !== AD_CREATE_IMAGE_OVERLAY_LABELS.LOGO));

  // change game logo size and position depending on size
  const cloneGameLogo = cloneDeep(gameLogo);
  cloneGameLogo.id = uuidv4();

  const gameLogoWidthToHeightRatio = parseFloat(cloneGameLogo.styles.width) / parseFloat(cloneGameLogo.styles.height);
  let heightOfOneBlackCrop = (AD_CREATE_PLAYER_DIMENSIONS.height - size.height / size.width * AD_CREATE_PLAYER_DIMENSIONS.width) / 2;
  let widthOfOneBlackCrop = (AD_CREATE_PLAYER_DIMENSIONS.width - size.width / size.height * AD_CREATE_PLAYER_DIMENSIONS.height) / 2;

  if (heightOfOneBlackCrop < 0) {
    heightOfOneBlackCrop = 0;
  }

  if (widthOfOneBlackCrop < 0) {
    widthOfOneBlackCrop = 0;
  }

  let newGameLogoWidth = 100;
  let newGameLogoHeight = 100;

  if (parseFloat(gameLogo.styles.width) > parseFloat(gameLogo.styles.height)) {
    newGameLogoHeight = newGameLogoWidth / gameLogoWidthToHeightRatio;
  } else {
    newGameLogoWidth = newGameLogoHeight * gameLogoWidthToHeightRatio;
  }

  cloneGameLogo.styles.height = newGameLogoHeight + 'px';
  cloneGameLogo.styles.width = newGameLogoWidth + 'px';

  if (Math.round(size.width / size.height) >= AD_CREATE_RATIOS.LANDSCAPE_BREAK) {
    cloneGameLogo.styles.left = AD_CREATE_PLAYER_DIMENSIONS.width / 2 - newGameLogoWidth / 2 + 'px';
    cloneGameLogo.styles.top = AD_CREATE_PLAYER_DIMENSIONS.height / 2 - newGameLogoHeight / 2 + 'px';
  } else if (size.width / size.height < AD_CREATE_RATIOS.LANDSCAPE_START) {
    cloneGameLogo.styles.left = widthOfOneBlackCrop + INDENT_FOR_GAME_LOGO + 'px';
    cloneGameLogo.styles.top = INDENT_FOR_GAME_LOGO + 'px';
  } else {
    cloneGameLogo.styles.left = AD_CREATE_PLAYER_DIMENSIONS.width / 2 - newGameLogoWidth / 2 + 'px';
    cloneGameLogo.styles.top = heightOfOneBlackCrop + INDENT_FOR_GAME_LOGO + 'px';
  }

  // add game logo to size
  cloneStructure.push(cloneGameLogo);

  return cloneStructure;
};

/**
 * Helper class for Ad Create size overlays transformation
 */
class AdCreateProjectClassHelper {
  constructor(sizes, activeSize, payload) {
    this.sizes = sizes;
    this.activeSize = activeSize;
    this.payload = payload;
  }

  addNewSize(font, gameLogo) {
    const cloneSizes = cloneDeep(this.sizes);
    const sizeToAdd = cloneDeep(this.payload);

    const newSizeObject = {
      id: sizeToAdd.id,
      width: sizeToAdd.width,
      height: sizeToAdd.height,
      zoom: {
        power: 1,
        crop: {
          x: 0,
          y: 0,
        },
        dragAndDrop: calculateDefaultCropPositionForSize(sizeToAdd.width / sizeToAdd.height),
      },
      structure: [],
    };

    if (cloneSizes.length) {
      const biggestStructure = cloneDeep(cloneSizes.reduce((result, currentSize) => currentSize.structure.length > result.length ?
        currentSize.structure :
        result, []
      ));

      biggestStructure.forEach((bannerItem) => ({
        ...bannerItem,
        id: uuidv4(),
      }));

      newSizeObject.structure = biggestStructure;
    } else {
      newSizeObject.structure = createDefaultButtonOverlayForSize(newSizeObject.width, newSizeObject.height, font);
    }

    if (gameLogo) {
      newSizeObject.structure = addGameLogoToSize(newSizeObject, gameLogo);
    }

    cloneSizes.push(newSizeObject);
    
    return cloneSizes;
  }

  addOverlay() {
    const cloneSizes = cloneDeep(this.sizes);
    const cloneActiveSize = cloneDeep(this.activeSize);
    const cloneOverlay = cloneDeep(this.payload);

    if (cloneOverlay.type === AD_CREATE_BANNER_ITEM_TYPES.BUTTON) {
      const lastProjectButtonNumber = cloneSizes.reduce((projectNumberAccumulator, currentSize) => {
        const lastSizeButtonNumber = currentSize.structure.reduce((sizeNumberAccumulator, currentBannerItem) => {
          if (currentBannerItem.type === AD_CREATE_BANNER_ITEM_TYPES.BUTTON && currentBannerItem.groupId > sizeNumberAccumulator) {
            return currentBannerItem.groupId;
          } else {
            return sizeNumberAccumulator;
          }
        }, 0);

        if (lastSizeButtonNumber > projectNumberAccumulator) {
          return lastSizeButtonNumber;
        } else {
          return projectNumberAccumulator;
        }
      }, 0);

      cloneOverlay.groupId = lastProjectButtonNumber + 1;
    }

    cloneSizes.forEach((size) => {
      const lastIndexZ = size.structure.length;
      const id = uuidv4();

      cloneOverlay.id = id;
      cloneOverlay.styles['z-index'] = (lastIndexZ + 1).toString();

      if (cloneOverlay.type === AD_CREATE_BANNER_ITEM_TYPES.CONTAINER) {
        cloneOverlay.nestedBannerItems.forEach((nestedBannerItem) => {
          nestedBannerItem.styles['z-index'] = (lastIndexZ + 1).toString();
          nestedBannerItem.id = uuidv4();
          nestedBannerItem.containerId = cloneOverlay.id;
        });
      }

      if (cloneActiveSize?.id === size.id) {
        cloneActiveSize.structure = [
          ...size.structure,
          cloneDeep(cloneOverlay),
        ];
      }

      size.structure = [
        ...size.structure,
        cloneDeep(cloneOverlay),
      ];
    });

    return [cloneSizes, cloneActiveSize];
  }

  changeButtons() {
    const cloneSizes = cloneDeep(this.sizes);
    const {sizeId, banner} = cloneDeep(this.payload);
    const buttonGroupId = banner.groupId;
    const stylesNames = ['color', 'font-weight', 'font-family', 'font-style', 'text-decoration', 'background', 'border-color', 'border-width', 'border-radius'];

    cloneSizes.forEach((size) => {
      if (size.id === sizeId) {
        const activeBannerItem = size.structure.find((item) => item.id === banner.id);
        activeBannerItem.styles = banner.styles;
        activeBannerItem.fontId = banner?.fontId;
        activeBannerItem.text = banner.text;
      } else {
        const notActiveBannerItem = size.structure.find((item) =>
          item.type === AD_CREATE_BANNER_ITEM_TYPES.BUTTON &&
          item.groupId === buttonGroupId
        );

        if (notActiveBannerItem) {
          notActiveBannerItem.fontId = banner?.fontId;
          notActiveBannerItem.text = banner.text;
          stylesNames.forEach((styleName) => notActiveBannerItem.styles[styleName] = banner.styles[styleName]);
        }
      }
    });

    return cloneSizes;
  }

}

export default AdCreateProjectClassHelper;

/**
 * Get most top/bottom/left/right point of item with rotate
 */
export const getPixelsPointsByAngle = (x, y, width, height, angle) => {
  const radians = angle * Math.PI / 180;

  return [
    Math.min(
      y + height/2 + width/-2 * Math.sin(radians) + height/-2 * Math.cos(radians),
      y + height/2 + width/2 * Math.sin(radians) + height/-2 * Math.cos(radians),
      y + height/2 + width/2 * Math.sin(radians) + height/2 * Math.cos(radians),
      y + height/2 + width/-2 * Math.sin(radians) + height/2 * Math.cos(radians)
    ),
    Math.max(
      y + height/2 + width/-2 * Math.sin(radians) + height/-2 * Math.cos(radians),
      y + height/2 + width/2 * Math.sin(radians) + height/-2 * Math.cos(radians),
      y + height/2 + width/2 * Math.sin(radians) + height/2 * Math.cos(radians),
      y + height/2 + width/-2 * Math.sin(radians) + height/2 * Math.cos(radians)
    ),
    Math.min(
      x + width/2 + width/-2 * Math.cos(radians) - height/-2 * Math.sin(radians),
      x + width/2 + width/2 * Math.cos(radians) - height/-2 * Math.sin(radians),
      x + width/2 + width/2 * Math.cos(radians) - height/2 * Math.sin(radians),
      x + width/2 + width/-2 * Math.cos(radians) - height/2 * Math.sin(radians)
    ),
    Math.max(
      x + width/2 + width/-2 * Math.cos(radians) - height/-2 * Math.sin(radians),
      x + width/2 + width/2 * Math.cos(radians) - height/-2 * Math.sin(radians),
      x + width/2 + width/2 * Math.cos(radians) - height/2 * Math.sin(radians),
      x + width/2 + width/-2 * Math.cos(radians) - height/2 * Math.sin(radians)
    ),
  ];
};

export const getLeastLeftPositionWithRotate = (x, width, height, angle) => {
  const radians = angle * Math.PI / 180;

  return Math.max(
    x - width/2 - width/-2 * Math.cos(radians) + height/-2 * Math.sin(radians),
    x - width/2 - width/2 * Math.cos(radians) + height/-2 * Math.sin(radians),
    x - width/2 - width/2 * Math.cos(radians) + height/2 * Math.sin(radians),
    x - width/2 - width/-2 * Math.cos(radians) + height/2 * Math.sin(radians)
  );
};

export const getMostLeftPositionWithRotate = (x, width, height, angle) => {
  const radians = angle * Math.PI / 180;

  return Math.min(
    x - width/2 - width/-2 * Math.cos(radians) + height/-2 * Math.sin(radians),
    x - width/2 - width/2 * Math.cos(radians) + height/-2 * Math.sin(radians),
    x - width/2 - width/2 * Math.cos(radians) + height/2 * Math.sin(radians),
    x - width/2 - width/-2 * Math.cos(radians) + height/2 * Math.sin(radians)
  );
};

export const getLeastTopPositionWithRotate = (y, width, height, angle) => {
  const radians = angle * Math.PI / 180;

  return Math.max(
    y - height/2 - width/-2 * Math.sin(radians) - height/-2 * Math.cos(radians),
    y - height/2 - width/2 * Math.sin(radians) - height/-2 * Math.cos(radians),
    y - height/2 - width/2 * Math.sin(radians) - height/2 * Math.cos(radians),
    y - height/2 - width/-2 * Math.sin(radians) - height/2 * Math.cos(radians)
  );
};

export const getMostTopPositionWithRotate = (y, width, height, angle) => {
  const radians = angle * Math.PI / 180;

  return Math.min(
    y - height/2 - width/-2 * Math.sin(radians) - height/-2 * Math.cos(radians),
    y - height/2 - width/2 * Math.sin(radians) - height/-2 * Math.cos(radians),
    y - height/2 - width/2 * Math.sin(radians) - height/2 * Math.cos(radians),
    y - height/2 - width/-2 * Math.sin(radians) - height/2 * Math.cos(radians)
  );
};

export const getContainerBannerItemEdges = (nestedBannerItems, currentNestedBannerItemId) => {
  const cloneOtherNestedBannerItems = cloneDeep(nestedBannerItems.filter((nestedBannerItems) => nestedBannerItems.id !== currentNestedBannerItemId));

  const arrayOfMaxTopPoints = [];
  const arrayOfMaxBottomPoints = [];
  const arrayOfMaxLeftPoints = [];
  const arrayOfMaxRightPoints = [];

  cloneOtherNestedBannerItems.forEach((nestedBannerItem) => {
    const transform = nestedBannerItem.styles.transform;
    const rotateDeg = Math.round(transform.substring(transform.indexOf('rotate'), transform.indexOf('deg')).replace(/[^\d.-]/g, ''));
    const [widthAfterScale, heightAfterScale, leftWithScale, topWithScale] = getPositionAndDimensionAfterScaling(nestedBannerItem);

    const [
      maxTopPoint,
      maxBottomPoint,
      maxLeftPoint,
      maxRightPoint,
    ] = getPixelsPointsByAngle(
      leftWithScale,
      topWithScale,
      widthAfterScale,
      heightAfterScale,
      rotateDeg
    );

    arrayOfMaxTopPoints.push(maxTopPoint);
    arrayOfMaxBottomPoints.push(maxBottomPoint);
    arrayOfMaxLeftPoints.push(maxLeftPoint);
    arrayOfMaxRightPoints.push(maxRightPoint);
  });

  return {
    minTop: Math.min(...arrayOfMaxTopPoints),
    maxBottom: Math.max(...arrayOfMaxBottomPoints),
    minLeft: Math.min(...arrayOfMaxLeftPoints),
    maxRight: Math.max(...arrayOfMaxRightPoints),
  };
};

export const getPositionAndDimensionAfterScaling = (bannerItem) => {
  const {left, top, width, height, transform} = bannerItem.styles;
  const scale = transform.substring(transform.indexOf('scale')).replace(/[^\d.-]/g, '');

  const widthAfterScale = parseFloat(width) * scale;
  const heightAfterScale = parseFloat(height) * scale;
  const leftWithScale = parseFloat(left) - (widthAfterScale - parseFloat(width)) / 2;
  const topWithScale = parseFloat(top) - (heightAfterScale - parseFloat(height)) / 2;

  return [
    widthAfterScale,
    heightAfterScale,
    leftWithScale,
    topWithScale,
  ];
};

export const getNestedBannerItemPositionAndScaleAfterScalingContainer = (bannerItem, containerBannerItem, changes) => {
  const {left, top, width, height, transform} = bannerItem.styles;
  const {left: containerLeft, top: containerTop} = containerBannerItem.styles;
  const rotateDeg = Math.round(transform.substring(transform.indexOf('rotate'), transform.indexOf('deg')).replace(/[^\d.-]/g, ''));
  const scale = Number(transform.substring(transform.indexOf('scale')).replace(/[^\d.-]/g, ''));
  const newScale = scale + (scale * changes.scaleDiffPercent);

  const widthWithCurrentScale = parseFloat(width) * scale;
  const heightWithCurrentScale = parseFloat(height) * scale;
  const leftWithCurrentScale = parseFloat(left) - (widthWithCurrentScale - parseFloat(width)) / 2;
  const topWithCurrentScale = parseFloat(top) - (heightWithCurrentScale - parseFloat(height)) / 2;

  const [
    minTopPoint,
    ,
    minLeftPoint,
  ] = getPixelsPointsByAngle(
    leftWithCurrentScale,
    topWithCurrentScale,
    widthWithCurrentScale,
    heightWithCurrentScale,
    rotateDeg
  );

  const widthWithNewScale = parseFloat(width) * newScale;
  const heightWithNewScale = parseFloat(height) * newScale;
  const leftWithNewScale = parseFloat(left) - (widthWithNewScale - parseFloat(width)) / 2;
  const topWithNewScale = parseFloat(top) - (heightWithNewScale - parseFloat(height)) / 2;

  const [
    minTopPointAfterScale,
    ,
    minLeftPointAfterScale,
  ] = getPixelsPointsByAngle(
    leftWithNewScale,
    topWithNewScale,
    widthWithNewScale,
    heightWithNewScale,
    rotateDeg
  );

  const leftShift = (minLeftPoint - parseFloat(containerLeft)) * changes.scaleDiff;
  const topShift = (minTopPoint - parseFloat(containerTop)) * changes.scaleDiff;

  const newLeft = parseFloat(left) + (minLeftPoint - minLeftPointAfterScale) + leftShift + 'px';
  const newTop = parseFloat(top) + (minTopPoint - minTopPointAfterScale) + topShift + 'px';

  return {newLeft, newTop, newScale};
};