import { Record } from 'immutable';

import {
  SET_FILES_TO_SHARE,

  SEND_SHARING_DATA_REQUEST,
  SEND_SHARING_DATA_SUCCESS,
  SEND_SHARING_DATA_FAIL,

  OPEN_SHARING_MODAL,
  CLOSE_SHARING_MODAL,
} from './actionTypes';

const InitialState = Record({
  isSharingModal: false,
  isFetching: false,
  filesToShare: [],
  creativeId: 0,
  mainImage: false,
  error: null,
});

export const actionHandlers = {
  [SEND_SHARING_DATA_REQUEST]: (state) => state.withMutations((ctx) => ctx.set('isFetching', true)),
  [SEND_SHARING_DATA_SUCCESS]: (state) => state.withMutations((ctx) => {
    ctx
      .set('isFetching', false)
      .set('error', null);
  }),
  [SEND_SHARING_DATA_FAIL]: (state, action) => state.withMutations((ctx) => {
    ctx
      .set('isFetching', false)
      .set('error', action.error);
  }),

  [SET_FILES_TO_SHARE]: (state, action) => state.withMutations((ctx) => {
    ctx
      .set('filesToShare', action.filesToShare)
      .set('creativeId', action.creativeId ? action.creativeId : 0)
      .set('mainImage', action.mainImage || false);
  }),

  [OPEN_SHARING_MODAL]: (state) => state.withMutations((ctx) => ctx.set('isSharingModal', true)),
  [CLOSE_SHARING_MODAL]: (state) => state.withMutations((ctx) => ctx.set('isSharingModal', false)),
};

const initialState = new InitialState();

export const reducers = (state = initialState, action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};

export default reducers;
