import GameModel from '../Models/gameModal';
import ExternalUserModel from '../Models/externalUserModel';
import { SPECIAL_GAME_NAME } from 'constants/Game';
import {
  GET_ALL_GAMES_FOR_EXTERNAL_USERS,
  RESET_ALL_GAMES_FOR_EXTERNAL_USERS,
  GET_EXTERNAL_USERS_START,
  GET_EXTERNAL_USERS_SUCCESS,
  GET_EXTERNAL_USERS_FAIL,
  EXTERNAL_USER_ACTION_START,
  EXTERNAL_USER_ACTION_SUCCESS,
  EXTERNAL_USER_ACTION_FAIL,
} from './actionsTypes';


const initState = {
  games: null,
  externalUsers: {
    loading: false,
    success: {
      total: 0,
      limit: 0,
      skip: 0,
      data: [],
    },
    error: '',
  },
  userActions: {
    loading: false,
    success: false,
    error: '',
  },
};

const externalUsersLoginReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {

    case GET_ALL_GAMES_FOR_EXTERNAL_USERS:
      return {
        ...state,
        games: payload
          .filter((game) => game.name !== SPECIAL_GAME_NAME)
          .map((game) => new GameModel(game)),
      };

    case RESET_ALL_GAMES_FOR_EXTERNAL_USERS:
      return {
        ...state,
        games: null,
      };

    case GET_EXTERNAL_USERS_START:
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          loading: true,
        },
      };

    case GET_EXTERNAL_USERS_SUCCESS:
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          loading: false,
          success: {
            ...payload,
            data: payload.data.map((dataItem) => new ExternalUserModel(dataItem, state.games)),
          },
          error: '',
        },
      };

    case GET_EXTERNAL_USERS_FAIL:
      return {
        ...state,
        externalUsers: {
          ...state.externalUsers,
          loading: false,
          error: payload,
        },
      };

    case EXTERNAL_USER_ACTION_START:
      return {
        ...state,
        userActions: {
          loading: true,
          success: false,
          error: '',
        },
      };

    case EXTERNAL_USER_ACTION_SUCCESS:
      return {
        ...state,
        userActions: {
          loading: false,
          success: true,
          error: '',
        },
      };

    case EXTERNAL_USER_ACTION_FAIL:
      return {
        ...state,
        userActions: {
          loading: false,
          success: false,
          error: payload,
        },
      };

    default:
      return state;
  }
};

export default externalUsersLoginReducer;
