import PropTypes from 'prop-types';
import { attributeOptionPropType } from 'types/assets';
import { ASSET_CATEGORIES_KEYS } from 'constants/Asset';


export const assetsClassificationPropTypes = PropTypes.shape({
  [ASSET_CATEGORIES_KEYS.CATEGORY]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
});

export const uploadInfoPropTypes = PropTypes.shape({
  isCreative: PropTypes.bool.isRequired,
  gameId: PropTypes.number,
  creativityId: PropTypes.number,
  storageId: PropTypes.number,
  creativeTabName: PropTypes.string,
  dirPath: PropTypes.string,
  gameRootFolder: PropTypes.string,
});

export const assetToUploadErrorsPropTypes = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.oneOf([null]),
      ]),
    }),
    PropTypes.oneOf([null]),
  ]),
});

export const assetToUploadPropTypes = PropTypes.shape({
  id: PropTypes.string,
  file: PropTypes.object,
  name: PropTypes.string,
  path: PropTypes.string,
  iconPreview: PropTypes.string,
  [ASSET_CATEGORIES_KEYS.CATEGORY]: PropTypes.string,
  [ASSET_CATEGORIES_KEYS.SUB_CATEGORY]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  [ASSET_CATEGORIES_KEYS.PRODUCTION_FORMAT]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  [ASSET_CATEGORIES_KEYS.DEVELOPER_FORMAT]: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  typeCat: PropTypes.oneOfType([
    attributeOptionPropType,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  description: PropTypes.string,
});

export const folderStructurePropTypes = PropTypes.shape({
  path: PropTypes.string,
  assets: PropTypes.arrayOf(assetToUploadPropTypes),
});
