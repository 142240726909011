import React from 'react';
import { createTheme } from '@material-ui/core/styles';

import { MuiButton, MuiChip } from './components/buttons';
import { MuiCheckbox } from './components/checkbox';
import { MuiInput, MuiInputBase, MuiOutlinedInput } from './components/inputs';
import { MuiFormLabel } from './components/formLabel';
import { MuiAutocomplete } from './components/autocomplete';
import { MuiMenuItem } from './components/menu';
import { MuiAccordion, MuiAccordionSummary } from './components/expansionPanel';
import { MuiSlider } from './components/slider';
import { MuiSelect } from './components/select';
import { MuiTableCell } from './components/table';
import { MuiContainer } from './components/container';

import ZonaProBold from '../assets/fonts/zona-pro/zonapro-bold.otf';
import ZonaProRegular from '../assets/fonts/zona-pro/zonapro-regular.otf';
import ZonaProLight from '../assets/fonts/zona-pro/zonapro-light.otf';
import ZonaProThin from '../assets/fonts/zona-pro/zonapro-thin.otf';

import colors from './colors.module.scss';
import fonts from './fonts.module.scss';

import { ReactComponent as CheckIcon } from '../assets/svg/check.svg';
import { ReactComponent as ChevronIcon } from '../assets/svg/chevron-down.svg';

const zonaProBold = {
  fontFamily: 'Zona Pro',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Zona Pro'),
    url(${ZonaProBold}) format('opentype')
  `,
};

const zonaProRegular = {
  fontFamily: 'Zona Pro',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Zona Pro'),
    url(${ZonaProRegular}) format('opentype')
  `,
};

const zonaProLight = {
  fontFamily: 'Zona Pro',
  fontWeight: 300,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Zona Pro'),
    url(${ZonaProLight}) format('opentype')
  `,
};

const zonaProThin = {
  fontFamily: 'Zona Pro',
  fontWeight: 100,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  src: `
    local('Zona Pro'),
    url(${ZonaProThin}) format('opentype')
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.lightGrey,
    },
    background: {
      default: '#FFFFFF',
    },
    text: {
      primary: colors.darkNavy,
    },
  },

  typography: {
    fontFamily: fonts.zonaPro,
    h1: {
      marginBottom: '0.5rem',
      color: colors.pink,
      fontSize: '1.75rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    button: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [zonaProBold, zonaProRegular, zonaProLight, zonaProThin],
      },
    },

    MuiButton,
    MuiChip,
    MuiInput,
    MuiInputBase,
    MuiOutlinedInput,
    MuiAutocomplete,
    MuiMenuItem,
    MuiFormLabel,
    MuiCheckbox,
    MuiAccordion,
    MuiAccordionSummary,
    MuiSlider,
    MuiSelect,
    MuiTableCell,
    MuiContainer,
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiCheckbox: {
      color: 'default',
      checkedIcon: <CheckIcon />,
      icon: <span />,
    },
    MuiAccordionSummary: {
      expandIcon: <ChevronIcon />,
    },
    MuiAccordion: {
      elevation: 0,
      square: true,
    },
    MuiPaper: {
      elevation: 0,
      square: true,
    },
    MuiCard: {
      elevation: 0,
    },
    MuiList: {
      disablePadding: true,
    },
    MuiSelect: {
      IconComponent: ChevronIcon,
    },
  },
});

export default theme;
