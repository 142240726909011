// Creative actions types
export const GET_CREATIVES_START = 'GET_CREATIVES_START';
export const GET_CREATIVES_FAIL = 'GET_CREATIVES_FAIL';
export const GET_CREATIVES_SUCCESS = 'GET_CREATIVES_SUCCESS';

export const GET_CREATIVE_BY_ID_START = 'GET_CREATIVE_BY_ID_START';
export const GET_CREATIVE_BY_ID_FAIL = 'GET_CREATIVE_BY_ID_FAIL';
export const GET_CREATIVE_BY_ID_SUCCESS = 'GET_CREATIVE_BY_ID_SUCCESS';

export const UPDATE_CREATIVE_START = 'UPDATE_CREATIVE_START';
export const UPDATE_CREATIVE_SUCCESS = 'UPDATE_CREATIVE_SUCCESS';
export const UPDATE_CREATIVE_FAIL = 'UPDATE_CREATIVE_FAIL';

export const SET_MAIN_IMAGE_FROM_DOC_REQUEST = 'SET_MAIN_IMAGE_FROM_DOC_REQUEST';
export const SET_MAIN_IMAGE_FROM_DOC_SUCCESS = 'SET_MAIN_IMAGE_FROM_DOC_SUCCESS';
export const SET_MAIN_IMAGE_FROM_DOC_FAIL = 'SET_MAIN_IMAGE_FROM_DOC_FAIL';

export const DELETE_CREATIVE_START = 'DELETE_CREATIVE_START';
export const DELETE_CREATIVE_FAIL = 'DELETE_CREATIVE_FAIL';
export const DELETE_CREATIVE_SUCCESS = 'DELETE_CREATIVE_SUCCESS';

// Jira issue actions types
export const GET_JIRA_ISSUE_METADATA_SUCCESS = 'GET_JIRA_ISSUE_METADATA_SUCCESS';

// Transition actions types
export const GET_JIRA_ISSUE_TRANSITION_SUCCESS = 'GET_JIRA_ISSUE_TRANSITION_SUCCESS';

// Tab actions types
export const SET_CURRENT_TAB_CREATIVE = 'SET_CURRENT_TAB_CREATIVE';

export const CREATE_NEW_TAB_REQUEST = 'CREATE_NEW_TAB_REQUEST';
export const CREATE_NEW_TAB_SUCCESS = 'CREATE_NEW_TAB_SUCCESS';
export const CREATE_NEW_TAB_FAIL = 'CREATE_NEW_TAB_FAIL';

export const RENAME_TAB_REQUEST = 'RENAME_TAB_REQUEST';
export const RENAME_TAB_SUCCESS = 'RENAME_TAB_SUCCESS';
export const RENAME_TAB_FAIL = 'RENAME_TAB_FAIL';

export const DELETE_TAB_REQUEST = 'DELETE_TAB_REQUEST';
export const DELETE_TAB_SUCCESS = 'DELETE_TAB_SUCCESS';
export const DELETE_TAB_FAIL = 'DELETE_TAB_FAIL';

// Asset (file, folder) actions types
export const CREATE_NEW_FOLDER_CREATIVE_REQUEST = 'CREATE_NEW_FOLDER_CREATIVE_REQUEST';
export const CREATE_NEW_FOLDER_CREATIVE_SUCCESS = 'CREATE_NEW_FOLDER_CREATIVE_SUCCESS';
export const CREATE_NEW_FOLDER_CREATIVE_FAIL = 'CREATE_NEW_FOLDER_CREATIVE_FAIL';

export const UPLOAD_DOC_CREATIVE_REQUEST = 'UPLOAD_DOC_CREATIVE_REQUEST';
export const UPLOAD_DOC_CREATIVE_SUCCESS = 'UPLOAD_DOC_CREATIVE_SUCCESS';
export const UPLOAD_DOC_CREATIVE_FAIL = 'UPLOAD_DOC_CREATIVE_FAIL';

// Comment actions types
export const GET_JIRA_ISSUE_COMMENT_START = 'GET_JIRA_ISSUE_COMMENT_START';
export const GET_JIRA_ISSUE_COMMENT_SUCCESS = 'GET_JIRA_ISSUE_COMMENT_SUCCESS';
export const GET_JIRA_ISSUE_COMMENT_FAIL = 'GET_JIRA_ISSUE_COMMENT_FAIL';

export const ADD_JIRA_ISSUE_COMMENT_START = 'ADD_JIRA_ISSUE_COMMENT_START';
export const ADD_JIRA_ISSUE_COMMENT_SUCCESS = 'ADD_JIRA_ISSUE_COMMENT_SUCCESS';
export const ADD_JIRA_ISSUE_COMMENT_FAIL = 'ADD_JIRA_ISSUE_COMMENT_FAIL';

export const DELETE_JIRA_ISSUE_COMMENT_START = 'DELETE_JIRA_ISSUE_COMMENT_START';
export const DELETE_JIRA_ISSUE_COMMENT_SUCCESS = 'DELETE_JIRA_ISSUE_COMMENT_SUCCESS';
export const DELETE_JIRA_ISSUE_COMMENT_FAIL = 'DELETE_JIRA_ISSUE_COMMENT_FAIL';
