import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip, Zoom } from '@material-ui/core';
import CustomCheckbox from 'components/UIComponents/CustomCheckbox';
import { assetToUploadPropTypes } from 'components/AssetUploader/Types';
import { IMAGE_TAG_SUPPORTED_EXTENSIONS } from 'constants/Asset';
import { str } from 'utils/utils';

import CloseIcon from '@material-ui/icons/Close';

import './UploaderAssetCard.scss';

const UploaderAssetCard = ({ asset, isSelected, error, toggleAssetSelection, onRemove }) => {

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  useEffect(() => {
    const file = asset.file;
    const extension = str.getExtensionFromPath(file.path);

    if (IMAGE_TAG_SUPPORTED_EXTENSIONS.includes(extension)) {
      setImagePreview(URL.createObjectURL(file));
    } else if (file.type.includes('video')) {
      setVideoPreview(URL.createObjectURL(file));
    }
  }, [asset.file]);

  return (
    <div
      className={classnames('uploader-asset-card', {
        'isSelected': isSelected,
        'error': !!error,
      })}
    >
      <div className='uploader-asset-card__overlay'>
        <button
          className='uploader-asset-card__close-button'
          onClick={() => onRemove(asset.id)}
        >
          <CloseIcon className='uploader-asset-card__close-icon' />
        </button>

        <CustomCheckbox
          checkedCondition={isSelected}
          handleCheck={toggleAssetSelection}
        />

        <Tooltip title={asset.name ?? ''} TransitionComponent={Zoom}>
          <div className='uploader-asset-card__tooltip' />
        </Tooltip>
      </div>

      <div className='uploader-asset-card__preview-container'>
        {videoPreview ?
          <video className='uploader-asset-card__video' src={videoPreview}></video> :
          <img
            src={imagePreview || asset.iconPreview}
            className={classnames('uploader-asset-card__image', {
              'icon': !imagePreview,
            })}
            alt={'Asset icon'}
          />
        }
      </div>

      <Tooltip title={asset.name ?? ''} TransitionComponent={Zoom}>
        <p className='uploader-asset-card__name'>{asset.name}</p>
      </Tooltip>
    </div>
  );
};

UploaderAssetCard.propTypes = {
  asset: assetToUploadPropTypes,
  isSelected: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  toggleAssetSelection: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default UploaderAssetCard;
