export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';

export const LOG_OUT_FAIL = 'LOG_OUT_FAIL';
export const LOG_OUT_START = 'LOG_OUT_START';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';

export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAIL = 'GET_USERS_LIST_FAIL';

export const SYNC_JIRA_START = 'SYNC_JIRA_START';
export const SYNC_JIRA_SUCCESS = 'SYNC_JIRA_SUCCESS';
export const SYNC_JIRA_FAIL = 'SYNC_JIRA_FAIL';

export const CREATE_CREATIVE_API_KEY_START = 'CREATE_CREATIVE_API_KEY_START';
export const CREATE_CREATIVE_API_KEY_SUCCESS = 'CREATE_CREATIVE_API_KEY_SUCCESS';
export const CREATE_CREATIVE_API_KEY_FAIL = 'CREATE_CREATIVE_API_KEY_FAIL';

export const REVOKE_CREATIVE_API_KEY_START = 'REVOKE_CREATIVE_API_KEY_START';
export const REVOKE_CREATIVE_API_KEY_SUCCESS = 'REVOKE_CREATIVE_API_KEY_SUCCESS';
export const REVOKE_CREATIVE_API_KEY_FAIL = 'REVOKE_CREATIVE_API_KEY_FAIL';

export const HIDE_CREATIVE_API_KEY = 'HIDE_CREATIVE_API_KEY';
export const SET_USER_JIRA_PROJECT_IDS = 'SET_USER_JIRA_PROJECT_IDS';
